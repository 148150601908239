import { t } from 'i18n-js';
import {
  Modal, Icon, Header,
} from 'semantic-ui-react';
import ModalActionButtons from '../../../../../components/buttons/ModalActionButtons';
import HeaderError from '../../../../../components/errors/HeaderError';
import { useFileContext } from '../../Context';
import { useDelete } from '../../../service';
import toastMessage from '../../../../../utilities/toast';
import { deleteTab, deleteTabsInFolder } from '../../../../../layout/tabMonitor';
import DeleteModalMessage from '../../../../../components/modals/DeleteModalMessage';

const DeleteModal = ({ setModalClose, setIsBusy }) => {
  const {
    name, id, type, folder,
  } = useFileContext();

  const fileOrFolder = type === 'folder' ? 'folder' : 'file';

  const {
    deleteFile,
    isLoading,
    isError,
    error,
    resetResponse,
  } = useDelete(id);

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleSubmitting = () => {
    setIsBusy(true);
    deleteFile(null, {
      onSuccess: () => {
        setIsBusy(false);
        handleModalClose();
        toastMessage('success', t('file_list.actions.delete_success', { fileName: name }), 'deleteSuccess');
        deleteTab(`workfiles/${id}`);
        if (type === 'folder') {
          deleteTabsInFolder(`${folder}/${name}`);
        }
      },
      onError: () => {
        setIsBusy(false);
      },
    });
  };

  const headerContent = type === 'folder'
    ? t('file_list.actions.delete_folder')
    : t('file_list.actions.delete_file');

  const isValidEntry = !!id;

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="trash alternate" title="delete" />
        <Header.Content content={headerContent} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <DeleteModalMessage title={t('file_list.actions.delete_sure', { fileOrFolder, fileName: name })} />
      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmitting}
        isValidEntry={isValidEntry}
        error={isError}
        submitText={t('file_list.actions.delete')}
        negativeSentiment
      />
    </>
  );
};

export default DeleteModal;
