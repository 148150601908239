import chorus from '../../chorus';
import $ from '../../jquery';
import t from '../../intl';
import TruncatedText from '../core/truncated_text_view';
import ApproveAirlockConfirmAlert from '../../alerts/approve_airlock_confirm_alert';
import DeleteNoteConfirmAlert from '../../alerts/delete_note_confirm_alert';
import NotificationDeleteAlert from '../../alerts/notification_delete_alert';
import RejectAirlockConfirmAlert from '../../alerts/reject_airlock_confirm_alert';
import DownloadAirlockConfirmAlert from '../../alerts/download_airlock_confirm_alert';
import CommentList from './comment_list_view';
import Comment from '../../dialogs/activities/comment_dialog';
import EditNote from '../../dialogs/activities/edit_note_dialog';
import EditAirlock from '../../dialogs/airlock/edit_airlock_dialog';
import DataSourceAccount from '../../dialogs/dataset/data_source_account_dialog';
import ErrorDetails from '../core/error_details_view';
import Activity, { toAirlock } from '../../presenters/activity_presenters';
import { comments } from '../../utilities/collaboration';
import Insight from '../../models/insight';
import View from '../loading_view';

export default View.extend({
  constructorName: 'ActivityView',
  templateName: 'activity',
  tagName: 'li',

  events: {
    'click a.promote': 'promote',
    'click a.update_credentials': 'launchUpdateCredentialsDialog',
    'click a.delete_note': 'launchDeleteNoteConfirmAlert',
    'click a.delete_notification': 'launchNotificationDeleteAlert',
    'click a.edit_note': 'launchEditNoteDialog',
    'click a.comment': 'launchCommentDialog',
    'click a.edit_airlock': 'launchEditAirlockDialog',
    'click a.approve_airlock': 'launchApproveAirlockConfirmAlert',
    'click a.reject_airlock': 'launchRejectAirlockConfirmAlert',
    'click a.copy_airlock': 'copy_airlock',
    'click a.expand_attachments': 'expandAttachments',
    'click a.download_ready_airlock': 'handleDownload',
  },

  subviews: {
    '.comment_list': 'commentList',
    '.activity_content > .truncated_text': 'htmlContent',
    '.activity_content > .actions > .error_details': 'failureContent',
  },

  setup() {
    this.subscribePageEvent('comment:added', this.addComment);
    this.subscribePageEvent('comment:deleted', this.deleteComment);
    this.subscribePageEvent('selected:activity', this.activityTabShown);
  },

  activityTabShown() {
    // XAP-7709. Re-render html when tab is shown as read more/less comments require
    // html to be visible in order to display (calculate heights etc) properly
    this.htmlContent && this.htmlContent.show();
  },

  addComment(comment) {
    if (this.model.id === comment.get('eventId')) {
      const activityComments = comments(this.model);
      if (!activityComments.get(comment.id)) {
        activityComments.add(comment);
      }
      this.render();
    }
  },

  deleteComment(comment) {
    const activityComments = comments(this.model);
    if (activityComments.get(comment.id)) {
      activityComments.remove(comment);
      this.render();
    }
  },

  context() {
    return new Activity(this.model, this.options);
  },

  setupSubviews() {
    this.commentList = this.commentList || new CommentList({ collection: comments(this.model), usersCollection: this.options.usersCollection, managementWorkspace: this.options.managementWorkspace });

    if (!this.htmlContent) {
      if (this.model.isUserGenerated()) {
        this.htmlContent = new TruncatedText({
          model: this.model,
          attribute: 'body',
          attributeIsHtmlSafe: true,
        });
      }
      if (this.model.hasCommitMessage()) {
        this.htmlContent = new TruncatedText({
          model: this.model,
          attribute: 'commitMessage',
          attributeIsHtmlSafe: true,
        });
      }
      if (this.model.isAirlock()) {
        this.htmlContent = new TruncatedText({
          model: this.model,
          attribute: 'reason',
          attributeIsHtmlSafe: true,
        });
      }
    }
    this.failureContent = this.failureContent || new ErrorDetails({ model: this.model });
  },

  promote(e) {
    e.preventDefault();
    const insight = new Insight({
      noteId: this.model.get('id'),
      action: 'create',
    });
    insight.bind('saved', function onSave() {
      this.model.fetch();
      chorus.PageEvents.trigger('insight:promoted', this.model);
    }, this);
    insight.save({ validateBody: false });
  },

  copy_airlock(e) {
    e.preventDefault();
    const airlock = toAirlock(this.model);

    airlock.save({}, { method: 'create', isCopy: true });
    airlock.on('saved', () => { chorus.PageEvents.trigger('airlock:saved', airlock); });

    const workspace = this.model.get('target');
    chorus.toast('airlock.copy.scheduled.toast', { workspaceName: workspace.name });
  },

  launchUpdateCredentialsDialog(e) {
    e.preventDefault();
    new DataSourceAccount({
      dataSource: this.model.dataSource(),
      title: t('data_sources.account.edit.title'),
    }).launchModal();
  },

  launchDeleteNoteConfirmAlert(e) {
    e.preventDefault();
    const alert = new DeleteNoteConfirmAlert({ activity: this.model });
    alert.launchModal();
  },

  launchNotificationDeleteAlert(e) {
    e.preventDefault();
    const alert = new NotificationDeleteAlert({ activity: this.model });
    alert.launchModal();
  },

  launchEditNoteDialog(e) {
    e.preventDefault();
    const dialog = new EditNote({ activity: this.model });
    dialog.launchModal();
  },

  launchEditAirlockDialog(e) {
    e.preventDefault();
    const dialog = new EditAirlock({ activity: this.model });
    dialog.launchModal();
  },

  launchApproveAirlockConfirmAlert(e) {
    e.preventDefault();
    const alert = new ApproveAirlockConfirmAlert({ activity: this.model });
    alert.launchModal();
  },

  launchRejectAirlockConfirmAlert(e) {
    e.preventDefault();
    const alert = new RejectAirlockConfirmAlert({ activity: this.model });
    alert.launchModal();
  },

  launchCommentDialog(e) {
    e.preventDefault();
    const dialog = new Comment({ pageModel: this.model, eventId: this.model.id });
    dialog.launchModal();
  },

  postRender() {
    $(this.el).attr('data-activity-id', this.model.get('errorModelId') || this.model.get('id'));
    this.$('a.delete_link, a.edit_link').data('activity', this.model);
    if (this.model.get('isInsight')) {
      $(this.el).addClass('insight');
    } else if (this.model.isAirlock()) {
      $(this.el).addClass('download-request');
      const length = this.model.get('attachments').length;
      if (length > 3) {
        $(this.el).addClass('download-request4');
      } else {
        $(this.el).addClass(`download-request${length}`);
      }
    } else if (this.model.isFailure()) {
      $(this.el).addClass('error');
    }
  },

  handleDownload(e) {
    e.preventDefault();
    const alert = new DownloadAirlockConfirmAlert({ activity: this.model, href: e.target.href });
    alert.launchModal();
  },

  expandAttachments() {
    this.model.set('expandAttachments', true);
  },

  show() {
    this.htmlContent && this.htmlContent.show();
  },
});
