import _ from '../../underscore';
import $ from '../../jquery';
import BreadcrumbsView from '../core/breadcrumbs_view';
import LinkMenu from '../core/link_menu_view';
import View from '../loading_view';

export default View.extend({
  templateName: 'default_content_header',

  subviews: {
    '.breadcrumbs': 'breadcrumbs',
  },

  context() {
    const ctx = this.options;
    return _.extend({}, ctx, this.additionalContext());
  },

  postRender() {
    if (this.options.linkMenus) {
      this.configureMenus();
    }

    if (this.options.crumbs) {
      this.configureBreadcrumbs();
    }
  },

  configureMenus() {
    const self = this;
    _.each(this.options.linkMenus, (menuOptions, menuKey) => {
      const menu = new LinkMenu(menuOptions);
      self.$('.menus').append(menu.render().el);
      $(menu.el).addClass(menuKey);
      menu.bind('choice', (eventType, choice) => {
        self.trigger(`choice:${eventType}`, choice);
      });
    });
  },

  configureBreadcrumbs() {
    this.breadcrumbs = new BreadcrumbsView({
      breadcrumbs: _.isFunction(this.crumbs) ? _.bind(this.crumbs, this) : this.options.crumbs,
    });
  },
});
