import MiniApp from '../models/mini_apps/app';
import Collection from './collections';

export default Collection.extend({
  model: MiniApp,
  urlTemplate: 'api/workspaces/workspaces/{{workspaceID}}/mini_app',

  urlParams(options) {
    const opts = options;
    delete opts.per_page;
    delete opts.page;
    return {
      onlyShowPublished: false,
    };
  },
});
