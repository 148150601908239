import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import ListContentDetailsButtonView from './list_content_details_button_view';
import View from '../loading_view';
import BoundForMultiSelect from '../../mixins/bound_for_multi_select';

export default View.include(BoundForMultiSelect).extend({
  constructorName: 'ListContentDetailsView',
  templateName: 'list_content_details',
  additionalClass: 'action_bar_primary',

  events: {
    'click a.next': 'fetchNextPage',
    'click a.previous': 'fetchPreviousPage',
  },

  subviews: {
    'div.button_holder': 'buttonView',
  },

  setup() {
    this.buttonView = this.options.buttonView || new ListContentDetailsButtonView(this.options);
    this.listenTo(this.collection, 'remove', this.render);
    if (this.options.multiSelect) {
      this.unselectOnFetch();
    }
  },

  search(val, page, hideNavigation) {
    const options = { data: {} };
    options.data[this.collection.searchField] = `*${val}*`;
    this.startLoading(hideNavigation);
    this.collection.fetchPage(page, options);
    this.scrollToTopOfPage();
  },

  fetchFirstPage() {
    this.search(this.namePattern());
  },

  fetchNextPage() {
    if (this.$('.page_navigation').hasClass('disabled') === false) {
      const page = parseInt(this.collection.pagination.page, 10);
      this.search(this.namePattern(), page + 1, false);
    }
  },

  fetchPreviousPage() {
    if (this.$('.page_navigation').hasClass('disabled') === false) {
      const page = parseInt(this.collection.pagination.page, 10);
      this.search(this.namePattern(), page - 1, false);
    }
  },

  namePattern() {
    if (typeof this.collection.attributes.namePattern !== 'undefined') {
      return this.collection.attributes.namePattern;
    }
    return '';
  },

  scrollToTopOfPage() {
    window.scroll(0, 0);
  },

  postRender(el) {
    if (!this.paginationRequired && this.options.hideIfNoPagination) {
      el.addClass('hidden');
    } else {
      el.removeClass('hidden');
    }

    if (this.options.search) {
      this.setupSearch();
    }

    this.renderCheckedState();
  },

  setupSearch() {
    if (this.collection.attributes.namePattern) {
      this.$('input.search:text').val(this.collection.attributes.namePattern);
    }

    chorus.search(_.extend(
      {
        input: this.$('input.search:text'),
        afterFilter: _.bind(this.updateFilterCount, this),
      },
      this.options.search,
    ));

    if (this.options.search.localFilters === true) {
      this.updateFilterCount();
      this.listenTo(this.collection, 'filtersChanged', this.resetFilters);
    }
  },

  startLoading(hideNavigation) {
    if (hideNavigation) {
      this.$('.page_navigation').addClass('hidden');
    } else {
      this.$('.page_navigation').addClass('disabled');
    }
    this.$('.rsh-loading').removeClass('hidden');
  },

  resetFilters() {
    const search = this.$('input.chorus_search');
    if (search.val().length > 0) {
      search.val('').trigger('textchange').blur();
    } else {
      this.updateFilterCount();
    }
  },

  updateFilterCount() {
    this.options.search.list.find('.app-noneFound').remove();
    const count = this.options.search.list.find('> li').not('.hidden').length;
    this.$('.app-listItemCount').text(t(`entity.name.${this.options.modelClass}`, { count }));
    if (count === 0 && this.options.search.noneFoundText) {
      const noneFound = $("<li class='browse_more app-noneFound'></li>");
      noneFound.append(this.options.search.noneFoundText);
      this.options.search.list.append(noneFound);
    }
  },

  updatePagination() {
    this.$('.rsh-loading').addClass('hidden');
    const count = this.collection.totalRecordCount();
    this.$('.app-listItemCount').text(t(`entity.name.${this.options.modelClass}`, { count }));

    if (this.collection.loaded && this.collection.pagination) {
      if (this.collection.length > 0) {
        this.$('.current').text(this.collection.pagination.page);
        this.$('.total').text(this.collection.pagination.total);
      }

      const { page } = this.collection.pagination;
      const { total } = this.collection.pagination;
      this.paginationRequired = total <= 1;
      this.$('.pagination').toggleClass('hidden', this.paginationRequired);

      const hasPreviousLink = page > 1;
      this.$('a.previous').toggleClass('hidden', !hasPreviousLink);
      this.$('span.previous').toggleClass('hidden', hasPreviousLink);

      const hasNextLink = page < total;
      this.$('.next').toggleClass('hidden', !hasNextLink);
      this.$('span.next').toggleClass('hidden', hasNextLink);
    } else {
      this.$('.page_navigation').addClass('hidden');
    }
  },

  additionalContext() {
    const hash = {
      hideCounts: this.options.hideCounts,
      buttons: this.options.buttons,
      search: this.options.search,
      workspaceId: this.collection && this.collection.attributes && this.collection.attributes.workspaceId,
      multiSelect: this.options.multiSelect,
      pagination: !this.options.hideIfNoPagination || (
        this.collection.loaded &&
          this.collection.pagination &&
          this.collection.pagination.total > 1
      ),
      page: 1,
      total: 1,
      nextPage: false,
      prevPage: false,
      count: t(`entity.name.${this.options.modelClass}`, { count: 0 }),
    };

    this.paginationRequired = hash.pagination;

    if (this.collection.loaded && this.collection.pagination) {
      const count = this.collection.totalRecordCount();
      hash.page = this.collection.pagination.page;
      hash.total = this.collection.pagination.total;
      hash.nextPage = hash.page < hash.total;
      hash.prevPage = hash.page > 1;
      hash.count = t(`entity.name.${this.options.modelClass}`, { count });
    }

    return hash;
  },
});
