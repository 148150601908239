import _ from '../../../underscore';
import View from '../../loading_view';

export default View.extend({
  templateName: 'mini_apps/apps-sidebar-workfiles',
  entityType: 'MiniAppsSidebarWorkfiles',

  setup() {
    this.debouncedUpdateModel = _.debounce(this.updateModel.bind(this), 10);
    this.shouldUpdate = true;

    this.subscribePageEvent('mini_app:deleted', this.modelDeleted);
    this.subscribePageEvent('workfile:uploaded', this.checkWorkfileChanged);
    this.subscribePageEvent('workfile:deleted', this.checkWorkfileChanged);

    if (this.model) {
      this.listenTo(this.model, 'sync', () => {
        this.render();
      });
    }
  },

  debouncedUpdateModel: null, // Will be populated in setup
  updateModel() {
    if (this.shouldUpdate) {
      this.model.fetch();
    }
  },

  modelDeleted(app) {
    if (!this.model || app.get('id') === this.model.get('id')) {
      this.shouldUpdate = false;
    }
  },

  checkWorkfileChanged(changedWorkfile) {
    const appName = changedWorkfile.get('fileName');
    if (appName) {
      const slashPosition = appName.indexOf('/');
      if (slashPosition !== -1) {
        this.debouncedUpdateModel();
      }
    }
  },

  additionalContext() {
    return {
      workfiles: this.model.getWorkfiles(),
      workspaceId: this.options.workspaceId,
    };
  },
});
