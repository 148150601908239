import _ from '../../underscore';
import $ from '../../jquery';
import chorus from '../../chorus';
import DesktopsSidebar from './desktops_sidebar';
import View from '../loading_view';
import Routing from '../../mixins/routing';
import { getManagementWorkspace } from '../../models/management/workspace';
import { FEATURE } from '../../../utilities/features';

export default View.include(Routing).extend({
  templateName: 'desktops',

  events: {
    'click .start': 'startVM',
    'click .stop': 'stopVM',
    'click .connect': 'connect',
    'click .desktop_refresh': 'fetchCollection',
  },

  subviews: {
    '.app-sidebarBasic': 'sidebar',
  },

  additionalContext() {
    return {
      total_desktops: this.collection.length,
      no_desktops: this.collection.loaded && this.collection.length === 0 ? true : false,
    };
  },

  setup(options) {
    this.workspace = options.workspace;
    this.desktops = this.collection;
    this.sidebar = new DesktopsSidebar();
    this.connectingVms = {};
    this.connectedVms = {};

    this.managementWorkspace = getManagementWorkspace(this.workspace.get('uuid'));
    this.managementWorkspace.fetchIfNotLoaded();
    this.requiredResources.add(this.managementWorkspace);
    if (FEATURE.NEW_UX && !this.desktops?.models?.length) {
      this.collection.fetch();
    }
  },

  collectionModelContext(item) {
    const desktopTags = item.get('tags').desktops ? item.get('tags').desktops : '20';
    const vnc = item.get('tags') ? item.get('tags').vnc : null;
    const chorusUser = chorus.session.user().get('uuid');
    const userIsConnected = item.get('connectedUsers').some((user) => user.uuid === chorusUser);
    const connectionPossible = userIsConnected || ( item.get('os') === 'Linux' && item.get('connectedUsers').length < desktopTags ) || ( item.get('os') === 'Windows' && item.get('connectedUsers').length < 1 );
    return {
      iconUrl: item.iconUrl(),
      connectedStyle: item.get('connectedUsers').length > 0 ? 'background-color:pink;' : '',
      connectedClass: (item.get('connectedUsers').length > 0 && item.get('os') === 'Windows' && !userIsConnected) ? ' hidden' : '',
      connectedLinuxClass: (item.get('connectedUsers').length > 0 && item.get('os') === 'Linux' && !userIsConnected) ? ' hidden' : '',
      runningClass: item.get('status') === 'running' ? '' : ' hidden',
      startingClass: item.get('status') === 'starting' ? '' : ' hidden',
      stoppingClass: item.get('status') === 'deallocating' ? '' : ' hidden',
      deallocatedClass: item.get('status') === 'deallocated' || item.get('status') === 'stopped' ? '' : ' hidden',
      failedClass: item.get('status') === 'failed' ? '' : ' hidden',
      resolvedUsers: this.resolveUsers(item.get('connectedUsers')).slice(0, 3),
      connected: item.get('connectedUsers').length > 0,
      desktopUrl: this.standaloneDesktopUrl(item.get('id')),
      vnc,
      connectionPossible,
    };
  },

  showTemplate(template) {
    this.desktops.setTemplate(template);
    this.desktops.fetch();
  },

  toggleConnectLabel(id) {
    if (this.connectingVms[id]) {
      delete this.connectingVms[id];
    } else {
      this.connectingVms[id] = true;
    }
    this.$(`li[data-id="${id}"]`).toggleClass('connecting');
  },

  startVM(e) {
    e.preventDefault();
    const id = $(e.currentTarget).data('id');
    const desktop = this.collection.get(id);
    this.collection.attributes.tokenPromise().then((token) => {
      desktop.start(token);
      this.$(`li[data-id="${id}"][class="deallocated"]`).addClass('hidden');
      this.$(`li[data-id="${id}"][class="failed"]`).addClass('hidden');
      this.$(`li[data-id="${id}"][class="starting hidden"]`).removeClass('hidden');
      this.beginCollectionRefresh();
    });
  },

  beginCollectionRefresh() {
    const refreshFunc = function refreshVms() {
      this.refreshCount += 1;
      // check for 10 times, 5 mins
      if (this.refreshCount >= 10) {
        clearInterval(this.refreshTimer);
        this.refreshTimer = null;
      }
      this.desktops.fetch({
        success: function () {
          const changingVm = this.collection.findWhere({ status: 'starting' }) ||
            this.collection.findWhere({ status: 'deallocating' });
          if (!changingVm) {
            clearInterval(this.refreshTimer);
            this.refreshTimer = null;
          }
        }.bind(this),
      });
    };

    this.refreshCount = 0;
    if (!this.refreshTimer) {
      this.refreshTimer = setInterval(_.bind(refreshFunc, this), 30000);
    }
  },

  connect(e) {
    e.preventDefault();
    const id = $(e.currentTarget).data('id');
    this.$(`li[data-id="${id}"][class="running"]`).addClass('hidden');
    this.$(`li[data-id="${id}"][class="opening hidden"]`).removeClass('hidden');
    this.connectVM(e, id);
  },

  connectVM(e, id) {
    this.connectedVms[id] = true;
    this.$(`li[data-id="${id}"][class="running hidden"]`).removeClass('hidden');
    this.$(`li[data-id="${id}"][class="opening"]`).addClass('hidden');
    const connection = $(e.currentTarget).data('connection');
    let target = this.desktopUrl(id);
    if (connection) {
      target += '/';
      target += connection;
    }
    this.navigate(target);
  },

  desktopUrl(id) {
    const desktop = this.collection.get(id);
    const random = Math.random().toString(36).substr(2, 5);
    const os = desktop.get('os').toString().substr(0, 3);
    const { workspaceId } = this.desktops.attributes;
    return `#/workspaces/${workspaceId}/desktop/${desktop.get('name')}-${os}-${random}`;
  },

  standaloneDesktopUrl(id) {
    const desktop = this.collection.get(id);
    const random = Math.random().toString(36).substr(2, 5);
    const os = desktop.get('os').toString().substr(0, 3);
    const { workspaceId } = this.desktops.attributes;
    const suffix = desktop.get('os') === 'Linux' ? '/vnc' : '';
    return `#/desktop/${workspaceId}/${desktop.get('name')}-${os}-${random}${suffix}`;
  },

  stopVM(e) {
    e.preventDefault();
    const id = $(e.currentTarget).data('id');
    this.connectedVms[id] = false;
    const desktop = this.collection.get(id);
    this.collection.attributes.tokenPromise().then((token) => {
      desktop.stop(token);
      this.$(`li[data-id="${id}"][class="running"]`).addClass('hidden');
      this.$(`li[data-id="${id}"][class="stopping hidden"]`).removeClass('hidden');
      this.beginCollectionRefresh();
    });
  },

  fetchCollection(e) {
    if (e) { e.preventDefault(); }
    this.collection.fetch();
  },

  resolveUsers(users) {
    if (!this.managementWorkspace.get('members')) { return []; }
    const names = users.map((u) => {
      const uuid = u.uuid || u; // cope with both old version of api that just returns uuid and new version that returns an object with uuid
      const member = this.managementWorkspace.get('members').find(ele => ele.uuid === uuid);
      return member ? `${member.firstName} ${member.lastName}` : '';
    });
    return names.filter(u => u !== '');
  },
});
