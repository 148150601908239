import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import PickItems from '../pick_items_dialog';
import WorkspaceDatasetSet from '../../collections/workspace_dataset_set';

export default PickItems.extend({
  title: t('dataset.attach'),
  constructorName: 'DatasetsAttachDialog',
  submitButtonTranslationKey: 'actions.dataset_attach',
  emptyListTranslationKey: 'dataset.none',
  searchPlaceholderKey: 'dataset.dialog.search',
  selectedEvent: 'datasets:selected',
  modelClass: 'WorkspaceDataset',
  multiSelection: true,

  makeModel(...args) {
    this._super('makeModel', args);
    this.options = _.extend({
      collection: new WorkspaceDatasetSet([], {
        workspaceId: this.options.workspaceId,
        type: this.options.type,
      }),
    }, this.options);
  },

  setup() {
    const onTextChangeFunction = _.debounce(_.bind(function search(e) {
      this.paginationView.collection.attributes.namePattern = $(e.target).val();
      this.paginationView.fetchFirstPage();
    }, this), 300);

    this.options = _.extend({
      onTextChange: onTextChangeFunction,
    }, this.options);

    this._super('setup');
    this.paginationView.fetchFirstPage();
  },

  collectionModelContext(model) {
    return {
      name: model.get('objectName'),
      imageUrl: model.iconUrl({ size: 'icon' }),
    };
  },
});
