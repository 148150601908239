import _ from '../underscore';
import Base from './collections';

export default Base.extend({
  lastFetchId: 0,

  makeSuccessFunction(options, success) {
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;

    return _.bind(function onBind(collection, data) {
      if (fetchId !== this.lastFetchId) return null;
      const parentFunction = this._super('makeSuccessFunction', [options || {}, success]);
      return parentFunction(collection, data);
    }, this);
  },
});
