import _ from '../underscore';

/* eslint no-underscore-dangle: ["error", { "allow": ["_callback", "_events"] }] */

export default {

  // Only bind if that triple doesn't yet exist.
  bindOnce(eventName, callback, context) {
    const callbacksForThisEvent = this._events && this._events[eventName];
    if (callbacksForThisEvent) {
      const found = _.any(
        callbacksForThisEvent,
        binding => binding.callback._callback === callback && binding.context === context,
      );

      if (found) { return true; }
    }
    this.once(eventName, callback, context);
    return null;
  },

  shouldTriggerImmediately() {
    return false;
  },
};
