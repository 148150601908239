import t from '../intl';
import Confirm from './confirm';

export default Confirm.extend({
  constructorName: 'ResendMfaAlert',
  additionalClass: 'error',
  persistent: true,
  text: t('users.resend_mfa.alert.text'),
  title: t('users.resend_mfa.alert.title'),
  ok: t('users.resend_mfa.alert.ok'),

  confirmAlert(e) {
    e.preventDefault();
    this.model.performAction('DuoResend');
    this.closeModal();
  },

  postRender() {
    this._super('postRender'); // eslint-disable-line no-underscore-dangle
    this.$('button.submit').removeClass('btn-danger');
  },
});
