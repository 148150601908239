import ChartTask from './chart_task';

export default ChartTask.extend({
  chartType: 'histogram',
  columnLabels: {
    frequency: 'dataset.visualization.histogram.frequency',
    bin: 'dataset.visualization.histogram.bin',
  },

  beforeSave(...args) {
    this._super('beforeSave', args);
    this.set({
      'chart[xAxis]': this.get('xAxis'),
      'chart[bins]': this.get('bins'),
    });
  },
});

