/* global window:false document:false */

import $ from 'jquery';
import chorus from '../../chorus';
import _ from '../../underscore';
import t from '../../intl';
import { idToken } from '../../auth/oauth';
import { HandlebarsTemplates } from '../../vendor/handlebars';
import RCommand from '../../models/r_command';
import View from '../loading_view';
import Routing from '../../mixins/routing';
import { FEATURE } from '../../../utilities/features';

require('jquery.terminal');

/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true }] */

class ReconnectionState {
  constructor(workspace) {
    this.workspace = workspace;
    this.reconnecting = false;
    this.quitting = false;
    this.reconnectAttempts = 24; // 5 second delay in between attempts gives a total reconnect time of 2 mins
  }

  observeCommand(cmd) {
    if (cmd.includes('q()') || cmd.includes('quit()')) {
      this.quitting = true;
      this.workspace.set('activeRSessionStatus', 'quitting', { silent: true });
    }
  }

  reset() {
    this.reconnectAttempts = 24; // 5 second delay in between attempts gives a total reconnect time of 2 mins
    this.reconnecting = false;
  }

  shouldReconnect() {
    if (this.quitting || this.reconnectAttempts === 0) {
      this.quitting = false;
      return false;
    }

    this.reconnecting = true;
    return true;
  }

  shouldRetry() {
    return (this.reconnecting && this.reconnectAttempts > 0);
  }

  retryWithFn(fn) {
    this.reconnectAttempts -= 1;
    fn();
  }
}

export default View.include(Routing).extend({
  templateName: 'rtools/rtools_console',
  events: {
    'click .app-btnStartR': 'startRWhenReady',
    'click .app-consolePlaceholder': 'startRWhenReady',
    'click .app-autoCompleteEntry': 'consoleAutoCompleteClick',
    'click .rsh-autocomplete-prev-btn': 'consoleAutoCompletePrev',
    'click .rsh-autocomplete-next-btn': 'consoleAutoCompleteNext',
    'click .app-commandStop': 'stopCommand',
    'click .app-consoleInput': 'focusConsole',
    'click .app-btnClearConsole': 'clsConsole',
  },
  bindCallbacks: $.noop,
  listeningToResize: false,
  consoleRunning: false,
  enableInput: true,
  terminalAvailable: true,
  autoCompletePage: 0,

  setup(options) {
    this.workspace = this.model.get('workspace');
    this.workspaceId = this.workspace.id;
    this.command = options.command;

    this.timeouts = [];
    this.reconnectionState = new ReconnectionState(this.workspace);

    this.subscribePageEvent('rsh:rconsole:focus', this.focusConsole);
    this.subscribePageEvent('rsh:rconsole:focusAndEnable', this.focusAndEnableConsole);
    this.subscribePageEvent('rsh:rconsole:blur', this.blurConsole);
    this.subscribePageEvent('rsh:ui:rtools:sidebar:workfiles:action_share', this.performCommandWhenReady);
    // if a plot dialog is displayed, disable terminal input
    this.subscribePageEvent('rsh:ui:rtools:plot:dialog:open', this.blurConsole);
    this.subscribePageEvent('rsh:ui:rtools:report:dialog:open', this.blurConsole);
    this.subscribePageEvent('modal:closed', this.focusConsole);
    this.subscribePageEvent('modal:open', this.blurTerminal);

    this.listenTo(this.model, 'change:active', (_model, active) => {
      if (active) {
        this.focusConsole();
      } else {
        this.blurConsole();
      }
    });

    this.user = chorus.session.get('user');
    // Track if the terminal is globally available (enough sessions free etc)
    this.terminalAvailable = true;
    // If you need to modify the display, place code in postRender(); to ensure DOM is ready
  },

  isActive() {
    return this.model.get('active') && this.$el.is(':visible');
  },

  teardown() {
    if (this.terminal) {
      this.terminal.destroy();
      this.terminal = null;
      this.socketClose();
    }
    this.timeouts.forEach(id => clearTimeout(id));
    this._super('teardown');

    this.workspace.getConsoleUsage().fetch();
  },

  disableConsole() {
    if (this.terminal) {
      this.terminal.pause();
      this.enableInput = false;
    }
  },

  // Enable the console for input
  // 'start_ui' message > enableConsole > consoleReady > focusConsole
  enableConsole() {
    if (this.terminal && this.isActive()) {
      // Check that the console isn't already busy. We don't want to enable
      // it if was disabled due to busy-state, it'll get enabled when
      // it's finished being busy instead.
      if (!this.$('.app-busyConsoleSpinner').hasClass('app-isLoading')) {
        this.terminal.resume().focus();
        this.enableInput = true;
        this.consoleReady();
      }
    }
  },

  focusConsole() {
    if (this.consoleRunning) {
      if (this.terminal && this.isActive()) {
        this.$('.rsh-console').removeClass('rsh-console-blur');
        if (this.$('.app-autoComplete').is(':visible')) {
          this.$('.app-autoComplete').focus();
        } else if (!this.$('.app-busyConsoleSpinner').hasClass('app-isLoading')) {
          this.terminal.resume().focus();
        } else {
          this.terminal.focus();
        }
      }
      this.consoleResize();
    } else if (this.terminal && this.isActive()) {
      this.enableConsole();
    }
  },

  blurConsole() {
    // Awful code to check that body doesn't have focus...
    if (!$(document.activeElement).is('body')) {
      this.$('.rsh-console').addClass('rsh-console-blur');
      if (this.$('.app-autoComplete').is(':visible')) {
        this.$('.app-autoComplete').hide();
      }
      if (this.terminal) {
        this.terminal.pause();
      }
    }
  },

  blurTerminal() {
    if (this.terminal) {
      this.terminal.focus(false);
    }
  },

  focusAndEnableConsole() {
    this.focusConsole();
    this.enableConsole();
  },

  scrollConsoleToTop() {
    this.scrollUpdate();
    const api = this.$('.app-consoleInput').data('jsp');
    if (api) {
      api.scrollTo(0, 0);
    }
  },

  scrollConsoleToBottom() {
    this.scrollUpdate();
    const api = this.$('.app-consoleInput').data('jsp');
    if (api) {
      api.scrollToBottom();
    } else {
      this.consoleResize();
    }

    // We probably don't want to refocus the console here in case messages are received while we
    // don't have focus.
  },

  // Re-initialise scrolling after content change
  scrollUpdate() {
    const api = this.$('.app-consoleInput').data('jsp');
    if (api) {
      api.reinitialise();
    }
  },

  // Clears the contents of the console, but does not reset connection
  clsConsole() {
    if (this.terminal) {
      this.terminal.set_command('', true);
      this.terminal.clear();
      _.defer(() => this.focusConsole());
    }
  },

  startR() {
    this.enableInput = false;

    // In case of fatal error the old terminal will still be there
    // clean it up so the spinner can appear
    if(!this.consoleRunning && this.terminal){
      this.terminal.destroy();
      this.terminal = null;
      this.render();
    }

    // Button & spinner
    this.$('.app-startConsoleSpinner').startLoading('', 'spinner');
    this.$('.app-btnStartR').hide();

    // Blur any output generated by local.r
    this.$('.rsh-console').addClass('rsh-console-blur');

    // Text in main page
    this.$('.app-consolePlaceholder p').text(t('rsh.r.console.connecting'));
    if (this.workspace.id) {
      this.options.images.fetchIfNotLoaded().then(() => this.activateConsole());
    } else {
      // eslint-disable-next-line no-alert
      window.alert(t('rsh.r.console.expired'));
      this.navigate('#/workspaces/');
    }
  },

  startRWhenReady(e) {
    if (e) {
      e.preventDefault();
    }
    if (this.terminalAvailable && this.$('.app-btnStartR').is(':visible')) {
      this.startR();
    }
  },

  activateConsole() {
    const baseURL = `${(window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host}/api`;
    this.wsURL = `${baseURL}/consoles/${this.workspaceId}/${this.options.images.getSelectedId()}`;
    this.socketInit(this.wsURL);
    this.model.set('unsaved', true);
    if (!this.reconnectionState.reconnecting) {
      this.availableConsoles();
    }
  },

  availableConsoles() {
    // This will generate an event with a payload giving us console information
    const user = chorus.session.get('user');
    if (user) {
      this.workspace.getConsoleUsage();
    }
  },

  startReconnect() {
    this.disableConsole();

    // Hide placeholder
    this.$('.app-consolePlaceholder').show();
    this.$('.app-panelHeader').show();

    // Button & spinner
    this.$('.app-startConsoleSpinner').startLoading('', 'spinner');
    this.$('.app-btnStartR').hide();
    this.$('.app-consolePlaceholder p').text(t('rsh.r.console.reconnecting'));

    // Hide the console
    this.$('.app-panelConsoleFooter').hide();

    // Cleanup
    const self = this;
    _.defer(() => {
      self.consoleResize();
    });

    this.socketClose();
    this.delay(5000).then(() => this.activateConsole());
  },

  socketOnOpen() {
    if (this.reconnectionState.reconnecting) {
      this.reconnectionState.reset();
    }
    const interpreter = this.socketWrite.bind(this);
    const options = {
      greetings: '',
      name: this.workspace.name(),
      login: false,
      echoCommand: true,
      keydown: e => (this.keyDown(e)),
      onClear: () => (this.scrollConsoleToTop()),
      // Plugin glitch mean this is fired many times, so we need to debounce these so
      // that they will only be called once
      onBlur: _.debounce(() => (this.blurConsole()), 200, true),
      onCommandChange: () => (this.scrollConsoleToBottom()),
    };

    this.terminal = $(this.el).find('.app-consoleInput').terminal(interpreter, options);

    // We don't want the console to accept input until we get a message from the server, so
    // lets disable it for now
    this.disableConsole();
    idToken().then((token) => {
      this.delay(500).then(() => {
        // Send auth message only if the socket is ready
        if (this.webSocket.readyState){
          this.socketWriteNoHistory(`Authorization: Bearer ${token}`)
        }
      });
    });
  },

  // Handle messages webSocket
  socketOnMessage(message) {
    const msg = message.data;
    let returnToConsole = false;

    if (msg) {
      const decodedMessage = chorus.htmlDecode(msg);
      returnToConsole = true;
      if (this.isControlMessage(decodedMessage)) {
        // Here we test to see if the message that came back from the remote R console is JSON. If it is, then
        // it probably contains a special message and a data payload, so lets deal with that in context.
        const messageObject = $.parseJSON(decodedMessage);
        switch (messageObject.task) {
          case 'auto_complete': this.consoleAutoCompletePopup(messageObject); break;
          case 'set_next_prompt': this.processNextPrompt(messageObject.args.prompt); break;
          case 'display_plot': this.processPlotNotification(messageObject.args); break;
          case 'display_report': this.processReportNotification(messageObject.args); break;
          // Ignoring memory usage for now. Refer to XAP-10744 and XAP-11558 for further info.
          case 'memory_usage_update': break;
          case 'start_ui': this.enableConsole(); break;
          case 'command_refused': this.processCommandRefused(messageObject); break;
          case 'command_interrupted': this.processCommandInterrupted(messageObject); break;
          case 'session_limit_reached':
            this.consoleClose(t('rsh.r.console.limit.reached'));
            this.webSocket.onclose = $.noop;
            break;
          case 'startup_info':
            this.processStartupInfo(messageObject);
            returnToConsole = false;
            break;
          case 'fatal_error':
            chorus.toast('rsh.r.console.fatalError');
            this.closeConnection();
            break;
          default:
            // We didn't get JSON we can handle
            this.processBadlyFormedTask();
        }
      } else {
        // this is a normal R response rather than JSON task, so process as normal
        this.consoleWrite(msg);
        this.scrollConsoleToBottom();
        returnToConsole = false;
      }
    }

    if (returnToConsole) {
      this.$('.app-commandStop').hide();
      this.$('.app-busyConsoleSpinner').stopLoading();
      if (this.isActive()) {
        // if ( this.terminal)
        if (document.hasFocus()) {
          this.terminal.resume();
          this.scrollConsoleToBottom();
        }
      }
    }
  },

  // Handle errors from webSocket
  socketOnError(errorMessage) {
    if (this.terminal && this.reconnectionState.shouldReconnect()) {
      this.startReconnect();
    } else if (this.reconnectionState.shouldRetry()) {
      this.reconnectionState.retryWithFn(() => {
        this.socketClose();
        this.delay(5000).then(() => this.activateConsole());
      });
    } else {
      chorus.toast('rsh.r.console.errorSocketError');
      this.consoleClose(`${t('rsh.r.console.errorSocketError')} ${errorMessage}`);
    }
  },

  // WebSocket closed, we need to check if it was user-generated or because of error
  socketOnClose() {
    if (this.reconnectionState.reconnecting) {
      // Already reconnecting so do nothing
    } else if (this.terminal && this.reconnectionState.shouldReconnect()) {
      // Only start reconnecting if reconnect not already commenced by socketOnError
      this.startReconnect();
    } else {
      this.consoleClose(t('rsh.r.console.errorSocketClosedByServer'));
      this.options.images.fetch();
    }
  },

  socketInit(ws) {
    this.webSocket = new window.WebSocket(ws);
    this.webSocket.onopen = this.socketOnOpen.bind(this);
    this.webSocket.onclose = this.socketOnClose.bind(this);
    this.webSocket.onmessage = this.socketOnMessage.bind(this);
    this.webSocket.onerror = this.socketOnError.bind(this);
  },

  socketClose() {
    this.webSocket.onclose = $.noop;
    this.webSocket.close();
    this.webSocket = null;
  },

  socketWrite(message) {
    if (this.webSocket){
      this.reconnectionState.observeCommand(message);
      this.webSocket.send(message);
      this.$('.app-busyConsoleSpinner').startLoading();
      this.terminal.pause();
      _.defer(() => {
        this.$('.app-commandStop').show();
      }, 3000);
      this.historyUpdate(message);
    }
  },

  socketWriteNoHistory(message) {
    this.webSocket.send(message);
  },

  isControlMessage(msg) {
    try {
      let parsed = JSON.parse(msg);
      return parsed.task !== undefined && parsed.args !== undefined;
    } catch (e) {
      return false;
    }
  },

  stopCommand() {
    this.socketWriteNoHistory('RSH-SEND-INTERRUPT');
  },

  processMessage(message) {
    if (message) {
      if (this.terminal) {
        this.terminal.echo(message, { exec: false });
        this.terminal.set_command('', true);
      }
    }
  },

  processBadlyFormedTask() {
    // We didn't get JSON we can handle, so re-enable the console and warn the user
    this.terminal.resume();
    this.consoleWrite(t('rsh.r.console.badJSON'));
  },

  processPlotNotification(data) {
    const url = `data:image/gif;base64,${data}`;
    this.model.trigger('plot:available', { plot: url });
  },

  processReportNotification(data) {
    this.model.trigger('report:available', { report: data });
  },

  processCommandRefused(notificationObject) {
    const { args: { reason } } = notificationObject;
    this.consoleWrite(`${t('rsh.r.console.refused')} '${reason}'. ${t('rsh.r.console.aborted_nextstep')}`);
  },

  processCommandInterrupted(notificationObject) {
    this.consoleWrite(`${t('rsh.r.console.aborted')} '${notificationObject.args.reason}`);
  },

  processStartupInfo(notificationObject) {
    this.$('.app-consolePlaceholder p').text(notificationObject.args.reason);
  },

  processNextPrompt(prompt) {
    // N.B. IT IS VERY IMPORTANT THAT 'indexOf' METHOD OF MATCHING IS NOT REPLACED HERE
    // this is due to how JavaScript handles non-printable characters.
    if (prompt.indexOf(':') !== -1) {
      this.terminal.set_prompt(prompt);
    } else if (prompt.indexOf('(END)') !== -1) {
      this.terminal.set_prompt('>');
      // We need to force 'q' to exit paging mode.
      this.socketWrite('q');
      this.scrollConsoleToBottom();
    } else if (prompt.indexOf('+') !== -1 || prompt.indexOf('-') !== -1 ||
        prompt.indexOf('*') !== -1 || prompt.indexOf('/') !== -1) {
      this.terminal.set_prompt(prompt);
      this.scrollConsoleToBottom();
    } else {
      this.terminal.set_prompt(prompt);
      this.enableInput = true;
      this.scrollConsoleToBottom();
    }
  },

  // Removes unwanted ansi sequences from message
  removeUnwantedAnsiCharacterSequences(message) {
    if (message) {
      return message.replace(/\[K/g, '')
        // eslint-disable-next-line no-control-regex
        .replace(/\[\?1h=/g, '')
        .replace(/\[\?1049h/g, '')
        .replace(/\[\?1049l/g, '')
        .replace(/\[\?1l/g, '')
        .replace(/&gt;&gt;/g, '');
    }
    return message;
  },

  // Sanitize lines executed from workfile
  sanitizeCommand(message) {
    if (message) {
      return message.replace(/\r/g, '');
    }
    return message;
  },

  // Destroy the console, pass message to UI
  // No UI elements should be in this...
  consoleClose(message) {
    if (this.terminal) {
      this.terminal.destroy();
      this.terminal = null;
      this.closeConnection();
      this.render();
    }
    this.consoleHide(message);
    this.model.trigger('console:closed');
    this.model.set('unsaved', false);
  },

  closeConnection(){
    this.consoleRunning = false;
    this.socketClose();
  },

  // Console connection has been made (WebSocket has been successfully opened)
  consoleReady() {
    this.consoleRunning = true;
    this.options.images.fetch();
    // Hide placeholder
    this.$('.app-consolePlaceholder').hide();
    this.$('.app-panelHeader').hide();

    // Default messages & buttons
    this.$('.app-consolePlaceholder p').text(t('rsh.r.console.placeholder'));
    this.$('.app-startConsoleSpinner').stopLoading();

    // Show the console
    this.$('.app-panelConsoleFooter').show();

    // Give console focus and enable it
    this.performCommand();
  },

  // Hide console UI
  // We only deal with thje UI elements here, the console should be destroyed elsewhere
  consoleHide(message) {
    // Hide placeholder
    this.$('.app-consolePlaceholder').show();
    this.$('.app-panelHeader').show();

    // Default messages & buttons
    this.$('.app-startConsoleSpinner').stopLoading();
    this.$('.app-btnStartR').show();
    if (message) {
      this.$('.app-consolePlaceholder p').text(message);
    } else {
      this.$('.app-consolePlaceholder p').text(t('rsh.r.console.placeholder'));
    }

    // Hide the console
    this.$('.app-panelConsoleFooter').hide();

    // Cleanup
    const self = this;
    _.defer(() => {
      self.consoleResize();
    });
  },

  // Writes output to the console ( this.terminal ). Here we call the message
  // processing to ensure the correct user prompts are set
  consoleWrite(message) {
    const msg = this.removeUnwantedAnsiCharacterSequences(message);
    const isNextPrompt = msg.indexOf('ConsoleNextPrompt2') !== -1;
    if (isNextPrompt) {
      const prompt = msg.replace('ConsoleNextPrompt2', '');
      this.processNextPrompt(prompt);
    } else {
      this.processMessage(msg);
    }
  },

  postRender() {
    // UI Cleanup
    this._super('postRender'); // eslint-disable-line no-underscore-dangle
    this.$('.app-panelConsoleFooter').hide();
    this.$('.app-autoComplete').hide();
    this.$('.app-commandStop').hide();

    // Trigger check of console availability
    this.availableConsoles();

    // Start listening to page resize events
    if (!this.listeningToResize) {
      this.listeningToResize = true;
      this.listenTo(chorus.page, 'resized', this.consoleResize); // OC page event
    }
    this.performCommandWhenReady(this.command);
  },

  // Each time the user enters a command, we want to update the history panel, so here
  // we broadcast a message for the history View.
  historyUpdate(message) {
    const maxLines = 6;
    let displayMessage = _.escape(message);
    const displayMessages = displayMessage.split('\n');
    const linesToOutput = Math.min(displayMessages.length, maxLines);

    if (linesToOutput > 1) {
      displayMessage = '';
      for (let i = 0; i < linesToOutput; i += 1) {
        displayMessage = displayMessage.concat(displayMessages[i], '<br/>');
      }
      if (displayMessages.length > maxLines) {
        displayMessage = displayMessage.concat('...');
      }
    }
    displayMessage = displayMessage.replace(/ /g, '&nbsp');
    this.model.trigger('history:update', { fullEntry: message, shortEntry: displayMessage });
  },

  // We're listening for events from the History panel, so lets handle pasting...
  historyPaste(history) {
    if (this.terminal && this.isActive()) {
      this.paste(history);
    }
  },

  // We're listening for events from the scripts panel, so lets handle pasting...
  performCommandWhenReady(command) {
    this.command = command;
    if (this.terminal && this.consoleRunning) {
      this.performCommand();
    } else if (this.command.get('action') && !this.webSocket) {
      this.startR();
    }
  },

  performCommand() {
    if (this.command.get('action') === 'run') {
      const message = this.command.get('command');
      const msg = this.sanitizeCommand(message);

      this.terminal.echo(msg, { exec: false });
      this.socketWrite(msg);
    } else if (this.command.get('action') === 'paste') {
      this.terminal.insert(this.command.get('command'));
    }

    this.command = new RCommand();
    this.scrollAndFocus();
  },

  scrollAndFocus() {
    this.scrollConsoleToBottom();
    _.defer(() => this.focusConsole());
  },

  paste(textToInsert) {
    this.terminal.insert(textToInsert);
    this.scrollAndFocus();
  },

  // validates whether the current keystroke is a valid character for a method or variable name.
  // If so, it returns the ascii character - otherwise -1
  valid_method_character(e) {
    const code = e.keyCode;
    if (code > 47 && code < 58) {
      return String.fromCharCode(e.keyCode);
    } else if (code > 64 && code < 91) {
      const char = String.fromCharCode(e.keyCode);
      return e.shiftKey ? char.toUpperCase() : char.toLowerCase();
    } else if (e.keyCode === 189 && e.shiftKey) {
      return '_';
    } else if (e.keyCode === 190) {
      return '.';
    }
    return -1;
  },

  // Handle all keydown events, even for non-printable characters
  keyDown(e) {
    if (this.enableInput) {
      const code = e.keyCode || e.which;
      if (this.$('.app-autoComplete').is(':visible')) {
        // directly disable terminal, but don't trigger blur
        e.preventDefault();
        this.$('.app-autoComplete').focus();
        const selected = this.$('.rsh-console-autocomplete-highlight-entry');
        const key = this.valid_method_character(e);
        if (key !== -1) {
          const newCommand = this.terminal.get_command() + key;
          this.terminal.set_command(newCommand);
          const updatedList = [];
          this.$('.app-autoCompleteEntries li').each((idx, li) => {
            const complete = $(li).text();
            if (complete.indexOf(newCommand) === 0) {
              updatedList.push(complete);
            }
          });
          this.clearAndPopulateAutoComplete(updatedList);
          return false;
        } else if (code === 38) { // UP = select previous
          const prev = selected.prev();
          selected.removeClass('rsh-console-autocomplete-highlight-entry');
          if (prev.length > 0 && prev.hasClass('app-autoCompleteEntry')) {
            prev.addClass('rsh-console-autocomplete-highlight-entry');
          } else {
            this.$('.app-autoComplete li.app-autoCompleteEntry:last')
              .addClass('rsh-console-autocomplete-highlight-entry');
          }
          this.$('.app-autoComplete').focus();
          return false;
        } else if (code === 40) { // DOWN = select next
          const next = selected.next();
          selected.removeClass('rsh-console-autocomplete-highlight-entry');
          if (next.length > 0 && next.hasClass('app-autoCompleteEntry')) {
            next.addClass('rsh-console-autocomplete-highlight-entry');
          } else {
            this.$('.app-autoComplete li:first').addClass('rsh-console-autocomplete-highlight-entry');
          }
          this.$('.app-autoComplete').focus();
          return false;
        } else if (code === 27 || code === 8) { // ESC, BACKSPACE (cancel)
          this.$('.app-autoComplete').hide();
          this.terminal.focus();
          this.enableInput = true;
          return false;
          // Keycodes that'll generate an autocomplete
        } else if (code === 9) { // TAB
          this.autoCompletePage += 1;
          this.clearAndPopulateAutoComplete();
        } else if (
          code === 32 ||
            code === 39 ||
            code === 13
        ) { // SPACE, RIGHT, ENTER
          this.$('.app-autoComplete').hide();
          this.consoleAutoCompleteKeyPress(selected);
          return false;
        }
      } else if (this.isActive()) {
        if (code === 9) { // TAB
          e.preventDefault();
          this.$('.app-autoComplete').hide();
          this.terminal.focus();
          this.enableInput = true;
          this.consoleAutoComplete();
          return false;
        }
        return undefined; // undefined ensures terminal processes keys
      }
    }
    return false;
  },

  // User has hit the autocomplete key, so we send the current command line
  // over the socket to the R server
  consoleAutoComplete() {
    const currentCommandLine = this.terminal.get_command();
    if (currentCommandLine.length >= 1) {
      let cmd = currentCommandLine;
      const lastSymbolLocation = Math.max(
        currentCommandLine.lastIndexOf('('),
        currentCommandLine.lastIndexOf('='),
        currentCommandLine.lastIndexOf(','),
        currentCommandLine.lastIndexOf(' '),
        currentCommandLine.lastIndexOf('<-'),
      );

      if (currentCommandLine.lastIndexOf('(') !== -1 || currentCommandLine.lastIndexOf('<-') !== -1) {
        cmd = lastSymbolLocation === (currentCommandLine.length - 1) ?
          $.trim(currentCommandLine.substr(0, lastSymbolLocation + 1)) :
          $.trim(currentCommandLine.substr(lastSymbolLocation + 1, currentCommandLine.length));
      }

      const sendCommand = `{ "task":"auto_complete", "args" : { "token":"${cmd}"} }`;
      this.terminal.lastAutoCompleteToken = cmd;
      this.socketWriteNoHistory(sendCommand);
    }
  },

  // We got a response from autocomplete from the server, so we need to turn
  // the string into an array & display a popup
  consoleAutoCompletePopup(notificationObject) {
    // Autocomplete was triggered, which can only happen if console *was* enabled,
    // so to ensure blurring of the terminal hasn't blocked input for autocomplete,
    // we force it to be enabled:
    this.enableInput = true;
    // Jump use to bottom of terminal
    this.scrollConsoleToBottom();
    this.autoCompleteOptions = notificationObject.args.results;
    this.autoCompletePage = 0;
    // Clean up
    this.clearAndPopulateAutoComplete();
    // Position the popup to be below the command line
    const cursor = this.$('.cursor');
    const container = this.$('.app-consoleInput');
    const autocomplete = this.$('.app-autoComplete');
    const offset = FEATURE.NEW_UX ? -5 : 40;

    let cursorTop = cursor.offset().top - container.offset().top + offset;
    let cursorLeft = cursor.offset().left + 20;
    if ((autocomplete.height() + cursorTop) > container.height()) {
      cursorTop = cursorTop - (autocomplete.height() + cursorTop - container.height() - offset);
    }

    // Set the position
    autocomplete.css({
      top: `${cursorTop}px`,
      left: `${cursorLeft}px`,
    }).fadeIn(100).focus();
    autocomplete.keypress(this.keyDown.bind(this));
  },

  clearAndPopulateAutoComplete() {
    const self = this;
    const options = this.autoCompleteOptions;
    let page = this.autoCompletePage;

    this.$('.app-autoComplete li').remove();
    this.$('.rsh-console-autocomplete-highlight-entry').remove();

    const elements = self.$('.app-autoCompleteEntries');
    // Re-populate
    const commandResultLength = options.length;
    let displayCommandResultLength = options.length;
    let moreCommands = '';
    const maxCommands = 10;
    let startCommand = (maxCommands * this.autoCompletePage);
    if (commandResultLength > maxCommands) {
      if (startCommand > displayCommandResultLength) {
        // wrap round to zero if page goes above number of items
        page = 0;
        this.autoCompletePage = 0;
        startCommand = 0;
      }
      displayCommandResultLength = Math.min(displayCommandResultLength, (page * maxCommands) + maxCommands);
      const pages = Math.ceil(commandResultLength / maxCommands);
      const prevButton = (page > 0) ? 'rsh-autocomplete-prev-btn' : 'rsh-autocomplete-prev-btn-disable';
      const nextButton = (page + 1 < pages) ? 'rsh-autocomplete-next-btn' : 'rsh-autocomplete-next-btn-disable';
      const context = {
        prevButton, nextButton, pages, page: page + 1,
      };
      moreCommands = HandlebarsTemplates['rtools/autocomplete_pagination'](context);
    } else if (commandResultLength === 0) {
      moreCommands = `<li>${t('rsh.r.console.autocomplete_empty')}</li>`;
    }
    for (let i = startCommand; i < displayCommandResultLength; i += 1) {
      const token = this.terminal.lastAutoCompleteToken;
      const newEntry = `<li data-autocomplete='${token}' class='app-autoCompleteEntry'>${options[i]}</li>`;
      elements.append(newEntry);
    }
    elements.append(moreCommands);
    // Select first item in the list
    const firstItem = this.$('.app-autoCompleteEntries li:first');
    if (firstItem.hasClass('app-autoCompleteEntry')) {
      firstItem.addClass('rsh-console-autocomplete-highlight-entry');
    }
    // Handle mouse selection
    this.$('.app-autoCompleteEntries .app-autoCompleteEntry').each(function createMouseOver() {
      $(this).on('mouseover', function mouseOver() {
        self.$('.app-autoCompleteEntries li').removeClass('rsh-console-autocomplete-highlight-entry');
        $(this).addClass('rsh-console-autocomplete-highlight-entry');
      });
    });
  },

  // Here we handle the user selecting something from the autocomplete list, replacing the incomplete command
  // (if matched) with the full command.
  consoleAutoCompleteClick(e) {
    this.$('.rsh-console-autocomplete-highlight-entry').removeClass('rsh-console-autocomplete-highlight-entry');
    $(e.target).addClass('rsh-console-autocomplete-highlight-entry');
    const currentCommandLine = this.terminal.get_command();
    const autoCompleteToken = this.terminal.lastAutoCompleteToken.replace(/^('|\(|=| |,)/, '');
    const tokenLocation = currentCommandLine.lastIndexOf(autoCompleteToken);
    let newCommandLine = '';
    if (tokenLocation === 0 && (autoCompleteToken !== currentCommandLine || currentCommandLine.includes('('))) {
      newCommandLine = currentCommandLine;
    } else {
      newCommandLine = currentCommandLine.substr(0, tokenLocation);
    }
    newCommandLine += $(e.target).text();
    this.terminal.enable().set_command(newCommandLine).focus();
    this.$('.app-autoComplete').hide();
  },

  // Here we handle the user selecting something from the autocomplete list, replacing the incomplete command
  // (if matched) with the full command.
  consoleAutoCompletePrev() {
    this.autoCompletePage -= 1;
    this.clearAndPopulateAutoComplete();
  },

  consoleAutoCompleteNext() {
    this.autoCompletePage += 1;
    this.clearAndPopulateAutoComplete();
  },

  consoleAutoCompleteKeyPress(selected) {
    if ($(selected).hasClass('app-autoCompleteEntry')) {
      const currentCommandLine = this.terminal.get_command();
      const autoCompleteToken = this.terminal.lastAutoCompleteToken.replace(/^('|\(|=| |,)/, '');
      const tokenLocation = currentCommandLine.lastIndexOf(autoCompleteToken);
      let newCommandLine = '';
      if (tokenLocation === 0 && (autoCompleteToken !== currentCommandLine || currentCommandLine.includes('('))) {
        newCommandLine = currentCommandLine;
      } else {
        newCommandLine = currentCommandLine.substr(0, tokenLocation);
      }
      newCommandLine += $(selected).text();
      this.terminal.enable().set_command(newCommandLine).focus();
    } else {
      this.terminal.enable().focus();
    }
  },

  // Global resize events are triggered from chorus.js - here we handle resizing of the Console
  // so that it expands to fill available vertical space
  consoleResize() {
    if (this.terminal && this.isActive()) {
      // Now lets relocated the terminal elements
      const sidebarWidth = 300; // 290 + a spacer
      const footerHeight = 12;
      const pageHeight = document.body.offsetHeight;
      const pageWidth = document.body.offsetWidth;
      const infoBar = this.$('.app-panelConsoleFooter');
      const infoBarHeight = infoBar.height();
      const consoleTerminal = this.$('.terminal');
      const consoleOffset = consoleTerminal.offset();
      const consoleTop = consoleOffset ? consoleOffset.top : 0;
      const consoleWidth = pageWidth - sidebarWidth;
      const consoleHeight = pageHeight - (consoleTop + infoBarHeight + footerHeight);
      // Set locations
      consoleTerminal.height(consoleHeight - 4);
      consoleTerminal.css({ minHeight: consoleHeight });
      consoleTerminal.css({ width: `${consoleWidth}px` });
      infoBar.css({ width: `${consoleWidth}px` });
      // The jScrollPane plugin intercepts keypresses. This only becomes an issue if
      // the terminal is blurred and refocused - our terminal no longer recieves up/down
      // keypresses. To avoid this, we now have to unbind keyhandling from jScrollPane
      // in turn allowing the terminal to do its thing... -- Sheru
      this.$('.app-consoleInput').unbind('keydown keypress');
    }
  },

  delay(ms) {
    return new Promise((resolve) => {
      this.timeouts.push(setTimeout(resolve, ms));
    });
  },
});
