import { Item } from 'semantic-ui-react';
import useWorkspaceUser, { getUserName } from '../../adapters/user';
import { UserAvatar } from '../avatars/Avatars';

const UserInfo = () => {
  const { user } = useWorkspaceUser();
  const userName = user && getUserName(user);

  return (
    <Item data-testid="user-info" id="aridhia-user-info">
      <Item.Content>
        <Item.Header as="h5" className="margin-none">
          {userName}
        </Item.Header>
        <Item.Meta as="h6" id="aridhia-user-info-email" className="truncate">
          {user?.email.toUpperCase()}
        </Item.Meta>
      </Item.Content>
      {userName && <UserAvatar userName={userName} className="margin-left-small" />}
    </Item>
  );
};

export default UserInfo;
