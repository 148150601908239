import chorus from '../../chorus';
import t from '../../intl';
import $ from '../../jquery';
import _ from '../../underscore';
import Dialog from '../../dialogs/dialogs';
import Workfile from '../../models/workfile';
import { workspace } from '../../utilities/workspace_assoc';
import DialogFormHelpers from '../../mixins/dialog_form_helpers';
import Routing from '../../mixins/routing';

/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true }] */

export default Dialog.include(DialogFormHelpers, Routing).extend({
  constructorName: 'WorkfileNew',

  templateName: 'workfile_new',
  title: t('workfiles.new_dialog.title'),

  persistent: true,
  typingAutoDetection: true,

  events: {
    'change .filetype': 'changeFileType',
  },

  makeModel(options) {
    if (options && options.pageModel) {
      this.pageModel = options.pageModel;
    }
    this.model = this.model || this.pageModel || new Workfile({
      workspace: { id: this.options.workspaceId },
    });
  },

  setup(options) {
    this.folder = options.folder;
    this.defaultFileType = options.defaultFileType ? options.defaultFileType : 'r';
    this.selectFileType = true;
    if (typeof options.selectFileType !== 'undefined') { this.selectFileType = options.selectFileType; }
    this.listenTo(this.resource, 'saved', this.saved);
    this.listenTo(this.resource, 'saveFailed', this.saveFailed);
    this.disableFormUnlessValid({
      formSelector: 'form',
      inputSelector: 'input[name=fileName]',
    });
  },

  additionalContext() {
    return { 
      fileName: this.model.get('fileName') || '',
      selectFileType: this.selectFileType,
      folders: this.folderOptions(),
      defaultFileType: this.defaultFileType
    };
  },

  folderOptions() {
    const topFolders = workspace(this.model).workfileFolders();
    let selected = topFolders[0];

    if (this.folder) {
      selected = this.folder.name();
    }

    const folders = _.map(topFolders, folder => ({ data: folder, value: folder, selected: folder === selected }));

    const folder = _.findWhere(folders, { selected: true });
    if (!folder && !selected.startsWith('inbox') && !selected.startsWith('public inbox')) {
      folders.unshift({ data: t('workfile.current_folder'), value: selected, selected: true });
    }

    return folders;
  },

  postRender() {
    this.$('input[name=fileName]').unbind('textchange').bind('textchange', _.bind(this.changeFileName, this));
    this.$('.app-fileName').attr('placeholder', t('workfile.create_placeholder'));
    this.toggleSubmitDisabled();
    this.updateFileType();
  },

  changeFileType() {
    this.typingAutoDetection = false;
    this.updateFileType();
  },

  updateFileType() {
    let appFileType = `.${this.workfileType()}`;
    let submitText = ` ${appFileType} `;

    if (appFileType === '.') {
      appFileType = '';
      submitText = ' ';
    }

    this.$('.app-fileType').text(appFileType);
    this.$('button.submit').text(t('workfile.button.create') + submitText + t('workfile.button.create_ext'));
  },

  applyFileTypeFromName(fileName) {
    const parts = fileName.split('.');
    let appFileType = parts.length > 1 ?
      parts[parts.length - 1].trim().toLowerCase() :
      '';

    if (appFileType !== '') {
      if (!this.workfileTypes().includes(appFileType)) {
        appFileType = 'oth';
      }
      this.$('select[name=filetype]').val(appFileType);
      this.updateFileType();
    }
  },

  changeFileName() {
    const fileName = this.workfileName();
    if (fileName === '') {
      this.typingAutoDetection = true;
    } else if (this.typingAutoDetection) {
      this.applyFileTypeFromName(fileName);
    }
  },

  workfileType() {
    if (!this.selectFileType) { return '' };
    const appFileType = this.$('select[name=filetype]').val().trim();
    return appFileType === 'oth' ? '' : appFileType;
  },

  workfileTypes() {
    return _.toArray(this.$('select[name=filetype] option').map(function optionValue() {
      return $(this).val();
    }));
  },

  workfileName() {
    return this.$('input[name=fileName]').val().trim();
  },

  create() {
    const appFileType = this.workfileType();
    let fileName = this.workfileName();
    const folder = this.$('select[name=folder]').val().trim();

    if (appFileType === '' && fileName === '.') fileName = '. '; // trigger validation error by adding a space

    if (appFileType && new RegExp(`\\.${appFileType}$`, 'i').test(fileName)) {
      fileName = fileName.substring(0, (fileName.length - (appFileType.length + 1)));
    }

    let parts = [fileName, appFileType];
    parts = parts.filter(n => n !== '');

    this.resource.set({
      fileName: parts.join('.'),
      folder,
      content: this.options.content || '',
    });

    this.$('button.submit').startLoading('actions.adding');
    this.resource.save({ source: 'empty' });
  },

  saved() {
    this.model.results = this.options.results;
    this.closeModal();
    if (this.options.editInline && typeof this.model.results === 'undefined') {
      this.navigate(this.model.showUrl());
    }

    if (this.options.toast) {
      chorus.toast('workfiles.file.save_succeeded');
    }
  },

  saveFailed() {
    this.$('button.submit').stopLoading();
  },
});
