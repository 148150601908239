import ActivityList from '../activities/activity_list_view';
import View from '../loading_view';

export default View.extend({
  constructorName: 'WorkspaceShowView',
  templateName: 'workspace_show',
  useLoadingSection: true,

  subviews: {
    '.activity_list': 'activityList',
  },

  setup() {
    this.collection = this.model.activities();
    this.collection.fetchIfNotLoaded();
    this.requiredResources.add(this.collection);

    this.activityList = new ActivityList({
      workspace: this.model,
      collection: this.collection,
      additionalClass: 'workspace_detail',
      displayStyle: 'without_workspace',
    });
  },
});
