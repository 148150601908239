import _ from '../../underscore';
import View from '../base_view';

export default View.extend({
  constructorName: 'LoadingSectionView',
  templateName: 'loading_section',

  postRender() {
    _.delay(_.bind(this.showSpinner, this), 125);
  },

  showSpinner() {
    // Here we show the spinner & call startLoading, which actually attaches
    // the spinner element to the template... startLoading() can be found in
    // jquery_extensions.js
    this.$('.app-spinner').removeClass('hidden').startLoading();
  },

  makeLoadingSectionView: null,
});

