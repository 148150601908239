import { t } from 'i18n-js';
import { Message, Icon } from 'semantic-ui-react';

const NotFound = () => (
  <Message
    icon
    negative
    className="margin-small"
    data-testid="not-found-message"
  >
    <Icon name="times circle" data-testid="not-found-icon" />
    <Message.Content>
      <Message.Header as="h4">
        {t('not_found.title')}
      </Message.Header>
      <p role="paragraph">{t('not_found.instruction')}</p>
    </Message.Content>
  </Message>
);

export default NotFound;
