/**
 *  Adding and removing the cookie used for authorising web tunnel
 *
 */
import Cookies from 'js-cookie';
import { TUNNEL_PATH } from '../constants';
import { accessToken } from '../../../../utilities/request';

const COOKIE_NAME = 'workspace_token';
const COOKIE_OPTIONS = { sameSite: 'strict', secure: true, path: TUNNEL_PATH };

export const addAuth = async () => {
  const token = await accessToken();
  Cookies.set(COOKIE_NAME, token, COOKIE_OPTIONS);
};

export const removeAuth = () => {
  Cookies.remove(COOKIE_NAME, COOKIE_OPTIONS);
};
