import $ from '../../jquery';
import _ from '../../underscore';
import View from '../loading_view';

export default View.extend({
  constructorName: 'DatabaseColumnListView',
  tagName: 'ul',
  templateName: 'database_column_list',
  additionalClass: 'selectable list',
  events: {
    'click li': 'selectColumnClick',
  },
  selectMulti: false,

  setup() {
    this.listenTo(this.collection, 'column:removed', this.deselectColumn);
    this.listenTo(this.collection, 'column:deselected', this.deselectColumn);
    this.listenTo(this.collection, 'column:select_all', this.selectAll);
    this.listenTo(this.collection, 'column:select_none', this.selectNone);

    this.datasetMeta = this.collection.attributes.dataset.metadata();
    if (this.collection.attributes.dataset.get('hasMetadata')) {
      this.datasetMeta.fetch();
      this.requiredResources.add(this.datasetMeta);
    }
    this.listenTo(this.datasetMeta, 'change', this.render);
    this.subscribePageEvent('datasetmeta:changed', this.fetchdatasetMetaAndReload);
  },

  postRender() {
    if (!this.selectMulti) {
      this.toggleColumnSelection(this.$('li:eq(0)'));
    }
  },

  fetchdatasetMetaAndReload() {
    this.datasetMeta.fetch();
    this.render();
  },

  collectionModelContext(model) {
    let description = '';
    if (this.datasetMeta && this.datasetMeta.get('columns')) {
      const columnMeta = this.datasetMeta.get('columns')[model.get('name')];
      if (columnMeta) {
        ({ description } = columnMeta);
      }
    }

    return {
      typeClass: model.get('typeClass'),
      typeString: model.get('typeString'),
      description,
    };
  },

  selectNone() {
    if (this.selectMulti) {
      _.each(this.$('li'), function deSelectItem(li) {
        this.toggleColumnSelection($(li), false);
      }, this);
    } else {
      this.toggleColumnSelection(this.$('li:eq(0)'));
    }
  },

  selectAll() {
    _.each(this.$('li'), function selectItem(li) {
      this.toggleColumnSelection($(li), true);
    }, this);
  },

  selectColumnClick(e) {
    this.toggleColumnSelection($(e.target).closest('li'));
  },

  toggleColumnSelection($selectedColumn, forceState) {
    if (this.selectMulti) {
      const turnOn = (arguments.length === 2) ? forceState : !$selectedColumn.is('.selected');
      const column = this.collection.get($selectedColumn.data('cid'));
      if (turnOn) {
        $selectedColumn.addClass('selected');
        this.collection.trigger('column:selected', column);
      } else {
        this.collection.trigger('column:deselected', column);
      }
    } else {
      const $deselected = this.$('li.selected');
      $deselected.removeClass('selected');
      this.collection.trigger('column:deselected', this.collection.at(this.$('li').index($deselected)));

      $selectedColumn.addClass('selected');
      this.collection.trigger('column:selected', this.collection.at(this.$('li').index($selectedColumn)));
    }
  },

  deselectColumn(model) {
    if (this.selectMulti) {
      this.$('li').eq(this.collection.indexOf(model)).removeClass('selected');
    }
  },
});
