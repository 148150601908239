const BOOLEAN_REG = /^(?:(1|y(?:es)?|t(?:rue)?)|(0|n(?:o)?|f(?:alse)?))$/i;
const BOOLEAN_TRUTHY_REG = /^(?:(1|y(?:es)?|t(?:rue)?))$/i;
const INTEGER_REG = /^-?\d+$/;
const DECIMAL_REG = /^(-?\d+(\.\d+)?(e[+\-]?\d+)?|NaN)$/;
const DATETIME_REG = /^(\d{4})-(\d{2})-(\d{2})[ T](\d{2}):(\d{2}):(\d{2})?(\.\d{1,6})?$/;
const DATETIME_TZ_REG = /^(\d{4})-(\d{2})-(\d{2})[ T](\d{2}):(\d{2}):(\d{2})(\.\d{1,6})?[+\-](\d{2})(:(\d{2}))?$/;
const DATE_REG = /^(\d{4})-(\d{2})-(\d{2})$/;
const TIME_REG = /^(\d{2}):(\d{2}):(\d{2})?(\.\d{1,6})?$/;
const TIME_TZ_REG = /^(\d{2}):(\d{2}):(\d{2})?(\.\d{1,6})?[+\-](\d{2})(:(\d{2}))?$/;

export const BOOLEAN_TRUTH_PAIRS = {
  1: '0',
  y: 'n',
  yes: 'no',
  t: 'f',
  true: 'false',
  Y: 'N',
  Yes: 'No',
  YES: 'NO',
  T: 'F',
  True: 'False',
  TRUE: 'FALSE',
};

const BOOLEAN_FALSE_PAIRS = {
  0: '1',
  n: 'y',
  no: 'yes',
  f: 't',
  false: 'true',
  N: 'Y',
  No: 'Yes',
  NO: 'YES',
  F: 'T',
  False: 'True',
  FALSE: 'TRUE',
};

export const validBooleanPair = values => BOOLEAN_TRUTH_PAIRS[values[0]] === values[1];

const RIGHT = { textAlign: 'right' };
const CENTER = { textAlign: 'center' };

export const regExp = (datatype) => {
  switch (datatype) {
    case 'INTEGER':
      return INTEGER_REG;
    case 'DECIMAL':
      return DECIMAL_REG;
    case 'DATE':
      return DATE_REG;
    case 'DATETIME':
      return DATETIME_REG;
    case 'DATETIME_TZ':
      return DATETIME_TZ_REG;
    case 'TIME':
      return TIME_REG;
    case 'TIME_TZ':
      return TIME_TZ_REG;
    default:
      return '';
  }
};

const escapedRegExp = datatype => regExp(datatype).toString().replace(/\//g, '');

export const cellProps = {
  integer: {
    style: RIGHT,
    pattern: escapedRegExp('INTEGER'),
  },
  decimal: {
    style: RIGHT,
    pattern: escapedRegExp('DECIMAL'),
  },
  boolean: {
    style: RIGHT,
    pattern: '(?:(t|f))?',
  },
  'timestamp without time zone': {
    placeholder: 'YYYY-MM-DD HH:MM:SS',
    style: CENTER,
    pattern: escapedRegExp('DATETIME'),
  },
  'timestamp with time zone': {
    placeholder: 'YYYY-MM-DD HH:MM:SS±##:##',
    style: CENTER,
    pattern: escapedRegExp('DATETIME_TZ'),
  },
  date: {
    placeholder: 'YYYY-MM-DD',
    style: CENTER,
    pattern: escapedRegExp('DATE'),
  },
  'time without time zone': {
    placeholder: 'HH:MM:SS',
    style: CENTER,
    pattern: escapedRegExp('TIME'),
  },
  'time with time zone': {
    placeholder: 'HH:MM:SS±##:##',
    style: CENTER,
    pattern: escapedRegExp('TIME_TZ'),
  },
  text: {},
};

const TYPES = [
  {
    dataType: 'boolean',
    test: x => BOOLEAN_REG.test(x),
    postgreSQL: 'boolean',
  },
  {
    dataType: 'integer',
    test: x => INTEGER_REG.test(x),
    postgreSQL: 'integer',
  },
  {
    dataType: 'decimal',
    test: x => DECIMAL_REG.test(x),
    postgreSQL: 'decimal',
  },
  {
    dataType: 'timestamp without time zone',
    test: x => DATETIME_REG.test(x),
    postgreSQL: 'timestamp',
  },
  {
    dataType: 'timestamp with time zone',
    test: x => DATETIME_TZ_REG.test(x),
    postgreSQL: 'timestamptz',
  },
  {
    dataType: 'date',
    test: x => DATE_REG.test(x),
    postgreSQL: 'date',
  },
  {
    dataType: 'time without time zone',
    test: x => TIME_REG.test(x),
    postgreSQL: 'time',
  },
  {
    dataType: 'time with time zone',
    test: x => TIME_TZ_REG.test(x),
    postgreSQL: 'timetz',
  },
  {
    dataType: 'text',
    test: () => true,
    postgreSQL: 'text',
  },
];

const numericSort = (a, b) => {
  if (a === null || a === '') { return (b === null || b === '') ? 0 : 1; }
  if (b === null || b === '') { return -1; }
  const aF = parseFloat(a);
  const bF = parseFloat(b);
  if (aF > bF) { return 1; }
  if (bF > aF) { return -1; }
  return 0;
};

const booleanSort = (a, b) => {
  if (a === null || a === '') { return (b === null || b === '') ? 0 : 1; }
  if (b === null || b === '') { return -1; }
  return (BOOLEAN_TRUTHY_REG.test(a) ? 1 : 0) - (BOOLEAN_TRUTHY_REG.test(b) ? 1 : 0);
};

const textSort = (a, b) => {
  if (a === null) { return (b === null) ? 0 : 1; }
  if (b === null) { return -1; }
  if (a === '') { return (b === '') ? 0 : 1; }
  if (b === '') { return -1; }

  if (a < b) { return -1; }
  if (a === b) { return 0; }
  return 1;
};

export const sortMethod = (datatype) => {
  switch (datatype) {
    case 'integer':
    case 'decimal':
      return (rowA, rowB, colId) => numericSort(rowA.getValue(colId), rowB.getValue(colId));
    case 'boolean':
      return (rowA, rowB, colId) => booleanSort(rowA.getValue(colId), rowB.getValue(colId));
    default:
      return (rowA, rowB, colId) => textSort(rowA.getValue(colId), rowB.getValue(colId));
  }
};

const rangeChecker = rangeCheck => ({ target }) => {
  if (!rangeCheck(target.value)) {
    target.setCustomValidity('Value is out of range');
  } else {
    target.setCustomValidity('');
  }
};

const checkDateComponent = (date) => {
  const year = parseInt(date[0], 10);
  const month = parseInt(date[1], 10);
  const day = parseInt(date[2], 10);

  if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
    return day >= 1 && day <= 31;
  } else if ([4, 6, 9, 11].includes(month)) {
    return day >= 1 && day <= 30;
  } else if (month === 2) {
    if (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)) {
      return day >= 1 && day <= 29;
    }
    return day >= 1 && day <= 28;
  }

  return false;
};

const checkTimeComponent = (time) => {
  const hour = parseInt(time[0], 10);
  const minute = parseInt(time[1], 10);
  const second = parseInt(time[2], 10);

  if ((hour >= 0 && hour <= 23) &&
        (minute >= 0 && minute <= 59) &&
        (second >= 0 && second <= 59)) {
    return true;
  }

  return false;
};

const checkTimeZoneComponent = (value) => {
  const direction = value.slice(0, 1);
  const timezone = value.slice(1).split(':');
  const hour = parseInt(timezone[0], 10);

  const minute = (timezone.length > 1) ? parseInt(timezone[1], 10) : 0;

  if (((direction === '-' && hour >= 0 && hour <= 12) ||
        (direction === '+' && hour >= 0 && hour <= 14)) &&
        (minute >= 0 && minute <= 59)) {
    return true;
  }

  return false;
};

// numbers in javascript are always double precision floats which makes checking for ranges numerically a little
// interesting, so we stick to string comparisons and don't convert anything to a number
const checkInt = (min, max) => rangeChecker((value) => {
  if (value.startsWith('-')) {
    if (value.length === min.length) {
      return value < min; // a string comparison is valid for numbers of the same length
    }
    return value.length < min.length;
  }

  if (value.length === max.length) {
    return value < max; // a string comparison is valid for numbers of the same length
  }
  return value.length < max.length;
});

const checkFloat = range => rangeChecker((value) => {
  const significantDigits = value.replace(/^-/, '').replace(/^0*/, '').replace(/e.*/, '').replace(/\./, '');
  return significantDigits.length <= range;
});

const checkDate = () => rangeChecker((value) => {
  if (value === '' || value === null) { return true; }
  const date = value.split('-');
  return checkDateComponent(date);
});

const checkTime = () => rangeChecker((value) => {
  if (value === '' || value === null) { return true; }
  const time = value.slice(0, 8).split(':');
  return checkTimeComponent(time);
});

const checkTimeTZ = () => rangeChecker((value) => {
  if (value === '' || value === null) { return true; }
  const time = value.slice(0, 8).split(':');
  const timeZoneMatch = value.match(/[-+]/);
  if (timeZoneMatch === null) { return false; }
  const timeZoneIndex = timeZoneMatch.index;
  const timeZone = value.slice(timeZoneIndex);

  return checkTimeComponent(time) && checkTimeZoneComponent(timeZone);
});

const checkDateTime = () => rangeChecker((value) => {
  if (value === '' || value === null) { return true; }
  const date = value.slice(0, 10).split('-');
  const time = value.slice(11, 19).split(':');
  return checkDateComponent(date) && checkTimeComponent(time);
});

const checkDateTimeTZ = () => rangeChecker((value) => {
  if (value === '' || value === null) { return true; }
  const date = value.slice(0, 10).split('-');
  const tempTime = value.slice(11);
  const time = tempTime.slice(0, 8).split(':');
  const timeZoneMatch = tempTime.match(/[-+]/);
  if (timeZoneMatch === null) { return false; }
  const timeZoneIndex = timeZoneMatch.index;
  const timeZone = tempTime.slice(timeZoneIndex);

  return checkDateComponent(date) && checkTimeComponent(time) && checkTimeZoneComponent(timeZone);
});

export const ranges = {
  bigint: checkInt('-9223372036854775808', '9223372036854775807'),
  integer: checkInt('-2147483648', '2147483647'),
  smallint: checkInt('-32768', '32767'),
  real: checkFloat(6),
  'double precision': checkFloat(15),
  date: checkDate(),
  'time without time zone': checkTime(),
  'time with time zone': checkTimeTZ(),
  'timestamp without time zone': checkDateTime(),
  'timestamp with time zone': checkDateTimeTZ(),
};

const booleanValues = (data, col) => {
  const values = new Set();
  for (let row = 0; row < data.length; row += 1) {
    const v = data[row][col];
    if (v !== null && v !== '') {
      values.add(data[row][col]);
      if (values.size > 2) {
        return [];
      }
    }
  }
  if (values.size === 1) {
    const singleBoolean = values.values().next().value;
    values.add(BOOLEAN_TRUTHY_REG.test(singleBoolean) ?
      BOOLEAN_TRUTH_PAIRS[singleBoolean] :
      BOOLEAN_FALSE_PAIRS[singleBoolean]);
  }
  const valuesArray = [...values].sort().reverse();
  return validBooleanPair(valuesArray) ? valuesArray : [];
};

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["columnType"] }] */

export const validTypes = (data, columns) => {
  const typeReducer = (possibleTypes, row) => possibleTypes.map(({ types, nonNull }, i) => ({
    types: (row[i] === null || row[i] === '') ? types : types.filter(x => x.test(row[i])),
    nonNull: nonNull || (row[i] !== ''),
  }));
  const availableTypes = TYPES.slice();
  const initValue = columns.map(() => ({ types: availableTypes, nonNull: false }));
  return data
    .reduce(typeReducer, initValue)
    .map((columnType, i) => {
      if (columnType.nonNull) {
        const first = columnType.types[0];
        if (first && first.dataType === 'boolean') {
          const values = booleanValues(data, i);
          if (values.length === 0) {
            columnType.types.shift();
          } else {
            columnType.types[0] = Object.assign({}, first, { booleanValues: values });
          }
        }
        return columnType.types;
      }
      return availableTypes.slice().reverse();
    });
};

export const guessTypes = (data, columns) => (validTypes(data, columns).map(validTypeList => validTypeList.shift()));
