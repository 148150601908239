import _ from '../underscore';
import $ from '../jquery';
import View from '../views/loading_view';

export default View.extend({
  constructorName: 'PickItemsList',

  templateName: 'pick_items_list',

  events: {
    'click li': 'itemClicked',
  },

  setup() {
    this.options.selectedItemIds = [];
    this.selectedItemModels = [];

    if (this.options.defaultSelection) {
      if (this.options.multiSelection) {
        this.options.selectedItemIds = this.options.defaultSelection.pluck('id');
      } else {
        this.options.selectedItemIds.push(this.options.defaultSelection.id);
      }
      this.options.selectedItemIds = _.map(this.options.selectedItemIds, id => id.toString());
    }
  },

  postRender() {
    this._super('postRender');
  },

  resetSelection() {
    this.options.selectedItemIds = [];
  },

  additionalContext() {
    return {
      emptyListTranslationKey: this.options.emptyListTranslationKey || 'pickitem.dialog.empty',
    };
  },

  itemClicked(e) {
    const selectedItemId = $(e.currentTarget).data('id').toString();
    if (this.options.multiSelection) {
      if (this.isSelected(selectedItemId)) {
        this.options.selectedItemIds.splice(this.options.selectedItemIds.indexOf(selectedItemId), 1);
        this.selectedItemModels = this.selectedItemModels.filter(model => model.get('id') !== selectedItemId);
      } else {
        this.options.selectedItemIds.push(selectedItemId);
        this.selectedItemModels.push(this.collection.get(selectedItemId).clone());
      }
    } else {
      this.options.selectedItemIds = [selectedItemId];
    }

    _.each(this.$('li'), _.bind(function highlightSelected(item) {
      const listItem = $(item);
      if (this.isSelected(listItem.data('id'))) {
        const selectedEntity = this.collection.get(listItem.data('id'));
        if (!(selectedEntity.entityType === 'workfile' && selectedEntity.isFolder())) {
          listItem.addClass('selected');
        }
      } else {
        listItem.removeClass('selected');
      }
    }, this));
    this.trigger('item:selected', this.selectedItem());
  },

  selectedItem() {
    if (this.options.multiSelection) {
      return _.map(this.options.selectedItemIds, _.bind(function selectById(id) {
        return this.collection.get(id);
      }, this));
    }
    return this.collection.get(this.options.selectedItemIds[0]);
  },

  isSelected(model) {
    const id = (model.id || model).toString();
    return _.include(this.options.selectedItemIds, id);
  },

  collectionModelContext(model) {
    const self = this;
    return _.extend(this.baseCollectionModelContext(model), {
      selected: self.isSelected(model) ? 'selected' : '',
    });
  },
});
