import { useState } from 'react';
import {
  Modal, Header, Icon, Message,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import ModalActionButtons from '../../../../../components/buttons/ModalActionButtons';
import HeaderError from '../../../../../components/errors/HeaderError';
import { useMultipleRename } from '../../../service_batch';
import toastMessage from '../../../../../utilities/toast';
import ModalWrapper from '../../../../../components/modals/ModalWrapper';
import BaseFolderSelection from '../../../../../components/navigation/BaseFolderSelection';
import NewFolderSubmodal, { NewFolderSubmodalButton }
  from '../../../../../components/modals/NewFolderSubmodal';
import { baseFolders } from '../../../../../components/navigation/constants';
import FileNavigation from '../../../../../components/navigation/FileNavigation';
import { useInboxContext } from '../../Context';

const InboxMultipleApproveModal = ({ setModalClose, setIsBusy }) => {
  const { selectedFiles, setSelectedFiles } = useInboxContext();
  const [destinationFolder, setDestinationFolder] = useState('files');

  const selectedFilesWithBody = selectedFiles.map((file) => (
    {
      ...file,
      body: {
        workfile: {
          id: file.id,
          file_name: file.name,
          folder: destinationFolder,
          file_type: file.fileType,
        },
      },
    }
  ));

  const {
    renameFiles,
    isLoading,
    isError,
    error,
    resetResponse,
    failedToRename,
    successfulRename,
  } = useMultipleRename(selectedFilesWithBody);

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleSubmitting = () => {
    setIsBusy(true);
    renameFiles(null, {
      onSuccess: () => {
        if (failedToRename.length > 0) {
          const message = failedToRename.length === 1
            ? t('file_list.actions.approve_selected_single_error', { file: failedToRename[0].name })
            : t('file_list.actions.approve_selected_error', { count: failedToRename.length - 1, file: failedToRename[0].name });
          toastMessage('error', message, 'approveError');
        } else {
          const message = successfulRename.length === 1
            ? t('file_list.actions.approve_selected_single_success', { file: successfulRename[0].name })
            : t('file_list.actions.approve_selected_success', { count: successfulRename.length - 1, file: successfulRename[0].name });
          toastMessage('success', message, 'approveSuccess');
        }
        setIsBusy(false);
        handleModalClose();
        setSelectedFiles(failedToRename.length > 0 ? failedToRename : []);
      },
      onError: () => {
        setIsBusy(false);
      },
    });
  };

  const handleSetFolder = (activeFolder) => {
    setDestinationFolder(activeFolder);
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="thumbs up" title={t('file_list.actions.approve')} />
        <Header.Content content={t('file_list.actions.approve')} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <Message
          icon="folder"
          info
          content={t('file_list.actions.approve_info')}
        />
        <BaseFolderSelection
          baseFolders={[baseFolders.FILES, baseFolders.BLOBS]}
          folder={destinationFolder}
          handleSetFolder={handleSetFolder}
        />
        <FileNavigation
          currentFolder={destinationFolder}
          handleSetFolder={handleSetFolder}
        />
      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmitting}
        isValidEntry={!!destinationFolder}
        error={isError}
        submitText={t('file_list.actions.approve')}
      >
        <ModalWrapper
          size="tiny"
          trigger={NewFolderSubmodalButton}
        >
          <NewFolderSubmodal
            destinationFolder={destinationFolder}
            handleSetFolder={handleSetFolder}
          />
        </ModalWrapper>
      </ModalActionButtons>
    </>
  );
};

export default InboxMultipleApproveModal;
