import { useState } from 'react';
import UploadTokenShowView from '../../../../../application/views/upload_token/upload_token_show_view';
import ReactToBackboneWrapper from '../../ReactToBackboneWrapper';

const UploadTokenPanel = ({ tab, workspace }) => {
  const { component, id } = tab;
  const [view] = useState(() => new UploadTokenShowView({ model: workspace }));

  return (
    <ReactToBackboneWrapper
      view={view}
      tabId={id}
      component={component}
    />
  );
};

export default UploadTokenPanel;
