import { t } from 'i18n-js';
import { Form } from 'semantic-ui-react';
import { folderNames } from '../../constants';

const folderDropdownOptions = (baseFolderPath, isFile) => {
  const options = [
    {
      key: 'files',
      text: t('workfile.files_folder'),
      value: 'files',
    },
  ];

  if (isFile) {
    options.push({
      key: 'blobs',
      text: t('workfile.blobs_folder'),
      value: 'blobs',
    });
  }

  if (baseFolderPath && !baseFolderPath.match(folderNames)) {
    options.unshift({
      key: baseFolderPath,
      text: t('workfile.current_folder'),
      value: baseFolderPath,
    });
  }

  return options;
};

const FolderLocationDropdown = ({
  baseFolderPath,
  handleDropdownChange,
  value,
  isFile = false,
}) => (
  <Form.Select
    className="modal-dropdown"
    fluid
    label={t('modals.new_workfile.list')}
    options={folderDropdownOptions(baseFolderPath, isFile)}
    onChange={handleDropdownChange}
    value={value}
  />
);

export default FolderLocationDropdown;
