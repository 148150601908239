import $ from '../jquery';
import ActivitySet from './activity_set';
import Notification from '../models/notification';
import Collection from './collections';

export default Collection.extend({
  constructorName: 'NotificationSet',
  model: Notification,
  urlTemplate: 'api/workspaces/notifications',

  urlParams() {
    return this.attributes;
  },

  modelAdded(model) {
    if (this.attributes.type === 'unread') {
      model.set({ unread: true }, { silent: true });
    }
  },

  activities() {
    const models = this.models.map(model => model.activity());

    const activities = new ActivitySet(models);
    activities.loaded = true;
    return activities;
  },

  markAllRead(options) {
    if (this.length > 0) {
      $.ajax({
        type: 'PUT',
        url: 'api/workspaces/notifications/read',
        success: options.success,
        data: {
          notification_ids: this.pluck('id'),
        },
      });
    } else {
      options.success && options.success();
    }
  },
});
