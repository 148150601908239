import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import WorkfileSidebar from './workfile_sidebar';
import WorkfileHeader from './workfile_header_view';
import SubNav from '../core/sub_nav_view';
import SubNavTools from '../core/sub_nav_tools_view';
import MainContentView from '../core/main_content_view';
import TextWorkfileContent from './text_workfile_content_view';
import SqlWorkfileContent from './sql_workfile_content_view';
import RWorkfileContent from './r_workfile_content_view';
import PdfWorkfileContent from './pdf_workfile_content_view';
import ImageWorkfileContent from './image_workfile_content_view';
import WorkfileContent from './workfile_content_view';
import MarkdownWorkfileContent from './markdown_workfile_content_view';
import RWorkfileContentDetails from './r_workfile_content_details_view';
import ArchivedWorkfileContentDetails from './archived_workfile_content_details';
import PartialWorkfileContentDetails from './partial_workfile_content_details';
import BinaryWorkfileContentDetails from './binary_workfile_content_details';
import PdfWorkfileContentDetails from './pdf_workfile_content_details';
import SqlWorkfileContentDetails from './sql_workfile_content_details_view';
import MarkdownWorkfileContentDetails from './markdown_workfile_content_details';
import WorkfileContentDetails from './workfile_content_details_view';
import ReadOnlyTextContent from './read_only_text_content_view';
import WorkfileDraft from '../../alerts/workfile_draft_alert';
import { refreshVersionInfo, fileVersions } from '../../utilities/file';
import { workspace } from '../../utilities/workspace_assoc';
import { instance } from '../../models/config';
import View from '../loading_view';
import FetchErrors from '../../mixins/fetch_errors';

const fileContent = (model, ref, tab, action) => {
  if (action === 'text' || model.get('fileName') === '.version') {
    return new TextWorkfileContent({ model, ref, tab });
  }
  const pdfRenderer = instance.get('pdfRendererWorkfile') || 'none';
  if (model.isPdf() && pdfRenderer !== 'none') {
    return new PdfWorkfileContent({ model });
  }
  if (model.isImage()) {
    return new ImageWorkfileContent({ model });
  }
  if (model.isPartialFile()) {
    return new ReadOnlyTextContent({ model });
  }
  if (model.isR()) {
    return new RWorkfileContent({ model, tab });
  }
  if (model.isSql()) {
    return new SqlWorkfileContent({ model, tab, action });
  }
  if (model.isMd()) {
    return new MarkdownWorkfileContent({ model });
  }
  if (model.isText()) {
    return new TextWorkfileContent({ model, ref, tab });
  }
  return new WorkfileContent({ model });
};

const fileContents = (model, contentView, action) => {
  if (action === 'text' || model.get('fileName') === '.version') {
    return new WorkfileContentDetails({ model, contentView });
  }
  const pdfRenderer = instance.get('pdfRendererWorkfile') || 'none';
  if (model.isPdf() && pdfRenderer !== 'none') {
    return new PdfWorkfileContentDetails({ model });
  }
  if (model.isImage()) {
    return null;
  }
  if (model.isPartialFile()) {
    return new PartialWorkfileContentDetails({ model });
  }
  if (model.isR()) {
    if (workspace(model).isArchive()) {
      return new ArchivedWorkfileContentDetails({ model });
    }
    return new RWorkfileContentDetails({ model, contentView });
  }
  if (model.isSql()) {
    if (workspace(model).isArchive()) {
      return new ArchivedWorkfileContentDetails({ model });
    }
    return new SqlWorkfileContentDetails({ model, contentView });
  }
  if (model.isBinary()) {
    return new BinaryWorkfileContentDetails({ model });
  }
  if (model.isMd()) {
    return new MarkdownWorkfileContentDetails({ model });
  }
  return new WorkfileContentDetails({ model, contentView });
};

export default View.include(FetchErrors).extend({
  helpId: 'workfile',
  subviews: {
    '.app-content': 'mainContent',
    '.app-sidebarBasic': 'sidebar',
  },
  bindCallbacks: $.noop,

  setup(options) {
    const workspaceId = options.workspace.id;
    const { versionId } = options;

    this.tab = options.tab;
    this.action = options.action;

    this.workspaceId = workspaceId;
    this.className = 'app-workfileShow';
    this.templateName = 'tab_content';
    this.model = options.workfile.clone();

    this.handleFetchErrorsFor(this.model);

    this.model.setVersion(versionId, false);
    workspace(this.model).fetchIfNotLoaded();
    fileVersions(this.model).fetchIfNotLoaded();

    this.onceLoaded(this.model, () => {
      refreshVersionInfo(this.model, () => {
        this.buildPage();
      });
    });

    this.requiredResources.add(this.model);

    this.requiredResources.add(workspace(this.model));
    this.requiredResources.add(fileVersions(this.model));

    this.listenTo(this.model, 'change:fileName', this.rename);
    this.listenTo(this.model, 'change:versionInfo', this.changeMainDisplayIfRequired);

    this.listenTo(this.model, 'saved', (model) => {
      if (model.get('fileName') === instance.get('workspaceHomepageFilename')) {
        chorus.PageEvents.trigger('homepage:changed', model);
      }
    });

    this.subscribePageEvent('change:fileName', function renderNewName(model) {
      if (this.model.id === model.id) {
        this.model.set('fileName', model.get('fileName'));
      }
    });

    this.subscribePageEvent('change:readOnly', function renderNewState(model) {
      if (this.model.id === model.id) {
        if (model.get('readOnly') !== this.model.get('readOnly')) {
          this.model.set('readOnly', model.get('readOnly'));
        }
      }
    });

    this.subscribePageEvent('workfile:removed_remote', this.remoteFileRemoved);
  },

  setVersion(versionId) {
    this.model.setVersion(versionId, true);
  },


  remoteFileRemoved(fileRemoved) {
    // We need to check if this tab matches the file that has been removed from the server
    if (fileRemoved.id === this.model.id) {
      this.tab.set('fileType', 'removed');
      this.model.set('removed', true);
    }
  },

  rename() {
    const fileName = this.model.get('fileName');
    this.tab.set('label', fileName);
  },

  buildPage() {
    const fileName = this.model.get('fileName');
    const fileType = this.model.get('fileType') || 'other';
    this.tab.set('fileType', fileType);
    this.tab.set('label', fileName);

    if (this.model.isLatestVersion() && this.model.get('hasDraft') && !this.model.isDraft) {
      const alert = new WorkfileDraft({
        model: this.model,
        continueWith: _.bind(this.continueBuildPage, this),
      });
      alert.launchModal();
    } else {
      this.continueBuildPage();
    }
  },

  continueBuildPage() {
    this.workspace = workspace(this.model);
    if (this.workspace.get('id') !== this.workspaceId) {
      this.dependentResourceNotFound();
      return;
    }

    this.buildMainContent();

    this.sidebar = new WorkfileSidebar({
      model: this.model,
      showVersions: true,
      showSchemaTabs: true,
      action: this.action,
    });
    this.subNav = new SubNav({ workspace: this.workspace, tab: 'workfiles' });
    this.subNavTools = new SubNavTools({ workspace: this.workspace });

    this.render();
  },

  buildMainContent() {
    if (this.mainContent) {
      this.mainContent.teardown(true);
      this.mainContent = null;
    }
    const contentView = fileContent(this.model, this.model.id, this.tab, this.action);
    this.mainContent = new MainContentView({
      model: this.model,
      scrollPanelDisable: _.isUndefined(contentView.scrollPanelDisable) ? true : contentView.scrollPanelDisable,
      content: contentView,
      contentHeader: new WorkfileHeader({ model: this.model }),
      contentDetails: fileContents(this.model, contentView, this.action),
    });
    this.isPartial = this.model.isPartialFile();
    // Disable re-render on model change - the sub-views will listen and re-render instead
    this.mainContent.unbindCallbacks();
  },

  changeMainDisplayIfRequired() {
    if (this.mainContent === null || this.isPartial === this.model.isPartialFile()) {
      return;
    }
    this.buildMainContent();
    this.render();
  },
});
