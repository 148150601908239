import { t } from 'i18n-js';
import { Message } from 'semantic-ui-react';

const ErrorMessage = () => (
  <Message
    data-testid="new-database-table-summary-message-error"
    icon="warning"
    header={t('database_tables_new.summary.error.header')}
    content={t('database_tables_new.summary.error.content')}
    negative
    attached="top"
  />
);

const CompleteMessage = () => (
  <Message
    data-testid="new-database-table-summary-message-complete"
    icon="table"
    header={t('database_tables_new.summary.completed.header')}
    content={t('database_tables_new.summary.completed.content')}
    positive
    attached="top"
  />
);

const InProgressMessage = () => (
  <Message
    data-testid="new-database-table-summary-message-loading"
    icon={{ name: 'circle notched', loading: true }}
    header={t('database_tables_new.summary.in_progress.header')}
    content={t('database_tables_new.summary.in_progress.content')}
    info
    attached="top"
  />
);

const DatabaseTableCreationSummaryMessage = ({
  errorStart,
  errorStatus,
  creationComplete,
}) => {
  if (errorStart || errorStatus) return <ErrorMessage />;
  if (creationComplete) return <CompleteMessage />;
  return <InProgressMessage />;
};

export default DatabaseTableCreationSummaryMessage;
