import chorus from '../chorus';
import t from '../intl';
import $ from '../jquery';
import Confirm from './confirm';

export default Confirm.extend({
  constructorName: 'UserEnableAlert',
  additionalClass: 'error',
  persistent: true,
  text: t('users.enable.alert.text'),
  title: t('users.enable.alert.title'),
  ok: t('users.enable.alert.ok'),
  requestedMessage: 'users.enable.alert.message',

  confirmAlert(e) {
    e.preventDefault();
    const changedAttrs = { enabled: true };
    this.model.saveChanges(changedAttrs);
    this.closeModal();
    chorus.toast(this.requestedMessage, $.noop);
  },

  postRender() {
    this._super('postRender'); // eslint-disable-line no-underscore-dangle
    this.$('button.submit').removeClass('btn-danger');
  },
});
