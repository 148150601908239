import chorus from '../chorus';
import t from '../intl';
import ModelDelete from './model_delete_alert';
import Notification from '../models/notification';

export default ModelDelete.extend({
  constructorName: 'NotificationDeleteAlert',

  ok: t('notification.delete.ok'),
  title: t('notification.delete.title'),
  text: t('notification.delete.text'),
  deleteMessage: 'notification.delete.success',

  makeModel(...args) {
    this._super('makeModel', args);
    this.model = new Notification({ id: this.options.activity.get('id') });
  },

  modelDeleted() {
    this._super('modelDeleted');
    chorus.PageEvents.trigger('notification:deleted');
  },
});
