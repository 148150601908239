import _ from '../../../underscore';
import PickItems from '../../pick_items_dialog';
import ManagementUsers from '../../../collections/management/users';

export default PickItems.extend({
  title: 'Add Administrator(s)',
  submitButtonTranslationKey: 'workspaces.admin.create.dialog_submit',
  emptyListTranslationKey: 'workspaces.admin.create.dialog_none',
  searchPlaceholderKey: 'workspaces.admin.create.dialog_search',
  selectedEvent: 'users:selected',
  modelClass: 'Table',
  multiSelection: true,
  returnModels: true,

  makeModel(...args) {
    this._super('makeModel', args);
    this.options = _.extend({
      collection: new ManagementUsers([], {
        page_size: 50,
        status: 'active',
        role: 'Workspace User',
        enabled: true,
      }),
    }, this.options);
  },

  setup() {
    this._super('setup');
    this.paginationView.fetchFirstPage();
  },

  modalClosed(...args) {
    this._super('modalClosed', args);
  },

  collectionModelContext(model) {
    return {
      name: model.name(),
      location: model.get('department'),
      imageUrl: model.fetchImageUrl({ size: 'icon' }),
    };
  },
});
