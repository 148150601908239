import _ from '../../underscore';
import t from '../../intl';
import Dialog from '../../dialogs/dialogs';
import DialogFormHelpers from '../../mixins/dialog_form_helpers';
import Routing from '../../mixins/routing';

export default Dialog.include(DialogFormHelpers, Routing).extend({

  templateName: 'data_table_editor_create_dialog',

  title: t('workspaces.dte.button.create'),

  persistent: true,

  setup() {
    this.disableFormUnlessValid({
      formSelector: 'form',
      inputSelector: 'input[name=dte_name]',
    });
  },

  postRender() {
    _.delay(_.bind(function setFocus() {
      this.$('.app-modal-create-Dataset').focus();
    }, this), 100);
  },

  create() {
    this.$('button.submit').startLoading('actions.adding');

    this.resourceName = this.$('input[name=dte_name]').val().trim();

    this.closeModal();
    const url = `workspaces/${this.options.workspaceId}/datatable/new/${encodeURIComponent(this.resourceName)}`;
    this.navigate(url);
  },
});
