import { useQuery } from '@tanstack/react-query';
import { configUrl } from '../constants/api_urls';
import { getter } from './service';

const useWorkspaceConfig = () => {
  const fetchWorkspace = () => getter(configUrl);
  const { data, isLoading, isError } = useQuery({
    queryKey: ['config'],
    queryFn: fetchWorkspace,
    refetchOnWindowFocus: false,
    retryOnMount: false,
  });

  return {
    config: data?.response,
    isLoading,
    isError,
  };
};

export default useWorkspaceConfig;
