import { useContext, createContext } from 'react';

const DatabaseTableContext = createContext();

export const useDatabaseTableContext = () => useContext(DatabaseTableContext);

export const DatabaseTableProvider = ({ children, table }) => (
  <DatabaseTableContext.Provider value={table}>
    {children}
  </DatabaseTableContext.Provider>
);
