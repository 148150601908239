/* eslint-disable no-param-reassign, camelcase, max-lines */
import { t } from 'i18n-js';
import parse from 'csv-parse/lib/es5/sync';
import { validationFields } from '../../../constants/validation';
import { checkURLIsValid, hasError, validColumnName } from '../../../utilities/validation';
import {
  databaseTableColumn,
  databaseTableParsedContent,
} from './state';
import { columnTypes } from './constants';

const isColumnTypeSupported = (column) => Object.values(columnTypes).includes(column.type);

export const getColumnId = (inputName) => (Number(inputName.replace(/\D/g, '')));

export const addEditMetadataErrors = (table) => {
  const errors = [];
  if (table.metadata.url && !checkURLIsValid(table.metadata.url)) {
    errors.push({ field: validationFields.URL, message: t('field_error.URL') });
  }
  return errors;
};

export const addEditColumnsErrors = (table) => {
  const errors = [];
  let primaryKeySet = false;
  const columnNames = [];
  if (table.columns_rows_mismatch) {
    errors.push({ field: validationFields.COLUMNS_MISMATCH, message: t('field_error.COLUMNS_MISMATCH') });
  }
  if (table.parsed_content.parsing_errors) {
    errors.push({ field: validationFields.PARSING_ERROR, message: t('field_error.PARSING_ERROR') });
  }
  table.columns.forEach((column) => {
    if (!column.name) {
      errors.push({ field: `name-${column.id}`, message: t('field_error.COLUMN_NAME_SHORT') });
    } else if (columnNames[column.name]) {
      errors.push({ field: `name-${column.id}`, message: t('field_error.COLUMN_NAME_DUPLICATE_SHORT') });
    }
    if (!isColumnTypeSupported(column)) {
      errors.push(
        {
          field: `type-${column.id}`,
          message: t('field_error.NON_SUPPORTED_TYPE_SHORT', {
            sourceType: column.type,
          }),
        },
      );
    }
    if (column.primary) {
      primaryKeySet = true;
    }
    columnNames[column.name] = true;
  });
  if (!primaryKeySet) {
    errors.push({ field: validationFields.PRIMARY_KEY, message: t('field_error.PRIMARY_KEY') });
  }
  return errors;
};

export const filterFields = (fields, validationField) => {
  let updatedFields = fields.filter((element) => element.field !== validationField);
  if (validationField === 'primaryKey') {
    updatedFields = updatedFields.map((element) => {
      const updatedElement = element.field.split('-');
      const updatedField = /^name|type$/.test(updatedElement[0]) ? `${updatedElement[0]}-${Number(updatedElement[1]) + 1}` : element.field;
      return ({ field: updatedField, message: element.message });
    });
  }
  return updatedFields;
};

export const fileNameFromPath = (filePath) => (filePath.split('/').pop());

export const tableNameFromFilePath = (filePath) => {
  if (!filePath) return '';
  const fileName = fileNameFromPath(filePath);
  return fileName.split('.')[0].toLowerCase().replace(' ', '_');
};

export const matchingTDFFileFromPath = (filePath) => (filePath.split('/').pop().replace('.csv', '.xml'));

export const pathFromFullName = (fullName) => fullName.substr(0, fullName.lastIndexOf('/'));

export const parseTable = (content, metadata) => {
  const csvParseOpts = {
    delimiter: metadata.delimiter === 'other' ? metadata.other_delimiter : metadata.delimiter,
    hasHeader: metadata.input_has_header,
    types: [],
    quote: metadata.text_qualifier,
    escape: metadata.text_qualifier,
  };

  let tableRows = null;
  let splittedTableHeader = null;
  let splittedTableRows = null;
  let originalRowsLength = null;

  try {
    tableRows = parse(content, csvParseOpts);
    splittedTableRows = tableRows.slice(0, 12);
  } catch (e) {
    tableRows = content.split(/\r?\n/);
    splittedTableRows = tableRows.slice(0, 12).map((row) => row.split(csvParseOpts.delimiter));
  }
  originalRowsLength = tableRows.length;

  if (metadata.input_has_header === 'true') {
    [splittedTableHeader] = splittedTableRows;
    splittedTableRows.shift();
    originalRowsLength -= 1;
  } else {
    splittedTableHeader = [];
  }

  if (splittedTableHeader?.length > 0 && splittedTableRows?.length === 0) {
    const emptyArray = splittedTableHeader.map(() => '');
    splittedTableRows.push(emptyArray);
  }

  splittedTableHeader = splittedTableHeader.map((elem, index) => ({ id: index, name: elem, type: 'text' }));
  return {
    splittedTableHeader,
    splittedTableRows,
    originalRowsLength,
  };
};

export const checkIfEqualLength = (header, rows) => {
  if (header.length > 0) return rows.every((array) => array.length === header.length);
  if (rows.length <= 1) return true;
  return rows.every((array) => array.length === rows[0].length);
};

export const reorderColumnsId = (columns) => {
  columns.forEach((_, columnId) => {
    columns[columnId].id = columnId;
  });
  return columns;
};

export const keysToLowercase = (columns) => {
  columns.forEach((columnObj) => {
    Object.keys(columnObj).forEach((key) => {
      const value = columnObj[key];
      delete columnObj[key];
      columnObj[key.toLowerCase()] = value;
    });
  });
  return columns;
};

export const typesToLowercase = (cols) => cols.map((col) => ({ ...col, type: col.type.toLowerCase() }));

const reorderMetadataColumns = (splittedTableHeader, metadataColumns) => {
  const csvColumnsNames = splittedTableHeader.map((column) => column.name.toLowerCase());
  const metadataColumnsNames = metadataColumns.map((column) => column.name.toLowerCase());
  const equalValues = (csvColumnsNames.length === metadataColumnsNames.length)
    && csvColumnsNames.every((csvColumnName) => metadataColumnsNames.includes(csvColumnName));
  if (equalValues) {
    const reorderedMetadata = [];
    csvColumnsNames.forEach((csvColumnName) => {
      const matchingMetadataColumn = metadataColumns.find((column) => column.name.toLowerCase() === csvColumnName);
      matchingMetadataColumn.name = matchingMetadataColumn.name.toLowerCase();
      reorderedMetadata.push(matchingMetadataColumn);
    });
    return reorderColumnsId(reorderedMetadata);
  }
  return Array.from(splittedTableHeader);
};

export const removeAddedPrimaryColumn = (columns) => columns.filter((item) => item.primary_added !== true);

export const keyAddedByModal = (columns) => columns.some((column) => column.primary_added);

export const keyAddedByModalIsPrimary = (columns) => columns.some((column) => column.primary_added && column.primary);

export const parseContent = (newContent, metadata, addedPrimaryColumn) => {
  let error = null;
  let columnsToDisplay = [];
  const { splittedTableHeader, splittedTableRows, originalRowsLength } = parseTable(newContent, metadata);
  if (splittedTableRows.length > 0) {
    const sourceHeaderColumns = (metadata.columns.length > 0 && metadata.columns.length === splittedTableHeader.length)
      ? reorderMetadataColumns(splittedTableHeader, metadata.columns)
      : splittedTableHeader;
    const rowsAreEqualLength = checkIfEqualLength(sourceHeaderColumns, splittedTableRows);
    if (rowsAreEqualLength) {
      splittedTableRows[0].forEach(() => {
        columnsToDisplay.push(
          databaseTableColumn(
            columnsToDisplay.length,
            sourceHeaderColumns[columnsToDisplay.length]?.name || '',
            sourceHeaderColumns[columnsToDisplay.length]?.type || 'text',
            sourceHeaderColumns[columnsToDisplay.length]?.label || '',
            sourceHeaderColumns[columnsToDisplay.length]?.description || '',
          ),
        );
      });
      if (addedPrimaryColumn) {
        columnsToDisplay.unshift(
          databaseTableColumn(
            columnsToDisplay.length,
            t('database_tables_new.columns.add_primary_key.name'),
            columnTypes.INTEGER,
            t('database_tables_new.columns.add_primary_key.title'),
            t('database_tables_new.columns.add_primary_key.description'),
            true,
            true,
          ),
        );
        columnsToDisplay = reorderColumnsId(columnsToDisplay);
      }
    } else {
      error = { field: validationFields.PARSING_ERROR, message: t('field_error.PARSING_ERROR') };
    }
  }

  return {
    columns: columnsToDisplay,
    splittedTableHeader: !error ? splittedTableHeader : [],
    splittedTableRows: !error ? splittedTableRows : [],
    error,
    originalRowsLength,
  };
};

export const setPrimaryKey = (table) => {
  const { columns } = table;
  columns.unshift(
    databaseTableColumn(
      columns.length,
      t('database_tables_new.columns.add_primary_key.name'),
      columnTypes.INTEGER,
      t('database_tables_new.columns.add_primary_key.title'),
      t('database_tables_new.columns.add_primary_key.description'),
      true,
      true,
    ),
  );
  const updatedColumns = reorderColumnsId(columns);

  return updatedColumns;
};

export const setParsedContent = (content, metadata, addedPrimaryColumn, error) => {
  const updatedErrors = filterFields(error, validationFields.PARSING_ERROR);
  const parsedContent = databaseTableParsedContent();
  let originalRowsLength = 0;
  if (content) {
    const parsedContentObject = parseContent(content, metadata, addedPrimaryColumn);
    originalRowsLength = parsedContentObject.originalRowsLength;
    parsedContent.columns = Array.from(parsedContentObject.columns);
    parsedContent.splitted_table_header = parsedContentObject.splittedTableHeader;
    parsedContent.splitted_table_rows = parsedContentObject.splittedTableRows;
    parsedContent.parsing_errors = Boolean(parsedContentObject.error);
    if (parsedContentObject.error) {
      updatedErrors.push(parsedContentObject.error);
    }
  }

  return {
    parsedContent,
    parsingError: updatedErrors,
    originalRowsLength,
  };
};

export const setUpdatedColumns = (columns, columnId) => {
  const selectedColumnId = getColumnId(columnId);
  const isPrimaryAdded = columns[selectedColumnId].primary_added;
  const filteredColumns = columns.filter((elem) => selectedColumnId !== elem.id);
  const updatedColumns = reorderColumnsId(filteredColumns);

  return {
    updatedColumns,
    isPrimaryAdded,
  };
};

export const setAddColumns = (columns) => {
  const newColumns = Array.from(columns);
  newColumns.push(databaseTableColumn(columns.length));
  return newColumns;
};

export const setColumnsRowsMismatch = (table, updateMetadata, parsedContent) => {
  if (!!table.source_file && !!updateMetadata.columns.length) {
    if (updateMetadata.input_has_header === 'true') {
      return updateMetadata.columns.length !== parsedContent.splitted_table_header.length;
    }
    return updateMetadata.columns.length !== parsedContent.splitted_table_rows[0].length;
  }
  return false;
};

export const setUpdatedMetadataAttributes = (metadata, propertyToChange, value) => ({
  ...metadata,
  other_delimiter: propertyToChange === 'delimiter' && value !== 'other' ? '' : metadata.other_delimiter,
  [propertyToChange]: value,
});

export const setColumnsToDisplay = (table) => {
  if (table.metadata.columns.length) {
    if (table.parsed_content.splitted_table_header.length) {
      return reorderMetadataColumns(table.parsed_content.splitted_table_header, table.metadata.columns);
    }
    return Array.from(table.metadata.columns);
  }
  if (table.parsed_content.splitted_table_header.length) {
    return Array.from(table.parsed_content.splitted_table_header);
  }
  return Array.from(table.columns);
};

export const setErrors = (state) => {
  const { table, current_step } = state;
  let errors;
  switch (current_step) {
    case 2:
      errors = addEditMetadataErrors(table);
      break;
    case 3:
      errors = addEditColumnsErrors(table);
      break;
    default:
      errors = [];
  }

  return errors;
};

export const metadataAttributesMissing = (table) => {
  const requiredMetadataKeys = [
    'has_delimiter',
    'has_columns',
  ];
  return !requiredMetadataKeys.every((k) => table.metadata[k]);
};

export const metadataHasIssues = (state) => (
  !!state.table.metadata.source_metadata_file
  && !hasError(state.validation_errors, validationFields.COLUMNS_MISMATCH)
  && (state.table.metadata.is_empty || metadataAttributesMissing(state.table))
);

export const errorOrWarningClassname = (field, column, currentState) => {
  if (hasError(currentState.validation_errors, `${field}-${column.id}`)) return 'error';
  if (hasError(currentState.warnings, `${field}-${column.id}`) || (column.name && !validColumnName(column.name))) return 'warning';
  return '';
};
