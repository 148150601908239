import { Header, Segment } from 'semantic-ui-react';
import { useWorkspaceContext } from '../../../../adapters/Workspace';
import { useActivities } from '../../../service';
import Activities from '../../../components/Activities';
import LoadingError from '../../../../components/errors/LoadingError';
import { PanelHeader, DefaultPanelHeaderIcon } from '../../../../layout';
import ActivityHeaderButtons from './ActivityHeaderButtons';
import { calculateIsNarrow } from '../../../../helpers/panel_widths';
import { workspaceId } from '../../../../layout/helper';

const Activity = ({ panelWidth }) => {
  const {
    workspaceUuid, workspace,
  } = useWorkspaceContext();
  const entityId = workspaceId();
  const {
    activities, isError, isFetching, refetch,
  } = useActivities(workspaceUuid, 'workspace', entityId);

  const isNarrow = calculateIsNarrow(panelWidth);

  return (
    <Segment.Group className="aridhia-panel-wrapper" data-testid="activity-panel">
      <PanelHeader
        headerIcon={<DefaultPanelHeaderIcon name="calendar check" />}
        headerText={<Header as="h3" className="truncate" content={workspace.name} title={workspace.name} />}
        headerButtons={<ActivityHeaderButtons isFetching={isFetching} refetch={refetch} isNarrow={isNarrow} />}
      />
      <Segment as="article" className="aridhia-panel-content">
        {isError && <LoadingError />}
        <Segment basic>
          <Activities
            entityType="workspace"
            entityId={entityId}
            activities={activities}
          />
        </Segment>
      </Segment>
    </Segment.Group>
  );
};

export default Activity;
