import { useWorkspaceContext, useWorkspaceAdmin } from '../../adapters/Workspace';
import MarkdownRender from '../../components/text_editors/MarkdownRender';
import { isRichText } from '../../utilities/editor';

const HomeDescription = () => {
  const { workspaceUuid } = useWorkspaceContext();
  const { workspace } = useWorkspaceAdmin(workspaceUuid);

  return (
    <MarkdownRender
      value={workspace?.metadata.summary}
      isRichText={isRichText(workspace?.metadata.summary)}
    />
  );
};

export default HomeDescription;
