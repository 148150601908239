import React, { useEffect } from 'react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const mapModelsToProps = (models) => (
  {
    tab: models.tab,
    workspace: models.workspace,
  }
);

const options = {
  debounce: false,
};

const Tracking = ({ tab, workspace }) => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    if (tab) {
      const name = tab.get('name') || tab.get('label');
      const fullName = `${workspace.get('name')}-${name}`;
      trackPageView({
        documentTitle: fullName,
      });
    }
  }, [tab, trackPageView]);

  return null;
};

export default connectBackboneToReact(mapModelsToProps, options)(Tracking);
