import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import TagsInput from '../../views/tags/tags_input_view';
import Dialog from '../../dialogs/dialogs';
import TaggingSet from '../../collections/tagging_set';

export default Dialog.extend({
  constructorName: 'EditTagsDialog',
  templateName: 'edit_tags',
  title: t('edit_tags.title'),
  persistent: true,

  subviews: {
    '.tags_input': 'tagsInput',
  },

  setup() {
    const tags = this.tags();
    this.listenTo(tags, 'add', this.addTag);
    this.listenTo(tags, 'remove', this.removeTag);
    this.listenTo(this.collection, 'updateTagsFailed', this.saveFailed);
    this.tagsInput = new TagsInput({ tags, taggable: this.collection });
  },

  addTag(tag) {
    this.collection.each((model) => {
      model.tags().add(tag);
    });
  },

  removeTag(tag) {
    this.collection.each((model) => {
      const tagToRemove = model.tags().where({ name: tag.name() });
      model.tags().remove(tagToRemove);
    });
  },

  tags() {
    if (!this._tags) {
      let tagNames = this.collection.map(model => _.invoke(model.tags().pluck('name'), 'split', ','));
      tagNames = _.uniq(_.flatten(tagNames));

      const tagsHash = _.map(tagNames, tagName => ({ name: tagName }));

      this._tags = new TaggingSet(tagsHash);
    }
    return this._tags;
  },

  saveFailed(tags) {
    this.showErrors(tags);
    this.tagsInput.$el.addClass('hidden');
  },

  revealed(...args) {
    this._super('revealed', args);
    $('#facebox').css('overflow', 'visible');
    const self = this;
    setTimeout(() => {
      self.tagsInput.focusInput();
    }, 0);
  },
});
