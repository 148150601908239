import { useCallback, useState } from 'react';
import { t } from 'i18n-js';

export const initialState = Object.freeze({
  name: '', value: '', environment_name: null, description: null,
});
const valueValidationError = { content: t('secrets.value_validation') };

const updateState = (element, event, state) => {
  const { value } = event || {};
  // eslint-disable-next-line no-param-reassign
  state[element.target.name] = value;
};
const updateFormState = (newState) => (state) => {
  if (newState.name !== state.name
    || newState.nameError !== state.nameError
    || newState.valueError !== state.valueError
    || newState.remaining !== state.remaining) {
    return newState;
  }
  return state;
};

const MAX_VALUE_LENGTH = 8000;
const validate = ({ name, value }) => {
  const valueError = (value.length > MAX_VALUE_LENGTH) ? valueValidationError : null;
  const complete = (value.length > 0 && value.length <= MAX_VALUE_LENGTH);

  return [{
    name, valueError, remaining: (MAX_VALUE_LENGTH - value.length),
  }, complete];
};

export const useFormState = () => useState({
  name: '', nameError: null, valueError: null, envNameError: null, remaining: MAX_VALUE_LENGTH,
});

export const useOnChange = (mutationParameters, setMutationEnabled, setFormState) => (
  useCallback((element, event) => {
    // update the state that will be used as the parameter to the mutation function
    updateState(element, event, mutationParameters.current);

    // validate the updated values and create a form state based on the values
    const [formState, complete] = validate(mutationParameters.current);

    // update any state that may need to cause a re-render (e.g. form completion and errors)
    setFormState(updateFormState(formState));
    setMutationEnabled(complete && element.target.form.checkValidity());
  }, [mutationParameters, setMutationEnabled, setFormState])
);

export const useEditFormState = () => useState({ remaining: MAX_VALUE_LENGTH });

const validateEdit = (currentValues, newValues) => {
  let complete = ['description', 'environment_name'].some(
    (prop) => (currentValues[prop] !== newValues[prop]),
  );
  const valueLength = newValues.value.length;
  const valueError = valueLength > MAX_VALUE_LENGTH;
  complete = complete || valueLength > 0;
  complete = complete && !valueError;
  return [{ valueError, remaining: (MAX_VALUE_LENGTH - valueLength) }, complete];
};

export const useOnEditChange = (currentValues, mutationParameters, setMutationEnabled, setFormState) => (
  useCallback((element, event) => {
    // update the state that will be used as the parameter to the mutation function
    updateState(element, event, mutationParameters.current);

    // validate the updated values and create a form state based on the values
    const [formState, complete] = validateEdit(currentValues, mutationParameters.current);

    // update any state that may need to cause a re-render (e.g. form completion and errors)
    setFormState(formState);
    setMutationEnabled(complete && element.target.form.checkValidity());
  }, [currentValues, mutationParameters, setMutationEnabled, setFormState])
);

export const convertNameToValidEnv = (name) => (name.replaceAll('-', '_').replace(/^(\d)/, '_$1'));
