import { baseFolders } from './constants';

export const getHeaderIcon = (folder) => {
  let headerIcon;

  switch (folder) {
    case baseFolders.FILES:
      headerIcon = 'file alternate';
      break;
    case baseFolders.BLOBS:
      headerIcon = 'file';
      break;
    case baseFolders.DB_TABLES:
      headerIcon = 'database';
      break;
    default:
      headerIcon = 'file';
  }
  return headerIcon;
};

export default getHeaderIcon;
