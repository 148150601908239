import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import ImageUpload from '../../views/core/image_upload_view';
import Dialog from '../../dialogs/dialogs';
import ClEditor from '../../mixins/cl_editor_helpers';

export default Dialog.include(ClEditor).extend({
  constructorName: 'EditWorkspace',

  templateName: 'edit_workspace_dialog',
  title: t('workspace.settings.title'),
  persistent: true,

  events: {
    'submit form': 'updateWorkspace',
    'click .submit': 'updateWorkspace',
    'mousedown .dialog_header': 'closeDropdown',
  },

  additionalContext() {
    const owner = this.model.owner();
    const archive = this.model.canArchive();
    const meta = this.model.hasPermission('Workspace.WriteMeta');
    const status = this.managementWorkspace.get('status');
    const metadata = this.managementWorkspace.get('metadata');

    return {
      hasImage: this.model.hasImage(),
      imageUrl: this.model.fetchImageUrl(),
      members: this.model.members().models,
      canUpdate: meta && (status === 'active'),
      ownerName: owner.displayName(),
      ownerUrl: owner.showUrl(),
      settingsLocked: !meta,
      archiveLocked: !archive,
      isArchive: this.model.isArchive(),
      summary: metadata ? metadata.summary : '',
      statusPending: status === 'pending',
      statusError: status === 'error',
    };
  },

  setup(options) {
    this.imageUpload = new ImageUpload({
      model: this.model,
      addImageKey: 'workspace.settings.image.add',
      changeImageKey: 'workspace.settings.image.change',
      removeImageKey: 'workspace.settings.image.remove',
      spinnerSmall: true,
      editable: this.model.hasPermission('Workspace.WriteMeta'),
    });

    this.managementWorkspace = options.managementWorkspace;

    $(document).one('reveal.facebox', _.bind(this.setupSelects, this));
  },

  setupSelects() {
    chorus.styleSelect(this.$('select.owner'));
  },

  closeDropdown() {
    chorus.styleSelect(this.$('select.owner'));
  },

  subviews: {
    '.edit_photo': 'imageUpload',
  },

  postRender() {
    let canUpdateName = false;
    if (this.model.workspaceAdmin()) {
      canUpdateName = true;
    } else if (this.model.canUpdate()) {
      this.$('input[name=status]').attr('disabled', 'disabled');
      canUpdateName = true;
    } else {
      this.$('input[name=name], textarea[name=summary], input[name=status]').attr('disabled', 'disabled');
    }

    this.$('select.owner').val(this.model.owner().get('id'));

    _.defer(_.bind(function buildEditor() {
      const clEditor = this.makeEditor($(this.el), '.toolbar', 'summary');
      if (!canUpdateName) {
        clEditor.disable(true);
      }
    }, this));
  },

  updateWorkspace(e) {
    e.preventDefault();

    this.$('button.submit').startLoading('actions.saving');
    this.managementWorkspace.updateSummary(
      this.getNormalizedText(this.$('textarea[name=summary]')),
      this.saved.bind(this), this.saveFailed.bind(this),
    );
  },

  saved() {
    this.model.set({ summary: this.getNormalizedText(this.$('textarea[name=summary]')) }, { silent: true });
    chorus.PageEvents.trigger('workspace:edited');
    this.closeModal();
  },

  saveFailed() {
    this.$('button.submit').stopLoading();
    this.closeModal();
    chorus.toast('shuttle.save.workspace_error', { toastOpts: { theme: 'bad_activity' } });
  },
});
