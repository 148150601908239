import { useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { useFiles } from '../../files/panels/service';
import { orderTypes, orderDirections } from '../../files/constants';
import FileNavigationTable from './FileNavigationTable';

const FileNavigation = ({
  currentFolder, handleSetFolder, handleSelectFile, selectedFile,
}) => {
  const [page, setPage] = useState(1);
  const [filterTerm, setFilterTerm] = useState('');

  const handleFilterChange = (value) => {
    setFilterTerm(value);
    setPage(1);
  };

  const {
    files,
    pagination,
    isFetching,
    isError,
    refetch,
  } = useFiles(currentFolder, page, filterTerm, orderTypes.FILE_NAME, orderDirections.ASC, '');

  const handleRefreshButton = () => {
    refetch();
    setPage(1);
  };

  return (
    <Segment as="article" className="aridhia-table-wrapper-modal">
      <FileNavigationTable
        files={files}
        pagination={pagination}
        isFetching={isFetching}
        handleRefreshButton={handleRefreshButton}
        isError={isError}
        handleFilterChange={handleFilterChange}
        setPage={setPage}
        handleSetFolder={handleSetFolder}
        handleSelectFile={handleSelectFile}
        currentFolder={currentFolder}
        selectedFile={selectedFile}
      />
    </Segment>
  );
};

const FileNavigationWrapper = ({
  currentFolder, handleSetFolder, handleSelectFile, selectedFile = null,
}) => (
  <FileNavigation
    key={currentFolder}
    currentFolder={currentFolder}
    handleSetFolder={handleSetFolder}
    handleSelectFile={handleSelectFile}
    selectedFile={selectedFile}
  />
);

export default FileNavigationWrapper;
