import $ from '../../jquery';
import TagsInput from './tags_input_view';
import View from '../loading_view';
import Routing from '../../mixins/routing';

export default View.include(Routing).extend({
  templateName: 'tag_box',
  constructorName: 'TagBoxView',
  subviews: { '.tags_input': 'tagsInput' },
  bindCallbacks: $.noop,

  setup() {
    this.requiredResources.add(this.model);
    this.tags = this.model.tags();
    this.tagsInput = new TagsInput({ tags: this.tags, taggable: this.model });
    this.listenTo(this.tagsInput, 'tag:click', this.navigateToTagShowPage);
  },

  navigateToTagShowPage(tag) {
    // this ensures url fragment has an initial slash in browser address bar
    const url = tag.showUrl(this.options.workspaceIdForTagLink).replace('#', '#/');
    this.navigate(url);
  },

  resourcesLoaded() {
    this.tags.reset(this.model.get('tags'));
  },
});
