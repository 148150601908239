import { useQuery } from '@tanstack/react-query';
import { workspaceAuditUrl, getWorkspaceAuditUrl } from '../constants/api_urls';
import { poster, getter } from '../adapters/service';
import { useWorkspaceAdmin, useWorkspaceContext } from '../adapters/Workspace';
import { idToken } from '../../application/auth/oauth';

export const useNewAudit = () => {
  const postAudit = (body) => poster(workspaceAuditUrl(), body);

  return postAudit;
};

export const useAudit = (page, sort, sortDir, filteredColumns, filteredValues) => {
  const { workspaceUuid } = useWorkspaceContext();
  const { workspace } = useWorkspaceAdmin(workspaceUuid);
  const getAuditData = () => getter(getWorkspaceAuditUrl(
    page,
    sort,
    sortDir,
    filteredColumns,
    filteredValues,
  ));
  const {
    data,
    isFetching,
    isError,
    refetch,
  } = useQuery({ queryKey: ['auditData', page, sort, sortDir, filteredColumns, filteredValues], queryFn: getAuditData });
  const membersList = [];
  membersList.push({ value: '', text: 'All Users', key: 'Clear Filter' });
  workspace?.members.forEach((member) => {
    const userName = { value: `${member.uuid}`, text: `${member.first_name} ${member.last_name}`, key: `${member.uuid}` };
    membersList.push(userName);
  });
  const rowsData = [];
  data?.response?.rows.forEach((row) => {
    const userUUID = row[1];
    const user = workspace?.members.find((member) => member.uuid === userUUID);
    let userName = `${user?.first_name} ${user?.last_name}`;
    if (userName === 'undefined undefined') {
      // For when a user declines an invitation
      // eslint-disable-next-line prefer-destructuring
      userName = row[2];
    }
    rowsData.push([row[0], userName, row[2], row[3], row[4], row[5]]);
  });
  return {
    auditColumns: data ? data.response.columns : [],
    auditRows: rowsData || [],
    pagination: data ? data.pagination : {},
    membersList,
    isFetching,
    isError,
    refetch,
  };
};

export const downloadAudit = async (downloadUrl) => {
  const token = await idToken();
  const response = await fetch(downloadUrl, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
    responseType: 'blob',
  });
  if (response.status === 200) {
    const blob = await response.blob();
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, 'audit.csv');
    } else {
      const objectUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('data-bypass', '');
      a.href = objectUrl;
      a.download = 'audit.csv';
      document.body.appendChild(a);
      a.click();
    }
  } else {
    throw Error('Unable to download csv.');
  }
};
