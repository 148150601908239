import $ from '../../jquery';
import DatasetShowView from './dataset_show_view';
import DatasetContentDetails from './dataset_content_details';
import DatasetEditChorusView from './dataset_edit_chorus_view';
import MainContentView from '../core/main_content_view';
import DatasetEditChorusViewSidebar from './dataset_edit_chorus_view_sidebar';

export default DatasetShowView.extend({
  constructorName: 'ChorusViewShowView',

  setup(...args) {
    this._super('setup', args);
    this.listenTo(this.model, 'dataset:cancelEdit', this.drawColumns);
  },

  drawColumns() {
    this.mainContent.contentDetails && this.stopListening(this.mainContent.contentDetails, 'dataset:edit');
    this._super('drawColumns');
    this.listenTo(this.mainContent.contentDetails, 'dataset:edit', this.editChorusView);
  },

  editChorusView() {
    this.stopListening(this.mainContent.contentDetails);
    const sameHeader = this.mainContent.contentHeader;

    if (this.mainContent) {
      this.mainContent.teardown(true);
    }
    this.mainContent = new MainContentView({
      content: new DatasetEditChorusView({ model: this.dataset }),
      contentHeader: sameHeader,
      contentDetails: new DatasetContentDetails({
        dataset: this.dataset,
        collection: this.columnSet,
        inEditChorusView: true,
      }),
    });

    this.renderSubview('mainContent');
  },

  constructSidebarForType(type, ...args) {
    if (type === 'edit_chorus_view') {
      this.secondarySidebar = new DatasetEditChorusViewSidebar({ model: this.model });
    } else {
      this._super('constructSidebarForType', [type, ...args]);
    }
  },

  checkEntityType: $.noop,
});
