import { Dropdown } from 'semantic-ui-react';
import { t } from 'i18n-js';
import {
  workspaceFilesUrl,
  workspaceBlobsUrl,
  workspaceInboxUrl,
  uploadFile,
}
  from '../../constants/ui_urls';
import NewFileLauncher from './NewFileLauncher';
import NewFolderLauncher from './NewFolderLauncher';
import Can from '../../permissions';

const FilesLauncher = () => (
  <Dropdown.Item
    text={(t('workspaces.launcher.view_files'))}
    href={workspaceFilesUrl()}
    icon="file alternate outline"
  />
);

const BlobsLauncher = () => (
  <Dropdown.Item
    text={(t('workspaces.launcher.view_blobs'))}
    href={workspaceBlobsUrl()}
    icon="file outline"
  />
);

const InboxLauncher = () => (
  <Dropdown.Item
    text={(t('workspaces.launcher.inbox'))}
    href={workspaceInboxUrl()}
    icon="box"
  />
);

const UploadFileLauncher = () => (
  <Dropdown.Item
    text={(t('workspaces.launcher.upload_file'))}
    href={uploadFile('files')}
    icon="upload"
  />
);

const FilesLauncherMenu = () => (
  <Dropdown
    aria-label={t('workspaces.launcher.files')}
    icon={{ name: 'file', title: t('workspaces.launcher.files') }}
    text={t('workspaces.launcher.files')}
    item
  >
    <Dropdown.Menu>
      <Can I="Read" this="WorkspaceStorage">
        <FilesLauncher />
        <BlobsLauncher />
      </Can>
      <Can I="View" this="WorkspaceInboundAirlock">
        <InboxLauncher />
      </Can>
      <Can I="Write" this="WorkspaceStorage">
        <NewFileLauncher />
        <NewFolderLauncher />
      </Can>
      <Can I="Upload to" this="WorkspaceStorage">
        <UploadFileLauncher />
      </Can>
    </Dropdown.Menu>
  </Dropdown>
);

export default FilesLauncherMenu;
