import {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react';
import { afterRender } from '../utilities/helper';

let modelChangeListener = () => {};

const createTabList = (model) => {
  const cacheList = new WeakMap();

  return () => {
    if (cacheList.has(model)) {
      return cacheList.get(model);
    }

    const list = [];

    model.visitNodes((node) => {
      if (node.getType() === 'tab') {
        const {
          className, id: key, name: title, config: { config: { url } }, isLegacy,
          // eslint-disable-next-line no-underscore-dangle
        } = node._attributes;

        list.push({
          className, key, selected: node.isVisible(), title, url, isLegacy,
        });
      }
    });

    cacheList.set(model, list);
    return list;
  };
};

const TabListContext = createContext();
export const modelChanged = () => afterRender(modelChangeListener);

export const TabListContextProvider = ({ model, children }) => {
  const [tabList, updateTabList] = useState(createTabList(model)(null));

  useEffect(() => {
    modelChangeListener = () => updateTabList(createTabList(model));

    return () => { modelChangeListener = () => {}; };
  });

  return <TabListContext.Provider value={tabList}>{children}</TabListContext.Provider>;
};

export const useTabContext = () => (useContext(TabListContext));
