import AppsTabs from '../../../../apps/components/AppsTabs';
import ResponsivePanel from '../../ResponsivePanel';

const AppsPanel = () => (
  <ResponsivePanel>
    {(panelWidth) => <AppsTabs panelWidth={panelWidth} />}
  </ResponsivePanel>
);

export default AppsPanel;
