import { lazy } from 'react';

const doLoad = async () => {
  // chorus.page is set by router - leaving setup unchanged for now
  const { page } = window.chorus;
  const { workspace } = page;
  await workspace.fetchIfNotLoaded();
  await workspace.mgmt_workspace().fetchIfNotLoaded();
  return workspace;
};

let loadPromise = null;

const loadLegacy = () => {
  if (loadPromise === null) {
    loadPromise = doLoad();
  }
  return loadPromise;
};

const legacyLoader = (Component) => lazy(async () => {
  const workspace = await loadLegacy();
  // This replaces the workspace object loaded by react-query with the backbone model instead
  return { default: (props) => Component({ ...props, workspace }) };
});

export default legacyLoader;
