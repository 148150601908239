import dynamicDataset from '../../models/dynamic_dataset';
import workfile from '../../models/workfile';
import workspace from '../../models/workspace';
import attachment from '../../models/attachment';
import Collection from '../collections';
import SearchCollection from '../../mixins/search_collection';
import MultiModelSet from '../../mixins/multi_model_set';

const Models = {
  workfile,
  workspace,
  attachment,
};

const buildForType = (modelJson, options) => {
  if (modelJson.entityType === 'dataset') {
    return dynamicDataset(options);
  }
  return new Models[modelJson.entityType](options);
};

export default Collection.include(SearchCollection, MultiModelSet).extend({
  constructorName: 'WorkspaceItemSet',
  searchKey: 'thisWorkspace',
  model(modelJson, options) {
    return buildForType(modelJson, options);
  },
});
