import React from 'react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import {
  Header, Grid, Container, Table, Icon, Form, Checkbox, Button, Menu, Modal, Pagination,
} from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { t } from 'i18n-js';
import TooltipMessage from '../TooltipMessage';

const tokenType = (row) => {
  let type = "";

  if (row.get('publicInbox')) {
    type = t('upload_token.upload_list.table.public_inbox') + " ";
  }
  if (row.get('destination')) {
    type += t('upload_token.upload_list.table.approved_inbox', { destination: row.get('destination') });
  }
  if (row.get('dataUpload')) {
    type += t('upload_token.upload_list.table.data_upload');
  }

  if (type.length === 0) {
    type = t('upload_token.upload_list.table.inbox');
  }
  return type;
}

const createTokenRowFn = (active, deleteToken, tokenCopied) => (row, i) => (
  <Table.Row key={`row-${i}`}>
    <Table.Cell key={`row-${i}-cell-1`} collapsing>
      {active
        && <i className="green check circle icon" />}
      {!active
        && <i className="grey times circle icon" />}
    </Table.Cell>
    <Table.Cell key={`row-${i}-cell-2`} collapsing>
      {row.get('validFrom')}
    </Table.Cell>
    <Table.Cell key={`row-${i}-cell-3`} collapsing>
      {row.get('validTo')}
    </Table.Cell>
    <Table.Cell key={`row-${i}-cell-4`} collapsing>
      {`${row.get('user').firstName} ${row.get('user').lastName}`}
    </Table.Cell>
    <Table.Cell key={`row-${i}-cell-5`} collapsing>
      { tokenType(row) }
    </Table.Cell>
    <Table.Cell key={`row-${i}-cell-6`}>
      {row.get('reason')}
    </Table.Cell>
    <Table.Cell key={`row-${i}-cell-7`} collapsing className="aridhia-tight">
      { active && row.get('token')
        && (
          <TooltipMessage
            text={t('upload_token.upload_list.table.copy')}
            component={(
              <CopyToClipboard text={row.get('token')} onCopy={tokenCopied}>
                <Button compact icon>
                  <Icon name="copy" />
                </Button>
              </CopyToClipboard>
            )}
          />
        )}
      { active && !row.get('token')
        && (
          <Modal
            trigger={<Button compact icon><Icon name="trash alternate outline" /></Button>}
            header={t('upload_token.upload_list.remove')}
            content={t('upload_token.upload_list.remove_text')}
            actions={[t('actions.cancel'), {
              key: `confirm-${i}`, content: t('actions.confirm'), negative: true, onClick: () => { deleteToken({ tokenID: row.get('id'), publicInbox: row.get('publicInbox') }); },
            }]}
            className="confirm"
            size="tiny"
            mountNode={document.querySelector('#modal-wrapper') || document.body}
          />
        )}
      {!active
        && (
          <TooltipMessage
            text={t('upload_token.upload_list.table.inactive')}
            component={(
              <Button compact icon>
                <Icon name="archive" />
              </Button>
            )}
          />
        )}
    </Table.Cell>
  </Table.Row>
);

const UploadTokenList = ({
  uploadTokenList, toggleView, changePage, deleteToken, tokenCopied,
}) => {
  const allTokens = uploadTokenList.get('tokens');
  const active = !uploadTokenList.get('viewArchived');
  const page = uploadTokenList.get('page') ? uploadTokenList.get('page') : 1;
  const perPage = 5;
  const pages = Math.ceil(allTokens.length / perPage);
  const tokens = allTokens.slice((perPage * page) - perPage, (perPage * page));
  const tokenRow = createTokenRowFn(active, deleteToken, tokenCopied);

  return (
    <Container>
      <Grid columns="equal" padded>
        <Grid.Row className="nospacing">
          <Grid.Column className="nospacing">
            <Header as="h2" size="medium">
              {t('upload_token.upload_list.title')}
            </Header>
          </Grid.Column>
          <Grid.Column floated="right" textAlign="right" className="nospacing">
            <Form>
              <Checkbox
                toggle
                id="toggle"
                label={t('upload_token.upload_list.archived')}
                onChange={toggleView}
                checked={!active}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Table celled unstackable compact striped className="aridhia-uploadtokenlist-table">
        <Table.Header>
          <Table.Row key="header-row">
            <Table.HeaderCell key="header-1" />
            <Table.HeaderCell key="header-2">
              {t('upload_token.upload_list.table.valid_from')}
            </Table.HeaderCell>
            <Table.HeaderCell key="header-3">
              {t('upload_token.upload_list.table.valid_to')}
            </Table.HeaderCell>
            <Table.HeaderCell key="header-4">
              {t('upload_token.upload_list.table.created_by')}
            </Table.HeaderCell>
            <Table.HeaderCell key="header-5">
              {t('upload_token.upload_list.table.token_type')}
            </Table.HeaderCell>
            <Table.HeaderCell key="header-6">
              {t('upload_token.upload_list.table.reason')}
            </Table.HeaderCell>
            <Table.HeaderCell key="header-7" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { tokens?.map(tokenRow) }
        </Table.Body>
        { !!tokens.length
        && (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell key="row-footer" colSpan="8">
                <Pagination
                  totalPages={pages}
                  firstItem={null}
                  lastItem={null}
                  siblingRange={1}
                  onPageChange={(_e, value) => { changePage({ page: value.activePage }); }}
                  boundaryRange={1}
                  defaultActivePage={1}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
        {!tokens.length
        && (
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.Cell key="row-footer" collapsing>
                {t('upload_token.upload_list.empty')}
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    </Container>
  );
}

const mapModelsToProps = ({
  uploadTokenList, toggleView, changePage, deleteToken, tokenCopied,
}) => ({
  uploadTokenList,
  toggleView,
  changePage,
  deleteToken,
  tokenCopied,
});

const options = {
  debounce: true,
  events: {
    uploadToken: ['change:tokens'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(UploadTokenList);
