import t from '../intl';
import ModelDelete from './model_delete_alert';
import WorkspaceDataset from '../models/workspace_dataset';
import WorkspaceDatasetSet from '../collections/workspace_dataset_set';
import { workspace } from '../utilities/workspace_assoc';

export default ModelDelete.extend({

  templateName: 'dataset_delete_alert',
  focusSelector: 'button.submit-no-dependencies',

  events: {
    'click button.cancel': 'cancelAlert',
    'click button.submit-no-dependencies': 'deleteModel',
    'click button.submit-dependencies': 'deleteModelAndDependencies',
  },

  bindCallbacks() {
    this.model.bind('destroy', this.modelDeleted, this);
    this.model.bind('destroyFailed', this.destroyFailed, this);
  },

  makeModel() {
    this.model = this.model || new WorkspaceDataset({
      id: this.options.datasetId,
      datasetName: this.options.datasetName,
      isChorusView: this.options.chorusView,
      workspace: { id: this.options.workspaceId },
    });
  },

  deleteModel() {
    this.destroyModel(false);
  },

  deleteModelAndDependencies() {
    this.destroyModel(true);
  },

  setupLabels(){
    let multipleSelected = this.model instanceof WorkspaceDatasetSet;
    
    if (this.model.get('isChorusView')) {
      this.text = t('dataset.chorusview.delete.text', { datasetName: this.model.get('datasetName') });
    } else if(multipleSelected) {
      this.text = t('dataset.delete_multiple.confirm.text');
      this.title = t('dataset.delete_multiple.title');
      this.confirmButton = t('dataset.delete_multiple.confirm.button');
      this.confirmButtonWithDependencies = t('dataset.delete_multiple.confirm.button_with_dependencies');
      this.deleteMessage = 'dataset.delete_multiple.success.notification';
    } else {
      this.text = t('dataset.delete.confirm.text', { datasetName: this.model.get('datasetName') });
      this.title = t('dataset.delete.title');
      this.confirmButton = t('dataset.delete.confirm.button');
      this.confirmButtonWithDependencies = t('dataset.delete.confirm.button_with_dependencies');
      this.deleteMessage = 'dataset.delete.success.notification';
    }
  },

  additionalContext() {
    return {
      ...(this._super('additionalContext')),
      confirmButton: this.confirmButton,
      confirmButtonWithDependencies: this.confirmButtonWithDependencies,
    };
  },

  getRedirectUrl() {
    if (this.model instanceof WorkspaceDatasetSet) {
      return workspace(this.model.models[0]).datasets().showUrl();
    } else {
      return workspace(this.model).datasets().showUrl();
    }
  },

  setup() {
    this.redirectUrl = this.getRedirectUrl();
    this.setupLabels();
  },

  destroyFailed() {
    this.$('button.submit-no-dependencies').stopLoading();
    this.$('button.submit-dependencies').stopLoading();
    this.resource.serverErrors = { fields: { base: { UNDELETABLE_DATASET: {} } } };
    this.showErrors();
  },

  destroyModel(includeDependencies) {
    let button = 'button.submit-no-dependencies';

    if (includeDependencies) {
      button = 'button.submit-dependencies';
    }

    this.$('.errors').addClass('hidden');

    this.model.setIncludeDependencies(includeDependencies);
    this.model.destroy();
    this.$(button).startLoading('actions.deleting');
    
    return false;
  },

  modelDeleted() {
    this.closeModal();
    this.deleteMessage = this.deleteMessage ? this.deleteMessage : 'dataset.delete.success.notification';
    chorus.toast(this.deleteMessage, this.deleteMessageParams());
    chorus.PageEvents.trigger(`dataset:deleted`, this.model);
    this.navigate(this.redirectUrl);
  },
});
