export const hasError = (errors, field) => errors.some((error) => error.field === field);

export const findErrors = (errors, field) => errors.filter((error) => error.field === field);

export const invalidCharacters = '"?:|<>*\\/';

export const invalidCharactersIn = (str) => new RegExp(`[${invalidCharacters}]`).test(str);

export const invalidFileNames = (str) => (
  /^(?:aux|con|clock\$|nul|prn|com[1-9]|lpt[1-9]|\.|\.\.)$/ig.test(str)
);

export const validTableName = (str) => (/^[a-z_][a-z0-9_]{0,63}$/.test(str));

export const validColumnName = (str) => (/^[a-z_][a-z0-9_]{0,63}$|^"[a-zA-Z0-9_ ][^"]{1,61}"$/.test(str));

export const checkURLIsValid = (source) => {
  let url;
  try {
    url = new URL(source);
  } catch (_) {
    return false;
  }
  return !!url;
};

export default checkURLIsValid;
