import { Header, Segment } from 'semantic-ui-react';
import ImageIcon from '../../../../components/ImageIcon';

const OfficeHeader = ({ favIconUrl, fileName }) => (
  <Segment as="header" attached="top" className="aridhia-panel-header">
    <div className="flex justify-space-between align-center gap-width">
      <div>
        <ImageIcon src={favIconUrl} size="large" />
        <Header as="h3" className="truncate">
          <Header.Content>
            {fileName}
          </Header.Content>
        </Header>
      </div>
    </div>
  </Segment>
);

export default OfficeHeader;
