import { t } from 'i18n-js';
import {
  Modal, Icon, Header,
} from 'semantic-ui-react';
import ModalActionButtons from '../../../../../components/buttons/ModalActionButtons';
import HeaderError from '../../../../../components/errors/HeaderError';
import { useFilesContext } from '../../Context';
import { useMultipleDelete } from '../../../service_batch';
import toastMessage from '../../../../../utilities/toast';
import { deleteTab, deleteTabsInFolder } from '../../../../../layout/tabMonitor';
import DeleteModalMessage from '../../../../../components/modals/DeleteModalMessage';

const MultipleDeleteModal = ({ setModalClose, setIsBusy }) => {
  const {
    selectedFiles, setSelectedFiles,
  } = useFilesContext();
  const selectedFilesLength = selectedFiles.length;
  const {
    deleteFiles,
    isLoading,
    isError,
    error,
    resetResponse,
    failedToDelete,
    successfulDelete,
  } = useMultipleDelete(selectedFiles);

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleSubmitting = () => {
    setIsBusy(true);
    deleteFiles(null, {
      onSuccess: () => {
        if (failedToDelete.length > 0) {
          const failedFiles = failedToDelete.map((file) => file.name).join(', ');
          const message = failedToDelete.length === 1
            ? t('file_list.actions.delete_selected_single_error', { file: failedFiles })
            : t('file_list.actions.delete_selected_error', { count: failedToDelete.length, files: failedFiles });
          toastMessage('error', message, 'deleteError');
        } else {
          const successFiles = successfulDelete.map((file) => file.name).join(', ');
          const message = successfulDelete.length === 1
            ? t('file_list.actions.delete_selected_single_success', { file: successFiles })
            : t('file_list.actions.delete_selected_success', { count: successfulDelete.length, files: successFiles });
          toastMessage('success', message, 'deleteSuccess');
        }
        successfulDelete.forEach((file) => {
          deleteTab(`workfiles/${file.id}`);
          if (file.fileType === 'folder') {
            deleteTabsInFolder(`${file.folder}/${file.name}`);
          }
        });
        setIsBusy(false);
        handleModalClose();
        setSelectedFiles(failedToDelete.length > 0 ? failedToDelete : []);
      },
      onError: () => {
        setIsBusy(false);
      },
    });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="trash alternate" title="delete" />
        <Header.Content content={t('file_list.actions.delete_selected')} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <DeleteModalMessage title={t('file_list.actions.delete_selected_sure', { selectedFilesLength })} />
      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmitting}
        isValidEntry
        error={isError}
        submitText={t('file_list.actions.delete')}
        negativeSentiment
      />
    </>
  );
};

export default MultipleDeleteModal;
