// XAP-11226 - Membership list now pulled from Mgmnt API Workspace request
import _ from '../underscore';
import User from '../models/user';
import BulkSaver from '../models/bulk_saver';
import Collection from './collections';

export default Collection.extend({
  constructorName: 'MemberSet',
  model: User,
  urlTemplate: 'api/workspaces/workspaces/{{workspaceId}}/members',
  save() {
    const memberIds = _.pluck(this.models, 'id');
    const memberRoles = _.map(this.models, model => model.get('role'));
    new BulkSaver({ collection: this }).save({ memberIds, memberRoles });
  },
});
