import _ from '../underscore';

export default {
  getRows() {
    const rows = this.get('rows');
    const columns = this.getColumns();

    return _.map(rows, row => _.inject(_.zip(columns, row), (memo, columnValuePair) => {
      const column = columnValuePair[0];
      const value = columnValuePair[1];

      const assignableMemo = memo;
      assignableMemo[column.uniqueName] = value;
      return assignableMemo;
    }, {}));
  },

  getColumns() {
    const columns = this.get('columns');
    _.each(columns, (column, index) => {
      const col = column;
      col.uniqueName = `${col.name}_${index}`;
    });
    return columns;
  },

  getErrors() {
    return this.attributes;
  },

  getColumnLabel(columnName) {
    return columnName;
  },

  getSortedRows(rows) {
    return rows;
  },

  hasResults() {
    return !!this.getRows().length;
  },
};
