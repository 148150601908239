import { t } from 'i18n-js';
import {
  Icon,
  Message,
} from 'semantic-ui-react';

const LoadingError = ({ title, message }) => (
  <Message icon negative className="margin-small" data-testid="loading-error-message">
    <Icon name="exclamation triangle" data-testid="error-message-icon" />
    <Message.Content>
      <Message.Header as="h4">{title || t('workspaces.failed_to_load.title')}</Message.Header>
      <p role="paragraph">{message || t('workspaces.failed_to_load.message')}</p>
    </Message.Content>
  </Message>
);

export default LoadingError;
