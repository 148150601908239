import {
  Segment, Icon, Header, Button,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import RequestVirtualMachineModal from './modals/RequestVirtualMachineModal';
import Can from '../../../../permissions';

const NoVirtualMachines = () => (
  <Segment placeholder>
    <Header as="h4" icon>
      <Icon name="desktop" data-testid="no-virtual-machines-header-icon" />
      {t('virtual_machines.no_desktops')}
    </Header>
    <Can I="Request" a="VirtualMachine">
      <ModalWrapper trigger={<Button icon={{ name: 'plus', 'data-testid': 'no-virtual-machines-button-icon' }} primary content={t('virtual_machines.actions.request')} />}>
        <RequestVirtualMachineModal />
      </ModalWrapper>
    </Can>
  </Segment>
);

export default NoVirtualMachines;
