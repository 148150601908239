import { useState } from 'react';
import { Modal, Header, Icon } from 'semantic-ui-react';
import { t } from 'i18n-js';
import ModalActionButtons from '../../../../../components/buttons/ModalActionButtons';
import BaseFolderSelection from '../../../../../components/navigation/BaseFolderSelection';
import HeaderError from '../../../../../components/errors/HeaderError';
import { baseFolders } from '../../../../../components/navigation/constants';
import FileNavigation from '../../../../../components/navigation/FileNavigation';
import { useDatabaseTableCreationContext } from '../../Context';
import { tableNameFromFilePath, fileNameFromPath } from '../../helper';
import { setTableContentFromCSV } from '../../actions';
import { useFileContent } from '../../service';

const ImportCSVFileModal = ({ setModalClose, setIsBusy }) => {
  const [folder, setFolder] = useState('files');
  const [selectedFile, setSelectedFile] = useState({
    fileId: null,
    tableName: '',
    tablePath: '',
    fileName: '',
    fileSize: 0,
  });
  const { dispatch } = useDatabaseTableCreationContext();
  const includeContent = 'preview';
  const includeMetadata = 'false';

  const useWorkfileContentOptions = {
    refetchOnWindowFocus: false,
    enabled: false,
    cacheTime: 2000,
    onSuccess: (data) => {
      let newContent = '';
      if (data.response.version_info.content) {
        newContent = data.response.version_info.content.replace(/\r?\n$/, '');
      }
      dispatch(setTableContentFromCSV(selectedFile, newContent));
      setIsBusy(false);
      setModalClose();
    },
    onError: () => {
      setIsBusy(false);
    },
  };

  const {
    isError,
    isFetching,
    error,
    refetch,
  } = useFileContent(selectedFile.fileId, includeContent, includeMetadata, useWorkfileContentOptions);

  const handleSelectFile = (item) => {
    const tableName = tableNameFromFilePath(item.fullPath);
    setSelectedFile({
      fileId: item.itemId,
      tableName,
      tablePath: item.fullPath,
      fileName: fileNameFromPath(item.fullPath),
      fileSize: item.size,
    });
  };

  const handleConfirm = () => {
    setIsBusy(true);
    refetch();
  };

  const handleRemove = () => {
    setSelectedFile({
      fileId: null,
      tableName: '',
      tablePath: '',
      fileName: '',
      fileSize: 0,
    });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="file text" />
        <Header.Content content={t('database_tables_new.new_table.from_csv.modal_header')} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={handleRemove}
          />
        )}
      </Header>
      <Modal.Content>
        <BaseFolderSelection
          baseFolders={[baseFolders.FILES, baseFolders.BLOBS]}
          folder={folder}
          handleSetFolder={setFolder}
        />
        <FileNavigation
          currentFolder={folder}
          handleSetFolder={setFolder}
          handleSelectFile={handleSelectFile}
          selectedFile={selectedFile.fileName}
        />
      </Modal.Content>
      <Modal.Actions>
        <ModalActionButtons
          loading={isFetching}
          onClose={setModalClose}
          onSubmit={handleConfirm}
          isValidEntry={!!selectedFile.tableName}
          submitText={t('database_tables_new.new_table.from_csv.action')}
          error={!!error}
        />
      </Modal.Actions>
    </>
  );
};

export default ImportCSVFileModal;
