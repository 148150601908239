import { useCallback, useState } from 'react';
import Measure from 'react-measure';
import { calculateIsNarrow, panelWidths } from '../../helpers/panel_widths';
import { ResponsiveContext, useResponsiveContext } from '../responsive_context';

const ResponsivePanel = ({ narrowWidth = 751, children }) => {
  const [panelWidth, setPanelWidth] = useState(panelWidths.WIDE);

  const resize = useCallback((contentRect) => {
    const width = contentRect.bounds.width > narrowWidth ? panelWidths.WIDE : panelWidths.NARROW;

    // Do not re-render responsive panel when we click on different tab
    if (contentRect.bounds.width > 0) {
      setPanelWidth(width);
    }
  }, [narrowWidth, setPanelWidth]);

  return (
    <Measure bounds onResize={resize}>
      {({ measureRef }) => (
        <div ref={measureRef} className="semantic ux-update">
          {children(panelWidth)}
        </div>
      )}
    </Measure>
  );
};

export const useIsNarrow = () => ({
  isNarrow: calculateIsNarrow(useResponsiveContext()),
});

export const ResponsivePanelContext = ({ narrowWidth = 751, children }) => (
  <ResponsivePanel narrowWidth={narrowWidth}>
    {(value) => (
      <ResponsiveContext.Provider value={value}>
        {children}
      </ResponsiveContext.Provider>
    )}
  </ResponsivePanel>
);

export default ResponsivePanel;
