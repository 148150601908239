import { t } from 'i18n-js';
import chorus from '../../../chorus';
import View from '../../loading_view';
import Routing from '../../../mixins/routing';
import $ from '../../../jquery';

// TODO: Change loading message when iframe is being loaded to something like "Your script is being loaded"

export default View.include(Routing).extend({

  templateName: 'mini_apps/app_run',

  bindCallbacks: $.noop,

  logsAvailable: false,
  showingLogs: false,

  additionalContext() {
    return {
      workspaceId: this.options.workspace.id,
      miniAppLoaded: this.miniAppLoaded,
      miniAppStatusError: this.miniAppStatusError,
      logs: this.logs,
    };
  },

  setup(options) {
    this._super('setup', options);
    this.miniAppLoaded = false;
    this.pullingImage = false;
    this.timeouts = [];
    this.requiredResources.add(this.model);
    this.model.fetchIfNotLoaded();
    this.onceLoaded(this.model, this.initializeApp);
    this.showingLogs = this.showingLogs;
    this.logs = '';
    this.logsAvailable = this.logsAvailable;
  },

  initializeApp() {
    this.model.initializeApp()
      .then((initStatus) => {
        if (initStatus !== 'ready') { this.setupPoll(); }
      })
      .catch(() => {
        this.miniAppStatusError = new Error(t('mini_app.initialize.error'));
        if (!this.torndown) { this.render(); }
      });
  },

  handleStatus(status, maxAttempts) {
    switch (status) {
      case 'ready':
        this.enableLogs();
        chorus.PageEvents.trigger('mini_app:ready');
        return Promise.resolve();
      case 'pulling':
        this.pullingImage = true;
        this.updateMessage();
        break;
      case 'failed':
        this.enableLogs();
        this.errorOut(t('mini_app.initialize.script.error'));
      // eslint-disable-next-line no-fallthrough
      case 'inactive':
      case 'noresource':
        this.errorOut(t('mini_app.load.error.noresource'));
        break;
      case 'unknown':
        this.errorOut(t('mini_app.load.error.unknown'));
        break;
      default:
        // 'loading'
    }

    const poll = () => this.pollForReadyMiniAppPod(maxAttempts - 1);
    return this.delay(1000).then(poll);
  },

  pollForReadyMiniAppPod(maxAttempts) {
    if (maxAttempts < 1) {
      this.errorOut(t('mini_app.load.error.max_attempts_exceeded'));
    }
    if (this.torndown) {
      this.errorOut(t('mini_app.load.error.torndown'));
    }
    // 5 second timeout for each request
    return this.model.status()
      .catch(() => {
        this.errorOut(t('mini_app.load.error.unknown'));
      }) // returned non-success http status
      .then(response => this.handleStatus(response.data.status, maxAttempts));
  },

  pollForRunningMiniApp(maxAttempts) {
    if (maxAttempts < 1) {
      this.errorOut(t('mini_app.load.error.user.max_attempts_exceeded'));
    }
    if (this.torndown) {
      this.errorOut(t('mini_app.load.error.torndown'));
    }
    // 5 second timeout for each request
    return this.model.appIsRunning()
      .catch((e) => {
        let errorMessage = 'mini_app.load.error.unknown';
        if (e.response && e.response.status === 500) {
          this.enableLogs();
          errorMessage = 'mini_app.initialize.script.error';
        } else if (e.code === 'ECONNABORTED') {
          return this.delay(1000).then(() => this.pollForRunningMiniApp(maxAttempts - 1));
        }
        return this.errorOut(t(errorMessage));
      }) // returned 5XX status
      .then((response) => {
        if (response.status >= 300) {
          return this.delay(1000).then(() => this.pollForRunningMiniApp(maxAttempts - 1));
        }
        return Promise.resolve(response);
      });
  },

  setupPoll() {
    this.pollForReadyMiniAppPod(600) // approx 10 minutes maximum wait for pod creation
      .then(() => this.pollForRunningMiniApp(300)) // approx 5 minutes maximum wait for user's code to be running
      .then(() => { this.miniAppLoaded = true; })
      .catch((err) => { this.miniAppStatusError = err; })
      .then(() => {
        if (!this.torndown) {
          this.render();
          this.constantPolling = setInterval(this.pollingIframe.bind(this), 10 * 1000);
          if (this.showingLogs) { this.showLog(1); }
        }
      });
  },

  pollingIframe() {
    if (!this.torndown) {
      const miniAppIframe = document.getElementById('miniapploaded');
      const element = miniAppIframe.contentWindow.document.querySelector('body');
      const eventApplied = element.classList.contains('copyeventattached');
      if (!eventApplied) {
        element.classList.add('copyeventattached');
        element.addEventListener('copy', () => {
          window.parent.postMessage('frameCopyEvent', '*');
        });
        element.addEventListener('cut', () => {
          window.parent.postMessage('frameCopyEvent', '*');
        });
      }
    } else {
      clearInterval(this.constantPolling);
    }
  },

  postRender() {
    delete this.miniAppStatusError; // delete error post-render so we don't render the same error twice by mistake
    this.delay(7000).then(() => {
      this.updateMessage();
    });
    this.$el.find('iframe').load(this.fadeOutLoadSpinner.bind(this));
  },

  updateMessage() {
    const message = this.pullingImage ? t('mini_app.pulling_image') : t('mini_app.preparing_env');
    if (this.$el.find('.loading-text').html() !== message) {
      this.$el.find('.loading-text').html(message);
    }
  },

  fadeOutLoadSpinner() {
    this.$el.find('.loading').fadeOut(500);
  },

  teardown(preserveContainer) {
    this.$el.find('iframe').off('load');

    this.timeouts.forEach(id => clearTimeout(id));
    this._super('teardown', [preserveContainer]);

    this.closeWebsocket();
  },

  showLog(duration = 400) {
    this.showingLogs = !this.showingLogs;
    if (this.showingLogs) {
      this.startWebsocket();
    } else {
      this.closeWebsocket();
    }
    this.$('.log').slideToggle(duration);
  },

  startWebsocket() {
    const wsUrl = this.model.getSocketUrl();
    this.socketInit(wsUrl);
  },

  closeWebsocket() {
    if (this.webSocket) {
      this.webSocket.close();
    }
  },

  socketInit(ws) {
    this.webSocket = new window.WebSocket(ws);

    this.webSocket.onopen = this.socketOnOpen.bind(this);
    this.webSocket.onmessage = this.socketOnMessage.bind(this);
    this.webSocket.onerror = this.socketOnError.bind(this);
  },

  socketOnOpen() {
    this.clearLogs();
  },

  socketOnMessage(message) {
    if (message.data) {
      this.logs += `${message.data}\n`;
      this.updateLogs();
    }
  },

  socketOnError() {
    this.logs += `${t('mini_app.logs.error')}\n`;
    this.updateLogs();
  },

  updateLogs() {
    this.$('.log-container')
      .html(this.logs);
  },

  clearLogs() {
    this.logs = '';
    this.$('.log-container').text('');
  },

  enableLogs() {
    chorus.PageEvents.trigger('mini_app:logs_available');
    this.logsAvailable = true;
  },

  errorOut(errorMessage) {
    this.miniAppLoaded = false;
    chorus.PageEvents.trigger('mini_app:run_error');
    this.miniAppLoadError = new Error(errorMessage);
    throw this.miniAppLoadError;
  },

  restartMiniApp() {
    this.closeWebsocket();

    const failAction = (error) => {
      this.miniAppLoaded = false;
      this.miniAppStatusError = error;
      this.render();
      chorus.toast('mini_app.sidebar.failed', {
        toastOpts: { theme: 'bad_activity' },
      });
    };

    this.model.status()
      .then((statusResponse) => {
        const { status } = statusResponse.data;
        if (status === 'loading') {
          chorus.toast('mini_app.sidebar.loading', {
            toastOpts: { theme: 'bad_activity' },
          });
          return Promise.resolve();
        }
        this.miniAppLoaded = false;

        delete this.miniAppStatusError;
        this.render();
        return this.model.removeMiniApp()
          .then((response) => {
            if (response.success) {
              this.model.fetch().then(() => this.initializeApp());
            } else {
              this.errorOut(t('mini_app.restart.error'));
            }
          });
      })
      .catch(() => failAction(t('mini_app.restart.error')));
  },

  delay(ms) {
    return new Promise((resolve) => {
      this.timeouts.push(setTimeout(resolve, ms));
    });
  },

  getWarningMessage() {
    const fileThatWasChanged = this.model.latestModifiedFile();

    if (this.miniAppStatusError !== undefined || this.miniAppLoadError !== undefined || fileThatWasChanged === '.version') {
      return t('mini_app.file_has_changed.restart');
    }
    return t('mini_app.file_has_changed.refresh');
  },

});
