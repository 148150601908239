import { useContext, createContext } from 'react';

const InboxContext = createContext();

export const useInboxContext = () => useContext(InboxContext);

export const InboxProvider = ({ children, state }) => (
  <InboxContext.Provider value={state}>
    {children}
  </InboxContext.Provider>
);

const InboxFileContext = createContext();

export const useInboxFileContext = () => useContext(InboxFileContext);

export const InboxFileProvider = ({ children, file }) => (
  <InboxFileContext.Provider value={file}>
    {children}
  </InboxFileContext.Provider>
);
