import _ from '../underscore';
import Model from './models';

export default Model.extend({
  url(options) {
    return this.attributes.collection.url(options);
  },

  initialize() {
    this.bind('saved', _.bind(function onSave() {
      this.trigger('saved');
    }, this.attributes.collection));
  },

  handleRequestFailure(...args) {
    const { collection } = this.attributes;
    collection.handleRequestFailure(...args);
  },
});
