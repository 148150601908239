import React from 'react';
import { List } from 'semantic-ui-react';
import { t } from 'i18n-js';
import './SingleContainerisedCard.less';
import Parser from 'html-react-parser';

const AppContainerStatus = ({ podStatus }) => {
  switch (podStatus) {
    case 'loading':
      return t('app.card.body.status_loading');
    case 'pulling':
      return t('app.card.body.status_pulling');
    case 'ready':
      return t('app.card.body.status_ready');
    case 'inactive':
      return t('app.card.body.status_inactive');
    case 'failed':
      return t('app.card.body.status_failed');
    case 'noresource':
      return t('app.card.body.status_noresource');
    case 'unknown':
    default:
      return t('app.card.body.status_unknown');
  }
};

const AppType = ({ isSystemApp }) => (isSystemApp ? t('app.card.body.type_system') : t('app.card.body.type_custom'));

const SingleCardBodyInfo = ({
  podStatus, isSystemApp, additionalInfo, license,
}) => (
  <div className="aridhia-info">
    <List>
      <List.Item>
        <List.Header>{t('app.card.body.type')}</List.Header>
        <AppType isSystemApp={isSystemApp} />
        <List.Header>{t('app.card.body.status')}</List.Header>
        <AppContainerStatus podStatus={podStatus} />
        {license ? (
          <>
            <List.Header>{t('app.card.body.license')}</List.Header>
            <div>{Parser(license)}</div>
          </>
        ) : (null)}
        {additionalInfo ? (
          <>
            <List.Header>{t('app.card.body.additional_info')}</List.Header>
            <div>{Parser(additionalInfo)}</div>
          </>
        ) : (null)}
      </List.Item>
      {/* runningTag is not available but will be with ticket XAP-18529 */}

      {/* {podStatus === 'Running' &&
        <React.Fragment>
          <List.Item>
            <List.Header>{t('app.card.body.image_version')}</List.Header>
            {runningTag}
          </List.Item>
        </React.Fragment>
      } */}
    </List>
  </div>
);

export default SingleCardBodyInfo;
