import { t } from 'i18n-js';
import { getUserProfile, idToken } from '../../application/auth/oauth';
import requestMethods from '../constants/requests';
import { workspaceUrl } from '../constants/api_urls';

// TODO migrate from old requests to this fetcher. To do after Backbone is replaced

const fetchOptions = (method, body) => ({
  method,
  headers: {
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  body,
});

const buildFetcher = (token) => async (url, body = null, method = requestMethods.GET, moreOpts = {}) => {
  if (url === '/oidc') {
    return getUserProfile();
  }

  const options = { ...fetchOptions(method, body), ...moreOpts };

  const authToken = await token();

  if (authToken) {
    options.headers.Authorization = `Bearer ${authToken}`;
  }

  const response = await fetch(url, options);

  if (!response.ok) {
    const error = new Error();
    try {
      error.info = await response.json();
    } catch {
      error.message = t('server_error');
    }

    error.status = response.status;
    throw error;
  }

  if (response.status === 202 || response.status === 204) {
    return {};
  }

  return response.json();
};

const buildRawFetcher = (token) => async (url, body = null, method = requestMethods.GET, moreOpts = {}) => {
  const options = { ...fetchOptions(method, body), ...moreOpts };

  const authToken = await token();

  if (authToken) {
    options.headers.Authorization = `Bearer ${authToken}`;
  }

  const response = await fetch(url, options);

  if (!response.ok) {
    const error = new Error();
    try {
      error.info = await response.json();
    } catch {
      error.message = t('server_error');
    }

    error.status = response.status;
    throw error;
  }

  return response.text();
};

const fetcher = buildFetcher(idToken);
export const rawFetcher = buildRawFetcher(idToken);

const CACHE_PERIOD = 1000 * 60 * 5;
const cached = (fn) => {
  let value = null;
  let time = Date.now();
  const expired = () => (Date.now() - time > CACHE_PERIOD);

  return async () => {
    if (!value || expired()) {
      value = await fn(value);
      time = Date.now();
    }
    return value;
  };
};

let fetchAccessToken = null;

export const accessToken = () => {
  if (!fetchAccessToken) {
    fetchAccessToken = cached(async () => {
      const { response } = await fetcher(workspaceUrl());
      return response.token;
    });
  }
  return fetchAccessToken();
};

export const accessTokenFetcher = buildFetcher(accessToken);

export const officeFetcher = async (url, options = {}) => {
  const response = await fetch(url, options);

  if (response.status !== 200) {
    throw new Error(t('server_error'));
  }

  return response.text();
};

export default fetcher;
