import {
  Icon, Header, Button, Segment,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import { NewFileModal } from '../../../components/modals/NewFileModal';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import Can from '../../../../permissions';

const NoFilesMessage = () => (
  <Segment placeholder>
    <Header as="h4" icon>
      <Icon name="file alternate" data-testid="file-icon" />
      {t('file_list.accessibility.no_file_list')}
    </Header>
    <Can I="Write" this="WorkspaceStorage">
      <ModalWrapper trigger={<Button icon={{ name: 'add', 'data-testid': 'add-file-icon' }} primary content={t('file_list.actions.new_file.default')} />}>
        <NewFileModal />
      </ModalWrapper>
    </Can>
  </Segment>
);

export default NoFilesMessage;
