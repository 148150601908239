/* eslint-disable camelcase */
import { Dropdown } from 'semantic-ui-react';
import { t } from 'i18n-js';
import {
  databaseTableElementUrl,
  airlockDatabaseTable,
  editDatabaseTableMetadata,
  analyseDatabaseTableData,
  newView,
} from '../../../../constants/ui_urls';
import { useDatabaseTableContext } from '../../../Context';
import { useWorkspaceContext } from '../../../../adapters/Workspace';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import AddNoteModal from '../../../../activities/components/modals/AddNoteModal';
import DeleteTableModal from '../../../components/modals/DeleteTableModal';
import PreviewTableModal from '../../../components/modals/PreviewTableModal';
import SaveAsCSVModal from '../../../components/modals/SaveAsCSVModal';
import Can from '../../../../permissions';
import { isCustomView, isTable } from '../../../helper';
import { supportedTableTypes } from '../../../constants';
import TableIcon from '../../../components/TableIcon';
import NewDBViewModal from '../../../components/modals/NewDBViewModal';

const SaveAsCSVOption = (
  <Dropdown.Item
    icon="save"
    text={t('tables_list.actions.save_as_csv')}
  />
);

const AddNoteOption = (
  <Dropdown.Item
    icon="sticky note"
    text={t('tables_list.actions.add_note')}
  />
);

const DeleteTableModalOption = (
  <Dropdown.Item
    icon="trash alternate"
    text={t('tables_list.actions.delete')}
  />
);

const PreviewTableModalOption = (
  <Dropdown.Item
    icon="eye"
    text={t('tables_list.actions.preview_data')}
  />
);

const NewDBViewOption = (
  <Dropdown.Item
    icon={(<TableIcon tableType={supportedTableTypes.VIEW} className="margin-right-medium" />)}
    text={t('tables_list.actions.new_db_view')}
  />
);

const DatabaseTablesRowDropdown = () => {
  const { workspace: { can_airlock } } = useWorkspaceContext();
  const {
    tableId,
    tableName,
    tableType,
    isImporting,
    analyseEnabled,
  } = useDatabaseTableContext();

  return (
    <Dropdown
      icon={{ name: 'ellipsis vertical', color: 'blue' }}
      name="table_list_dropdown"
      aria-label={t('tables_list.actions.table_list_dropdown')}
      className="icon"
      inline
      pointing="right"
    >
      <Dropdown.Menu>
        <Dropdown.Item
          icon="table"
          text={t('tables_list.actions.open')}
          href={databaseTableElementUrl(tableId, tableType)}
        />
        {!isImporting && (
          <Can I="Read" this="WorkspaceDatabase">
            <ModalWrapper trigger={PreviewTableModalOption} size="large">
              <PreviewTableModal />
            </ModalWrapper>
          </Can>
        )}
        <Can I="Write" this="WorkspaceStorage">
          <ModalWrapper trigger={AddNoteOption}>
            <AddNoteModal
              entityType="dataset"
              entityId={tableId}
              entityName={tableName}
            />
          </ModalWrapper>
        </Can>
        <Can I="Write" this="WorkspaceDatabase">
          <>
            <Dropdown.Item
              icon="edit outline"
              text={t('tables_list.actions.edit_metadata')}
              href={editDatabaseTableMetadata(tableId)}
            />
            {isTable(tableType) && !isImporting && (
              <Dropdown.Item
                icon={(
                  <TableIcon tableType={supportedTableTypes.CHORUS_VIEW} className="margin-right-medium" />
                )}
                text={t('tables_list.actions.new_view')}
                href={newView(tableId)}
              />
            )}
            {isCustomView(tableType) && !isImporting && (
              <ModalWrapper trigger={NewDBViewOption}>
                <NewDBViewModal />
              </ModalWrapper>
            )}
            {!isCustomView(tableType) && !isImporting && (
              <>
                {analyseEnabled && (
                  <Dropdown.Item
                    icon="chart pie"
                    text={t('tables_list.actions.analyse_data')}
                    href={analyseDatabaseTableData(tableId)}
                  />
                )}
                <Can I="Export" this="WorkspaceDatabase">
                  <ModalWrapper trigger={SaveAsCSVOption} size="small">
                    <SaveAsCSVModal />
                  </ModalWrapper>
                </Can>
              </>
            )}
          </>
        </Can>
        {!isImporting && can_airlock && (
          <Can I="Create" this="WorkspaceAirlock">
            <Dropdown.Item
              icon="unlock"
              text={t('tables_list.actions.airlock')}
              href={airlockDatabaseTable(tableId)}
            />
          </Can>
        )}
        <Can I="Write" this="WorkspaceDatabase">
          <ModalWrapper trigger={DeleteTableModalOption}>
            <DeleteTableModal />
          </ModalWrapper>
        </Can>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DatabaseTablesRowDropdown;
