import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';

const NullQualifierForm = ({ nullQualifier, handleNullQualifierChange, triggerErrorNullQualifierChange }) => (
  <Form.Field inline>
    <label htmlFor="null_qualifier">{t('dataset.import.null_qualifier')}</label>
    <Input
      id="null_qualifier"
      defaultValue={nullQualifier}
      onBlur={handleNullQualifierChange}
      onChange={triggerErrorNullQualifierChange}
      className="small-size"
    />
  </Form.Field>
);

const mapModelsToProps = ({ datasetUpload }) => ({
  nullQualifier: datasetUpload.get('null_qualifier'),
  handleNullQualifierChange: (_e) => {
    datasetUpload.updateNullQualifier(_e.target.value);
  },
  triggerErrorNullQualifierChange: (_e) => {
    if (_e.target.value.length <= 1) {
      datasetUpload.updateNullQualifier(_e.target.value);
    }
  },
});

const options = {
  debounce: true,
  events: {
    datasetUpload: ['change:null_qualifier'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(NullQualifierForm);
