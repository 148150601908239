import View from '../loading_view';
import RToolsSidebar from './rtools_sidebar_view';
import RToolsModel from '../../models/rtools_console';
import RCommand from '../../models/r_command';
import LoadWrapper from "../async_wrapper_view";
import { rCommands } from '../../collections/r_commands';
import ShellSessions from '../../collections/shell_sessions';

const RToolsConsole = LoadWrapper(() => import(/* webpackChunkName: "console" */ './rtools_console_view'));

export default View.extend({
  subviews: {
    '.app-content': 'mainContent',
    '.app-sidebarBasic': 'sidebar',
  },

  setup(options) {
    this.className = 'app-rconsole';
    this.templateName = 'tab_content';

    this.workspace = options.model;

    const model = new RToolsModel({ workspace: this.workspace });
    this.images = new ShellSessions([], { workspace: this.workspace, tokenPromise: this.workspace.token() });

    this.requiredResources.add(this.images);
    this.images.fetchIfNotLoaded();
    const {tab} = options;

    this.mainContent = this.RToolsConsole = new RToolsConsole({ model, images: this.images, command: this.resolveCommand() });
    this.sidebar = new RToolsSidebar({ model, images: this.images, console: this.mainContent });

    model.set('active', tab.get('active'));
    this.listenTo(tab, 'change:active', (_, active) => { model.set('active', active); });
    this.listenTo(model, 'change:unsaved', (_, unsaved) => { tab.set('unsaved', unsaved); });
    this.listenTo(this.images, 'sync change:selected', this.updateSession);
  },

  resolveCommand() {
    const params = this.options.tab.urlParams();
    const checkId = params.pop();
    const command = (checkId === 'rtools') ? null : rCommands.findWhere({ checkId });

    return command || new RCommand();
  },

  performCommandWhenReady() {
    const command = this.resolveCommand();
    this.RToolsConsole.performCommandWhenReady(command);
  },

  updateSession() {
    const selected = this.images.getSelected();
    if (selected.has('sessionId')) {
      this.workspace.set('activeRSessionId', selected.get('sessionId'), { silent: true });
    } else {
      this.workspace.unset('activeRSessionId', { silent: true });
    }
  },
});
