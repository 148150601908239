const chorusIdentifier64 = /^[a-zA-Z][a-zA-Z0-9_]{0,63}$/;
const chorusIdentifierLower64 = /^[a-z][a-z0-9_]{0,63}$/;
const chorusIdentifier = /^[a-zA-Z][a-zA-Z0-9_]*$/;
const safePgName = /^[a-z_][a-z0-9_"]*$|^".*"$/;
const unsafeDirectoryName = /[<>:"\/\\|?*\x00-\x1F]|^(?:aux|con|clock\$|nul|prn|com[1-9]|lpt[1-9])$/ig;
const allWhitespace = /^(\s|&nbsp;)*$/;
const time = /^(\d{1,2}(:\d{2}){1,2})?$/;
const year = /^[0-9]{1,4}$/;
const month = /^(0?[1-9]|1[0-2])$/;
const day = /^(0?[1-9]|[12][0-9]|3[01])$/;
const boolean = /^(true|false)$/;

export const ChorusIdentifier64 = () => chorusIdentifier64;

export const ChorusIdentifierLower64 = () => chorusIdentifierLower64;

export const ChorusIdentifier = (length) => {
  if (length) {
    return new RegExp(`^[a-zA-Z][a-zA-Z0-9_]{0,${length - 1}}$`);
  }

  return chorusIdentifier;
};

export const Password = (options) => {
  if (options) {
    const min = options.min || '0';
    const max = options.max || '';
    return new RegExp(`^.{${min},${max}}$`);
  }
  return null;
};

export const SafePgName = () => safePgName;

export const UnsafeDirectoryName = () => unsafeDirectoryName;

export const PostgresIdentifier = (length) => ChorusIdentifier(length);

export const AllWhitespace = () => allWhitespace;

export const Time = () => time;

export const Year = () => year;

export const Month = () => month;

export const Day = () => day;

export const Boolean = () => boolean;
