import _ from '../underscore';

export default {
  initialize(models, options, ...args) {
    this._super('initialize', [models, options, ...args]);
    this.search = options.search;
  },

  refreshFromSearch() {
    const entityJson = this.search.get(this.searchKey);
    const pagination = {
      page: this.search.currentPageNumber(),
      total: this.search.numPages(entityJson.numFound),
      records: entityJson.numFound,
    };
    this.reset(entityJson.results, { pagination });
  },

  fetchPage(pageNumber) {
    this.search.set({ page: pageNumber });
    this.search.fetch({ success: _.bind(this.refreshFromSearch, this) });
  },
};
