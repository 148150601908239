import { t } from 'i18n-js';
import { Modal, Icon, Header } from 'semantic-ui-react';
import { useDatabaseTableCreationContext } from '../../Context';
import { CancelButton, PositiveButton } from '../../../../../components/buttons/ModalActionButtons';
import { addPrimaryKey, setPrimaryKey } from '../../actions';

const PrimaryKeyModal = ({ setModalClose }) => {
  const { dispatch } = useDatabaseTableCreationContext();

  const handleClose = () => {
    dispatch(setPrimaryKey);
    setModalClose();
  };

  const handleSubmitting = () => {
    dispatch(addPrimaryKey);
    setModalClose();
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="key" />
        <Header.Content content={t('database_tables_new.columns.add_primary_key.title')} />
      </Header>
      <Modal.Content>
        <p>{t('database_tables_new.columns.add_primary_key.text1')}</p>
        <p>{t('database_tables_new.columns.add_primary_key.text2')}</p>
        <p>{t('database_tables_new.columns.add_primary_key.text3')}</p>
      </Modal.Content>
      <Modal.Actions>
        <CancelButton
          loading={false}
          onClose={handleClose}
          cancelText={t('database_tables_new.columns.add_primary_key.button_choose')}
          cancelIcon="arrow left"
        />
        <PositiveButton
          isValidEntry
          loading={false}
          onSubmit={handleSubmitting}
          submitText={t('database_tables_new.columns.add_primary_key.button_add')}
        />
      </Modal.Actions>
    </>
  );
};

export default PrimaryKeyModal;
