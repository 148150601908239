import chorus from '../../chorus';
import _ from '../../underscore';
import t from '../../intl';
import ColumnSelect from '../../views/dataset/column_select';
import Dialog from '../../dialogs/dialogs';
import { joinMap } from '../../utilities/schema_assoc';

export default Dialog.extend({
  constructorName: 'JoinConfigurationDialog',
  templateName: 'join_configuration',
  additionalClass: 'with_sub_header',
  title: t('dataset.manage_join_tables.title'),
  useLoadingSection: true,

  subviews: {
    '.source_columns': 'sourceColumnsSelect',
    '.destination_columns': 'destinationColumnsSelect',
  },

  events: {
    'click button.submit': 'addJoin',
  },

  setup() {
    this.destinationObject = this.options.destinationObject;
    this.destinationColumns = this.destinationObject.columns();
    this.requiredResources.push(this.destinationColumns);
    this.listenTo(this.destinationColumns, 'fetchFailed', this.columnFetchFailed);
    this.destinationColumns.fetchIfNotLoaded();
    this.sourceColumnsSelect = new ColumnSelect({
      collection: this.model.aggregateColumnSet,
      showAliasedName: true,
    });
    this.destinationColumnsSelect = new ColumnSelect({
      collection: this.destinationColumns,
    });
  },

  columnFetchFailed() {
    this.model.serverErrors = this.destinationColumns.serverErrors;
    this.closeModal();
    this.previousModal.showErrors(this.model);
  },

  additionalContext() {
    return {
      destinationObjectName: this.destinationObject.get('objectName'),
      joinMap,
    };
  },

  postRender() {
    _.defer(_.bind(function applyStyle() {
      chorus.styleSelect(this.$('select.join_type'));
    }, this));
  },

  addJoin() {
    this.model.addJoin(
      this.sourceColumnsSelect.getSelectedColumn(),
      this.destinationColumnsSelect.getSelectedColumn(),
      this.$('select.join_type').val(),
    );
    this.closeModal();
  },
});
