/* eslint-disable max-lines, react/jsx-props-no-spreading */
import React, { useState, useCallback } from 'react';
import {
  Icon,
  Label,
  Input,
  Pagination,
  Table,
  Checkbox,
  Dropdown,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import _ from 'underscore';
import DefaultRefresh from '../../components/buttons/RefreshButton';
import TableHeaderError from '../../components/errors/TableHeaderError';

const nextDirection = (direction) => {
  // Cycle through sorting directions null(default) -> ascending -> descending -> null(default)
  switch (direction) {
    case 'ascending':
      return 'descending';
    case 'descending':
      return null;
    default:
      return 'ascending';
  }
};

const createHeaderCell = (onSortBy) => ({
  name,
  content,
  tooltip,
  direction,
  unsortable = true,
  collapsing = false,
  ...props
}) => {
  if (unsortable) {
    return (
      <Table.HeaderCell key={name} className="aridhia-non-sortable-header-cell" title={tooltip} collapsing={collapsing} {...props}>
        {content}
      </Table.HeaderCell>
    );
  }

  const onClick = () => onSortBy([name, nextDirection(direction)]);

  return (
    <Table.HeaderCell key={name} title={tooltip} sorted={direction} onClick={onClick} {...props}>
      {content}
    </Table.HeaderCell>
  );
};

export const DefaultColumnsHeader = ({ columns, onSortBy }) => (
  <Table.Row aria-label={t('table.accessibility.table_columns')}>
    {columns.map(createHeaderCell(onSortBy))}
  </Table.Row>
);

export const DefaultActionMenu = (props) => (
  <Dropdown
    icon={{ name: 'ellipsis vertical', color: 'blue' }}
    className="icon"
    inline
    pointing="right"
  >
    <Dropdown.Menu {...props} />
  </Dropdown>
);

export const DefaultFilter = ({
  onFilter, className = 'margin-right-small', ...props
}) => {
  const debouncedFilter = _.debounce(onFilter, 250, false);
  const handleFilter = (e, { value }) => {
    debouncedFilter(value);
  };

  return (
    <Input
      size="small"
      className={className}
      placeholder={t('header.filter.placeholder')}
      onChange={handleFilter}
      {...props}
    />
  );
};

export const DefaultDropdown = React.memo(({ onFilter, options, filter }) => {
  const [inputvalue, setinputvalue] = useState(false);
  const handleFilter = useCallback((e, { value }) => {
    onFilter(value);
    setinputvalue(!!value);
  }, [onFilter, setinputvalue]);
  return (
    <Dropdown
      icon={{
        name: 'filter', size: 'small', circular: inputvalue, inverted: inputvalue, color: inputvalue ? 'blue' : 'grey', 'data-testid': 'filter-icon', 'aria-labelledby': `${filter}`,
      }}
      className="float-right"
      selectOnBlur={false}
      pointing="top right"
      scrolling
      options={options}
      onChange={handleFilter}
      aria-label={`${filter} dropdown`}
      aria-labelledby={`${filter} dropdown`}
    />
  );
});

export const DefaultFilterDropdown = React.memo(({ onFilter, tabIndex, filter }) => {
  const [inputvalue, setinputvalue] = useState(false);
  const handleFilter = useCallback((value) => {
    onFilter(value);
    setinputvalue(!!value);
  }, [onFilter, setinputvalue]);
  return (
    <Dropdown
      clearable
      pointing="top right"
      icon={{
        name: 'filter', size: 'small', circular: inputvalue, inverted: inputvalue, color: inputvalue ? 'blue' : 'grey', 'data-testid': 'filter-icon', 'aria-labelledby': `${filter}`,
      }}
      className="float-right"
      aria-label={`${filter} dropdown`}
      aria-labelledby={`${filter} dropdown`}
    >
      <Dropdown.Menu>
        <DefaultFilter
          onFilter={handleFilter}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          className="margin-small"
          tabIndex={tabIndex + 1}
          aria-label={`${filter} input`}
          aria-labelledby={`${filter} input`}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
});

export const DefaultTableHeader = ({
  columns,
  multiSelectIcon,
  multiSelectActions,
  errorComponent,
  filter,
  refresh,
}) => (
  <Table.Row aria-label={t('table.accessibility.table_header_row')}>
    {columns.length > 1 && (
      <Table.HeaderCell className="aridhia-non-sortable-header-cell">
        {multiSelectIcon}
      </Table.HeaderCell>
    )}
    <Table.HeaderCell colSpan={columns.length - 1} className="aridhia-non-sortable-header-cell" data-testid="table-actions">
      {multiSelectActions}
      {errorComponent}
      <div className="float-right">
        {filter}
        {refresh}
      </div>
    </Table.HeaderCell>
  </Table.Row>
);

export const DefaultStats = ({ shown, total }) => (
  <>
    <Label>
      {t('pagination.shown')}
      <Label.Detail data-testid="visible-items">
        {shown}
      </Label.Detail>
    </Label>
    <Label>
      {t('pagination.total')}
      <Label.Detail data-testid="total-items">
        {total || shown}
      </Label.Detail>
    </Label>
  </>
);

export const DefaultPaging = ({
  total,
  pageSize = 50,
  activePage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(total / pageSize);
  const canNextPage = activePage < totalPages;
  const canPreviousPage = activePage > 1;
  if (total > pageSize) {
    return (
      <Pagination
        floated="right"
        size="mini"
        ellipsisItem={null}
        activePage={activePage}
        totalPages={totalPages}
        firstItem={canPreviousPage ? { content: <Icon name="angle double left" />, icon: true } : null}
        lastItem={canNextPage ? { content: <Icon name="angle double right" />, icon: true } : null}
        prevItem={canPreviousPage ? { content: <Icon name="angle left" />, icon: true } : null}
        nextItem={canNextPage ? { content: <Icon name="angle right" />, icon: true } : null}
        siblingRange={2}
        boundaryRange={2}
        onPageChange={(e, data) => onPageChange(data.activePage)}
      />
    );
  }
  return null;
};

export const DefaultEmptyWrapper = ({
  isFetching, // Use isFetching so empty content is hidden when doing isLoading and refetch
  tableData,
  columns,
  emptyContent,
}) => (
  tableData.length === 0 && (
    <Table.Row>
      <Table.Cell colSpan={columns.length}>
        {!isFetching && emptyContent}
      </Table.Cell>
    </Table.Row>
  )
);

export const DefaultErrorWrapper = ({ isError }) => (isError && (TableHeaderError({})));

export const DefaultMultiSelectIcon = ({ selectedItems }) => (
  selectedItems.length > 0 && <Icon name="check" color="blue" className="margin-none" data-testid="multi-select-icon" />
);

export const DefaultSelectAll = ({
  tableData,
  selectedTableData,
  handleSelectedTableData,
  isFetching,
  isError,
}) => (
  <Checkbox
    value="all"
    aria-label={t('table.accessibility.select_all_checkbox')}
    onChange={handleSelectedTableData}
    checked={!isFetching && selectedTableData?.length > 0 && (selectedTableData.length === tableData.length)}
    disabled={isFetching || isError}
  />
);

export const useDefaultComponents = (props) => {
  const {
    columnsHeader = DefaultColumnsHeader,
    refresh = DefaultRefresh,
    filter = DefaultFilter,
    pagination = DefaultPaging,
    stats = DefaultStats,
    emptyWrapper = DefaultEmptyWrapper,
    errorWrapper = DefaultErrorWrapper,
  } = props;

  return ({
    columnsHeader: columnsHeader(props),
    refresh: refresh(props),
    filter: filter(props),
    pagination: pagination(props),
    stats: stats(props),
    emptyWrapper: emptyWrapper(props),
    errorComponent: errorWrapper(props), // error is returned from useQuery - avoid collision by naming differently
  });
};

export const useTableHeader = (props) => {
  const { tableHeader = DefaultTableHeader } = props;
  return ({ tableHeader: tableHeader(props) });
};
