
export const labelFormat = (label, maxLength) => {
  const max = maxLength || (typeof label === 'number' ? 6 : 15);

  if (!label) {
    return '';
  } else if ((typeof label === 'number') && label.toString().length > max) {
    return label.toExponential(2);
  } else if (label.toString().length > max) {
    return `${label.toString().slice(0, max - 1)}...`;
  }
  return label.toString();
};

export const labelFormatKeepPercentage = (label, maxLength) => {
  if (/.*\(\d*%\)/.test(label)) {
    const percentage = label.match(/\(\d*%\)/)[0];
    const name = label.split(/\(\d*%\)/)[0].trim();
    return `${labelFormat(name, 6)} ${percentage}`;
  }
  return labelFormat(label, maxLength);
};
