import React from 'react';
import { createRoot } from 'react-dom/client';
import { BackboneNavbar } from 'components/NavBar';
import chorus from '../../chorus';
import $ from '../../jquery';
import NotificationSet from '../../collections/notification_set';
import StaticContentView from '../../dialogs/staticcontent_dialog';
import AboutDialog from '../../dialogs/about_dialog';
import ReactView from '../react_view';
import Routing from '../../mixins/routing';
import { instance } from '../../models/config';
import { signout } from '../../auth/oauth'
import { FEATURE } from '../../../utilities/features';

export default ReactView.include(Routing).extend({
  constructorName: 'HeaderView',

  setup(options) {
    this.session = chorus.session;
    this.navigateByElement = this.navigateByElement.bind(this);
    this.popupMenuDialog = this.popupMenuDialog.bind(this);
    this.popupAboutDialog = this.popupAboutDialog.bind(this);
    this.user = chorus.session.user();
    this.unreadNotifications = new NotificationSet([], { type: 'unread' });
    this.unreadNotifications.per_page = 0;
    this.unreadNotifications.fetch();
    this.model = options.model;
  },

  popupMenuDialog(e) {
    e && e.preventDefault();
    const url = $(e.target).attr('href');
    const title = $(e.target).text();
    this.dialog = new StaticContentView({ contentURL: url, contentTitle: title }).launchModal();
  },

  popupAboutDialog(e) {
    e && e.preventDefault();
    this.dialog = new AboutDialog().launchModal();
  },

  render() {
    const modelsMap = {
      user: this.user,
      workspace: this.model,
      notifications: this.unreadNotifications,
    };

    $(this.el).addClass('semantic');

    // Header seems to be rendered twice
    // First time it has this.root created inherited from react_view
    // Second time it does not so needs to be created
    if (!this.root._internalRoot) {
      this.root = createRoot(this.el);
    }

    this.root.render(
      <BackboneNavbar
        models={modelsMap}
        navigateFunc={this.navigateByElement}
        popupFunc={this.popupMenuDialog}
        showAboutDialog={this.popupAboutDialog}
      />,
    );

    return this;
  },

  navigateByElement(e) {
    e && e.preventDefault(e);
    const url = $(e.target).attr('href') ||
        $(e.target).closest('a').attr('href');
    this.admin_navigate(url);
  },

  admin_navigate(url) {
    if (url === '#/logout') {
      signout();
    } else {
      window.location = `${instance.get('administrationUrl')}${url}`;
    }
  },
});
