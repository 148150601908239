import React from 'react';
import { BackboneProvider, connectBackboneToReact } from 'connect-backbone-to-react';
import { Card } from 'semantic-ui-react';
import ScrollableContainer from '../ScrollableContainer';
import SingleCardContainer from './SingleCardContainer';
import './ContainerisedApp.less';

const CardList = ({
  apps,
}) => (
  <ScrollableContainer id="app-list-container">
    <Card.Group>
      {
          apps.map((app) => (
            <BackboneProvider key={app.id} models={{ containerisedApp: app }}>
              <SingleCardContainer />
            </BackboneProvider>
          ))
        }
    </Card.Group>
  </ScrollableContainer>
);

const mapModelsToProps = ({ containerisedApps }) => ({
  apps: containerisedApps,
});

const options = {
  debounce: true,
  events: {
    containerisedApps: [
      'reset',
      'add',
      'remove',
      'appsLoaded',
    ],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(CardList);
