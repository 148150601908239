import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import Dialog from '../../dialogs/dialogs';
import Comment from '../../models/comment';
import ClEditor from '../../mixins/cl_editor_helpers';

export default Dialog.include(ClEditor).extend({
  templateName: 'comment',
  title: t('comments.new_dialog.title'),
  persistent: true,

  events: {
    'submit form': 'save',
  },

  makeModel(...args) {
    this._super('makeModel', args);

    this.model = new Comment({
      entityType: this.options.entityType,
      eventId: this.options.eventId,
    });
    this.listenTo(this.model, 'saved', this.saved);
    this.listenTo(this.model, 'saveFailed', this.saveFailed);
    this.listenTo(this.model, 'validationFailed', this.saveFailed);
  },

  additionalContext() {
    return { entityTitle: this.options.entityTitle };
  },

  postRender() {
    _.defer(_.bind(function buildEditor() {
      this.makeEditor($(this.el), '.toolbar', 'body', { width: 'auto', height: 150 });
    }, this));
  },

  showErrors(model) {
    this._super('showErrors');

    let errorModel = model;

    if (!errorModel) {
      errorModel = this.resource;
    }

    if (errorModel.errors && errorModel.errors.body) {
      const $input = this.$('.cleditorMain');
      this.markInputAsInvalid($input, errorModel.errors.body, true);

      this.$('iframe').contents().find('body').css('margin-right', '20px');
    }
  },

  save(e) {
    e.preventDefault();
    this.model.save({ body: this.getNormalizedText(this.$('textarea[name=body]')) });
  },

  saved() {
    this.pageModel.trigger('invalidated');
    const user = chorus.session.user().get('fullName').split(' ');
    this.model.set('author', {
      uuid: chorus.session.user().get('uuid'),
      firstName: user[0],
      lastName: user[(user.length - 1)],
    });
    chorus.PageEvents.trigger('comment:added', this.model);
    this.closeModal();
  },
});
