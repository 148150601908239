const CACHE_PERIOD = 1000 * 60 * 10;
function cached(fn) {
  let value = null;
  let time = Date.now();

  const expired = () => (Date.now() - time > CACHE_PERIOD);
  const updateState = (x) => { value = x; time = Date.now(); return x; };
  return () => {
    if (!value || expired()) {
      return fn().then(updateState);
    }
    return Promise.resolve(value);
  };
}

const workspaceToken = (workspace) => {
  const w = workspace.clone();
  return cached(() => w.fetch().then(() => w.get('token')));
};

export default workspaceToken;
