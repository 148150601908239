import { useState } from 'react';
import Backbone from 'backbone';
import WorkfileShowView from '../../../../../application/views/workfiles/workfile_show_view';
import ReactToBackboneWrapper from '../../ReactToBackboneWrapper';
import { renameTab } from '../../../tabMonitor';

const FilePanel = ({ tab, workspace }) => {
  const { component, id, config } = tab;

  const [view] = useState(
    () => new WorkfileShowView({
      workspace,
      tab: new Backbone.Model({ active: true }),
      workfile: workspace.getWorkfile(config.args[4]),
      versionId: config.args.length >= 7 && config.args[6],
      action: config.args.length >= 6 && config.args[5],
    }),
  );

  const callback = () => renameTab(id, view.model.getFileName(), view.model.fullName());

  return (
    <ReactToBackboneWrapper
      view={view}
      tabId={id}
      component={component}
      onceLoadedCallback={callback}
    />
  );
};

export default FilePanel;
