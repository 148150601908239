import chorus from '../../chorus';
import _ from '../../underscore';
import t from '../../intl';
import Dialog from '../../dialogs/dialogs';
import WorkspaceExportConfiguration from '../../models/workspace_export_configuration';
import DialogFormHelpers from '../../mixins/dialog_form_helpers';

export default Dialog.include(DialogFormHelpers).extend({
  constructorName: 'WorkspaceExport',
  templateName: 'workspace_export',
  submitKey: 'actions.export',

  events: {
    'click button.submit': 'startExport',
  },

  setup() {
    this.title = t('workspace.export_dialog.title');
    this.workspace = this.options.pageModel;
    this.model = new WorkspaceExportConfiguration({
      workspaceId: this.workspace.id,
      reason: '',
    });
    this.resource = this.model;

    this.listenTo(this.model, 'saved', this.saved);
    this.listenTo(this.model, 'saveFailed', this.saveFailed);
    this.disableFormUnlessValid({
      formSelector: 'form',
      inputSelector: 'input',
    });
    _.defer(() => {
      this.toggleSubmitDisabled();
    });
  },

  updateModel() {
    this.model.set({
      reason: this.$('input').val(),
    });
  },

  saved() {
    this.closeModal();
    chorus.toast('workspace.export_started');
  },

  saveFailed() {
    if (this.model.serverErrors) {
      this.showErrors();
    }

    this.$('button.submit').stopLoading();
  },

  startExport(e) {
    e.preventDefault();

    this.updateModel();

    this.model.save();
    this.$('button.submit').startLoading(this.loadingKey);
  },
});
