import chorus from './chorus';
import Router from './router';
import { instance as Config } from './models/config';

import Dataset from './models/dataset';
import DatasetStatistics from './models/dataset_statistics';
import FrameEvent from './models/frame_event';
import TaggingsUpdater from './models/taggings_updater';
import Session from './models/session';

import ActivitySet from './collections/activity_set';
import TaggingSet from './collections/tagging_set';
import RCommands from './collections/r_commands';
import WorkspaceSet from './collections/workspace_set';
import Collection from './collections/collections';

import WorkspacesManagementAdministratorsList from './dialogs/workspaces/management/admin_list';
import WorkfileNewVersion from './dialogs/workfiles/workfile_new_version';
import WorkfileNew from './dialogs/workfiles/workfile_new_dialog';
import WorkfileSaveAs from './dialogs/workfiles/saveas_workfile_dialog';
import WorkspaceEditMembersDialog from './dialogs/workspaces/workspace_edit_members_dialog';
import WorkspaceExport from './dialogs/workspaces/workspace_export_dialog';
import WorkspaceMembersMore from './dialogs/workspaces/workspace_members_more_dialog';
import MiniAppCreateDialog from './dialogs/mini_apps/mini_app_create_dialog';

import DeleteNoteConfirmAlert from './alerts/delete_note_confirm_alert';
import ActionFailed from './alerts/action_failed_alert';
import WorkfileDelete from './alerts/workfile_delete_alert';
import NotificationDeleteAlert from './alerts/notification_delete_alert';
import WorkfileVersionDelete from './alerts/workfile_version_delete_alert';
import DatasetDelete from './alerts/dataset_delete_alert';
import FileImportFailed from './alerts/import_failed_alert';
import DataSourceChangedOwner from './alerts/data_source_change_owner_alert';
import DataSourceDelete from './alerts/data_source_delete_alert';
import DatasetExportFailed from './alerts/dataset_export_failed_alert';
import AirlockExportFailed from './alerts/airlock_export_failed_alert';

import ServerErrors from './mixins/server_errors';

import { azureInitializeNew } from './auth/oauth';
import { idToken } from './auth/oauth';

import './style.scss';

chorus.models.Dataset = Dataset;
chorus.models.DatasetStatistics = DatasetStatistics;
chorus.models.FrameEvent = FrameEvent;
chorus.models.TaggingsUpdater = TaggingsUpdater;
chorus.models.Session = Session;

chorus.collections.Base = Collection;
chorus.collections.ActivitySet = ActivitySet;
chorus.collections.RCommands = RCommands;
chorus.collections.WorkspaceSet = WorkspaceSet;
chorus.collections.TaggingSet = TaggingSet;

chorus.dialogs.WorkspaceEditMembers = WorkspaceEditMembersDialog;
chorus.dialogs.WorkspacesManagementAdministratorsList = WorkspacesManagementAdministratorsList;
chorus.dialogs.WorkspaceExport = WorkspaceExport;
chorus.dialogs.WorkspaceMembersMore = WorkspaceMembersMore;
chorus.dialogs.WorkfileNewVersion = WorkfileNewVersion;
chorus.dialogs.WorkfileNew = WorkfileNew;
chorus.dialogs.WorkfileSaveAs = WorkfileSaveAs;
chorus.dialogs.MiniAppCreate = MiniAppCreateDialog;

chorus.alerts.DeleteNoteConfirmAlert = DeleteNoteConfirmAlert;
chorus.alerts.ActionFailed = ActionFailed;
chorus.alerts.WorkfileDelete = WorkfileDelete;
chorus.alerts.NotificationDeleteAlert = NotificationDeleteAlert;
chorus.alerts.WorkfileVersionDelete = WorkfileVersionDelete;
chorus.alerts.DatasetDelete = DatasetDelete;
chorus.alerts.FileImportFailed = FileImportFailed;
chorus.alerts.DataSourceChangedOwner = DataSourceChangedOwner;
chorus.alerts.DataSourceDelete = DataSourceDelete;
chorus.alerts.DatasetExportFailed = DatasetExportFailed;
chorus.alerts.AirlockExportFailed = AirlockExportFailed;

chorus.instance = Config;
chorus.Router = Router;

chorus.Mixins.ServerErrors = ServerErrors;
chorus.initializeUser = azureInitializeNew;
window.tokenPromise = idToken;
