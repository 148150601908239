import { t } from 'i18n-js';
import { Menu, Image, Icon } from 'semantic-ui-react';

export const HomeLink = ({ portalUrl, homeUrl, homeLogo }) => (
  <Menu.Item href={homeUrl || portalUrl} title="Home" className="aridhia-home-logo">
    <Image
      src={homeLogo || '/images/refresh/logo.svg'}
      size="tiny"
    />
  </Menu.Item>
);

export const FairLink = ({ fairUrl }) => (
  <Menu.Item
    href={fairUrl}
    target="_blank"
    rel="noopener noreferrer"
    title="FAIR"
  >
    <Image
      src="/images/FAIRLogo2020-white.svg"
      size="mini"
      spaced="right"
      alt="FAIR"
    />
    <span>Data Services</span>
  </Menu.Item>
);

// Currently only for ADDI
export const ExternalLink = ({ customLink }) => (
  <Menu.Item
    href={customLink.href}
    target="_blank"
    rel="noopener noreferrer"
    title={customLink.title}
  >
    <Image
      src={customLink.src}
      size="tiny"
    />
  </Menu.Item>
);

export const HelpLink = ({ helpUrl }) => {
  const helpLinkUrl = helpUrl || t('url.knowledgebase');
  return (
    <Menu.Item
      href={helpLinkUrl}
      target="_knowledgebase"
      rel="noopener noreferrer"
      title={t('header.visit_knowledgebase')}
      className="help-link"
    >
      <Icon
        data-testid="help-link-icon"
        name="question circle outline"
        size="large"
        className="margin-none"
      />
    </Menu.Item>
  );
};

export const FeedbackLink = () => (
  <Menu.Item
    href={t('url.knowledgebase_give_feedback')}
    target="_feedback"
    rel="noopener noreferrer"
    title={t('header.feedback')}
    className="feedback-link"
  >
    <Icon
      data-testid="feedback-link-icon"
      name="bullhorn"
      className="margin-none"
    />
  </Menu.Item>
);
