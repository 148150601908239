import { fileVersions } from '../../utilities/file';
import View from '../loading_view';

export default View.extend({
  templateName: 'read_only_workfile_content_details',
  constructorName: 'ReadOnlyWorkfileContentDetailsView',
  additionalClass: 'workfile_content_details action_bar_limited',

  subviews: {
    '.workfile_version_list': 'versionList',
  },

  additionalContext() {
    return {
      explanationText: this.explanationText,
      downloadUrl: this.model.downloadUrl(),
    };
  },

  // Much of this is copied and duplicated from sql_workfile_contents_details_view
  // TODO: refactor workfile details views and templates

  setup() {
    this.resource = this.model;
    this.allVersions = fileVersions(this.model);
  },
});
