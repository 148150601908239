import { t } from 'i18n-js';
import DatabaseTableColumnsTableRow, { DatabaseTableColumnsTableRowNarrow } from './DatabaseTableColumnsTableRow';
import NoDatabaseTableColumns from './NoDatabaseTableColumns';
import { useDatabaseTableContext } from '../../../Context';
import {
  ColumnsHeader,
  EmptyMessage,
  ErrorComponent,
  Filter,
  TableWrapper,
} from '../../../../query_table';
import RefreshButton from '../../../../components/buttons/RefreshButton';
import DatabaseTableStats from './DatabaseTableStats';
import { calculateIsNarrow } from '../../../../helpers/panel_widths';
import DatabaseTableColumnsTableHeader from './DatabaseTableColumnsTableHeader';

const createRow = (isNarrow) => (column) => {
  if (isNarrow) return DatabaseTableColumnsTableRowNarrow({ column });

  return DatabaseTableColumnsTableRow({ column });
};

const tableColumns = () => ([
  { name: 'name', content: t('database_table.table_header.name') },
  { name: 'type', content: t('database_table.table_header.type') },
  { name: 'values', content: t('database_table.table_header.values') },
  { name: 'label', content: t('database_table.table_header.label') },
  { name: 'description', content: t('database_table.table_header.description') },
]);

const DatabaseTableColumnsTable = () => {
  const {
    columns,
    isRefetching,
    handleRefreshButton,
    isColumnsError,
    setFilterTerm,
    panelWidth,
  } = useDatabaseTableContext();

  const isNarrow = calculateIsNarrow(panelWidth);
  const renderBodyRow = createRow(isNarrow);

  return (
    <TableWrapper
      tableData={columns}
      columns={tableColumns()}
      tableHeader={(
        <DatabaseTableColumnsTableHeader
          columns={tableColumns()}
          errorComponent={<ErrorComponent isError={isColumnsError} />}
          filter={<Filter onFilter={setFilterTerm} />}
          refresh={<RefreshButton isFetching={isRefetching} refetch={handleRefreshButton} />}
        />
      )}
      columnsHeader={!isNarrow && <ColumnsHeader columns={tableColumns()} />}
      renderBodyRow={renderBodyRow}
      emptyWrapper={(
        <EmptyMessage
          isFetching={isRefetching}
          tableData={columns}
          columns={tableColumns()}
          emptyContent={<NoDatabaseTableColumns />}
        />
      )}
      stats={<DatabaseTableStats />}
    />
  );
};

export default DatabaseTableColumnsTable;
