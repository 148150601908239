import { Button } from 'semantic-ui-react';
import { t } from 'i18n-js';

const RefreshButton = ({
  isFetching,
  refetch,
  className = '',
  size = 'small',
  compact = false,
}) => (
  <Button
    compact={compact}
    icon={{ name: 'refresh', loading: isFetching, 'data-testid': 'refresh-icon' }}
    aria-label={t('actions.refresh')}
    onClick={refetch}
    size={size}
    className={`margin-none ${className}`}
  />
);

export default RefreshButton;
