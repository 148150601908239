import React from 'react';
import './SingleContainerisedCard.less';
import SingleCardBodyTile from './SingleCardBodyTile';
import SingleCardBodyInfo from './SingleCardBodyInfo';

const SingleCardBody = ({
  podStatus,
  isSystemApp,
  runningTag,
  startedBy,
  showInfo,
  handleRun,
  displayPicture,
  additionalInfo,
  license,
}) => (showInfo
  ? (
    <SingleCardBodyInfo
      podStatus={podStatus}
      isSystemApp={isSystemApp}
      runningTag={runningTag}
      startedBy={startedBy}
      additionalInfo={additionalInfo}
      license={license}
    />
  )
  : <SingleCardBodyTile handleRun={handleRun} displayPicture={displayPicture} />);

export default SingleCardBody;
