import {
  Segment, Icon, Header, Button,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import Can from '../../../../permissions';
import { newDatabaseTablePanel } from '../../../../constants/ui_urls';

const NoDatabaseTables = () => (
  <Segment placeholder>
    <Header as="h4" icon>
      <Icon name="table" data-testid="database-table-icon" />
      {t('tables_list.no_tables')}
    </Header>
    <Can I="Write" this="WorkspaceDatabase">
      <Button
        icon={{ name: 'add', 'data-testid': 'add-table-icon' }}
        primary
        content={t('tables_list.actions.new_database_table')}
        href={newDatabaseTablePanel()}
      />
    </Can>
  </Segment>
);

export default NoDatabaseTables;
