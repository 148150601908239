import * as d3 from 'd3';
import _ from '../../underscore';
import $ from '../../jquery';
import View from '../loading_view';
import Axes from './axes';

export default View.extend({
  persistent: true,

  render() {
    const $el = $(this.el);
    $el.html('');
    $el.addClass('visualization');

    const data = _.clone(this.model.get('rows')).reverse();
    const buckets = _.pluck(data, 'bucket');

    const svg = d3.select(this.el).append('svg')
      .attr('class', 'chart frequency')
      .attr('width', 925)
      .attr('height', 340);

    this.axes = new Axes({
      el: svg,
      minXValue: 0,
      maxXValue: d3.max(data, d => d.count),
      xScaleType: 'numeric',
      yLabels: buckets,
      xAxisLabel: 'count',
      yAxisLabel: this.model.get('yAxis'),
      ticks: true,
      hasXGrids: true,
    });

    if (!$el.isOnDom()) return;

    this.axes.render();
    const scales = this.axes.scales();

    const plot = svg.append('svg:g').attr('class', 'plot');

    const boxHeight = Math.abs(scales.y.bandwidth() * 0.8);
    const boxOffset = Math.abs(scales.y.bandwidth() * 0.1);

    plot.selectAll('rect')
      .data(data)
      .enter().append('svg:rect')
      .attr('x', scales.x.range()[0])
      .attr('y', row => scales.y(row.bucket) + boxOffset)
      .attr('height', boxHeight)
      .attr('width', row => scales.x(row.count) - scales.x(0));
    svg.select('.xaxis').remove();
    svg.select('.yaxis').remove();
    this.axes.render();

    this.postRender();
  },
});

