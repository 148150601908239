import t from '../../intl';
import MemoNew from './memo_new_dialog';
import Insight from '../../models/insight';

export default MemoNew.extend({
  title: t('insight.new_dialog.title'),
  placeholder: t('insight.placeholder'),
  submitButton: t('insight.button.create'),

  events: {
    'click button.submit': 'save',
  },

  makeModel(...args) {
    this.model = new Insight({
      entityType: this.options.entityType,
      entityId: this.options.entityId,
      workspaceId: this.options.workspace.id,
    });
    this._super('makeModel', args);
  },
});
