import { Dropdown } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { workspaceUploadTokenUrl, uploadFile } from '../../../constants/ui_urls';
import Can from '../../../permissions';

const UploadFileLauncher = () => (
  <Dropdown.Item
    text={(t('workspaces.launcher.upload_files_or_data'))}
    href={uploadFile('files')}
    icon="upload"
  />
);

const UploadTokens = () => (
  <Dropdown.Item
    text={(t('workspaces.launcher.manage_upload_tokens'))}
    href={workspaceUploadTokenUrl()}
    icon="arrow circle up"
  />
);

const UploadLauncherMenu = () => (
  <Dropdown
    aria-label={t('workspaces.launcher.upload')}
    icon={{ name: 'upload', title: t('workspaces.launcher.upload') }}
    text={t('workspaces.launcher.upload')}
    direction="left"
    item
  >
    <Dropdown.Menu>
      <Can I="Upload to" this="WorkspaceStorage">
        <UploadFileLauncher />
      </Can>
      <Can I="Create tokens for" this="Workspace">
        <UploadTokens />
      </Can>
    </Dropdown.Menu>
  </Dropdown>
);

export default UploadLauncherMenu;
