import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import PickItems from '../pick_items_dialog';
import WorkfileSet from '../../collections/workfile_set';
import WorkfileFolder from '../../models/workfile_folder';
import DatasetExport from '../../models/dataset_export';
import Workfile from '../../models/workfile';
import chorus from '../../chorus';

export default PickItems.extend({
  constructorName: 'MetadataExportToFile',
  templateName: 'workfolders_attach',

  title: t('dataset.meta.export_to_file.modal_header'),
  submitButtonTranslationKey: 'actions.confirm',
  emptyListTranslationKey: 'workfolders.none',
  selectedEvent: 'files:selected',
  modelClass: 'Workfile',
  multiSelection: false,
  fileName: '',
  folder: '',
  exportFileType: 'xml',

  events: {
    'click .workfile-folder': 'changeRootFolder',
    'click .workfile-breadcrumb-folder': 'changeFolderViaBreadcrumb',
    'keyup .app-fileName': 'changeFileName',
  },

  makeModel(...args) {
    this._super('makeModel', args);
    this.options = _.extend({
      collection: new WorkfileSet([], {
        workspaceId: this.options.workspace.id,
        succinct: true,
      }),
    }, this.options);
  },

  setup() {
    this.workspace = this.options.workspace;
    this.datasetId = this.options.datasetId;

    if (this.options.exportFileType) {
      this.exportFileType = this.options.exportFileType;
    }
    if (this.options.fileName) {
      this.fileName = `${this.options.fileName}.${this.exportFileType}`;
    }

    this._super('setup');

    if (this.options.folder) {
      this.setFolder(this.createFolder(this.options.folder));
    } else {
      this.setFolder(this.workspace.defaultWorkfilesFolder());
    }
  },

  postRender() {
    this._super('postRender');
    this.$('ul').find(`[data-folder='${this.folder.topLevelFolder()}']`).addClass('active');
  },

  collectionModelContext(model) {
    return {
      name: model.get('fileName'),
      imageUrl: model.iconUrl({ size: 'icon' }),
    };
  },

  additionalContext() {
    return _.extend(this._super('additionalContext'), {
      folders: this.folderOptions(),
      fileName: this.fileName,
    });
  },

  folderOptions() {
    return _.map(this.workspace.workfileFolders(), folder => ({ data: folder }));
  },

  changeRootFolder(e) {
    e.preventDefault();
    this.$el.find('li').removeClass('active');
    $(e.target).addClass('active');
    this.setFolder(this.createFolder($(e.target).data('folder')));
  },

  changeFolderViaBreadcrumb(e) {
    e.preventDefault();
    this.setFolder(this.createFolder($(e.target).data('folder')));
  },

  changeFileName(e) {
    e.preventDefault();
    this.fileName = $(e.target).val();
    this.enableOrDisableSubmitButton();
  },

  enableOrDisableSubmitButton() {
    if (this.fileName) {
      this.$('input.app-fileName').removeClass('error');
      this.$('button.submit').removeAttr('disabled');
    } else {
      if (this.fileName) {
        this.$('input.app-fileName').addClass('error');
      }
      this.$('button.submit').attr('disabled', 'disabled');
    }
  },

  itemSelected(selectedItems) {
    let selectedItem = selectedItems;
    if (this.multiSelection) {
      selectedItem = selectedItems.slice(-1)[0];
    }
    if (selectedItem && selectedItem.isFolder()) {
      this.setFolder(this.createFolder(`${selectedItem.get('folder')}/${selectedItem.get('fileName')}`));
    } else {
      this.multiSelection ? this._super('itemSelected', selectedItems) : this._super('itemSelected', selectedItem);
    }
  },

  crumbs() {
    const crumbs = [];
    const parts = this.folder.name().split('/');

    _.each(parts, function addCrumb(data, idx) {
      crumbs.push({ label: data, data_folder: this.slice(0, idx + 1).join('/') });
    }, parts);

    crumbs.shift();
    return crumbs;
  },

  createFolder(folder) {
    return new WorkfileFolder({ folder, workspaceId: this.workspace.id });
  },

  setFolder(choice) {
    this.folder = choice;
    this.trigger('folder:changed', this.folder.name());
    this.paginationView.collection.attributes.foldersOnly = true;
    this.paginationView.collection.attributes.folder = this.folder.name();
    this.paginationView.fetchFirstPage();
  },

  resetSelection() {
    this.renderSubviews();
    this._super('resetSelection');
  },

  submit() {
    this.$('button.submit').attr('disabled', 'disabled');
    const folder = this.folder.get('folder');
    const fileName = this.fileName;

    this.datasetExport = new DatasetExport({
      folder,
      fileName,
      workspaceId: this.workspace.id,
      datasetId: this.datasetId,
    });
    this.datasetExport.save({}, {
      success: function onSuccess() {
        chorus.toast('dataset.meta.export_to_file.success', {
          file: `${folder}/${fileName}`,
        });
        this.closeModal();
      }.bind(this),
      error: function onFail(model) {
        this.showErrors();
        this.$('button.submit').removeAttr('disabled');
        chorus.toast('dataset.meta.export_to_file.failed', {
          reason: model.serverErrorMessage(),
          toastOpts: { theme: 'bad_activity' },
        });
      }.bind(this),
    });
  },
});
