import { Menu } from 'semantic-ui-react';
import useWorkspaceConfig from '../../adapters/config';
import { useCustomisationContext } from '../../customisation/Context';
import { useWorkspaceContext } from '../../adapters/Workspace';
import {
  ExternalLink,
  FairLink,
  HelpLink,
  HomeLink,
  FeedbackLink,
} from './NavBarLinks';
import WorkspaceBreadcrumbs from './WorkspaceBreadcrumbs';
import NavBarSearch from './NavBarSearch';
import NavBarToggle from './NavBarToggle';
import ProfileDropdown from './ProfileDropdown';
import './NavBar.less';

const NavBar = () => {
  const { workspace } = useWorkspaceContext();
  const { config } = useWorkspaceConfig();
  const {
    homeUrl, homeLogo, fairUrl, customLink, kbUrl, feedbackUrl,
  } = useCustomisationContext();

  const portalUrl = config?.administration_url;

  return (
    <Menu as="header" inverted attached size="small" id="aridhia-nav-bar">
      <HomeLink portalUrl={portalUrl} homeUrl={homeUrl} homeLogo={homeLogo} />
      <WorkspaceBreadcrumbs portalUrl={portalUrl} workspaceName={workspace?.name} />
      {fairUrl && <FairLink fairUrl={fairUrl} />}
      {customLink && <ExternalLink customLink={customLink} />}
      <NavBarSearch />
      <Menu.Menu position="right">
        <HelpLink helpUrl={kbUrl} />
        {/* If feedbackUrl does not exist, the feedback button is displayed here in its default state. If a custom feedbackUrl exists, the feedback button will be displayed in the ProfileDropdown. */}
        {!feedbackUrl && <FeedbackLink />}
        <ProfileDropdown />
        <NavBarToggle />
      </Menu.Menu>
    </Menu>
  );
};

export default NavBar;
