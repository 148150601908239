import t from '../intl';
import { FEATURE } from '../../utilities/features';

export default {
  fetchErrorTemplateName: 'fetch_errors',
  fetchErrorSubviews: {},
  fetchErrorEvents: {
    'click .close-tab': 'closeTab',
  },

  handleFetchErrorsFor(resource) {
    this.listenTo(resource, 'resourceNotFound', FEATURE.NEW_UX ? this.newUXNotFound : this.genericFetchError);
    this.listenTo(resource, 'resourceForbidden', this.genericFetchError);
    this.listenTo(resource, 'unprocessableEntity', this.genericFetchError);
  },
  stopHandlingFetchErrors(resource) {
    this.stopListening(resource, 'resourceNotFound');
    this.stopListening(resource, 'resourceForbidden');
    this.stopListening(resource, 'unprocessableEntity');
  },
  newUXNotFound() {
    this.templateName = 'new_ux_not_found';
    this.render();
  },
  genericFetchError() {
    this._old = {
      templateName: this.templateName,
      subviews: this.subviews,
      events: this.events,
    };

    this.templateName = this.fetchErrorTemplateName;
    this.subviews = this.fetchErrorSubviews;
    this.events = this.fetchErrorEvents;
    if (this.tab) {
      this._old.tab = {
        label: this.tab.get('label'),
        fileType: this.tab.get('fileType'),
      };
      this.tab.set('label', t('not_found.title'));
      this.tab.set('fileType', 'removed');
    }
    this.render();
    this.delegateEvents();
  },
  revertGenericFetchErrorPage() {
    if (this._old) {
      this.templateName = this._old.templateName;
      this.subviews = this._old.subviews;
      this.events = this._old.events;
      if (this._old.tab) {
        this.tab.set('label', this._old.tab.label);
        this.tab.set('fileType', this._old.tab.fileType);
      }
      delete this._old;
    }
    this.render();
  },
  closeTab() {
    this.tab.destroy();
  },
};
