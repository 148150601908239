import Collection from './collections';
import Service from '../models/service';

export default Collection.extend({
  model: Service,
  urlTemplate: 'api/admin/options/workspace',

  parse(response) {
    this.loaded = true;
    delete this.serverErrors;

    return response.resource.map(resource => ({
      name: resource.value,
      description: resource.description,
    }));
  },
});
