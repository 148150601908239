import _ from '../underscore';
import WorkspaceDataset from './workspace_dataset';
import { ChorusIdentifier } from '../../utilities/validation_regexes';

export default WorkspaceDataset.extend({
  constructorName: 'ChorusView',
  paramsToSave: ['id', 'objectName', 'schemaId', 'workspaceId', 'query', 'sourceObjectId', 'sourceObjectType'],

  showUrlTemplate: 'workspaces/{{workspace.id}}/chorus_views/{{id}}',

  urlTemplate(options) {
    if (options && options.download) {
      return 'api/workspaces/datasets/{{id}}/download.csv';
    } else if (this.duplicate) {
      return `api/workspaces/chorus_views/${this.get('sourceObjectId')}/duplicate`;
    } else if (options.method === 'read') {
      return 'api/workspaces/workspaces/{{workspace.id}}/datasets/{{id}}';
    }
    return 'api/workspaces/chorus_views/{{id}}';
  },

  schemaId() {
    return this.get('schema').id;
  },

  workspaceId() {
    return this.get('workspace').id;
  },

  isChorusView() {
    return true;
  },

  statistics() {
    const stats = this._super('statistics');
    if (!stats.datasetId) {
      stats.set({ workspace: this.get('workspace') });
      stats.datasetId = this.get('id');
    }

    return stats;
  },

  activities(...args) {
    const activities = this._super('activities', args);
    activities.attributes.workspace = this.get('workspace');
    return activities;
  },

  initialize() {
    this.sourceObjectId = this.get('sourceObjectId');
    this._super('initialize');
    this.joins = [];
    this.sourceObjectColumns = [];
    this.attributes.entitySubtype = 'CHORUS_VIEW';
    this.attributes.objectType = 'CHORUS_VIEW';
  },

  declareValidations(newAttrs) {
    this.require('objectName', newAttrs, 'dataset.chorusview.validation.object_name_required');
    this.requirePattern(
      'objectName',
      ChorusIdentifier(),
      newAttrs,
      'dataset.chorusview.validation.object_name_pattern',
    );
  },

  addJoin(sourceColumn, destinationColumn, joinType) {
    this.joins.push({
      sourceColumn, destinationColumn, joinType, columns: [],
    });
    destinationColumn.dataset.setDatasetNumber(this.joins.length + 1);
    this.trigger('change');
    this.aggregateColumnSet.add(destinationColumn.dataset.columns().models);
    this.aggregateColumnSet.trigger('change');
    this.aggregateColumnSet.trigger('join:added');
  },

  addColumn(column) {
    const columnList = this._columnListForDataset(column.dataset);

    if (!_.contains(columnList, column)) {
      const col = column;
      columnList.push(col);
      col.selected = true;
      col.trigger('change');
      this.trigger('change');
    }
  },

  removeColumn(column) {
    const columnList = this._columnListForDataset(column.dataset);
    if (columnList.indexOf(column) !== -1) {
      const col = column;
      columnList.splice(columnList.indexOf(col), 1);
      col.selected = false;
      col.trigger('change');
      this.trigger('change');
    }
  },

  removeJoin(dataset) {
    const joinToRemove = _.find(this.joins, join => join.destinationColumn.dataset === dataset);
    this.joins = _.without(this.joins, joinToRemove);

    this.removeDependentJoins(joinToRemove);

    this._reorderJoins();

    const columnsToRemove = this.aggregateColumnSet.select(column => column.dataset === dataset);
    this.aggregateColumnSet.remove(columnsToRemove);

    this.trigger('change');
  },

  removeDependentJoins(removedJoin) {
    const dependentJoins = _.filter(
      this.joins,
      join => join.sourceColumn.dataset === removedJoin.destinationColumn.dataset,
    );

    _.each(dependentJoins, _.bind(function onRemove(join) {
      this.removeJoin(join.destinationColumn.dataset);
    }, this));
  },

  generateSelectClause() {
    const names = _.map(this._allColumns(), column => column.quotedName());

    return `SELECT ${names.length ? names.join(', ') : '*'}`;
  },

  valid() {
    return this._allColumns().length > 0;
  },

  getJoinDatasetByCid(cid) {
    return _.find(this.joins, join => join.destinationColumn.dataset.cid === cid).destinationColumn.dataset;
  },

  _reorderJoins() {
    _.each(this.joins, (join, index) => {
      join.destinationColumn.dataset.setDatasetNumber(index + 2);
    });
  },

  _allColumns() {
    return this.sourceObjectColumns.concat(_.flatten(_.pluck(this.joins, 'columns')));
  },

  _columnListForDataset(dataset) {
    if (dataset === this.sourceObject) {
      return this.sourceObjectColumns;
    }
    const join = _.find(this.joins, j => dataset === j.destinationColumn.dataset);
    if (join) {
      return join.columns;
    }
    return null;
  },
});
