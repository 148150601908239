import { t } from 'i18n-js';
import { Form } from 'semantic-ui-react';
import { supportedFileTypes } from '../constants';

const dropdownOptions = (
  Object.values(supportedFileTypes).map((extension) => {
    const container = {};
    const translation = `workfiles.header.menu.new.${extension}`;

    container.key = extension;
    container.text = t(translation);
    container.value = extension;

    return container;
  })
);

const FileTypeDropdown = ({ handleDropdownChange, value }) => (
  <Form.Select
    fluid
    className="modal-dropdown"
    label={t('modals.new_workfile.type')}
    options={dropdownOptions}
    onChange={handleDropdownChange}
    value={value}
  />
);

export default FileTypeDropdown;
