import { Actions } from 'flexlayout-react';

let dispatchLayoutChange = () => null;
let visitTabs = () => null;

export const renameTab = (tabId, name) => {
  dispatchLayoutChange(Actions.updateNodeAttributes(tabId, { helpText: name }));
  dispatchLayoutChange(Actions.renameTab(tabId, name));
};

export const setTabIcon = (tabId, descriptor) => {
  dispatchLayoutChange(Actions.updateNodeAttributes(tabId, { icon: descriptor }));
};

export const deleteTab = (tabId) => {
  dispatchLayoutChange(Actions.deleteTab(tabId));
};

export const deleteTabsInFolder = (folder) => {
  visitTabs((tab) => {
    if (tab.getHelpText()?.startsWith(folder)) {
      deleteTab(tab.id);
    }
  });
};

const useTabMonitor = (model) => {
  visitTabs = (fn) => model.visitNodes((node) => { if (node.getType() === 'tab') { fn(node); } });
  dispatchLayoutChange = (action) => setTimeout(() => model.doAction(action), 0);
};

export default useTabMonitor;
