/* global document:false */

import ace from 'ace-builds/src-noconflict/ace';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-emmet';
import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import View from '../loading_view';

const AceEditorView = View.extend({
  templateName: 'ace_editor_view',
  constructorName: 'AceEditorView',
  bindCallbacks: $.noop,

  setup() {
    delete chorus.rsh.stateManager.unsavedFiles[this.cid];
    this.listenTo(chorus.page, 'resized', this.editorResize);
  },

  teardown() {
    delete this.options.onBlur;
    delete this.options.onChange;
    delete this.editor;
    this._super('teardown', [true]); // eslint-disable-line no-underscore-dangle
  },

  setupAce() {
    if (!this.torndown) {
      const self = this;
      const editor_div = this.$('.text_editor')[0];
      const editor = ace.edit(editor_div);
      this.editor = editor;
      this.editor.setOptions({
        minLines: 1,
        fontSize: 14,
        wrap: true,
        showPrintMargin: false,
        animatedScroll: false,
        fixedWidthGutter: true,
        highlightActiveLine: false,
        mode: this.options.mode,
        theme: 'ace/theme/chrome',
        autoScrollEditorIntoView: true,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: false,
        enableSnippets: true,
        enableEmmet: true,
      });

      // Prevent access to ACE style pop-up menu
      editor.commands.removeCommand('showSettingsMenu');
      // And pointless next-error
      editor.commands.removeCommand('goToPreviousError');
      editor.commands.removeCommand('goToNextError');

      // ACE has clobbered the Run-Menu hotkeys.
      // Re-establish them with ACE catching the keys.
      var hotkeys = (this.options.hotkeys == null) ? {} : this.options.hotkeys;
      Object.keys(hotkeys).forEach(function (key) {
        let method = hotkeys[key];
        const keybind = chorus.hotKeyMeta + '-' + key.toUpperCase();
        editor.commands.addCommand({ 
          name: 'run-' + key,
          bindKey: {win: keybind, mac: keybind},
          exec: function(editor) { method(); },
          readOnly: true
        });
      });

      const content = this.options.model.content();
      if (content) {
        this.editor.session.setValue(content, -1);
      }

      this.selectionCallback = this.options.onSelectionChange;
      editor.on('changeSelection', _.bind(this.onChangeSelection, this));

      editor.on('blur', () => {
        self.onBlur();
      });
      editor.on('change', _.bind(this.onChange, this));

      if (this.options.beforeEdit) {
        this.options.beforeEdit.call(this);
      }

      this.onFocus();
    }
  },

  postRender() {
    _.defer(_.bind(this.setupAce, this));
  },

  onChangeSelection() {
    this.selectionCallback(this.editor.getSelection().isEmpty());
  },

  onBlur() {
    this.trigger('blur');
  },

  onFocus() {
    this.editorResize();
    this.editor.focus();
  },

  onChange() {
    if (this.options.tab){
      this.options.tab.set('unsaved', true);
    }
  },

  editorResize() {
    if (this.$('.ace_editor').is(':visible')) {
      if (this.editor && this.$el.length > 0) {
        const tTop = this.$el.offset().top;
        const pHeight = document.body.offsetHeight;

        const resultsConsole = this.parentView.parentView.$('.app-resultsConsole');
        const resultsConsoleHeight = (resultsConsole.length > 0) ? resultsConsole.outerHeight() : 0;
        const editorHeight = pHeight - tTop - resultsConsoleHeight - 5;
        this.$('.text_editor')[0].style.height = `${editorHeight}px`;
        this.editor.resize();
      }
    }
  },
});

// delegate methods to the ACE editor
_.each([
  'getValue', 'setValue', 'getOption', 'setOption', 'getSelection', 'getSession',
  'focus', 'getCursorPosition', 'moveCursorToPosition',
], (method) => {
  AceEditorView.prototype[method] = function editorDelegate(...args) {
    return this.editor && this.editor[method](...args);
  };
});

export default AceEditorView;
