import _ from '../../underscore';
import $ from '../../jquery';
import Bare from '../bare_view';

export default Bare.extend({
  tagName: 'ul',
  constructorName: 'MenuView',

  templateName: 'components/menu',

  events: {
    'click li a:not([disabled=disabled])': 'itemClicked',
  },

  setup(options) {
    const args = {
      content: $(this.el),
      show: {
        event: 'click',
        delay: 0,
      },
      hide: 'unfocus',
      style: {
        tip: false,
        classes: 'rsh-qtip-corner',
      },
      position: {
        my: 'top left',
        at: 'bottom left',
      },
    };

    if (options.orientation === 'right') {
      args.position.my = 'top left';
    } else if (options.orientation === 'left') {
      args.position.my = 'top right';
    }

    options.launchElement.on('click', (e) => { e.preventDefault(); });
    options.launchElement.qtip(args);
    this.qtip = options.launchElement.data('qtip');
    this.render();
  },

  postRender() {
    _.each(this.$('li a'), function applyName(el, i) {
      $(el).attr('data-menu-name', this.options.items[i].name);
    }, this);

    $(this.el).addClass(this.options.additionalClass);
  },

  itemClicked(e) {
    e.preventDefault();
    const itemName = $(e.currentTarget).attr('data-menu-name');
    this.selectItem(itemName);
    this.qtip.hide();
  },

  context() {
    return { items: this.options.items };
  },

  selectItem(name) {
    const selectedItem = _.find(this.options.items, item => item.name === name);

    if (!selectedItem) { return; }
    if (selectedItem.onSelect) { selectedItem.onSelect.call(this, selectedItem.data); }
    if (this.options.onChange) { this.options.onChange.call(this, selectedItem.data); }

    this.showSelectedItem(name);
  },

  showSelectedItem(name) {
    if (this.options.checkable) {
      const li = this.$(`li a[data-menu-name=${name}]`).parent();
      this.$('li').removeClass('selected');
      li.addClass('selected');
    }
  },

  disableItem(name) {
    this.$(`li a[data-menu-name='${name}']`).attr('disabled', 'disabled');
  },

  enableItem(name) {
    this.$(`li a[data-menu-name='${name}']`).attr('disabled', false);
  },
});
