import { useState } from 'react';
import { t } from 'i18n-js';
import Backbone from 'backbone';
import DatasetShowView from '../../../../../application/views/dataset/dataset_show_view';
import ReactToBackboneWrapper from '../../ReactToBackboneWrapper';
import { renameTab } from '../../../tabMonitor';
import NotAuthorised from '../../../../components/errors/NotAuthorised';

const NewViewPanel = ({ tab, workspace }) => {
  const { component, config, id } = tab;
  const dataset = workspace.getDataset(config.params.table_id);

  const [view] = useState(() => new DatasetShowView({
    workspace,
    tab: new Backbone.Model(),
    dataset,
    contentDetailsOptions: { inDeriveChorusView: true },
  }));

  if (!workspace.hasPermission('WorkspaceDatabase.Write')) {
    return <NotAuthorised />;
  }

  const callback = () => renameTab(id, t('layout.tabs.new_view', { name: view.dataset.get('objectName') }));

  return (
    <ReactToBackboneWrapper
      view={view}
      tabId={id}
      component={component}
      onceLoadedCallback={callback}
    />
  );
};

export default NewViewPanel;
