import { Ability, AbilityBuilder } from '@casl/ability';

const configurePermissions = (permissions) => {
  const { can: allow, build } = new AbilityBuilder(Ability);

  // Build and configure a permissions context from the API permissions
  // This can then be used with the casl permissions library
  for (const permission of permissions) {
    const [item, ...action] = permission.split('.');
    allow(action.join('.'), item);
  }

  const permissionsContext = build();

  // Add permissions that are not covered by API
  // this allows us to be more precise with our permissions or rename them to suit our needs
  if (permissionsContext.can('Interactive', 'Workspace')) {
    allow('Interact with', 'Workspace');
  }

  if (permissionsContext.can('Write', 'WorkspaceStorage')) {
    allow('Upload to', 'WorkspaceStorage');
  }

  if (permissionsContext.can('ContributeViaApi', 'Workspace')) {
    allow('Create tokens for', 'Workspace');
  }

  if (permissionsContext.can('WriteMeta', 'Workspace') || permissionsContext.can('WriteMembers', 'Workspace')) {
    allow('write meta or members', 'Workspace');
  }

  if (permissionsContext.can('Request', 'Workspace')) {
    allow('Request', 'VirtualMachine');
  }

  if (permissionsContext.can('Review', 'WorkspaceInboundAirlock') || permissionsContext.can('Read', 'WorkspaceInboundAirlock')) {
    allow('View', 'WorkspaceInboundAirlock');
  }

  return build();
};

export default configurePermissions;
