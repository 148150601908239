/**
 * Constants that are used in multiple places
 */

export const TUNNEL_PATH = '/api/guac/tunnel';

// Copied from guacamole-common where they are private
export const STATE_IDLE = 0;
export const STATE_CONNECTING = 1;
export const STATE_WAITING = 2;
export const STATE_CONNECTED = 3;
export const STATE_DISCONNECTING = 4;
export const STATE_DISCONNECTED = 5;

// VM status taking into account service status

export const VM = {
  NOT_FOUND: 0,
  HEALTHY: 1,
  POWERED_OFF: 2,
  FETCHING: 3,
  UNHEALTHY: 4,
};
