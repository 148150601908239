import { t } from 'i18n-js';
import FilesTableRow from './FilesTableRow';
import FilesMultiSelectActions from './FilesMultiSelectActions';
import { sizeAsString } from '../../../../utilities/helper';
import { formatDateTime } from '../../../../helpers/dates';
import { FileProvider, useFilesContext } from '../Context';
import NoFilesMessage from './NoFilesMessage';
import {
  ColumnsHeader,
  EmptyMessage,
  Filter,
  MultiSelectIcon,
  Paging,
  SelectAll,
  Stats,
  TableHeader,
  TableWrapper,
} from '../../../../query_table';
import { orderDirections, orderTypes } from '../../../constants';
import RefreshButton from '../../../../components/buttons/RefreshButton';
import FilesTableHeaderError from './FilesTableHeaderError';
import { useOfficeDiscoveryContext } from '../../../OfficeDiscoveryContext';

const state = (file) => ({
  id: file.id,
  name: file.file_name,
  folder: file.folder,
  type: file.file_type,
  date: file.updated_at ? formatDateTime(file.updated_at) : null,
  size: sizeAsString(file.content_length),
  isEditable: !file.read_only && !file.destroying,
  isDeleting: file.destroying,
});

const renderBodyRow = (file) => (
  <FileProvider file={state(file)} key={file.id}>
    <FilesTableRow key={file.id} />
  </FileProvider>
);

const FilesSelectAll = () => {
  const {
    files,
    isFetching,
    isError,
    selectedFiles,
    handleSelectedFiles,
  } = useFilesContext();

  return (
    <SelectAll
      tableData={files}
      selectedTableData={selectedFiles}
      handleSelectedTableData={handleSelectedFiles}
      isFetching={isFetching}
      isError={isError}
    />
  );
};

const orderDirection = (orderByDirection) => (orderByDirection === orderDirections.ASC ? 'ascending' : 'descending');

const columns = (orderBy, orderByDirection) => ([
  { name: 'multi_select', content: <FilesSelectAll />, collapsing: true },
  {
    name: 'file_name',
    content: t('file_list.table_header.file_name'),
    direction: orderBy === orderTypes.FILE_NAME ? orderDirection(orderByDirection) : null,
    unsortable: false,
  },
  { name: 'type', content: t('file_list.table_header.type') },
  { name: 'size', content: t('file_list.table_header.size') },
  {
    name: 'updated_at',
    content: t('file_list.table_header.updated_at'),
    direction: orderBy === orderTypes.UPDATED_AT ? orderDirection(orderByDirection) : null,
    unsortable: false,
  },
  { name: 'actions', collapsing: true },
]);

const FilesTable = () => {
  const {
    files,
    selectedFiles,
    isFetching: isFilesFetching,
    handleRefreshButton: refetchFiles,
    isError: isFilesError,
    handleFilterChange,
    pagination,
    setPage,
    orderBy,
    orderByDirection,
    handleSort,
  } = useFilesContext();

  const {
    isError: isOfficeError,
    isFetching: isOfficeFetching,
    refetch: refetchOffice,
  } = useOfficeDiscoveryContext();

  const isFetching = isFilesFetching || isOfficeFetching;
  const refetch = () => {
    refetchFiles();
    refetchOffice();
  };

  return (
    <TableWrapper
      tableData={files}
      columns={columns()}
      tableHeader={(
        <TableHeader
          columns={columns(orderBy, orderByDirection)}
          multiSelectIcon={<MultiSelectIcon selectedItems={selectedFiles} />}
          multiSelectActions={<FilesMultiSelectActions />}
          errorComponent={<FilesTableHeaderError isFilesError={isFilesError} isOfficeError={isOfficeError} />}
          filter={<Filter onFilter={handleFilterChange} />}
          refresh={<RefreshButton isFetching={isFetching} refetch={refetch} />}
        />
      )}
      columnsHeader={<ColumnsHeader columns={columns(orderBy, orderByDirection)} onSortBy={handleSort} />}
      renderBodyRow={renderBodyRow}
      emptyWrapper={(
        <EmptyMessage
          isFetching={isFilesFetching}
          tableData={files}
          columns={columns()}
          emptyContent={<NoFilesMessage />}
        />
      )}
      stats={<Stats shown={files.length} total={pagination.records} />}
      pagination={<Paging total={pagination.records} onPageChange={setPage} activePage={pagination.page} />}
    />
  );
};

export default FilesTable;
