import React from 'react';
import { Checkbox, Form, FormInput } from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';

const FormDestination = ({ destination, updateDestination, useDestination, updateUseDestination }) => (
  <Form.Group inline>
    <Form.Checkbox
      id="useDestination"
      label={t('upload_token.upload_form.use_destination')}
      checked={useDestination}
      onChange={updateUseDestination}
      width={3}
    />
    <Form.Input
      id="destination"
      disabled={!useDestination}
      defaultValue={destination}
      placeholder={t('upload_token.upload_form.destination_placeholder')}
      onBlur={updateDestination}
      width={6}
    />
  </Form.Group>
);

const mapModelsToProps = ({ uploadToken }) => ({
  destination: uploadToken.get('destination'),
  useDestination: uploadToken.get('useDestination'),
  updateDestination: (e) => {
    uploadToken.updateDestination(e.currentTarget.value);
  },
  updateUseDestination: (_e, value) => {
    uploadToken.updateUseDestination(value.checked);
  }
});

const options = {
  debounce: true,
  events: {
    uploadToken: ['change:destination', 'change:useDestination'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(FormDestination);
