import { t } from 'i18n-js';
import {
  Segment, Grid, Header, Label,
} from 'semantic-ui-react';
import { useDatabaseTableCreationContext } from '../Context';
import MarkdownRender from '../../../../components/text_editors/MarkdownRender';
import { isRichText } from '../../../../utilities/editor';

const DatabaseTableCreationConfirmDescription = () => {
  const { newDatabaseTableState } = useDatabaseTableCreationContext();
  const includedColumns = newDatabaseTableState.table.columns.filter((column) => !column.disabled);
  const columnsLabelText = includedColumns.length === 1
    ? t('database_tables_new.columns.column')
    : t('database_tables_new.columns.columns');
  const tablename = newDatabaseTableState.table.data_table_name_from_file
    || newDatabaseTableState.table.data_table_name;

  return (
    <Segment secondary>
      <Grid columns={3} divided>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3" content={tablename} className="margin-none" />
            {newDatabaseTableState.table.metadata.title && (
              <>
                <Header as="h5" content={t('database_tables_new.metadata.from_scratch.title')} className="margin-none" />
                <p>{newDatabaseTableState.table.metadata.title}</p>
              </>
            )}
          </Grid.Column>
          <Grid.Column>
            {newDatabaseTableState.table.metadata.description && (
              <>
                <Header as="h5" content={t('database_tables_new.metadata.from_scratch.description')} className="margin-none" />
                <div className="six-line-truncate">
                  <MarkdownRender
                    value={newDatabaseTableState.table.metadata.description}
                    isRichText={isRichText(newDatabaseTableState.table.metadata.description)}
                  />
                </div>
              </>
            )}
            {newDatabaseTableState.table.metadata.url && (
              <>
                <Header as="h5" content={t('database_tables_new.metadata.from_scratch.url')} className="margin-none margin-top-small" />
                <a href={newDatabaseTableState.table.metadata.url} target="_blank" rel="noreferrer">{newDatabaseTableState.table.metadata.url}</a>
              </>
            )}
          </Grid.Column>
          <Grid.Column>
            <Label content={`${includedColumns.length} ${columnsLabelText}`} className="float-right" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default DatabaseTableCreationConfirmDescription;
