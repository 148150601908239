import React, { Component } from 'react';
import { Button, Container } from 'semantic-ui-react';
import { t } from 'i18n-js';
import MetadataCopyConfirm from './MetadataCopyConfirm';

const ImportFromFileButton = ({ onClick }) => (
  <Button onClick={onClick}>
    {t('dataset.meta.import_from_file.button')}
  </Button>
);

const CopyFromDatasetButton = ({ onClick }) => (
  <Button onClick={onClick}>
    {t('dataset.meta.copy_from_dataset.button')}
  </Button>
);

const ExportToFileButton = ({ onClick }) => (
  <Button onClick={onClick}>
    {t('dataset.meta.export_to_file.button')}
  </Button>
);

export default class MetadataButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openConfirm: false,
      targetModal: '',
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  handleCancel() {
    this.setState({ openConfirm: false, targetModal: '' });
  }

  handleConfirm(modal) {
    this.props.toggleModal(`${modal}_modal`);
    this.setState({ openConfirm: false, targetModal: modal });
  }

  onClick(button) {
    this.setState({ openConfirm: true, targetModal: button });
  }

  render() {
    const { hasMetadata } = this.props;
    return (
      <Container className="metadata-buttons">
        <ImportFromFileButton
          onClick={() => (
            hasMetadata ? this.onClick('metadata_import_from_file') : this.handleConfirm('metadata_import_from_file')
          )}
        />
        <CopyFromDatasetButton
          onClick={() => (
            hasMetadata ? this.onClick('metadata_copy_from_dataset') : this.handleConfirm('metadata_copy_from_dataset')
          )}
        />
        {hasMetadata
          && <ExportToFileButton onClick={() => this.handleConfirm('metadata_export_to_file')} />}
        <MetadataCopyConfirm
          open={this.state.openConfirm}
          onCancel={this.handleCancel}
          onConfirm={() => this.handleConfirm(this.state.targetModal)}
        />
      </Container>
    );
  }
}
