import chorus from '../../../chorus';
import _ from '../../../underscore';
import $ from '../../../jquery';
import t from '../../../intl';
import WorkspaceManagementAdministratorsList from './administrators_list';
import WorkspaceManagementAdditionalServices from './additional_services';
import WorkspacesManagementTermsAndConditions from '../../../dialogs/workspaces/management/terms_and_conditions';
import Services from '../../../collections/services';
import ManagementUsers from '../../../collections/management/users';
import View from '../../loading_view';
import ClEditor from '../../../mixins/cl_editor_helpers';
import Routing from '../../../mixins/routing';

export default View.include(ClEditor, Routing).extend({
  templateName: 'workspace/management/manage',
  subviews: {
    '.workspace_member_list': 'workspaceMemberList',
    '.app-sidebarBasic': 'sidebar',
    '.workspace-additional-services': 'additionalServices',
  },

  events: {
    'click .create-workspace': 'create',
    'click .update-workspace': 'update',
    'click .cancel': 'goBack',
  },

  additionalContext() {
    return {
      editing: this.options.editing,
      active: this.activeOrNew(),
    };
  },

  setup() {
    this.original_admin_members = this.options.admins;
    this.resulting_admins = this.options.admins.clone();

    this.original_services = this.options.services;
    this.resulting_services = this.options.services.clone();


    this.admins = new ManagementUsers();
    this.admins.sortAsc('firstName');
    this.admins.fetch();

    this.workspaceMemberList = new WorkspaceManagementAdministratorsList({
      administrators: this.admins,
      collection: this.resulting_admins,
      editing: this.options.editing,
    });

    new Services().fetch({
      success: function fetchComplete(data) {
        this.additionalServices = new WorkspaceManagementAdditionalServices({
          collection: this.resulting_services,
          available_services: data,
        });
        this.render();
      }.bind(this),
    });
  },

  activeOrNew() {
    return (!this.options.editing || this.model.get('status') === 'active');
  },

  postRender() {
    if (this.activeOrNew()) {
      _.defer(_.bind(function addToolButtons() {
        const editor = this.makeEditor($(this.el), '.toolbar', 'workspace_summary', { width: '100%', height: 100 });
        _.delay(() => {
          editor.refresh();
        }, 500);
      }, this));
    }
  },

  create(e) {
    e.preventDefault();

    if (this.performValidation()) {
      const termsAndConditions = new WorkspacesManagementTermsAndConditions();
      termsAndConditions.launchModal();
      termsAndConditions.on('accepted', () => {
        this.model.createWorkspace({
          name: this.$('input[name=workspace_name]').val().trim(),
          reference: this.$('input[name=workspace_reference]').val().trim(),
          summary: this.$('textarea[name=workspace_summary]').val().trim(),
          admins: this.resulting_admins,
          services: this.resulting_services,
        }, () => {
          this.navigate('workspaces');
          chorus.toast('workspaces.admin.create.success.notification');
        }, (error) => {
          chorus.toast(
            'workspaces.admin.workspace_add.restricted',
            { status: error, toastOpts: { theme: 'bad_activity' } },
          );
        });
      });
    }
  },

  serviceChanges() {
    const toAdd = this.resulting_services.models.filter(service => !this.original_services.get(service));
    const toDelete = this.original_services.models.filter(service => !this.resulting_services.get(service));

    const markedAsDelete = toDelete.map((service) => {
      service.set('deleted', true);
      return service;
    });

    return toAdd.concat(markedAsDelete);
  },

  update() {
    if (this.model.attributes.status !== 'active') {
      $(':focus').blur();
      chorus.toast(
        'workspaces.admin.workspace_edit.restricted',
        { status: `Status is '${this.model.attributes.status}'` },
      );
      return;
    }

    if (this.performValidation()) {
      const adminsDiff = this.resulting_admins.models.filter((admin) => {
        const existing = this.original_admin_members.get(admin);
        if (existing) {
          return admin.get('deleted') === true || typeof admin.changed.owner === 'boolean';
        }

        return true;
      });
      this.model.updateWorkspace({
        name: this.$('input[name=workspace_name]').val().trim(),
        uuid: this.model.get('uuid'),
        reference: this.$('input[name=workspace_reference]').val().trim(),
        summary: this.$('textarea[name=workspace_summary]').val(),
        admins: adminsDiff,
        services: this.serviceChanges(),
      }, () => {
        this.navigate('workspaces');
        chorus.toast('workspaces.admin.update.success.notification');
      }, (error) => {
        chorus.toast('workspaces.admin.workspace_edit.restricted', { status: error });
      });
    }
  },

  goBack() {
    window.history.back();
  },

  performValidation() {
    this.clearErrors();

    const $name = $('input[name=workspace_name]');
    if ($name.val().length === 0) {
      this.markInputAsInvalid($name, t('workspaces.admin.create.name_required'), true);
      return false;
    }

    if (!/^[a-z][a-z0-9_]{2,19}$/.test($name.val())) {
      this.markInputAsInvalid($name, t('workspaces.admin.create.name_validation'), true);
      return false;
    }

    if (this.resulting_admins.length === 0) {
      $('.workspace_management_administrators_list p').css('color', 'red');
      return false;
    }

    return true;
  },
});
