import { useEffect, useState } from 'react';
import Backbone from 'backbone';
import RTools from '../../../../../application/views/rtools/rtools_view';
import ReactToBackboneWrapper from '../../ReactToBackboneWrapper';

const RConsolePanel = ({ tab, workspace }) => {
  const { component, config, id } = tab;
  let reRender = true;

  const [view] = useState(() => {
    const backboneTab = new Backbone.Model({ active: true });
    backboneTab.urlParams = () => ([config.params.console_id]);
    reRender = false;
    return new RTools({ model: workspace, tab: backboneTab });
  });

  useEffect(() => {
    if (reRender) {
      view.options.tab.urlParams = () => ([config.params.console_id]);
      view.performCommandWhenReady();
    }
  }, [view, config.params, reRender]);

  return (
    <ReactToBackboneWrapper
      view={view}
      tabId={id}
      component={component}
    />
  );
};

export default RConsolePanel;
