import { Table, Label } from 'semantic-ui-react';
import { t } from 'i18n-js';

export const DatabaseTableColumnsTableRowNarrow = ({ column }) => (
  <Table.Row key={column.name} aria-label={t('database_table.accessibility.database_table_row_narrow')}>
    <Table.Cell>
      <p>
        <strong>{column.name}</strong>
      </p>
      <Label size="small" content={column.data_type} className="margin-bottom-medium margin-left-none" />
      {column.example && <p>{column.example}</p>}
      {column.label && <p>{column.label}</p>}
      {column.description && <p>{column.description}</p>}
    </Table.Cell>
  </Table.Row>
);

const DatabaseTableColumnsTableRow = ({ column }) => (
  <Table.Row key={column.name} aria-label={t('database_table.accessibility.database_table_row')}>
    <Table.Cell>
      {column.name}
    </Table.Cell>
    <Table.Cell collapsing>
      <Label size="small" content={column.data_type} />
    </Table.Cell>
    <Table.Cell className="two-line-truncate" title={column.example}>
      <div>
        {column.example}
      </div>
    </Table.Cell>
    <Table.Cell className="two-line-truncate" title={column.label}>
      <div>
        {column.label}
      </div>
    </Table.Cell>
    <Table.Cell className="two-line-truncate" title={column.description}>
      <div>
        {column.description}
      </div>
    </Table.Cell>
  </Table.Row>
);

export default DatabaseTableColumnsTableRow;
