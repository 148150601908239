import { Segment } from 'semantic-ui-react';
import OfficeHeader from './OfficeHeader';
import OfficeFrame from './OfficeFrame';

const OfficeView = ({
  fileId,
  fileName,
  favIconUrl,
  documentUrl,
  token,
  onFormLoaded,
}) => (
  <Segment.Group className="aridhia-panel-wrapper flex">
    <OfficeHeader favIconUrl={favIconUrl} fileName={fileName} />
    <OfficeFrame
      fileId={fileId}
      fileName={fileName}
      documentUrl={documentUrl}
      token={token}
      onFormLoaded={onFormLoaded}
    />
  </Segment.Group>
);

export default OfficeView;
