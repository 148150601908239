import t from '../../intl';
import DataPreviewTask from './data_preview_task';

export default DataPreviewTask.extend({
  urlTemplateBase: 'api/workspaces/datasets/preview_sql',

  name() {
    return this.get('objectName') || t('dataset.sql.filename');
  },
});
