import { Label, Table, Checkbox } from 'semantic-ui-react';
import { t } from 'i18n-js';
import DatabaseTablesRowNameCell from './DatabaseTablesRowNameCell';
import DatabaseTablesRowDropdown from './DatabaseTablesRowDropdown';
import { useDatabaseTablesContext } from '../Context';
import { useDatabaseTableContext } from '../../../Context';
import { sanitiseTableType } from '../../../helper';

const TableTypeCell = ({ tableType }) => (
  <Table.Cell collapsing>
    <Label content={sanitiseTableType(tableType)} />
  </Table.Cell>
);

const TableSizeCell = ({ tableSize }) => (
  <Table.Cell collapsing>
    {tableSize && <Label content={tableSize} />}
  </Table.Cell>
);

const TableDescriptionCell = ({ tableDescription }) => (
  <Table.Cell className="two-line-truncate">
    <div>{tableDescription}</div>
  </Table.Cell>
);

const TableDateCell = ({ tableDate }) => (
  <Table.Cell className="no-wrap">{tableDate}</Table.Cell>
);

const DatabaseTablesTableRow = () => {
  const {
    tableId,
    tableName,
    tableDescription,
    tableType,
    tableDate,
    tableSize,
  } = useDatabaseTableContext();
  const {
    selectedTables,
    handleSelectedTables,
  } = useDatabaseTablesContext();

  return (
    <Table.Row aria-label={t('database_table.accessibility.database_table_row')}>
      <Table.Cell collapsing>
        <Checkbox
          value={tableId}
          onChange={handleSelectedTables}
          checked={(selectedTables?.findIndex((item) => item.id === tableId)) >= 0}
          name={tableName}
        />
      </Table.Cell>
      <DatabaseTablesRowNameCell />
      <TableTypeCell tableType={tableType} />
      <TableSizeCell tableSize={tableSize} />
      <TableDescriptionCell tableDescription={tableDescription} />
      <TableDateCell tableDate={tableDate} />
      <Table.Cell textAlign="center" collapsing>
        <DatabaseTablesRowDropdown />
      </Table.Cell>
    </Table.Row>
  );
};

export default DatabaseTablesTableRow;
