import { useVirtualMachines } from '../../service';
import { VM } from './constants';
import { statuses } from '../../constants';

const transformToStatus = (vm) => {
  if (!vm) {
    return VM.NOT_FOUND;
  }
  if (vm.power_state !== statuses.RUNNING) {
    return VM.POWERED_OFF;
  }
  return VM.HEALTHY;
};

export const processResponse = (vmName) => (response) => (
  transformToStatus(response.data.find((v) => v.name === vmName))
);

export const useVmStatusQuery = (vmName) => (
  useVirtualMachines({ select: processResponse(vmName) })
);
