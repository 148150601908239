export function userInitials(name) {
  const parts = name.toUpperCase().split(' ');
  if (parts.length > 1) {
    return `${parts[0].charAt(0)}${parts[1].charAt(0)}`;
  }
  return `${parts[0].charAt(0)}`;
}

/**
 * Generate a colour based on user's name
 * Calculate an index based on number of characters and vowels in username
 * This keeps it constant each time we calculate but fairly unique
 *
 * @param name
 * @returns {*}
 */
export function avatarBackgroundColour(name) {
  const colours = [
    '#E9421E',
    '#B6271B',
    '#850F19',
    '#6E1932',
    '#562150',
    '#2C2A4C',
    '#0C3247',
    '#1B5A72',
    '#2C88A2',
    '#34867A',
    '#3C8556',
    '#5B8C41',
    '#7E922F',
    '#9EAE02',
    '#BE8C00',
    '#995F19',
  ];

  const vowels = (name.match(/[aeiou]/gi) || []).length;
  const index = (name.length + vowels) % colours.length;

  return colours[index];
}

export default {
  userInitials,
  avatarBackgroundColour,
};
