import { Message, Popup, Icon } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { metadataAttributesMissing } from '../helper';

export const DatabaseTableCreationTableInvalidNameWarning = () => (
  <Message warning>
    <Message.Header>{t('database_tables_new.new_table.validation.title')}</Message.Header>
    <Message.Content>
      {t('database_tables_new.new_table.validation.list_header')}
      <Message.List>
        <Message.Item>{t('database_tables_new.new_table.validation.list_item_1')}</Message.Item>
        <Message.Item>{t('database_tables_new.new_table.validation.list_item_2')}</Message.Item>
      </Message.List>
    </Message.Content>
    <Message.Content>
      {t('database_tables_new.new_table.validation.reason')}
    </Message.Content>
  </Message>
);

export const DatabaseTableCreationTableCsvIsEmpty = () => (
  <Message warning>
    <Message.Header>{t('database_tables_new.new_table.no_content.title')}</Message.Header>
    <Message.Content>
      {t('database_tables_new.new_table.no_content.message')}
    </Message.Content>
  </Message>
);

export const DatabaseTableCreationTDFFileIsLinked = () => {
  const mountNode = document.querySelector('#popup-wrapper') || document.body;
  return (
    <Popup
      trigger={(
        <span className="float-right info-colour">
          {t('database_tables_new.metadata.linked_tdf.title')}
          <Icon name="info circle" data-testid="linked-tdf-popup-icon" />
        </span>
      )}
      mountNode={mountNode}
    >
      <Popup.Content>
        {t('database_tables_new.metadata.linked_tdf.message')}
      </Popup.Content>
    </Popup>
  );
};

const MissingMetadataList = ({ metadata }) => (
  <>
    <Message.Content>
      {t('database_tables_new.metadata.issues.missing_attributes_message')}
    </Message.Content>
    <Message.Content>
      <ul>
        {!metadata.has_delimiter && (
        <li>{t('database_tables_new.metadata.issues.missing_delimiter')}</li>
        )}
        {!metadata.has_columns && (
        <li>{t('database_tables_new.metadata.issues.missing_columns_data')}</li>
        )}
      </ul>
    </Message.Content>
  </>
);

export const MetadataAttributesWarning = ({ table }) => (
  <Message warning>
    <Message.Header>{t('database_tables_new.metadata.issues.header')}</Message.Header>
    {table.metadata.is_empty && table.metadata.file_size !== 0 && (
      <Message.Content>{t('database_tables_new.metadata.issues.corrupted')}</Message.Content>
    )}
    {table.metadata.is_empty && table.metadata.file_size === 0 && (
      <Message.Content>{t('database_tables_new.metadata.issues.empty')}</Message.Content>
    )}
    {!table.metadata.is_empty && metadataAttributesMissing(table) && (
      <MissingMetadataList metadata={table.metadata} />
    )}
  </Message>
);

export const WarningPopup = ({ isOpen, header, message }) => {
  const mountNode = document.querySelector('#popup-wrapper') || document.body;

  return (
    <Popup
      header={header}
      content={message}
      trigger={<Icon name="exclamation triangle" className="warning-colour float-right" data-testid="warning-popup-icon" />}
      open={isOpen}
      mountNode={mountNode}
      position="top right"
      hideOnScroll
    />
  );
};

export const ParsingOrMismatchError = ({ table }) => (
  <Message icon negative className="margin-small">
    <Icon name="exclamation triangle" data-testid="parsing-or-mismatch-error-icon" />
    <Message.Content>
      <Message.Header as="h4">{t('database_tables_new.step3.display_errors.header')}</Message.Header>
      {table.parsed_content.parsing_errors && (
        <p role="paragraph">{t('database_tables_new.step3.display_errors.parsing_error')}</p>
      )}
      {table.columns_rows_mismatch && (
        <p role="paragraph">{t('database_tables_new.step3.display_errors.rows_mismatch')}</p>
      )}
    </Message.Content>
  </Message>
);
