import { workspace } from '../utilities/workspace_assoc';
import Model from './models';

/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true }] */

export default Model.extend({
  constructorName: 'RCommand',

  showUrlTemplate() {
    return 'workspaces/{{workspace_id}}/rtools/{{checkId}}';
  },

  initialize() {
    this._super('initialize');
    this.set({ checkId: Math.floor((Math.random() * 1e8) + 1).toString() });

    if (this.get('workfile')) {
      this.extractFromWorkfile();
    }
    if (this.get('command') === undefined) {
      this.createCommand();
    }
  },

  createCommand() {
    const command = (this.get('scriptType') !== undefined) ? this.sourceCommand() : '';
    this.set('command', command);
  },

  sourceCommand() {
    switch (this.get('fileName').toLowerCase().replace(/.*\.(.*)$/,"$1")) {
      case 'r':
        return `xap.source('${this.get('fileName')}', FALSE, '${this.get('folder')}', TRUE);`;
      case 'rnw':
        return `xap.knit('${this.get('fileName')}', TRUE, '${this.get('folder')}');`;
      case 'png':
      case 'jpg':
        return `xap.image('${this.get('fileName')}', '${this.get('folder')}');`;
      default:
        return `xap.workfile('${this.get('fileName')}', '${this.get('folder')}');`;
    }
  },

  extractFromWorkfile() {
    const model = this.get('workfile');

    this.set('scriptType', model.get('fileType'));
    this.set('relativePath', model.get('relativePath'));
    this.set('folder', model.get('folder'));
    this.set('topFolder', model.get('topFolder'));
    this.set('fileName', model.get('fileName'));
    this.set('workspace_id', workspace(model).id);
  },

});
