import chorus from '../../chorus';
import t from '../../intl';
import Dialog from '../../dialogs/dialogs';
import DialogFormHelpers from '../../mixins/dialog_form_helpers';

export default Dialog.include(DialogFormHelpers).extend({
  constructorName: 'AssignChorusViewName',
  templateName: 'name_chorus_view',
  title: t('dataset.name_chorus_view.title'),

  setup() {
    this.listenTo(this.model, 'saved', this.chorusViewCreated);
    this.listenTo(this.model, 'saveFailed', this.chorusViewFailed);
    this.listenTo(this.model, 'validationFailed', this.chorusViewFailed);
    this.disableFormUnlessValid({
      formSelector: 'form',
      inputSelector: 'input[name=objectName]',
    });
  },

  create(e) {
    e.preventDefault();

    this.model.set({ objectName: this.$('input[name=objectName]').val().trim() });
    this.$('button.submit').startLoading('actions.creating');
    this.model.save();
  },

  chorusViewCreated() {
    if (this.options.flags && this.options.flags.toast) {
      chorus.toast('rsh.editor.controls.results_console.custom_view_save_succeeded');
    }
    chorus.PageEvents.trigger('datasets:changed');
    chorus.PageEvents.trigger('close:modals');
  },

  chorusViewFailed() {
    this.$('button.submit').stopLoading();
  },
});
