import t from '../../intl';
import _ from '../../underscore';
import AirlockNewTabView from './airlock_new_tab_view';
import AirlockNewSidebarView from './airlock_new_sidebar_view';
import AirlockDestinations from '../../collections/management/airlock_destinations';
import AirlockWizard from '../../models/airlock_wizard';
import Workfile from '../../models/workfile';
import WorkfileSet from '../../collections/workfile_set';
import View from '../loading_view';
import { locationMap } from '../../models/management/workspace_options';

export default View.extend({
  subviews: {
    '.app-content': 'mainContent',
    '.app-sidebarBasic': 'sidebar',
  },

  setup() {
    this.templateName = 'tab_content';
    const workspace = this.options.model;

    let approvalHelp = t('airlock.help.select_destination') + t('airlock.help.submit_request');
    if (workspace.hasPermission('WorkspaceAirlock.Approve')) {
      approvalHelp += t('airlock.help.approve_request');
    }

    // used for ids to ensure uniqueness in dom if we ever have two of this view open
    this.wizardModel = new AirlockWizard({
      nonce: Math.floor((Math.random() * 10000) + 1),
      activeSection: 0,
      files: {},
      datasets: {},
      destination: 'download',
      reason: '',
      canApproveAirlock: workspace.hasPermission('WorkspaceAirlock.Approve'),
      sections: [
        {
          title: t('airlock.breadcrumb.select_content'),
          help: t('airlock.help.select_content').replace(/\\n/g, '\n'),
        },
        {
          title: t('airlock.breadcrumb.select_destination'),
          help: approvalHelp.replace(/\\n/g, '\n'),
          currentWorkspace: workspace.id,
          visibleWorkspaces: null, // loaded afterwards
        },
        {
          title: t('airlock.breadcrumb.complete'),
          help: '',
        },
      ],
    });

    if (this.initialContent) {
      this.addContent(this.initialContent);
      this.initialContent = null;
    }

    this.mainContent = new AirlockNewTabView({
      model: this.wizardModel,
      workspaceModel: workspace,
      tabModel: this.options.tab,
    });
    this.sidebar = new AirlockNewSidebarView({ model: this.wizardModel });
    this.loadDestinationWorkspaces(workspace.get('uuid'));
  },

  addRequestContent(content) {
    const models = content.models ? content.models : [content];
    if (this.wizardModel) {
      if (this.wizardModel.isComplete()) {
        this.wizardModel.reset();
      } else {
        this.addContent(models);
      }
    } else {
      this.initialContent = models;
    }
  },

  findSubFiles(workfile) {
    const collection = new WorkfileSet([], {
      folder: workfile.fullName(),
      workspaceId: workfile.get('workspace').id,
      succinct: true,
    });
    collection.fetch().then(() => {
      collection.models.forEach((subWorkfile) => {
        if (subWorkfile.isFolder()) {
          this.findSubFiles(subWorkfile);
        }
      });
      this.wizardModel.addFiles(collection.models.filter(file => !file.isFolder()));
    });
  },

  addContent(content) {
    if (content[0] instanceof Workfile) {
      content.forEach((workfile) => {
        if (workfile.isFolder()) {
          this.findSubFiles(workfile);
        }
      });
      this.wizardModel.addFiles(content.filter(file => !file.isFolder()));
    } else {
      this.wizardModel.addDatasets(content);
    }
  },

  loadDestinationWorkspaces(workspaceUuid) {
    const destinations = new AirlockDestinations([], { workspaceUuid });
    destinations.fetch().then(async () => {
      const locationOptionsRequired = _.some(destinations.models, m => m.get('guidance'));

      let viewDestinations = null;
      if (locationOptionsRequired) {
        const locations = await locationMap();
        viewDestinations = destinations.models.map(m => m.attributes).map(({
          uuid, name, location, guidance,
        }) => ({
          uuid,
          name: `${locations.get(location)} - ${name} ${guidance ? '*' : ''}`,
          location: locations.get(location),
          guidance,
        }));
      } else {
        viewDestinations = destinations.models.map(m => m.attributes).map(({ uuid, name }) => ({ uuid, name }));
      }
      this.wizardModel.updateDestinations(_.sortBy(viewDestinations, 'name'));
    });
  },
});
