/* eslint-disable max-lines */
import { useState } from 'react';
import { t, translations } from 'i18n-js';
import {
  Modal, Icon, Header, Message,
} from 'semantic-ui-react';
import ModalActionButtons, { CloseButton } from '../../../../../components/buttons/ModalActionButtons';
import { validationLengths, validationFields } from '../../../../../constants/validation';
import HeaderError from '../../../../../components/errors/HeaderError';
import { supportedVirtualMachineTypes } from '../../../../constants';
import { useRequestNewVirtualMachine } from '../../service';
import { useWorkspaceContext } from '../../../../../adapters/Workspace';
import RequestVirtualMachineModalForm from './RequestVirtualMachineModalForm';
import RequestVirtualMachineConfirmationMessage from '../RequestVirtualMachineConfirmationMessage';
import { useCustomisationContext } from '../../../../../customisation/Context';
import KnowledgeBaseButton from '../../../../../components/buttons/KnowledgeBaseButton';

const checkForErrors = (data, vmCustomKBLink) => {
  const errors = [];
  if (data.name?.length > validationLengths.NAME) {
    errors.push({
      field: validationFields.NAME,
      message: (
        t(
          'field_error.TOO_LONG',
          { field: t('virtual_machines.request_modal.name.label'), count: validationLengths.NAME },
        )
      ),
    });
  }

  if (!data.type) {
    errors.push({
      field: 'type',
      message: (t('field_error.BLANK', { field: t('virtual_machines.request_modal.name.label') })),
    });
  }

  if (data.type && !Object.values(supportedVirtualMachineTypes).includes(data.type)) {
    errors.push({
      field: 'type',
      message: (
        t('field_error.INVALID', { field: t('virtual_machines.request_modal.type.label').toLowerCase() })
      ),
    });
  }

  if (data.info?.length > validationLengths.TEXT) {
    errors.push({
      field: 'info',
      message: (
        t(
          'field_error.TOO_LONG',
          { field: t('modals.generic.text_field'), count: validationLengths.TEXT },
        )
      ),
    });
  }

  if (vmCustomKBLink && (!data.info || data.info.trim().length === 0)) {
    errors.push({
      field: 'info',
      message: (
        t(
          'field_error.BLANK',
          { field: t('modals.generic.text_field') },
        )
      ),
    });
  }
  return errors;
};

const RequestVirtualMachineModal = ({ setModalClose, setIsBusy }) => {
  const [virtualMachine, setDesktop] = useState({ name: '', type: null, info: '' });
  const { workspaceUuid } = useWorkspaceContext();
  const [validationErrors, setValidationErrors] = useState([]);
  const [isInfoTextboxFilled, setIsInfoTextboxFilled] = useState(false);
  const { vmCustomKBLink } = useCustomisationContext() || {};
  const isValidEntry = !!virtualMachine.type && (!vmCustomKBLink || isInfoTextboxFilled);

  const {
    requestNewVirtualMachine,
    isError,
    isLoading,
    isSuccess,
    error,
    resetResponse,
  } = useRequestNewVirtualMachine();

  const handleChange = (value, field) => {
    if (field === 'type' && translations.customization?.virtual_machines?.request_modal?.info?.template?.[value]) {
      setDesktop({ ...virtualMachine, type: value, info: t(`virtual_machines.request_modal.info.template.${value}`) });
    } else if (field === 'type' && !translations.customization?.virtual_machines?.request_modal?.info?.template?.[value]) {
      setDesktop({ ...virtualMachine, type: value, info: '' });
    } else {
      setDesktop({ ...virtualMachine, [field]: value });
    }
    if (field === 'info') {
      setIsInfoTextboxFilled(value.trim().length > 0);
    }

    const newErrors = validationErrors.filter((err) => err.field !== field);
    setValidationErrors(newErrors);
  };

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleSubmitting = () => {
    setIsBusy(true);
    const errors = checkForErrors(virtualMachine, vmCustomKBLink);
    setValidationErrors(errors);

    if (errors.length > 0) {
      setIsBusy(false);
      return;
    }

    const payload = {
      action: 'send_mail',
      template: 'workspace_request',
      workspace_uuid: workspaceUuid,
      display_name: virtualMachine.name,
      type: virtualMachine.type,
      info: virtualMachine.info,
    };

    requestNewVirtualMachine(payload, {
      onSettled: () => {
        setIsBusy(false);
      },
    });
  };

  const { vmCustomMessage } = useCustomisationContext() || {};

  return (
    <>
      <Header as="header" aria-label={t('virtual_machines.request_modal.header_text')} className="aridhia-modal-header">
        <Icon name="desktop" title="desktop" />
        <Header.Content content={t('virtual_machines.request_modal.header_text')} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        {vmCustomMessage && !isSuccess && (
          <Message info icon size="small" data-testid="vm-custom-message">
            <Icon name="info circle" />
            <Message.Content>
              <p>
                {t('virtual_machines.request_modal.custom_message')}
                <a href="https://knowledgebase.aridhia.io/when-to-use-a-vm" target="_blank" rel="noopener noreferrer">{t('virtual_machines.request_modal.custom_link_text')}</a>
              </p>
            </Message.Content>
          </Message>
        )}
        {!isSuccess && (
          <RequestVirtualMachineModalForm
            virtualMachine={virtualMachine}
            validationErrors={validationErrors}
            handleChange={handleChange}
          />
        )}
        {isSuccess && (
          <RequestVirtualMachineConfirmationMessage />
        )}
      </Modal.Content>
      {!isSuccess && (
        <ModalActionButtons
          loading={isLoading}
          onClose={handleModalClose}
          onSubmit={handleSubmitting}
          isValidEntry={isValidEntry}
          error={validationErrors.length > 0 || isError}
          submitText={t('actions.request_virtual_machine')}
        >
          <KnowledgeBaseButton
            title={t('virtual_machines.request_modal.knowledgebase_label')}
            targetUrl={t('url.knowledgebase_using_a_data_science_virtual_machine')}
            className="float-left margin-left-none"
          />
        </ModalActionButtons>
      )}
      {isSuccess && (
        <Modal.Actions>
          <CloseButton
            onClose={handleModalClose}
          />
        </Modal.Actions>
      )}
    </>
  );
};

export default RequestVirtualMachineModal;
