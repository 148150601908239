import React from 'react';
import Announcement from '../../../new_ux/components/announcement/Announcement';
import AnnouncementModel from 'models/announcement';
import View from '../react_view';

export default View.extend({
  constructorName: 'announcementView',

  renderOnce() {
    const model = new AnnouncementModel();
    model.fetch()
      .then(() => {
        this.root.render(
          <Announcement announcements={model.get('announcements')} />,
        );
      });
  },
});
