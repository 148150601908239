/* eslint-disable object-curly-newline,react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import { Button, Icon, Modal, Header } from 'semantic-ui-react';
import { t } from 'i18n-js';
import HeaderError from '../../components/errors/HeaderError';
import { CancelButton } from '../../components/buttons/ModalActionButtons';

export const StandardHeader = React.memo(({
  isError,
  reset,
  error,
  icon,
  title,
}) => {
  const message = (error && error.message) ? error.message : String(error);
  return (
    <Header as="header" className="aridhia-modal-header" data-testid="modal-header">
      {icon && <Icon name={icon} data-testid="modal-header-icon" />}
      <Header.Content content={title} />
      {isError && (
        <HeaderError
          error={message}
          onDismiss={() => reset()}
        />
      )}
    </Header>
  );
});

export const StandardActions = React.memo(({
  cancellationText = null,
  confirmationText = null,
  confirmationIcon,
  positive = true,
  onClose,
  onSubmit,
  isDisabled,
  isSubmitting,
}) => {
  // set defaults for text items when item is called rather than defined to ensure language text is loaded correctly
  const cancellation = cancellationText || t('actions.cancel');
  const confirmation = confirmationText || t('actions.confirm');
  let icon = confirmationIcon;
  if (icon === undefined) {
    icon = positive ? 'check' : 'x';
  }
  return (
    <Modal.Actions>
      <CancelButton
        loading={isSubmitting}
        onClose={onClose}
        cancelText={cancellation}
      />
      <Button
        icon={icon}
        content={confirmation}
        labelPosition="right"
        positive={positive}
        negative={!positive}
        onClick={onSubmit}
        disabled={isDisabled || isSubmitting}
        loading={isSubmitting}
      />
    </Modal.Actions>
  );
});

/**
 * Creates a copy of an array or object so that if the mutation parameters are changed within the modal then
 * the original object passed in to the modal isn't changed.
 *
 * @param arrayOrObject
 * @returns {*[]|*}
 */
const createCopy = (arrayOrObject) => {
  if (Array.isArray(arrayOrObject)) {
    return [].concat(arrayOrObject);
  } if (typeof arrayOrObject === 'object') {
    return { ...arrayOrObject };
  }
  return arrayOrObject; // built-in object like string or other value that is unlikely to be modified in place
};

export const StandardModalContent = ({
  useMutationHook,
  defaultMutationParameters = {},
  setModalClose,
  setIsBusy,
  children,
  initiallyEnabled = true,
  actionProps = {},
  headerProps = {},
  Actions = StandardActions,
  ModalHeader = StandardHeader,
}) => {
  const mutationOptions = {
    onMutate: () => setIsBusy(true),
    onSettled: () => setIsBusy(false),
    onSuccess: setModalClose,
  };
  const { error, isError, isLoading, mutate, reset } = useMutationHook(mutationOptions);
  const [mutationEnabled, setMutationEnabled] = useState(initiallyEnabled);
  const mutationParameters = useRef(createCopy(defaultMutationParameters));
  const child = React.useMemo(
    () => React.cloneElement(children, {
      isError,
      resetMutationError: reset,
      mutationParameters,
      setMutationEnabled,
      isLoading,
    }),
    [children, isError, reset, mutationParameters, setMutationEnabled, isLoading],
  );
  return (
    <>
      <ModalHeader
        isError={isError}
        reset={reset}
        error={error}
        {...headerProps}
      />
      <Modal.Content scrolling>
        {child}
      </Modal.Content>
      <Actions
        isDisabled={!mutationEnabled}
        isSubmitting={isLoading}
        onClose={setModalClose}
        onSubmit={() => mutate(mutationParameters.current)}
        mutationParameters={mutationParameters}
        {...actionProps}
      />
    </>
  );
};
