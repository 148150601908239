import chorus from '../chorus';
import _ from '../underscore';
import $ from '../jquery';
import t from '../intl';
import Tabs from '../views/core/tabs';
import FolderNew from '../dialogs/workfiles/folder_new_dialog';
import DataTableEditorCreate from '../dialogs/dataset/data_table_editor_create_dialog';
import MiniAppCreate from '../dialogs/mini_apps/mini_app_create_dialog';
import WorkspaceRouter from '../models/workspace_router';
import BasePage from './base_page';
import TabSet from '../collections/tab_set';
import Tab from '../models/layout/tab';
import { instance } from '../models/config';
import Routing from '../mixins/routing';
import Workspace from '../models/workspace';
import RibbonConfiguration from '../models/layout/ribbon_configuration';
import Backbone from '../vendor/backbone';
import ManagementUser from '../models/management/user';
import Announcement from '../views/core/announcement_view';
import Toast from '../views/core/toast_view';
import Tracking from '../views/core/tracking_view';
import CopyWarningAlert from '../views/core/copy_warning_alert_view';
import { FEATURE } from '../../utilities/features';

export default BasePage.include(Routing).extend({
  templateName: 'logged_in_layout_with_tabs',
  helpId: 'workspaceTabs',
  bindCallbacks: $.noop,

  setup(...args) {
    if (!chorus.session.loggedIn()) { return; }
    this.user = new ManagementUser({ uuid: 'self' });
    this.user.fetch().then((data) => {
      chorus.session.user().set({ organisationAdmin: data.role === 'Administrator', uuid: data.uuid });
    });
    this.tabTemplate = new WorkspaceRouter(this, this.model);

    if (!FEATURE.NEW_UX) {
      this.subscribePageEvent('workfile:deleted', this.removeFileTab);
      this.subscribePageEvent('workfile:close', this.removeFileTab);
      this.subscribePageEvent('dataset:deleted', this.removeDatasetTab);
      this.subscribePageEvent('dataset_upload:cancelled', this.removeDatasetUploadTab);
      this.subscribePageEvent('mini_app:destroyed', this.miniAppDestroyed);
      this.subscribePageEvent('containerised_app:close', this.containerisedAppFormClosed);
      this.subscribePageEvent('containerised_app:stop', this.containerisedAppRunStop);
    }
    this.subscribePageEvent('mini_app:created', this.miniAppDefaulted);
    this.subscribePageEvent('mini_app:defaulted', this.miniAppDefaulted);
    this.subscribePageEvent('dte:file_uploaded', this.dteFileUploaded);
    this.subscribePageEvent('workfile:create-upload-dialog', this.createUploadDialog);
    this.subscribePageEvent('close:modals', () => {
      while (chorus.modal && chorus.modal.closeModal());
    });

    this.onceLoaded(this.model, _.bind(this.resourceLoaded, this, args));
  },

  miniAppDefaulted(model) {
    this.tabTemplate.defaultApp = model;
  },

  miniAppDestroyed(app) {
    const tabToRemove = this.tabCollection.findWhere({ miniAppID: app.id });
    this.tabCollection.remove(tabToRemove);
  },

  containerisedAppFormClosed() {
    const tabToRemove = this.tabCollection.findWhere({ type: 'containerised_app_create' });
    this.tabCollection.remove(tabToRemove);
  },

  containerisedAppRunStop(app) {
    const tabToRemove = this.tabCollection.findWhere({ appID: app.get('id') });
    this.tabCollection.remove(tabToRemove);
  },

  dteFileUploaded(webImport) {
    this.mainContent.destroyTabWhere({
      label: webImport.get('fileName'),
      type: 'workfile',
      closeable: true,
    }, true);
  },

  beginCollectionRefresh() {
    const refreshFunc = function refreshNotifications() {
      this.header.unreadNotifications.fetch();
    };

    this.refreshTimer = setInterval(_.bind(refreshFunc, this), 30000);
  },

  teardown(...args) {
    this._super('teardown', ...args);

    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  },

  resourceLoaded(args) {
    if (!FEATURE.NEW_UX){
      this.addInitialTabs();
      this.updatePageArgs(_.toArray(args));
      this.beginCollectionRefresh();
      this.announcement = new Announcement();
      this.toast = new Toast();
      this.tracking = new Tracking({ model: this.model, collection: this.tabCollection });
      this.copywarningalert = new CopyWarningAlert({ model: this.model, tabs: this.tabCollection });

      const linkMenuOptions = this.buildAddMenu();

      this.mainContent = new Tabs({
        model: this.model,
        configuration: this.configuration,
        collection: this.tabCollection,
        menuOptions: linkMenuOptions,
      });
    }
    
    if (this.$el.is(':visible')) {
      this.render({ teardownSubViews: true });
    }

    this.listenTo(this.model, 'airlock', this.addFilesToRequest);
    // move listner here
  },

  addInitialTabs() {
    this.tabCollection = new TabSet();
    const { tabCollection } = this;

    _.each(this.tabTemplate.defaultTabs(), (tab) => {
      tabCollection.add(tab);
    });

    this.addDesktops();
  },

  buildAddMenu() {
    return {
      isSimple: true,
      options: this.menuOptions(),
      choose: this.menuChoices.bind(this),
    };
  },

  menuOptions() {
    const consoles = this.consoleOptions();
    const files = this.fileOptions();
    const miniapps = this.miniappOptions();
    const containerisedApps = this.containerisedAppOptions();
    const imports = this.importOptions(files);
    const dte = this.dteOptions();

    return consoles.concat(files, dte, miniapps, containerisedApps, imports);
  },

  importOptions(files) {
    const menus = [];

    if (files.length > 0) {
      menus.push({ data: 'upload', text: t('workfiles.header.menu.new.upload'), icon: 'fa-upload' });
      menus.push({ data: 'upload_token', text: t('workfiles.header.menu.new.upload_api'), icon: 'fa-upload' });
    }
    /* temporarily disabled
    if (this.workspace.hasPermission('csv_import')) {
      menus.push({ data: 'upload_data', text: t('workfiles.header.menu.new.upload_data'), icon: 'fa-table' });
    }
    */
    return menus;
  },

  fileOptions() {
    let menus = [];

    if (this.model.loaded && this.model.hasPermission('WorkspaceStorage.Write')) {
      menus = [
        {
          data: 'folder',
          text: t('workfiles.header.menu.new.folder'),
          icon: 'fa-folder',
        },
        {
          data: 'file',
          text: t('workfiles.header.menu.new.file'),
          icon: 'fa-file-text',
        },
      ];
    }

    return menus;
  },

  miniappOptions() {
    let menus = [];

    if (this.model.loaded && this.model.hasPermission('WorkspaceShinyApp.Write')) {
      menus = [
        {
          data: 'miniapp',
          text: t('workfiles.header.menu.new.mini_app'),
          separator: !FEATURE.CONTAINERISED_APPS,
          icon: 'fa-caret-square-o-right',
        },
      ];
    }

    return menus;
  },

  containerisedAppOptions() {
    let menus = [];

    if (this.model.loaded && FEATURE.CONTAINERISED_APPS && this.model.hasPermission('ContainerisedApp.Create')) {
      menus = [
        {
          data: 'containerised_app',
          text: t('workfiles.header.menu.new.containerised_app'),
          separator: true,
          icon: 'fa fa-rocket',
        },
      ];
    }

    return menus;
  },

  consoleOptions() {
    const menus = [];

    if (this.model.loaded) {
      menus.push({ data: 'rtools', text: t('workfiles.header.menu.new.rsession'), separator: true });
    }

    return menus;
  },

  dteOptions() {
    const menus = [];

    if (this.model.loaded &&
      this.model.hasPermission('WorkspaceDatabase.Read') &&
      instance.dataTableEditorEnabled()) {
      menus.push({
        data: 'data_table_editor',
        text: t('workfiles.header.menu.new.data_table_editor'),
        icon: 'fa-table',
      });
    }

    return menus;
  },

  menuChoices(fileType) {
    switch (fileType) {
      case 'rtools':
      case 'upload_data':
        this.navigate(`workspaces/${this.model.id}/${fileType}`);
        break;
      case 'upload':
        this.createUploadLink();
        break;
      case 'upload_token':
        this.navigate(`workspaces/${this.model.get('id')}/upload_token`);
        break;
      case 'folder':
        this.createFolderDialog();
        break;
      case 'miniapp':
        new MiniAppCreate({
          workspaceId: this.model.get('id'),
          token: this.model.token(),
        }).launchModal();
        break;
      case 'containerised_app':
        this.navigate(`workspaces/${this.model.get('id')}/app`);
        break;
      case 'data_table_editor':
        new DataTableEditorCreate({
          workspaceId: this.model.get('id'),
        }).launchModal();
        break;
      default:
        this.createFileDialog();
    }
  },

  makeModel(workspaceId) {
    this.workspaceId = parseInt(workspaceId, 10);
    this.workspace = new Workspace({ id: workspaceId });

    this.configuration = new RibbonConfiguration({
      workspace: this.workspace,
    });

    this.handleFetchErrorsFor(this.workspace);
    this.workspace.fetch();

    this.model = this.workspace;
  },

  updatePageArgs(args) {
    if (!this.validate(args)) {
      return false;
    }

    const tab = this.tabTemplate.getTab(args);

    if (tab) {
      this.tabCollection.setActive(tab);
      return true;
    }

    return false;
  },

  // / Sub routing functions
  validate(args) {
    // The different args are from the following urls
    // #/workspaces/:workspaceId
    // #/workspaces/:workspaceId/:listName
    // #/workspaces/:workspaceId/:objectType/:objectId
    // #/workspaces/:workspaceId/:objectType/:objectId/version/:versionId
    const valid = args && args.length > 0 && args[0] === String(this.model.id);

    if (valid) {
      const lastArg = args.pop();
      Array.prototype.push.apply(args, lastArg.split('/'));
    }

    return valid;
  },

  addDesktops() {
    const desktops = this.model.desktops();
    desktops.fetchIfNotLoaded();
    this.onceLoaded(desktops, this.addDesktopTab);
  },

  addDesktopTab() {
    const desktops = this.model.desktops();
    if (desktops.length > 0) {
      this.tabCollection.getOrAdd(new Tab(this.tabTemplate.desktopsTab()));
    }
  },

  removeFileTab(workfile) {
    const workfileId = parseInt(workfile.id, 10);
    const tabToRemove = this.tabCollection.find(model => model.get('file_type') === 'workfile' &&
      workfileId === parseInt(model.get('view_model').replace('workfile', ''), 10));
    this.tabCollection.remove(tabToRemove);
  },

  removeDatasetTab(dataset) {
    // remove tab for old datasets
    const datasetId = parseInt(dataset.id, 10);
    const tabToRemove = this.tabCollection.find(model => model.get('fileType') === 'dataset' &&
      datasetId === parseInt(model.get('view_model').replace('dataset', ''), 10));
    this.tabCollection.remove(tabToRemove);
    // remove tab for new datasets
    const datasetName = dataset.get('datasetName');
    const tabNewToRemove = this.tabCollection.find(model => model.get('fileType') === 'dataset' &&
      datasetName === model.get('view_model').replace('new_dte', ''));
    this.tabCollection.remove(tabNewToRemove);
  },

  removeDatasetUploadTab(workfile) {
    const workfileId = parseInt(workfile.id, 10);
    const tabToRemove = this.tabCollection.find(model => model.get('file_type') === 'datasetupload' &&
      workfileId === parseInt(model.get('view_model').replace('datasetupload', ''), 10));
    this.tabCollection.remove(tabToRemove);
  },

  createFileDialog() {
    const tab = this.tabCollection.getActive();
    let folder;

    if (tab.get('view_model') === 'workfiles') {
      const view = tab.get('view');
      ({ folder } = view);
    }

    new chorus.dialogs.WorkfileNew({
      workspaceId: this.model.get('id'),
      editInline: true,
      folder,
    }).launchModal();
  },

  createFolderDialog() {
    const tab = this.tabCollection.getActive();
    let folder;

    if (tab.get('view_model') === 'workfiles') {
      const view = tab.get('view');
      ({ folder } = view);
    }

    new FolderNew({
      workspace: this.model,
      editInline: true,
      folder,
    }).launchModal();
  },

  getActiveFolder() {
    const tab = this.tabCollection.getActive();
    let folder = null;
    if (tab.get('view_model') === 'workfiles') {
      ({ folder } = tab.get('view'));
    }

    if (folder === null) {
      folder = new Backbone.Model({ workspaceId: this.model.get('id'), folder: 'files' });
    }

    return folder;
  },

  createUploadLink() {
    const folder = this.getActiveFolder();
    this.navigate(`workspaces/${this.model.get('id')}/upload/${+new Date()}/${folder.get('folder')}`);
  },

  addFilesToRequest(content) {
    const airlockTab = this.tabCollection.getOrAdd(new Tab(this.tabTemplate.airlockNewTab()));
    airlockTab.getOrCreateView().addRequestContent(content);
    this.navigate(`workspaces/${this.model.id}/airlock_new`);
  },
});
