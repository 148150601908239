import { Icon } from 'semantic-ui-react';
import announcementLevels from './constants';

const iconName = (level) => {
  const match = announcementLevels[String(level).trim().toLowerCase()];
  return match || announcementLevels.information;
};

const AnnouncementIcon = ({ level }) => (
  <Icon
    name={iconName(level)}
  />
);

export default AnnouncementIcon;
