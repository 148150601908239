import { useCallback, useState } from 'react';
import chorus from '../../application/chorus';

const setOpen = () => {
  chorus?.PageEvents?.trigger('modal:open');
  return true;
};

const setClosed = () => {
  chorus?.PageEvents?.trigger('modal:closed');
  return false;
};

export const useModalState = (isOpen = false) => {
  const [open, dispatch] = useState(isOpen);

  // We have to trigger a pageEvent for the legacy panels
  const handleOpen = useCallback(() => dispatch(setOpen), [dispatch]);
  const handleClose = useCallback(() => dispatch(setClosed), [dispatch]);

  return [open, handleOpen, handleClose];
};

export default useModalState;
