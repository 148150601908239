import chorus from '../chorus';
import t from '../intl';
import Confirm from './confirm';

export default Confirm.extend({
  constructorName: 'Analyze',
  text: t('analyze.alert.text'),
  ok: t('analyze.alert.ok'),

  setup() {
    this.title = t('analyze.alert.title', { name: this.model.name() });
  },

  confirmAlert() {
    this.$('button.submit').startLoading('analyze.alert.loading');
    this.listenTo(this.model.analyze(), 'saveFailed', this.saveFailed);
    this.listenTo(this.model.analyze(), 'saved', this.saved);
    this.model.analyze().save();
  },

  saveFailed() {
    this.$('button.submit').stopLoading();
    this.showErrors(this.model.analyze());
  },

  saved() {
    chorus.PageEvents.trigger('analyze:running');
    chorus.toast('analyze.alert.toast', { name: this.model.name() });
    this.closeModal();
  },
});
