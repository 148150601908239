import { Menu } from 'semantic-ui-react';
import HomeLauncher from '../../home/components/HomeLauncher';
import ActivityLauncher from '../../activities/panels/activity/components/ActivityLauncher';
import FilesLauncherMenu from '../../files/components/FilesLauncherMenu';
import DatabaseLauncherMenu from '../../database_tables/components/DatabaseLauncherMenu';
import ToolsLauncherMenu from '../../apps/components/ToolsLauncherMenu';
import AppsLauncherMenu from '../../apps/components/AppsLauncherMenu';
import UploadLauncherMenu from './ribbon-launchers/UploadLauncherMenu';
import AdministerLauncherMenu from './ribbon-launchers/AdministerLauncherMenu';
import './Ribbon.less';

const Ribbon = () => (
  <Menu as="nav" inverted color="blue" icon="labeled" id="aridhia-ribbon">
    <ul>
      <li><HomeLauncher /></li>
      <li><ActivityLauncher /></li>
      <li><FilesLauncherMenu /></li>
      <li><DatabaseLauncherMenu /></li>
      <li><ToolsLauncherMenu /></li>
      <li><AppsLauncherMenu /></li>
      <li><UploadLauncherMenu /></li>
      <li><AdministerLauncherMenu /></li>
    </ul>
  </Menu>
);

export default Ribbon;
