import _ from '../underscore';
import { workspace } from '../utilities/workspace_assoc';
import { instance } from '../models/config';
import Presenter from './presenters';
import { linkTo } from '../utilities/url_helpers';
import { humanizedDatasetType } from '../utilities/helpers';

export default Presenter.extend({
  setup() {
    _.each(this.options, (value, key) => {
      this[key] = value;
    }, this);
  },

  typeString() {
    const r = this.resource;
    return humanizedDatasetType(r && r.attributes, r && r.statistics());
  },

  editUrl() {
    return `/#/workspaces/${this.workspaceId()}/datatable/dataset/${this.resource.id}`;
  },

  analyseUrl() {
    return `${this.editUrl()}/analyse`;
  },

  metadataUrl() {
    return `/#/workspaces/${this.workspaceId()}/dataset_metadata/${this.resource.id}`;
  },

  canShowRefreshButtonOnDataset() {
    return this.view.parentView?.className === 'app-datasetsShow';
  },

  showDTELink() {
    return instance.dataTableEditorEnabled();
  },

  canAssociate() {
    return this.resource.isGreenplum();
  },

  isDeleteable() {
    return !this.options.searchPage &&
        this.hasWorkspace() &&
        this.resource.isDeleteable() &&
        workspace(this.resource).canUpdate();
  },

  realWorkspace() {
    // this.workspace gets overriden by options hash passed by pages.
    return this.options.searchPage ? null : workspace(this.resource);
  },

  workspaceId() {
    return this.hasWorkspace() && workspace(this.resource).id;
  },

  canDuplicateChorusViews() {
    return workspace(this.resource).hasPermission('WorkspaceDatabase.Write');
  },

  importsEnabled() {
    return (this.hasWorkspace() && workspace(this.resource).sandbox()) ||
            (this.resource && this.resource.isOracle());
  },

  hasWorkspace() {
    return this.resource && this.realWorkspace();
  },

  isChorusView() {
    return this.resource ? this.resource.isChorusView() : '';
  },

  displayEntityType() {
    return this.resource ? this.resource.metaType() : '';
  },

  canUpdate() {
    return this.hasWorkspace() && workspace(this.resource).canUpdate() && !this.resource.isImporting();
  },

  canUpdateMetadata() {
    return this.hasWorkspace() && workspace(this.resource).canUpdate();
  },

  canAddNotes() {
    return this.hasWorkspace() && workspace(this.resource).canAddNotes();
  },

  canPreview() {
    return this.resource.loaded && !this.resource.isImporting();
  },

  canAnalyze() {
    return this.resource &&
        this.resource.hasCredentials() &&
        this.resource.supportsAnalyze() &&
        workspace(this.resource) &&
        workspace(this.resource).canUpdate() &&
        !this.resource.isExternal() &&
        !this.resource.isImporting();
  },

  canImport() {
    return this.resource && this.resource.isOracle();
  },

  hasImport() {
    return this.resource && this.resource.hasImport();
  },

  canExport() {
    return !this.resource.isImporting() && !this.options.searchPage && this.resource && this.resource.hasCredentials()
        && workspace(this.resource) && workspace(this.resource).hasPermission('WorkspaceDatabase.Export')
        && !this.resource.isChorusView();
  },

  canDownload() {
    return !this.resource.isImporting() && this.resource && workspace(this.resource).canAirlock();
  },

  _linkToModel(model) {
    return linkTo(model.showUrl(), this.ellipsize(model.name()), { title: model.name() });
  },

  ellipsize(name) {
    if (!name) return '';
    const length = 15;
    return (name.length < length) ? name : `${name.slice(0, length - 3).trim()}...`;
  },
});
