import Dialog from './dialogs';
import DialogFormHelpers from '../mixins/dialog_form_helpers';

export default Dialog.include(DialogFormHelpers).extend({
  formSelector: 'form',
  persistent: true,
  templateName: 'new_terms_and_conditions',

  events: {
    'click .save': 'save',
    'click .cancel': 'close',
  },

  setup() {
    this.disableFormUnlessValid({
      formSelector: this.formSelector,
      inputSelector: "input[name='terms-and-conditions']",
      checkInput() {
        return this.$("input[name='terms-and-conditions']").is(':checked');
      },
    });
  },

  save(e) {
    e.preventDefault();
    this.trigger('accepted');
    this.closeModal();
  },

  close() {
    this.closeModal();
  },
});
