import Sidebar from '../views/core/sidebar_view';

export default Sidebar.extend({
  templateName: 'artifact_list_sidebar',

  setArtifact(artifact) {
    this.model = artifact;
    this.artifact = artifact;
  },

  additionalContext() {
    return {
      downloadUrl: this.artifact.downloadUrl(),
      iconUrl: this.artifact.iconUrl(),
      name: this.artifact.get('name'),
    };
  },
});
