import View from '../loading_view';

export default View.extend({
  constructorName: 'ReadOnlyTextContent',
  templateName: 'read_only_text_content',

  scrollPanelDisable: false,

  additionalContext() {
    return {
      content: this.model && this.model.content(),
    };
  },
});
