import { useContext, createContext } from 'react';

const DatabaseTableCreationContext = createContext();

export const useDatabaseTableCreationContext = () => useContext(DatabaseTableCreationContext);

export const DatabaseTableCreationProvider = ({ children, value }) => (
  <DatabaseTableCreationContext.Provider value={value}>
    {children}
  </DatabaseTableCreationContext.Provider>
);

const DatabaseTableCreationQueryContext = createContext();

export const useDatabaseTableCreationQueryContext = () => useContext(DatabaseTableCreationQueryContext);

export const DatabaseTableCreationQueryProvider = ({ children, value }) => (
  <DatabaseTableCreationQueryContext.Provider value={value}>
    {children}
  </DatabaseTableCreationQueryContext.Provider>
);
