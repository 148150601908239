import { createRoot } from 'react-dom/client';
import $ from '../jquery';
import Backbone from '../vendor/backbone';
import RequiredResources from '../collections/required_resources';
import _ from '../underscore';
import chorus from '../chorus';

export default Backbone.View.extend({
  // The order in which setup methods are called on views is as follows:
  // - initialize
  // -> preInitialize
  // -> -> makeModel
  // -> setup
  preInitialize(...args) {
    this.makeModel(...args);
    this.resource = this.model || this.collection;
  },

  initialize: function initialize(...args) {
    this.preInitialize(...args);
    this.setup(...args);

    if (this.requiredResources.length !== 0 && this.requiredResources.allResponded()) {
      this.resourcesLoaded();
    }
    this.root = createRoot(this.el);
  },

  makeModel: $.noop,
  setup: $.noop,
  resourcesLoaded: $.noop,
  renderOnce: $.noop,
  rendered: false,
  root: $.noop,

  // Sets backbone view options and creates a listener for completion of requiredResources
  _configure(options, ...args) {
    const opts = [options, ...args];
    const backboneOptions = (opts.length > 0 && _.isObject(opts[0])) ? [opts[0]] : [{}];
    this._super('_configure', backboneOptions);
    this.requiredResources = new RequiredResources();

    this.listenTo(this.requiredResources, 'allResourcesResponded', function onResponse() {
      this.resourcesLoaded();
    });

    this.requiredResources.reset(options.requiredResources);
  },

  render() {
    if (this.rendered) {
      return this;
    }

    this.renderOnce();
    this.rendered = true;

    return this;
  },

  teardown() {
    this.root.unmount();
    this.torndown = true;
  },

  modelDeleted(model, deleteMessage, deleteMessageParams, redirectUrl) {
    chorus.toast(deleteMessage, deleteMessageParams);
    chorus.PageEvents.trigger(`${model.entityType}:deleted`, model);
    if (redirectUrl) {
      this.navigate(redirectUrl);
    }
  },
});
