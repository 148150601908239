import { useRef, useState } from 'react';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import MarkdownRender from './MarkdownRender';
import './MarkdownEditor.less';

const MarkdownEditor = ({
  onChange,
  value,
  refs = null,
  tools = [['bold', 'italic'], ['ordered-list', 'unordered-list'], ['link', 'quote']],
  textAreaAttributes,
}) => {
  const [selectedTab, setSelectedTab] = useState('write');

  return (
    <ReactMde
      refs={refs}
      onChange={onChange}
      value={value}
      toolbarCommands={tools}
      selectedTab={selectedTab}
      onTabChange={setSelectedTab}
      generateMarkdownPreview={(markdown) => Promise.resolve(
        <MarkdownRender value={markdown} />,
      )}
      minPreviewHeight={190}
      childProps={{ textArea: textAreaAttributes }}
    />
  );
};

/**
 * This is intended to adapt the MarkdownEditor to fit better with other semantic-ui form components in terms of the
 * onChange interface and also the ability to provide a name for the input element such that the aria label
 *
 * @param name
 * @param defaultValue
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
export const SemanticMarkdownEditor = ({ name, defaultValue, onChange }) => {
  // The preview in ReactMde doesn't work unless the value is a controlled value
  const [value, setValue] = useState(defaultValue);
  // in order to include the input html element in the same way as other semanctic-ui form elements we need to obtain
  // a reference to it.
  const textarea = useRef();
  const handleChange = (newValue) => {
    setValue(newValue);
    // make it look somewhat similar to the onchange events from semantic-ui components
    onChange({ target: textarea.current }, { value: newValue });
  };
  return (
    <MarkdownEditor refs={{ textarea }} value={value} onChange={handleChange} textAreaAttributes={{ name }} />
  );
};

export default MarkdownEditor;
