import { useState } from 'react';
import { t } from 'i18n-js';
import Backbone from 'backbone';
import DatasetMetadataView from '../../../../../application/views/dataset/dataset_metadata_show_view';
import ReactToBackboneWrapper from '../../ReactToBackboneWrapper';
import { renameTab, deleteTab } from '../../../tabMonitor';
import { invalidateTable } from '../../../../database_tables/panels/database_table/service';

const DatabaseTableMetadataPanel = ({ tab, workspace }) => {
  const { component, config, id } = tab;
  const dataset = workspace.getDataset(config.args[4]);
  const columns = dataset.columns();
  const backboneTab = new Backbone.Model();
  backboneTab.remove = () => {
    deleteTab(tab.id);
    invalidateTable(workspace.id, config.args[4]);
  };

  const [view] = useState(() => new DatasetMetadataView({
    workspace, tab: backboneTab, dataset, columns,
  }));

  const callback = () => renameTab(
    id,
    t('layout.tabs.dataset_metadata', { name: view.model.get('objectName') }),
  );

  return (
    <ReactToBackboneWrapper
      view={view}
      tabId={id}
      component={component}
      onceLoadedCallback={callback}
    />
  );
};

export default DatabaseTableMetadataPanel;
