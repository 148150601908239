import chorus from '../../chorus';
import Filter from './filter_view';
import DatasetFilter from '../../models/dataset_filter';

export default Filter.extend({
  className: 'dataset',

  setup(...args) {
    this._super('setup', args);
    this.model = this.model || new DatasetFilter();
  },

  postRender(...args) {
    chorus.datePicker({
      '%Y': this.$(".filter.date input[name='year']"),
      '%m': this.$(".filter.date input[name='month']"),
      '%d': this.$(".filter.date input[name='day']"),
    });

    this._super('postRender', args);
  },

  dateFieldValue() {
    const year = this.$(".filter.date input[name='year']").val();
    const month = this.$(".filter.date input[name='month']").val();
    const day = this.$(".filter.date input[name='day']").val();
    return {
      year,
      month,
      day,
      value: ((year + month + day).length > 0) ? [year, month, day].join('/') : '',
    };
  },

  fieldValues() {
    switch (this.map.type) {
      case 'Time':
        return { value: this.$('.filter.time input').val() };
      case 'Date':
        return this.dateFieldValue();
      default:
        return { value: this.$('.filter.default input').val() };
    }
  },
});
