import t from '../intl';
import ModelDelete from './model_delete_alert';
import Comment from '../models/comment';
import Note from '../models/note';
import { toAirlock } from '../presenters/activity_presenters';

export default ModelDelete.extend({
  constructorName: 'DeleteNoteConfirmAlert',

  makeModel(...args) {
    this._super('makeModel', args);

    const { commentId } = this.options;

    if (commentId) {
      this.model = new Comment({
        id: commentId,
      });
      this.setComment();
    } else {
      const { activity } = this.options;
      this.model = this.toNote(activity);
      this.model.unset('body');
      if (activity.isInsight()) {
        this.setInsight();
      } else if (activity.isAirlock()) {
        this.setAirlock();
        this.model = toAirlock(activity);
      } else {
        this.setNote();
      }
    }
  },

  toNote(activity) {
    return new Note({
      id: activity.id,
      body: activity.get('body'),
    });
  },

  setComment() {
    this.text = t('comments.delete.alert.text');
    this.title = t('comments.delete.alert.title');
    this.ok = t('comments.delete.alert.ok');
    this.deleteMessage = 'comments.delete.alert.delete_message';
  },

  setNote() {
    this.text = t('notes.delete.alert.text');
    this.title = t('notes.delete.alert.title');
    this.ok = t('notes.delete.alert.ok');
    this.deleteMessage = 'notes.delete.alert.delete_message';
  },

  setInsight() {
    this.text = t('insight.delete.alert.text');
    this.title = t('insight.delete.alert.title');
    this.ok = t('insight.delete.alert.ok');
    this.deleteMessage = 'insight.delete.alert.delete_message';
  },

  setAirlock() {
    this.text = t('airlock.delete.text');
    this.title = t('airlock.delete.title');
    this.ok = t('airlock.delete.ok');
    this.deleteMessage = 'airlock.delete.message';
  },
});
