import { Card } from 'semantic-ui-react';
import { AppProvider, useAppsContext } from '../../../Context';
import AppsCard from './AppsCard';

const AppsCards = () => {
  const { appsData } = useAppsContext();

  return (
    <Card.Group stackable itemsPerRow={3} data-testid="apps-card-list">
      {appsData?.map((app) => (
        <AppProvider key={app.id} app={app}>
          <AppsCard />
        </AppProvider>
      ))}
    </Card.Group>
  );
};

export default AppsCards;
