/* eslint-disable max-lines */
import { t } from 'i18n-js';
import {
  getArrayFromUrl,
  getPageArgsFromUrl,
  getUrl,
  getUrlFromArray,
  removeTrailingSlash,
} from '../helper';
import makeMatcher from '../routing';

const matcher = makeMatcher();

const tab = (id, component, config, name) => ({
  type: 'tab',
  id,
  component,
  // Config object includes all attributes that panels need to render.
  // This allows us to pass this single prop and stop the panel re-rendering multiple times
  config: {
    id,
    component,
    config,
    name: name || t(`layout.tabs.${component}`),
  },
  name: name || t(`layout.tabs.${component}`),
  className: `semantic aridhia_icon_${component}`,
  helpText: name || t(`layout.tabs.${component}`),
});

const filesTab = (url, pageArgs, params) => {
  const [, storage] = pageArgs;
  const config = { params, url };

  return tab(storage, storage, config);
};

// This is a hack to get around the fact that in NIHR there is a homepage with a link to a specific batch upload
// which causes problems if you try and upload a file of the same name
const isOldBatchId = (batchId) => ((+new Date()) - batchId) / (1000 * 60 * 60 * 24) > 3;

const uploadTab = (url, pageArgs, params) => {
  const [id] = getUrlFromArray(pageArgs).split('?');
  const component = 'upload';
  const config = { args: getArrayFromUrl(url), params, url };
  const [, batchId] = pageArgs;

  if (isOldBatchId(batchId)) {
    config.batchId = (+new Date());
  } else {
    config.batchId = batchId;
  }
  config.folder = [...pageArgs].splice(2).join('/');

  return tab(id, component, config);
};

const dynamicTab = (url, pageArgs, params) => {
  const [id] = getUrlFromArray(pageArgs).split('?');
  const [component] = pageArgs;
  const config = { args: getArrayFromUrl(url), params, url };

  return tab(id, component, config, ' ');
};

const simpleTab = (url, pageArgs, params) => {
  const [id] = getUrlFromArray(pageArgs).split('?');
  const [component, name] = pageArgs;
  const config = { args: getArrayFromUrl(url), params, url };

  return tab(id, component, config, name);
};

const dBTableCreationTab = (url, pageArgs, params) => {
  const [id] = getUrlFromArray(pageArgs).split('?');
  const config = { params, url };

  return tab(id, 'database_table_creation', config);
};

const newViewTab = (url, pageArgs, params) => {
  const [id] = getUrlFromArray(pageArgs).split('?');
  const config = { params, url };

  return tab(id, 'new_view', config, ' ');
};

const databaseTableTab = (url, pageArgs, params) => {
  const [id] = getUrlFromArray(pageArgs).split('?');
  const config = { args: getArrayFromUrl(url), params, url };

  return tab(id, 'database_table', config, ' ');
};

const analyseTab = (url, pageArgs, params) => {
  const [id] = getUrlFromArray(pageArgs).split('?');
  const component = 'analyse';
  const config = { args: getArrayFromUrl(url), params, url };

  return tab(id, component, config, ' ');
};

// to remove when removing the modal
const newDatasetTab = (url, pageArgs, params) => {
  const [id] = getUrlFromArray(pageArgs).split('?');
  const [component, , detail] = pageArgs;
  const config = { args: getArrayFromUrl(url), params, url };
  const name = t('layout.tabs.dataset_upload', { name: detail });

  return tab(id, component, config, name);
};

const sqlResultsTab = (url, pageArgs, params) => {
  const [id] = getUrlFromArray(pageArgs).split('?');
  const [component] = pageArgs;
  const config = { args: getArrayFromUrl(url), params, url };

  return tab(id, component, config);
};

const rtoolsTab = (url, _, params) => {
  const config = { args: getArrayFromUrl(url), params, url };

  return tab('rtools', 'rtools', config);
};

const airlockTab = (url, _, params) => {
  const config = { args: getArrayFromUrl(url), params, url };
  return tab('airlock_new', 'airlock_new', config);
};

const notFoundTab = (url, pageArgs) => {
  const [id] = getUrlFromArray(pageArgs).split('?');
  return tab(id, 'not_found', { url });
};

const officeTab = (url, pageArgs, params) => {
  const config = {
    pageArgs, args: getArrayFromUrl(url), params, url,
  };
  const [id] = getUrlFromArray(pageArgs).split('?');

  return tab(id, 'office', config, ' ');
};

const createTab = (url, pageArgs) => {
  const path = (pageArgs.join('/'));

  const routes = [
    ['activity/:activity_id/attachment/:attachment_id', dynamicTab],
    ['airlock_new', airlockTab],
    ['airlock_new/:airlock_type/:airlock_id', airlockTab],
    ['app/:app_id', dynamicTab],
    ['apps', simpleTab],
    ['audit', simpleTab],
    ['folders/blobs', filesTab],
    ['folders/blobs/:folder_path*', filesTab],
    ['custom_views/:table_id', dynamicTab],
    ['database_views/:table_id', dynamicTab],
    ['database_tables/new', dBTableCreationTab],
    ['database_tables/new/:file_id', dBTableCreationTab],
    ['datasets', simpleTab],
    ['datasets/:table_id', databaseTableTab],
    ['datasets/:table_id/new_view', newViewTab],
    ['dataset_metadata/:metadata_id', dynamicTab],
    ['datatable/dataset/:table_id/analyse', analyseTab],
    ['datatable/file/:table_id/analyse', analyseTab],
    ['datatable/new/:table_name', newDatasetTab], // to remove when removing the modal
    ['desktop/:virtual_machine_id', simpleTab],
    ['desktop/:virtual_machine_id/vnc', simpleTab],
    ['desktops', simpleTab],
    ['features', simpleTab],
    ['folders/files', filesTab],
    ['folders/files/:folder_path*', filesTab],
    ['home', simpleTab],
    ['folders/inbox', filesTab],
    ['folders/inbox/:folder_path*', filesTab],
    ['mini_app/:app_id', dynamicTab],
    ['mini_apps', simpleTab],
    ['rtools', rtoolsTab],
    ['rtools/:console_id', rtoolsTab],
    ['summary', simpleTab],
    ['sqlresults/:results_id', sqlResultsTab],
    ['upload/:upload_id/inbox', uploadTab],
    ['upload/:upload_id/files', uploadTab],
    ['upload/:upload_id/files/:folder_path*', uploadTab],
    ['upload/:upload_id/blobs', uploadTab],
    ['upload/:upload_id/blobs/:folder_path*', uploadTab],
    ['upload_token', simpleTab],
    ['workfiles/:file_id', dynamicTab],
    ['workfiles/:file_id/run', dynamicTab],
    ['workfiles/:file_id/text', dynamicTab],
    ['workfiles/:file_id/office', officeTab],
    ['secrets', simpleTab],
  ];

  for (const route of routes) {
    const [pattern, tabFn] = route;
    const [matched, params] = matcher(pattern, path);
    if (matched) {
      return tabFn(url, pageArgs, params);
    }
  }

  return notFoundTab(url, pageArgs);
};

export const getCurrentTabComponent = () => {
  const url = removeTrailingSlash(getUrl());
  const pageArgs = getPageArgsFromUrl(url, 3);
  const { component } = createTab(url, pageArgs);
  return component;
};

export default createTab;
