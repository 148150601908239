import { Segment, Icon, Header } from 'semantic-ui-react';
import { t } from 'i18n-js';

const NoInboxFilesMessage = () => (
  <Segment placeholder>
    <Header as="h4" icon>
      <Icon name="file alternate" data-testid="file-icon" />
      {t('file_list.accessibility.no_file_list')}
    </Header>
  </Segment>
);

export default NoInboxFilesMessage;
