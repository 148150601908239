import _ from '../underscore';
import Header from '../views/core/header';
import BarePage from './bare_page';
import Routing from '../mixins/routing';
import { FEATURE } from '../../utilities/features';

export default BarePage.include(Routing).extend({
  templateName: 'error',
  additionalClass: 'logged_in_layout',

  events: {
    'click button.submit': 'navigateToHome',
  },

  subviews: {
    '#header': 'header',
  },

  setupSubviews() {
    if (!FEATURE.NEW_UX) {
      this.header = new Header({});
    }
  },

  navigateToHome() {
    this.navigate('#');
  },

  context() {
    return _.extend({
      title: this.title,
      text: this.text,
    }, this.pageOptions);
  },
});
