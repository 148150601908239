import { t } from 'i18n-js';
import React from 'react';
import { Image } from 'semantic-ui-react';
import './SingleContainerisedCard.less';
import DataTile from '../../../../public/images/datatile.png';

const SingleCardBodyTile = ({ handleRun, displayPicture }) => {
  const tile = displayPicture || DataTile;

  return (
    <Image
      className="aridhia-tile-image"
      src={tile}
      alt={t('app.card.body.display_picture')}
      as="a"
      onClick={handleRun}
    />
  );
};

export default SingleCardBodyTile;
