import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import DatabaseSidebarList from './database_sidebar_list_view';
import { renderTemplate } from '../../utilities/handlebars_helpers';

export default DatabaseSidebarList.extend({
  constructorName: 'FunctionTabView',
  templateName: 'function_tab',
  useLoadingSection: true,

  setup(...args) {
    this._super('setup', args);
    this.resource = this.schema.functions();
    this.collection = this.resource;
    this.listenTo(this.resource, 'change reset add remove fetchFailed', this.render);
    this.collection.fetchAllIfNotLoaded();
  },

  postRender(...args) {
    this._super('postRender', args);

    chorus.search({
      list: this.$('ul'),
      input: this.$('input.search'),
    });

    this.setupDragging();
    this.setupDescriptionPopover();
  },

  setupDragging() {
    this.$('ul.list li').each(function(idx) { 
      this.setAttribute('draggable', 'True');
      this.addEventListener( 'dragstart', function(ev) { 
        ev.dataTransfer.setData('text', this.getAttribute('data-fullname')); 
      });
    });
  },

  setupDescriptionPopover() {
    this.$('li .name').qtip({
      events: {
        render: _.bind(function addContent(e, api) {
          e.preventDefault();
          e.stopPropagation();
          const cid = $(api.elements.target).parent().data('cid');
          const model = this.collection.get(cid);
          const content = this.tooltipContent(model);
          $(api.elements.content).html(content);
        }, this),
        show(e, api) {
          $(api.elements.target).addClass('hover');
        },
        hide(e, api) {
          $(api.elements.target).removeClass('hover');
        },
      },
      show: {
        delay: 0,
        effect: false,
      },
      hide: {
        delay: 250,
        fixed: true,
        effect: false,
      },
      position: {
        viewport: $(window),
        my: 'top center',
        at: 'bottom left',
      },
      style: {
        classes: 'tooltip-function',
        tip: {
          def: false,
          height: 5,
          classes: 'hidden',
        },
      },
    });
  },

  tooltipContent(model) {
    const html = renderTemplate('function_tab_tooltip', {
      description: _.prune(model.get('description') || '', 100),
      returnType: model.get('returnType'),
      name: model.get('name'),
      argumentsString: model.formattedArgumentList(),
    }).toString();
    const content = $('<div/>').html(html);
    content.find('a.more').data('model', model);
    return content;
  },

  collectionModelContext(model) {
    return {
      hintText: model.toHintText(),
      cid: model.cid,
      name: model.get('name'),
      fullName: model.toText(),
    };
  },

  additionalContext(...args) {
    return _.extend(this._super('additionalContext', args), {
      hasCollection: !!this.collection,
      error: this.collection && this.collection.serverErrors && this.collection.serverErrors.message,
    });
  },

  displayLoadingSection() {
    return this.schema && !(this.collection && (this.collection.loaded || this.collection.serverErrors));
  },
});
