import React, { useRef, useState } from 'react';
import { t } from 'i18n-js';
import { Button, Icon, Table } from 'semantic-ui-react';
import NoCompletionInput from '../Forms';

function EnvVarTableAddRow({ onAdd }) {
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const nameInput = useRef(null);

  const canSubmit = name && value;

  const handleSubmit = () => {
    onAdd(name, value);
    setName('');
    setValue('');
    nameInput.current.focus();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && canSubmit) {
      handleSubmit();
    }
  };

  return (
    <Table.Row>
      <Table.HeaderCell>
        <NoCompletionInput
          ref={nameInput}
          fluid
          placeholder={t('app.form.vars.name')}
          value={name}
          onChange={(_, data) => setName(data.value)}
          onKeyDown={handleKeyDown}
        />
      </Table.HeaderCell>
      <Table.HeaderCell>
        <NoCompletionInput
          fluid
          placeholder={t('app.form.vars.value')}
          value={value}
          onChange={(_, data) => setValue(data.value)}
          onKeyDown={handleKeyDown}
        />
      </Table.HeaderCell>
      <Table.HeaderCell collapsing textAlign="right">
        <Button
          onClick={handleSubmit}
          disabled={!canSubmit}
          compact
          basic
          icon
          floated="right"
        >
          <Icon
            name="plus"
            color="blue"
            alt={t('app.form.vars.set')}
          />
        </Button>
      </Table.HeaderCell>
    </Table.Row>
  );
}

export default EnvVarTableAddRow;
