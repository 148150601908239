import _ from '../../underscore';
import t from '../../intl';
import ResultsConsole from '../../views/workfiles/results_console_view';
import Dialog from '../../dialogs/dialogs';
import { preview } from '../../utilities/schema_assoc';

export default Dialog.extend({
  constructorName: 'DatasetPreview',
  additionalClass: 'dialog_wide',

  templateName: 'dataset_preview',
  title() { return t('dataset.data_preview_title', { name: this.model.get('objectName') }); },

  events: {
    'click button.cancel': 'cancelTask',
  },

  subviews: {
    '.results_console': 'resultsConsole',
  },

  setup() {
    _.bindAll(this, 'title');
    this.resultsConsole = new ResultsConsole({
      footerSize: _.bind(this.footerSize, this),
      showDownloadDialog: true,
      dataset: this.model,
      enableResize: true,
      enableExpander: true,
      verticalDialogPadding: this.verticalPadding,
    });
    this.subscribePageEvent('action:closePreview', this.closeModal);
    this.subscribePageEvent('modal:closed', this.cancelTask);
  },

  footerSize() {
    return this.$('.form_controls').outerHeight(true);
  },

  postRender() {
    this.task = preview(this.model);
    this.resultsConsole.execute(this.task);
  },

  cancelTask() {
    this.task && this.task.cancel();
  },
});
