import NavBar from '../components/nav_bar/NavBar';
import Announcements from '../components/announcement/Announcements';
import LayoutManager from '../layout/components/LayoutManager';
import CopyWarning from '../components/copy_warning/CopyWarning';
import ToastWrapper from '../components/ToastWrapper/ToastWrapper';
import Ribbon from '../components/ribbon/Ribbon';
import PageWrapper from './PageWrapper';
import '../assets/styles/index.less';
import '../components/toasts/Toasts.less';

const LayoutPage = () => (
  <PageWrapper>
    <div className="semantic ux-update">
      <NavBar />
    </div>
    <section id="announcement" className="semantic">
      <Announcements />
    </section>
    <section className="semantic ux-update">
      <Ribbon />
    </section>
    <section id="body" className="row-fluid">
      <main id="main_content">
        <LayoutManager />
      </main>
    </section>
    <CopyWarning />
    <footer id="footer" className="row-fluid rsh-footerbar" />
    <ToastWrapper />
  </PageWrapper>
);

export default LayoutPage;
