import Inbox from '../../../../files/panels/inbox/components/Inbox';
import ResponsivePanel from '../../ResponsivePanel';

const InboxPanel = ({ tab }) => {
  const { config } = tab;

  const folderPath = config.params.folder_path ? `inbox/${config.params.folder_path}` : 'inbox';

  return (
    <ResponsivePanel>
      {(panelWidth) => <Inbox panelWidth={panelWidth} folderPath={folderPath} />}
    </ResponsivePanel>
  );
};

export default InboxPanel;
