import TermsAndConditions from '../../terms_and_conditions';

export default TermsAndConditions.extend({
  title: 'Adding a Workspace: Terms & Conditions',
  formSelector: 'form.new_workspace',

  additionalContext() {
    return { headerTranslation: 'ts_and_cs.workspace.header', workspace: true };
  },
});
