import { Card, Image, Message } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { statusColor } from '../../../helper';
import VirtualMachinesCardFooter from './VirtualMachinesCardFooter';
import { statuses } from '../../../constants';

export const PausedMessage = () => (
  <Message
    compact
    warning
    size="tiny"
    icon={{ name: 'warning circle', 'data-testid': 'paused-warning-message icon' }}
    header={t('virtual_machines.warning.paused.header')}
    content={t('virtual_machines.warning.paused.message')}
  />
);

export const OsImage = ({ os }) => (
  <Image
    src={`/images/new_ux/${os}.svg`}
    alt={`${os} ${t('virtual_machines.logo_alt_text')}`}
    size="mini"
    floated="right"
  />
);

export const CardContent = ({ os, createdAt, numberOfConnectedUsers }) => (
  <>
    <p>
      {`${t('virtual_machines.content.type')}: `}
      <strong>{os}</strong>
    </p>
    {createdAt && (
      <p>
        {`${t('virtual_machines.content.created')}: `}
        <strong>{createdAt}</strong>
      </p>
    )}
    <p>
      {`${t('virtual_machines.content.connected_users')}: `}
      <strong>{numberOfConnectedUsers}</strong>
    </p>
  </>
);

export const CardView = ({
  color, image, name, status, content, additionalMessage, footer,
}) => (
  <Card color={color} data-testid="virtual-machines-card">
    <Card.Content>
      {image}
      <Card.Header as="h3" className="margin-bottom-none">
        {name}
      </Card.Header>
      <Card.Meta as="p" data-testid="virtual-machines-card-meta">
        {status}
      </Card.Meta>
      <Card.Description data-testid="virtual-machines-card-content">
        {content}
        {additionalMessage}
      </Card.Description>
    </Card.Content>
    <Card.Content extra textAlign="right" data-testid="virtual-machines-card-footer">
      {footer}
    </Card.Content>
  </Card>
);

const VirtualMachinesCard = (props) => {
  const { displayName, status } = (props);

  return CardView({
    color: statusColor(status),
    image: OsImage(props),
    name: displayName,
    status: t(`virtual_machines.status.${status}`),
    content: CardContent(props),
    additionalMessage: status === statuses.PAUSED && PausedMessage(),
    footer: VirtualMachinesCardFooter(props),
  });
};

export default VirtualMachinesCard;
