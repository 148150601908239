import { t } from 'i18n-js';
import { Grid, Divider } from 'semantic-ui-react';
import DatabaseTableCreationTableFromScratch from './DatabaseTableCreationTableFromScratch';
import DatabaseTableCreationTableFromCSV from './DatabaseTableCreationTableFromCSV';

const DatabaseTableCreationTable = () => (
  <Grid columns={2} className="aridhia-grid">
    <Divider vertical>{t('database_tables_new.or')}</Divider>
    <Grid.Row>
      <Grid.Column>
        <DatabaseTableCreationTableFromScratch />
      </Grid.Column>
      <Grid.Column>
        <DatabaseTableCreationTableFromCSV />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default DatabaseTableCreationTable;
