import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';

const HeaderRowForm = ({ inputHasHeader, handleInputHasHeaderChange }) => (
  <>
    <Checkbox
      id="include_header"
      label={t('dataset.import.include_header')}
      checked={inputHasHeader}
      onChange={handleInputHasHeaderChange}
    />
  </>
);

const mapModelsToProps = ({ datasetUpload }) => ({
  inputHasHeader: datasetUpload.get('input_has_header'),
  handleInputHasHeaderChange: (_e, { checked }) => {
    datasetUpload.updateInputHasHeader(!!checked);
  },
});

const options = {
  debounce: true,
  events: {
    datasetUpload: ['change:input_has_header'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(HeaderRowForm);
