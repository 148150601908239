import WorkfolderPickItems from './workfolder_pick_items';
import Workfile from '../../models/workfile';
import Routing from '../../mixins/routing';

export default WorkfolderPickItems.include(Routing).extend({
  makeModel(...args) {
    this._super('makeModel', args);
    this.workfile = this.workfile || new Workfile({
      workspace: { id: this.options.workspace.id },
    });
  },

  setup() {
    this.excludeFileName = false;
    this._super('setup');

    if (this.workfile) {
      this.listenTo(this.workfile, 'saved', this.saved);
      this.listenTo(this.workfile, 'saveFailed', this.saveFailed);
    }
  },

  submit() {
    const folder = this.folder.get('folder');
    const fileName = this.$('input[name=fileName]').val();
    this.workfile.set({
      folder,
      versionInfo: null,
      fileName,
      content: this.options.content,
    }, { silent: true });
    this.workfile.save({ source: 'empty' });
    this.$('button.submit').attr('disabled', 'disabled');
  },

  saved() {
    this.navigate(this.workfile.showUrl());
    chorus.toast('workfiles.file.save_succeeded');
    this.closeModal();
  },

  saveFailed() {
    chorus.toast('workfiles.file.save_failed', {
      toastOpts: { theme: 'bad_activity' },
    });
    this.closeModal();
  },
});
