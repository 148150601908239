import $ from '../jquery';

export default {
  preInitialize(...args) {
    this.events['change .select_all'] = 'changeSelection';

    this.listenTo(this.collection, 'selectNone', this.anyUnselected);
    this.listenTo(this.collection, 'unselectAny', this.anyUnselected);
    this.listenTo(this.collection, 'allSelected', this.allSelected);
    this.selectAllChecked = false;
    this._super('preInitialize', args);
  },

  changeSelection(e) {
    e.preventDefault();
    if ($(e.currentTarget).is(':checked')) {
      this.collection.trigger('selectAll');
      this.selectAllChecked = true;
    } else {
      this.collection.trigger('selectNone');
      this.selectAllChecked = false;
    }
  },

  renderCheckedState() {
    this.$('.select_all').prop('checked', this.selectAllChecked);
  },

  allSelected() {
    this.$('.select_all').prop('checked', true);
    this.selectAllChecked = true;
  },

  anyUnselected() {
    this.$('.select_all').prop('checked', false);
    this.selectAllChecked = false;
  },

  unselectOnFetch() {
    const selectNone = function onSelectNone() {
      this.collection.trigger('selectNone', { reset: true });
    };
    this.listenTo(this.collection, 'choice:filter', selectNone);
    this.listenTo(this.collection, 'choice:sort', selectNone);
    this.listenTo(this.collection, 'paginate searched', selectNone);
  },
};
