import React from 'react';
import Cookies from 'js-cookie';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { BackboneProvider } from 'connect-backbone-to-react';
import View from '../react_view';
import { FEATURE } from '../../../utilities/features';
import Tracking from 'components/tracking/Tracking';

export default View.extend({
  constructorName: 'trackingView',

  setup(options) {
    this.workspace = options.model;
    this.listenTo(this.collection, 'change:active', this.changeTabActive);
    this.listenTo(this.collection, 'change:label', this.changeTabLabel);
    this.tab = this.collection.models.find(t => t.get('active'));
  },

  changeTabActive(tab) {
    if (tab.get('active') && tab.get('name') && (!this.tab || this.tab.get('url') !== tab.get('url'))) {
      this.tab = tab;
      this.render();
    }
  },

  changeTabLabel(tab) {
    if (tab.get('active') && tab.get('label') && (!this.tab || this.tab.get('url') !== tab.get('url'))) {
      this.tab = tab;
      this.render();
    }
  },

  render() {
    if (FEATURE.SITE_TRACKING_ENABLED && Cookies.get('workspacesTrackingConsent') === 'true' && window.customizationVariables.tracking) {
      const instance = createInstance({
        urlBase: '/matomo/',
        siteId: window.customizationVariables.tracking.siteId,
      });

      const modelsMap = {
        tab: this.tab,
        workspace: this.workspace,
      };

      this.root.render(
        <BackboneProvider models={modelsMap}>
          <MatomoProvider value={instance}>
            <Tracking />
          </MatomoProvider>
        </BackboneProvider>,
      );
    }
  },

});
