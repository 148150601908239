import * as d3 from 'd3';
import chorus from '../../chorus';
import _ from '../../underscore';
import View from '../loading_view';
import { labelFormatKeepPercentage, labelFormat } from '../../utilities/vis_helpers';

const Axis = function buildAxis(options) {
  this.labels = options.labels;
  this.longLabels = options.longLabels;
  this.axisLabel = options.axisLabel;
  this.hasGrids = options.hasGrids;
  this.width = options.el.attr('width');
  this.height = options.el.attr('height');
  this.container = options.el;
  this.scaleType = options.scaleType;
  this.timeFormat = options.timeFormat;
  this.timeType = options.timeType;

  this.minValue = options.minValue;
  this.maxValue = options.maxValue;

  this.paddingX = options.paddingX || 20;
  this.paddingY = options.paddingY || 20;
  this.offsetX = options.offsetX || 0;
  this.offsetY = options.offsetY || 0;
  this.tickLength = options.tickLength || 5;
  this.labelSpacing = options.labelSpacing || 10;
};

Axis.extend = View.extend;
_.extend(Axis.prototype, {
  maxX() {
    return this.width - this.paddingX;
  },

  minX() {
    return this.paddingX + this.offsetX;
  },

  maxY() {
    return this.height - this.paddingY - this.offsetY;
  },

  minY() {
    return this.paddingY;
  },

  axisLabels() {
    if (this.scaleType === 'numeric') {
      let tickArray = this.scale().ticks(6);
      if (tickArray.length === 0) tickArray = [this.minValue];
      return tickArray;
    } else if (this.scaleType === 'time') {
      if (this.timeType === 'datetime') {
        return this.scale().ticks(4);
      }
      return this.scale().ticks(6);
    }
    if (this.longLabels) {
      return this.longLabels;
    }

    return this.labels;
  },

  tickScale() {
    const scale = this.scale();
    if (['numeric', 'time'].includes(this.scaleType)) {
      return scale;
    }
    return function getScale(d) {
      return scale(d) + (scale.bandwidth() / 2);
    };
  },

  scale() {
    if (this.scaleType === 'time') {
      return d3.scaleTime()
        .domain([Date.parse(this.minValue), Date.parse(this.maxValue)])
        .range(this.range());
    } else if (this.scaleType === 'numeric') {
      return d3.scaleLinear()
        .domain([this.minValue, this.maxValue])
        .range(this.range());
    }

    return d3.scaleBand()
      .domain(this.longLabels || this.labels)
      .range(this.range());
  },
});

const XAxis = Axis.extend({
  requiredBottomSpace() {
    const self = this;
    this.el = this.container.append('svg:g').attr('class', 'xaxis');
    const testTickLabels = this.el.selectAll('.tick_label')
      .data(this.axisLabels()).enter()
      .append('svg:g')
      .attr('class', 'tick_label')
      .append('svg:text');

    if (this.scaleType === 'time') {
      testTickLabels.text(d => self.timeFormat(d));
    } else {
      testTickLabels.text(d => labelFormat(d));
    }

    this.rotateTickLabelsIfNeeded();

    this.el.append('svg:text').text(this.axisLabel).attr('class', 'axis_label');

    const height = this.tickLabelHeight() + this.axisLabelHeight() + (2 * this.labelSpacing) + this.tickLength;
    this.el.remove();
    return height;
  },

  tickLabelHeight() {
    return _.max(_.map(this.el.selectAll('.tick_label').nodes(), label => label.getBBox().height));
  },

  axisLabelHeight() {
    return this.el.selectAll('.axis_label').node().getBBox().height;
  },

  range() {
    return [this.minX(), this.maxX()];
  },

  rotateTickLabelsIfNeeded() {
    const bandWidth = (this.maxX() - this.minX()) / this.axisLabels().length;
    const tickLabels = this.el.selectAll('.tick_label text');
    const needToRotate = _.any(tickLabels.nodes(), label => label.getBBox().width > bandWidth);

    if (needToRotate) {
      const maxWidth = _.max(_.map(tickLabels.nodes(), tickLabel => tickLabel.getBBox().width));
      const angle = 290;
      const translationY = Math.sin((angle * Math.PI) / 180) * maxWidth;

      tickLabels.attr('transform', function translationRotation() {
        const box = this.getBBox();
        const rightX = box.x + box.width;
        const centerY = box.y + (box.height / 2);
        const translation = `translate(${-0.5 * box.width} ${translationY}) `;
        const rotation = `rotate(${angle} ${rightX} ${centerY})`;
        return translation + rotation;
      });
    }
  },

  render() {
    const tickScale = this.tickScale();

    const self = this;
    this.el = this.container.append('svg:g').attr('class', 'xaxis');

    // draw axis label
    const axisLabel = this.el.append('svg:text')
      .text(this.axisLabel)
      .attr('x', 0)
      .attr('y', this.maxY())
      .attr('class', 'axis_label');

    this.el.append('svg:text')
      .text(chorus.instance.get('visualizationOverlayString'))
      .attr('class', 'visualization_overlay_string')
      .attr('x', 10)
      .attr('y', this.maxY());

    // reposition axis label now that we know its width
    const axisLabelWidth = axisLabel.node().getBBox().width;
    const centerX = ((this.minX() + this.maxX()) / 2) - (axisLabelWidth / 2);
    this.el.select('.axis_label').attr('x', centerX);

    // draw tick labels
    const tickLabelBottom = this.maxY() - this.labelSpacing - this.axisLabelHeight();
    const tickLabels = this.el.append('svg:g').attr('class', 'labels')
      .selectAll('.tick_label')
      .data(this.axisLabels())
      .enter()
      .append('svg:g')
      .attr('class', 'tick_label')
      .append('svg:text')
      .attr('y', tickLabelBottom)
      .attr('x', 0);

    if (this.scaleType === 'time') {
      tickLabels.text(d => self.timeFormat(d));
    } else {
      tickLabels.text(d => labelFormatKeepPercentage(d));
    }

    // reposition labels now that we know their width
    tickLabels
      .attr('x', function repositionLabels(d) {
        const left = tickScale(d);
        const { width } = this.getBBox();
        return left - (width / 2);
      });

    this.rotateTickLabelsIfNeeded();

    const tickBottom = tickLabelBottom - this.tickLabelHeight() - this.labelSpacing;
    const tickTop = tickBottom - this.tickLength;

    // draw ticks
    this.el.append('svg:g').attr('class', 'ticks').selectAll('.tick')
      .data(this.axisLabels())
      .enter()
      .append('svg:line')
      .attr('class', 'tick')
      .attr('y1', tickTop)
      .attr('y2', tickBottom)
      .attr('x1', tickScale)
      .attr('x2', tickScale);

    // draw grid lines if applicable
    if (this.hasGrids) {
      this.el.append('svg:g').attr('class', 'grids').selectAll('.grid')
        .data(this.axisLabels().slice(1))
        .enter()
        .append('svg:line')
        .attr('class', 'grid')
        .attr('y1', this.minY())
        .attr('y2', tickTop)
        .attr('x1', tickScale)
        .attr('x2', tickScale);
    }

    // draw main axis line
    this.el.append('svg:line')
      .attr('class', 'axis')
      .attr('x1', this.minX())
      .attr('x2', this.maxX())
      .attr('y1', tickTop)
      .attr('y2', tickTop);
  },
});

const YAxis = Axis.extend({
  requiredLeftSpace() {
    this.el = this.container.append('svg:g').attr('class', 'yaxis');
    this.el.selectAll('.tick_label.test-origin-y')
      .data(this.axisLabels()).enter()
      .append('svg:g')
      .attr('class', 'tick_label')
      .append('svg:text')
      .text(d => labelFormat(d));
    const testAxisLabel = this.el.append('svg:g')
      .attr('class', 'axis_label')
      .append('svg:text')
      .attr('x', 0)
      .attr('y', 0)
      .text(this.axisLabel);

    const axisLabelHeight = testAxisLabel.node().getBBox().height;
    const width = axisLabelHeight + this.labelWidth() + (2 * this.labelSpacing) + this.tickLength;

    this.el.remove();
    return width;
  },

  labelWidth() {
    return _.max(_.map(this.el.selectAll('.tick_label').nodes(), label => label.getBBox().width));
  },

  range() {
    return [this.maxY(), this.minY()];
  },

  render() {
    this.el = this.container.append('svg:g').attr('class', 'yaxis');

    const tickScale = this.tickScale();

    // draw axis label
    const axisLabelContainer = this.el.append('svg:g')
      .attr('class', 'axis_label');
    const axisLabel = axisLabelContainer.append('svg:text')
      .attr('x', 0)
      .attr('y', 0)
      .text(this.axisLabel);

    // reposition axis label now that we know its height
    const centerY = (this.minY() + this.maxY()) / 2;
    const axisLabelBox = axisLabel.node().getBBox();
    const axisLabelWidth = axisLabelBox.width;
    const axisLabelHeight = axisLabelBox.height;
    axisLabel
      .attr('transform', 'rotate(270)')
      .attr('x', -1 * (centerY + (axisLabelWidth / 2)))
      .attr('y', this.paddingX + axisLabelHeight);

    const tickLabelLeft = this.paddingX + axisLabelHeight + this.labelSpacing;

    // draw labels
    this.el.selectAll('.tick_label')
      .data(this.axisLabels()).enter()
      .append('svg:text')
      .attr('class', 'tick_label')
      .attr('y', 0)
      .attr('x', 0)
      .text(d => labelFormat(d))
      .attr('title', d => d);

    // reposition labels now that we know their width
    this.el.selectAll('.tick_label')
      .attr('x', tickLabelLeft)
      .attr('y', function repositionLabels(d) {
        const scalePoint = tickScale(d);
        const { height } = this.getBBox();
        return scalePoint + (height / 4);
      });

    const tickLeft = tickLabelLeft + this.labelWidth() + this.labelSpacing;
    const tickRight = tickLeft + this.tickLength;

    // draw ticks
    this.el.selectAll('.tick')
      .data(this.axisLabels()).enter()
      .append('svg:line')
      .attr('class', 'tick')
      .attr('y1', tickScale)
      .attr('y2', tickScale)
      .attr('x1', tickLeft)
      .attr('x2', tickRight);

    // draw grid lines if applicable
    if (this.hasGrids) {
      this.el.append('svg:g').attr('class', 'grids').selectAll('.grid')
        .data(this.axisLabels())
        .enter()
        .append('svg:line')
        .attr('class', 'grid')
        .attr('y1', tickScale)
        .attr('y2', tickScale)
        .attr('x1', tickRight)
        .attr('x2', this.maxX());
    }

    // draw main axis line
    this.el.append('svg:line')
      .attr('class', 'axis')
      .attr('y1', this.height - this.paddingY - this.offsetY)
      .attr('y2', this.paddingY)
      .attr('x1', tickRight)
      .attr('x2', tickRight);
  },

});

const Axes = function buildAxes(options) {
  this.xAxis = new XAxis({
    el: options.el,
    minValue: options.minXValue,
    maxValue: options.maxXValue,
    scaleType: options.xScaleType,
    labels: options.xLabels,
    longLabels: options.xLongLabels,
    axisLabel: options.xAxisLabel,
    hasGrids: options.hasXGrids,
    timeFormat: options.timeFormat,
    timeType: options.timeType,
    paddingX: options.paddingX,
    paddingY: options.paddingY,
  });

  this.yAxis = new YAxis({
    el: options.el,
    minValue: options.minYValue,
    maxValue: options.maxYValue,
    scaleType: options.yScaleType,
    labels: options.yLabels,
    axisLabel: options.yAxisLabel,
    hasGrids: options.hasYGrids,
    paddingX: options.paddingX,
    paddingY: options.paddingY,

  });
};

_.extend(Axes.prototype, {
  scales() {
    return { x: this.xAxis.scale(), y: this.yAxis.scale() };
  },


  render() {
    this.xAxis.offsetX = this.yAxis.requiredLeftSpace();
    this.yAxis.offsetY = this.xAxis.requiredBottomSpace();

    this.xAxis.render();
    this.yAxis.render();
  },
});

export default Axes;
