import t from '../../intl';
import WorkfolderPickItems from '../workfiles/workfolder_pick_items';

export default WorkfolderPickItems.extend({
  setup() {
    this.tab = this.options.tab;
    this.inboundAirlockModel = this.options.inboundAirlockModel;
    this.excludeFileName = true;
    this._super('setup');
  },

  submit() {
    this.$('button.submit').attr('disabled', 'disabled');
    const folder = this.folder.get('folder');
    this.inboundAirlockModel.set({
      folder,
      origFolder: folder,
      sendToInbox: false,
    });

    const shorterFolder = folder.length > 16 ? `... ${folder.slice(folder.length - 13)}` : folder;
    this.tab.set('label', t('inbound_airlock.upload_tab', { folder: shorterFolder }));
    this.tab.set('title', t('inbound_airlock.upload_tab', { folder: folder }));

    this.closeModal();
  },
});
