import _ from '../underscore';
import DatasetFilter from '../models/dataset_filter';
import Collection from './collections';

const DatasetFilterSet = Collection.extend({
  constructorName: 'DatasetFilterSet',
  model: DatasetFilter,

  sqlStrings() {
    let wheres = this.map(filter => filter.sqlString());

    wheres = _.without(wheres, '');
    return wheres;
  },

  whereClause() {
    const wheres = this.sqlStrings();
    return wheres.length ? (`WHERE ${wheres.join(' AND ')}`) : '';
  },

  count() {
    return this.sqlStrings().length;
  },

  clone() {
    const clonedModels = this.map(model => model.clone());
    return new DatasetFilterSet(clonedModels);
  },
});

export default DatasetFilterSet;
