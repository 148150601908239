import _ from '../underscore';
import $ from '../jquery';
import t from '../intl';
import Modal from '../dialogs/modals';

export default Modal.extend({
  constructorName: 'Alert',
  templateName: 'alert',

  events: {
    'click button.cancel': 'cancelAlert',
    'click button.submit': 'confirmAlert',
  },

  confirmAlert: $.noop,

  focusSelector: 'button.cancel',

  cancelAlert() {
    this.closeModal();
  },

  additionalContext() {
    return {
      title: this.title,
      text: _.result(this, 'text'),
      htmltext: this.htmltext,
      body: this.body,
      ok: this.ok,
      cancel: this.cancel || t('actions.cancel'),
    };
  },

  revealed() {
    $('#facebox').removeClass().addClass('alert_facebox');
  },
});
