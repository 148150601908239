import FileNavigationEmpty from '../navigation/FileNavigationEmpty';
import FileNavigationTableRow from '../navigation/FileNavigationTableRow';
import {
  EmptyMessage, Paging, Stats, TableWrapper,
} from '../../query_table';
import FileNavigationTableHeader from '../navigation/FileNavigationTableHeader';

const AttachmentTable = ({
  files,
  isFetching,
  handleRefreshButton,
  isError,
  handleFilterChange,
  currentFolder,
  handleSetFolder,
  handleAddAttachedItems,
  pagination,
  setPage,
}) => {
  const renderBodyRow = (file) => {
    const name = file.file_name || file.object_name;
    const type = file.file_type || file.entity_type;
    const destinationFolder = `${file.folder}/${name}`;
    const onClick = file.file_type === 'folder'
      ? () => handleSetFolder(destinationFolder)
      : () => handleAddAttachedItems({ itemId: file.id, itemName: name, itemType: type });

    return (
      <FileNavigationTableRow
        key={file.id}
        name={name}
        type={type}
        onClick={onClick}
      />
    );
  };

  return (
    <TableWrapper
      tableData={files}
      columns={[1]}
      tableHeader={(
        <FileNavigationTableHeader
          isFetching={isFetching}
          handleRefreshButton={handleRefreshButton}
          isError={isError}
          handleFilterChange={handleFilterChange}
          currentFolder={currentFolder}
          handleSetFolder={handleSetFolder}
        />
      )}
      renderBodyRow={renderBodyRow}
      emptyWrapper={(
        <EmptyMessage
          isFetching={isFetching}
          tableData={files}
          columns={[1]}
          emptyContent={<FileNavigationEmpty />}
        />
      )}
      stats={<Stats shown={files.length} total={pagination.records} />}
      pagination={<Paging total={pagination.records} onPageChange={setPage} activePage={pagination.page} />}
    />
  );
};

export default AttachmentTable;
