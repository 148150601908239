import $ from '../jquery';
import Backbone from '../vendor/backbone';
import Handlebars from '../vendor/handlebars';
import chorus from '../chorus';
import _ from '../underscore';
import t from '../intl';
import { AllWhitespace } from '../../utilities/validation_regexes';
import Urls from '../mixins/urls';
import Events from '../mixins/events';
import ServerErrors from '../mixins/server_errors';
import DbHelpers from '../mixins/db_helpers';
import Fetching from '../mixins/fetching';
import withSearchResults from '../utilities/search_helpers';

const Model = Backbone.Model.include(
  Urls,
  Events,
  Fetching,
  ServerErrors,
  DbHelpers,
).extend({
  constructorName: 'Model',

  eventType() {
    return this.get('entityType');
  },

  isDeleted() {
    return this.get('isDeleted') && (this.get('isDeleted') === true || this.get('isDeleted') === 'true');
  },

  canDestroy() {
    return true;
  },

  // Build the url for a model based on the urlTemplate in the model's context.
  url(options) {
    const template = _.isFunction(this.urlTemplate) ? this.urlTemplate(options) : this.urlTemplate;
    const context = _.extend({}, this.attributes, { entityId: this.entityId, entityType: this.entityType });
    const uri = new window.URI(`/${Handlebars.compile(template, { noEscape: true })(context)}`);
    if (this.urlParams) {
      const params = _.isFunction(this.urlParams) ? this.urlParams(options) : this.urlParams;
      uri.addSearch(this.underscoreKeys(params));
    }
    return uri.toString();
  },

  activities(opts) {
    if (!this._activities) {
      this._activities = new chorus.collections.ActivitySet([], _.extend({ entity: this }, opts));
      this.bind('invalidated', this._activities.fetch, this._activities);
      if (this.activeCollections) {
        this.activeCollections().push(this._activities);
      }
    }
    return this._activities;
  },

  save(attrs, options) {
    const opts = options || {};
    const effectiveAttrs = attrs || {};
    this.beforeSave(effectiveAttrs, opts);
    const { success } = opts;
    const { error } = opts;
    opts.success = function onSuccess(model, data, xhr) {
      model.trigger('saved', model, data, xhr);
      if (success) success(model, data, xhr);
    };

    opts.error = function onError(model, xhr) {
      model.handleRequestFailure('saveFailed', xhr, opts);
      if (error) error(model, xhr);
    };

    if (this.performValidation(effectiveAttrs)) {
      this.trigger('validated');
      const attrsToSave = _.isEmpty(effectiveAttrs) ? undefined : effectiveAttrs;
      return Backbone.Model.prototype.save.call(this, attrsToSave, opts);
    }
    this.trigger('validationFailed');
    return false;
  },

  parse(data, ...args) {
    const attrs = this._super('parse', [data, ...args]);
    this._savedAttributes = _.clone(attrs);
    return attrs;
  },

  destroy(options) {
    const opts = options || {};
    opts.wait = true;
    const { error } = opts;
    opts.error = function onError(model, xhr) {
      model.handleRequestFailure('destroyFailed', xhr);
      if (error) error(model, xhr);
    };
    return Backbone.Model.prototype.destroy.call(this, opts);
  },

  declareValidations: $.noop,
  beforeSave: $.noop,

  shouldTriggerImmediately(eventName) {
    if (eventName === 'loaded') {
      return this.loaded;
    }

    return false;
  },

  isValid() {
    return _.isEmpty(this.errors);
  },

  clearErrors() {
    this.errors = {};
  },

  performValidation(newAttrs) {
    this.errors = {};
    this.declareValidations(newAttrs);
    return _(this.errors).isEmpty();
  },

  setValidationError(attr, messageKey, customKey, vars) {
    const variables = vars || {};
    variables.fieldName = this._textForAttr(attr);
    this.errors[attr] = this.errors[attr] || t((customKey || messageKey), variables);
  },

  // Client-side model validation used to verify that `attr` is present in `newAttrs` and is not blank/whitespace.
  require(attr, newAttrs, messageKey) {
    const value = newAttrs && Object.prototype.hasOwnProperty.call(newAttrs, attr) ? newAttrs[attr] : this.get(attr);

    let present = value;

    if (value && _.isString(value) && _.stripTags(value).match(AllWhitespace())) {
      present = false;
    }

    if (!present) {
      this.setValidationError(attr, 'validation.required', messageKey);
    }
  },

  requirePositiveInteger(attr, newAttrs, messageKey) {
    const value = newAttrs && Object.prototype.hasOwnProperty.call(newAttrs, attr) ? newAttrs[attr] : this.get(attr);
    const intValue = parseInt(value, 10);
    if (!intValue || intValue <= 0 || parseFloat(value) !== intValue) {
      this.setValidationError(attr, 'validation.positive_integer', messageKey);
    }
  },

  requirePattern(attr, regex, newAttrs, messageKey, allowBlank) {
    let value = newAttrs && Object.prototype.hasOwnProperty.call(newAttrs, attr) ? newAttrs[attr] : this.get(attr);
    value = value && value.toString();

    if (allowBlank && !value) {
      return;
    }

    if (!value || !value.match(regex)) {
      this.setValidationError(attr, 'validation.required_pattern', messageKey);
    }
  },

  requireValidEmailAddress(name, newAttrs, messageKey) {
    this.requirePattern(name, /[\w.-]+(\+[\w-]*)?@([\w-]+\.)+[\w-]+/, newAttrs, messageKey);
  },

  requireConfirmation(attr, newAttrs, messageKey) {
    const confAttrName = `${attr}Confirmation`;
    let value;
    let conf;

    if (newAttrs && Object.prototype.hasOwnProperty.call(newAttrs, attr)) {
      if (Object.prototype.hasOwnProperty.call(newAttrs, confAttrName)) {
        value = newAttrs[attr];
        conf = newAttrs[confAttrName];
      } else {
        throw new Error('newAttrs supplied an original value but not a confirmation');
      }
    } else {
      value = this.get(attr);
      conf = this.get(confAttrName);
    }

    if (!value || !conf || value !== conf) {
      this.setValidationError(attr, 'validation.confirmation', messageKey);
    }
  },

  requireIntegerRange(attr, min, max, newAttrs, messageKey) {
    const value = newAttrs && Object.prototype.hasOwnProperty.call(newAttrs, attr) ? newAttrs[attr] : this.get(attr);
    const intValue = parseInt(value, 10);
    if (!intValue || intValue < min || intValue > max || parseFloat(value) !== intValue) {
      this.setValidationError(attr, 'validation.integer_range', messageKey, { min, max });
    }
  },

  hasOwnPage() {
    return false;
  },

  highlightedAttribute(attr) {
    const highlightedAttrs = this.get('highlightedAttributes');
    if (highlightedAttrs && highlightedAttrs[attr]) {
      const attribute = highlightedAttrs[attr];
      return _.isArray(attribute) ? attribute[0] : attribute;
    }
    return null;
  },

  name() {
    if (this.nameFunction) {
      return this[this.nameFunction]();
    }
    return this.get(this.nameAttribute || 'name') || '';
  },

  displayName() {
    return this.name();
  },

  shortName(length) {
    const len = length || 20;

    const name = this.name() || '';
    return (name.length < len) ? name : `${name.slice(0, len)}...`;
  },

  // TODO: use the helper in the template, not in the model
  highlightedName() {
    const highlightedModel = withSearchResults(this);
    return new Handlebars.SafeString(highlightedModel.name());
  },

  // When the `paramsToSave` attribute is set on a model, the JSON version of the model only includes the white-listed attributes.
  // When the `paramsToIgnore` attribute is set and `paramsToSave` is not, the JSON version of the model explicitly excludes the rejected attributes.
  toJSON(...args) {
    const { paramsToSave } = this;
    const { paramsToIgnore } = this;
    let result = {};
    let attributes = this._super('toJSON', args);
    if (paramsToSave) {
      const newAttributes = {};
      _.map(attributes, (value, key) => {
        if (_.include(paramsToSave, key)) {
          newAttributes[key] = value;
        }
      });

      const functionParams = _.select(paramsToSave, function applyFunction(paramToSave) {
        return _.isFunction(this[paramToSave]);
      }, this);
      _.each(functionParams, function setAttribute(functionParam) {
        newAttributes[functionParam] = this[functionParam]();
      }, this);
      attributes = newAttributes;
    } else if (paramsToIgnore) {
      _.each(paramsToIgnore, (paramToIgnore) => {
        delete attributes[paramToIgnore];
      });
    }
    attributes = _.inject(attributes, (attrs, value, key) => {
      const assignableAttributes = attrs;
      if (value !== undefined && value !== null) {
        assignableAttributes[key] = value;
      }
      return assignableAttributes;
    }, {});
    attributes = this.underscoreKeys(attributes);
    if (this.nestParams === false) {
      result = attributes;
    } else if (this.parameterWrapper) {
      result[this.parameterWrapper] = attributes;
    } else if (this.constructorName && this.constructorName !== 'Model') {
      result[_.underscored(this.constructorName)] = attributes;
    } else {
      result = attributes;
    }
    return result;
  },

  _textForAttr(attr) {
    return (this.attrToLabel && this.attrToLabel[attr]) ? t(this.attrToLabel[attr]) : attr;
  },

  // return changes on this model since the last save.
  unsavedChanges() {
    this._savedAttributes = this._savedAttributes || {};
    const changes = {};
    const allKeys = _.union(_.keys(this._savedAttributes), _.keys(this.attributes));
    _.each(allKeys, function checkForChange(key) {
      const oldValue = this._savedAttributes[key];
      const newValue = this.attributes[key];
      if (oldValue !== newValue) {
        changes[key] = { oldValue, newValue };
      }
    }, this);
    return changes;
  },

  set(key, val, options) {
    let attrs;
    let opts = options;
    if (key === null) return this;

    // Handle both `"key", value` and `{key: value}` -style arguments.
    if (_.isObject(key)) {
      attrs = key;
      opts = val;
    } else {
      (attrs = {})[key] = val;
    }

    if (attrs instanceof Backbone.Model) attrs = attrs.attributes;

    // Can't use _super because we end up nesting set calls which _super doesn't handle
    const result = Backbone.Model.prototype.set.apply(this, [attrs, opts]);
    if (attrs && attrs.completeJson) {
      this.loaded = true;
      this.statusCode = this.statusCode || 204;
    }
    return result;
  },
});

Model.extend = chorus.classExtend;

export default Model;
