import { Segment, Tab } from 'semantic-ui-react';
import { usePermissions } from '../../../../permissions';
import { actions, instances } from '../../../../permissions/constants';
import NotAuthorised from '../../../../components/errors/NotAuthorised';
import { AppsProvider } from '../../../Context';
import { useAppsData } from '../../../service';
import AppsHeader from './AppsHeader';
import AppsCardsHeader from './AppsCardsHeader';
import AppsCards from './AppsCards';
import NoAppsMessage from './NoAppsMessage';
import LoadingError from '../../../../components/errors/LoadingError';

const Apps = () => {
  const {
    appsData,
    pagination,
    isLoading,
    isError,
    isRefetching,
  } = useAppsData();

  if (!usePermissions(actions.READ, instances.CONTAINERISED_APPS)) {
    return (<NotAuthorised />);
  }

  const state = {
    appsData,
    numberOfApps: pagination?.records,
    isLoading,
    isError,
    isRefetching,
  };

  return (
    <Tab.Pane loading={isLoading} data-testid="apps">
      <AppsProvider apps={state}>
        <Segment.Group>
          <AppsHeader />
          <AppsCardsHeader />
          <Segment attached as="article">
            {isError && <LoadingError />}
            {pagination?.records === 0 && <NoAppsMessage />}
            <AppsCards />
          </Segment>
        </Segment.Group>
      </AppsProvider>
    </Tab.Pane>
  );
};

export default Apps;
