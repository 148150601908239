import _ from '../../underscore';
import SearchItemBase from './search_item_base';

export default SearchItemBase.extend({
  constructorName: 'SearchWorkspaceView',
  templateName: 'search_workspace',

  additionalContext() {
    return _.extend(this._super('additionalContext'), {
      showUrl: this.model.showUrl(),
      iconUrl: this.model.defaultIconUrl(),
    });
  },
});
