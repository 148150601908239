import ActivityListHeader from '../activities/activity_list_header_view';
import { getManagementWorkspace } from '../../models/management/workspace';
import TruncatedText from '../core/truncated_text_view';
import TagBox from '../tags/tag_box_view';
import View from '../loading_view';

export default View.extend({
  constructorName: 'WorkspaceSummaryContentHeaderView',
  templateName: 'workspace_summary_content_header',
  additionalClass: 'taggable_header',
  useLoadingSection: true,

  subviews: {
    '.truncated_summary': 'truncatedSummary',
    '.activity_list_header': 'activityListHeader',
  },

  setup() {
    this.model.activities().fetchIfNotLoaded();
    this.onceLoaded(this.model, this.onLoadModel.bind(this));
    this.listenTo(this.model, 'saved', this.updateHeaderAndActivityList);
    this.subscribePageEvent('workspace:edited', this.updateSummary);
  },

  onLoadModel() {
    const managementWorkspace = getManagementWorkspace(this.model.get('uuid'));
    managementWorkspace.fetch().then(() => {
      if (managementWorkspace.get('metadata')) {
        this.model.set('summary', managementWorkspace.get('metadata').summary, { silent: true });
      }
      this.updateHeaderAndActivityList();
    });
  },

  updateHeaderAndActivityList() {
    this.resourcesLoaded();
    this.render();
  },

  resourcesLoaded() {
    this.truncatedSummary = new TruncatedText({ model: this.model, attribute: 'summary', attributeIsHtmlSafe: true });
    this.activityListHeader = new ActivityListHeader({
      model: this.model,
      allTitle: this.model.get('name'),
      insightsTitle: this.model.get('name'),
      tagBox: new TagBox({
        model: this.model,
        workspaceIdForTagLink: this.model.id,
      }),
    });
  },

  postRender() {
    if (this.model.get('summary')) {
      this.$('.truncated_summary').removeClass('hidden');
    } else {
      this.$('.truncated_summary').addClass('hidden');
    }
  },

  updateSummary() {
    this.truncatedSummary = new TruncatedText({ model: this.model, attribute: 'summary', attributeIsHtmlSafe: true });
    this.render();
  },
});
