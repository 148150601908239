import { t } from 'i18n-js';
import { Label, Icon } from 'semantic-ui-react';

const colorLookup = {
  error: 'red',
  warning: 'yellow',
};

const TableHeaderError = ({ message = t('workspaces.failed_to_load.title'), severity = 'error' }) => (
  <Label color={colorLookup[severity]} data-testid="table-header-error">
    <Icon name="warning sign" />
    {message}
  </Label>
);

export default TableHeaderError;
