import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import AceEditorView from '../core/ace_editor_view';
import WorkfileNewVersion from '../../dialogs/workfiles/workfile_new_version';
import WorkfileConflict from '../../alerts/workfile_conflict_alert';
import { createDraft, canEdit } from '../../utilities/file';
import View from '../loading_view';
import { workspace } from '../../utilities/workspace_assoc';

export default View.extend({
  templateName: 'text_workfile_content',
  saveInterval: 30000,
  bindCallbacks: $.noop,

  subviews: {
    '.editor': 'editor',
  },

  setup() {
    const self = this;
    // Reference to file, so that we can handle ONLY messages that are related to this file instance -- sheru
    this.fileRef = this.options.ref;
    this.editor = new AceEditorView({
      model: this.model,
      tab: this.options.tab,
      mode: this.mode(),
      onSelectionChange: _.bind(this.onSelectionChange, this),
      hotkeys: this.options.hotkeys,
      beforeEdit() {
        setTimeout(_.bind(self.editText, self), 100);
      },
    });

    this.listenTo(this.model, 'change', function displayContent() {
      const content = this.model.content();
      if (content != null) {
        this.editor.setValue(content, -1);
        this.options.tab.set('unsaved', false);
      }
    });


    this.listenTo(this.options.tab, 'change:unsaved', this.startTimer);
    this.listenTo(this.model, 'saveFailed', this.versionConflict);
    this.listenTo(this.model, 'saved', this.saveCompleted);
  },

  onSelectionChange(isEmpty) {
    if (isEmpty) {
      this.model.trigger('file:selectionEmpty');
    } else {
      this.model.trigger('file:selectionPresent');
    }
  },

  mode() {
    switch (this.model.extension()) {
      // The following mode are those expected by ACE and may differ
      // from expected format...
      case 'sql': return 'ace/mode/pgsql';
      case 'rnw': return 'ace/mode/latex';
      case 'r': return 'ace/mode/r';
      case 'rnw': return 'ace/mode/r';
      case 'html': return 'ace/mode/html';
      case 'dtd':
      case 'xsl':
      case 'xml': return 'ace/mode/xml';
      case 'js': return 'ace/mode/javascript';
      case 'css': return 'ace/mode/css';
      case 'h':
      case 'hpp':
      case 'c':
      case 'cpp': return 'ace/mode/c_cpp';
      case 'java': return 'ace/mode/java';
      case 'md': return 'ace/mode/markdown';
      case 'py': return 'ace/mode/python';
      case 'rb': return 'ace/mode/ruby';
      default: return 'ace/mode/text';
    }
  },

  versionConflict() {
    if (this.model.hasConflict()) {
      this.alert = new WorkfileConflict({ model: this.model });
      this.alert.launchModal();
    }
  },

  editText() {
    if (canEdit(this.model)) {
      if (this.cursor) {
        this.editor.moveCursorToPosition(this.cursor);
      }
      this.editor.setOption('readOnly', false);
      if (!chorus.modal) {
        this.editor.focus();
      }
    } else {
      this.editor.setOption('readOnly', true);
    }
  },

  startTimer(model, unsaved) {
    if (unsaved) {
      if (!this.saveTimer) {
        this.saveTimer = setTimeout(_.bind(this.saveDraft, this), this.saveInterval);
      }
    } else if (this.saveTimer) {
      clearTimeout(this.saveTimer);
      delete this.saveTimer;
    }
  },

  saveDraft() {
    this.model.content(this.editor.getValue(), { silent: true });
    const overrides = {};
    if (this.model.get('hasDraft')) {
      overrides.method = 'update';
    }
    createDraft(this.model).save({}, overrides);
    this.options.tab.set('unsaved', false);
  },

  saveCompleted() {
    chorus.toast('rsh.r.editor.save_succeeded');
    _.defer(() => { this.editText(); });
  },

  teardown() {
    if (this.saveTimer) {
      this.saveDraft();
    }
    this._super('teardown');
  },

  saveCursorPosition() {
    this.cursor = this.editor.getCursorPosition();
  },

  replaceCurrentVersion() {
    this.saveCursorPosition();
    this.replaceCurrentVersionWithContent(this.editor.getValue());
  },

  setEditorOption(option, value) {
    this.editor.setOption(option, value);
  },

  createNewVersion() {
    this.saveCursorPosition();
    this.createNewVersionWithContent(this.editor.getValue());
  },

  createNewFile() {
    this.saveCursorPosition();
    this.newWorkfile(this.editor.getValue(), { fileType: this.model.extension() });
  },

  replaceCurrentVersionWithContent(value) {
    this.options.tab.set('unsaved', false);
    // Fix possible ASCII > UTF8 conversion errors before the exception in
    // Rails.
    const safeContent = chorus.replaceWordChars(value);
    this.model.content(safeContent, { silent: true });
    const { model } = this;

    this.model.save({}, {
      updateWorkfileVersion: true,
      silent: true,
      notFound() {
        if (model.attributes.removed) {
          chorus.PageEvents.trigger('workfile:removed_remote', model);
        } else {
          this.alert = new WorkfileConflict({ model });
          this.alert.launchModal();
        }
      },
    });
    // Need to save silently because content details and
    // content share the same models, and we don't want to render content details

    this.editor.setValue(safeContent, -1);
    this.options.tab.set('unsaved', false);
  },

  createNewVersionWithContent(value) {
    this.options.tab.set('unsaved', false);
    // Fix possible ASCII > UTF8 conversion errors before the exception in
    // Rails.
    const safeContent = chorus.replaceWordChars(value);
    this.model.content(safeContent, { silent: true });
    this.dialog = new WorkfileNewVersion({ pageModel: this.model, pageCollection: this.collection });
    this.dialog.launchModal();
    // we need to manually create the dialog instead of using data-dialog because qtip is not part of page
  },

  newWorkfile(content, options) {
    const opts = options;
    if (opts && typeof opts.editInline === 'undefined') {
      opts.editInline = true;
    }
    if (opts && typeof opts.results === 'undefined') {
      opts.resource = null;
    }
    const settings = $.extend(opts, {
      workspace: workspace(this.model),
      workfile: this.model.clone(),
      content: this.editor.getValue(),
    });
    const workfile = new chorus.dialogs.WorkfileSaveAs(settings);
    workfile.launchModal();
  },

});
