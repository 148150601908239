import { t } from 'i18n-js';

export const orderTypes = Object.freeze({
  DATABASE_TABLE_NAME: 'object_name',
  CREATED_AT: 'created_at',
});

export const sanitisedTableTypes = Object.freeze({
  CHORUS_VIEW: t('tables_list.table_type.chorus_view'),
  VIEW: t('tables_list.table_type.database_view'),
  TABLE: t('tables_list.table_type.table'),
});

export const supportedTableTypes = Object.freeze({
  CHORUS_VIEW: 'CHORUS_VIEW',
  VIEW: 'VIEW',
  TABLE: 'TABLE',
});
