/* eslint-disable react/jsx-props-no-spreading */
import { Card, Label, Segment } from 'semantic-ui-react';
import { t } from 'i18n-js';
import NoVirtualMachines from './NoVirtualMachines';
import RefreshButton from '../../../../components/buttons/RefreshButton';
import LoadingError from '../../../../components/errors/LoadingError';
import VirtualMachinesCard from './VirtualMachinesCard';

const VirtualMachinePanelContent = ({
  numberOfVirtualMachines, isFetching, refetch, error, cards,
}) => (
  <Segment attached as="article" className="aridhia-panel-content">
    <Segment attached as="aside" className="aridhia-display-options flex justify-space-between align-baseline">
      <Label basic>
        {t('virtual_machines.count')}
        <Label.Detail data-testid="virtual-machines-total">
          {numberOfVirtualMachines}
        </Label.Detail>
      </Label>
      <RefreshButton compact isFetching={isFetching} refetch={refetch} />
    </Segment>
    <Segment basic className="margin-none">
      {error}
      {numberOfVirtualMachines === 0 && <NoVirtualMachines />}
      {numberOfVirtualMachines !== 0
        && (
          <Card.Group stackable itemsPerRow={3} data-testid="virtual-machines-cards">
            {cards}
          </Card.Group>
        )}
    </Segment>
  </Segment>
);

const createCard = (vm) => (<VirtualMachinesCard key={vm.name} {...vm} />);
const Wrapper = ({
  data, isError, isFetching, refetch,
}) => {
  // Because the backend updates the VM list on demand there can be a case
  // where it returns an empty list of VMs while they're being fetched in the background
  // As we don't yet know if the VM list is truly empty we keep showing the loader
  // and wait for subsequent requests to clarift the status
  const waitForRefresh = data?.vms.length === 0 && data?.refresh_pending;

  return VirtualMachinePanelContent({
    numberOfVirtualMachines: data?.vms.length,
    isFetching: isFetching || waitForRefresh,
    refetch,
    error: isError && <LoadingError />,
    cards: data?.vms.map(createCard),
  });
};

export default Wrapper;
