import $ from '../../jquery';
import View from '../loading_view';

export default View.extend({
  templateName: 'list_content_details_buttons',
  bindCallbacks: $.noop,

  additionalContext() {
    return {
      buttons: this.options.buttons,
    };
  },
});
