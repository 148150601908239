import { createContext, useContext, useMemo } from 'react';
import { useOfficeDiscovery } from './panels/service';
import { calculateFileNameExtension } from './helper';

export const OfficeDiscoveryContext = createContext();

export const useOfficeDiscoveryContext = () => useContext(OfficeDiscoveryContext);

const createActionLookUp = (discoveryDOM) => {
  const lookup = {};

  discoveryDOM?.querySelectorAll("app > action[ext]:not([ext=''])").forEach((actionNode) => {
    const appNode = actionNode.parentElement;
    const extension = actionNode.getAttribute('ext');

    lookup[extension] = {
      appName: appNode.getAttribute('name'),
      favIconUrl: appNode.getAttribute('favIconUrl'),
      url: actionNode.getAttribute('urlsrc'),
    };
  });

  return lookup;
};

export const OfficeDiscoveryProvider = ({ children }) => {
  const {
    isError,
    isFetching,
    refetch,
    discoveryDOM,
  } = useOfficeDiscovery();

  const value = useMemo(
    () => {
      const lookup = createActionLookUp(discoveryDOM);

      return {
        isError,
        isFetching,
        refetch,
        getAction: (fileName) => lookup[calculateFileNameExtension(fileName)],
      };
    },
    [isError, isFetching, refetch, discoveryDOM],
  );

  return (
    <OfficeDiscoveryContext.Provider value={value}>
      {children}
    </OfficeDiscoveryContext.Provider>
  );
};
