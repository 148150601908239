import { useState } from 'react';
import { t } from 'i18n-js';
import { Table, Form } from 'semantic-ui-react';
import { useDatabaseTableCreationContext } from '../Context';
import {
  ColumnNameAttribute,
  ColumnTypeOptionsAttribute,
  ColumnPrimaryKeyAttribute,
  ColumnExcludeAttribute,
  ColumnLabelAttribute,
} from './DatabaseTableCreationColumnsMetadataAttributes';
import { WarningPopup } from './DatabaseTableCreationTableWarnings';
import { validColumnName, hasError, findErrors } from '../../../../utilities/validation';
import EditableDescriptionPopup from './EditableDescriptionPopup';
import { errorOrWarningClassname } from '../helper';

const NameFieldWithWarning = ({ column, state }) => {
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const nameHasError = hasError(state.validation_errors, `name-${column.id}`);
  const nameHasWarning = !!(column.name && !validColumnName(column.name)) && !nameHasError;

  return (
    <Form.Field
      className={errorOrWarningClassname('name', column, state)}
      onMouseEnter={() => setPopupIsOpen(true)}
      onMouseLeave={() => setPopupIsOpen(false)}
      onFocus={() => setPopupIsOpen(true)}
      onBlur={() => setPopupIsOpen(false)}
    >
      {nameHasWarning && (
        <WarningPopup
          isOpen={popupIsOpen}
          header={t('database_tables_new.new_table.validation.title')}
          message={t('database_tables_new.new_table.validation.reason_short')}
        />
      )}
      {nameHasError && (
        <div className="aridhia-form-validation-error">{(findErrors(state.validation_errors, `name-${column.id}`)[0]).message}</div>
      )}
      <label htmlFor={`name-${column.id}`}>{t('database_tables_new.columns.column_name')}</label>
      <ColumnNameAttribute column={column} />
    </Form.Field>
  );
};

const TypeFieldWithWarning = ({ column, state }) => {
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const typeHasError = hasError(state.validation_errors, `type-${column.id}`);
  const typeHasWarning = hasError(state.warnings, `type-${column.id}`) && !typeHasError;

  return (
    <Form.Field
      className={errorOrWarningClassname('type', column, state)}
      onMouseEnter={() => setPopupIsOpen(true)}
      onMouseLeave={() => setPopupIsOpen(false)}
      onFocus={() => setPopupIsOpen(true)}
      onBlur={() => setPopupIsOpen(false)}
    >
      {typeHasWarning && (
        <WarningPopup
          isOpen={popupIsOpen}
          header={t('database_tables_new.new_table.type_mismatch.title')}
          message={(findErrors(state.warnings, `type-${column.id}`)[0]).message}
        />
      )}
      {typeHasError && (
        <div className="aridhia-form-validation-error">{(findErrors(state.validation_errors, `type-${column.id}`)[0]).message}</div>
      )}
      <label htmlFor={`type-${column.id}`}>{t('database_tables_new.columns.column_type')}</label>
      <ColumnTypeOptionsAttribute column={column} />
    </Form.Field>
  );
};

const PlaceholderEmptyHeaderCell = () => (
  <Table.HeaderCell className="empty" />
);

const DatabaseTableCreationColumnsProperties = () => {
  const { newDatabaseTableState } = useDatabaseTableCreationContext();

  return (
    <Table.Row>
      {newDatabaseTableState.table.columns.map((column) => (
        <Table.HeaderCell key={`step3-type-${column.id}`}>
          <NameFieldWithWarning
            column={column}
            state={newDatabaseTableState}
          />
          <TypeFieldWithWarning
            column={column}
            state={newDatabaseTableState}
          />
          <Form.Field>
            <label htmlFor={`label-${column.id}`}>{t('database_tables_new.columns.column_label')}</label>
            <ColumnLabelAttribute column={column} />
          </Form.Field>
          <Form.Field>
            <ColumnPrimaryKeyAttribute column={column} />
          </Form.Field>
          <Form.Field>
            <ColumnExcludeAttribute column={column} />
          </Form.Field>
          <Form.Field>
            <label htmlFor={`description-${column.id}`}>{t('database_tables_new.columns.column_description')}</label>
            <EditableDescriptionPopup column={column} />
          </Form.Field>
        </Table.HeaderCell>
      ))}
      {!newDatabaseTableState.table.data_table_name_from_file && <PlaceholderEmptyHeaderCell />}
    </Table.Row>
  );
};

export default DatabaseTableCreationColumnsProperties;
