import React from 'react';

const ScrollableContainer = ({
  id,
  className = '',
  overflowX = 'auto',
  overflowY = 'auto',
  children,
}) => (
  <div id={id} className={`scrollable-container ${className}`} style={{ position: 'relative' }}>
    <div
      className="scrollable"
      style={{
        overflowX,
        overflowY,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      {children}
    </div>
  </div>
);

export default ScrollableContainer;
