import Backbone from '../../vendor/backbone';
import _ from '../../underscore';

export default Backbone.Model.extend({
  defaults: {
    active: false,
    dirty: true,
    unsaved: false,
  },

  getOrCreateView() {
    let view = this.get('view');
    if (!view) {
      const viewFun = this.get('viewFun');
      if (viewFun) {
        view = viewFun(this);
        this.makeTabAware(view);
        this.set('view', view);
      }
    }
    return view;
  },

  makeTabAware(view) {
    const tavView = view;
    const { render } = tavView;
    const tab = this;

    tavView.render = function buildRender(onlyIfDirty) {
      if (tab.get('dirty') || !onlyIfDirty) {
        if (tab.get('active')) {
          tab.set('dirty', false);
          tab.set('resizeRequired', false);

          return _.bind(render, this)();
        }
        tab.set('dirty', true);
        return this.el;
      }
      return null;
    };
  },

  remove() {
    if (this.collection) {
      this.collection.remove(this);
    }
  },

  urlParams() {
    return this.get('url').split('/');
  },

});
