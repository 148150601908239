import { useState, useEffect } from 'react';
import { Segment } from 'semantic-ui-react';
import { useFiles } from '../../service';
import FilesTable from './FilesTable';
import { FilesProvider } from '../Context';
import { isFileFolder, sanitiseFolderPath } from '../../../helper';
import { selectedItemsChecker } from '../../../../utilities/helper';
import { orderTypes, orderDirections } from '../../../constants';
import { usePermissions } from '../../../../permissions';
import { actions, instances } from '../../../../permissions/constants';
import NotAuthorised from '../../../../components/errors/NotAuthorised';
import { PanelHeader, DefaultPanelHeaderIcon } from '../../../../layout';
import FilesBreadcrumbs from './FilesBreadcrumbs';
import FileHeaderButtons from './FilesHeaderButtons';

const Files = ({ panelWidth, folderPath }) => {
  const [page, setPage] = useState(1);
  const [filterTerm, setFilterTerm] = useState('');
  const [orderBy, setOrderBy] = useState(orderTypes.FILE_NAME);
  const [orderByDirection, setOrderByDirection] = useState(orderDirections.ASC);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const baseFolderPath = sanitiseFolderPath(folderPath);

  const {
    files,
    pagination,
    isError,
    isFetching,
    refetch,
  } = useFiles(baseFolderPath, page, filterTerm, orderBy, orderByDirection);

  useEffect(() => {
    if (page > pagination.total) { setPage(pagination.total); }
  }, [pagination, page]);

  const handleFilterChange = (value) => {
    setFilterTerm(value);
    setPage(1);
  };

  const handleSort = ([column, direction]) => {
    const orderDirection = direction === 'descending' ? orderDirections.DESC : orderDirections.ASC;
    setOrderBy(column);
    setOrderByDirection(orderDirection);
  };

  const handleRefreshButton = () => {
    refetch();
    setPage(1);
    setSelectedFiles([]);
  };

  const handleSelectedFiles = (e, {
    value, checked, name, filetype, folder,
  }) => {
    setSelectedFiles(selectedItemsChecker(selectedFiles, value, checked, name, files, filetype, folder));
  };

  const state = {
    files,
    isError,
    isFetching,
    baseFolderPath,
    pagination,
    setPage,
    orderBy,
    orderByDirection,
    selectedFiles,
    setSelectedFiles,
    handleFilterChange,
    handleRefreshButton,
    handleSort,
    handleSelectedFiles,
    panelWidth,
  };

  if (!usePermissions(actions.READ, instances.STORAGE)) {
    return (<NotAuthorised />);
  }

  return (
    <FilesProvider value={state}>
      <Segment.Group
        className="aridhia-panel-wrapper"
        data-testid="files"
      >
        <PanelHeader
          headerIcon={<DefaultPanelHeaderIcon name={isFileFolder(baseFolderPath) ? 'file alternate' : 'file'} />}
          headerText={<FilesBreadcrumbs />}
          headerButtons={<FileHeaderButtons />}
        />
        <Segment
          attached
          as="article"
          className="aridhia-panel-content"
        >
          <FilesTable />
        </Segment>
      </Segment.Group>
    </FilesProvider>
  );
};

const FilesWrapper = ({ folderPath, panelWidth }) => (
  <Files key={folderPath} panelWidth={panelWidth} folderPath={folderPath} />
);

export default FilesWrapper;
