import _ from '../underscore';

const TagItemManager = function buildManager() {};

TagItemManager.prototype = {
  init() {},

  filter(list) {
    return list;
  },

  itemContains() {
    return true;
  },

  stringToItem(str) {
    return { name: str };
  },

  itemToString(item) {
    return item.name;
  },

  compareItems(item1, item2) {
    return _.strip(item1.name.toLowerCase()) === _.strip(item2.name.toLowerCase());
  },
};

export default TagItemManager;
