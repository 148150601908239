import { useState } from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import _ from 'underscore';
import { useFilesOrTables } from './service';
import AttachmentTable from './AttachmentTable';
import AttachedItems from './AttachedItems';
import BaseFolderSelection from '../navigation/BaseFolderSelection';
import { baseFolders } from '../navigation/constants';
import { returnBaseFolder } from '../../utilities/helper';

const AttachmentSelection = ({
  toAttachItems,
  setToAttachItems,
}) => {
  const [page, setPage] = useState(1);
  const [filterTerm, setFilterTerm] = useState('');
  const [destinationFolder, setDestinationFolder] = useState('files');
  const baseFolder = returnBaseFolder(destinationFolder);

  const handleFilterChange = (value) => {
    setFilterTerm(value);
    setPage(1);
  };

  const handleAddAttachedItems = (item) => {
    if (toAttachItems.filter((obj) => _.isEqual(obj, item)).length === 0) {
      setToAttachItems(toAttachItems.concat(item));
    }
  };

  const handleRemoveAttachedItems = (item) => {
    const newAttached = toAttachItems.filter((obj) => !_.isEqual(obj, item));
    setToAttachItems(newAttached);
  };

  const {
    files,
    pagination,
    isFetching,
    isError,
    refetch,
  } = useFilesOrTables(destinationFolder, page, filterTerm);

  const handleRefreshButton = () => {
    refetch();
    setPage(1);
  };

  return (
    <>
      <BaseFolderSelection
        baseFolders={[baseFolders.FILES, baseFolders.BLOBS, baseFolders.DB_TABLES]}
        folder={baseFolder}
        handleSetFolder={(activeFolder) => setDestinationFolder(activeFolder)}
        handleFilterChange={handleFilterChange}
      />
      <Segment as="article" className="aridhia-table-wrapper-modal margin-top-none margin-bottom-none">
        <AttachmentTable
          files={files}
          pagination={pagination}
          isFetching={isFetching}
          handleRefreshButton={handleRefreshButton}
          isError={isError}
          handleFilterChange={handleFilterChange}
          setPage={setPage}
          handleSetFolder={(activeFolder) => setDestinationFolder(activeFolder)}
          handleAddAttachedItems={handleAddAttachedItems}
          currentFolder={destinationFolder}
        />
      </Segment>
      <Icon
        name="triangle down"
        size="big"
        className="aridhia-pointer"
      />
      <AttachedItems
        attachedItems={toAttachItems}
        handleRemoveAttachedItems={handleRemoveAttachedItems}
      />
    </>
  );
};

export default AttachmentSelection;
