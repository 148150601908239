import chorus from '../chorus';
import _ from '../underscore';
import $ from '../jquery';
import UserModel from './user';
import Dataset from './workspace_dataset';
import Workfile from './workfile';
import schema from './schema';
import miniApp from './mini_apps/app';
import attachmentModel from './attachment';
import dynamicDataset from './dynamic_dataset';
import Model from './models';
import { relativeTimestamp } from '../utilities/helpers';
import t from '../intl';

const Entities = {
  newOwner: UserModel,
  schema,
  actor: UserModel,
  workfile: Workfile,
  newUser: UserModel,
  member: UserModel,
  miniApp,
  sourceDataset: Dataset,
};

const AttachmentEntities = {
  dataset: Dataset,
  workfile: Workfile,
  attachment: attachmentModel,
};

const buildForType = (associate, options) => new Entities[associate](options);

const buildForAttachment = (entityType, attachment) => new AttachmentEntities[entityType](attachment);

const associateEntity = (associate, setupFunction) => function associationMethod() {
  const model = buildForType(associate, this.get(associate));

  if (setupFunction) setupFunction.call(this, model);
  return model;
};

const associateDataset = setupFunction => function associationMethod() {
  const model = dynamicDataset(this.get('dataset'));

  setupFunction.call(this, model);
  return model;
};

export default Model.extend({
  constructorName: 'Activity',
  urlTemplate: 'api/workspaces/activities/{{id}}',

  author() {
    if (!this._author) {
      if (this.has('author')) {
        this._author = new UserModel(this.get('author'));
      } else if (this.has('actor')) {
        this._author = new UserModel(this.get('actor'));
      }
    }

    return this._author;
  },

  newOwner: associateEntity('newOwner'),
  schema: associateEntity('schema'),
  actor: associateEntity('actor'),
  workfile: associateEntity('workfile'),
  newUser: associateEntity('newUser'),
  member: associateEntity('member'),

  miniapp: associateEntity('miniApp', function associate(model) {
    model.set({ workspace: this.get('workspace') }, { silent: true });
  }),

  importSource: associateEntity('sourceDataset', function associate(model) {
    model.set({ workspace: this.get('workspace') }, { silent: true });
  }),

  dataset: associateDataset(function associate(model) {
    model.set({ workspace: this.get('workspace') }, { silent: true });
  }),

  attachments() {
    if (!this._attachments) {
      this._attachments = _.map(this.get('attachments'), (attachment) => {
        const entityType = $.inArray(['workfile', 'dataset'], attachment.entityType) ?
          attachment.entityType :
          'attachment';
        const model = buildForAttachment(entityType, attachment);
        model.set({ workspace_id: this.attributes.workspace.id, activity_id: this.id }, { silent: true });
        return model;
      });
    }
    return this._attachments;
  },

  isNote() {
    return this.get('action') === 'NOTE';
  },

  isAirlock() {
    return this.get('actionType') === 'Airlock';
  },

  isInsight() {
    return this.get('isInsight');
  },

  isSubComment() {
    return this.get('action') === 'SUB_COMMENT';
  },

  hasCommitMessage() {
    return this.get('commitMessage');
  },

  isUserGenerated() {
    return this.isNote() || this.isInsight() || this.isSubComment();
  },

  isPublished() {
    return this.get('isPublished');
  },

  isOwner() {
    return (this.actor().get('uuid') === chorus.session.user().get('uuid'));
  },

  isFailure() {
    const failureActions = [
      'CredentialsInvalid',
      'FileImportFailed',
      'WorkspaceImportFailed',
      'SchemaImportFailed',
      'DatasetExportFailed',
      'WorkspaceExportFailed',
      'WorkfileUploadFailed',
      'FileExtractFailed',
    ];

    return _.contains(failureActions, this.get('action'));
  },

  isSuccessfulImport() {
    const successActions = [
      'FileImportSuccess',
      'WorkspaceImportSuccess',
      'SchemaImportSuccess',
    ];

    return _.contains(successActions, this.get('action'));
  },

  promoterLink() {
    if (this.promoter()) {
      if (this.promoter().get('uuid') === chorus.session.user().get('uuid')) {
        return chorus.session.user().get('fullName');
      }
      return this.promoter().displayName();
    }
    return `${t('actions.removed_user.first_name')} ${t('actions.removed_user.last_name')}`;
  },

  promoter() {
    return this.get('promotedBy') ? new UserModel(this.get('promotedBy')) : null;
  },

  promotionTimestamp() {
    return this.get('promotionTime') ? relativeTimestamp(this.get('promotionTime')) : null;
  },

  reindexError() {
    if (this.isFailure()) {
      this.attributes.errorModelId = this.get('id');
    }
  },
});
