import { t } from 'i18n-js';
import {
  Modal, Icon, Header,
} from 'semantic-ui-react';
import HeaderError from '../../../components/errors/HeaderError';
import { useDelete } from '../../service';
import toastMessage from '../../../utilities/toast';
import { CancelButton, NegativeButton } from '../../../components/buttons/ModalActionButtons';
import { deleteTab } from '../../../layout/tabMonitor';
import { supportedTableTypes } from '../../constants';
import { isDependencyError, sanitiseTableType } from '../../helper';
import { useDatabaseTableContext } from '../../Context';
import DeleteModalMessage from '../../../components/modals/DeleteModalMessage';

const DeleteTableModal = ({ setModalClose, setIsBusy }) => {
  const { tableId, tableType, tableName } = useDatabaseTableContext();
  const tableTypeText = (sanitiseTableType(tableType) || t('tables_list.table_type.table')).toLowerCase();

  const {
    deleteTable,
    isLoading,
    isError,
    error,
    resetResponse,
  } = useDelete(tableId);

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleOnSuccess = () => {
    handleModalClose();
    toastMessage('success', t('modals.delete_table.delete_succeeded', { tableType: sanitiseTableType(tableType) }), 'deleteSuccess');
  };

  const handleDeleteTabs = () => {
    deleteTab(`datasets/${tableId}`);
    deleteTab(`custom_views/${tableId}`);
    deleteTab(`database_views/${tableId}`);
    deleteTab(`datatable/dataset/${tableId}/analyse`);
    deleteTab(`dataset_metadata/${tableId}`);
  };

  const handleSubmitting = () => {
    setIsBusy(true);
    deleteTable({ withDependencies: false }, {
      onSuccess: () => {
        handleOnSuccess();
        handleDeleteTabs();
        setIsBusy(false);
      },
      onError: () => {
        setIsBusy(false);
      },
    });
  };

  const handleSubmittingDep = () => {
    deleteTable({ withDependencies: true }, {
      onSuccess: () => {
        handleOnSuccess();
        handleDeleteTabs();
      },
    });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header" data-testid="delete-table-modal-header">
        <Icon name="trash alternate" title="delete" />
        <Header.Content content={t('modals.delete_table.title', { tableType: tableTypeText })} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <DeleteModalMessage title={t('tables_list.actions.delete_sure', { tableType: tableTypeText, tableName })} />
      </Modal.Content>
      <Modal.Actions>
        <CancelButton loading={isLoading} onClose={handleModalClose} />
        {tableType !== supportedTableTypes.CHORUS_VIEW && isDependencyError(error) && (
          <NegativeButton
            isValidEntry
            loading={isLoading}
            onSubmit={handleSubmittingDep}
            submitText={t('tables_list.actions.delete_with_dependencies', { tableType: tableTypeText })}
          />
        )}
        <NegativeButton
          error={isError}
          isValidEntry
          loading={isLoading}
          onSubmit={handleSubmitting}
          submitText={t('tables_list.actions.delete_table', { tableType: tableTypeText })}
        />
      </Modal.Actions>
    </>
  );
};

export default DeleteTableModal;
