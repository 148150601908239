import React from 'react';
import { BackboneProvider } from 'connect-backbone-to-react';
import $ from '../../jquery';
import View from '../loading_view';
import FetchErrors from '../../mixins/fetch_errors';
import DatasetUploadContainer from '../../../components/dataset/DatasetUploadContainer';
import t from '../../intl';
import DatasetUpload from 'models/dataset_upload';
import ButtonState from '../../models/button_state';
import chorus from '../../chorus';
import Routing from '../../mixins/routing';
import _ from '../../underscore';
import WorkfilesAttachDialog from '../../dialogs/workfiles/workfiles_attach_dialog';

function formatTableName(name) {
  if (!name) return '';
  return name.split('.')[0].toLowerCase().replace(' ', '_');
}

export default View.include(Routing, FetchErrors).extend({
  bindCallbacks: $.noop,
  className: 'app-datasetUploadShow',

  setup(options) {
    this.tab = options.tab;
    this.workspace = options.workspace;
    this.model = options.workfile.clone();

    this.model.setVersion('latest', { includeContent: 'preview' });
    this.handleFetchErrorsFor(this.model);
    this.datasetUpload = new DatasetUpload();

    this.buttonState = new ButtonState();
    this.datasetUpload.on('save', () => {
      this.buttonState.set('saving', false);
    });

    this.onceLoaded(this.model, () => {
      const content = this.checkContent(this.model.content());
      const fileName = this.model.get('fileName');
      const dataTableName = formatTableName(fileName);
      this.datasetUpload.set('data_table_name', dataTableName, { silent: true });
      this.datasetUpload.set('source_file', this.model.fullName(), { silent: true });
      this.datasetUpload.set('workspace_id', this.model.get('workspace').id, { silent: true });
      this.datasetUpload.set('preview_content', content, { silent: true });
      this.tab.set('label', t('dataset.import.title', { fileName }));
      this.postRender();
    });

    this.subscribePageEvent('workfile:removed_remote', this.remoteFileRemoved);
  },

  importTDFFromFile(file) {
    const workfile = file[0].clone();
    this.datasetUpload.set('loaded', false);
    workfile.setVersion('latest', { includeContent: true, contentIsMetadata: true });
    this.handleFetchErrorsFor(workfile);
    this.onceLoaded(workfile, () => {
      if (workfile.content().DatasetDefinition) {
        this.datasetUpload.prepare(workfile.content().DatasetDefinition, workfile.id, workfile.fullName());
      } else {
        this.datasetUpload.set('loaded', true);
        chorus.toast('dataset.import.copy_from_workfile.error', {
          toastOpts: { theme: 'bad_activity' },
        });
      }
      this.postRender();
    });
  },

  checkContent(content) {
    if (!content) {
      return '';
    }
    if (this.datasetUpload.getFileSize(this.model) > 10240) {
      return content.replace(/\r?\n?[^\r\n]*$/, '');
    }
    return content;
  },

  remoteFileRemoved(fileRemoved) {
    // We need to check if this tab matches the file that has been removed from the server
    if (fileRemoved.id === this.model.id) {
      this.tab.set('fileType', 'removed');
      this.model.set('removed', true);
    }
  },

  render() {
    const onSave = (e) => {
      if (e) { e.preventDefault(); }
      this.buttonState.set('saving', true);
      this.datasetUpload.save({}, {
        success: function onSuccess(_model, response) {
          this.navigate(`/workspaces/${this.model.get('workspace').id}/datasets/${response.dataset_id}`);
          this.tab.remove();
        }.bind(this),
        error: function onFail(_model, response) {
          this.buttonState.set('saving', false);
          chorus.toast('dataset.import.failed', {
            reason: response.statusText,
            toastOpts: { theme: 'bad_activity' },
          });
        }.bind(this),
      });
    };

    const onClose = () => {
      this.tab.remove();
    };

    const modelsMap = {
      workfile: this.model,
      datasetUpload: this.datasetUpload,
      buttonState: this.buttonState,
    };

    const importTDFFile = (e) => {
      if (e) { e.preventDefault(); }
      const workfileDialog = new WorkfilesAttachDialog({
        workspace: this.workspace,
        crumbs: true,
        title: t('dataset.import.import_from_file.modal_header'),
        submitButtonTranslationKey: 'actions.confirm',
        multiSelection: false,
      });
      workfileDialog.on('files:selected', _.bind(this.importTDFFromFile, this));
      workfileDialog.launchModal();
    };

    this.root.render(
      <BackboneProvider models={modelsMap}>
        <DatasetUploadContainer onClose={onClose} onSave={onSave} importTDFFile={importTDFFile} />
      </BackboneProvider>,
    );

    return this;
  },

});
