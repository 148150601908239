/* eslint-disable max-lines */
import { t } from 'i18n-js';
import { useQuery } from '@tanstack/react-query';
import {
  workfileContentMetadata,
  workfileMetadataExtraction,
  workfileMetadataExtractionStatus,
  workspaceFilesListUrl,
  newDatabaseTable,
  workspaceTable,
} from '../../../constants/api_urls';
import {
  getter,
  poster,
} from '../../../adapters/service';
import {
  removeAddedPrimaryColumn,
  keyAddedByModal,
  keyAddedByModalIsPrimary,
} from './helper';
import { setTableColumnsFromTypeGuessing, setCreationComplete, setTypeguessinginProgress } from './actions';
import serverErrorMessage from '../../../utilities/server_errors';
import toastMessage from '../../../utilities/toast';

export const useMetadataExtraction = (table, workspaceUuid, dispatch) => {
  const requestBody = {
    resource: {
      workspace: workspaceUuid,
      file: String(table.source_file_id),
    },
    extraction_parameters: {
      extraction_type: 'column types',
      data_table_name: table.data_table_name_from_file,
      delimiter: table.metadata.delimiter === 'other' ? table.metadata.other_delimiter : table.metadata.delimiter,
      includes_header_row: table.metadata.input_has_header,
      text_qualifier: table.metadata.text_qualifier,
      null_qualifier: table.metadata.null_qualifier,
      encoding: table.metadata.encoding,
    },
  };
  const startMetadataGuessing = (body) => poster(workfileMetadataExtraction(), body);
  const {
    data,
    isLoading,
    isError,
  } = useQuery(
    [
      'metadataExtraction',
      table.source_file_id,
      table.metadata.delimiter,
      table.metadata.other_delimiter,
      table.metadata.input_has_header,
      table.metadata.text_qualifier,
      table.metadata.null_qualifier,
      table.metadata.encoding,
    ],
    () => startMetadataGuessing(requestBody),
    {
      refetchOnWindowFocus: false,
      enabled: !!table.source_file_id,
      cacheTime: 2000,
      onSuccess: () => {
        dispatch(setTypeguessinginProgress(true));
      },
      onError: () => {
        dispatch(setTypeguessinginProgress(false));
      },
    },
  );

  return {
    metadataExtraction: data ? data.response : {},
    metadataExtractionIsLoading: isLoading,
    metadataExtractionIsError: isError,
  };
};

export const useMetadataExtractionStatus = (table, dispatch, uuid) => {
  const getStatus = () => getter(workfileMetadataExtractionStatus(uuid));
  const {
    data,
    isLoading,
    isError,
  } = useQuery(
    [
      'metadataExtractionStatus',
      uuid,
      table.metadata.delimiter,
      table.metadata.other_delimiter,
      table.metadata.input_has_header,
      table.metadata.text_qualifier,
      table.metadata.null_qualifier,
      table.metadata.encoding,
    ],
    getStatus,
    {
      refetchOnWindowFocus: false,
      cacheTime: 2000,
      enabled: Boolean(uuid),
      refetchInterval: (responseData) => (!responseData?.response.complete ? 5000 : false),
      onSuccess: (response) => {
        if (response.response.complete) {
          const responseMetadata = response.response.metadata;
          const updatedColumns = table.columns;
          const typeWarnings = [];
          const guessedColumns = [];
          if (responseMetadata.status !== 'success') {
            toastMessage('error', `${responseMetadata?.error_msg}`, 'typeGuessingError');
            dispatch(setTypeguessinginProgress(false));
          } else {
            if (table.added_primary_column) {
              responseMetadata.columns.unshift([{ data_type: 'integer', postgres_sql: 'integer' }]);
            }
            responseMetadata.columns.forEach((element, index) => {
              // creating guessed columns
              const elementTypeArray = [];
              element.forEach((type) => {
                elementTypeArray.push(type.postgres_sql);
              });
              guessedColumns.push({ id: index, value: elementTypeArray });
              // setting warnings when type is completely different
              if (
                !guessedColumns[index].value.includes(updatedColumns[index].type.toLowerCase())
                && !!table.metadata.columns.length
                && !!updatedColumns[index].type
              ) {
                typeWarnings.push(
                  {
                    field: `type-${index}`,
                    message: t('field_error.TYPE_MISMATCH_METADATA', {
                      sourceType: updatedColumns[index].type,
                      guessedtypeFlattened: guessedColumns[index].value.join(),
                    }),
                  },
                );
              }
              // setting warnings when type is supported but a better match is found
              if (
                guessedColumns[index].value.includes(updatedColumns[index].type.toLowerCase())
                && guessedColumns[index].value.indexOf(updatedColumns[index].type.toLowerCase()) > 0
                && !!table.metadata.columns.length
                && !!updatedColumns[index].type
              ) {
                typeWarnings.push(
                  {
                    field: `type-${index}`,
                    message: t('field_error.TYPE_MISMATCH_IMPROVEMENT', {
                      sourceType: updatedColumns[index].type,
                      guessedtypeFlattened: guessedColumns[index].value.join(),
                      guessedtype: guessedColumns[index].value[0],
                    }),
                  },
                );
              }
              // eslint-disable-next-line prefer-destructuring
              updatedColumns[index].type = guessedColumns[index].value[0];
            });
            dispatch(setTableColumnsFromTypeGuessing(updatedColumns, typeWarnings, guessedColumns));
          }
        }
      },
      onError: () => {
        dispatch(setTypeguessinginProgress(false));
      },
    },
  );

  return {
    metadataExtractionStatus: data ? data.response : {},
    metadataExtractionStatusIsLoading: isLoading,
    metadataExtractionStatusIsError: isError,
  };
};

export const useFileContent = (fileId, typeOfContent, includeMetadata, options) => {
  const getFile = () => getter(workfileContentMetadata(fileId, typeOfContent, includeMetadata));
  const {
    isError,
    isFetching,
    error,
    refetch,
  } = useQuery({
    queryKey: ['fileContent', fileId],
    queryFn: getFile,
    ...options,
  });

  return {
    isError,
    isFetching,
    error: error?.message || serverErrorMessage(error),
    refetch,
  };
};

const getFileContent = async (folder, page, namePattern) => {
  const data = await getter(workspaceFilesListUrl(folder, page, namePattern));
  const matchingTDFFileID = data.response[0]?.id;
  if (matchingTDFFileID) {
    return getter(workfileContentMetadata(matchingTDFFileID, true, true));
  }
  return null;
};

export const useMatchingTDFFile = (folder, page, namePattern, options) => {
  const fetchFile = () => getFileContent(folder, page, namePattern);

  const {
    isFetching,
  } = useQuery({
    queryKey: ['matchTDFFile', folder, page, namePattern],
    queryFn: fetchFile,
    ...options,
  });

  return {
    isFetchingTDF: isFetching,
  };
};

export const useCreateNewDatabaseTable = (table) => {
  const requestBody = {
    workspace_id: table.workspace_id,
    source_file: table.source_file,
    data_table_name: table.data_table_name || table.data_table_name_from_file,
    delimiter: table.metadata.delimiter === 'other' ? table.metadata.other_delimiter : table.metadata.delimiter,
    custom_delimiter: table.metadata.other_delimiter,
    input_has_header: table.metadata.input_has_header === 'true',
    text_qualifier: table.metadata.text_qualifier,
    null_qualifier: table.metadata.null_qualifier,
    encoding: table.metadata.encoding,
    columns: removeAddedPrimaryColumn(table.columns),
    preview_content: table.source_file_content,
    parser_error_exists: table.parsed_content.parsing_errors,
    add_key_column: keyAddedByModal(table.columns),
    key_column_is_primary: keyAddedByModalIsPrimary(table.columns),
    ready_to_upload: true,
    loaded: true,
    tdf_source_file: table.metadata.source_metadata_file,
    tdf_source_file_id: table.metadata.source_metadata_file_id,
    title: table.metadata.title,
    url: table.metadata.url,
    description: table.metadata.description,
  };
  const startCreateNewTable = (body) => poster(newDatabaseTable(), body);
  const {
    data,
    isLoading,
    isError,
  } = useQuery(
    [
      'newDatabaseTable',
      table.source_file_id,
    ],
    () => startCreateNewTable(requestBody),
    {
      cacheTime: 5000,
      refetchOnWindowFocus: false,
      enabled: !table.creation_complete,
    },
  );

  return {
    newDatabaseTable: data || {},
    newDatabaseTableIsLoading: isLoading,
    newDatabaseTableIsError: isError,
  };
};

export const useCreateNewDatabaseTableStatus = (table, dispatch, datasetId) => {
  const getStatus = () => getter(workspaceTable(datasetId));
  const {
    data,
    isLoading,
    isError,
  } = useQuery(
    [
      'newDatabaseTableStatus',
      datasetId,
    ],
    getStatus,
    {
      refetchOnWindowFocus: false,
      cacheTime: 5000,
      enabled: Boolean(datasetId) && !table.creation_complete,
      refetchInterval: (responseData) => (
        (responseData?.response.import_progress && responseData?.response.import_progress < 100) ? 5000 : false),
      onSuccess: (response) => {
        if (response.response.import_progress === 100 || !response?.response.import_progress) {
          dispatch(setCreationComplete(datasetId));
        }
      },
    },
  );

  return {
    newDatabaseTableStatus: data ? data.response : {},
    newDatabaseTableStatusIsLoading: isLoading,
    newDatabaseTableStatusIsError: isError,
  };
};
