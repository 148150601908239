import $ from '../jquery';
import Activity from './activity';
import Model from './models';

export default Model.extend({
  constructorName: 'Notification',
  urlTemplate: 'api/workspaces/notifications/{{id}}',

  activity() {
    if (!this._activity) {
      this.attributes.errorModelId = this.attributes.event.id;
      delete (this.attributes.event.id);
      delete (this.attributes.event.timestamp);
      const notificationAttributes = $.extend(this.attributes, this.attributes.event);

      if (this.attributes.comment) {
        notificationAttributes.body = this.attributes.comment.body;
        notificationAttributes.actor = this.attributes.comment.author;
      }

      this._activity = new Activity(notificationAttributes);
    }
    return this._activity;
  },
});
