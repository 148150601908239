import React from 'react';

function TooltipMessage({ text, component }) {
  return (
    <span
      data-tooltip={text}
      data-position="top center"
    >
      { component }
    </span>
  );
}
export default TooltipMessage;
