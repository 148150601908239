import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import RToolsSidebarFileListView from './rtools_sidebar_file_list_view';
import Sidebar from '../core/sidebar_view';
import RtoolsPlotView from '../../dialogs/rtools/plotview_dialog';
import { openFileTab } from '../../utilities/file_redirect';

export default Sidebar.extend({
  constructorName: 'RToolsSidebar',
  templateName: 'rtools/rtools_sidebar',
  bindCallbacks: $.noop,
  events: {
    'click .app-btnClearPlots': 'plotsClear',
    'click .app-historyEntry': 'historyPaste',
    'click .app-btnClearHistory': 'historyClear',
    'click .app-btnClearReports': 'reportsClear',
    'change .app-imageSelect': 'changeImage',
  },

  subviews: {
    '#app-scripts': 'fileListTab',
  },

  setup(options) {
    this.reportSelect = this.reportSelect.bind(this);
    this.workspace = this.model.get('workspace');
    this.console = options.console;
    this.consoleHistory = [];
    this.plotCounter = 0;

    this.listenTo(this.model, 'history:update', this.historyUpdate);
    this.listenTo(this.model, 'report:available', this.reportsUpdate);
    this.listenTo(this.model, 'plot:available', this.plotsUpdate);
    this.listenTo(this.model, 'console:closed', this.reportsClear);
    this.listenTo(this.model, 'console:closed', this.plotsClear);
    this.listenTo(this.options.images, 'serverResponded', this.render);
    this.fileListTab = new RToolsSidebarFileListView({ console: this.console, workspace: this.workspace });
  },

  additionalContext() {
    return { images: this.options.images.models.map(m => m.attributes) };
  },

  postRender() {
    this.$('.app-btnClearHistory').hide();
    this.$('.app-btnClearPlots').hide();
    this.$('.app-btnClearReports').hide();
    this.$('.app-sidebarHistoryPlaceholder').show();
    this.$('.app-workfilesTab').parent().addClass('active');

    // Call postRender on the view we're inheriting from (sidebar_view). This forces the scrollarea to be rendered.
    this._super('postRender');
  },

  historyUpdate(param) {
    if (param.shortEntry !== 'q' && param.shortEntry !== '') {
      this.consoleHistory.push({ shortEntry: param.shortEntry, fullEntry: param.fullEntry });

      const html = `<li id = 'console-history-${(this.consoleHistory.length - 1).toString()}'\
       class='app-historyEntry rsh-history-entry'><span>${param.shortEntry}</span></li>`;

      this.$('.app-history').append(html);
      this.$('.app-sidebarHistoryPlaceholder').hide();
      this.$('.app-btnClearHistory').show();
      chorus.PageEvents.trigger('rsh:resized:sidebar');
    }
  },

  getCurrentImage() {
    return this.options.images.models.find(m => m.get('selected'));
  },

  changeImage(e) {
    const value = e.target.value;
    const current = this.getCurrentImage();
    if (current) {
      if (current.get('id') === value) {
        return;
      }
      current.set('selected', false);
    }

    const future = this.options.images.models.find(m => m.get('id') === value);
    future.set('selected', true);
  },

  historyClear(e) {
    if (e) { e.preventDefault(); }
    this.$('.app-btnClearHistory').hide();
    this.$('.app-history > li').remove();
    this.$('.app-sidebarHistoryPlaceholder').show();
    chorus.PageEvents.trigger('rsh:resized:sidebar');
    this.console.focusConsole();
  },

  historyPaste(e) {
    if (e) { e.preventDefault(); }
    const message = this.consoleHistory[e.currentTarget.id.split('console-history-')[1]].fullEntry;
    this.console.historyPaste(message);
  },

  plotsClear(e) {
    if (e) { e.preventDefault(); }
    this.$('.app-btnClearPlots').hide();
    this.$('.app-plots img').remove();
    this.$('.app-sidebarPlotsPlaceholder').show();
    chorus.PageEvents.trigger('rsh:resized:sidebar');
  },

  // Update the list of plots in the sidebar
  plotsUpdate(param) {
    const { plot } = param;
    this.plotCounter += 1;
    const id = `plot${this.plotCounter}.png`;
    const image = $(`<img data-id='${id}'>`);
    image.attr('src', plot);
    image.prependTo(this.$('.app-plots'));
    image.on('click', (e) => { this.plotSelect(e); });
    image.bind('contextmenu', () => false);// Trigger a recalculation of the sidebar layout
    chorus.PageEvents.trigger('rsh:resized:sidebar');
    this.$('.app-sidebarPlotsPlaceholder').hide();
    this.$('.app-btnClearPlots').show();
    this.plotsShow();
  },

  plotsShow() {
    this.$('#app-consoleSidebarTabs a[href="#app-plot"]').tab('show');
  },

  // A plot in the sidebar is clicked, we fire an event for the plot viewer to pick up
  plotSelect(e) {
    const url = e.target.src;
    const plotname = $(e.target).data('id');
    this.dialog = new RtoolsPlotView({ plotURL: url, plotName: plotname, workspaceId: this.workspace.id }).launchModal();
  },

  reportsUpdate(param) {
    const { report } = param;
    const reportElement = $(`<li data-id='${report}'>${report}</li>`);

    reportElement.prependTo(this.$('.app-reports')).on('click', this.reportSelect);
    this.$('.app-sidebarReportsPlaceholder').hide();
    this.$('.app-btnClearReports').show();
    this.reportsShow();
  },

  reportsShow() {
    this.$('#app-consoleSidebarTabs a[href="#app-reports"]').tab('show');
    chorus.PageEvents.trigger('rsh:resized:sidebar');
  },

  reportsClear(e) {
    if (e) { e.preventDefault(); }
    this.$('.app-btnClearReports').hide();
    this.$('.app-reports > li').remove();
    this.$('.app-sidebarReportsPlaceholder').show();
    chorus.PageEvents.trigger('rsh:resized:sidebar');
  },

  // A report in the sidebar is clicked, we fire an event for the plot viewer to pick up
  reportSelect(e) {
    const reportName = $(e.target).data('id');
    const currentImage = this.getCurrentImage();

    const reportPath = currentImage.get('id').includes('r_legacy') ?
      'files/scripts/.knitr_reports' : 'files/.knitr_reports';
    openFileTab(this.workspace.get('id'), `${reportPath}/${reportName}`);
  },

});
