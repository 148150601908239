import chorus from '../../../chorus';
import $ from '../../../jquery';
import View from '../../loading_view';

export default View.extend({
  templateName: 'workspace/management/administrators_list',
  events: {
    'click a.workspace-admin-admin-remove': 'removeAdmin',
    'click button.workspace-admin-admin-add': 'addAdmin',
    'click a.workspace-admin-owner-toggle': 'toggleOwner',
  },


  additionalContext() {
    return {
      editing: this.options.editing,
      emptyList: this.collection.length === this.collection.where({ deleted: true }).length,
      active: (!this.options.editing || this.parentView.model.get('status') === 'active'),
    };
  },

  removeAdmin(e) {
    e.preventDefault();
    const user = this.collection.findWhere({ uuid: $(e.currentTarget).data('uuid') });

    user.set({ deleted: true });

    // If owner, set first item to be the new owner
    const activeUsers = this.collection.where({ deleted: false });
    if (user.get('owner') === true && activeUsers.length > 0) {
      user.set({ owner: false });
      activeUsers[0].set({ owner: true });
    }

    if (this.collection.length === this.collection.where({ deleted: true }).length) {
      this.collection.reset();
    }

    this.render();
  },

  addAdmin() {
    const adminList = new chorus.dialogs.WorkspacesManagementAdministratorsList();
    adminList.launchModal();
    adminList.on('users:selected', this.selected.bind(this));
  },

  selected(selectedAdmins) {
    selectedAdmins.forEach((admin) => {
      admin.set('deleted', false);
    });

    // If no previous users selected, make first selection the owner
    if (this.collection.length === 0) {
      selectedAdmins[0].set('owner', true);
    }

    this.collection.add(selectedAdmins, { merge: true });
    this.render();
  },

  toggleOwner(e) {
    // Remove from existing
    this.collection.findWhere({ owner: true }).set({ role: 'Administrator', owner: false });

    // Add to new
    this.collection.findWhere({ uuid: $(e.currentTarget).data('uuid') }).set({ owner: true, role: 'Owner' });
  },
});
