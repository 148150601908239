import _ from '../../underscore';
import $ from '../../jquery';
import ItemWrapper from './item_wrapper_view';
import Collection from '../../collections/collections';
import View from '../loading_view';

export default View.extend({
  constructorName: 'PageItemListView',
  additionalClass: 'selectable list',
  tagName: 'ul',
  templateName: 'no_template',
  persistent: false,
  suppressRenderOnChange: true,

  events: {
    'click  li input[type=checkbox]': 'checkboxClicked',
    'click .item_wrapper': 'listItemClicked',
  },

  checkboxClicked(e) {
    e.stopPropagation();
    const clickedBox = $(e.currentTarget);
    const clickedLi = clickedBox.closest('.item_wrapper');
    const index = this.$('.item_wrapper').index(clickedLi);
    const model = this.findModelByliItem(clickedLi.find('div.item_content'), this.collection);
    const willBeChecked = !this.findModel(model, this.selectedModels);

    if (willBeChecked) {
      let modelsToAdd = [model];
      if (e.shiftKey && this.previousIndex >= 0) {
        const min = _.min([this.previousIndex, index]);
        const max = _.max([this.previousIndex, index]);
        const ids = this.allVisibleIdentifiers().slice(min, max + 1);
        modelsToAdd = this.modelsFromIds(ids, this.collection);
      }
      this.addModelsToSelection(modelsToAdd);
      this.previousIndex = index;
      this.sendCheckedEvents();
    } else {
      this.unselectItem(model);
      clickedLi.removeClass('wasSelected');
    }
  },

  setup() {
    this.eventName = this.options.eventName || this.options.entityType;
    this.EntityViewType = this.options.EntityViewType;
    this.listItemOptions = this.options.listItemOptions || {};
    this.multiListMode = this.options.multiListMode;
    this.selectionMode = false;

    if (this.options.entityType) {
      this.selectedModels = this.options.selectedModels || this.collection.clone().reset();
    } else {
      this.selectedModels = this.options.selectedModels || new Collection();
    }

    this.listenTo(this.collection, 'selectAll', this.selectAll);
    this.listenTo(this.collection, 'selectNone', this.selectNone);
    this.listenTo(this.collection, 'clear_selection', this.clearSelection);

    this.selectedIndex = 0;
    this.collection.bind('paginate', function renderPage() {
      this.selectedIndex = 0;
      this.render();
    }, this);

    if (this.eventName) {
      this.subscribePageEvent(`${this.eventName}:search`, function searchComplete() {
        this.selectItem(this.$('>li:not(:hidden)').eq(0));
      });
    }
  },

  appendListItemView(model) {
    const view = this.makeListItemView(model);
    $(this.el).append(view.render().el);
    this.liViews.push(view);
    this.registerSubView(view);
  },

  postRender() {
    _.each(this.liViews, (liViews) => {
      liViews.teardown();
    });
    this.liViews = [];
    this.collection.each(function addItem(model) {
      this.appendListItemView(model);
    }, this);

    this.checkSelectedModels();
    this.selectItem(this.$('>li').eq(this.selectedIndex));
  },

  listItemClicked(e) {
    if (!this.selectionMode) {
      this.selectItem($(e.currentTarget));
    }
  },

  clearSelectedState() {
    this.$('>li.selected').removeClass('selected');
    this.$('>li.wasSelected').removeClass('wasSelected');
  },

  manageWasSelected() {
    let liItems = this.$('>li.checked:not(:hidden)');
    let wasSelected = liItems.filter('.wasSelected');

    if (wasSelected.length === 0) {
      wasSelected = this.$('>li.wasSelected:not(:hidden)');
    }

    if (wasSelected.length === 0) {
      liItems.eq(0).addClass('wasSelected');
    } else {
      liItems = wasSelected;
    }

    return liItems;
  },

  manageSelectionState() {
    // Ensure that the classes against the list items are correctly
    // manipulated to reflect the single and multi selection state
    this.selectionMode = this.selectedModels.length >= 1;
    if (this.selectedModels.length === 1) {
      this.clearSelectedState();
      this.manageWasSelected();
    } else if (this.selectedModels.length > 1) {
      this.manageWasSelected();
      this.$('>li.item_wrapper.selected').removeClass('selected');
    } else {
      let liItems = this.manageWasSelected();
      if (liItems.length === 0) {
        liItems = this.$('>li:not(:hidden)');
      }

      this.clearSelectedState();
      this.selectItem(liItems.eq(0));
    }
  },

  addModelsToSelection(models) {
    this.selectedModels.add(_.filter(models, function findAndAdd(model) {
      return !this.findModel(model, this.selectedModels);
    }, this));
  },

  findModel(model, collection) {
    return collection.findWhere({
      entityType: model.get('entityType'),
      id: model.get('id'),
    });
  },

  findModelByliItem(liItem, collection) {
    return collection.findWhere({
      entityType: liItem.data('type'),
      id: liItem.data('id'),
    });
  },

  getSelectableItems() {
    return this.collection.models;
  },

  liIdentifier(liItem) {
    return `${liItem.data('id')}#${liItem.data('type')}`;
  },

  modelIdentifier(model) {
    return `${model.get('id')}#${model.entityType}`;
  },

  selectedIds() {
    const self = this;
    return $(this.selectedModels.models).map(function selectId() {
      return self.modelIdentifier(this);
    });
  },

  listItemsFromIds(ids) {
    let liItems = [];
    if (ids.length > 0) {
      const self = this;
      liItems = $.grep(
        this.$('>li:not(:hidden)'),
        liItem => $.inArray(
          self.liIdentifier($(liItem).children('div.item_content')),
          ids,
        ) >= 0,
      );
    }

    return $(liItems);
  },

  modelsFromIds(ids, collection) {
    const self = this;
    return $.grep(collection.models, model => $.inArray(self.modelIdentifier(model), ids) >= 0);
  },

  allVisibleIdentifiers() {
    const self = this;
    const liItems = this.$('>li:not(:hidden)>div.item_content');
    return liItems.map(function resolveItemId() {
      return self.liIdentifier($(this));
    });
  },

  allVisibleModels() {
    const ids = this.allVisibleIdentifiers();
    return this.modelsFromIds(ids, this.collection);
  },

  checkItems(liItems, checked) {
    if (liItems) {
      liItems.each(function checkItem() {
        $(this).find('input[type=checkbox]').prop('checked', checked);
        $(this).toggleClass('checked', checked);
      });
    }
  },

  unselectedItems(selected) {
    const allItems = this.$('>li:not(:hidden)');
    const unselected = [];

    $.grep(allItems, (el) => {
      if ($.inArray(el, selected) === -1) unselected.push(el);
    });

    return $(unselected);
  },

  checkSelectedModels() {
    const ids = this.selectedIds();
    const selected = this.listItemsFromIds(ids);
    const unselected = this.unselectedItems(selected);

    this.checkItems(unselected, false);
    this.checkItems(selected, true);
  },

  checkAll(checked) {
    const liItems = this.$('>li:not(:hidden)');
    this.checkItems(liItems, checked);
  },

  selectAll() {
    this.selectedModels.add(this.allVisibleModels());
    this.manageSelectionState();
    this.checkAll(true);
    this.collection.trigger('checked', this.selectedModels);
    this.collection.trigger(`${this.eventName}:checked`, this.selectedModels);
  },

  selectNone(options) {
    const defaults = { reset: false };
    const settings = $.extend({}, defaults, options);

    this.selectedModels.reset();
    if (settings.reset) {
      this.collection.trigger('checked', this.selectedModels);
      this.collection.trigger(`${this.eventName}:checked`, this.selectedModels);
    }
    this.checkAll(false);
    this.manageSelectionState();
    if (!settings.reset) {
      this.collection.trigger('checked', this.selectedModels);
      this.collection.trigger(`${this.eventName}:checked`, this.selectedModels);
    }
  },

  clearSelection(model) {
    this.unselectItem(model);
    delete this.selectedIndex;
    this.$('>li').removeClass('selected');
  },

  selectItem($target) {
    const $lis = this.$('>li');
    const preSelected = $target.hasClass('selected');

    $lis.removeClass('selected');
    $target.addClass('selected');

    this.selectedIndex = $lis.index($target);
    if (this.selectedIndex >= 0) {
      if (!preSelected) {
        const model = this.findModelByliItem($target.children('div.item_content'), this.collection);
        this.itemSelected(model);
      }
    } else {
      this.selectedIndex = 0;
      this.itemDeselected();
    }
  },

  unselectItem(model) {
    if (model) {
      const match = this.findModel(model, this.selectedModels);
      this.selectedModels.remove(match, { silent: true });
    }
    delete this.previousIndex;
    this.sendCheckedEvents();
  },

  selectModel(model) {
    const ids = [this.modelIdentifier(model)];
    const liItems = this.listItemsFromIds(ids);
    if (liItems.length === 1) {
      this.selectItem(liItems);
    }
  },

  itemSelected(model) {
    const eventName = this.eventName || model.eventType();
    if (eventName) {
      this.lastEventName = eventName;
      this.collection.trigger(`${eventName}:selected`, model);
      this.collection.trigger('selected', model);
    }
  },

  itemDeselected() {
    if (this.lastEventName) {
      this.collection.trigger(`${this.lastEventName}:deselected`);
    }
  },

  sendCheckedEvents() {
    this.collection.trigger(`${this.eventName}:checked`, this.selectedModels);
    this.collection.trigger('checked', this.selectedModels);
    this.manageSelectionState();
    this.checkSelectedModels();

    const event = this.getSelectionEvent();
    event && this.collection.trigger(event);
  },

  getSelectionEvent() {
    const element = this.multiListMode ? this.$el.closest('.content') : this.$el;
    return element.find('input[type=checkbox]:not(:checked)').length === 0 ? 'allSelected' : 'unselectAny';
  },

  makeListItemView(model) {
    const itemView = new this.EntityViewType(_.extend({ model, checkable: true }, this.listItemOptions));
    itemView.listenTo(model, 'change:tags', itemView.render);

    const wrapper = new ItemWrapper({ itemView });

    // If the model is removed from the list then we should remove it from display
    wrapper.listenTo(model, 'remove', _.bind(function removeFromList() {
      this.liViews.splice(this.liViews.indexOf(wrapper), 1);
      wrapper.teardown();
    }, this));

    return wrapper;
  },
});
