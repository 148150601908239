import { appPodStatuses } from '../../constants';

const statusColour = (status) => {
  switch (status) {
    case appPodStatuses.LOADING:
    case appPodStatuses.PULLING:
      return 'orange';
    case appPodStatuses.READY:
      return 'green';
    case appPodStatuses.INACTIVE:
    case appPodStatuses.FAILED:
    case appPodStatuses.NO_RESOURCE:
      return 'red';
    default:
      return 'grey';
  }
};

export default statusColour;
