import { t } from 'i18n-js';
import { Button, Modal } from 'semantic-ui-react';

export const CloseButton = ({ onClose }) => (
  <Button
    content={t('actions.close')}
    onClick={onClose}
  />
);

export const CancelButton = ({
  loading, onClose, cancelText, cancelIcon,
}) => (
  <Button
    icon={cancelIcon}
    content={cancelText || t('actions.cancel')}
    disabled={loading}
    onClick={onClose}
  />
);

export const PositiveButton = ({
  onSubmit, loading, isValidEntry, error, submitText,
}) => (
  <Button
    positive
    icon="checkmark"
    labelPosition="right"
    className="margin-right-none"
    onClick={onSubmit}
    loading={loading}
    disabled={loading || !isValidEntry || error}
    content={submitText}
  />
);

export const NegativeButton = ({
  onSubmit, loading, isValidEntry, error, submitText,
}) => (
  <Button
    negative
    icon="times"
    labelPosition="right"
    className="margin-right-none"
    onClick={onSubmit}
    loading={loading}
    disabled={loading || !isValidEntry || error}
    content={submitText}
  />
);

const ModalActionButtons = ({
  loading,
  onClose,
  onSubmit,
  isValidEntry,
  error,
  submitText,
  negativeSentiment = false,
  children,
}) => (
  <Modal.Actions>
    {children}
    <CancelButton loading={loading} onClose={onClose} />
    {negativeSentiment
      ? (
        <NegativeButton
          onSubmit={onSubmit}
          loading={loading}
          isValidEntry={isValidEntry}
          error={error}
          submitText={submitText}
        />
      )
      : (
        <PositiveButton
          onSubmit={onSubmit}
          loading={loading}
          isValidEntry={isValidEntry}
          error={error}
          submitText={submitText}
        />
      )}
  </Modal.Actions>
);

export default ModalActionButtons;
