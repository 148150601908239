import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import SidebarFileListView from './sidebar_file_list_view';
import Sidebar from '../core/sidebar_view';
import LinkMenu from '../core/link_menu_view';

export default Sidebar.extend({
  constructorName: 'RToolsSidebarFileList',
  templateName: 'rtools/rtools_sidebar_file_list',

  events: {
    'click .app-entryActions': 'workfileShare',
    'click .more_items a': 'fetchMoreFiles',
    'click .nav-sub-tab': 'changeFolder',
  },

  subviews: {
    '.app-filterWorkfileMenu': 'filterWorkfileMenu',
    '.file-list': 'fileList',
  },

  setup(options) {
    this.fileType = 'R';
    this.folder = options.workspace.workfileFolders()[0];

    this.filterWorkfileMenu = new LinkMenu({
      options: [
        { data: '', text: t('workfiles.header.menu.filter.all') },
        { data: 'R', text: t('workfiles.header.menu.filter.r') },
        { data: 'RNW', text: t('workfiles.header.menu.filter.rnw') },
        { data: 'SQL', text: t('workfiles.header.menu.filter.sql') },
        { data: 'TEXT', text: t('workfiles.header.menu.filter.text') },
        { data: 'IMAGE', text: t('workfiles.header.menu.filter.image') },
        { data: 'FOLDER', text: t('workfiles.header.menu.filter.folder') },
        { data: 'OTHER', text: t('workfiles.header.menu.filter.other') },
      ],
      choose: _.bind(this.filterWorkfilesAndClearSearch, this),
      isSimple: true,
    });

    this.fileList = new SidebarFileListView({
      console: options.console,
      workspace: options.workspace,
      folder: this.folder,
    });
  },

  additionalContext() {
    return _.extend(this._super('additionalContext'), {
      folders: this.folderOptions(),
      fileType: this.fileType,
    });
  },

  folderOptions() {
    return _.map(this.options.workspace.workfileFolders(), folder => ({ data: folder }));
  },

  filterWorkfilesAndClearSearch(choice) {
    if (this.fileType !== choice) {
      this.fileType = choice;
      this.$('span.app-filterWorkfileMenuChoice').text(choice);
      this.fileList.fetchByType(this.fileType);
    }
  },

  postRender() {
    const self = this;
    const onTextChangeFunction = _.debounce(_.bind((e) => {
      self.fileList.fetchByName($(e.target).val());
    }, this), 300);

    chorus.search(_.extend({
      list: this.$('ul.app-scripts'),
      input: this.$('.app-searchContainer input'),
      onTextChange: onTextChangeFunction,
    }));
    this.$('ul').find(`[data-folder='${this.folder}']`).addClass('active');
  },

  changeFolder(e) {
    this.fileList.changeFolder(e);
  },
});
