import _ from '../underscore';
import t from '../intl';
import SearchIndexPage from './search_index_page';
import { searchWorkspace } from '../utilities/workspace_assoc';

export default SearchIndexPage.extend({
  parseSearchParams(searchParams) {
    const workspaceId = searchParams.shift();
    const result = this._super('parseSearchParams', [searchParams]);
    return _.extend(result, { workspaceId });
  },

  crumbs() {
    return [
      { label: searchWorkspace(this.search).get('name'), url: searchWorkspace(this.search).showUrl() },
      { label: t('breadcrumbs.search_results') },
    ];
  },

  searchInMenuOptions(...args) {
    return this._super('searchInMenuOptions', args).concat([
      {
        data: 'this_workspace',
        text: t('search.in.this_workspace', { workspaceName: searchWorkspace(this.search).get('name') }),
      },
    ]);
  },

  typeOptions(...args) {
    const options = this._super('typeOptions', args);
    if (this.search.isScoped()) {
      const toDisable = ['data_source', 'user', 'workspace'];
      _.each(options, (option) => {
        if (_.include(toDisable, option.data)) {
          const opt = option;
          opt.disabled = true;
        }
      });
    }

    return options;
  },

  makeModel(...args) {
    this._super('makeModel', args);
    this.workspaceId = this.search.get('workspaceId');
  },

  setup(...args) {
    this._super('setup', args);
    this.listenTo(searchWorkspace(this.search), 'loaded', this.resourcesLoaded);
    this.breadcrumbs.requiredResources.add(searchWorkspace(this.search));
    searchWorkspace(this.search).fetch();
  },

  resourcesLoaded(...args) {
    if (searchWorkspace(this.search).loaded && this.search.loaded) {
      this._super('resourcesLoaded', args);
    }
  },
});
