import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import SqlPreview from './sql_preview_dialog';
import NameChorusView from './name_chorus_view_dialog';

export default SqlPreview.extend({
  constructorName: 'VerifyChorusView',

  templateName: 'verify_chorus_view',
  title: t('dataset.verify_chorus_view.title'),
  additionalClass: 'sql_preview dialog_wide',

  persistent: true,

  events: {
    'click button.edit': 'makeEditable',
  },

  setup() {
    this._super('setup'); // eslint-disable-line no-underscore-dangle
    this.events = _.clone(this.events);
    _.extend(this.events, {
      'submit form': 'nameChorusView',
    });
  },

  nameChorusView(e) {
    e.preventDefault();

    this.model.set({ query: this.sql() });

    const assignNameDialog = new NameChorusView({ model: this.model.clone() });
    this.launchSubModal(assignNameDialog);
  },

  sql() {
    return this.editor.getValue();
  },

  makeEditable(e) {
    e && e.preventDefault();

    this.editor.setOption('readOnly', false);
    this.editor.focus();
    $(e.target).addClass('disabled');
  },
});
