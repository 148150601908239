import _ from '../../underscore';
import $ from '../../jquery';
import RCommand from '../../models/r_command';
import Sidebar from '../core/sidebar_view';
import WorkfileSet from '../../collections/workfile_set';
import WorkfileFolder from '../../models/workfile_folder';

export default Sidebar.extend({
  constructorName: 'SidebarFileList',
  templateName: 'rtools/sidebar_file_list',

  events: {
    'click .app-entryActions': 'workfileShare',
    'click .more_items a': 'fetchMoreFiles',
    'click .app-sidebarFolder': 'changeFolder',
  },

  setup(options) {
    this.fileType = 'R';
    this.console = options.console;
    this.workspace = options.workspace;
    this.collection = new WorkfileSet([], {
      workspaceId: options.workspace.id,
      fileType: 'R',
      matching: true,
      succinct: true,
    });
    this.resource = this.collection;

    this.setFolder(this.createFolder(options.folder));
  },

  collectionModelContext(model) {
    return {
      url: model.showUrl(),
      isFolder: model.isFolder(),
      isR: model.isR(),
    };
  },

  fetchMoreFiles(ev) {
    ev.preventDefault();
    const pageToFetch = parseInt(this.collection.pagination.page, 10) + 1;
    this.collection.fetchPage(pageToFetch, { reset: false, remove: false, success: _.bind(this.render, this) });
  },

  additionalContext() {
    const context = {};
    const folderParts = this.folder.name().split('/');

    if (folderParts.length > 1) {
      context.currentFolder = folderParts.pop();
      context.previousFolderPath = folderParts.join('/');

      folderParts.shift();
      context.previousFolder = folderParts.pop();
    }

    if (this.collection.pagination) {
      context.showMoreLink = this.collection.pagination.page < this.collection.pagination.total;
    }
    return context;
  },

  postRender() {
    this.$('.app-spinner').startLoading();
  },

  fetchWorkfiles() {
    this.$('.rsh-sidebar-loading-text').removeClass('hidden');
    this.collection.fetch();
  },

  fetchByType(fileType) {
    this.collection.attributes.fileType = fileType;
    this.fetchWorkfiles();
  },

  fetchByName(name) {
    this.collection.attributes.namePattern = name;
    this.fetchWorkfiles();
  },

  setFolder(choice) {
    this.folder = choice;
    this.collection.attributes.folder = this.folder.name();
    this.fetchWorkfiles();
  },

  workfileShare(e) {
    if (e) { e.preventDefault(); }
    let thisEntry = this.$(e.target);
    if (!$(thisEntry).data('fileid')) {
      thisEntry = thisEntry.parent();
    }
    const command = new RCommand({
      scriptType: $(thisEntry).data('filetype'),
      fileName: $(thisEntry).data('filename'),
      folder: $(thisEntry).data('folder'),
      relativePath: $(thisEntry).data('relativepath'),
      topFolder: $(thisEntry).data('topfolder'),
      action: $(thisEntry).data('action'),
      workspace_id: this.workspace.id,
    });
    this.console.performCommandWhenReady(command);
  },

  changeFolder(e) {
    e.preventDefault();
    this.setFolder(this.createFolder($(e.target).data('folder')));
  },

  createFolder(folder) {
    return new WorkfileFolder({ folder, workspaceId: this.workspace.id });
  },
});
