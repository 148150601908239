import React from 'react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { Grid, Header } from 'semantic-ui-react';
import { t } from 'i18n-js';
import MetadataTitleForm from './MetadataTitleForm';
import MetadataUrlForm from './MetadataUrlForm';
import MetadataDescriptionForm from './MetadataDescriptionForm';

function DatasetMetadataInformation() {
  return (
    <div className="metadata-information">
      <Header as="h1">
        {t('dataset.meta.dataset_information')}
      </Header>
      <Grid divided="vertically" columns="equal">
        <Grid.Row columns={2}>
          <Grid.Column>
            <MetadataTitleForm />
            <MetadataUrlForm />
          </Grid.Column>
          <Grid.Column>
            <MetadataDescriptionForm />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

const mapModelsToProps = ({ datasetMeta }) => ({
  datasetMeta,
});

const options = {
  debounce: false,
};

export default connectBackboneToReact(mapModelsToProps, options)(DatasetMetadataInformation);
