import { t } from 'i18n-js';

const externalLink = (urlName) => {
  const hostname = [window.location.hostname];
  if (!window.customizationVariables || !window.customizationVariables.customizationName) {
    return t(urlName, { referrer: hostname, skin: 'default' });
  }
  return t(urlName, { referrer: hostname, skin: window.customizationVariables.customizationName });
};

export default externalLink;
