import { useQueries } from '@tanstack/react-query';
import { getter, queryClient } from '../../../adapters/service';
import {
  databaseTableColumns, databaseTableMetadata, databaseTableStatistics, workspaceTable,
} from '../../../constants/api_urls';
import { sizeAsString } from '../../../utilities/helper';
import { isTable } from '../../helper';
import serverErrorMessage from '../../../utilities/server_errors';

export const invalidateTable = (tableId) => {
  queryClient.invalidateQueries(['table', tableId]);
  queryClient.invalidateQueries(['tableColumns', tableId]);
  queryClient.invalidateQueries(['tableStatistics', tableId]);
  queryClient.invalidateQueries(['tableMetadata', tableId]);
};

// Obtain table name `object_name`, `object_type`(table or view), `created_at` and `import_status`
const fetchTable = ({ queryKey }) => getter(workspaceTable(queryKey[1]));

// Obtain column information: `name`, `data_type`, `common_values` and `example` (used if common values not set)
const fetchColumns = ({ queryKey }) => getter(databaseTableColumns(queryKey[1]));

// Obtain number of `rows`, `columns` and table size `on_disk_size`
const fetchStatistics = ({ queryKey }) => getter(databaseTableStatistics(queryKey[1]));

// Obtain table data: `Title`, `Url` and `Description` and for each column: `Description` and `Label`
const fetchMetadata = ({ queryKey }) => getter(databaseTableMetadata(queryKey[1]));

const importProgress = (data) => data?.response.import_progress;

const useTableData = (tableId) => {
  const [table, columns, statistics, metadata] = useQueries({
    queries: [
      {
        queryKey: ['table', tableId],
        queryFn: fetchTable,
        refetchInterval: (responseData) => (
          importProgress(responseData) && importProgress(responseData) < 100 ? 5000 : false
        ),
      },
      {
        queryKey: ['tableColumns', tableId],
        queryFn: fetchColumns,
      },
      {
        queryKey: ['tableStatistics', tableId],
        queryFn: fetchStatistics,
      },
      {
        queryKey: ['tableMetadata', tableId],
        queryFn: fetchMetadata,
      },
    ],
  });

  const isLoading = table.isLoading || columns.isLoading || statistics.isLoading || metadata.isLoading;
  const isRefetching = table.isRefetching || columns.isRefetching || statistics.isRefetching || metadata.isRefetching;

  return {
    tableData: table?.data?.response || {},
    tableColumns: columns?.data?.response || [],
    tableStatistics: statistics?.data?.response || {},
    tableMetadata: metadata?.data?.response?.content?.DatasetDefinition || {},
    isLoading,
    isTableError: table.isError,
    isColumnsError: columns.isError,
    columnsError: columns.error?.message || serverErrorMessage(columns.error),
    tableErrorCode: table.error?.status,
    isRefetching,
  };
};

const translateExample = (example) => {
  if (typeof example === 'boolean') {
    const translatedExample = example ? 't' : 'f';
    return translatedExample;
  }
  return example;
};

const getExample = (column) => {
  if (
    (column.example == null || column.statistics?.common_values?.length > 1)
  && column.statistics?.common_values != null
  ) {
    return column.statistics.common_values.toString();
  }
  return translateExample(column.example);
};

const addExample = (column) => ({ ...column, example: getExample(column) });

export const useTable = (tableId) => {
  const {
    tableData,
    tableColumns,
    tableStatistics,
    tableMetadata,
    isLoading,
    isTableError,
    isColumnsError,
    columnsError,
    tableErrorCode,
    isRefetching,
  } = useTableData(tableId);

  // merge tableData, tableMetadata and tableStatistics info
  const table = {
    ...tableData,
    title: tableMetadata.Title,
    url: tableMetadata.Url,
    description: tableMetadata.Description,
    rows: tableStatistics.rows,
    columns: tableStatistics.columns,
    query: tableData.query || tableStatistics.definition,
    size: isTable(tableData.object_type) ? sizeAsString(tableStatistics.on_disk_size) : null,
  };

  const getMetadata = (i) => {
    const description = tableMetadata.Columns?.Column[i]?.Description;
    const label = tableMetadata.Columns?.Column[i]?.Label;
    return { description, label };
  };

  const addMetadata = (column, i) => ({ ...column, ...getMetadata(i) });

  // merge tableColumns with example and column metadata from tableMetadata query
  const columns = tableColumns.map(addExample).map(addMetadata);

  return {
    table,
    columns,
    isLoading,
    isTableError,
    isColumnsError,
    columnsError,
    tableErrorCode,
    isRefetching,
  };
};
