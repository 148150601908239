import DatabaseTable from '../../../../database_tables/panels/database_table/components/DatabaseTable';
import ResponsivePanel from '../../ResponsivePanel';

const DatabaseTablePanel = ({ tab }) => {
  const { config, id } = tab;

  return (
    <ResponsivePanel>
      {(panelWidth) => (
        <DatabaseTable tableId={config.params.table_id} tabId={id} panelWidth={panelWidth} />
      )}
    </ResponsivePanel>
  );
};

export default DatabaseTablePanel;
