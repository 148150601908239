import _ from '../underscore';
import { labelFormat } from '../utilities/vis_helpers';

export const Timeseries = function present(task, options) {
  this.task = task;
  this.options = options;
};

export const Boxplot = function present(task, options) {
  this.task = task;
  this.options = options;
};


export const Histogram = function present(task, options) {
  this.task = task;
  this.options = options;
};


export const Heatmap = function present(task, options) {
  this.task = task;
  this.options = options;
};

_.extend(Timeseries.prototype, {
  present() {
    const rows = _.map(this.task.get('rows'), (row) => {
      const { value } = row;
      let { time } = row;
      if (!Date.parse(time)) {
        time = `2001-01-01 ${time.slice(11)}`;
      }
      return { time, value };
    });

    rows.minY = _.min(_.pluck(rows, 'value'));
    rows.maxY = _.max(_.pluck(rows, 'value'));
    rows.minX = _.first(_.pluck(rows, 'time'));
    rows.maxX = _.last(_.pluck(rows, 'time'));

    return rows;
  },
});

_.extend(Boxplot.prototype, {
  present() {
    const boxes = _.map(this.task.get('rows'), row => ({
      min: row.min,
      median: row.median,
      bucket: row.bucket,
      max: row.max,
      firstQuartile: row.firstQuartile,
      thirdQuartile: row.thirdQuartile,
      percentage: row.percentage,
      label: `${labelFormat(row.bucket, 6)} (${parseInt(row.percentage, 10)}%)`,
      longLabel: `${labelFormat(row.bucket, 1000)} (${parseInt(row.percentage, 10)}%)`,
    }));

    const orderedBoxes = _.sortBy(boxes, box => -1 * parseFloat(box.percentage));

    orderedBoxes.minY = _.min(_.pluck(orderedBoxes, 'min'));
    orderedBoxes.maxY = _.max(_.pluck(orderedBoxes, 'max'));
    if (orderedBoxes.minY === orderedBoxes.maxY) {
      orderedBoxes.minY -= 1;
      orderedBoxes.maxY += 1;
    }

    return orderedBoxes;
  },
});

_.extend(Histogram.prototype, {
  present() {
    return _.map(this.task.get('rows'), row => ({ bin: row.bin, frequency: row.frequency }));
  },
});

_.extend(Heatmap.prototype, {
  present() {
    const rows = _.clone(this.task.get('rows'));

    const xs = _.pluck(rows, 'xLabel');
    const ys = _.pluck(rows, 'yLabel');
    const values = _.pluck(rows, 'value');

    rows.minX = _.min(_.flatten(xs));
    rows.minY = _.min(_.flatten(ys));
    rows.maxX = _.max(_.flatten(xs));
    rows.maxY = _.max(_.flatten(ys));

    rows.minValue = _.min(values);
    rows.maxValue = _.max(values);

    return rows;
  },
});
