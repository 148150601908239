import { useState } from 'react';
import { Form, Modal } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { CancelButton, SaveButton } from '../Buttons';

const StopAppModal = ({ stopApp, children }) => {
  const mountNode = document.querySelector('#modal-wrapper') || document.body;

  const [open, setOpen] = useState(false);

  const handleStop = () => {
    setOpen(false);
    stopApp();
  };

  return (
    <Modal
      id="run-app-modal"
      as={Form}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={children}
      mountNode={mountNode}
    >
      <Modal.Header>{t('app.stop.header')}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {t('app.stop.content')}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <CancelButton onClose={() => setOpen(false)} cancelText={t('app.stop.cancel')} />
        <SaveButton
          id="stop-btn"
          onSave={handleStop}
          saveText={t('app.stop.confirm')}
          iconName="arrow right"
          minimumDataEntryMet
        />
      </Modal.Actions>
    </Modal>
  );
};

export default StopAppModal;
