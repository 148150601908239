import { t } from 'i18n-js';
import { appRunStatus } from '../../models/containerised_app';
import AppLoading from './AppLoading';
import AppPlaceHolder from './AppPlaceHolder';
import AppRunError from './AppRunError';
import AppViewer from './AppViewer';

const AppBody = ({
  appName,
  appUrl,
  appMgmtUrl,
  allowDownload,
  appRunState,
  setAppRunState,
  setPodStatus,
  workspaceToken,
}) => {
  switch (appRunState.code) {
    case appRunStatus.LOADING:
      return (
        <AppLoading
          appUrl={appUrl}
          appMgmtUrl={appMgmtUrl}
          workspaceToken={workspaceToken}
          message={appRunState.message}
          setAppRunState={setAppRunState}
          setPodStatus={setPodStatus}
        />
      );
    case appRunStatus.READY:
      return (
        <AppViewer
          appName={appName}
          appUrl={appUrl}
          allowDownload={allowDownload}
        />
      );
    case appRunStatus.FETCHING:
    case appRunStatus.STOPPING:
      return (
        <AppPlaceHolder message={appRunState.message} />
      );
    case appRunStatus.ERROR:
      return (
        <AppRunError errorMessage={appRunState.message} />
      );
    case appRunStatus.IDLE:
      return (
        <AppRunError errorMessage={t('app.stop.close')} />
      );
    default:
      return (
        <AppRunError errorMessage={t('app.load.error.unknown')} />
      );
  }
};

export default AppBody;
