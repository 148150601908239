import React from 'react';
import { Visibility } from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import UploadTokenList from './UploadTokenList';
import UploadTokenForm from './UploadTokenForm';
import Loading from '../Loading';
import './UploadToken.less';

function UploadTokenContainer({ loaded, ...props }) {
  if (loaded) {
    return (
      <div className="main_content app-content semantic semantic-body" id="uploadtoken">
        <Visibility className="content">
          <UploadTokenList
            toggleView={props.toggleView}
            changePage={props.changePage}
            deleteToken={props.deleteToken}
            tokenCopied={props.tokenCopied}
            publicInboxVisible={props.publicInboxVisible}
          />
          <UploadTokenForm
            createToken={props.createToken}
            publicInboxVisible={props.publicInboxVisible}
            canSetDestination={props.canSetDestination}
          />
        </Visibility>
      </div>
    );
  }
  return (
    <Loading />
  );
}

const mapModelsToProps = ({ uploadTokenList }) => ({
  loaded: uploadTokenList.get('loaded'),
});

const options = {
  debounce: true,
};

export default connectBackboneToReact(mapModelsToProps, options)(UploadTokenContainer);
