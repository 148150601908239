import { t } from 'i18n-js';
import { Icon, Segment, Header } from 'semantic-ui-react';

const FileNavigationEmpty = () => (
  <Segment placeholder>
    <Header as="h4" icon>
      <Icon name="folder outline" />
      {t('file_list.accessibility.no_element_list')}
    </Header>
  </Segment>
);

export default FileNavigationEmpty;
