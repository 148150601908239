import chorus from '../chorus';
import _ from '../underscore';
import Model from './models';

export default Model.extend({
  urlTemplate: 'api/workspaces/taggings',
  constructorName: 'TaggingsUpdater',

  updateTags(params, opts) {
    const tagging = new this.Taggings(_.extend({ taggables: this.getTaggables() }, params));
    this.listenTo(tagging, 'saved', this.taggingsSaved);
    this.listenTo(tagging, 'saveFailed', this.taggingsSaveFailed);

    tagging.onUpdateSuccess = opts.success;
    tagging.onUpdateFail = opts.error;

    this.pushTaggingOntoQueue(tagging);
  },

  Taggings: Model.extend({
    urlTemplate: 'api/workspaces/taggings',

    initialize() {
      const method = this.get('add') ? 'add' : 'remove';
      const tag = this.get('add') || this.get('remove');
      this.set(method, tag.name());
    },
  }),

  pushTaggingOntoQueue(tagging) {
    // ensure that only one save happens at a time
    this.queue = this.queue || [];

    this.queue.push(tagging);
    if (this.queue.length === 1) {
      tagging.save({}, { success: tagging.onUpdateSuccess, error: tagging.onUpdateFail });
    }
  },

  saveNextTaggingFromQueue() {
    this.queue.shift(); // note that the last tagging has finished saving by removing it
    if (this.queue.length > 0) {
      this.queue[0].save(); // start saving the next tagging
    }
  },

  getTaggables() {
    return this.get('collection').map(model => ({
      entityId: model.id,
      entityType: model.get('entityType'),
    }));
  },

  taggingsSaved() {
    this.saveNextTaggingFromQueue();
    this.trigger('updated');
  },

  taggingsSaveFailed(saverWithServerError) {
    const tagName = this.queue[0].get('add') || this.queue[0].get('remove');
    chorus.toast('tag_update_failed', { tagName, toastOpts: { theme: 'bad_activity' } });
    this.queue = [];
    this.trigger('updateFailed', saverWithServerError);
  },
});
