import _ from '../../underscore';
import $ from '../../jquery';
import View from '../loading_view';

export default View.extend({
  templateName: 'item_wrapper',
  tagName: 'li',

  subviews: {
    '.item_content': 'itemView',
  },

  events: {
    'click .app-itemRename': 'rshRenameItem',
    'click .app-itemRenameCancel': 'rshRenameCancel',
  },

  setup() {
    this.itemView = this.options.itemView;
    this.rshToolsActive = false;
  },

  additionalContext() {
    return _.extend({ checkable: true }, this.itemView.additionalContext());
  },

  rshRenameItem(e) {
    e.preventDefault();
    const item = this.$(e.currentTarget).parent().parent().parent()
      .parent()[0];

    $(item).find('.app-btnItemTools').hide();
    $(item).find('.app-itemActions').show();

    const thisItem = $(item).find('.name');
    const renameItem = `<input type='text' class='app-renameInput rsh-inp-rename' value='${thisItem.text()}'/>`;

    this.$(thisItem).hide().after(renameItem);
    this.rshToolsActive = true; // disable further tools until this is false
  },

  rshRenameCancel(e) {
    e.preventDefault();
    const item = this.$(e.currentTarget).parent().parent().parent()
      .parent()[0];
    $(item).find('.app-renameInput').remove();
    $(item).find('.app-itemActions').hide();
    $(item).find('.app-btnItemTools').show();
    const thisItem = $(item).find('.name');
    this.$(thisItem).show();
    this.rshToolsActive = false; // renable tools
  },

  rshShowItemTools(e) {
    if (!this.rshToolsActive) {
      this.$('.app-btnItemTools').hide();
      this.$(e.currentTarget).find('.app-btnItemTools').fadeIn(250);
      e.stopPropagation();
    }
  },

  rshHideItemTools(e) {
    if (!this.rshToolsActive) {
      this.$(e.currentTarget).find('.app-btnItemTools').hide();
      e.stopPropagation();
    }
  },
});
