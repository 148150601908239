import { connectBackboneToReact } from 'connect-backbone-to-react';
import CopyWarningAlertModal from './CopyWarningAlertModal';

function opts() {
  return {
    debounce: true,
    events: {
      // eslint-disable-next-line prefer-rest-params
      copyWarning: Array.prototype.map.call(arguments, (s) => (`change:${s}`)),
    },
  };
}

const mapModelsToProps = ({ copyWarning }) => ({
  open: copyWarning.get('open'),
  handleCloseWarning: () => {
    copyWarning.closeCopyWarningAlert();
  },
});

export default connectBackboneToReact(mapModelsToProps, opts('open'))(CopyWarningAlertModal);
