import { t } from 'i18n-js';
import { Message, Icon } from 'semantic-ui-react';

const NotAuthorised = () => (
  <Message
    negative
    icon
    className="margin-small"
    data-testid="not-authorised-message"
  >
    <Icon name="ban" data-testid="not-authorised-icon" />
    <Message.Content>
      <Message.Header as="h4">
        {t('unauthorised.header')}
      </Message.Header>
      <p role="paragraph">{t('unauthorised.message')}</p>
    </Message.Content>
  </Message>
);

export default NotAuthorised;
