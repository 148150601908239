import Backbone from 'backbone';

const InboundAirlock = Backbone.Model.extend({
  defaults: {
    step: 0,
    readyToUpload: false,
    hasFiles: false,
    agreeToPII: false,
    folder: 'files',
    origFolder: 'files',
    sendToInbox: false,
    canUploadToInbox: false,
    canaryCallDone: false,
    sendToPublicInbox: false,
  },

  initialize() {
    this.upload = this.upload.bind(this);
    this.setReadyToUpload = this.setReadyToUpload.bind(this);
    this.setComplete = this.setComplete.bind(this);
    const uppy = this.get('uppy');
    uppy.on('file-added', this.setReadyToUpload);
    uppy.on('file-removed', this.setReadyToUpload);
    uppy.on('complete', this.setComplete);
  },

  upload() {
    this.set('step', 1);
    this.get('uppy').upload();
  },

  setReadyToUpload() {
    const hasFiles = this.get('uppy').getFiles().length > 0;
    if (hasFiles !== this.get('hasFiles')) {
      this.set('hasFiles', hasFiles);
    }

    const ready = hasFiles && this.get('agreeToPII');
    if (ready !== this.get('readyToUpload')) {
      this.set('readyToUpload', ready);
    }

    if (!ready && this.get('step') > 0) {
      this.set({ step: 0, agreeToPII: false });
    }
  },

  setComplete() {
    if (this.get('step') !== 2) {
      this.set('step', 2);
    }
  },

  updateAgreeToPII(checked) {
    if (checked !== this.get('agreeToPII')) {
      this.set('agreeToPII', checked);
      this.setReadyToUpload();
    }
  },

  updateSendToInbox(checked) {
    if (checked !== this.get('sendToInbox')) {
      if (checked) {
        this.set('folder', 'inbox');
      } else {
        this.set('folder', this.get('origFolder'));
      }
      this.set('sendToInbox', checked);
    }
  },

  updateSendToPublicInbox(checked) {
    if (checked !== this.get('sendToPublicInbox')) {
      if (checked) {
        this.set('folder', 'public inbox');
      } else {
        this.set('folder', this.get('origFolder'));
      }
      this.set('sendToPublicInbox', checked);
    }
  },
});

export default InboundAirlock;
