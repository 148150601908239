import Model from './models';
import chorus from '../chorus';
import t from '../intl';

export const Config = Model.extend({
  constructorName: 'Config',
  urlTemplate: 'api/workspaces/config/',

  fileSizeMbWorkfiles() {
    return this.get('fileSizesMbWorkfiles');
  },

  fileSizeMbCsvImports() {
    return this.get('fileSizesMbCsvImports');
  },

  fileSizeMbCsvExports() {
    return this.get('fileSizesMbCsvExports');
  },

  azureTokenIssuer() {
    return this.get('azureTokenIssuer');
  },

  azureTokenAudience() {
    return this.get('azureTokenAudience');
  },

  dataTableEditorEnabled() {
    return this.get('dataTableEditorEnabled');
  },

  copyWarningEnabled() {
    return this.get('copyWarningEnabled');
  },

  clear(...args) {
    this._super('clear', args);
  },

  start() {
    this.fetch({
      success(config) {
        const issuer = config.azureTokenIssuer();
        if (issuer) {
          sessionStorage.setItem('issuer', issuer);
        }
        sessionStorage.setItem('workspaceListUri', config.get('administrationUrl'));
        sessionStorage.setItem('audience', config.azureTokenAudience());
        sessionStorage.setItem('copyWarningEnabled', config.get('copyWarningEnabled'));
        chorus.initialize();
        document.title = t('application_title');
      },
    });
  },
});

export const instance = new Config();

export default Config;
