import Collection from './workspace_token_collection';

export default Collection.extend({
  urlTemplate: 'api/mini_apps',
  constructorName: 'MiniAppPods',

  parse(response) {
    this.loaded = true;
    delete this.serverErrors;

    return response.apps.map(app => ({
      name: app.mini_app,
      image: app.image_version,
      status: app.status,
      userUuid: app.user_uuid,
    }));
  },
});
