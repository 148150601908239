import { Menu, Icon } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { workspaceHomeUrl } from '../../constants/ui_urls';

const HomeLauncher = () => (
  <Menu.Item href={workspaceHomeUrl()}>
    <Icon data-testid="icon" name="home" title={(t('workspaces.launcher.home'))} />
    <span>{(t('workspaces.launcher.home'))}</span>
  </Menu.Item>
);

export default HomeLauncher;
