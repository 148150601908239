import View from '../loading_view';

export default View.extend({
  constructorName: 'ErrorDetails',
  templateName: 'error_details',

  additionalContext() {
    if (!this.model.get('errorModelId')) {
      return { errorModelId: this.model.get('id') };
    }
    return {};
  },
});
