import $ from '../../jquery';
import ManagementUser from '../../models/management/user';
import UserSet from '.././user_set';

/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true }] */

export default UserSet.extend({
  constructorName: 'ManagementUsers',
  model: ManagementUser,
  urlTemplate: 'api/admin/user',
  searchField: 'basic_fields',

  parse(response) {
    this.loaded = true;
    this.pagination = {
      page: response.paging.page,
      per_page: response.paging.page_size,
      total: Math.ceil(response.paging.total / response.paging.page_size),
      records: response.paging.total,
    };
    delete this.serverErrors;

    return response.data.map(row => ({
      id: row.uuid,
      uuid: row.uuid,
      firstName: row.first_name,
      lastName: row.last_name,
      email: row.email,
      department: row.department,
      enabled: row.enabled,
      status: row.status,
      errored: row.status === 'error',
      active: row.status === 'active',
      role: row.role,
      entityType: 'user',
    }));
  },

  urlParams() {
    const params = { fields: 'all' };

    if (this.attributes.page_size) {
      params.page_size = this.attributes.page_size;
    }

    if (this.attributes.status) {
      params.status = this.attributes.status;
    }

    if (this.attributes.role) {
      params.role = this.attributes.role;
    }

    if (this.attributes.enabled !== undefined) {
      params.enabled = this.attributes.enabled;
    }

    return params;
  },

  comparator(user) {
    return user.displayName();
  },

  search(term) {
    this.attributes.namePattern = term;
    this.fetch({
      data: { basic_fields: `*${term}*` },
      silent: true,
      success: function foo() {
        this.trigger('searched');
      }.bind(this),
    });
  },
});
