import { t } from 'i18n-js';
import { Icon, Message } from 'semantic-ui-react';

const RequestVirtualMachineConfirmationMessage = () => (
  <Message positive icon>
    <Icon size="large" name="thumbs up" data-testid="thumbs_up_icon" />
    <Message.Content>
      <Message.Header>
        {t('virtual_machines.request_modal.header')}
      </Message.Header>
      <p data-testid="confirmation-text">
        {t('virtual_machines.request_modal.confirmation_text_first')}
        <a href={t('virtual_machines.request_modal.service_desk_url')} rel="noopener noreferrer" target="_blank">
          {t('virtual_machines.request_modal.service_desk')}
        </a>
        {t('virtual_machines.request_modal.confirmation_text_second')}
      </p>
    </Message.Content>
  </Message>
);

export default RequestVirtualMachineConfirmationMessage;
