import ChartConfiguration from './chart_configuration_view';

export default ChartConfiguration.extend({
  constructorName: 'FrequencyChartConfiguration',
  templateName: 'chart_configuration',
  additionalClass: 'frequency',

  columnGroups: [
    {
      type: 'all',
      name: 'category',
      options: true,
    },
  ],

  chartOptions() {
    return {
      type: 'frequency',
      name: this.model.get('objectName'),
      yAxis: this.$('.category select option:selected').text(),
      bins: this.$('.limiter .selected_value').text(),
    };
  },
});
