import { React, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { validationFields } from '../../../../constants/validation';
import { hasError } from '../../../../utilities/validation';

const RowsForm = ({
  validationErrors, rowLimit, setRowLimit, includeHeader, handleIncludeHeader,
}) => {
  const [newRowLimit, setNewRowLimit] = useState(1000);

  const handleRowLimitChange = (limit) => {
    setNewRowLimit(limit);
    setRowLimit(limit);
  };

  return (
    <Form.Group inline data-testid="rows-form">
      <label htmlFor="rows">{t('modals.save_as_csv.rows.title')}</label>
      <Form.Radio
        label={t('modals.save_as_csv.rows.all_rows')}
        id="allRows"
        checked={rowLimit === 0}
        onChange={() => setRowLimit(0)}
      />
      <Form.Radio
        label={t('modals.save_as_csv.rows.specify_rows')}
        id="custom"
        checked={rowLimit !== 0}
        onChange={() => setRowLimit(newRowLimit)}
      />
      <Form.Input
        id="custom_rows"
        type="number"
        min="0"
        value={newRowLimit}
        placeholder={1000}
        onChange={(e, { value }) => handleRowLimitChange(value)}
        error={hasError(validationErrors, validationFields.ROW_LIMIT)}
      />
      <Form.Checkbox
        id="include_header"
        label={t('modals.save_as_csv.rows.include_header')}
        checked={includeHeader}
        onChange={handleIncludeHeader}
      />
    </Form.Group>
  );
};

export default RowsForm;
