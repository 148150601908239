// TODO: use fetch rather than axios when backbone is removed
import axios from 'axios';
import { t } from 'i18n-js';
import { appRunStatus, podStatusToAppRunState } from '../models/containerised_app';

const delay = async (callback, ms) => {
  await new Promise((resolve) => setTimeout(resolve, ms));
  return callback();
};

const fetchPodStatus = async (signal, appMgmtUrl, getWorkspaceToken) => {
  const token = await getWorkspaceToken();
  const response = await axios.get(
    appMgmtUrl,
    {
      signal,
      timeout: 10000,
      headers: { Authorization: `JWT ${token}` },
      withCredentials: true,
    },
  );

  return response.data.status;
};

const pollForReadyPod = async (signal, appUrl, appMgmtUrl, getWorkspaceToken, setAppRunState, setPodStatus, maxAttempts) => {
  if (maxAttempts < 1) {
    setAppRunState(appRunStatus.ERROR, t('app.load.error.max_attempts_exceeded'));
    return { isPodReady: false };
  }

  try {
    const podStatus = await fetchPodStatus(signal, appMgmtUrl, getWorkspaceToken);
    setPodStatus(podStatus);
    const { code, message } = await podStatusToAppRunState(podStatus);
    setAppRunState(code, message);

    if (podStatus === 'ready') {
      return { isPodReady: true };
    }

    if (code === appRunStatus.LOADING) {
      return delay(
        () => pollForReadyPod(signal, appUrl, appMgmtUrl, getWorkspaceToken, setAppRunState, setPodStatus, maxAttempts - 1), 1000,
      );
    }
  } catch {
    setAppRunState(appRunStatus.ERROR, t('app.load.error.unknown'));
  }

  return { isPodReady: false };
};

const fetchApp = (signal, appUrl) => axios.get(
  appUrl,
  {
    signal,
    timeout: 5000,
    withCredentials: true,
    // only reject on 5XX status codes, allow 502 as that is the expected response from a loading App
    validateStatus: (status) => (status === 502 || status < 500),
  },
);

const pollForRunningApp = async (signal, appUrl, setAppRunState, maxAttempts) => {
  if (maxAttempts < 1) {
    setAppRunState(appRunStatus.ERROR, t('app.load.error.user.max_attempts_exceeded'));
    return null;
  }

  try {
    const response = await fetchApp(signal, appUrl);
    if (response.status >= 300) {
      return delay(() => pollForRunningApp(signal, appUrl, setAppRunState, maxAttempts - 1), 1000);
    }
    setAppRunState(appRunStatus.READY);
  } catch (e) {
    if (e.code === 'ECONNABORTED') {
      return delay(() => pollForRunningApp(signal, appUrl, setAppRunState, maxAttempts - 1), 1000);
    }

    if (e.response && e.response.status === 500) {
      setAppRunState(appRunStatus.ERROR, t('app.initialize.image.error'));
    } else {
      setAppRunState(appRunStatus.ERROR, t('app.load.error.unknown'));
    }
  }

  return null;
};

const syncAppLoadingProgress = async (signal, appUrl, appMgmtUrl, getWorkspaceToken, setAppRunState, setPodStatus) => {
  try {
    // approx 10 minutes maximum wait for pod creation
    const { isPodReady } = await pollForReadyPod(signal, appUrl, appMgmtUrl, getWorkspaceToken, setAppRunState, setPodStatus, 600);

    if (isPodReady) {
      // approx 5 minutes maximum wait for user's image to be running
      await pollForRunningApp(signal, appUrl, setAppRunState, 300);
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      setAppRunState(appRunStatus.ERROR, t('app.load.error.unknown'));
    }
  }
};

export default syncAppLoadingProgress;
