import { Button } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { useNavbarState } from '../../layout/layoutState';

const NavBarToggle = () => {
  const [isNavHidden, toggleNavHidden] = useNavbarState();

  return (
    <Button
      icon={isNavHidden ? 'chevron down' : 'chevron up'}
      size="mini"
      inverted
      basic
      className="aridhia-nav-toggle"
      onClick={toggleNavHidden}
      title={isNavHidden ? t('accessibility.show') : t('accessibility.hide')}
      data-testid="navbar-toggle"
    />
  );
};

export default NavBarToggle;
