import { t } from 'i18n-js';
import {
  Menu, Icon, Breadcrumb,
} from 'semantic-ui-react';

const WorkspaceBreadcrumbs = ({ portalUrl, workspaceName }) => (
  <Menu.Item
    id="aridhia-workspace-breadcrumbs"
    fitted
    data-testid="aridhia-workspace-breadcrumbs"
  >
    <Breadcrumb size="tiny">
      <Breadcrumb.Section href={portalUrl} title={t('header.my_workspaces')}>
        <Icon data-testid="workspace-breadcrumbs-portal-icon" name="grid layout" />
      </Breadcrumb.Section>
      <Breadcrumb.Divider
        data-testid="workspace-breadcrumbs-divider"
        icon="right angle"
      />
      <Breadcrumb.Section>
        {workspaceName}
      </Breadcrumb.Section>
    </Breadcrumb>
  </Menu.Item>
);

export default WorkspaceBreadcrumbs;
