import { Dropdown } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { workspaceAppsUrl, workspaceShinyAppsUrl } from '../../constants/ui_urls';
import Can from '../../permissions';
import { workspaceId } from '../../layout/helper';

const ShinyAppsLauncher = () => (
  <Dropdown.Item
    text={(t('workspaces.launcher.view_shiny_apps'))}
    href={workspaceShinyAppsUrl()}
    icon="play circle outline"
  />
);

const createNewShinyAppModal = () => {
  const Dialog = window.chorus?.dialogs?.MiniAppCreate;
  if (Dialog) {
    new Dialog({ workspaceId: workspaceId() }).launchModal();
  }
};

const NewShinyAppLauncher = () => (
  <Dropdown.Item
    text={(t('workspaces.launcher.new_shiny_app'))}
    onClick={() => createNewShinyAppModal()}
    icon="plus"
  />
);

const AppsLauncher = () => (
  <Dropdown.Item
    text={(t('workspaces.launcher.view_apps'))}
    href={workspaceAppsUrl()}
    icon="play circle"
  />
);

const AppsLauncherMenu = () => (
  <Dropdown
    aria-label={t('workspaces.launcher.apps')}
    icon={{ name: 'play circle', title: t('workspaces.launcher.apps') }}
    text={t('workspaces.launcher.apps')}
    direction="left"
    item
  >
    <Dropdown.Menu>
      <Can I="Read" a="ContainerisedApp">
        <AppsLauncher />
      </Can>
      <Can I="Read" a="WorkspaceShinyApp">
        <ShinyAppsLauncher />
      </Can>
      <Can I="Write" a="WorkspaceShinyApp">
        <NewShinyAppLauncher />
      </Can>
    </Dropdown.Menu>
  </Dropdown>
);

export default AppsLauncherMenu;
