import { Icon, Label } from 'semantic-ui-react';

const HeaderError = ({ error, onDismiss }) => (
  <Label
    className="aridhia-modal-error margin-left-auto"
    size="small"
    color="red"
    data-testid="header-error"
  >
    <Icon name="warning sign" data-testid="header-error-icon" />
    {error}
    <Icon name="delete" onClick={onDismiss} />
  </Label>
);

export default HeaderError;
