import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import DataGrid from '../core/data_grid_view';
import VerifyChorusView from '../../dialogs/dataset/verify_chorus_view_dialog';
import ChorusView from '../../models/chorus_view';
import View from '../loading_view';

export default View.extend({
  templateName: 'simple_results_console',
  constructorName: 'SimpleResultsConsole',

  events: {
    'click .app-saveAsView': 'createChorusView',
    'click .app-saveAsWorkfile': 'createNewWorkfile',
  },

  subviews: {
    '.result_table': 'dataGrid',
  },

  setup() {
    this.dataset = this.options.dataset;
    this.dataGrid = new DataGrid({ model: this.dataset, sortable: false });
  },

  createNewWorkfile(e) {
    if (e) { e.preventDefault(); }
    const model = this.dataset.get('workfile');
    const workfile = new chorus.dialogs.WorkfileNew({
      workspaceId: model.get('workspace').id,
      workfileType: 'sql',
      editInline: true,
      toast: true,
      content: this.dataset.get('sql'),
    });
    workfile.launchModal();
  },

  createChorusView(e) {
    if (e) { e.preventDefault(); }
    const model = this.dataset.get('workfile');
    const executionSchema = model.executionSchema();

    this.chorusView = new ChorusView({
      sourceObjectId: model.id,
      sourceObjectType: 'workfile',
      workspace: model.get('workspace'),
      query: this.dataset.get('sql'),
    });
    this.chorusView.sourceObject = model;
    this.chorusView.setSchema(executionSchema);

    const dialog = new VerifyChorusView({
      model: this.chorusView,
      flags: {
        editSQL: false, previewData: false, previewBar: false, toast: true,
      },
    });
    dialog.launchModal();
  },
});
