import chorus from '../../chorus';
import t from '../../intl';
import View from '../loading_view';

/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true }] */

export default View.extend({
  templateName: 'shuttle_header',

  additionalContext() {
    return {
      objectName: this.options.objectName,
    };
  },

  postRender() {
    this.setupSearch();
  },

  setupSearch() {
    chorus.search({
      input: this.$('input.search:text'),
      placeholder: t('workspace.search'),
      onTextChange: this.options.onTextChange,
    });
  },
});
