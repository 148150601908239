import { Dropdown } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { FilesProvider } from '../../../files/panels/files/Context';
import { supportedFileTypes } from '../../../files/constants';
import { NewFileModal } from '../../../files/components/modals/NewFileModal';
import ModalWrapper from '../../modals/ModalWrapper';

const NewFileOption = (
  <Dropdown.Item
    text={(t('workspaces.launcher.new_sql_query'))}
    icon="search plus"
  />
);

const NewSQLFileLauncher = () => (
  <FilesProvider value={{ baseFolderPath: 'files', fixedFileType: supportedFileTypes.SQL }}>
    <ModalWrapper trigger={NewFileOption}>
      <NewFileModal />
    </ModalWrapper>
  </FilesProvider>
);

export default NewSQLFileLauncher;
