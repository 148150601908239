import React from 'react';
import { t } from 'i18n-js';

export default () => (
  <div className="main_content app-content center">
    <div className="rsh-loading spinner">
      <i className="fa fa-ellipsis-h fa-spin" />
      {t('loading')}
    </div>
  </div>
);
