import React from 'react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import {
  Container, Header, Table, Input, Checkbox, Radio, Button,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import { ChorusIdentifierLower64 } from '../../utilities/validation_regexes';
import DatatypeDropdown from './DatatypeDropdown';
import TooltipMessage from './TooltipMessage';

const headerCell = (
  previewData, nullQualifier, handleColumnDisabledChange, handleColumnNameChange, handleColumnTypeChange,
  handlePrimaryKeyChange,
) => (column, i) => {
  const error = !column.name || !column.name.match(ChorusIdentifierLower64()) || column.error;
  const showWarning = error && !column.disabled;
  let { unique } = column;
  if (previewData) {
    for (let j = 0; j < previewData.rows.length; j++) {
      if (previewData.rows[j][column.id] === nullQualifier) {
        unique = false;
      }
    }
  }

  const columnName = (
    <>
      <label htmlFor={`name-${i}`} className="offscreen">{t('dataset.import.column_name')}</label>
      <Input
        id={`name-${i}`}
        name={`name-${i}`}
        value={column.name}
        onChange={handleColumnNameChange}
        disabled={column.disabled}
        error={showWarning}
      />
    </>
  );

  const columnType = (
    <>
      <label htmlFor={`datatype-${i}`} className="offscreen">{t('dataset.import.data_type')}</label>
      <DatatypeDropdown
        name={`datatype-${i}`}
        datatype={column.type}
        handleColumnTypeChange={handleColumnTypeChange}
        disabled={column.disabled}
        error={column.typeError}
      />
    </>
  );

  return (
    <Table.HeaderCell key={`header-${i}`} verticalAlign="top">
      <div className="headerRow">
        <Checkbox
          id={`disabled-${i}`}
          name={`disabled-${i}`}
          label={t('dataset.import.visible')}
          checked={!column.disabled}
          onChange={handleColumnDisabledChange}
          disabled={column.primary}
        />
        <div className="headerColumnName">
          <TooltipMessage component={columnName} target="columnName" error={showWarning} column={column} />
        </div>
      </div>
      <div>
        <TooltipMessage component={columnType} target="columnType" error={column.typeError} />
      </div>
      <div className={!unique ? 'hidden' : ''}>
        <Radio
          label={t('dataset.import.primary_key')}
          name="primary"
          id={`primary-${i}`}
          value={i}
          checked={column.primary}
          onChange={handlePrimaryKeyChange}
          disabled={column.disabled}
          role="radio"
          aria-checked={column.primary ? 'true' : 'false'}
        />
      </div>
    </Table.HeaderCell>
  );
};

const fillerRows = (number, length) => {
  const rows = [];
  for (let i = 0; i < number; i++) {
    const cols = [];
    for (let j = 0; j <= length; j++) {
      cols.push(<Table.Cell key={`empty-${i}-${j}`}>&nbsp;</Table.Cell>);
    }
    rows.push(<Table.Row key={`empty-${i}`}>{cols}</Table.Row>);
  }
  return rows;
};

function DatasetUploadTable({
  handleColumnDisabledChange, handleColumnNameChange, handleColumnTypeChange, handlePrimaryKeyChange,
  handleKeyColumnIsPrimaryChange, handleAddKeyColumnChange, previewData, nullQualifier, addKeyColumn,
  keyColumnIsPrimary, columns,
}) {
  // display requires empty columns and rows for missing end data
  const columnLength = columns.length;
  let fillerRowNumber = 10;
  if (previewData) {
    fillerRowNumber -= previewData.rows.length;
    previewData.rows.map((row, i) => {
      for (let j = row.length; j < columnLength; j++) {
        previewData.rows[i].push(nullQualifier);
      }
      previewData.rows[i] = previewData.rows[i].slice(0, columnLength);
    });
  }

  return (
    <Container className="table-container">
      <Header as="h2" id="datasetInformation" className="offscreen">
        {t('dataset.import.new_dataset_columns')}
      </Header>
      <div role="group" aria-labelledby="datasetInformation">
        <Table compact striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell key="header-0">
                <div className="headerRow">
                  <Checkbox
                    id="disabled-id"
                    name="disabled-id"
                    label={t('dataset.import.visible')}
                    onChange={handleAddKeyColumnChange}
                    checked={addKeyColumn}
                    disabled={keyColumnIsPrimary}
                  />
                  <div className="headerColumnName">
                    <label htmlFor="column-id" className="offscreen">{t('dataset.import.column_name')}</label>
                    <Input
                      id="column-id"
                      name="column-id"
                      value="id"
                      disabled
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="datatype-id" className="offscreen">{t('dataset.import.data_type')}</label>
                  <DatatypeDropdown
                    name="datatype-id"
                    datatype="integer"
                    disabled
                  />
                </div>
                <div>
                  <Radio
                    label={t('dataset.import.primary_key')}
                    name="primary"
                    id="primary"
                    onChange={handleKeyColumnIsPrimaryChange}
                    checked={keyColumnIsPrimary}
                    role="radio"
                    aria-checked={keyColumnIsPrimary ? 'true' : 'false'}
                  />
                </div>
              </Table.HeaderCell>
              {
                columns.map(headerCell(
                  previewData, nullQualifier, handleColumnDisabledChange, handleColumnNameChange,
                  handleColumnTypeChange, handlePrimaryKeyChange,
                ))
              }
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { previewData
              && (
                previewData.rows.map((row, i) => (
                  <Table.Row key={`row-${i}`}>
                    <Table.Cell key={`row-${i}-cell-0`} disabled={!addKeyColumn}>
                      {(i + 1)}
                    </Table.Cell>
                    {
                    row.map((cell, j) => (
                      <Table.Cell key={`row-${i}-cell-${j}`} disabled={columns[j].disabled}>
                        {nullQualifier === cell ? '' : cell}
                      </Table.Cell>
                    ))
                  }
                  </Table.Row>
                ))
              )}
            {
              fillerRows(fillerRowNumber, columnLength)
            }
          </Table.Body>
        </Table>
      </div>
    </Container>
  );
}

const mapModelsToProps = ({ workfile, datasetUpload }) => {
  const nullQualifier = datasetUpload.get('null_qualifier');
  const addKeyColumn = datasetUpload.get('add_key_column');
  const keyColumnIsPrimary = datasetUpload.get('key_column_is_primary');
  try {
    return {
      previewData: datasetUpload.getPreviewData(workfile),
      nullQualifier,
      addKeyColumn,
      keyColumnIsPrimary,
      columns: datasetUpload.get('columns'),
      handleColumnDisabledChange: (_e, { name, checked }) => {
        datasetUpload.updateColumnDisabled(name, !checked);
      },
      handleColumnNameChange: (_e, { name, value }) => {
        datasetUpload.updateColumnName(name, value);
      },
      handleColumnTypeChange: (_e, { name, value }) => {
        datasetUpload.updateColumnType(name, value);
      },
      handlePrimaryKeyChange: (_e, { value }) => {
        datasetUpload.updatePrimary(value);
      },
      handleKeyColumnIsPrimaryChange: () => {
        datasetUpload.updateKeyColumnIsPrimary();
      },
      handleAddKeyColumnChange: (_e, { checked }) => {
        datasetUpload.updateAddKeyColumn(checked);
      },
    };
  } catch (err) {
    return {
      previewData: { rows: [], columns: [] },
      nullQualifier,
      addKeyColumn,
      keyColumnIsPrimary,
      columns: [],
    };
  }
};

const options = {
  debounce: false,
  events: {
    datasetUpload: ['change:previewData', 'change:add_key_column', 'change:key_column_is_primary', 'change:columns'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(DatasetUploadTable);
