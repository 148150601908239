import $ from '../../jquery';
import Schema from '../../models/schema';
import View from '../loading_view';
import { linkTo } from '../../utilities/url_helpers';

export default View.extend({
  constructorName: 'DatabaseSidebarListView',
  events: {
    'click .context a': 'contextClicked',
  },

  setup() {
    this.setSchema(this.options.schema);
    this.subscribePageEvent('workfile:changed', this.workfileChanged);
  },

  additionalContext() {
    if (!this.schema) {
      return {
        schemaAssociated: false,
      };
    }
    return {
      schemaAssociated: true,
      schemaLink: linkTo('#', this.schema.get('name')),
      schemas: [{
        id: this.schema.get('id'),
        name: this.schema.get('name'),
        isCurrent: true,
      }],
    };
  },

  setSchemaToCurrentWorkspace: $.noop,
  fetchResourceAfterSchemaSelected: $.noop,
  searchTextChanged: $.noop,

  teardown() {
    this.$('li').qtip('destroy');
    this._super('teardown');
  },

  schemaSelected(e) {
    const schemaId = $(e.target).data('id');
    if (schemaId === 'workspaceSchema') {
      this.setSchemaToCurrentWorkspace();
      this.fetchResourceAfterSchemaSelected();
    } else {
      this.schema = this.schemas.get(schemaId);
      this.fetchResourceAfterSchemaSelected();
    }
    this.render();
  },

  closeQtip() {
    $('.workfile_show_sidebar li:hover a').trigger('mouseleave');
    $('.workfile_show_sidebar .context a').trigger('unfocus');
  },

  contextClicked(e) {
    e.preventDefault();
  },

  workfileChanged(workfile) {
    const executionSchema = workfile.executionSchema();
    if (!this.schema || (executionSchema.id && executionSchema.id !== this.schema.id)) {
      this.setSchema(new Schema(executionSchema));
    }
  },

  setSchema(schema) {
    this.schema = schema;
  },
});
