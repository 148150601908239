import React, { useContext, createContext } from 'react';

const FilesContext = createContext();

export const useFilesContext = () => useContext(FilesContext);

export const FilesProvider = ({ children, value }) => (
  <FilesContext.Provider value={value}>
    {children}
  </FilesContext.Provider>
);

const FileContext = createContext();

export const useFileContext = () => useContext(FileContext);

export const FileProvider = ({ children, file }) => (
  <FileContext.Provider value={file}>
    {children}
  </FileContext.Provider>
);
