import { t } from 'i18n-js';
import DatabaseTablesTableRow from './DatabaseTablesTableRow';
import { sizeAsString } from '../../../../utilities/helper';
import { formatDateTime } from '../../../../helpers/dates';
import { useDatabaseTablesContext } from '../Context';
import { DatabaseTableProvider } from '../../../Context';
import { isImporting, canAnalyze, isTable } from '../../../helper';
import NoDatabaseTables from './NoDatabaseTables';
import {
  ColumnsHeader,
  EmptyMessage,
  ErrorComponent,
  Filter,
  MultiSelectIcon,
  Paging,
  SelectAll,
  Stats,
  TableHeader,
  TableWrapper,
} from '../../../../query_table';
import RefreshButton from '../../../../components/buttons/RefreshButton';
import DatabaseTablesMultiSelectActions from './DatabaseTablesMultiSelectActions';

const state = (table) => ({
  tableId: table.id,
  tableName: table.object_name,
  tableDescription: table.description,
  tableType: table.object_type,
  tableDate: table.created_at ? formatDateTime(table.created_at) : null,
  tableSize: isTable(table.object_type) ? sizeAsString(table.size) : null,
  isImporting: isImporting(table.import_status, table.import_progress),
  analyseEnabled: canAnalyze(table.import_status, table.import_progress, table.object_type),
});

const renderBodyRow = (table) => (
  <DatabaseTableProvider table={state(table)} key={table.id}>
    <DatabaseTablesTableRow key={table.id} />
  </DatabaseTableProvider>
);

const DatabaseTablesSelectAll = () => {
  const {
    tables,
    isFetching,
    isError,
    selectedTables,
    handleSelectedTables,
  } = useDatabaseTablesContext();

  return (
    <SelectAll
      tableData={tables}
      selectedTableData={selectedTables}
      handleSelectedTableData={handleSelectedTables}
      isFetching={isFetching}
      isError={isError}
    />
  );
};

const columns = () => ([
  { name: 'multi_select', content: <DatabaseTablesSelectAll />, collapsing: true },
  {
    name: 'object_name',
    content: t('tables_list.table_header.object_name'),
    unsortable: false,
  },
  { name: 'type', content: t('tables_list.table_header.type') },
  { name: 'size', content: t('tables_list.table_header.size') },
  { name: 'description', content: t('tables_list.table_header.description') },
  {
    name: 'created_at',
    content: t('tables_list.table_header.created'),
    unsortable: false,
  },
  { name: 'actions', collapsing: true },
]);

const DatabaseTablesTable = () => {
  const {
    tables,
    selectedTables,
    isFetching,
    isError,
    setPage,
    pagination,
    handleRefreshButton,
    handleFilterChange,
    handleSort,
  } = useDatabaseTablesContext();

  return (
    <TableWrapper
      tableData={tables}
      columns={columns()}
      tableHeader={(
        <TableHeader
          columns={columns()}
          multiSelectIcon={<MultiSelectIcon selectedItems={selectedTables} />}
          multiSelectActions={<DatabaseTablesMultiSelectActions />}
          errorComponent={<ErrorComponent isError={isError} />}
          filter={<Filter onFilter={handleFilterChange} />}
          refresh={<RefreshButton isFetching={isFetching} refetch={handleRefreshButton} />}
        />
      )}
      columnsHeader={<ColumnsHeader columns={columns()} onSortBy={handleSort} />}
      renderBodyRow={renderBodyRow}
      emptyWrapper={(
        <EmptyMessage
          isFetching={isFetching}
          tableData={tables}
          columns={columns()}
          emptyContent={<NoDatabaseTables />}
        />
      )}
      stats={<Stats shown={tables.length} total={pagination.records} />}
      pagination={<Paging total={pagination.records} onPageChange={setPage} activePage={pagination.page} />}
    />
  );
};

export default DatabaseTablesTable;
