import { useMutation } from '@tanstack/react-query';
import { t } from 'i18n-js';
import { workspaceSnapshot } from '../constants/api_urls';
import { poster } from '../adapters/service';
import toastMessage from '../utilities/toast';

const postSnapshot = (id) => (body) => {
  const formattedBody = {
    workspace_export: {
      ...body,
    },
  };
  return poster(workspaceSnapshot(id), formattedBody);
};

export const createSnapshotMutation = (mutationFn) => ({ onSuccess, ...options }) => {
  const opts = {
    ...options,
    onSuccess: () => {
      if (onSuccess) {
        const message = t('modals.snapshot.toast_message');
        toastMessage('success', message, 'snapshotSuccess');
        onSuccess();
      }
    },
  };

  return (
    useMutation(mutationFn, opts)
  );
};

export const createUseSnapshotCreation = (id) => createSnapshotMutation(postSnapshot(id));
