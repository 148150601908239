import { t } from 'i18n-js';
import { Form } from 'semantic-ui-react';
import FormValidationError from '../../../../../components/errors/FormValidationError';
import { findErrors, hasError } from '../../../../../utilities/validation';
import { supportedVirtualMachineTypes } from '../../../../constants';
import { validationLengths, validationFields } from '../../../../../constants/validation';
import { useCustomisationContext } from '../../../../../customisation/Context';
import KnowledgeBaseButton from '../../../../../components/buttons/KnowledgeBaseButton';

const dropdownOptions = (
  Object.values(supportedVirtualMachineTypes).map((type) => ({ key: type, text: type, value: type }))
);

const RequestVirtualMachineModalForm = ({
  virtualMachine,
  validationErrors,
  handleChange,
}) => {
  const { vmCustomKBLink } = useCustomisationContext() || {};
  return (
    <Form data-testid="request-virtual-machine-modal-form">
      <Form.Field>
        <label htmlFor="display_name">
          {t('virtual_machines.request_modal.name.label')}
          {hasError(validationErrors, validationFields.NAME) && (
            <FormValidationError errorMsg={(findErrors(validationErrors, validationFields.NAME)[0]).message} />
          )}
        </label>
        <Form.Input
          id="display_name"
          autoFocus
          placeholder={t('virtual_machines.request_modal.name.placeholder')}
          maxLength={validationLengths.NAME}
          value={virtualMachine.name}
          onChange={(e, { value }) => handleChange(value, 'name')}
          error={hasError(validationErrors, validationFields.NAME)}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor="type">
          {t('virtual_machines.request_modal.type.label')}
          {hasError(validationErrors, 'type') && (
            <FormValidationError errorMsg={(findErrors(validationErrors, 'type')[0]).message} />
          )}
          {vmCustomKBLink && (
            <KnowledgeBaseButton
              targetUrl={t('virtual_machines.request_modal.type.kb_url')}
              title={t('virtual_machines.request_modal.type.kb_title')}
              size="mini"
              className="margin-left-small"
            />
          )}
        </label>
        <Form.Select
          id="type"
          fluid
          placeholder={t('virtual_machines.request_modal.type.placeholder')}
          options={dropdownOptions}
          value={virtualMachine.type}
          onChange={(e, { value }) => handleChange(value, 'type')}
          error={hasError(validationErrors, 'type')}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor="info">
          {t('virtual_machines.request_modal.info.label')}
          {hasError(validationErrors, 'info') && (
            <FormValidationError errorMsg={(findErrors(validationErrors, 'info')[0]).message} />
          )}
        </label>
        <Form.TextArea
          id="info"
          placeholder={t('virtual_machines.request_modal.info.placeholder')}
          maxLength={validationLengths.TEXT}
          value={virtualMachine.info}
          onChange={(e, { value }) => handleChange(value, 'info')}
          error={hasError(validationErrors, 'info')}
          required={!!vmCustomKBLink}
        />
      </Form.Field>
    </Form>
  );
};

export default RequestVirtualMachineModalForm;
