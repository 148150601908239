import React from 'react';
import { Visibility, Form, Header } from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';
import DatasetMetadataInformation from './DatasetMetadataInformation';
import DatasetMetadataTable from './DatasetMetadataTable';
import Buttons from '../Buttons';
import Loading from '../Loading';
import MetadataButtons from './MetadataButtons';
import './Dataset.less';

function DatasetMetadataContainer({
  loaded, datasetMeta, columnSet, toggleModal, ...props
}) {
  if (loaded) {
    return (
      <div className="main_content app-content semantic semantic-body" id="datasetingress">
        <Visibility className="datasetmeta content">
          <Form className="content-scroll-spacer">
            <Header as="h1">
              {t('dataset.meta.edit.page_title')}
            </Header>
            <MetadataButtons toggleModal={toggleModal} hasMetadata={datasetMeta.loaded} {...props} />
            <DatasetMetadataInformation />
            <DatasetMetadataTable />
            <Buttons {...props} />
          </Form>
        </Visibility>
      </div>
    );
  }
  return (
    <Loading />
  );
}

const mapModelsToProps = ({
  columnSet, datasetMeta, buttonState,
}) => ({
  columnSet,
  datasetMeta,
  loaded: columnSet ? columnSet.loaded : false,
  saving: buttonState.get('saving'),
  minimumDataEntryMet: datasetMeta && datasetMeta.get('readyToUpload'),
});

const options = {
  debounce: false,
  events: {
    buttonState: ['change:saving'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(DatasetMetadataContainer);
