import SchemaFunctionSet from '../collections/schema_function_set';
import SchemaDatasetSet from '../collections/schema_dataset_set';
import Model from './models';
import { DataSourceCredentialsModel } from '../mixins/data_source_credentials';

export default Model.include(DataSourceCredentialsModel).extend({
  constructorName: 'Schema',
  showUrlTemplate: 'schemas/{{id}}',
  urlTemplate: 'api/workspaces/schemas/{{id}}',

  functions() {
    this._schemaFunctions = this._schemaFunctions || new SchemaFunctionSet([], {
      id: this.get('id'),
      schemaName: this.get('name'),
    });
    return this._schemaFunctions;
  },

  datasets() {
    if (!this._datasets) {
      this._datasets = new SchemaDatasetSet([], { schemaId: this.id });
    }
    return this._datasets;
  },

  tables() {
    if (!this._tables) {
      this._tables = new SchemaDatasetSet([], { schemaId: this.id, tablesOnly: 'true' });
    }
    return this._tables;
  },

  canonicalName() {
    return this.name();
  },

  isEqualToSchema(other) {
    return this.get('id') === other.get('id');
  },
}, {
  DEFAULT_NAME: 'public',
});
