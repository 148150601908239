import { useState } from 'react';
import Backbone from 'backbone';
import { datatableTabConfiguration } from '../../../../../application/models/workspace_router';
import DataTableEditor from '../../../../../application/views/workspaces/data_table_editor_view';
import ReactToBackboneWrapper from '../../ReactToBackboneWrapper';
import { renameTab } from '../../../tabMonitor';

const DatabaseTableEditorPanel = ({ tab, workspace }) => {
  const { component, config, id } = tab;
  const tabConfiguration = datatableTabConfiguration(workspace, config.args[4], config.args[5]);
  if (tabConfiguration.itemModel && tabConfiguration.itemModel.get('entityType') === 'workfile') {
    tabConfiguration.itemModel.loaded = false;
  }
  const [view] = useState(() => new DataTableEditor({
    tab: new Backbone.Model(),
    workspace,
    itemModel: tabConfiguration.itemModel,
    resourceName: tabConfiguration.resourceName,
    type: config.args[4],
    analyse: config.args[6] === 'analyse',
    disableSandbox: true,
  }));

  const callback = config.args[4] === 'file' ? () => renameTab(id, tabConfiguration.itemModel.getFileName()) : () => renameTab(id, tabConfiguration.itemModel.get('objectName'));

  return (
    <ReactToBackboneWrapper
      view={view}
      tabId={id}
      component={component}
      onceLoadedCallback={callback}
    />
  );
};

export default DatabaseTableEditorPanel;
