import {
  Card, Dropdown, Icon, Popup,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import './SingleContainerisedCard.less';

const SingleCardHeader = ({
  name,
  navigateToEditDetails,
  navigateToUpdateCode,
  handleDelete,
  setShowInfo,
  showInfo,
  showMenu,
  handleRun,
}) => {
  const infoToggleToolTip = showInfo ? t('app.card.nav_bar.display_picture') : t('app.card.nav_bar.info');

  return (
    <Card.Content className="aridhia-card-header">
      <Card.Header as="a" data-testid="app-header" onClick={handleRun}>
        {name}
      </Card.Header>
      <Popup
        position="bottom right"
        basic
        popper={{ className: 'ui semantic' }}
        hideOnScroll
        content={infoToggleToolTip}
        trigger={(
          <Icon
            link
            onClick={() => setShowInfo(!showInfo)}
            role="button"
            aria-label={infoToggleToolTip}
            name={showInfo ? 'picture' : 'info circle'}
            style={{ marginBottom: '1px', height: 'auto', color: 'black' }}
          />
        )}
      />

      {showMenu && (
        <Popup
          position="bottom right"
          basic
          popper={{ className: 'ui semantic' }}
          content={t('app.card.header.menu')}
          hideOnScroll
          trigger={(
            <Dropdown
              aria-label={t('app.card.header.menu')}
              icon="vertical ellipsis"
              direction="left"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="edit"
                  text={t('app.card.header.edit')}
                  onClick={navigateToEditDetails}
                  id={`edit-${name}`}
                />
                <Dropdown.Item
                  icon="upload"
                  text={t('app.card.header.generate_token')}
                  onClick={navigateToUpdateCode}
                  id={`update-${name}`}
                />
                <Dropdown.Item
                  icon="trash alternate"
                  text={t('app.card.header.delete')}
                  onClick={handleDelete}
                  id={`delete-${name}`}
                />
              </Dropdown.Menu>
            </Dropdown>
          )}
        />
      )}
    </Card.Content>
  );
};

export default SingleCardHeader;
