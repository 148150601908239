import * as d3 from 'd3';
import _ from '../../underscore';
import $ from '../../jquery';
import View from '../loading_view';
import Axes from './axes';
import { Heatmap } from '../../presenters/visualization_data_presenter';

export default View.extend({
  render() {
    const $el = $(this.el);
    $el.html('');
    $el.addClass('visualization');

    const data = new Heatmap(this.model).present();
    const xLabels = _.uniq(_.pluck(data, 'xLabel'));
    const yLabels = _.uniq(_.pluck(data, 'yLabel'));

    const svg = d3.select(this.el).append('svg')
      .attr('class', 'chart heatmap')
      .attr('width', 925)
      .attr('height', 340);

    this.axes = new Axes({
      el: svg,
      xScaleType: 'numeric',
      yScaleType: 'numeric',
      minXValue: _.min(_.flatten(xLabels)),
      minYValue: _.min(_.flatten(yLabels)),
      maxXValue: _.max(_.flatten(xLabels)),
      maxYValue: _.max(_.flatten(yLabels)),
      xAxisLabel: this.model.get('xAxis'),
      yAxisLabel: this.model.get('yAxis'),
      ticks: true,
    });

    if (!$el.isOnDom()) return;

    this.axes.render();
    const scales = this.axes.scales();
    if (data.minValue === data.maxValue) {
      data.minValue -= 1;
      data.maxValue += 1;
    }

    const fillScale = d3.scaleLinear().domain([data.minValue, data.maxValue]).range(['white', 'steelblue']);

    const content = svg.append('svg:g').attr('class', 'content');

    content.selectAll('.bin').data(data).enter()
      .append('svg:rect')
      .attr('class', 'bin')
      .attr('x', row => scales.x(row.xLabel[0]) + 1)
      .attr('y', row => scales.y(row.yLabel[1]) - 1)
      .attr('height', row => Math.abs(scales.y(row.yLabel[1]) - scales.y(row.yLabel[0])))
      .attr('width', row => Math.abs(scales.x(row.xLabel[1]) - scales.x(row.xLabel[0])))
      .style('fill', row => fillScale(row.value));
  },
});
