import _ from '../../underscore';
import TruncatedText from '../core/truncated_text_view';
import { lastComment } from '../../utilities/collaboration';
import View from '../loading_view';
import TagsContext from '../../mixins/tags_context';
import { sizeAsString } from '../../../utilities/helpers';
import { parseDateFromApi } from '../../utilities/helpers';
import secureImage from '../../utilities/secure_image';
import t from '../../intl';

export default View.include(TagsContext).extend({
  constructorName: 'WorkfileItemView',
  templateName: 'workfile_item',
  tagName: 'div',
  secureLoad: null,

  subviews: {
    '.comment .body': 'commentBody',
  },

  setup(...args) {
    this._super('setup', args);
    this.listenTo(this.model, 'invalidated', function reFetch() { this.model.fetch(); });
  },

  setupSubviews() {
    this.commentBody = new TruncatedText({
      model: lastComment(this.model),
      attribute: 'body',
      attributeIsHtmlSafe: true,
    });
  },

  postRender() {
    this.$el.attr('data-id', this.model.id);
    this.$el.attr('data-type', this.model.entityType);
    if (this.secureLoad) {
      secureImage(this.$('img'), this.secureLoad);
    }
  },

  additionalContext() {
    let iconUrl = this.model.iconUrl({ size: 'large' });
    if (iconUrl.startsWith('api')) {
      this.secureLoad = iconUrl;
      iconUrl = '/images/workfiles/large/img.png';
    }

    const ctx = {
      iconUrl,
      name: this.model.getFileName(),
      model: this.model,
    };

    if (this.model.isInbox()) {
      const metadata = this.model.get('metadata');
      if (metadata) {
        ctx.has_metadata = true;
        ctx.fileInfo = t('workfiles.inbox.uploaded', {
          source: metadata.source ? metadata.source : t('workfiles.inbox.source.unknown'),
        });
        if (!this.model.isFolder()) {
          ctx.fileInfo += ` ${sizeAsString(metadata.contentLength)}`;
        }
      }
    }

    ctx.url = this.model.listUrl();
    if (this.model.get('destroying')) { this.additionalClass = 'disabled'; }

    _.extend(ctx, this.additionalContextForTags());

    const comment = lastComment(this.model);
    if (comment) {
      const date = parseDateFromApi(comment.get('commentCreatedStamp'));

      ctx.lastComment = {
        body: comment.get('body'),
        creator: comment.author(),
        on: date && date.toString('MMM d'),
      };

      ctx.otherCommentCount = parseInt(this.model.get('commentCount'), 10) - 1;
    }
    return ctx;
  },
});
