import Backbone from 'backbone';
import Cookies from 'js-cookie';

const Announcement = Backbone.Model.extend({
  url: () => {
    return 'api/admin/serviceannouncements';
  },

  defaults: {
    announcements: [],
  },

  parse(resp) {
    const response = resp.response || resp;

    let announcements = response.data ? response.data.serviceBanners : [];
    const dismissedTime = Cookies.get('announcementDismissedTime');
    if (dismissedTime) {
      announcements = announcements.filter(a => new Date(a.publishedAt).getTime() >= dismissedTime);
    }

    return { announcements };
  },
});

export default Announcement;
