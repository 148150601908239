import { useState } from 'react';
import { t } from 'i18n-js';
import {
  Button, Modal, Icon, Header,
} from 'semantic-ui-react';
import AttachmentSelection from './AttachmentSelection';
import './Attachments.less';

export const AttachmentModalButton = (
  <Button
    content={t('file_list.actions.link_items')}
    aria-label={t('file_list.actions.link_items')}
    className="margin-left-none"
    primary
    floated="left"
    icon="linkify"
    labelPosition="left"
  />
);

const AttachmentModal = ({
  attachedItems,
  setAttachedItems,
  setModalClose,
  setIsBusy,
}) => {
  const [toAttachItems, setToAttachItems] = useState([]);

  const handleClose = () => {
    setToAttachItems([]);
    setModalClose();
  };

  const handleAddAttachedItems = () => {
    setIsBusy(true);
    const newAttachedItems = [...attachedItems, ...toAttachItems];
    const newFilteredAttachedItems = [...new Map(newAttachedItems.map((obj) => [JSON.stringify(obj), obj])).values()];
    setAttachedItems(newFilteredAttachedItems);
    setIsBusy(false);
    handleClose();
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="linkify" title={t('file_list.actions.add_note_link')} />
        <Header.Content content={t('file_list.actions.add_note_link')} />
      </Header>
      <Modal.Content scrolling>
        <AttachmentSelection
          toAttachItems={toAttachItems}
          setToAttachItems={setToAttachItems}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={t('actions.cancel')}
          onClick={() => handleClose()}
        />
        <Button
          positive
          labelPosition="right"
          className="margin-right-none"
          onClick={() => handleAddAttachedItems()}
          icon="linkify"
          content={t('file_list.actions.link')}
        />
      </Modal.Actions>
    </>
  );
};

export default AttachmentModal;
