import chorus from '../chorus';
import _ from '../underscore';
import $ from '../jquery';
import t from '../intl';
import Confirm from './confirm';
import Base from './base';
import { toAirlock } from '../presenters/activity_presenters';
import { idToken } from '../auth/oauth';

export default Confirm.extend({
  constructorName: 'DownloadAirlockConfirmAlert',
  focusSelector: 'button.cancel',
  additionalClass: 'error',
  persistent: true,

  events: _.extend({}, Base.prototype.events, {
    'click button.submit': 'downloadAirlock',
  }),

  downloadAirlock(e) {
    e.preventDefault();
    this.$('button.submit').startLoading('airlock.download.alert.downloading');
    idToken().then((token) => {
      $.ajax(
        this.href,
        {
          headers: { Authorization: `Bearer ${token}` },
          success: (data) => {
            this.closeModal();
            window.open(data.url);
          },
          error: () => {
            this.closeModal();
            chorus.toast('airlock.download_failed', { toastOpts: { theme: 'bad_activity' } });
          },
        },
      );
    });
  },

  makeModel(...args) {
    this._super('makeModel', args);
    const { activity, href } = this.options;
    this.href = href;
    this.model = toAirlock(activity);
    this.title = t('airlock.download.alert.title');
    this.htmltext = `${t('airlock.download.alert.text')}<br><br>
      &quot;${this.model.get('reason')}&quot;<br><br>
      ${t('airlock.download.alert.htmltext')}
      <a href="${t('url.knowledgebase_terms')}" target="_blank">${t('airlock.download.alert.terms')}</a>.
      ${t('airlock.download.alert.audit')}`;
    this.ok = t('airlock.download.alert.ok');
  },
});
