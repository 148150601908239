import Model from './models';

export default Model.extend({
  constructorName: 'FrameEvent',

  initialize() {
    const callback = this.onReceivedMessage.bind(this);
    if ('addEventListener' in window) {
      window.addEventListener('message', callback, false);
    } else {
      window.attachEvent('onmessage', callback);
    }
  },

  onReceivedMessage(event) {
    this.trigger('frame:event', event);
  },
});
