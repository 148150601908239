import { useState } from 'react';
import Backbone from 'backbone';
import MiniAppShowView from '../../../../../application/views/mini_apps/app/mini_app';
import ReactToBackboneWrapper from '../../ReactToBackboneWrapper';
import NotAuthorised from '../../../../components/errors/NotAuthorised';
import { renameTab } from '../../../tabMonitor';

const ShinyAppPanel = ({ tab, workspace }) => {
  const { component, id, config } = tab;
  const [view] = useState(
    () => new MiniAppShowView({ workspace, tab: new Backbone.Model({ active: true }), selectedApp: config.args[4] }),
  );

  if (!workspace.hasPermission('WorkspaceShinyApp.Read')) {
    return <NotAuthorised />;
  }

  const callback = () => renameTab(id, view.model.getCurrentName());

  return (
    <ReactToBackboneWrapper
      view={view}
      tabId={id}
      component={component}
      onceLoadedCallback={callback}
    />
  );
};

export default ShinyAppPanel;
