import React from 'react';
import { Confirm } from 'semantic-ui-react';
import { t } from 'i18n-js';

export default ({ open, onCancel, onConfirm }) => {
  const mountNode = document.querySelector('#modal-wrapper') || document.body;

  return (
    <Confirm
      mountNode={mountNode}
      header={t('dataset.meta.confirm.header')}
      content={t('dataset.meta.confirm.content')}
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      className="confirm"
    />
  );
};
