import _ from '../../underscore';
import $ from '../../jquery';
import ActivityList from '../activities/activity_list_view';
import DataTab from '../dataset/data_tab_view';
import FunctionTab from '../dataset/function_tab_view';
import TabControl from '../core/tab_control_view';
import Sidebar from '../core/sidebar_view';
import ReadonlyWorkfile from '../../dialogs/workfiles/readonly_workfile_dialog';
import NotesNew from '../../dialogs/activities/notes_new_dialog';
import InboundAirlockReject from '../../alerts/inbound_airlock_reject_alert';
import InboundAirlockAccept from '../../dialogs/inbound_airlock/inbound_airlock_accept_dialog';
import ExtractFiles from '../../dialogs/workfiles/extract_files';
import EditTags from '../../dialogs/tags/edit_tags_dialog';
import RCommand from '../../models/r_command';
import { workspace } from '../../utilities/workspace_assoc';
import { datasetUploadUrl, editUrl, analyseUrl, canEdit } from '../../utilities/file';
import { instance } from '../../models/config';
import Collection from '../../collections/collections';
import Routing from '../../mixins/routing';
import { rCommands } from '../../collections/r_commands';
import chorus from '../../chorus';
import t from '../../intl';
import CsvDialog from '../../dialogs/dataset/csv_to_dataset_dialog';
import { FEATURE } from '../../../utilities/features';

export default Sidebar.include(Routing).extend({
  constructorName: 'WorkfileSidebar',
  templateName: 'workfile_sidebar',
  useLoadingSection: true,
  bindCallbacks: $.noop,

  options: {
    showEditingLinks: true,
    showDownloadLink: true,
    showUpdatedTime: true,
  },
  subviews: {
    '.tab_control': 'tabs',
  },

  events: {
    'click .edit_tags': 'startEditingTags',
    'click .add_note': 'launchAddNoteDialog',
    'click .save_as': 'launchSaveAsDialog',
    'click .make_read_only': 'makeReadOnly',
    'click .make_read_write': 'makeReadWrite',
    'click .workfile_run': 'runFile',
    'click .workfile_download': 'launchWorkfileAirlock',
    'click .refresh': 'refresh',
    'click .open_as_text': 'launchOpenAsText',
    'click .open_as_md': 'launchMdViewer',
    'click .approve': 'launchAcceptDialog',
    'click .reject': 'launchRejectDialog',
    'click .extract_files': 'launchExtractFilesDialog',
    'click .open-csv-modal': 'openAboutDialog',
  },

  setup(options) {
    this.tabs = new TabControl();
    this.action = options.action;

    if (this.model) {
      this.setWorkfile(this.model, true);
    }
  },

  setWorkfile(workfile, evenIfNotChanged) {
    if (this.model !== workfile || evenIfNotChanged) {
      this.resource = workfile;
      this.model = workfile;
      if (this.model && !this.model.isInbox()) {
        this.collection = this.model.activities();
        this.listenTo(this.model, 'change:fileName', this.render);
        this.listenTo(this.model, 'change:readOnly', this.render);
        this.listenTo(this.collection, 'invalidated', function reFetch() { this.collection.fetch(); });
        this.collection.fetch();
        this.tabs.activity = new ActivityList({
          workspace: workspace(this.model),
          collection: this.collection,
          additionalClass: 'sidebar',
          displayStyle: ['without_object', 'without_workspace'],
        });
        this.onceLoaded(this.model, this.modelLoaded);
      } else {
        delete this.collection;
        delete this.tabs.activity;
      }
    }
  },

  modelLoaded() {
    if (this.options.showSchemaTabs && this.model.isSql() && workspace(this.model).canUpdate()) {
      this.tabs.tabNames = ['data', 'database_function_list', 'activity'];
      const schema = this.model.executionSchema();
      this.tabs.database_function_list = new FunctionTab({ schema });
      this.tabs.data = this.tabs.data || new DataTab({ schema });
    } else {
      this.tabs.tabNames = ['activity'];
    }

    this.tabs.activity = new ActivityList({
      workspace: workspace(this.model),
      collection: this.collection,
      additionalClass: 'sidebar',
      displayStyle: ['without_object', 'without_workspace'],
    });
  },

  additionalContext() {
    const isInbox = this.model && this.model.isInbox();
    const canUpdate = this.model && canEdit(this.model);
    const isFolder = this.model && this.model.isFolder();
    const canToggleReadOnly = this.model && !isFolder && !isInbox && workspace(this.model).canToggleReadOnly();
    const canAdd = true;
    const canDownload = this.model &&
      workspace(this.model).canAirlock() &&
      this.options.showDownloadLink && !this.model.get('destroying');
    const dteEnabled = this.model &&
      instance.dataTableEditorEnabled() &&
      this.model.isCsv();
    const workfileEditUrl = this.model && editUrl(this.model);
    const workfileDatasetUploadUrl = this.model && datasetUploadUrl(this.model);
    const workfileAnalyseUrl = this.model && analyseUrl(this.model);
    const ctx = {
      fileName: this.model && this.model.getFileName(),
      title: isInbox ? t('rsh.sidebar.title.inbox_list') : t('rsh.sidebar.title.workfile_list'),
      help: isInbox ? t('rsh.sidebar.help.inbox_list') : t('rsh.sidebar.help.workfiles_list'),
      isInbox,
      showAddNoteLink: canAdd && this.options.showEditingLinks && !isInbox,
      showCopyLink: true,
      showDownloadLink: canDownload && !isInbox,
      showEditLinks: canUpdate && this.options.showEditingLinks && !isInbox,
      largeCsv: this.model?.attributes?.contentLength >= 10485760,
      showUpdatedTime: this.options.showUpdatedTime && !isFolder && !isInbox,
      showVersions: this.options.showVersions && !isFolder,
      showReadOnly: canToggleReadOnly,
      showDTELink: dteEnabled,
      editUrl: workfileEditUrl,
      datasetUploadUrl: workfileDatasetUploadUrl,
      analyseUrl: workfileAnalyseUrl,
      isFolder,
      listMode: this.options.listMode,
      canRun: this.model && this.model.canRun() && workspace(this.model).canUpdate() && !isInbox,
      isText: (this.model && this.model.isText() && !this.model.isMd()) || this.action === 'text',
      isMdAsText: this.model && this.model.isMd() && this.action === 'text',
      isCsv: this.model && this.model.isCsv(),
      isZip: this.model && this.model.isZip(),
      openAsText: this.model && !this.model.isCsv() && !this.model.isText() && !this.model.isMdAsText && !isFolder,
    };

    if (this.model) {
      ctx.downloadUrl = this.model.downloadUrl();
      _.extend(ctx, this.modifierContext());
    }
    return ctx;
  },

  openAboutDialog: function(event) {
    event.preventDefault();

    new CsvDialog({
      workspaceId: this.model.attributes.workspace.id,
      workfileId: this.model.attributes.id
    }).launchModal();
  },

  modifierContext() {
    const modifier = this.model.modifier();
    return {
      updatedBy: modifier.displayShortName(),
      modifierUrl: modifier.showUrl(),
    };
  },

  postRender() {
    this._super('postRender');
    // This little hack grabs the file extension and separates it out, solving very long
    // file titles losing their file extension in display -- Sheru
    let rshFileExt = this.$('.app-sidebarFilename').text();
    if (rshFileExt.lastIndexOf('.') !== -1) {
      rshFileExt = rshFileExt.substr(rshFileExt.lastIndexOf('.'), rshFileExt.length);
      this.$('.app-sidebarFileExt').text(rshFileExt);
    }
  },

  startEditingTags(e) {
    e.preventDefault();
    new EditTags({ collection: new Collection([this.model]) }).launchModal();
  },

  launchSaveAsDialog(e) {
    e && e.preventDefault();
    this.model.fetch({
      success: _.bind(() => {
        const settings = {
          workspace: workspace(this.model),
          workfile: this.model.clone(),
          content: this.model.get('versionInfo').content,
        };
        const workfile = new chorus.dialogs.WorkfileSaveAs(settings);
        workfile.launchModal();
      }, this),
      includeContent: true,
    });
  },

  launchWorkfileAirlock(e) {
    e.preventDefault();

    if (FEATURE.NEW_UX) {
      return this.navigate(`workspaces/${workspace(this.model).id}/airlock_new/workfile/${this.model.get('id')}`);
    }

    workspace(this.model).trigger('airlock', this.model);
  },

  launchOpenAsText(e) {
    e.preventDefault();
    if (this.model.isText() || window.confirm(t('workfile.open_as_text.warning_text'))) {
      chorus.PageEvents.trigger('workfile:close', this.resource);
      this.navigate(this.model.showUrl({ openAsText: true }));
    }
    return false;
  },

  launchMdViewer(e) {
    e.preventDefault();
    chorus.PageEvents.trigger('workfile:close', this.resource);
    this.navigate(this.model.showUrl());
    return false;
  },

  launchAcceptDialog(e) {
    e && e.preventDefault();
    const dialog = new InboundAirlockAccept({
      workspace: workspace(this.model),
      workfile: this.model.clone(),
    });
    dialog.launchModal();
  },

  launchRejectDialog(e) {
    if (e) { e.preventDefault(); }

    new InboundAirlockReject({ model: this.model.clone() }).launchModal();
  },

  launchExtractFilesDialog(e) {
    e && e.preventDefault();
    const dialog = new ExtractFiles({
      workfile: this.model.clone(),
    });
    dialog.launchModal();
  },

  runFile(e) {
    if (e) { e.preventDefault(); }

    if (this.model.isR()) {
      const command = new RCommand({ workfile: this.model, action: 'run' });
      rCommands.add(command);
      this.navigate(command.showUrl());
    } else {
      this.navigate(this.model.showUrl({ run: true }));
    }
  },

  launchAddNoteDialog(e) {
    e && e.preventDefault();

    new NotesNew({
      entityId: this.model.id,
      entityType: 'workfile',
      workspace: workspace(this.model),
      allowWorkspaceAttachments: true,
      pageModel: this.collection,
    }).launchModal();
    return false;
  },

  makeReadOnly() {
    this.setReadOnly(true);
    return false;
  },

  makeReadWrite() {
    this.setReadOnly(false);
    return false;
  },

  setReadOnly(enabled) {
    new ReadonlyWorkfile({
      model: this.model,
      enabled,
    }).launchModal();
  },

  refresh() {
    if (this.options.workfileCollection) {
      this.options.workfileCollection.fetch();
    }
  },

});
