import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18n-js';
import {
  deleter, getter, patcher, poster,
} from '../adapters/service';
import { combineHooks } from '../../utilities/helpers';
import {
  provideClientPaging, useClientSort, useNameFilter, useTableDataFromQuery,
} from '../adapters/query_transformers';
import serverErrorMessage from '../utilities/server_errors';

const KEY = ['secrets'];
const SECRETS_URL = '/api/workspaces/secrets';
const transformErrors = (error) => {
  if (error?.status === 422) {
    throw Error(t('record_error.TAKEN'));
  }
  throw Error(serverErrorMessage(error));
};

const secretUrl = (name) => `${SECRETS_URL}/${name}`;
const postSecret = (body) => poster(SECRETS_URL, body).catch(transformErrors);
const deleteSecret = async (nameOrNames) => {
  const list = [].concat(nameOrNames);
  let response = null;
  for (const name of list) {
    // Deliberately not trying to send all the requests at once, I really do want
    // to wait on each promise before starting the next
    // eslint-disable-next-line no-await-in-loop
    response = await deleter(secretUrl(name));
  }
  return response;
};

const createEditBody = (current, newValues) => {
  const body = {};

  // if a value for the secret has been provided then we are updating the secret with a new value, no comparison with
  // the old value is possible because it is not provided back to the frontend
  if (newValues.value && newValues.value !== '') {
    body.value = newValues.value;
  }

  // check to see what has changed and send it
  ['description', 'environment_name'].forEach((prop) => {
    if (current[prop] !== newValues[prop]) {
      body[prop] = newValues[prop];
    }
  });
  return body;
};

const createEditSecret = (current) => async (newValues) => patcher(
  secretUrl(current.name),
  createEditBody(current, newValues),
);

const getSecrets = () => getter(SECRETS_URL);

export const createSecretMutation = (mutationFn) => ({ onSuccess, ...options }) => {
  const queryClient = useQueryClient();

  const opts = {
    ...options,
    onSuccess: () => {
      const promise = queryClient.invalidateQueries(KEY);
      if (onSuccess) { promise.then(onSuccess); }
      return promise;
    },
  };

  return (
    useMutation(mutationFn, opts)
  );
};

const QUERY_OPTIONS = {
  queryKey: KEY,
  queryFn: getSecrets,
};

export const useSecretCreation = createSecretMutation(postSecret);
export const useSecretsDelete = createSecretMutation(deleteSecret);
export const useSecretsEdit = (currentSecret) => createSecretMutation(createEditSecret(currentSecret));
export const useSecretsQueryBase = () => (useQuery(QUERY_OPTIONS));
const useClientPaging = provideClientPaging(10);
export const useSecretsQuery = combineHooks(
  useSecretsQueryBase,
  useTableDataFromQuery,
  useClientSort,
  useNameFilter,
  useClientPaging,
);
