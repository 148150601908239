import chorus from '../../chorus';
import _ from '../../underscore';
import t from '../../intl';
import View from '../loading_view';

export default View.extend({
  templateName: 'filter_wizard',
  constructorName: 'FilterWizard',
  persistent: true,

  events: {
    'click .add_filter': 'addFilter',
  },

  setup() {
    this.options = this.options || {};
    this.columnSet = this.options.columnSet;
    this.collection = this.collection || this.filterCollection();
    this.listenTo(this.columnSet, 'remove', this.removeInvalidFilters);
  },

  additionalContext() {
    return {
      title: t(this.title),
      extraContent: this.extraContent(),
    };
  },

  postRender() {
    _.each(this.filterViews, function teardownFilters(filterView) {
      this.stopListening(filterView);
      filterView.teardown();
    }, this);
    this.filterViews = [];
    if (!this.collection.length) {
      this.addFilter();
    } else {
      this.collection.each(function addFilters(filter) {
        this.renderFilterView(filter);
      }, this);
    }

    this.tagLastLi();
  },

  resetFilters() {
    this.collection.reset();
    this.render();
  },

  renderFilterView(filter) {
    const $ul = this.$('.filters');
    const filterView = this.filterView(filter);
    $ul.append(filterView.render().el);
    this.listenTo(filterView, 'deleted', function onDelete() { this.removeFilterView(filterView); });
    this.filterViews.push(filterView);
    this.registerSubView(filterView);
  },

  addFilter(e) {
    e && e.preventDefault();
    this.collection.add(this.filterModel());
    this.renderFilterView(this.collection.last());
    this.tagLastLi();
  },

  removeFilterView(filterView) {
    this.collection.remove(filterView.model);
    filterView.teardown();
    chorus.PageEvents.trigger('content:changed');
    this.tagLastLi();
  },

  removeInvalidFilters() {
    const badFilters = this.collection.filter(function filterColumn(filter) {
      return !this.columnSet.include(filter.get('column'));
    }, this);

    if (badFilters) {
      this.collection.remove(badFilters);
      this.render();
    }
  },

  tagLastLi() {
    const $ul = this.$('.filters');
    $ul.find('li').removeClass('last');
    $ul.find('li:last-child').addClass('last');
  },

  filterCount() {
    return this.collection.count();
  },

  extraContent() {
    return '';
  },
});
