import { t } from 'i18n-js';
import { Comment, Icon } from 'semantic-ui-react';
import MarkdownRender from '../../components/text_editors/MarkdownRender';
import Attachment from './Attachment';
import { activityType } from '../constants';
import ModalWrapper from '../../components/modals/ModalWrapper';
import AddCommentModal from './modals/AddCommentModal';
import Can from '../../permissions';
import { relativeDate, formatDateTime } from '../../helpers/dates';
import { isRichText } from '../../utilities/editor';
import { getUserName } from '../../adapters/user';
import { ErrorAvatar, SystemAvatar, UserAvatar } from '../../components/avatars/Avatars';

const Avatar = ({ activity, userName }) => {
  if (activity.isErrorMsg) {
    return <ErrorAvatar className="aridhia-avatar-activity" />;
  }

  if (activity.isSystemMsg) {
    return <SystemAvatar className="aridhia-avatar-activity" />;
  }

  return <UserAvatar userName={userName} className="aridhia-avatar-activity" />;
};

const Activity = ({ activity, entityId }) => {
  const userName = getUserName(activity.user);
  const isSubComment = activity.action === activityType.subComment;

  return (
    <Comment data-testid="activity">
      <Avatar activity={activity} userName={userName} />
      <Comment.Content>
        <Comment.Author as="span">{userName}</Comment.Author>
        <Comment.Metadata title={relativeDate(activity.timestamp)}>
          {formatDateTime(activity.timestamp)}
        </Comment.Metadata>
        <Comment.Text>
          <MarkdownRender value={activity.body} isRichText={isRichText(activity.body)} />
        </Comment.Text>
        <Comment.Actions>
          {!isSubComment && (
            <Can I="Interact with" this="Workspace">
              <ModalWrapper trigger={(
                <Comment.Action>
                  <Icon name="comment" color="blue" className="margin-right-tiny" />
                  {t('activities.actions.comment')}
                </Comment.Action>
              )}
              >
                <AddCommentModal
                  entityId={entityId}
                  eventId={activity.id}
                />
              </ModalWrapper>
            </Can>
          )}
          {activity.attachments?.map((acttachment) => (
            <Attachment
              key={`${activity.id}-${acttachment.id}`}
              activityId={activity.id}
              entity={acttachment}
            />
          ))}
        </Comment.Actions>
      </Comment.Content>
      {activity.comments?.length > 0 && (
        <Comment.Group size="small">
          {activity.comments.map((comment) => (
            <Activity key={comment.id} activity={comment} />
          ))}
        </Comment.Group>
      )}
    </Comment>
  );
};

export default Activity;
