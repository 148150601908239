import { useQuery } from '@tanstack/react-query';
import { currentUserUrl } from '../constants/api_urls';
import { getter } from './service';

export const getUserName = (user) => (`${user?.first_name} ${user?.last_name}`);

const useWorkspaceUser = () => {
  const fetchUser = () => getter(currentUserUrl);
  const { data, isLoading, isError } = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
    staleTime: 1000 * 60 * 10,
  });

  return {
    user: data,
    isLoading,
    isError,
  };
};

export default useWorkspaceUser;
