import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import JoinConfiguration from './join_configuration_dialog';
import PaginatedJoinTablesList from './paginated_join_tables_list';
import PreviewColumns from './preview_columns_dialog';
import Dialog from '../../dialogs/dialogs';

export default Dialog.extend({
  constructorName: 'ManageJoinTables',

  templateName: 'manage_join_tables',
  additionalClass: 'with_sub_header pick_dataset_with_action',
  title: t('dataset.manage_join_tables.title'),

  events: {
    'click a.join': 'joinLinkClicked',
    'click a.preview_columns': 'onClickPreviewColumns',
  },

  subviews: {
    '.paginated_join_tables': 'paginatedJoinTables',
  },

  makeModel(...args) {
    this._super('makeModel', args);
    this.model = this.options.chorusView;
  },

  setup() {
    const { schema } = this.options;
    this.resource = schema.datasets();
    this.collection = this.resource;
    this.paginatedJoinTables = new PaginatedJoinTablesList({ collection: this.resource });

    this.renderSubviews();
    this.collection.fetchPage(1, { per_page: 7 });
  },

  postRender() {
    this._super('postRender');

    const onTextChangeFunction = _.debounce(_.bind(function onSearch(e) {
      this.collection.search($(e.target).val());
    }, this), 300);

    chorus.search({
      input: this.$('.search input:text'),
      onTextChange: onTextChangeFunction,
    });
  },

  joinLinkClicked(e) {
    e.preventDefault();
    const clickedId = $(e.target).closest('li').data('cid');
    const dataset = this.collection.get(clickedId);

    const joinConfigurationDialog = new JoinConfiguration({
      model: this.model,
      destinationObject: dataset.clone(),
    });
    this.launchSubModal(joinConfigurationDialog);
  },

  onClickPreviewColumns(e) {
    e.preventDefault();

    const clickedId = $(e.target).closest('li').data('cid');
    const dataset = this.collection.get(clickedId);

    const previewColumnsDialog = new PreviewColumns({ model: dataset });
    this.launchSubModal(previewColumnsDialog);
  },
});
