import Base from './base';

export default Base.extend({
  constructorName: 'ConfirmAlert',

  focusSelector: 'button.submit',

  postRender() {
    this._super('postRender');
    this.$('button.submit').removeClass('hidden');
  },
});
