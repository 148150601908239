import chorus from '../../chorus';
import _ from '../../underscore';
import t from '../../intl';
import Dialog from '../../dialogs/dialogs';
import Workfile from '../../models/workfile';
import DialogFormHelpers from '../../mixins/dialog_form_helpers';
import Routing from '../../mixins/routing';

export default Dialog.include(DialogFormHelpers, Routing).extend({
  constructorName: 'FolderNew',

  templateName: 'folder_new',
  title: t('workfiles.new_folder.title'),

  persistent: true,

  makeModel() {
    this.model = this.model || new Workfile({
      workspace: { id: this.options.workspace.id },
    });
  },

  setup(options) {
    this.folder = options.folder;
    this.workspace = options.workspace;
    this.listenTo(this.resource, 'saved', this.saved);
    this.listenTo(this.resource, 'saveFailed', this.saveFailed);
    this.disableFormUnlessValid({
      formSelector: 'form',
      inputSelector: 'input[name=folderName]',
    });
  },

  additionalContext() {
    return { folders: this.folderOptions() };
  },

  folderOptions() {
    const topFolders = this.workspace.workfileFolders();
    let selected = topFolders[0];

    if (this.folder) {
      selected = this.folder.name();
    }

    const folders = _.map(topFolders, folder => ({ data: folder, value: folder, selected: folder === selected }));

    const folder = _.findWhere(folders, { selected: true });
    if (!folder && !selected.startsWith('inbox') && !selected.startsWith('public inbox')) {
      folders.unshift({ data: t('workfile.current_folder'), value: selected, selected: true });
    }

    return folders;
  },

  create() {
    const folderName = this.$('input[name=folderName]').val().trim();
    const folder = this.$('select[name=folder]').val().trim();

    this.resource.set({
      fileName: folderName,
      folder,
    });

    this.$('button.submit').startLoading('actions.adding');
    this.resource.save({ source: 'empty', entity_subtype: 'folder', content_type: 'folder' });
  },

  saved() {
    this.model.results = this.options.results;
    this.closeModal();
    this.navigate(this.model.showUrl());
    chorus.PageEvents.trigger('workfiles:changed');

    if (this.options.toast) {
      chorus.toast('workfiles.folder.save_succeeded');
    }
  },

  saveFailed() {
    this.$('button.submit').stopLoading();
  },
});
