import { t } from 'i18n-js';
import { Button } from 'semantic-ui-react';
import { useDatabaseTableCreationContext } from '../Context';
import { removeFromScratchColumn, addFromScratchColumn } from '../actions';

export const DatabaseTablesRemoveColumnButtons = ({ column }) => {
  const { newDatabaseTableState, dispatch } = useDatabaseTableCreationContext();

  const removeColumn = (e, { id }) => {
    dispatch(removeFromScratchColumn(id));
  };

  if (newDatabaseTableState.table.columns.length <= 1) {
    return null;
  }

  return (
    <Button
      id={`delete-column-button-${column.id}`}
      icon="times"
      negative
      basic
      fluid
      disabled={column.primary}
      content={t('database_tables_new.columns.button_remove')}
      onClick={removeColumn}
    />
  );
};

const DatabaseTableCreationColumnButtons = () => {
  const { dispatch } = useDatabaseTableCreationContext();

  const addColumn = () => {
    dispatch(addFromScratchColumn);
  };

  return (
    <Button
      icon="add"
      color="blue"
      aria-label={t('database_tables_new.columns.button_add')}
      onClick={addColumn}
    />
  );
};

export default DatabaseTableCreationColumnButtons;
