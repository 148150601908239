import {
  Icon, Button, List, Label,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import { useDatabaseTableContext } from '../../../Context';
import { editDatabaseTableMetadata } from '../../../../constants/ui_urls';
import MarkdownRender from '../../../../components/text_editors/MarkdownRender';
import { isRichText } from '../../../../utilities/editor';
import Can from '../../../../permissions';
import { sanitiseTableType } from '../../../helper';

const DatabaseTableMetadata = () => {
  const { table, tableId, tableType } = useDatabaseTableContext();

  return (
    <>
      <Can I="WriteMeta" this="Workspace">
        <Button size="tiny" compact className="position-top-right aridhia-accordion-button" href={editDatabaseTableMetadata(tableId)}>
          <Icon name="edit outline" title={t('tables_list.actions.edit_metadata')} />
          {t('tables_list.actions.edit_metadata')}
        </Button>
      </Can>
      <List as="ul" className="margin-none" data-testid="database-table-metadata">
        <List.Item as="li" data-testid="Title">
          <Label horizontal>{t('database_table.metadata.title')}</Label>
          <strong>{table.title}</strong>
        </List.Item>
        <List.Item as="li" data-testid="Type">
          <Label horizontal>{t('database_table.metadata.type')}</Label>
          {sanitiseTableType(tableType)}
        </List.Item>
        <List.Item as="li" data-testid="URL">
          <Label horizontal>{t('database_table.metadata.url')}</Label>
          <a href={table.url} target="_blank" rel="noreferrer">{table.url}</a>
        </List.Item>
        <List.Item as="li" data-testid="Description">
          <Label horizontal>{t('database_table.metadata.description')}</Label>
          <MarkdownRender value={table.description} isRichText={isRichText(table.description)} />
        </List.Item>
      </List>
    </>
  );
};

export default DatabaseTableMetadata;
