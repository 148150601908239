import _ from '../../underscore';
import View from '../loading_view';

export default View.extend({
  constructorName: 'BreadcrumbsView',
  templateName: 'breadcrumbs',

  additionalContext() {
    const crumbs = this.options.breadcrumbs;

    return {
      breadcrumbs: (_.isFunction(crumbs) ? crumbs() : crumbs),
    };
  },

  postRender() {
    const $crumb = this.$('.breadcrumb');
    _.each(this.context().breadcrumbs, (breadcrumb, index) => {
      if (breadcrumb.data) {
        $crumb.eq(index).find('a').data(breadcrumb.data);
      }
    });
  },
});
