import React from 'react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import {
  Header, Container, Table, Input,
} from 'semantic-ui-react';
import { t } from 'i18n-js';

function DatasetMetadataTable({
  datasetMeta, handleColumnLabelChange, handleColumnDescriptionChange,
}) {
  if (datasetMeta.get('columns').length) {
    return (
      <>
        <Header as="h2" className="header-information">
          {t('dataset.meta.header_information')}
        </Header>
        <Container className="table-container columns">
          <Table compact striped>
            <Table.Header>
              <Table.Row key="header-row">
                <Table.HeaderCell key="header-1">
                  {t('dataset.meta.header')}
                </Table.HeaderCell>
                <Table.HeaderCell key="header-2">
                  {t('dataset.meta.example')}
                </Table.HeaderCell>
                <Table.HeaderCell key="header-3">
                  {t('dataset.meta.type')}
                </Table.HeaderCell>
                <Table.HeaderCell key="header-4">
                  {t('dataset.meta.label')}
                </Table.HeaderCell>
                <Table.HeaderCell key="header-5">
                  {t('dataset.meta.description')}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {datasetMeta.get('columns').map((row, i) => (
                <Table.Row key={`row-${i}`}>
                  <Table.Cell key={`row-${i}-cell-1`}>
                    {row.name}
                  </Table.Cell>
                  <Table.Cell key={`row-${i}-cell-2`}>
                    {row.example?.toString()}
                  </Table.Cell>
                  <Table.Cell key={`row-${i}-cell-3`}>
                    {row.type}
                  </Table.Cell>
                  <Table.Cell key={`row-${i}-cell-4`}>
                    <label htmlFor={`label-${i}`} className="offscreen">{t('dataset.meta.label')}</label>
                    <Input
                      id={`label-${i}`}
                      name={`label-${i}`}
                      value={row.label}
                      onChange={handleColumnLabelChange}
                    />
                  </Table.Cell>
                  <Table.Cell key={`row-${i}-cell-5`}>
                    <label htmlFor={`description-${i}`} className="offscreen">{t('dataset.meta.description')}</label>
                    <Input
                      id={`description-${i}`}
                      name={`description-${i}`}
                      value={row.description}
                      className="large"
                      onChange={handleColumnDescriptionChange}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Container>
      </>
    );
  }
  return <></>;
}

const mapModelsToProps = ({ datasetMeta }) => ({
  datasetMeta,
  handleColumnLabelChange: (_e, { name, value }) => {
    datasetMeta.updateColumnLabel(name, value);
  },
  handleColumnDescriptionChange: (_e, { name, value }) => {
    datasetMeta.updateColumnDescription(name, value);
  },
});

const options = {
  debounce: false,
  events: {
    datasetMeta: ['change:columns'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(DatasetMetadataTable);
