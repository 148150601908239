import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import Dialog from '../../dialogs/dialogs';
import { toAirlock } from '../../presenters/activity_presenters';
import ClEditor from '../../mixins/cl_editor_helpers';

export default Dialog.include(ClEditor).extend({
  constructorName: 'EditAirlockDialog',
  templateName: 'edit_airlock',
  persistent: true,

  events: {
    'submit form': 'submit',
  },

  setup() {
    this.activity = this.options.activity;
    this.title = t('airlock.edit_dialog.title');
    this.resource = toAirlock(this.activity);
    this.model = this.resource;

    this.listenTo(this.resource, 'validationFailed', this.showErrors);
    this.listenTo(this.resource, 'saved', this.submitSucceeds);
  },

  showErrors(model) {
    this.$('button.submit').stopLoading();
    this._super('showErrors');
    let errorModel = model;

    if (!errorModel) {
      errorModel = this.resource;
    }

    if (errorModel.errors && errorModel.errors.reason) {
      const $input = this.$('.cleditorMain');
      this.markInputAsInvalid($input, errorModel.errors.reason, true);

      this.$('iframe').contents().find('reason').css('margin-right', '20px');
    }
  },

  postRender() {
    this.$('textarea').val(this.activity.get('reason'));
  },

  submit(e) {
    e && e.preventDefault();
    this.$('button.submit').startLoading('actions.saving');

    let newText = this.getNormalizedText(this.$('textarea'));
    const cleanText = _.trim($.stripHtml(newText));

    if (cleanText === '') {
      // if the user only has whitespace, force validation failure on the model
      newText = '';
    }

    this.model.save({ reason: newText });
  },

  submitSucceeds() {
    this.$('button.submit').stopLoading();
    this.closeModal();
    chorus.PageEvents.trigger('airlock:saved', this.model);
    chorus.toast('airlock.edit_dialog.updated', {});
  },
});
