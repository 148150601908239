import { Menu } from 'semantic-ui-react';
import { returnBaseFolder } from '../../utilities/helper';

const BaseFolderSelection = ({
  baseFolders,
  folder,
  handleSetFolder,
  handleFilterChange = null,
}) => {
  const handleBaseFolderSelection = (destFolder) => {
    handleSetFolder(destFolder);
    if (handleFilterChange) {
      handleFilterChange('');
    }
  };
  const baseFolder = returnBaseFolder(folder);

  return (
    <Menu
      size="small"
      pointing
      secondary
      color="blue"
    >
      {baseFolders.map((type) => (
        <Menu.Item
          key={`main_${type}`}
          active={type === baseFolder}
          onClick={() => handleBaseFolderSelection(type)}
        >
          {type.toUpperCase()}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default BaseFolderSelection;
