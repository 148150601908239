import { Menu, Icon } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { workspaceSummaryUrl } from '../../../../constants/ui_urls';

const ActivityLauncher = () => (
  <Menu.Item href={workspaceSummaryUrl()}>
    <Icon data-testid="icon" name="calendar check" title={(t('workspaces.launcher.activity'))} />
    <span>{(t('workspaces.launcher.activity'))}</span>
  </Menu.Item>
);

export default ActivityLauncher;
