import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { FEATURE } from '../../../utilities/features';
import SiteTrackingConsentToast from './SiteTrackingConsentToast';

const SiteTracking = ({ children }) => {
  if (!FEATURE.SITE_TRACKING_ENABLED || !window.customizationVariables.tracking) {
    return children;
  }

  if (FEATURE.SITE_TRACKING_ENABLED && Cookies.get('workspacesTrackingConsent') === undefined) {
    toast(<SiteTrackingConsentToast />, {
      hideProgressBar: true,
      position: 'bottom-center',
      draggable: false,
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
      toastId: 'SiteTrackingConsentToast',
    });
  }

  const instance = createInstance({
    urlBase: '/matomo/',
    siteId: window.customizationVariables.tracking.siteId,
  });

  return (
    <MatomoProvider value={instance}>
      {children}
    </MatomoProvider>
  );
};

export default SiteTracking;
