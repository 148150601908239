import { lazy, Suspense } from 'react';

// Most of our promised content comes in through react-query but
// a simpler mechanism can be useful when dealing with other libraries
// that return promises (e.g. the auth library that we use)
//
// promise parameter should be the promise of component
const PromisedComponent = ({ promise }) => {
  // create a component using the promise using React.lazy
  const Component = lazy(async () => {
    const result = await promise;

    // lazy expects the promise passed in to return an object with
    // the default property being a Component
    return { default: () => (result) };
  });

  // Lazy components should be wrapped in a suspense in case they take
  // a while to resolve.
  return (<Suspense><Component /></Suspense>);
};

export default PromisedComponent;
