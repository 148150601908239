import * as d3 from 'd3';
import _ from '../../underscore';
import $ from '../../jquery';
import View from '../loading_view';
import Axes from './axes';
import { Histogram } from '../../presenters/visualization_data_presenter';

export default View.extend({
  render() {
    const $el = $(this.el);
    $el.html('');
    $el.addClass('visualization');

    const data = new Histogram(this.model).present();

    const bins = _.pluck(data, 'bin');

    const frequencies = _.pluck(data, 'frequency');

    const svg = d3.select(this.el).append('svg')
      .attr('class', 'chart histogram')
      .attr('width', 925)
      .attr('height', 340);


    this.axes = new Axes({
      el: svg,
      minYValue: 0,
      maxYValue: _.max(frequencies),
      xScaleType: 'numeric',
      minXValue: _.min(_.flatten(bins)),
      maxXValue: _.max(_.flatten(bins)),
      yScaleType: 'numeric',
      yAxisLabel: 'count',
      xAxisLabel: this.model.get('chart[xAxis]'),
      ticks: true,
      hasYGrids: true,
    });

    if (!$el.isOnDom()) return;

    this.axes.render();
    const scales = this.axes.scales();

    const plot = svg.append('svg:g').attr('class', 'plot');

    const binWidth = Math.abs(scales.x(bins[0][1]) - scales.x(bins[0][0]));
    const barWidth = binWidth * 0.6;
    const barOffset = binWidth * 0.2;

    plot
      .selectAll('rect')
      .data(data)
      .enter().append('svg:rect')
      .attr('class', 'bar')
      .attr('x', d => (scales.x(d.bin[0]) + barOffset))
      .attr('width', barWidth)
      .attr('y', d => scales.y(d.frequency))
      .attr('height', d => scales.y(0) - scales.y(d.frequency));

    svg.select('.xaxis').remove();
    svg.select('.yaxis').remove();
    this.axes.render();
  },

});

