import React from 'react';
import { BackboneProvider } from 'connect-backbone-to-react';
import CardList from 'components/containerised_apps/CardList';
import View from '../react_view';
import $ from '../../jquery';
import '../../../components/containerised_apps/ContainerisedApp.less';
import ContainerisedAppsAndPods from '../../../collections/containerised_app_and_pods';

export default View.extend({
  bindCallbacks: $.noop,
  className: 'app-containerisedAppList semantic main_content',

  setup({ model, tabModel }) {
    this.workspace = model;
    this.tabModel = tabModel;
    
    this.apps = new ContainerisedAppsAndPods([], { workspace: this.workspace });

    this.listenTo(this.tabModel, 'change:active', (_model, active) => {
      if (active) {
        this.apps.fetch(); // TODO (XAP-18592): handle errors
      }
    });
  },

  renderOnce() {
    this.apps.fetch();

    const modelsMap = {
      containerisedApps: this.apps,
    };

    this.root.render(
      <BackboneProvider models={modelsMap}>
        <CardList />
      </BackboneProvider>,
    );
  },
});
