import { React, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { t } from 'i18n-js';

function includesDelimiter(delimiter) {
  return [',', '\t', ';', ' '].includes(delimiter);
}

const DelimiterForm = ({ delimiter, handleDelimiterChange }) => {
  const [customDelimiter, setCustomDelimiter] = useState('');

  const handleCustomDelimiterChange = (e, { value }) => {
    setCustomDelimiter(value);
    handleDelimiterChange(value);
  };

  return (
    <Form.Group inline data-testid="delimiter-form">
      <label htmlFor="delimiter">{t('modals.save_as_csv.delimiter.title')}</label>
      <Form.Radio
        label={t('modals.save_as_csv.delimiter.comma')}
        id="comma"
        value=","
        checked={delimiter === ','}
        onChange={(e, { value }) => handleDelimiterChange(value)}
      />
      <Form.Radio
        label={t('modals.save_as_csv.delimiter.tab')}
        id="tab"
        value="&#09;"
        checked={delimiter === '\t'}
        onChange={(e, { value }) => handleDelimiterChange(value)}
      />
      <Form.Radio
        label={t('modals.save_as_csv.delimiter.semicolon')}
        id="semicolon"
        value=";"
        checked={delimiter === ';'}
        onChange={(e, { value }) => handleDelimiterChange(value)}
      />
      <Form.Radio
        label={t('modals.save_as_csv.delimiter.space')}
        id="space"
        value=" "
        checked={delimiter === ' '}
        onChange={(e, { value }) => handleDelimiterChange(value)}
      />
      <Form.Radio
        label={t('modals.save_as_csv.delimiter.other')}
        value="other"
        checked={!includesDelimiter(delimiter)}
        onChange={(e, { value }) => handleDelimiterChange(value)}
      />
      <Form.Input
        size="mini"
        maxLength={1}
        value={customDelimiter}
        onChange={handleCustomDelimiterChange}
      >
        <input data-testid="custom-delimiter" />
      </Form.Input>
    </Form.Group>
  );
};

export default DelimiterForm;
