import t from '../intl';
import MainContentView from '../views/core/main_content_view';
import NotificationList from '../views/activities/notification_list_view';
import ListHeaderView from '../views/core/list_header_view';
import BasePage from './base_page';
import NotificationSet from '../collections/notification_set';

export default BasePage.extend({
  constructorName: 'NotificationIndexPage',
  crumbs: [
    { label: t('breadcrumbs.notifications') },
  ],

  setup() {
    this.collection = new NotificationSet([]);
    this.listenTo(this.collection, 'loaded', this.notificationsFetched);
    this.collection.fetch();

    this.subscribePageEvent('notification:deleted', this.refreshNotifications);

    this.mainContent = new MainContentView({
      collection: this.collection,
      contentHeader: new ListHeaderView(),
      content: new NotificationList({ collection: this.collection, allowMoreLink: true }),
    });
  },

  notificationsFetched: function markAllRead() {
    this.collection.markAllRead({});
    this.teardownSubViews();
    if (this.mainContent) {
      this.mainContent.torndown = false;
    }
    this.render();
  },

  refreshNotifications: function reFetch() {
    this.collection.fetch();
  },
});
