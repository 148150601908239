import chorus from '../../chorus';
import t from '../../intl';
import Dialog from '../../dialogs/dialogs';
import { schema } from '../../utilities/schema_assoc';
import DatabaseViewConverter from '../../models/database_view_converter';
import { ChorusIdentifierLower64 } from 'utilities/validation_regexes';

export default Dialog.extend({
  constructorName: 'CreateDatabaseView',

  templateName: 'create_database_view_dialog',
  title: t('create_database_view.title'),

  events: {
    'submit form': 'performValidation',
    'click button.submit': 'performValidation',
  },

  makeModel() {
    this.dataset = this.options.pageModel;
    this.model = new DatabaseViewConverter(
      {
        workspaceId: this.dataset.get('workspace') && this.dataset.get('workspace').id,
      },
      {
        from: this.dataset,
      },
    );
    this.listenTo(this.model, 'saved', this.saved);
    this.listenTo(this.model, 'saveFailed', this.saveFailed);
  },

  additionalContext() {
    return {
      canonicalName: this.canonicalName(),
    };
  },

  performValidation(e) {
    e && e.preventDefault();
    this.clearErrors();
    const $name = this.$('#create_database_view_name');

    if ($name.val().match(ChorusIdentifierLower64())) {
      this.$('button.submit').startLoading('actions.creating');
      this.model.set({ objectName: $name.val() }, { silent: true });
      this.model.save();
    } else {
      this.markInputAsInvalid($name, t('validation.chorus64'), true);
    }
  },

  saved() {
    chorus.toast('create_database_view.toast_success', {
      canonicalName: this.canonicalName(),
      viewName: this.model.get('objectName'),
    });

    const databaseView = this.model.databaseView();
    if (!databaseView.has('workspace')) {
      databaseView.set({ workspace: this.dataset.get('workspace') });
    }
    this.closeModal();
    chorus.PageEvents.trigger('datasets:changed');
  },

  saveFailed() {
    this.$('button.submit').stopLoading();
  },

  canonicalName() {
    return schema(this.dataset).canonicalName();
  },
});
