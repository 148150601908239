import { t } from 'i18n-js';
import { useWorkspaceContext } from '../../adapters/Workspace';
import Loader from '../../components/Loader';
import LoadingError from '../../components/errors/LoadingError';
import IFramedContent from '../../components/IFramedContent';
import useHomepage from '../service';

const HomeContent = () => {
  const { workspace } = useWorkspaceContext();
  const { data, isLoading, isError } = useHomepage({ enabled: workspace.has_homepage });

  if (!workspace?.has_homepage) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <LoadingError />;
  }

  return (
    <IFramedContent
      content={data}
      sandbox="allow-same-origin allow-top-navigation allow-popups allow-popups-to-escape-sandbox"
      title={t('homepage.title')}
      className="aridhia-full-height-content"
    />
  );
};

export default HomeContent;
