import { Card } from 'semantic-ui-react';
import AppsCardFooter from './AppsCardFooter';
import AppsCardContent from './AppsCardContent';
import { useAppContext } from '../../../Context';
import statusColour from '../helper';

const AppsCard = () => {
  const { name, podStatus } = useAppContext();

  return (
    <Card color={statusColour(podStatus)} data-testid="apps-card">
      <Card.Content>
        <Card.Header as="h3">
          {name}
        </Card.Header>
        <AppsCardContent />
      </Card.Content>
      <AppsCardFooter />
    </Card>
  );
};

export default AppsCard;
