import { QueryClient } from '@tanstack/react-query';
import productionFetcher, { accessTokenFetcher, rawFetcher as productionRawFetcher, officeFetcher as _officeFetcher } from '../utilities/request';
import requestMethods from '../constants/requests';

// Set fetcher and token fetcher to use browser fetch
let fetcher = productionFetcher;
let workspaceTokenFetcher = accessTokenFetcher;
let rawFetcher = productionRawFetcher;
let officeFetcher = _officeFetcher;

const clientConfig = {
  defaultOptions: {
    queries: {
      /* the query client is sometimes mounted multiple times due to multiple react roots
         (react -> backbone -> react) and will trigger a refetch of all queries on mount by default */
      refetchOnMount: false,
      /* if multiple requests are made to the same endpoint from different components then they should
         use the same result if done close enough together
       */
      staleTime: 5000,
    },
  },
  logger: { error: () => {} },
};

export const queryClient = new QueryClient(clientConfig);

// Used by tests to mock out responses
export const setMockResponses = (requests) => {
  queryClient.clear();
  const testFetcher = (url) => {
    const { response } = requests[url] || {};
    if (typeof response === 'function') {
      return response();
    }
    return Promise.resolve(response);
  };
  //  Set fetcher to use mock fetch
  workspaceTokenFetcher = testFetcher;
  fetcher = testFetcher;
  rawFetcher = testFetcher;
  officeFetcher = testFetcher;
};

export const getter = (url, options) => fetcher(url, null, requestMethods.GET, options);
export const poster = (url, body) => fetcher(url, JSON.stringify(body), requestMethods.POST);
export const patcher = (url, body) => fetcher(url, JSON.stringify(body), requestMethods.PATCH);
export const deleter = (url, body) => fetcher(url, JSON.stringify(body), requestMethods.DELETE);
export const putter = (url, body) => fetcher(url, JSON.stringify(body), requestMethods.PUT);

/*
 * These workspaceToken methods are for calling endpoints authorised with a workspace access token
 * instead of the idToken. The id refers to the workspace id to use when obtaining the access token.
 */
export const workspaceTokenGetter = (url, options) => (
  workspaceTokenFetcher(url, null, requestMethods.GET, options)
);
export const workspaceTokenPoster = (url, body) => (
  workspaceTokenFetcher(url, JSON.stringify(body), requestMethods.POST)
);
export const workspaceTokenPatcher = (url, body) => (
  workspaceTokenFetcher(url, JSON.stringify(body), requestMethods.PATCH)
);
export const rawGetter = (url, options) => rawFetcher(url, null, requestMethods.GET, options);

export const officeDiscoveryGetter = (url, options = {}) => officeFetcher(url, options);
