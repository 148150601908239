import { useState } from 'react';
import { t } from 'i18n-js';
import {
  Modal, Icon, Header,
} from 'semantic-ui-react';
import HeaderError from '../../../components/errors/HeaderError';
import MarkdownEditor from '../../../components/text_editors/MarkdownEditor';
import AttachedItems from '../../../components/attachments/AttachedItems';
import ModalActionButtons from '../../../components/buttons/ModalActionButtons';
import ModalWrapper from '../../../components/modals/ModalWrapper';
import AttachmentSubmodal, { AttachmentModalButton } from '../../../components/attachments/AttachmentModal';
import { useNote } from '../../service';
import toastMessage from '../../../utilities/toast';
import { workspaceId } from '../../../layout/helper';

const AddNoteModal = ({
  setModalClose, setIsBusy, entityType, entityId, entityName,
}) => {
  const [newNote, setNewNote] = useState('');
  const [attachedItems, setAttachedItems] = useState([]);

  const isValidEntry = !!newNote && newNote !== '<div></div>';

  const handleNoteChange = (value) => {
    setNewNote(value);
  };

  const handleRemoveAttachedItems = (item) => {
    const newAttached = attachedItems.filter((obj) => JSON.stringify(obj) !== JSON.stringify(item));
    setAttachedItems(newAttached);
  };

  const {
    addNote,
    isLoading,
    isError,
    error,
    resetResponse,
  } = useNote(entityId);

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleSubmit = () => {
    setIsBusy(true);
    const attachedWorkfiles = attachedItems.filter((obj) => obj.itemType !== 'dataset').map((obj) => obj.itemId);
    const attachedDataset = attachedItems.filter((obj) => obj.itemType === 'dataset').map((obj) => obj.itemId);

    const noteData = {
      note: {
        body: newNote,
        entity_id: entityId,
        entity_type: entityType,
        dataset_ids: attachedDataset,
        workfile_ids: attachedWorkfiles,
        recipients: [],
        workspace_id: workspaceId(),
      },
    };

    addNote(noteData, {
      onSuccess: () => {
        setIsBusy(false);
        handleModalClose();
        toastMessage('success', t('file_list.actions.add_note_success', { entityName }), 'noteSuccess');
      },
      onError: () => {
        setIsBusy(false);
      },
    });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="sticky note" title={t('file_list.actions.add_note')} />
        <Header.Content content={t('file_list.actions.add_note')} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <MarkdownEditor
          value={newNote}
          onChange={handleNoteChange}
        />
        <AttachedItems
          attachedItems={attachedItems}
          handleRemoveAttachedItems={handleRemoveAttachedItems}
          hasItemsAttached
        />
      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmit}
        isValidEntry={isValidEntry}
        error={isError}
        submitText={t('file_list.actions.add_note')}
      >
        <ModalWrapper trigger={AttachmentModalButton} size="small">
          <AttachmentSubmodal
            attachedItems={attachedItems}
            setAttachedItems={setAttachedItems}
          />
        </ModalWrapper>
      </ModalActionButtons>
    </>
  );
};

export default AddNoteModal;
