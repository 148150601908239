import axios from 'axios';
import { idToken } from '../auth/oauth';


export const { isCancel } = axios;
export const cancelTokenSource = axios.CancelToken.source;

// Timeout for Azure SAS is half an hour, renewing token every ten minutes for long uploads should work
const CACHE_PERIOD = 1000 * 60 * 10;
function cached(fn) {
  let value = null;
  let time = Date.now();

  const expired = () => (Date.now() - time > CACHE_PERIOD);
  const updateState = (x) => { value = x; time = Date.now(); return x; };
  return () => {
    if (!value || expired()) {
      return fn().then(updateState);
    }
    return Promise.resolve(value);
  };
}

export function blockname(i) {
  return btoa((100000 + i).toString().replace(/^1/, 'block'));
}

function createBlockList(numBlocks) {
  let data = '<?xml version="1.0" encoding="utf-8"?>\n<BlockList>\n<Latest>';
  for (let i = 0; i < numBlocks - 1; i++) {
    data += blockname(i);
    data += '</Latest>\n<Latest>';
  }
  data += blockname(numBlocks - 1);
  data += '</Latest>\n</BlockList> ';
  return data;
}

function injectChild(acc, val) {
  if (val.nodeName !== '#text') acc[val.nodeName.toLowerCase()] = val.textContent;
  return acc;
}

function parseBlock(block) {
  return Array.prototype.reduce.call(block.childNodes, injectChild, {});
}

function parseBlockList({ data }) {
  return Array.prototype.map.call(data.getElementsByTagName('Block'), parseBlock);
}

export const getUploadParams = (presignEndpoint, filename, type, cancelToken, batchId) => cached(() => {
  const data = { filename, type, batch_id: batchId };

  return idToken().then((token) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      cancelToken,
    };
    return axios.post(presignEndpoint, data, config).then(r => {
      const data = r.data;
      data.cancelToken = cancelToken;
      return data;
    });
  });
});

export const createTriggerScan = (presignEndpoint, filename, type, batchId) => () => {
  return idToken().then((token) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    return axios.post(presignEndpoint, { filename, type, batch_id: batchId, complete: true }, config).then(r => r.data);
  });
};

export function getBlockList({ url, headers, cancelToken }) {
  return axios.get(
    `${url}&comp=blocklist&blocklisttype=uncommitted&nocache=${Date.now()}`,
    { responseType: 'document', headers, cancelToken },
  )
    .then(parseBlockList)
    .catch((reason) => { return []; });
}

export function put({
  url, headers, data, cancelToken,
}) {
  return axios.put(url, data, { headers, cancelToken });
}

export function commitBlockList({
  url, headers, numBlocks, cancelToken,
}) {
  return axios.put(url, createBlockList(numBlocks), { headers, cancelToken });
}
