import Comment from '../models/comment';
import Collection from './collections';

export default Collection.extend({
  constructorName: 'CommentSet',
  model: Comment,

  comparator(comment) {
    return comment.get('timestamp');
  },
});
