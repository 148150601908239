import _ from '../underscore';
import Collection from './collections';

// A view with requiredResources won't render until those resources have triggered "serverResponded".
// Adding a resource to requiredResources causes a listener to be set up for that resource.
// When all resources have triggered "serverResponded", requiredResource triggers the "allResourcesResponded" event.
// This event is listenedTo by the view, which then calls render and any other commands in the resourcesLoaded method.

const RequiredResources = Collection.extend({
  constructorName: 'RequiredResources',

  allResponded() {
    return _.all(this.models, resource => resource.statusCode);
  },

  add(resources, options) {
    this._super('add', [resources, _.extend({}, options, { silent: true })]);
    this.trigger('add', resources);

    const res = _.isArray(resources) ? resources.slice() : [resources];
    _.each(res, _.bind(function waitForResponse(resource) {
      if (!resource.statusCode) {
        this.listenTo(resource, 'serverResponded', this.verifyResourcesResponded);
      }
    }, this));
  },

  verifyResourcesResponded() {
    if (this.allResponded()) {
      this.trigger('allResourcesResponded');
      this.stopListening();
    }
  },

  _prepareModel(obj) {
    const object = obj;
    if (!object.cid) {
      object.cid = _.uniqueId('rr');
    }
    return object;
  },

  cleanUp(context) {
    this.unbind(null, null, context);
    this.stopListening();
    this.each((resource) => {
      resource.unbind(null, null, context);
    });
    this.reset([], { silent: true });
  },
});

RequiredResources.prototype.push = RequiredResources.prototype.add;

export default RequiredResources;
