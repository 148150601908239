/* global window:false */
import i18n from "i18n-js";
import translations from '../translations/en.json';

translations.customization = window.customizationVariables.translation;
i18n.fallbacks = true;
i18n.translations = translations;
i18n.defaultLocale = 'en';
i18n.locale = 'customization';
window.t = i18n.t;
window.I18n = i18n;

export default window.t;

export const I18n = i18n;
