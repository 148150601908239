export const validationLengths = Object.freeze({
  NAME: 255,
  URL: 255,
  TABLE_NAME: 63,
  TEXT: 4096,
  // The below value is 10MB in bytes
  MAX_EDITABLE_CSV: 10485760,
});

export const validationFields = Object.freeze({
  NAME: 'name',
  COMMENT: 'comment',
  ROW_LIMIT: 'rowLimit',
  URL: 'url',
  PRIMARY_KEY: 'primaryKey',
  INFO: 'info',
  PARSING_ERROR: 'parsingError',
  COLUMNS_MISMATCH: 'columnsMismatch',
});
