import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import DataTabDataset from './data_tab_dataset_view';
import View from '../loading_view';

export default View.extend({
  constructorName: 'DataTabDatasetListView',
  templateName: 'data_tab_dataset_list',
  useLoadingSection: true,

  datasetViewsAreDirty: true,

  events: {
    'click a.more': 'fetchMoreDatasets',
  },

  setup() {
    this.listenTo(this.collection, 'reset', this.markDatasetViewsAsDirty);
    this.listenTo(this.collection, 'add', this.markDatasetViewsAsDirty);
    this.listenTo(this.collection, 'searched', this.rebuildDatasetViews);
    this.datasetViews = [];
  },

  markDatasetViewsAsDirty() {
    this.datasetViewsAreDirty = true;
  },

  postRender() {
    if (this.datasetViewsAreDirty) {
      this.rebuildDatasetViews();
      this.datasetViewsAreDirty = false;
    }
    _.each(this.datasetViews, function addView(view) {
      this.$('ul').append(view.render().$el);
      view.delegateEvents();
    }, this);

    this.setupDragging();

    chorus.search({
      list: this.$('ul'),
      input: this.$('input.search'),
    });
  },

  rebuildDatasetViews() {
    _.each(this.datasetViews, (view) => {
      view.teardown();
    });

    this.datasetViews = [];
    this.collection.each(function addView(model) {
      const datasetView = new DataTabDataset({ model });
      this.datasetViews.push(datasetView);
      this.registerSubView(datasetView);
    }, this);

    this.render();
  },

  fetchMoreDatasets(e) {
    e && e.preventDefault();
    this.trigger('fetch:more');
  },

  setupDragging() {
    this.$('ul.list li').each(function(idx) { 
      this.setAttribute('draggable', 'True');
      this.addEventListener( 'dragstart', function(ev) { 
        ev.dataTransfer.setData('text', this.getAttribute('data-fullname')); 
      });
    });
  },

  additionalContext() {
    const ctx = {};
    if (this.collection.pagination) {
      ctx.showMoreLink = this.collection.pagination.page < this.collection.pagination.total;
    }
    return ctx;
  },

  displayLoadingSection() {
    return !(this.collection && (this.collection.loaded || this.collection.serverErrors));
  },
});
