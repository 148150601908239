import moment from 'moment';
import chorus from '../chorus';
import _ from '../underscore';
import $ from '../jquery';
import t, { I18n } from '../intl';
import Handlebars from '../vendor/handlebars';
import { workspace } from './workspace_assoc';
import { linkTo } from './url_helpers';
import withSearchResults from './search_helpers';
import {
  humanizedDatasetType, relativeTimestamp, unsafeT, breaklinesT, percentage,
  renderErrors, userProfileLink, translate, breaklines, escapeAllowingHtmlTag, moreLink,
} from './helpers';

export const renderTemplate = (templateName, context) => {
  const template = window.HandlebarsTemplates[templateName];
  return new Handlebars.SafeString(template(context, { "allowProtoPropertiesByDefault": true, "allowProtoMethodsByDefault": true  }));
};

const handlebarsHalper = {
  chooserMenu(choices, options) {
    const opts = options.hash;
    const max = opts.max || 20;
    const items = choices || _.range(1, max + 1);
    opts.initial = opts.initial || _.last(items);
    const selected = opts.initial || items[0];
    const translationKey = opts.translationKey || 'dataset.visualization.sidebar.category_limit';
    const className = opts.className || '';
    let markup = `<div class='limiter ${className}'><span class='pointing_l'></span>${t(translationKey)} &nbsp;` +
        `<a href='#'><span class='selected_value'>${selected}</span><span class='triangle'></span></a>` +
        `<div class='limiter_menu_container'><ul class='limiter_menu ${className}'>`;
    _.each(items, (thing) => {
      markup = `${markup}<li>${thing}</li>`;
    });
    markup = `${markup}</ul></div></div>`;
    return new Handlebars.SafeString(markup);
  },

  sqlDefinition(definition) {
    if (!definition) {
      return '';
    }
    const sql = definition || '';
    const promptSpan = $('<span>').addClass('sql_prompt').text(t('dataset.content_details.sql_prompt')).outerHtml();
    const sqlSpan = $('<p>').addClass('sql_content').attr('title', sql).text(sql)
      .outerHtml();
    return new Handlebars.SafeString(t('dataset.content_details.definition', { sql_prompt: promptSpan, sql: sqlSpan }));
  },

  datasetLocation(dataset, label) {
    const locationPieces = [];
    const ws = workspace(dataset);

    if (ws) {
      const workspaceName = ws.name();

      locationPieces.push(linkTo(ws.showUrl(), workspaceName, { class: 'workspace' }).toString());

      const lbl = _.isString(label) ? label : 'dataset.from';
      const translation = t(lbl, { location: locationPieces.join('.') });
      return new Handlebars.SafeString($('<span></span>').html(translation).outerHtml());
    }
    return '';
  },

  humanizedDatasetType(dataset, statistics) {
    return humanizedDatasetType(dataset, statistics);
  },

  ifAny(...args) {
    const items = _.toArray(args);
    const block = items.pop();
    if (block.length === 0) {
      throw new Error('ifAny expects arguments');
    }
    if (_.any(items, arg => !!arg)) {
      return block.fn(this);
    }
    return block.inverse(this);
  },

  eachFunctionResult(...args) {
    const items = _.toArray(args);
    const block = items.pop();
    let results = '';
    const attachments = items[0].apply(this);
    _.each(attachments, (attachment) => {
      results += block.fn(attachment);
    });
    return results;
  },

  eachWithMoreLink(context, max, moreKey, lessKey, showLast, block) {
    let ret = '';
    const ctx = context;

    if (ctx && ctx.length > 0) {
      for (let i = 0, j = ctx.length; i < j; i++) {
        if (showLast) {
          ctx[i].moreClass = (j >= max && i < (j - max)) ? 'more' : '';
        } else {
          ctx[i].moreClass = (i >= max) ? 'more' : '';
        }

        ret += block.fn(ctx[i]);
      }
      ret += moreLink(ctx, max, moreKey, lessKey);
    } else {
      ret = block.inverse(this);
    }
    return ret;
  },

  percentageFromFraction(fraction) {
    return percentage(fraction * 100);
  },

  round(value) {
    if (value > 0.1) {
      const number = 10 ** 2;
      return Math.round(value * number) / number;
    }

    return value;
  },

  fix_to_four(value) {
    return parseFloat(value).toFixed(4);
  },

  displaySearchMatch(attributeName) {
    const attr = withSearchResults(this).get(attributeName, false);
    if (attr) {
      return new Handlebars.SafeString(attr);
    }
    return attr;
  },

  displaySearchMatchFromSafeField(attributeName) {
    const attr = withSearchResults(this).get(attributeName, true);
    if (attr) {
      return new Handlebars.SafeString(attr);
    }
    return attr;
  },

  searchResultCommentTitle(comment) {
    if (comment.isInsight) {
      return t('search.supporting_message_types.insight');
    }
    if (comment.isComment) {
      return t('search.supporting_message_types.comment');
    }
    if (comment.isVersionComment) {
      return t('search.supporting_message_types.version_comment');
    }
    if (comment.subType) {
      return t(`search.supporting_message_types.${comment.subType}`);
    }
    return t('search.supporting_message_types.note');
  },

  pluralize(numberOrArray, key, options) {
    const hash = options && options.hash;
    if (numberOrArray === 1 || numberOrArray.length === 1) {
      return t(key, hash);
    }
    if (I18n.lookup(`${key}_plural`)) {
      return t(`${key}_plural`, hash);
    }
    return `${t(key, hash)}s`;
  },

  encode(value) {
    return encodeURIComponent(value);
  },

  unsafeT(...args) {
    return unsafeT(this, ...args);
  },

  t(...args) {
    return translate(this, ...args);
  },

  hotKeyName(hotKeyChar) {
    return `${_.capitalize(chorus.hotKeyMeta)} + ${hotKeyChar}`;
  },

  displayTagMatch(context, displayedTagName) {
    const highlighted = context.highlightedAttributes;
    let text = displayedTagName;

    if (highlighted && highlighted.tagNames) {
      _.each(highlighted.tagNames, (highlightedTagName) => {
        const plainTagName = highlightedTagName.replace(/<\/?em>/g, '');
        if (text === plainTagName) {
          text = highlightedTagName;
        }
      });
    }

    return escapeAllowingHtmlTag(text, 'em');
  },

  formControls(submitText, cancelText) {
    const text = (cancelText && cancelText.hash) ? 'actions.cancel' : cancelText;
    return renderTemplate('components/form_controls', { submitText, cancelText: text });
  },

  formControlsWithDisabledSubmit(submitText, cancelText) {
    const text = (cancelText && cancelText.hash) ? 'actions.cancel' : cancelText;
    return renderTemplate('components/form_controls', { submitText, cancelText: text, disabled: true });
  },

  formatHelp(text) {
    let txt = Handlebars.Utils.escapeExpression(text);
    const header = "<header class='rsh-sidebar-panel-header'><h4>$1</h4>" +
            "</header><section class='rsh-sidebar-info-help'>$2</section>\r";
    txt = txt.replace(/\n{2}(.*)\n(.*)/gm, header);
    txt = txt.replace(/(.*)\n/gm, "<section class='rsh-sidebar-info-help'>$1</section>");
    return new Handlebars.SafeString(txt);
  },

  breaklinesT(text) {
    return breaklinesT(text);
  },

  breaklines(text) {
    return breaklines(text);
  },

  renderErrors(serverErrors) {
    return renderErrors(serverErrors);
  },

  uploadWidgetFor(propertyName) {
    return renderTemplate('components/upload_widget', { propertyName });
  },

  setChecked(value, currentValue) {
    if (value === currentValue) {
      return 'checked';
    }
    return '';
  },

  setSelected(value, currentValue) {
    if (value === currentValue) {
      return 'selected';
    }
    return '';
  },

  relativeTimestamp(timestamp) {
    return relativeTimestamp(timestamp);
  },

  displayTimestamp(timestamp) {
    const date = moment(timestamp);
    return (timestamp && date.isValid()) ? date.format('MMMM Do YYYY, h:mm a') : '';
  },

  displayDate(timestamp, detailed = false) {
    const date = moment(timestamp);
    const dateFormat = detailed ? 'D MMM YYYY, h:mm:ss a' : 'D MMM YYYY, H:mm';
    return (timestamp && date.isValid()) ? date.format(dateFormat) : '';
  },

  ifTenantAdmin(block) {
    const user = chorus && chorus.session && chorus.session.user();
    if (user && user.get('organisationAdmin')) {
      return block.fn(this);
    }
    return block.inverse(this);
  },

  ifAdmin(block) {
    const user = chorus && chorus.session && chorus.session.user();
    if (user && user.get('admin')) {
      return block.fn(this);
    }
    return block.inverse(this);
  },

  ifAdminOr(flag, block) {
    const user = chorus && chorus.session && chorus.session.user();
    if ((user && user.get('admin')) || flag) {
      return block.fn(this);
    }
    return block.inverse(this);
  },

  userProfileLink(user) {
    return userProfileLink(user);
  },

  usedInWorkspaces(workspaceSet, contextObject) {
    if (!workspaceSet || workspaceSet.length === 0) { return ''; }

    const context = contextObject.clone();
    const workspaces = (workspaceSet instanceof chorus.collections.WorkspaceSet) ?
      workspaceSet :
      new chorus.collections.WorkspaceSet(workspaceSet);


    function linkToContextObject(ws) {
      context.setWorkspace(ws);
      return linkTo(context.showUrl(), ws.get('name'), {
        title: ws.get('name'),
      }).toString();
    }

    const workspaceLink = linkToContextObject(workspaces.at(0));

    const result = $('<div></div>').addClass('found_in workspace_association');

    const otherWorkspacesMenu = linkTo(
      '#',
      t('workspaces_used_in.other_workspaces', { count: workspaces.length - 1 }),
      { class: 'open_other_menu' },
    ).toString();

    result.append(t('workspaces_used_in.body', {
      workspaceLink,
      otherWorkspacesMenu,
      count: workspaces.length,
    }));
    if (workspaces.length > 1) {
      const list = $('<ul></ul>').addClass('other_menu');
      _.each(_.rest(workspaces.models), (ws) => {
        list.append($('<li></li>').html(linkToContextObject(ws)));
      });
      result.append(list);
    }

    return new Handlebars.SafeString(result.outerHtml());
  },

  specialEncodeFolderPaths(folderPath) {
    return folderPath.split('/').map(encodeURIComponent).join('/');
  },

  workfileFoundInWorkspace(workfile) {
    const pathInWorkspace = (`${workfile.attributes.topFolder}/${
      workfile.attributes.relativePath}`).split('/').slice(0, -1).join('/');

    const workspaceUrl = `#/workspaces/${workfile.workspace().id}`;
    const folderUrl = `${workspaceUrl}/folders/${pathInWorkspace}`;

    const folderLink = linkTo(folderUrl, pathInWorkspace, { title: pathInWorkspace }).toString();
    const workspaceLink = linkTo(
      workspaceUrl, workfile.workspace().get('name'),
      { title: workfile.workspace().get('name') },
    ).toString();

    const result = $('<div></div>').addClass('found_in workspace_association');
    result.append(t('workfile_found_in_folder', { workspaceLink, folderLink }));

    return new Handlebars.SafeString(result.outerHtml());
  },

  termsTextWithURL() {
    return t('airlock.terms_and_conditions.text', { url: t('url.knowledgebase_terms') });
  },
};

_.each(handlebarsHalper, (helper, name) => {
  Handlebars.registerHelper(name, helper);
});
