import I18n, { t } from 'i18n-js';
import { capitalise } from './helper';

const serverErrorMessage = (err) => {
  let message;

  if (!err) { return message; }

  if (err.info?.errors?.fields) {
    Object.entries(err.info.errors.fields).forEach((fieldsEntry) => {
      const [field, error] = fieldsEntry;
      Object.entries(error).forEach((errorEntry) => {
        const [errorContext, context] = errorEntry;
        const fullKey = `field_error.${field}.${errorContext}`;
        const genericKey = `field_error.${errorContext}`;

        if (I18n.lookup(fullKey)) {
          message = t(fullKey, context);
        } else if (context) {
          const ctx = context;
          ctx.field = field;
          message = t(genericKey, ctx);
        }
      });
    });
  } else if (err.info?.errors?.record) {
    const fullKey = `record_error.${err.info.errors.record}`;

    if (I18n.lookup(fullKey)) {
      message = t(fullKey, err.info.errors);
    }
  } else if (err.info?.errors?.service) {
    const fullKey = `service_error.${err.info.errors.service}`;

    if (I18n.lookup(fullKey)) {
      message = t(fullKey);
    }
  }
  if (err.info?.errors?.message) {
    const errorNumber = err.info.errors.message[0].split('(').pop().split(')')[0];
    const fullKey = `azure_errors.${errorNumber}`;
    if (I18n.lookup(fullKey)) {
      message = t(fullKey);
    } else {
      message = err.info.errors.message;
    }
  }
  // TODO error returned if file got a lease in azure.
  // might need to be extended to cover more cases
  if (err.info?.status) {
    const serverError = err.info.status.toUpperCase();
    const fullKey = `field_error.${serverError}`;
    if (I18n.lookup(fullKey)) {
      message = t(fullKey);
    }
  }
  if (err.info?.error) {
    const serverError = err.info.error.toUpperCase();
    const fullKey = `field_error.${serverError}`;
    if (I18n.lookup(fullKey)) {
      message = t(fullKey);
    }
  }

  if (/PSQLException/i.test(message)) {
    if (/depend/i.test(message)) {
      message = t('psqlexception.depend');
    } else if (/duplicate/i.test(message)) {
      message = t('psqlexception.duplicate');
    } else if (/does not exist/i.test(message)) {
      message = t('psqlexception.does_not_exist');
    } else {
      const msg = capitalise(message.slice(21));
      message = t('psqlexception.generic', { msg });
    }
  }

  if (!message) {
    message = t('field_error.ERROR');
  }

  return message;
};

export default serverErrorMessage;
