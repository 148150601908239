import React from 'react';
import { Popup, List } from 'semantic-ui-react';
import { getUserName } from '../../adapters/user';
import { useWorkspaceContext, useWorkspaceAdmin } from '../../adapters/Workspace';
import { UserAvatar } from '../../components/avatars/Avatars';

const AvatarDetails = ({ member }) => (
  <>
    <Popup.Header>{`${member.first_name} ${member.last_name}`}</Popup.Header>
    <Popup.Content>
      <List>
        {member.invited && <List.Item className="small-text">INVITATION PENDING</List.Item>}
        <List.Item>{member.email}</List.Item>
        <List.Item className="italic">
          Role:
          {member.role}
        </List.Item>
      </List>
    </Popup.Content>
  </>
);

const HomeUserList = () => {
  const { workspaceUuid } = useWorkspaceContext();
  const { workspace } = useWorkspaceAdmin(workspaceUuid);
  const popupWrapper = document.getElementById('popup-wrapper');

  return (
    <div className="flex-wrap" role="list">
      {workspace?.members.map((member) => (
        <Popup
          key={member.email}
          mountNode={popupWrapper}
          size="small"
          trigger={UserAvatar({ userName: getUserName(member), invited: member.invited })}
        >
          <AvatarDetails member={member} />
        </Popup>
      ))}
    </div>
  );
};

export default HomeUserList;
