import Backbone from '../vendor/backbone';
import MiniAppSet from './mini_app';
import MiniAppPods from './mini_app_pods';
import User from '../models/user';
import { getManagementWorkspace } from '../models/management/workspace';

const MiniAppsAndPods = Backbone.Collection.include(Backbone.Events).extend({
  initialize(_models, options) {
    this.miniApps = new MiniAppSet([], { workspaceID: options.workspace.id });
    this.pods = new MiniAppPods([], { tokenPromise: options.workspace.token() });

    this.managementWorkspace = getManagementWorkspace(options.workspace.get('uuid'));
    this.managementWorkspace.fetchIfNotLoaded();

    this.listenTo(this.miniApps, 'sync', this.miniAppsSynced);
  },

  miniAppsSynced() {
    this.reset(this.miniApps.models);
    this.trigger('sync');
  },

  mergeModels() {
    this.set(this.miniApps.models);
    const { pods } = this;
    const members = this.managementWorkspace.get('members');

    this.forEach((model) => {
      const app = pods.findWhere({ name: model.get('name') });
      if (app) {
        const startedBy = members.find(m => m.uuid === app.get('userUuid'));
        const startedByUser = startedBy ? new User(startedBy) : null;
        model.set({
          running: app.get('status') === 'ready',
          loading: ['loading', 'pulling'].includes(app.get('status')),
          currentStatus: app.get('status'),
          image: app.get('image'),
          user: app.get('userUuid'),
          startedByUser,
        });
      } else {
        model.set({
          running: false,
          loading: false,
          currentStatus: 'unknown',
          image: '',
          user: '',
          startedByUser: null,
        });
      }
    });
    this.trigger('appsLoaded');
  },

  async fetchIfNotLoaded(options) {
    try {
      await Promise.all([this.miniApps.fetchIfNotLoaded(options), this.pods.fetchIfNotLoaded(options)]);
      this.statusCode = this.miniApps.statusCode;
      this.mergeModels();
    } catch (err) {
      console.log(`Error retrieving status of running Mini-Apps: ${err}`);
      this.forEach((model) => {
        model.set({
          running: false,
          loading: false,
          statusError: true,
        });
      });
    }
    this.trigger('appsLoaded');
  },

  async fetch(options) {
    try {
      await Promise.all([this.miniApps.fetch(options), this.pods.fetch(options)]);
      this.statusCode = this.miniApps.statusCode;
      this.mergeModels();
    } catch (err) {
      console.log(`Error retrieving status of running Mini-Apps: ${err}`);
      this.forEach((model) => {
        model.set({
          running: false,
          loading: false,
          statusError: true,
        });
      });
    }
    this.trigger('appsLoaded');
  },
});

export default MiniAppsAndPods;
