import Backbone from '../vendor/backbone';
import _ from '../underscore';
import Airlock from './airlock';

export default Backbone.Model.extend({
  nextSection() {
    this.set('activeSection', (this.get('activeSection') + 1) % 2);
  },

  addFiles(newFiles) {
    const f = this.files();
    _.each(newFiles, (i) => {
      if (!i.isFolder()) {
        f[i.id] = {
          id: i.id,
          name: i.get('fileName'),
          folder: i.get('folder'),
          iconUrl: i.iconUrl(),
        };
      }
    });
    this.trigger('change:files');
  },

  addDatasets(newDatasets) {
    const d = this.datasets();
    _.each(newDatasets, (i) => {
      d[i.get('id')] = {
        id: i.id,
        name: i.name(),
        iconUrl: i.iconUrl(),
      };
    });
    this.trigger('change:datasets');
  },

  removeFile(id) {
    delete this.files()[id];
    this.trigger('change:files');
  },

  removeDataset(id) {
    delete this.datasets()[id];
    this.trigger('change:datasets');
  },

  createRequest(autoApprove) {
    const airlock = new Airlock({
      workspaceId: this.get('sections')[1].currentWorkspace,
      datasetIds: _.keys(this.datasets()),
      workfileIds: _.keys(this.files()),
      destination: this.get('destination'),
      reason: this.get('reason'),
    });
    if (this.get('destination') === 'workspace') {
      airlock.set('destination_uuid', this.get('workspace'));
    }
    if (autoApprove) {
      airlock.set('status', 'approved_pending');
    }
    this.set('request', airlock, { silent: true });
    return airlock;
  },

  datasets() {
    return this.get('datasets');
  },

  files() {
    return this.get('files');
  },

  isComplete() {
    return this.get('activeSection') === 2 && this.get('request').loaded;
  },

  reset() {
    this.set({
      files: {},
      datasets: {},
      reason: '',
      activeSection: 0,
    });
  },

  updateDestinations(destinations) {
    const destinationSection = this.get('sections')[1];
    destinationSection.visibleWorkspaces = destinations;

    if (this.get('activeSection') === 1) {
      // setting the visible workspaces will not trigger a change due to not using set
      // so if the activeSection is 1 then we should trigger a change so the view can
      // be re-rendered
      this.trigger('change:activeSection', 1);
    }
  },

  viewContext() {
    const ctx = {
      sections: _.map(this.get('sections'), _.clone),
    };

    const activeSection = this.get('activeSection');
    ctx.sections[activeSection].active = true;
    ctx.sections[ctx.sections.length - 1].last = true;

    if (activeSection === 0) {
      const contentSection = ctx.sections[0];
      contentSection.hasContent = (_.size(this.files()) + _.size(this.datasets())) > 0;
      contentSection.files = _.values(this.files());
      contentSection.datasets = _.values(this.datasets());
    } else if (activeSection === 1) {
      const destinationSection = ctx.sections[1];
      const workspacesLoading = destinationSection.visibleWorkspaces === null;
      const workspace = this.get('workspace');
      const workspaceRequired = this.get('destination') === 'workspace';
      const selectedWorkspace =
        workspaceRequired &&
        !workspacesLoading &&
        destinationSection.visibleWorkspaces.find(w => w.uuid === workspace);
      let guidance = false;
      if (selectedWorkspace && selectedWorkspace.guidance) {
        guidance = 'airlock.guidance.region_change';
      }
      destinationSection.destination = this.get('destination');
      destinationSection.workspace = workspace;
      destinationSection.reason = this.get('reason');
      destinationSection.nonce = this.get('nonce');
      destinationSection.workspacesLoading = workspacesLoading;
      destinationSection.disableCopy = workspacesLoading || destinationSection.visibleWorkspaces.length === 0;
      destinationSection.workspaceRequired = workspaceRequired;
      destinationSection.canApproveAirlock = this.get('canApproveAirlock');
      destinationSection.guidance = guidance;
      destinationSection.selectedWorkspace = selectedWorkspace;
    } else if (activeSection === 2) {
      const completeSection = ctx.sections[2];
      const request = this.get('request');
      if (request) {
        completeSection.loaded = request.loaded;
        completeSection.errors = request.errors;
        _.extend(completeSection, request.attributes);
        completeSection.auto_approved = completeSection.status === 'approved_pending';
      }
    }

    return ctx;
  },
});
