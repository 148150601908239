import _ from '../../underscore';
import SearchItemBase from './search_item_base';
import WorkspaceDataset from '../../models/workspace_dataset';

export default SearchItemBase.extend({
  constructorName: 'SearchDatasetView',
  templateName: 'search_dataset',

  additionalContext() {
    const context = _.extend(this._super('additionalContext'), {
      dataset: new WorkspaceDataset(this.model),
      showUrl: this.model.showUrl(),
      iconUrl: this.model.iconUrl(),
    });

    if (this.model.get('workspaces')) {
      context.workspaces = this.model.get('workspaces');
    } else if (this.model.get('workspace')) {
      context.workspaces = [this.model.get('workspace')];
    }

    return context;
  },

  postRender() {
    this._super('postRender');

    this.$('a.data_source, a.database').data('data_source', this.model.get('data_source'));
    this.menu(this.$('.location .found_in a.open_other_menu'), {
      content: this.$('.other_menu'),
      classes: 'found_in_other_workspaces_menu',
    });
  },
});
