import React from 'react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { Dashboard } from '@uppy/react';
import {
  Checkbox, Form, Step, Header, Grid,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import './InboundAirlock.less';
import { SaveButton, CancelButton } from '../Buttons';
import Loading from '../Loading';

// Options for the connectBackboneToReact function are specified in multiple places for the inboundAirlock
// model, this reduces some of the duplication
function opts() {
  return {
    debounce: true,
    events: {
      // eslint-disable-next-line prefer-rest-params
      inboundAirlock: Array.prototype.map.call(arguments, (s) => (`change:${s}`)),
    },
  };
}

// Steps
function createStepItems(step) {
  const steps = [
    { key: 'step1', icon: 'hand point up outline', title: t('inbound_airlock.step1.text') },
    { key: 'step2', icon: 'upload', title: t('inbound_airlock.step2.text') },
    { key: 'step3', icon: 'check circle outline', title: t('inbound_airlock.step3.text') },
  ];
  steps[step].active = true;

  return steps;
}

const stepProps = ({ inboundAirlock }) => ({
  items: createStepItems(inboundAirlock.get('step')),
  fluid: true,
  size: 'mini',
  unstackable: true,
});

const Steps = connectBackboneToReact(stepProps, opts('step'))(Step.Group);

// Destination
const destinationProps = ({ inboundAirlock, launchSelectDestination }) => ({
  folder: inboundAirlock.get('folder'),
  sendToInbox: inboundAirlock.get('sendToInbox'),
  sendToPublicInbox: inboundAirlock.get('sendToPublicInbox'),
  launchSelectDestination,
});

const formatBreadcrumb = (folder) => (folder.charAt(0).toUpperCase() + folder.slice(1));

const DestinationDisplay = ({
  folder, sendToInbox, sendToPublicInbox, launchSelectDestination,
}) => (
  <Header as="h4" className="destination">
    {`${t('inbound_airlock.destination')}`}
    {!sendToInbox && !sendToPublicInbox
      && <a onClick={launchSelectDestination}>{ formatBreadcrumb(folder) }</a>}
    {(sendToInbox || sendToPublicInbox)
      && <span>{ formatBreadcrumb(folder) }</span>}
  </Header>
);

const Destination = connectBackboneToReact(destinationProps, opts('folder', 'sendToInbox', 'sendToPublicInbox'))(DestinationDisplay);

// Send to Inbox Checkbox
const sendToInboxProps = ({ inboundAirlock }) => ({
  checked: inboundAirlock.get('sendToInbox'),
  disabled: inboundAirlock.get('uploadToInboxOnly'),
  onChange: (_e, { checked }) => {
    inboundAirlock.updateSendToInbox(checked);
  },
  name: 'sendToInbox',
  label: t('inbound_airlock.step1.send_to_inbox'),
});

const SendToInboxCheckbox = connectBackboneToReact(sendToInboxProps, opts('sendToInbox'))(Checkbox);

// Send to Public Inbox Checkbox
const sendToPublicInboxProps = ({ inboundAirlock }) => ({
  checked: inboundAirlock.get('sendToPublicInbox'),
  onChange: (_e, { checked }) => {
    inboundAirlock.updateSendToPublicInbox(checked);
  },
  name: 'sendToPublicInbox',
  label: t('inbound_airlock.step1.send_to_public_inbox'),
});

const SendToPublicInboxCheckbox = connectBackboneToReact(sendToPublicInboxProps, opts('sendToPublicInbox'))(Checkbox);

// PII Checkbox
const checkboxProps = ({ inboundAirlock }) => ({
  disabled: !inboundAirlock.get('hasFiles'),
  checked: inboundAirlock.get('agreeToPII'),
  onChange: (_e, { checked }) => {
    inboundAirlock.updateAgreeToPII(checked);
  },
  name: 'pIIConfirm',
  label: <label dangerouslySetInnerHTML={{ __html: t('inbound_airlock.step1.confirm') }} />,
});

const PiiCheckbox = connectBackboneToReact(checkboxProps, opts('hasFiles', 'agreeToPII'))(Checkbox);

// ConfirmUpload Button Section
const saveButtonProps = ({ inboundAirlock }) => ({
  saveText: t('inbound_airlock.upload'),
  onSave: inboundAirlock.upload,
  minimumDataEntryMet: inboundAirlock.get('readyToUpload'),
  size: 'large',
  iconName: 'upload',
});

const ConfirmUpload = connectBackboneToReact(saveButtonProps, opts('readyToUpload'))(SaveButton);

const LeftCheckboxProps = ({ inboundAirlock }) => ({
  canUploadToInbox: inboundAirlock.get('canUploadToInbox'),
});

const LeftCheckboxDisplay = ({ canUploadToInbox }) => (
  <>
    {canUploadToInbox
      ? <SendToInboxCheckbox />
      : <SendToPublicInboxCheckbox />}
  </>
);

const LeftCheckbox = connectBackboneToReact(LeftCheckboxProps)(LeftCheckboxDisplay);

const LeftActions = ({ launchSelectDestination }) => (
  <Grid.Column>
    <Destination launchSelectDestination={launchSelectDestination} />
    <LeftCheckbox />
  </Grid.Column>
);

const RightActions = () => (
  <>
    <Grid.Column>
      <PiiCheckbox />
    </Grid.Column>
    <Grid.Column textAlign="right">
      <ConfirmUpload />
    </Grid.Column>
  </>
);

// Action sections
const SelectYourFilesActions = ({ launchSelectDestination }) => (
  <Grid columns={3} divided>
    <Grid.Row>
      <LeftActions launchSelectDestination={launchSelectDestination} />
      <RightActions />
    </Grid.Row>
  </Grid>
);

const CompleteActions = ({ onClose }) => (
  <Grid columns={2} divided>
    <Grid.Row>
      <Grid.Column>
        {t('inbound_airlock.step3.message')}
      </Grid.Column>
      <Grid.Column textAlign="right">
        <CancelButton cancelText={t('actions.close')} onClose={onClose} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

// Airlock
function InboundAirlockContainer({
  canaryCallDone, step, uppy, onClose, launchSelectDestination,
}) {
  if (canaryCallDone) {
    return (
      <div className="main_content semantic inboundairlock">
        <Steps />
        <Form>
          <Dashboard
            uppy={uppy}
            proudlyDisplayPoweredByUppy={false}
            hideUploadButton
            locale={{
              strings: {
                dropPaste: `${t('inbound_airlock.step1.drop_files')} %{browse}`,
                browse: t('inbound_airlock.step1.browse'),
                cancel: t('inbound_airlock.step1.clear'),
              },
            }}
            doneButtonHandler={onClose}
          />
        </Form>
        {step === 0 && <SelectYourFilesActions launchSelectDestination={launchSelectDestination} /> }
        {step === 2 && <CompleteActions onClose={onClose} /> }
      </div>
    );
  }
  return (
    <Loading />
  );
}

const airlockProps = ({ inboundAirlock }) => ({
  step: inboundAirlock.get('step'),
  uppy: inboundAirlock.get('uppy'),
  canaryCallDone: inboundAirlock.get('canaryCallDone'),
});

export default connectBackboneToReact(airlockProps, opts('canaryCallDone', 'step'))(InboundAirlockContainer);
