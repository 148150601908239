import { useQuery } from '@tanstack/react-query';
import { workspaceTokenGetter } from '../adapters/service';
import { virtualMachinesUrl } from '../constants/api_urls';

export const queryKey = ['virtualMachines'];
const defaultOptions = {
  staleTime: 10000,
};

export const useVirtualMachines = (options = {}) => {
  const fetchVirtualMachines = async () => workspaceTokenGetter(virtualMachinesUrl);
  return useQuery({
    queryKey,
    queryFn: fetchVirtualMachines,
    ...defaultOptions,
    ...options,
  });
};
