import VirtualMachines from '../../../../virtual_machines';
import { ResponsivePanelContext } from '../../ResponsivePanel';

const VirtualMachinesPanel = () => (
  <ResponsivePanelContext>
    <VirtualMachines />
  </ResponsivePanelContext>
);

export default VirtualMachinesPanel;
