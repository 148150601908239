import chorus from '../../chorus';
import $ from '../../jquery';
import WorkfileContent from './workfile_content_view';
import { instance } from '../../models/config';

export default WorkfileContent.extend({
  templateName: 'pdf_workfile_content',

  additionalContext() {
    return {
      overMaxRenderFilesize: this.model.attributes.overMaxRenderFilesize,
      pdfSourceUrl: encodeURIComponent(this.model.get('versionInfo').contentUrl),
      nativeRenderer: this.rendererIs('native'),
      javascriptRenderer: this.rendererIs('javascript'),
    };
  },

  postRender() {
    if (this.model.attributes.overMaxRenderFilesize) { return; }
    this.recalculatePdfViewport();
    this.listenTo(chorus.page, 'resized', this.recalculatePdfViewport);
  },

  recalculatePdfViewport() {
    const container = $(this.el).find('.pdf_container');
    const height = Math.max($(window).height() - container.offset().top - 30, 330);
    container.css('max-height', height);
    container.css('height', height);
    const pdfIframe = document.getElementById('pdfiframeloaded');
    if (pdfIframe) {
      this.delay(3000).then(() => {
        const element = pdfIframe.contentWindow.document.querySelector('body');
        element.addEventListener('copy', () => {
          window.parent.postMessage('frameCopyEvent', '*');
        });
        element.addEventListener('cut', () => {
          window.parent.postMessage('frameCopyEvent', '*');
        });
      });
    }
  },

  rendererIs(renderer) {
    return instance.get('pdfRendererWorkfile') === renderer;
  },

  delay(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  },
});
