import _ from '../../underscore';
import t from '../../intl';
import Task from './task';

export default Task.extend({
  constructorName: 'ChartTask',
  urlTemplateBase: 'api/workspaces/datasets/{{datasetId}}/visualizations',

  name() {
    return t('dataset.visualization.data.filename');
  },

  initialize(attrs, ...args) {
    this._super('initialize', [attrs, ...args]);
    this.dataset = this.get('dataset');
    this.unset('dataset');

    if (this.dataset) {
      this.set({ datasetId: this.dataset.get('id') }, { silent: true });
    }
    this.set({ type: this.chartType });
  },

  getColumnLabel(columnName) {
    return this.columnLabels[columnName] ? t(this.columnLabels[columnName]) : columnName;
  },

  getRows() {
    const rows = this.get('rows');
    const columns = this.getColumns();

    return _.map(rows, (row) => {
      _.each(columns, (column) => {
        const assignableRow = row;
        assignableRow[column.uniqueName] = assignableRow[column.name];
      });
      return row;
    });
  },
});
