import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import { instance } from '../../models/config';
import View from '../loading_view';
import { idToken } from '../../auth/oauth';

export default View.extend({
  constructorName: 'ImageUploadView',
  templateName: 'image_upload',

  events: {
    'click a.image_upload': 'openFileDialog',
    'click a.image_remove': 'removeImage',
  },

  additionalContext() {
    return {
      isTenantAdmin: this.isTenantAdmin,
      imagePermission: (this.model.get('entityType') !== 'user' || this.model.isSessionUser()),
      imageUrl: this.model.fetchImageUrl(),
      hasImage: this.model.hasImage(),
      addImageKey: this.addImageKey,
      changeImageKey: this.changeImageKey,
      removeImageKey: this.removeImageKey,
      editable: this.editable,
    };
  },

  setup(options) {
    this.isTenantAdmin = chorus.session.user().isTenantAdmin();
    this.addImageKey = options.addImageKey;
    this.changeImageKey = options.changeImageKey;
    this.removeImageKey = options.removeImageKey;
    this.spinnerSmall = options.spinnerSmall;
    this.editable = options.editable || !('editable' in options);
    this.config = instance;
  },

  openFileDialog() {
    this.$('#image_upload_input').click();
  },

  removeImage() {
    idToken().then((token) => {
      $.ajax({
        url: `api/workspaces/workspaces/${this.model.get('id')}/image`,
        type: 'DELETE',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }).done(() => {
        this.model.set('image', null);
      }).fail((xhr) => {
        chorus.toast('workspace.settings.image.remove_failed', { toastOpts: { theme: 'bad_activity' } });
      });
    });
  },

  postRender() {
    const self = this;

    function reEnableUpload() {
      self.$('.app-spinner').remove();
      self.$('img').show();
      self.$('input[type=file]').prop('disabled', false);
      self.$('a.action').removeClass('disabled');
      chorus.updateCachebuster();
    }

    function fileSelected(e, data) {
      self.uploadObj = data;
      // Insert a spinner...
      const spinner = "<div class='rsh-spinner app-spinner'><i class='fa fa-ellipsis-h fa-spin'></i></div>";

      self.$('img').hide().after(spinner);
      self.$('input[type=file]').prop('disabled', true);
      self.$('a.action').addClass('disabled');

      if (self.validFileSize()) {
        data.submit();
      } else {
        self.model.trigger('validationFailed');
        reEnableUpload();
      }
    }

    function uploadFinished(e, data) {
      reEnableUpload();
      const json = JSON.parse(data.result);
      delete self.resource.serverErrors;
      self.resource.trigger('validated');
      self.model.set({ image: json }, { silent: true });
      self.model.trigger('image:change');
      self.$('img').attr('src', self.model.fetchImageUrl());
      self.$('img').show();
    }

    function uploadFailed(e, json) {
      reEnableUpload();
      if (json.jqXHR.responseText) {
        const { errors } = JSON.parse(json.jqXHR.responseText);
        if (errors.fields.image_file_size && errors.fields.image_file_size.LESS_THAN) {
          const { count } = errors.fields.image_file_size.LESS_THAN;
          errors.fields.image_file_size.LESS_THAN.count = `${count.split(' ')[0] / 1024 / 1024} MB`;
        }
        self.resource.serverErrors = errors;
      }
      self.resource.trigger('saveFailed');
    }

    const multipart = !window.jasmine;
    idToken().then((token) => {
      this.$('input[type=file]').fileupload({
        url: this.model.createImageUrl(),
        type: 'POST',
        add: fileSelected,
        done: uploadFinished,
        fail: uploadFailed,
        multipart,
        dataType: 'text',
        pasteZone: null,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    });

    if (this.model.hasImage()) {
      this.$('img').show();
    } else {
      this.$('img').hide();
    }
  },

  validFileSize() {
    this.clearErrors();
    if (!this.model) return true;
    const maxImageSize = this.model.maxImageSize();
    delete this.model.serverErrors;

    let valid = true;
    _.each(this.uploadObj.files, function checkSize(file) {
      if (file.size > (maxImageSize * 1024 * 1024)) {
        this.model.serverErrors = { fields: { base: { FILE_SIZE_EXCEEDED: { count: maxImageSize } } } };
        this.showErrors(this.model);
        valid = false;
      }
    }, this);

    return valid;
  },
});
