import {
  Button, Dimmer, Icon, Loader, Message,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import './remote_desktop.less';
import { VM } from '../constants';

/**
 * Button for triggering paste event from client to remote desktop
 *
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */
export const VMPaste = ({ onClick }) => (
  <Button title={t('desktop.paste')} className="guacamole-paste" icon compact onClick={onClick}>
    <Icon name="paste" color="blue" />
  </Button>
);

/**
 * Direct wrapper for the guacamole element which will get inserted as a child once mounted.
 *
 * ! Warning: this is not a proper react component and is just a function
 *
 * @param ref
 * @returns {JSX.Element}
 * @constructor
 */
export const VMDisplay = (ref) => <div className="guacamole-display" ref={ref} />;

/**
 * Container for the panel, the class should ensure that the panel takes up the full size available
 * and the ref is used for measuring the available size available for the display.
 *
 * ! Warning: this is not a react component although can be converted into one by wrapping
 *   with React.forwardRef
 *
 * @param ref
 * @returns {JSX.Element}
 * @constructor
 */
export const FullHeightDisplay = ({
  fullScreenButton, pasteButton, display, overlay,
}, ref) => (
  <div className="semantic guacamole" ref={ref}>
    <Button.Group size="small" className="floating">
      {fullScreenButton}
      {pasteButton}
    </Button.Group>
    {display}
    {overlay}
  </div>
);

/**
 * Displays a loading message in a Dimmer
 *
 * @param state
 * @returns {JSX.Element}
 * @constructor
 */
export const OverlayMessage = ({ message, inverted = true }) => (
  <Dimmer inverted={inverted} active>
    {message}
  </Dimmer>
);

/**
 * Displays a loading message in a Dimmer
 *
 * @param state
 * @returns {JSX.Element}
 * @constructor
 */
export const OverlayLoadingMessage = ({ message }) => (
  OverlayMessage({ message: <Loader inverted size="big" content={message} /> })
);

export const OverlayWarningMessage = ({ header, message }) => (
  OverlayMessage({ message: <Message icon="warning circle" warning header={header} content={message} className="text-align-left" />, inverted: false })
);

export const Reconnect = ({ reconnect }) => (
  <Button positive icon="plug" onClick={reconnect} content={t('desktop.reconnect')} />
);

export const DisconnectedMessage = ({ vmStatus, reconnect }) => {
  switch (vmStatus) {
    case VM.NOT_FOUND:
    case VM.POWERED_OFF:
      return OverlayWarningMessage({ header: t('desktop.powered_off.header'), message: t('desktop.powered_off.content') });
    case VM.UNHEALTHY:
      return OverlayWarningMessage({ header: t('desktop.unhealthy.header'), message: t('desktop.unhealthy.content') });
    case VM.HEALTHY:
      return OverlayMessage({ message: Reconnect({ reconnect }) });
    default:
      return OverlayLoadingMessage({ message: t('desktop.fetching') });
  }
};
