import chorus from '../../chorus';
import _ from '../../underscore';
import t from '../../intl';
import Dialog from '../../dialogs/dialogs';
import Workfile from '../../models/workfile';
import DialogFormHelpers from '../../mixins/dialog_form_helpers';
import { UnsafeDirectoryName } from '../../../utilities/validation_regexes';

export default Dialog.include(DialogFormHelpers).extend({
  constructorName: 'WorkfileExtractFiles',

  templateName: 'workfile_extract_files',
  title: t('workfiles.extract_files.title'),

  persistent: true,

  makeModel() {
    this.model = this.options.workfile;
    this.listenTo(this.model, 'saved', this.saved);
    this.listenTo(this.model, 'saveFailed', this.saveFailed);
  },

  setup(options) {
    this.disableFormUnlessValid({
      formSelector: 'form',
      inputSelector: 'input[name=folderName]',
      checkInput: () => {
        this.$('.errors').addClass('hidden');
        if (this.$("input[name=folderName]").val().match(UnsafeDirectoryName())) {
          this.showDialogError(t('field_error.folder_name.INVALID', { value: this.$("input[name=folderName]").val()}));
          return false;
        }
        return true;
      },
    });
    _.defer(() => {
      this.toggleSubmitDisabled();
    });
  },

  additionalContext() {
    return {
      destination: this.model.get('folder'),
      folderName: this.defaultFolderName()
    };
  },

  defaultFolderName() {
    const name = this.model.getFileName().split(".")[0];
    return name.replace(UnsafeDirectoryName(), "");
  },

  create() {
    let folderName = this.$('input[name=folderName]').val().trim();
    this.model.set({
      extract: true,
      destinationFolder: `${this.model.get('folder')}/${folderName}`,
      removeZip: this.$('input[name=removeZip]').prop('checked'),
      versionInfo: null,
    }, { silent: true });
    this.$('button.submit').startLoading('actions.adding');
    this.model.save({ source: 'empty' });
  },

  saved() {
    this.closeModal();
    chorus.toast('workfiles.extract_files.succeeded');
  },

  saveFailed() {
    this.$('button.submit').stopLoading();
    chorus.toast('workfiles.extract_files.failed', {
      toastOpts: { theme: 'bad_activity' },
    });
  },
});
