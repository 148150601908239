import Model from './models';

export default Model.extend({
  constructorName: 'Desktop',

  urlTemplate(options) {
    return 'api/guac/vm';
  },

  start(token) {
    this.save({ action: 'start' }, { method: 'create', token });
  },

  stop(token) {
    this.save({ action: 'stop' }, { method: 'create', token });
  },

  iconUrl() {
    return `/images/${this.get('os').toLowerCase()}.svg`;
  },
});
