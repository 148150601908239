/* eslint-disable react/no-array-index-key */
import { Table } from 'semantic-ui-react';

const AuditTableRow = ({ row, index }) => (
  <Table.Row>
    {row.map((value, j) => (
      <Table.Cell
        className="two-line-truncate"
        key={`row-${index}-cell-${j}`}
      >
        <div title={value}>{value}</div>
      </Table.Cell>
    ))}
  </Table.Row>
);

export default AuditTableRow;
