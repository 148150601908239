import Activity from './activity';

export default Activity.extend({
  constructorName: 'Note',
  entityType: 'note',

  urlTemplate(options) {
    if (options && options.isFile) {
      return 'api/workspaces/notes/{{id}}/attachments';
    }
    return 'api/workspaces/notes/{{id}}';
  },

  declareValidations(newAttrs) {
    this.require('body', newAttrs);
  },

  attrToLabel: {
    body: 'notes.body',
  },

  initialize(...args) {
    this._super('initialize', args);
    this.files = [];
  },

  beforeSave() {
    if (this.datasets) {
      this.set({ datasetIds: this.datasets.pluck('id') }, { silent: true });
    }
    if (this.workfiles) {
      this.set({ workfileIds: this.workfiles.pluck('id') }, { silent: true });
    }
  },
});
