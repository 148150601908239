/* global XMLSerializer:false */

import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import VisualizationNotesNew from './visualization_notes_new';
import Menu from '../../views/core/menu';
import ResultsConsole from '../../views/workfiles/results_console_view';
import DatasetFilterWizard from '../../views/dataset/dataset_filter_wizard_view';
import Dialog from '../../dialogs/dialogs';
import Workfile from '../../models/workfile';
import { workspace } from '../../utilities/workspace_assoc';
import EmptyDataWarning from '../../views/visualizations/empty_data_warning';
import boxplot from '../../views/visualizations/boxplot_view';
import frequency from '../../views/visualizations/frequency_view';
import heatmap from '../../views/visualizations/heatmap_view';
import histogram from '../../views/visualizations/histogram_view';
import timeseries from '../../views/visualizations/time_series_view';
import WorkfileNewDialog from '../workfiles/workfile_new_dialog';

const Charts = {
  boxplot,
  frequency,
  heatmap,
  histogram,
  timeseries,
};

const buildForType = (chartType, options) => new Charts[chartType](options);

export default Dialog.extend({
  constructorName: 'Visualization',
  templateName: 'visualization',
  additionalClass: 'dialog_wide',

  subviews: {
    '.tabledata': 'resultsConsole',
    '.filter_options': 'filterWizard',
  },

  events: {
    'click a.show': 'showDataGrid',
    'click a.hide_btn': 'hideDataGrid',
    'click a.show_options': 'showFilterOptions',
    'click a.hide_options': 'hideFilterOptions',
    'click button.close_dialog': 'closeModal',
    'click button.refresh': 'refreshChart',
    'click .overlay:not(.disabled)': 'refreshChart',
    'click button.revert': 'revertFilters',
    'click button.stop': 'cancelRefresh',
  },

  setup() {
    this.task = this.options.task;
    this.requiredResources.add(workspace(this.model));
    this.type = this.options.chartOptions.type;
    this.title = t('visualization.title', { name: this.options.chartOptions.name });
    this.filters = this.options.filters.clone();
    this.lastSavedFilters = this.options.filters.clone();
    this.filterWizard = new DatasetFilterWizard({
      collection: this.filters,
      columnSet: this.options.columnSet,
    });

    this.configureResultsConsole();
    this.listenTo(this.filters, 'add remove change', this.filtersChanged);
  },

  postRender() {
    this.resultsConsole.initializeDataGrid(this.task);
    this.resultsConsole.$('.expander_button').remove();
    this.$(`.chart_icon.${this.type}`).addClass('selected');

    const menuItems = [
      {
        name: 'save_as_workfile',
        text: t('visualization.save_as_workfile'),
        onSelect: _.bind(this.saveAsWorkfile, this),
      },
      {
        name: 'save_as_note',
        text: t('visualization.save_as_note'),
        onSelect: _.bind(this.saveAsNoteAttachment, this),
      },
    ];

    this.menuView && this.menuView.teardown();

    this.menuView = new Menu({
      launchElement: this.$('button.save'),
      orientation: 'right',
      items: menuItems,
    });
    this.registerSubView(this.menuView);

    this.drawChart();
  },

  getAvailableHeight() {
    const maxPopupHeight = $('#facebox').find('.popup').css('max-height').replace('px', '');
    return maxPopupHeight - (this.$el.height() - this.resultsConsole.$('.result_table').height());
  },

  configureResultsConsole() {
    const resultsConsoleOptions = {
      enableResize: false,
      enableClose: false,
      enableExpander: false,
      hideExecution: true,
      model: this.task,
      footerSize: _.bind(this.footerSize, this),
      boundingContainer: this,
    };
    this.resultsConsole = new ResultsConsole(resultsConsoleOptions);
  },

  drawChart() {
    if (this.isValidData()) {
      if ((this.type === 'timeseries') && !this.isSufficientDataForTimeseries()) {
        this.emptyDataWarning = new EmptyDataWarning({
          message: t('visualization.insufficient_data'),
        });
        this.subviews['.chart_area'] = 'emptyDataWarning';
        this.renderSubview('emptyDataWarning');
      } else {
        this.$('.form_controls a.hide').addClass('hidden');
        this.$('.form_controls a.show').removeClass('hidden');
        this.$('button.save').prop('disabled', false);
        this.chart = buildForType(this.type, { model: this.task });
        this.subviews['.chart_area'] = 'chart';
        this.renderSubview('chart');
      }
    } else {
      this.emptyDataWarning = new EmptyDataWarning({
        message: t('visualization.empty_data'),
      });
      this.subviews['.chart_area'] = 'emptyDataWarning';
      this.renderSubview('emptyDataWarning');
    }
  },

  refreshChart() {
    this.$('.overlay').addClass('disabled');
    this.task.set({ filters: this.filters && this.filters.sqlStrings() });
    this.task.bindOnce('saveFailed', this.saveFailed, this);
    this.task.save();

    this.showButtons(['stop', 'refresh']);
    this.$('button.refresh').startLoading('visualization.refreshing');
    this.listenTo(this.task, 'saved', this.chartRefreshed);
  },

  saveFailed() {
    this.showErrors(this.task);
    this.$('button.refresh').stopLoading();
  },

  chartRefreshed() {
    this.drawChart();
    this.chartUpToDate();
    this.resultsConsole.initializeDataGrid(this.task);
  },

  chartUpToDate() {
    this.lastSavedFilters = this.filters.clone();
    this.$('.overlay').addClass('hidden');
    this.$('button.refresh').stopLoading();
    this.showButtons(['save', 'close_dialog']);
  },

  cancelRefresh() {
    this.task.cancel();
    this.listenTo(this.task, 'canceled', this.filtersChanged);
    this.$('button.refresh').stopLoading();
  },

  showButtons(buttonClasses) {
    const buttons = this.$('button');
    buttons.addClass('hidden');
    _.each(buttonClasses, (buttonClass) => {
      buttons.filter(`.${buttonClass}`).removeClass('hidden');
    });
  },

  isValidData() {
    return !_.isEmpty(this.task.get('rows'));
  },

  isSufficientDataForTimeseries() {
    return this.task.get('rows').length > 1;
  },

  makeFilename() {
    return `${this.sanitizeFilename(`${this.options.chartOptions.name}-${this.options.chartOptions.type}`)}.svg`;
  },

  makeSvgData() {
    const svg = this.$('.chart_area.visualization svg')[0];
    svg.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns', 'http://www.w3.org/2000/svg');
    return new XMLSerializer().serializeToString(svg);
  },

  saveWorkfile(ws) {
    this.$('button.save').startLoading('actions.saving');

    const workspaceId = ws ? ws.get('id') : workspace(this.model).id;

    this.workfile = new Workfile({
      workspace: { id: workspaceId },
      fileName: this.makeFilename(),
      content_type: 'image/svg+xml'
    })
    this.saveAsDialog = new WorkfileNewDialog({
      pageModel: this.workfile,
      content: this.makeSvgData(),
      selectFileType: false
    });
    this.closeModal();
    this.saveAsDialog.launchModal();
  },

  additionalContext() {
    return {
      filterCount: this.effectiveFilterLength(),
      chartType: t(`dataset.visualization.names.${this.type}`),
      workspaceId: workspace(this.model) && workspace(this.model).id,
      hasChart: !!this.chart,
      entityName: this.model.get('objectName'),
      serverErrors: this.task.serverErrors,
      canSave: workspace(this.model) && workspace(this.model).canUpdate(),
    };
  },

  showFilterOptions(e) {
    if (e) { e.preventDefault(); }

    this.$('a.show_options').addClass('hidden');
    this.$('a.hide_options').removeClass('hidden');
    this.$('.filter_options').removeClass('hidden');
  },

  hideFilterOptions(e) {
    if (e) { e.preventDefault(); }

    this.$('a.show_options').removeClass('hidden');
    this.$('a.hide_options').addClass('hidden');
    this.$('.filter_options').addClass('hidden');
  },

  effectiveFilterLength() {
    return _.compact(_.map(this.filters.models, model => model.isComplete())).length;
  },

  filtersChanged() {
    if (this.filters.whereClause() === this.lastSavedFilters.whereClause()) {
      this.chartUpToDate();
    } else {
      this.$('.overlay').removeClass('hidden');
      this.$('.overlay').removeClass('disabled');
      this.showButtons(['refresh', 'revert']);
    }
  },

  revertFilters() {
    this.filters = this.lastSavedFilters;
    this.listenTo(this.filters, 'remove change', this.filtersChanged);
    this.filterWizard.collection = this.filters;
    this.filterWizard.render();
    this.chartUpToDate();
  },

  showDataGrid(e) {
    if (e) { e.preventDefault(); }
    this.$('.results_console').removeClass('hidden').addClass('open success');
    this.$('.form_controls a.hide_btn').removeClass('hidden');
    this.$('.form_controls a.show').addClass('hidden');
    this.resultsConsole.initializeDataGrid(this.task);
  },

  hideDataGrid(e) {
    if (e) { e.preventDefault(); }
    this.$('.results_console').removeClass('open');
    this.$('.form_controls a.show').removeClass('hidden');
    this.$('.form_controls a.hide_btn').addClass('hidden');
  },

  saveAsNoteAttachment() {
    this.notesNewDialog = new VisualizationNotesNew({
      pageModel: this.model,
      entityId: this.model.get('id'),
      entityName: this.model.name(),
      entityType: 'dataset',
      workspace: workspace(this.model),
      allowWorkspaceAttachments: !!this.model.get('workspace'),
      attachVisualization: {
        fileName: this.makeFilename(),
        svgData: this.makeSvgData(),
      },
    });
    this.launchSubModal(this.notesNewDialog);
  },

  saveAsWorkfile() {
    this.saveWorkfile();
  },

  footerSize() {
    return this.$('.form_controls').outerHeight(true);
  },

  sanitizeFilename(fileName) {
    return fileName.replace(/[^A-Z0-9_\-.]/gi, '');
  },
});
