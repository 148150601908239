import React from 'react';
import { t } from 'i18n-js';
import { ChorusIdentifierLower64 } from '../../utilities/validation_regexes';

function TooltipMessage({
  target, column, error, component,
}) {
  let text;
  if (target === 'columnName') {
    if (!error) {
      text = column.name;
    } else if (!column.name) {
      text = t('dataset.import.warning.missing_column_name');
    } else if (!column.name.match(ChorusIdentifierLower64())) {
      text = t('dataset.import.warning.invalid_column_name');
    } else if (column.error) {
      text = t('dataset.import.warning.duplicated_column_name');
    } else {
      text = t('dataset.import.generic_warning');
    }
  } else if (error && target === 'columnType') {
    text = t('dataset.import.warning.invalid_column_type');
  } else if (error && target === 'tableName') {
    text = t('dataset.import.warning.invalid_table_name');
  }
  if (!text) {
    return component;
  }
  return (
    <span
      data-tooltip={text}
      data-position="bottom left"
      className={error ? 'input-message' : ''}
    >
      { component }
    </span>
  );
}
export default TooltipMessage;
