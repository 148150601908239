import Template from '../models/template';
import Collection from './collections';

export default Collection.extend({
  constructorName: 'TemplateSet',
  model: Template,
  urlTemplate: 'api/workspaces/templates/',

  url() {
    return this.urlTemplate;
  },
});
