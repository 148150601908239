export const getUrl = () => decodeURIComponent(window.location.hash);

export const getUrlFromArray = (urlArray) => (urlArray.join('/'));

export const getArrayFromUrl = (url) => (url).split('/');

const getPageArgsFromArray = (urlArray, fromIndex = 2) => ([...urlArray].splice(fromIndex));

export const getPageArgsFromUrl = (url, fromIndex = 2) => (getPageArgsFromArray(getArrayFromUrl(url), fromIndex));

export const getWorkspaceId = (url) => (getPageArgsFromUrl(url)[0]);

export const removeTrailingSlash = (str) => str.replace(/\/+$/, '');

let testId = null;

export const setWorkspaceIdForTest = (id) => { testId = id; };

export const workspaceId = () => {
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    if (testId) {
      return testId;
    }
  }
  const url = removeTrailingSlash(getUrl());
  return getWorkspaceId(url);
};
