import $ from '../../jquery';
import t from '../../intl';
import PageItemList from '../core/page_item_list_view';
import DatasetItem from './dataset_item_view';

export default PageItemList.extend({
  eventName: 'dataset',
  constructorName: 'DatasetListView',
  useLoadingSection: true,

  setup(...args) {
    this.options.entityType = 'dataset';
    this.options.EntityViewType = DatasetItem;
    this.options.listItemOptions = {
      hasActiveWorkspace: this.options.hasActiveWorkspace,
    };

    this._super('setup', args);
  },

  postRender(...args) {
    const $list = this.$el;
    if (this.collection.length === 0 && this.collection.loaded) {
      const noDatasetEl = $("<li class='browse_more not_found'></li>");
      noDatasetEl.append(t('datasource.none'));
      $list.append(noDatasetEl);
    }

    this._super('postRender', args);
  },
});
