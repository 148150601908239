import { Dropdown } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { NewFileModal } from './modals/NewFileModal';
import { FilesProvider } from '../panels/files/Context';
import ModalWrapper from '../../components/modals/ModalWrapper';

const NewFileTrigger = (
  <Dropdown.Item
    text={(t('workspaces.launcher.new_file'))}
    icon="file alternate"
  />
);

const NewFileLauncher = () => (
  <FilesProvider value={{ baseFolderPath: 'files' }}>
    <ModalWrapper trigger={NewFileTrigger}>
      <NewFileModal />
    </ModalWrapper>
  </FilesProvider>
);

export default NewFileLauncher;
