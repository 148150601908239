import { useState } from 'react';

const NAVBAR_HIDDEN_KEY = 'isNavHidden';

export const loadNavbarState = () => {
  const storedValue = localStorage.getItem(NAVBAR_HIDDEN_KEY);
  return storedValue === 'true';
};

export const toggleNavbarState = (setState) => () => {
  setState((prevState) => {
    const newState = !prevState;
    const pageElement = document.getElementById('layout-page');
    if (pageElement) {
      pageElement.classList.toggle('hidden-nav', newState);
    }

    localStorage.setItem(NAVBAR_HIDDEN_KEY, newState);
    return newState;
  });
};

export const useNavbarState = () => {
  const [isNavHidden, setIsNavHidden] = useState(loadNavbarState);

  const toggleNavHidden = toggleNavbarState(setIsNavHidden);
  return [isNavHidden, toggleNavHidden];
};

export const turnOffFullscreen = () => {
  const pageElement = document.getElementById('layout-page');

  if (pageElement) {
    if (pageElement.classList.contains('aridhia-panel-fullscreen')) {
      pageElement.classList.remove('aridhia-panel-fullscreen');
    }
  }
};

export const toggleFullscreen = () => {
  const pageElement = document.getElementById('layout-page');

  if (pageElement) {
    if (pageElement.classList.contains('aridhia-panel-fullscreen')) {
      pageElement.classList.remove('aridhia-panel-fullscreen');
    } else {
      pageElement.classList.add('aridhia-panel-fullscreen');
    }
  }
};
