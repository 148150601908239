import ActivityList from '../activities/activity_list_view';
import FunctionTab from '../dataset/function_tab_view';
import DataTab from '../dataset/data_tab_view';
import TabControl from '../core/tab_control_view';
import Sidebar from '../core/sidebar_view';
import { schema } from '../../utilities/schema_assoc';

export default Sidebar.extend({
  constructorName: 'DatasetEditChorusViewSidebarView',
  templateName: 'dataset_edit_chorus_view_sidebar',
  useLoadingSection: true,

  subviews: {
    '.tab_control': 'tabs',
  },

  setup() {
    this.collection = this.model.activities();
    this.collection.fetch();

    this.listenTo(this.collection, 'changed', this.render);
    this.requiredResources.push(this.model);
  },

  resourcesLoaded() {
    this.tabs = new TabControl(['data', 'database_function_list', 'activity']);
    this.schema = schema(this.model);

    this.tabs.database_function_list = new FunctionTab({ schema: this.schema });
    this.tabs.data = new DataTab({ schema: this.schema });
    this.tabs.activity = new ActivityList({
      collection: this.collection,
      additionalClass: 'sidebar',
      displayStyle: ['without_object', 'without_workspace'],
    });

  },
});
