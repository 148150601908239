import chorus from '../../chorus';
import _ from '../../underscore';
import t from '../../intl';
import Handlebars from '../../vendor/handlebars';
import TruncatedText from '../core/truncated_text_view';
import Activity from '../../presenters/activity_presenters';
import View from '../loading_view';
import ManagementUser from "../../models/management/user";

const findUser = (uuid, users) => users.find(user => user.get('uuid') === uuid);
const findMember = (uuid, users) => users.find(user => user.uuid === uuid);

export default View.extend({
  constructorName: 'CommentListView',
  templateName: 'comment_list',

  additionalContext() {
    return {
      initialLimit: this.options.initialLimit || 2,
      entityType: this.collection.attributes.entityType,
      entityId: this.collection.attributes.entityId,
    };
  },

  collectionModelContext(comment) {
    if (comment.note()) {
      return new Activity(comment, { displayStyle: this.options.displayStyle });
    }
    const author = comment.author();
    const isOwner = author.uuid === chorus.session.user().get('uuid');
    return {
      isOwner: isOwner || this.options.currentUserOwnsWorkspace,
      iconSrc: author.fetchImageUrl({ size: 'icon' }),
      iconHref: author.showUrl(),
      displayName: author.displayName(),
      timestamp: comment.get('timestamp'),
      id: comment.get('id'),
      headerHtml: new Handlebars.SafeString(t('activity.comments.commented_on_note', { authorLink: author.displayName() })),
      displayInitials: author.displayInitials(),
      displayAvatarBackgroundColor: author.displayAvatarBackgroundColor(),
    };
  },

  lookup_user(uuid) {
    const user = findUser(uuid, this.usersCollection);
    if (user) { return user; }
    const non_member = new ManagementUser({ uuid });
    this.usersCollection.push(non_member);
    non_member.fetch();
    return non_member;
  },

  setAuthor(model){
    const author = model.author();

    if (!author.get('uuid')) {
      author.set({ firstName: t('actions.removed_user.first_name'), lastName: t('actions.removed_user.last_name') });
    } else {
      const member = findMember(author.get('uuid'), this.managementWorkspace.get('members'));
      if (member) {
        author.set({ firstName: member.firstName, lastName: member.lastName });
      } else {
        author.set({ firstName: t('actions.removed_user.first_name'), lastName: t('actions.removed_user.last_name') });
        const non_member = this.lookup_user(author.get('uuid'));
        this.onceLoaded(non_member, () => {
          author.set({ firstName: non_member.get('firstName'), lastName: non_member.get('lastName') });
          //This is to trigger comment model change and re-render the view
          model.set('dummy', 1);
        });
      }
    };
  },

  setup() {
    this.usersCollection = this.options.usersCollection;
    this.managementWorkspace = this.options.managementWorkspace;
    this.requiredResources.add(this.managementWorkspace);
    this.collection.each(function resolveUser(model) {
      const comment = model;
      this.setAuthor(comment);
    }, this);
  },

  postRender() {
    const $lis = this.$('li .activity_content .body');
    _.each(this.commentViews, (commentView) => {
      commentView.teardown();
    });
    this.commentViews = [];
    this.collection.each(function addItem(model, index) {
      const comment = model;
      comment.loaded = true;
      const view = new TruncatedText({ model: comment, attribute: 'body', attributeIsHtmlSafe: true });
      $lis.eq(index).append(view.render().el);
      this.registerSubView(view);
      this.commentViews.push(view);
    }, this);
  },
});
