import chorus from '../../chorus';
import _ from '../../underscore';
import ManagementUser from './user';
import ManagementUserCollection from '../../collections/management/users';
import dig from '../../utilities/dig';
import Service from '../service';
import Workspace, { useDependencyHack } from '../workspace';
import { instance } from '../../models/config';
import Taggable from '../../mixins/tags_mixin';
import $ from '../../jquery';
import { idToken } from '../../auth/oauth';

/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true }] */

const ManagementWorkspace = Workspace.include(Taggable).extend({
  constructorName: 'ManagementWorkspace',
  urlTemplate: 'api/admin/workspace/{{uuid}}',
  editUrlTemplate: 'workspaces/{{uuid}}/edit',

  fetchWithImage() {
    this.fetch({
      success: () => {
        this._chorusWorkspace = new Workspace({ id: this.id, image_only: true });
        if (this.get('chorusID')) {
          this._chorusWorkspace.fetch({ success: () => { this.set('image', this._chorusWorkspace.get('image')); } });
        }
      },
    });
  },

  setTags(tags) {
    this.set('tags', tags);
    this._tags = undefined;
  },

  users(roles) {
    const filteredUsers = this.get('members').filter((member) => {
      if (roles) {
        return roles.indexOf(member.role) !== -1;
      }

      return true;
    });
    if (!this._users) {
      this._users = new ManagementUserCollection(filteredUsers.map((member) => {
        const user = new ManagementUser(member);
        user.set({
          id: member.uuid,
          owner: member.role === 'Owner',
        });

        return user;
      }));
    } else {
      this._usersUpdated = new ManagementUserCollection(filteredUsers.map((member) => {
        const user = new ManagementUser(member);
        user.set({
          id: member.uuid,
          owner: member.role === 'Owner',
        });

        return user;
      }));
    }
    if (this.get('status') === 'pending' || !this._usersUpdated) {
      return this._users;
    } 
    this._users = this._usersUpdated;
    return this._users;
  },

  services() {
    return Object.keys(this.get('resources'))
      .filter(service => this.get('resources')[service].type !== 'BasicPackage')
      .map(service => new Service(this.get('resources')[service]));
  },

  owner() {
    if (!this._owner) {
      this._owner = this.users().findWhere({ owner: true });
    }
    return this._owner;
  },

  updateSummary(summary, success, failure) {
    const metadata = { metadata: _.extend(this.get('metadata'), { summary }) };
    idToken().then((token) => {
      $.ajax({
        url: `/api/admin/workspace/${this.get('uuid')}`,
        type: 'patch',
        data: JSON.stringify(metadata),
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }).done(success).fail(failure);
    });
  },

  updateMembers(members, success, failure) {
    const memberPayload = [];
    _.each(members, (member) => {
      if (member.get('action')) {
        memberPayload.push({ uuid: member.get('id'), role: member.get('action') });
      } else {
        memberPayload.push({ uuid: member.get('id'), role: member.get('role') });
      }
    });

    idToken().then((token) => {
      $.ajax({
        url: `/api/admin/workspace/${this.get('uuid')}`,
        type: 'patch',
        data: JSON.stringify({members: memberPayload}),
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }).done(success).fail(failure);
    });
  },

  isInvited(recalcUsers = false) {
    if (chorus.session.user().isTenantAdmin()) {
      return false;
    }

    if (recalcUsers) {
      this._users = null;
    }

    const invited = this.invitedMembers();

    if (invited.length > 0) {
      const uuid = chorus.session.user().get('uuid');

      return !!(_.find(invited, user => user.get('uuid') === uuid));
    }

    return false;
  },

  invitedMembers() {
    return this.users().where({ invited: true }) || [];
  },

  currentSessionUserRole() {
    const member = this.users().findWhere({ uuid: chorus.session.user().get('uuid') });
    if (member) {
      return member.get('role');
    }
    return null;
  },

  provisioningError(error) {
    idToken().then((token) => {
      $.ajax({
        url: `api/admin/workspace/${this.get('uuid')}/status`,
        cache: false,
        type: 'get',
        headers: { Authorization: `Bearer ${token}` },
      }).done(data => error(data));
    });
  },

  canDestroy() {
    return this.get('status') === 'active' || this.get('status') === 'error';
  },

  showUrl() {
    const url = dig(this.attributes, ['resources', 'Basic', 'readOnly', 'workspaceUrl']);

    if (url) {
      const baseUrl = instance.get('publicUrl');
      if (baseUrl) {
        return url.replace(baseUrl, '');
      }
      return url;
    }

    return this._super('showUrl');
  },
});

export default ManagementWorkspace;

const cache = {};

export const getManagementWorkspace = (uuid) => {
  if (cache[uuid]) {
    return cache[uuid];
  }
  
  cache[uuid] = new ManagementWorkspace({ uuid });
  return cache[uuid];
};

useDependencyHack(getManagementWorkspace);