import chorus from '../../../chorus';
import Service from '../../../models/service';
import View from '../../loading_view';

export default View.extend({
  templateName: 'workspace/management/services',
  events: {
    'click .service-toggle': 'serviceToggle',
  },
  context() {
    return {
      shiny: this.collection.findWhere({ type: 'Shiny' }),
      dsa: this.collection.findWhere({ type: 'LandingZone' }),
      showShiny: !!this.options.available_services.findWhere({ name: 'shiny' }),
      showDsa: !!this.options.available_services.findWhere({ name: 'dsa' }),
    };
  },
  serviceToggle(e) {
    const service = this.collection.where({ type: e.target.title });

    if (service.length === 1) {
      this.collection.remove(service[0], { silent: true });
    } else if (e.target.title === 'shiny') {
      this.collection.push(new Service({ type: e.target.title, properties: { maxApplications: 10 } }));
    } else if (e.target.title === 'LandingZone') {
      this.collection.push(new Service({ type: 'LandingZone' }));
    }
    this.render();
    chorus.PageEvents.trigger('workspace:serviceToggle', this.collection);
  },
});
