import { t } from 'i18n-js';
import {
  Segment, Header, Button, Form, Input,
} from 'semantic-ui-react';
import { useDatabaseTableCreationContext } from '../Context';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import ImportTDFFileModal from './modals/ImportTDFFileModal';
import {
  DatabaseTableCreationTDFFileIsLinked,
  MetadataAttributesWarning,
} from './DatabaseTableCreationTableWarnings';
import { removeMetadataTDF } from '../actions';
import { metadataHasIssues } from '../helper';

const DatabaseTableCreationMetadataFromTDF = () => {
  const { newDatabaseTableState, dispatch } = useDatabaseTableCreationContext();
  const showWarning = metadataHasIssues(newDatabaseTableState);

  const handleRemoveFile = () => {
    dispatch(removeMetadataTDF);
  };

  const ImportFromTdfBUtton = (
    <Button
      icon="file"
      primary
      disabled={!!newDatabaseTableState.table.metadata.source_metadata_file}
      content={t('database_tables_new.metadata.from_tdf.action')}
    />
  );

  return (
    <>
      <Segment vertical basic>
        <Header
          as="h5"
          content={t('database_tables_new.metadata.from_tdf.header')}
          className="italic"
          textAlign="center"
          color="grey"
        />
      </Segment>
      <Segment vertical basic>
        <Form>
          <Form.Field className={showWarning ? 'warning' : ''}>
            {newDatabaseTableState.table.tdf_is_linked && (
              <DatabaseTableCreationTDFFileIsLinked />
            )}
            <label htmlFor="select">
              {t('database_tables_new.metadata.from_tdf.label_select')}
            </label>
            {!newDatabaseTableState.table.metadata.source_metadata_file && (
              <Input
                id="select"
                action
                placeholder={t('database_tables_new.metadata.from_tdf.placeholder_select')}
                defaultValue={newDatabaseTableState.table.metadata.source_metadata_file}
              >
                <input readOnly />
                <ModalWrapper trigger={ImportFromTdfBUtton} size="small">
                  <ImportTDFFileModal />
                </ModalWrapper>
              </Input>
            )}
            {!!newDatabaseTableState.table.metadata.source_metadata_file && (
              <Input
                id="select"
                action
                type="text"
                defaultValue={newDatabaseTableState.table.metadata.source_metadata_file}
                content={newDatabaseTableState.table.source_file}
              >
                <input readOnly />
                <Button
                  icon="times"
                  data-testid="remove-csv-icon"
                  name="delete"
                  onClick={handleRemoveFile}
                  disabled={!newDatabaseTableState.table.metadata.source_metadata_file}
                />
              </Input>
            )}
          </Form.Field>
        </Form>
        {showWarning && (
          <MetadataAttributesWarning table={newDatabaseTableState.table} />
        )}
      </Segment>
    </>
  );
};

export default DatabaseTableCreationMetadataFromTDF;
