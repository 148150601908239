import _ from '../underscore';
import Handlebars from '../vendor/handlebars';

export default (modelOrAttributes) => {
  const getReal = modelOrAttributes.get ||
        function getByFunction(attributeName) { return modelOrAttributes[attributeName]; };
  const obj = Object.create(modelOrAttributes);

  obj.get = function getAttribute(attributeName, safe) {
    if (getReal.call(obj, 'highlightedAttributes') &&
                getReal.call(obj, 'highlightedAttributes')[attributeName]) {
      const attribute = getReal.call(obj, 'highlightedAttributes')[attributeName];
      return new Handlebars.SafeString(_.isArray(attribute) ? attribute[0] : attribute);
    } else if (safe) {
      return new Handlebars.SafeString(obj[attributeName]);
    }
    return new Handlebars.SafeString(Handlebars.Utils.escapeExpression(getReal.call(obj, attributeName)));
  };

  return obj;
};
