/* eslint-disable react/no-array-index-key */
import { Table, Form } from 'semantic-ui-react';
import { useDatabaseTableCreationContext } from '../Context';
import { useWorkspaceContext } from '../../../../adapters/Workspace';
import { useMetadataExtraction, useMetadataExtractionStatus } from '../service';
import { hasError } from '../../../../utilities/validation';
import { validationFields } from '../../../../constants/validation';
import DatabaseTableCreationMetadataSelector from './DatabaseTableCreationMetadataSelector';
import DatabaseTableCreationColumnsProperties from './DatabaseTableCreationColumnsProperties';
import ConfirmWrapper from '../../../../components/modals/ConfirmWrapper';
import PrimaryKeyModal from './modals/PrimaryKeyModal';
import { ParsingOrMismatchError } from './DatabaseTableCreationTableWarnings';

const PlaceholderEmptyCell = ({ index }) => (
  <Table.Cell key={`placeholder-data-from-csv-${index}`}>
    {index}
  </Table.Cell>
);

const DatabaseTableCreationColumnsFromCSV = () => {
  const { newDatabaseTableState, dispatch } = useDatabaseTableCreationContext();
  const { workspaceUuid } = useWorkspaceContext();
  const parsingOrMismatchError = newDatabaseTableState.table.parsed_content.parsing_errors
    || newDatabaseTableState.table.columns_rows_mismatch;

  const {
    metadataExtraction,
  } = useMetadataExtraction(newDatabaseTableState.table, workspaceUuid, dispatch);
  useMetadataExtractionStatus(newDatabaseTableState.table, dispatch, metadataExtraction.uuid);

  return (
    <>
      <DatabaseTableCreationMetadataSelector />
      {hasError(newDatabaseTableState.validation_errors, validationFields.PRIMARY_KEY) && (
        <ConfirmWrapper size="small">
          <PrimaryKeyModal />
        </ConfirmWrapper>
      )}
      {parsingOrMismatchError
      && (
        <ParsingOrMismatchError
          table={newDatabaseTableState.table}
        />
      )}
      {newDatabaseTableState.table.parsed_content.splitted_table_rows
      && (
        <Form className="aridhia-database-tables-new-columns">
          <Table singleLine compact celled striped unstackable>
            <Table.Header fullWidth>
              <DatabaseTableCreationColumnsProperties />
            </Table.Header>
            {!parsingOrMismatchError && (
              <Table.Body className="truncate">
                {newDatabaseTableState.table.parsed_content.splitted_table_rows.map((row, index) => (
                  <Table.Row key={`row-data-from-csv-${index}`}>
                    {newDatabaseTableState.table.added_primary_column && <PlaceholderEmptyCell index={index} />}
                    {row.map((value, newIndex) => (
                      <Table.Cell key={`value-data-from-csv-${index}-${newIndex}`}>
                        {value === newDatabaseTableState.table.metadata.null_qualifier ? '' : value}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            )}
          </Table>
        </Form>
      )}
    </>
  );
};

export default DatabaseTableCreationColumnsFromCSV;
