import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import MainContentList from '../core/main_content_list_view';
import WorkfileSidebar from './workfile_sidebar';
import WorkfileFoldersHeader from './workfile_folders_header_view';
import EditTags from '../../dialogs/tags/edit_tags_dialog';
import MultipleSelectionSidebarMenu from '../core/multiple_selection_sidebar_menu';
import InboundAirlockReject from '../../alerts/inbound_airlock_reject_alert';
import InboundAirlockAccept from '../../dialogs/inbound_airlock/inbound_airlock_accept_dialog';
import WorkfileDelete from '../../alerts/workfile_delete_alert';
import View from '../loading_view';
import { FEATURE } from '../../../utilities/features';
import Routing from '../../mixins/routing';

export default View.include(Routing).extend({

  bindCallbacks: $.noop,

  subviews: {
    '.app-content': 'mainContent',
    '.app-sidebarBasic': 'sidebar',
    '.app-sidebarMultiple': 'multiSelectSidebarMenu',
  },


  setup(options) {
    this.className = 'app-workfilesShow';
    this.templateName = 'tab_content';

    this.folder = options.folder;
    this.model = options.model;
    this.workspace = this.model;
    this.collection = this.workspace.workfiles();
    this.mainContent = this.content();

    this.subscribePageEvent('workfile:search', function resetSelection() {
      this.collection.trigger('selectNone', { reset: true });
    });

    this.listenTo(this.collection, 'searched', function searchReRender() {
      this.mainContent.content.render();
      this.mainContent.contentFooter.render();
      this.mainContent.contentDetails.updatePagination();
    });

    this.subscribePageEvent('change:readOnly', function displayReadOnly(model) {
      const m = this.collection.get(model);
      if (m && m.get('readOnly') !== model.get('readOnly')) {
        m.set('readOnly', model.get('readOnly'));
      }
    });

    this.subscribePageEvent('note:deleted', function removeNote() {
      this.model.trigger('invalidated');
    });

    this.subscribePageEvent('workfiles:changed', this.fetchCollection);
    this.subscribePageEvent('change:fileName', this.filenameChanged);

    this.subscribePageEvent('workfile:deleted', (_workfile, deselectAll) => {
      if (deselectAll) {
        this.reloadandFetch({ success() { chorus.PageEvents.trigger('workfiles:deselectAll'); } });
      } else {
        this.reloadandFetch();
      }
    });

    this.multiSelectSidebarMenu = new MultipleSelectionSidebarMenu({
      selectEvent: 'workfile:checked',
      collection: this.collection,
      actions: [],
      actionEvents: {},
    });

    this.listenTo(this.collection, 'workfile:selected', this.setModel);
    this.listenTo(this.collection, 'workfile:checked', this.setCheckedModel);
  },

  fetchCollection(e) {
    if (e) {
      e.preventDefault();
    }
    this.reloadandFetch();
  },

  reloadandFetch(options) {
    this.$('.action_bar_primary .rsh-loading').first().removeClass('hidden');
    this.collection.fetch(options);
  },

  filenameChanged(model) {
    const m = this.collection.get(model);
    if (m) {
      const self = this;
      this.reloadandFetch({
        success() {
          self.mainContent.content.selectModel(model);
        },
      });
    }
  },

  content() {
    const onTextChangeFunction = _.debounce(_.bind(function search(e) {
      this.mainContent.contentDetails.startLoading('.count');
      this.collection.search($(e.target).val());
    }, this), 300);

    return new MainContentList({
      modelClass: 'Workfile',
      collection: this.collection,
      model: this.workspace,
      contentDetailsOptions: {
        multiSelect: true,
        buttonView: this.buttonView,
      },
      contentOptions: {
        listItemOptions: {
          workspaceIdForTagLink: this.workspace.id,
        },
        folder: this.folder,
      },
      search: {
        placeholder: t('workspace.search'),
        onTextChange: onTextChangeFunction,
      },
      useCustomList: true,
      contentHeader: this.header(),
    });
  },

  header() {
    return new WorkfileFoldersHeader({
      title: t('workfiles.title'),
      collection: this.collection,
      folder: this.folder,
      crumbs: true,
      linkMenus: {
        type: {
          title: t('header.menu.filter.title'),
          options: this.linkMenuOptions(),
          event: 'filter',
        },
        sort: {
          title: t('workfiles.header.menu.sort.title'),
          options: [
            { data: 'alpha', text: t('workfiles.header.menu.sort.alphabetically') },
            { data: 'date', text: t('workfiles.header.menu.sort.by_date') },
          ],
          event: 'sort',
        },
      },
    });
  },

  setModel(workfile) {
    this.model = workfile;
    if (this.sidebar) {
      this.sidebar.teardown(true);
    }
    this.sidebar = new WorkfileSidebar({
      model: this.model,
      listMode: true,
      workfileCollection: this.collection,
      workspace: this.workspace,
    });
    this.renderSubview('sidebar');
  },

  setCheckedModel() {
    const workfiles = this.multiSelectSidebarMenu.selectedModels;
    if (workfiles.length === 1) {
      this.setModel(workfiles.models[0]);
      return;
    }
    const actions = [];
    const actionEvents = {
      'click .edit_tags': _.bind(function launchTagsDialog() {
        new EditTags({ collection: this.multiSelectSidebarMenu.selectedModels }).launchModal();
      }, this),
    };

    const canInboxActions = workfiles.length && workfiles.reduce(
      (acc, current) => acc && current.isInbox(),
    );
    const canShowAirlock = !canInboxActions && workfiles.reduce(
      (acc, current) => acc && !current.get('destroying') && !current.isInbox(),
      this.workspace.canAirlock(),
    );
    const noFoldersSelected = workfiles.models.every((wf) => !wf.isFolder());

    if (canInboxActions) {
      actions.push('<a class="approve btn" href="#">{{t "actions.approve"}}</a>');
      actions.push('<a class="reject btn btn-warning" href="#">{{t "actions.reject"}}</a>');
      actionEvents['click .approve'] = _.bind((e) => {
        if (e) { e.preventDefault(); }
        new InboundAirlockAccept({ workspace: this.workspace, workfileSet: this.multiSelectSidebarMenu.selectedModels }).launchModal();
      }, this);
      actionEvents['click .reject'] = _.bind((e) => {
        if (e) { e.preventDefault(); }
        new InboundAirlockReject({ model: this.multiSelectSidebarMenu.selectedModels }).launchModal();
      }, this);
    }
    if (canShowAirlock) {
      actions.push('<a class="workfile_download btn">{{t "workspace.airlock"}}</a>');
      actionEvents['click .workfile_download'] = _.bind(function startAirlock() {
        this.workspace.trigger('airlock', this.multiSelectSidebarMenu.selectedModels);
        if (FEATURE.NEW_UX) {
          const workfileList = this.multiSelectSidebarMenu.selectedModels.models.map(a => a.id).join(',');
          this.navigate(`workspaces/${this.workspace.id}/airlock_new/workfile/${workfileList}`);
        }
      }, this);
    }

    if(noFoldersSelected){
      const tempFolder = this.folder.get('folder')
      if (!(tempFolder == 'inbox' || tempFolder == 'public inbox')) {
        actions.push('<a class="delete app-alert btn btn-warning">{{t "workfile.delete_multiple.button"}}</a>');
      } 
      actionEvents['click .delete'] = _.bind((e) => {
        if (e) { e.preventDefault(); }
        new WorkfileDelete({ collection: workfiles }).launchModal();
      }, this);
    }

    this.multiSelectSidebarMenu.setActions(actions);
    this.multiSelectSidebarMenu.setActionEvents(actionEvents);
    this.renderSubview('multiSelectSidebarMenu');
  },

  setFolder(folder) {
    this.folder = folder;
    this.mainContent.contentHeader.setFolder(folder);
    this.mainContent.content.setFolder(folder);
  },

  linkMenuOptions() {
    return [
      { data: '', text: t('workfiles.header.menu.filter.all') },
      { data: 'R', text: t('workfiles.header.menu.filter.r') },
      { data: 'RNW', text: t('workfiles.header.menu.filter.rnw') },
      { data: 'SQL', text: t('workfiles.header.menu.filter.sql') },
      { data: 'TEXT', text: t('workfiles.header.menu.filter.text') },
      { data: 'IMAGE', text: t('workfiles.header.menu.filter.image') },
      { data: 'OTHER', text: t('workfiles.header.menu.filter.other') },
    ];
  },
});
