import t from '../intl';
import Error from './error_page';
import chorus from '../chorus';
import _ from "../underscore";

export default Error.extend({
  title: t('unauthorized.title'),
  context() {
    return _.extend({
      title: this.title,
      text: t('unauthorized.text', chorus.session.get('user')).replace(/\\n/g, '\n'),
    }, this.pageOptions);
  },
});
