import { useRef } from 'react';
import { Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { t } from 'i18n-js';
import externalLink from '../../../utilities/navigation';
import { getDomain } from '../../../utilities/helpers';
import './SiteTrackingConsentToast.less';

const SiteTrackingConsentToast = () => {
  const toastId = useRef(null);

  const acceptCookies = (choice) => {
    const future = new Date();
    future.setDate(future.getDate() + 365);
    document.cookie = `workspacesTrackingConsent=${choice};expires=${future.toUTCString()};path=/;domain=${getDomain()}`;
    toast.dismiss(toastId.current);
  };

  return (
    <div>
      <h5>
        {t('tracking.notifications.title')}
      </h5>
      <p>
        {t('tracking.notifications.cookie1')}
        <a
          href={externalLink('url.cookie_policy')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('tracking.notifications.privacy')}
        </a>
        {t('tracking.notifications.cookie2')}
      </p>
      <div className="aridhia-button-group">
        <Button
          size="mini"
          as="a"
          href={externalLink('url.cookie_policy')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('tracking.notifications.settings')}
        </Button>
        <span>
          <Button size="mini" onClick={() => acceptCookies(false)}>
            {t('tracking.notifications.reject')}
          </Button>
          <Button size="mini" positive onClick={() => acceptCookies(true)}>
            {t('tracking.notifications.accept')}
          </Button>
        </span>
      </div>
    </div>
  );
};

export default SiteTrackingConsentToast;
