/* eslint-disable camelcase */
import { Button } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { airlockDatabaseTable } from '../../../../constants/ui_urls';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import MultipleDeleteModal from './modals/MultipleDeleteModal';
import { useDatabaseTablesContext } from '../Context';
import { useWorkspaceContext } from '../../../../adapters/Workspace';
import Can from '../../../../permissions';

const AirlockButton = ({ selectedTables }) => (
  <Button
    icon="unlock"
    basic
    size="small"
    content={t('tables_list.actions.airlock')}
    href={airlockDatabaseTable(selectedTables.map((table) => table.id).join())}
  />
);

const DeleteButton = (
  <Button
    basic
    size="small"
    icon="trash alternate outline"
    content={t('tables_list.actions.delete')}
  />
);

const DatabaseTablesMultiSelectActions = () => {
  const { workspace: { can_airlock } } = useWorkspaceContext();
  const { selectedTables } = useDatabaseTablesContext();

  return selectedTables.length > 0 && (
    <>
      {can_airlock && (
      <Can I="Create" this="WorkspaceAirlock">
        <AirlockButton selectedTables={selectedTables} />
      </Can>
      )}
      <Can I="Write" this="WorkspaceDatabase">
        <ModalWrapper trigger={DeleteButton} size="small">
          <MultipleDeleteModal />
        </ModalWrapper>
      </Can>
    </>
  );
};

export default DatabaseTablesMultiSelectActions;
