import chorus from '../chorus';
import _ from '../underscore';
import t from '../intl';
import Confirm from './confirm';
import Draft from '../models/draft';
import { workspace } from '../utilities/workspace_assoc';

export default Confirm.extend({
  constructorName: 'WorkfileConflict',

  text: t('workfile.conflict.alert.text'),
  ok: t('workfile.conflict.alert.submit'),
  cancel: t('workfile.conflict.alert.cancel'),
  additionalClass: 'error',

  setup() {
    this.title = t('workfile.conflict_title');
    // this.model.serverErrorMessage();
    delete this.model.serverErrors;
  },

  postRender() {
    this._super('postRender');
    this.$('button.cancel').click(_.bind(function cancelChanges() {
      this.discardChanges();
    }, this));
  },

  confirmAlert() {
    this.closeModal();
    this.dialog = new chorus.dialogs.WorkfileNewVersion({ pageModel: this.model });
    this.dialog.launchModal();
  },

  discardChanges() {
    const draft = new Draft({
      workspaceId: workspace(this.model).id,
      workfileId: this.model.get('id'),
    });
    this.listenTo(draft, 'change', (data) => {
      data.destroy();
    });

    draft.fetch();
    this.model.fetch();
    this.closeModal();
  },
});

