import $ from '../../jquery';
import WorkspaceSearchResultList from './workspace_search_result_list';
import SearchResultList from './search_result_list';
import SearchResult from '../../models/search_result';
import Collection from '../../collections/collections';
import View from '../loading_view';

export default View.extend({
  constructorName: 'SearchResults',
  templateName: 'search_results',

  subviews: {
    '.this_workspace': 'thisWorkspaceList',
    '.user_list': 'userList',
    '.workfile_list': 'workfileList',
    '.workspace_list': 'workspaceList',
    '.dataset_list': 'datasetList',
    '.data_source_list': 'dataSourceList',
    '.gnip_data_source_list': 'gnipDataSourceList',
    '.attachment_list': 'attachmentList',
  },

  setup() {
    this.selectedModels = new Collection();

    if (this.model.users().length) {
      this.userList = this.buildListView('user', this.model.users());
    }
    if (this.model.workfiles().length) {
      this.workfileList = this.buildListView('workfile', this.model.workfiles());
    }
    if (this.model.workspaces().length) {
      this.workspaceList = this.buildListView('workspace', this.model.workspaces());
    }
    if (this.model.datasets().length) {
      this.datasetList = this.buildListView('dataset', this.model.datasets());
    }
    if (this.model.attachments().length) {
      this.attachmentList = this.buildListView('attachment', this.model.attachments());
    }
    if (this.model.workspaceItems().length) {
      const opts = this.buildListViewOptions(null, this.model.workspaceItems());
      this.thisWorkspaceList = new WorkspaceSearchResultList(opts);
    }
  },

  saveSearchSelection(model) {
    this.$el.find('li.item_wrapper').toggleClass('selected', false);
    this.model.selectedItem = model;
  },

  additionalContext() {
    return {
      hasResults: this.model.total() > 0,
      isConstrained: this.model.isConstrained(),
      expandHref: new SearchResult({ query: this.model.get('query') }).showUrl(),
    };
  },

  buildListView(entityType, collection) {
    return new SearchResultList(this.buildListViewOptions(entityType, collection));
  },

  buildListViewOptions(entityType, collection) {
    this.listenTo(collection, 'selected', this.saveSearchSelection);
    return {
      entityType,
      collection,
      search: this.model,
      selectedModels: this.selectedModels,
    };
  },

  selectItem: function selectItem(e) {
    const $target = $(e.currentTarget);
    if ($target.hasClass('selected')) return;

    this.$('.item_wrapper').removeClass('selected');
    $target.addClass('selected');
  },
});
