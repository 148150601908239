import chorus from '../../chorus';
import $ from '../../jquery';
import _ from '../../underscore';
import Activity from './activity_view';
import View from '../loading_view';

export default View.extend({
  constructorName: 'NotificationListView',
  templateName: 'notification_list',
  useLoadingSection: true,

  events: {
    'click .more_items a': 'fetchMoreNotifications',
  },

  setup() {
    this.activities = [];
  },

  fetchMoreNotifications(ev) {
    ev.preventDefault();
    const pageToFetch = parseInt(this.collection.pagination.page, 10) + 1;
    this.collection.fetchPage(pageToFetch, { reset: false, remove: false, success: _.bind(this.render, this) });
    this.collection.bindOnce('loaded', function markAllRead() {
      this.collection.markAllRead({});
    }, this);
  },

  additionalContext() {
    const ctx = { };
    if (this.collection.loaded && this.collection.pagination) {
      const page = parseInt(this.collection.pagination.page, 10);
      const total = parseInt(this.collection.pagination.total, 10);
      ctx.showMoreLink = this.options.allowMoreLink && (total > page);
    } else {
      ctx.showMoreLink = false;
    }
    return ctx;
  },

  postRender() {
    const $list = this.$('ul');

    _.each(this.activities, (activityView) => {
      activityView.teardown();
    });
    this.activities = [];
    this.collection.each(function buildActivity(model) {
      try {
        const view = new Activity({
          model: model.activity(),
          isNotification: true,
          isReadOnly: true,
        });
        view.render();
        this.activities.push(view);
        this.registerSubView(view);

        if (model.get('unread')) {
          $(view.el).addClass('unread');
        }

        $list.append(view.el);
      } catch (err) {
        chorus.log('error', err, 'processing notification', model);
        if (chorus.isDevMode()) {
          chorus.toast('bad_notification', {
            type: model.get('action'),
            id: model.id,
            toastOpts: { theme: 'bad_activity' },
          });
        }
      }
    }, this);
  },

  show() {
    _.each(this.activities, (activity) => {
      activity.show();
    });
  },
});
