import chorus from '../../chorus';
import Dialog from '../../dialogs/dialogs';

export default Dialog.extend({
  constructorName: 'AccountDialog',
  templateName: 'data_source_account',
  translationKeys: {
    cancel: '',
    body: '',
  },

  events: {
    'submit form': 'save',
  },

  additionalContext() {
    return {
      translationKeys: this.translationKeys,
      translationValues: {},
    };
  },

  makeModel(...args) {
    this._super('makeModel', args);
    this.listenTo(this.model, 'saved', this.saved);
  },

  save(e) {
    e.preventDefault();
    this.model.save({
      dbUsername: this.$('input[name=dbUsername]').val(),
      dbPassword: this.$('input[name=dbPassword]').val(),
    });
  },

  saved() {
    this.closeModal();
    if (this.options.dataSource) { this.showSavedToast(); }
  },

  showSavedToast() {
    chorus.toast('data_sources.account.updated.toast', { dataSourceName: this.options.dataSource.name() });
  },
});
