export const appNames = Object.freeze({
  rStudio: 'R Studio',
  jupyterNotebook: 'Jupyter Notebook',
});

export const appPodStatuses = Object.freeze({
  LOADING: 'loading',
  PULLING: 'pulling',
  READY: 'ready',
  INACTIVE: 'inactive',
  FAILED: 'failed',
  NO_RESOURCE: 'noresource',
  UNKNOWN: 'unknown',
});
