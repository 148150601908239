import _ from '../underscore';

const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
const guid = () => `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;

export const CreateUserPayload = data => ({
  first_name: data.firstName,
  last_name: data.lastName,
  email: data.email.toLowerCase(),
  title: data.title,
  department: data.department,
  telephone: data.telephone,
  notes: data.notes,
  organisation: data.organisation,
});

export const CreateWorkspacePayload = (data) => {
  const payload = {
    name: data.name,
    uuid: guid(),
    resources: {
      Basic: {
        type: 'BasicPackage',
        properties: { storage_in_mb: 100 },
      },
    },

    members: data.admins.filter(user => !user.get('deleted')).map(user => ({
      uuid: user.get('uuid'),
      role: user.get('owner') === true ? 'Owner' : 'Administrator',
      first_name: user.get('firstName'),
      last_name: user.get('lastName'),
    })),

    metadata: {
      customerReference: data.reference,
      summary: data.summary,
    },
  };

    // Add Services
  data.services.forEach((value) => {
    payload.resources[value.get('type')] = {
      type: value.get('type'),
    };

    if (value.get('properties')) {
      const keys = Object.keys(value.get('properties'));
      const props = _.object(keys.map(key => [_.underscored(key), value.get('properties')[key]]));
      payload.resources[value.get('type')].properties = props;
    }
  });

  return payload;
};

export const UpdateWorkspacePayload = (data) => {
  const payload = {
    resources: {},

    members: data.admins.map((user) => {
      if (user.get('deleted') === true) {
        return { uuid: user.get('uuid'), role: 'delete' };
      }
      return {
        uuid: user.get('uuid'),
        role: user.get('owner') === true ? 'Owner' : 'Administrator',
        first_name: user.get('firstName'),
        last_name: user.get('lastName'),
      };
    }),

    metadata: {
      customerReference: data.reference,
      summary: data.summary,
    },
  };

    // Add Services
  data.services.forEach((value) => {
    if (value.get('deleted') !== true) {
      payload.resources[value.get('type')] = {
        type: value.get('type'),
      };

      if (value.get('properties')) {
        const keys = Object.keys(value.get('properties'));
        const props = _.object(keys.map(key => [_.underscored(key), value.get('properties')[key]]));
        payload.resources[value.get('type')].properties = props;
      }
    } else {
      payload.resources[value.get('type')] = 'delete';
    }
  });

  return payload;
};
