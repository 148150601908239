import React, { Component } from 'react';
import { Search } from 'semantic-ui-react';
import _ from 'underscore';
import { t } from 'i18n-js';

const searchResults = (value) => (
  {
    kb: {
      name: t('search.kb.category'),
      results: [
        {
          title: t('kb.filter.title', { value }),
          description: t('kb.filter.description'),
          value: 'kb-search',
        }],
    },
  }
);

export default class NavBarSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      results: [],
      value: '',
    };
    this.handleResultSelect = this.handleResultSelect.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  handleResultSelect(e, { result }) {
    const url = `https://knowledgebase.aridhia.io/search?s=${encodeURIComponent(this.state.value)}`;
    window.open(url, 'knowledgebase');
  }

  handleSearchChange(e, { value }) {
    const results = value.trim().length > 0
      ? Object.assign({}, searchResults(value))
      : [];

    this.setState({
      isLoading: false,
      results,
      value,
    });
  }

  render() {
    const { isLoading, value, results } = this.state;

    return (
      <Search
        className="navbar-search"
        category
        selectFirstResult
        showNoResults={false}
        loading={isLoading}
        onResultSelect={this.handleResultSelect}
        onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
        results={results}
        value={value}
        input={{
          maxLength: 50,
          icon: 'search',
          placeholder: t('workspace.search'),
        }}
      />
    );
  }
}
