/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback } from 'react';
import { t } from 'i18n-js';
import {
  Form,
  Dropdown,
  Input,
  Radio,
} from 'semantic-ui-react';
import { useDatabaseTableCreationContext } from '../Context';
import { hasError } from '../../../../utilities/validation';
import { validationLengths } from '../../../../constants/validation';
import { columnTypes, typeOptions } from '../constants';
import { setColumnProperty } from '../actions';

export const ColumnNameAttribute = memo(({ column }) => {
  const { newDatabaseTableState, dispatch } = useDatabaseTableCreationContext();

  const handleColumnNameChange = useCallback((e, { id, value }) => {
    dispatch(setColumnProperty(id, value, 'name'));
  }, [column, dispatch]);

  return (
    <Input
      id={`name-${column.id}`}
      placeholder={t('database_tables_new.columns.column_name')}
      maxLength={validationLengths.TABLE_NAME}
      onChange={handleColumnNameChange}
      value={column.name || ''}
      error={hasError(newDatabaseTableState.validation_errors, `name-${column.id}`)}
      disabled={column.primary_added}
    />
  );
});

export const ColumnTypeOptionsAttribute = memo(({ column }) => {
  const { newDatabaseTableState, dispatch } = useDatabaseTableCreationContext();

  const handleColumnTypeChange = useCallback((e, { id, value }) => {
    dispatch(setColumnProperty(id, value, 'type'));
  }, [column, dispatch]);

  return (
    <Dropdown
      id={`type-${column.id}`}
      placeholder={t('database_tables_new.columns.column_type')}
      options={typeOptions}
      onChange={handleColumnTypeChange}
      value={column.type || columnTypes.TEXT}
      selection
      fluid
      upward={false}
      disabled={column.primary_added || newDatabaseTableState.table.typeguessing_in_progress}
      loading={newDatabaseTableState.table.typeguessing_in_progress}
      error={hasError(newDatabaseTableState.validation_errors, `type-${column.id}`)}
    />
  );
});

export const ColumnLabelAttribute = memo(({ column }) => {
  const { dispatch } = useDatabaseTableCreationContext();

  const handleColumnLabelChange = useCallback((e, { id, value }) => {
    dispatch(setColumnProperty(id, value, 'label'));
  }, [column, dispatch]);

  return (
    <Form.Input
      id={`label-${column.id}`}
      placeholder={t('database_tables_new.columns.column_label')}
      maxLength={validationLengths.NAME}
      onChange={handleColumnLabelChange}
      value={column.label || ''}
      disabled={column.primary_added}
    />
  );
});

export const ColumnPrimaryKeyAttribute = memo(({ column }) => {
  const { newDatabaseTableState, dispatch } = useDatabaseTableCreationContext();

  const handleColumnPrimaryKeyChange = useCallback((e, { checked, id }) => {
    dispatch(setColumnProperty(id, checked, 'primary'));
  }, [column, newDatabaseTableState.table, dispatch]);

  return (
    <Radio
      className={column.type === 'boolean' ? 'disabled' : ''}
      id={`primary-key-${column.id}`}
      onClick={handleColumnPrimaryKeyChange}
      checked={column.primary || false}
      label={t('database_tables_new.columns.column_primary_key')}
      disabled={column.type === 'boolean'}
    />
  );
});

export const ColumnExcludeAttribute = memo(({ column }) => {
  const { dispatch } = useDatabaseTableCreationContext();

  const handleColumnExcludeChange = useCallback((e, { checked, id }) => {
    dispatch(setColumnProperty(id, checked, 'disabled'));
  }, [column, dispatch]);

  return (
    <Form.Checkbox
      id={`exclude-column-checkbox-${column.id}`}
      data-testid={`exclude-column-checkbox-${column.id}`}
      name={`checkboxExcludeColumn${column.id}`}
      value="false"
      disabled={column.primary || column.primary_added}
      onChange={handleColumnExcludeChange}
      checked={column.disabled || false}
      label={t('database_tables_new.columns.column_exclude')}
    />
  );
});
