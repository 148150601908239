import Base from './base_view';
import LoadingSection from './core/loading_section_view';

export default Base.extend({
  displayLoadingSection() {
    if (!this.useLoadingSection) {
      return false;
    }
    if (this.requiredResources.length > 0) {
      return !this.requiredResources.allResponded();
    }
    return this.resource && !this.resource.loaded;
  },

  makeLoadingSectionView() {
    return new LoadingSection();
  },
});
