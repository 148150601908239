import chorus from '../chorus';
import t from '../intl';
import DatasetStatistics from './dataset_statistics';
import DatabaseColumnSet from '../collections/database_column_set';
import DatasetAnalyze from './dataset_analyze';
import DatasetMeta from './dataset_meta';
import BoxplotTask from './tasks/boxplot_task';
import FrequencyTask from './tasks/frequency_task';
import HeatmapTask from './tasks/heatmap_task';
import HistogramTask from './tasks/histogram_task';
import TimeseriesTask from './tasks/timeseries_task';
import Model from './models';
import { DataSourceCredentialsModel } from '../mixins/data_source_credentials';
import Taggable from '../mixins/tags_mixin';

/* eslint no-underscore-dangle: ["error",
{ "allow": [
    "_workspace",
    "_metadata",
    "_statistics",
    "_columns",
    "_analyze",
    "_super"
] }] */

export default Model.include(DataSourceCredentialsModel, Taggable).extend({
  nameAttribute: 'objectName',
  constructorName: 'Dataset',
  entityType: 'dataset',

  showUrlTemplate: 'datasets/{{id}}',

  urlTemplate(options) {
    if (options && options.download) {
      return 'api/workspaces/datasets/{{id}}/download.csv';
    }

    return 'api/workspaces/datasets/{{id}}';
  },

  initialize() {
    this.bind('invalidated', this.refetchAfterInvalidated, this);

    if (!this.has('entitySubtype')) {
      this.set({ entitySubtype: this.get('datasetType') || 'SOURCE_TABLE' }, { silent: true });
    }
  },

  metaType() {
    return this.constructor.metaTypeMap[this.get('objectType')] || 'table';
  },

  isExternal() {
    const objectType = this.statistics().get('objectType');
    return (/EXT_TABLE$/).test(objectType);
  },

  columns(options) {
    if (!this._columns) {
      this._columns = new DatabaseColumnSet([], {
        id: this.get('id'),
        type: options && options.type,
        dataset: this,
      });

      this._columns.dataset = this;
      const objectNameField = `${this.metaType()}Name`;
      this._columns.attributes[objectNameField] = (this.metaType() === 'query') ? this.get('id') : this.name();
    }
    return this._columns;
  },

  setSchema(newSchema) {
    this.set('schema', newSchema.attributes);
  },

  fullName() {
    return this.name();
  },

  statistics() {
    if (!this._statistics) {
      this._statistics = new DatasetStatistics({ datasetId: this.id });
    }

    return this._statistics;
  },

  metadata() {
    if (!this._metadata) {
      this._metadata = new DatasetMeta({ datasetId: this.id });
    }

    return this._metadata;
  },

  iconUrl(options) {
    const size = (options && options.size) || 'large';
    const name = this.constructor.iconMap[this.get('entitySubtype')][this.get('objectType')];
    return `/images/${name}_${size}.png`;
  },

  urlParams() {
    if (this.attributes.includeDependencies !== undefined) { // the whole DELETE call gives a false positive if this is missing
      return { includeDependencies: this.attributes.includeDependencies };
    }

    return null;
  },

  download(options) {
    const data = { };
    if (options && options.rowLimit) {
      data.row_limit = options.rowLimit;
    }

    chorus.fileDownload(this.url({ download: true }), { data });
  },

  isChorusView() {
    return false;
  },

  refetchAfterInvalidated() {
    this.collection && this.fetch();
  },

  quotedName() {
    return this.safePGName(this.name());
  },

  clone() {
    const temp = this._super('clone');
    // We really wanted a shallow clone so set the _workspace back if its available
    if (this._workspace) {
      temp._workspace = this._workspace;
    }
    return temp;
  },

  selectName() {
    if (this.aliasedName) {
      return this.aliasedName;
    }
    return this.quotedName();
  },

  setDatasetNumber(number) {
    this.datasetNumber = number;
    this.aliasedName = String.fromCharCode(96 + this.datasetNumber);
  },

  clearDatasetNumber() {
    delete this.datasetNumber;
    delete this.aliasedName;
  },

  alias() {
    return this.aliasedName || this.quotedName();
  },

  aliased() {
    return this.datasetNumber || this.has('query');
  },

  hasCredentials() {
    return true;
  },

  supportsAnalyze() {
    return this.isTable();
  },

  isTable() {
    return this.get('objectType') === 'TABLE';
  },

  analyze() {
    if (!this._analyze) {
      this._analyze = new DatasetAnalyze({
        tableId: this.get('id'),
      });
    }

    return this._analyze;
  },

  isImporting() {
    /*
     * importStatus: 0 = pending, 1 = in_progress, 2 = completed, 3 = error
     */
    if (this.get('importStatus') === null) {
      return false;
    }
    return this.get('importProgress') < 100 && this.get('importStatus') < 2;
  },

  makeBoxplotTask(taskAttrs) {
    return new BoxplotTask({
      xAxis: taskAttrs.xAxis,
      yAxis: taskAttrs.yAxis,
      dataset: this,
      bins: taskAttrs.bins,
    });
  },

  makeFrequencyTask(taskAttrs) {
    return new FrequencyTask({
      yAxis: taskAttrs.yAxis,
      dataset: this,
      bins: taskAttrs.bins,
    });
  },

  makeHistogramTask(taskAttrs) {
    return new HistogramTask({
      bins: taskAttrs.bins,
      xAxis: taskAttrs.xAxis,
      dataset: this,
    });
  },

  makeHeatmapTask(taskAttrs) {
    return new HeatmapTask({
      xBins: taskAttrs.xBins,
      yBins: taskAttrs.yBins,
      xAxis: taskAttrs.xAxis,
      yAxis: taskAttrs.yAxis,
      dataset: this,
    });
  },

  makeTimeseriesTask(taskAttrs) {
    return new TimeseriesTask({
      xAxis: taskAttrs.xAxis,
      yAxis: taskAttrs.yAxis,
      aggregation: taskAttrs.aggregation,
      timeInterval: taskAttrs.timeInterval,
      dataset: this,
      timeType: taskAttrs.timeType,
    });
  },

  humanType() {
    return t(['dataset.entitySubtypes', this.get('entitySubtype'), this.get('objectType')].join('.'));
  },
}, {
  metaTypeMap: {
    TABLE: 'table',
    VIEW: 'view',
    EXTERNAL_TABLE: 'table',
    MASTER_TABLE: 'table',
    CHORUS_VIEW: 'query',
    MASK: 'mask',
  },

  iconMap: {
    CHORUS_VIEW: {
      CHORUS_VIEW: 'chorus_view',
    },

    SOURCE_TABLE: {
      TABLE: 'source_table',
      EXTERNAL_TABLE: 'source_table',
      MASTER_TABLE: 'source_table',
      VIEW: 'source_view',
    },

    SANDBOX_TABLE: {
      TABLE: 'sandbox_table',
      EXTERNAL_TABLE: 'sandbox_table',
      MASTER_TABLE: 'sandbox_table',
      VIEW: 'sandbox_view',
    },
  },
});
