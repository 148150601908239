import BasePlugin from '@uppy/core/lib/BasePlugin.js'
import ResumableUploader from './resumable_uploader';
import { BlobUploader, validateFile } from './azure_upload';

class AzureBlob extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.type = 'uploader';
    this.id = `AzureBlob${Math.random() * 10000}`;
  }

  install() {
    this.uppy.use(ResumableUploader, Object.assign({}, this.opts, {
      uploadClass: BlobUploader,
      validateFile,
    }));
  }
}

export default AzureBlob;
