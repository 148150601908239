import $ from '../../jquery';
import TagBox from '../tags/tag_box_view';
import { workspace } from '../../utilities/workspace_assoc';
import View from '../loading_view';
import secureImage from '../../utilities/secure_image';

export default View.extend({
  templateName: 'workfile_header',
  constructorName: 'WorkfileHeaderView',
  additionalClass: 'taggable_header',
  bindCallbacks: $.noop,
  secureLoad: null,

  subviews: {
    '.tag_box': 'tagBox',
  },

  setup() {
    // this.tagBox = new TagBox({
    //   model: this.model,
    //   workspaceIdForTagLink: workspace(this.model).id,
    // });
    // XAP-11157 - Temp disabling of tags
    this.tagBox = false;

    this.listenTo(this.model, 'change:removed', this.render);
    this.listenTo(this.model, 'change:fileName', this.render);
  },

  additionalContext() {
    let iconUrl = this.model.iconUrl({ size: 'large' });
    if (iconUrl.startsWith('api')) {
      this.secureLoad = iconUrl;
      iconUrl = '/images/workfiles/large/img.png';
    }
    const ctx = {
      fileName: this.model && this.model.getFileName(),
      iconUrl
    }
    return ctx;
  },

  postRender() {
    if (this.secureLoad) {
      secureImage(this.$('img'), this.secureLoad);
    }
  },
});
