import { Table, Icon } from 'semantic-ui-react';
import { t } from 'i18n-js';

const FolderNameCell = ({
  name,
  onClick,
}) => (
  <Table.Cell className="aridhia-table-name-cell" colSpan={2} disabled={!onClick} onClick={onClick}>
    <Icon name="folder" color="teal" />
    {name}
  </Table.Cell>
);

const FileNameCell = ({
  name,
  onClick,
  selectedFile,
}) => {
  const className = name === selectedFile ? 'aridhia-table-name-cell active' : 'aridhia-table-name-cell';

  return (
    <Table.Cell className={className} colSpan={2} disabled={!onClick} onClick={onClick}>
      <Icon name="file" />
      {name}
    </Table.Cell>
  );
};

const FileNavigationTableRow = ({
  name,
  type,
  onClick,
  selectedFile,
}) => (
  <Table.Row selected aria-label={t('files.accessibility.files_table_row')}>
    {type === 'folder' && (
      <FolderNameCell
        name={name}
        onClick={onClick}
      />
    )}
    {type !== 'folder' && (
      <FileNameCell
        name={name}
        onClick={onClick}
        selectedFile={selectedFile}
      />
    )}
  </Table.Row>
);

export default FileNavigationTableRow;
