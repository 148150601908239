import _ from '../../underscore';
import $ from '../../jquery';
import View from '../loading_view';

export default View.extend({
  constructorName: 'MainContentView',
  templateName: 'main_content',

  setup(options) {
    const opts = options || {};
    this.scrollPanelDisable = opts.scrollPanelDisable || false;
    this.contentHeader = opts.contentHeader;
    this.contentDetails = opts.contentDetails;
    this.content = opts.content;
    this.contentFooter = opts.contentFooter;
  },

  subviews: {
    '.content_header > div': 'contentHeader',
    '.content_details': 'contentDetails',
    '.content > div': 'content',
    '.content_footer > div': 'contentFooter',
  },
  additionalContext() {
    return {
      pageTitle: this.options.pageTitle,
    };
  },
  postRender() {
    this._super('postRender');
    // Break out of full screen mode if they are not viewing tab content
    if (!$('.logged_in_layout_with_tabs').length) {
      $('#page').removeClass('app-page-fullscreen').removeClass('app-page-fullscreen-menu');
    }
    if (!this.contentDetails) { this.$('.content_details').addClass('hidden'); }
    if (!this.content) { this.$('.content').addClass('hidden'); }
    if (!this.contentFooter) { this.$('.content_footer').addClass('hidden'); }
  },

});
