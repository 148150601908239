import React from 'react';
import { BackboneProvider } from 'connect-backbone-to-react';
import Uppy from '@uppy/core';
import Confirm from '../../dialogs/confirm_upload_public_inbox';
import InboundAirlockModel from 'models/inbound_airlock';
import InboundAirlockContainer from 'components/inbound_airlock/InboundAirlockContainer';
import InboundAirlockDestinationDialog from '../../dialogs/inbound_airlock/inbound_airlock_destination_dialog';
import Azure from '../../azure-uppy-plugin/uppy_azure_uploader_plugin';
import View from '../react_view';
import chorus from '../../chorus';
import $ from '../../jquery';
import t from '../../intl';
import { instance }  from '../../models/config';

export default View.extend({
  bindCallbacks: $.noop,
  className: 'app-inboundAirlockShow',

  setup(options) {
    const {
      model, tab, folder, batchId,
    } = options;
    this.workspace = model;
    this.closeTab = tab.remove.bind(tab);
    this.tab = tab;
    this.folderPath = folder;
    this.batchId = batchId;

    this.uppy = new Uppy({
      logger: {
        debug: (...args) => {},
        warn: (...args) => {},
        error: (...args) => {},
      },
      allowMultipleUploadBatches: false,
      onBeforeUpload: (_files) => {
        const uppyOpts = {
          presignEndpoint: `/api/workspaces/workspaces/${model.get('id')}/upload`,
          prefix: this.inboundAirlockModel.get('folder'),
          batchId,
        };
        this.uppy.use(Azure, uppyOpts);
        return true;
      },
    });

    this.uppy.on('upload-error', (_file, error) => {
      let errorMessage;
      switch (error.response.status) {
        case 412:
        case 400:
          errorMessage = 'inbound_airlock.upload_failed_dsa_400';
          break;
        case 403:
          errorMessage = 'inbound_airlock.upload_failed_dsa_403';
          break;
        case 404:
        case 503:
          errorMessage = 'inbound_airlock.upload_failed_dsa_404_503';
          break;
        default:
          errorMessage = 'inbound_airlock.upload_failed_generic';
      }
      chorus.toast(errorMessage, {
        statusText: error.response.statusText,
        folder: this.inboundAirlockModel.get('folder'),
        serviceDesk: t('inbound_airlock.upload_failed.service_desk'),
        toastOpts: { theme: 'bad_activity' },
      });
    });

    this.uppy.on('upload', () => {
      tab.set('unsaved', true);
      tab.set('close_confirmation_required', t('inbound_airlock.tab.close.warning'));
    });

    this.uppy.on('complete', () => {
      chorus.PageEvents.trigger('workfiles:changed');
      tab.set('unsaved', false);
      tab.set('close_confirmation_required', null);
      this.setup({
        model: this.workspace,
        tab: this.tab,
        folder: this.folderPath,
        batchId: this.batchId,
      });
    });

    const sendToInbox = folder.split('/')[0] === 'inbox';
    const sendToPublicInbox = folder.split('/')[0] === 'public inbox';
    this.inboundAirlockModel = new InboundAirlockModel({
      uppy: this.uppy,
      folder,
      origFolder: sendToInbox || sendToPublicInbox ? 'files' : folder,
      sendToInbox,
      sendToPublicInbox,
      uploadToInboxOnly: options.uploadToInboxOnly,
    });
    
    if (instance.get('topLevelFolders').includes('public inbox')) {
      let url = instance.get('canarycallUrl');
      var self = this;
      this.canarycall(url)
      .then((response) => {
        self.inboundAirlockModel.set({ 
          canUploadToInbox: true, 
          canaryCallDone: true,
          folder: sendToPublicInbox ? 'files' : folder,
          sendToPublicInbox: false,
        });
      })
      .catch((err) => {
        let tempInbox = self.inboundAirlockModel.get('sendToInbox');
        self.inboundAirlockModel.set({ 
          canUploadToInbox: false,
          sendToInbox: false,
          folder: sendToInbox ? 'public inbox' : folder,
          canaryCallDone: true,
          origFolder: sendToInbox || sendToPublicInbox  ? 'files' : folder,
        });
        if (tempInbox) {
          self.inboundAirlockModel.set({
            sendToPublicInbox: true,
          });
        }
        const confirm = new Confirm({});
        confirm.launchModal();
      });
    } else {
      this.inboundAirlockModel.set({ canUploadToInbox: true, canaryCallDone: true });
    }
  },

  teardown() {
    if (this.uppy) {
      this.uppy.close();
    }
    this._super('teardown');
  },

  renderOnce() {
    const modelsMap = {
      inboundAirlock: this.inboundAirlockModel,
    };

    const launchSelectDestination = (e) => {
      if (e) { e.preventDefault(); }

      const dialog = new InboundAirlockDestinationDialog({
        workspace: this.workspace,
        folder: this.inboundAirlockModel.get('origFolder'),
        inboundAirlockModel: this.inboundAirlockModel,
        tab: this.tab,
      });
      dialog.launchModal();
    };

    this.root.render(
      <BackboneProvider models={modelsMap}>
        <InboundAirlockContainer onClose={this.closeTab} launchSelectDestination={launchSelectDestination} />
      </BackboneProvider>,
    );
  },

  canarycall(url) {
    return new Promise((resolve, reject) => {
      const call = new XMLHttpRequest();
      call.open('GET', url);
      call.onload = () => call.status === 200 ? resolve(call) : reject(Error(call));
      call.onerror = (e) => reject(Error(e));
      call.send();
    });
  },
});
