import t from '../intl';
import ModelDelete from './model_delete_alert';

export default ModelDelete.extend({
  constructorName: 'DataSourceDelete',

  title() {
    return t('data_sources.delete.title', { datasourceName: this.name });
  },
  ok: t('actions.delete_data_source'),
  deleteMessage: 'data_sources.delete.toast',

  deleteMessageParams() {
    return {
      dataSourceName: this.model.name(),
    };
  },

  text() {
    return t(`data_sources.delete.text.${this.model.get('entityType')}`);
  },
});
