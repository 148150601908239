import { useEffect, useState } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { useTables } from '../service';
import { usePermissions } from '../../../../permissions';
import { actions, instances } from '../../../../permissions/constants';
import DatabaseTablesTable from './DatabaseTablesTable';
import { PanelHeader, DefaultPanelHeaderIcon } from '../../../../layout';
import { DatabaseTablesProvider } from '../Context';
import { selectedItemsChecker } from '../../../../utilities/helper';
import NotAuthorised from '../../../../components/errors/NotAuthorised';
import { orderTypes } from '../../../constants';
import DatabaseTablesHeaderButtons from './DatabaseTablesHeaderButtons';

const DatabaseTables = ({ panelWidth }) => {
  const [page, setPage] = useState(1);
  const [filterTerm, setFilterTerm] = useState('');
  const [orderBy, setOrderBy] = useState(orderTypes.DATABASE_TABLE_NAME);
  const [selectedTables, setSelectedTables] = useState([]);
  const {
    tables,
    pagination,
    isFetching,
    isError,
    refetch,
  } = useTables(page, filterTerm, orderBy);

  useEffect(() => {
    if (page > pagination.total) { setPage(pagination.total); }
  }, [pagination, page]);

  const handleFilterChange = (value) => {
    setFilterTerm(value);
    setPage(1);
  };

  // Need to strip out direction from default Sort before passing to API
  const handleSort = ([column]) => { setOrderBy(column); };

  const handleRefreshButton = () => {
    refetch();
    setPage(1);
  };

  const handleSelectedTables = (e, { value, checked, name }) => {
    setSelectedTables(selectedItemsChecker(selectedTables, value, checked, name, tables));
  };

  const state = {
    tables,
    isFetching,
    isError,
    pagination,
    selectedTables,
    setSelectedTables,
    filterTerm,
    handleFilterChange,
    setPage,
    handleRefreshButton,
    handleSort,
    handleSelectedTables,
    panelWidth,
  };

  if (!usePermissions(actions.READ, instances.DATABASE)) {
    return (<NotAuthorised />);
  }

  return (
    <DatabaseTablesProvider value={state}>
      <Segment.Group
        className="aridhia-panel-wrapper"
        data-testid="database-tables-panel"
      >
        <PanelHeader
          headerIcon={<DefaultPanelHeaderIcon name="list alternate" />}
          headerText={(
            <Header as="h3" className="truncate">
              <Header.Content title={t('tables_list.accessibility.table_list')}>
                {t('tables_list.accessibility.table_list')}
              </Header.Content>
            </Header>
          )}
          headerButtons={<DatabaseTablesHeaderButtons />}
        />
        <Segment
          attached
          as="article"
          className="aridhia-panel-content"
        >
          <DatabaseTablesTable />
        </Segment>
      </Segment.Group>
    </DatabaseTablesProvider>
  );
};

export default DatabaseTables;
