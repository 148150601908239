import DatasetFilter from './dataset_filter_view';
import FilterWizard from './filter_wizard_view';
import DatasetFilterSet from '../../collections/dataset_filter_set';
import DatasetFilterModel from '../../models/dataset_filter';

export default FilterWizard.extend({
  title: 'dataset.filter.title',
  constructorName: 'DatasetFilterWizard',

  filterView(filter) {
    return new DatasetFilter({
      model: filter,
      collection: this.columnSet,
      showAliasedName: this.options.showAliasedName,
    });
  },

  filterModel() {
    return new DatasetFilterModel();
  },

  filterCollection() {
    return new DatasetFilterSet();
  },
});
