import Backbone from 'backbone';
import ContainerisedApp from '../models/containerised_app';

const ContainerisedAppList = Backbone.Collection.extend({
  model: ContainerisedApp,

  initialize(_models, { workspace }) {
    this.workspace = workspace;
  },

  url(_options) {
    const workspaceId = this.workspace.get('id');
    return `api/workspaces/workspaces/${workspaceId}/containerised_app/`;
  },

  parse(response, _options) {
    return response.response;
  },
});

export default ContainerisedAppList;
