import View from '../loading_view';

export default View.extend({
  constructorName: 'SearchResultCommentList',
  templateName: 'search_result_comment_list',

  events: {
    'click a.show_more_comments': 'showMoreComments',
    'click a.show_fewer_comments': 'showFewerComments',
  },

  setup() {
    const comments = this.options.comments || [];
    const columns = this.options.columns || [];
    const columnDescriptions = this.options.columnDescriptions || [];
    const tableDescription = this.options.tableDescription || [];

    this.collection = comments.concat(columns).concat(columnDescriptions).concat(tableDescription);
  },

  showMoreComments(e) {
    e && e.preventDefault();
    this.$('.has_more_comments').addClass('hidden');
    this.$('.more_comments').removeClass('hidden');
  },

  showFewerComments(e) {
    e && e.preventDefault();
    this.$('.has_more_comments').removeClass('hidden');
    this.$('.more_comments').addClass('hidden');
  },

  additionalContext() {
    const comments = this.collection || [];
    return {
      comments: comments.slice(0, 3),
      moreComments: comments.slice(3),
      hasMoreComments: Math.max(0, comments.length - 3),
    };
  },
});
