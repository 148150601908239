import React from 'react';
import { BackboneProvider } from 'connect-backbone-to-react';
import $ from '../../jquery';
import View from '../loading_view';
import FetchErrors from '../../mixins/fetch_errors';
import DatasetMetadataContainer from '../../../components/dataset/DatasetMetadataContainer';
import t from '../../intl';
import chorus from '../../chorus';
import _ from '../../underscore';
import ButtonState from '../../models/button_state';
import Routing from '../../mixins/routing';
import WorkfilesAttachDialog from '../../dialogs/workfiles/workfiles_attach_dialog';
import MetadataCopyFromDataset from '../../dialogs/dataset/dataset_metadata_copy_from_dataset_dialog';
import MetadataExportToFile from '../../dialogs/dataset/dataset_metadata_export_to_file_dialog';

export default View.include(Routing, FetchErrors).extend({
  bindCallbacks: $.noop,
  className: 'app-datasetMetadataShow',

  setup(options) {
    this.tab = options.tab;
    this.buttonState = new ButtonState();

    this.workspace = options.workspace;
    this.model = options.dataset;
    this.columnSet = options.columns.clone();

    this.listenTo(this.columnSet, 'loaded', () => { this.trigger('change:loaded') });
    this.listenTo(this, 'change:loaded', () => { if (this.datasetMeta) this.render() });

    this.model.fetchIfNotLoaded();
    this.handleFetchErrorsFor(this.model);
    this.onceLoaded(this.model, () => {
      this.tab.set('label', t('dataset.meta.tab', { datasetName: this.model.get('objectName') }));
      this.datasetLoaded();
    });
  },

  datasetLoaded() {
    this.columnSet.fetchAll();
    this.handleFetchErrorsFor(this.columnSet);
    this.onceLoaded(this.columnSet, () => {
      this.datasetMeta = this.columnSet.attributes.dataset.metadata();
      this.columnSetLoaded();
    });
  },

  columnSetLoaded() {
    this.datasetMeta.fetch({ error: () => {
      this.datasetMeta.prepare(this.columnSet, this.model);
    }});
    this.onceLoaded(this.datasetMeta, () => {
      this.datasetMeta.loaded = true;
      this.datasetMeta.prepare(this.columnSet, this.model);
    });
  },

  changeMetaDataFromFile(file) {
    const workfile = file[0].clone();
    this.setLoaded(false);
    workfile.setVersion('latest', { includeContent: true, contentIsMetadata: true });
    this.handleFetchErrorsFor(workfile);
    this.onceLoaded(workfile, () => {
      if (workfile.content().DatasetDefinition) {
        this.datasetMeta.set({
          content: workfile.content(),
          copiedFromWorkfileId: workfile.id,
          copiedFromDatasetId: null,
        }, { silent: true });
        this.datasetMeta.prepare(this.columnSet, this.model, this.datasetMeta);
      } else {
        chorus.toast('dataset.meta.copy_from_workfile.error', {
          toastOpts: { theme: 'bad_activity' },
        });
      }
    });
  },

  changeMetadataFromDataset(dataset) {
    this.setLoaded(false);

    const selectedDataset = dataset[0];
    selectedDataset.fetchIfNotLoaded();
    this.handleFetchErrorsFor(selectedDataset);
    this.onceLoaded(selectedDataset, () => {
      const selectedMetadata = selectedDataset.metadata();
      selectedMetadata.fetch({
        error: () => {
          chorus.toast('dataset.meta.copy_from_dataset.error', {
            toastOpts: { theme: 'bad_activity' },
          });
          this.setLoaded(true);
        },
      });
      this.onceLoaded(selectedMetadata, () => {
        this.datasetMeta.set({
          copiedFromWorkfileId: null,
          copiedFromDatasetId: selectedMetadata.get('datasetId'),
        }, { silent: true });
        this.datasetMeta.prepare(this.columnSet, this.model, selectedMetadata);
      });
    });
  },

  launchMetadataImportFromFile(e) {
    if (e) { e.preventDefault(); }
    const workfileDialog = new WorkfilesAttachDialog({
      workspace: this.workspace,
      crumbs: true,
      title: t('dataset.meta.import_from_file.modal_header'),
      submitButtonTranslationKey: 'actions.confirm',
      multiSelection: false,
    });
    workfileDialog.on('files:selected', _.bind(this.changeMetaDataFromFile, this));
    workfileDialog.launchModal();
  },

  launchMetadataCopyFromDataset(e) {
    if (e) { e.preventDefault(); }
    const dialog = new MetadataCopyFromDataset({
      workspaceId: this.workspace.id,
      withMetadata: true,
      excludeDataset: this.model.id,
    });

    dialog.bind('dataset:selected', this.changeMetadataFromDataset, this);
    dialog.launchModal();
  },

  launchMetadataExportToFile(e) {
    if (e) { e.preventDefault(); }
    const dialog = new MetadataExportToFile({
      workspace: this.workspace,
      datasetId: this.model.id,
      fileName: this.model.get('objectName'),
      crumbs: true,
    });
    dialog.launchModal();
  },

  setLoaded(loadedState) {
    this.columnSet.loaded = loadedState;
    this.trigger('change:loaded');
  },

  render() {
    const onSave = (e) => {
      if (e) { e.preventDefault(); }
      this.setLoaded(false);
      this.buttonState.set('saving', true);
      this.datasetMeta.save({}, {
        silent: true,
        success: function onSuccess() {
          chorus.toast('dataset.meta.success');
          chorus.PageEvents.trigger('datasetmeta:changed');
          this.tab.remove();
          this.navigate(`/workspaces/${this.model.get('workspace').id}/datasets/${this.model.get('id')}`);
        }.bind(this),
        error: function onFail(_model, response) {
          this.buttonState.set('saving', false);
          this.setLoaded(true);
          chorus.toast('dataset.meta.failed', {
            reason: response.statusText,
            toastOpts: { theme: 'bad_activity' },
          });
        }.bind(this),
      });
    };

    const onClose = () => {
      this.tab.remove();
    };

    const toggleModal = (modal) => {
      switch (modal) {
        case 'metadata_import_from_file_modal':
          this.launchMetadataImportFromFile();
          break;
        case 'metadata_copy_from_dataset_modal':
          this.launchMetadataCopyFromDataset();
          break;
        case 'metadata_export_to_file_modal':
          this.launchMetadataExportToFile();
          break;
        default:
      }
    };

    const modelsMap = {
      workspace: this.workspace,
      dataset: this.model,
      buttonState: this.buttonState,
    };

    if (this.columnSet.loaded) {
      modelsMap.columnSet = this.columnSet;
      modelsMap.datasetMeta = this.datasetMeta;
    }

    this.root.render(
      <BackboneProvider models={modelsMap}>
        <DatasetMetadataContainer
          onClose={onClose}
          onSave={onSave}
          toggleModal={toggleModal}
        />
      </BackboneProvider>,
    );

    this.postRender();
    return this;
  },

});
