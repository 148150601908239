import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import LinkMenu from '../core/link_menu_view';
import View from '../loading_view';
import Routing from '../../mixins/routing';
import { FEATURE } from '../../../utilities/features';

export default View.include(Routing).extend({
  templateName: 'tabs',
  fullscreenMode: false,
  bindCallbacks: $.noop,

  events: {
    'click .app-closeTab': 'destroyTab',
    'click .app-fullscreen-toggle': 'showFullscreen',
    'click .app-page-fullscreen_close': 'closeFullscreen',
    'click .app-page-fullscreen_menu_toggle': 'toggleFullscreenMenu',
  },

  additionalContext() {
    return {
      hasMenu: FEATURE.NEW_UX ? false : this.options.menuOptions.options.length > 0,
      hasNewUX: FEATURE.NEW_UX,
    };
  },

  setup(options) {
    this.listenTo(this.collection, 'change:active', this.changeTab);
    this.listenTo(this.collection, 'change:label change:url change:fileType', this.changeLabel);
    this.listenTo(this.collection, 'change:unsaved', this.setSavedState);
    this.listenTo(this.collection, 'add', this.addTab);
    this.listenTo(this.collection, 'remove', this.removeTab);
    this.menu = new LinkMenu(options.menuOptions);
  },

  checkFullscreen() {
    if (this.fullscreenMode === true) {
      $('#page').css('display', 'none');
      setTimeout(() => {
        this.showFullscreen();
        $('#page').css('display', '');
      }, 500);
    } else {
      $('#page').removeClass('app-page-fullscreen');
      $('#page').removeClass('app-page-fullscreen-menu');
    }
  },

  setupSubviews() {
    let subviews = {
      '.app-btnWorkfileMenu': 'menu',
    };

    if (!FEATURE.NEW_UX) {
      subviews = { ...subviews, '#ribbon': 'ribbon' };
    }

    $.each(this.collection.models, function mapCid() {
      subviews[`#tab-${this.cid} > div`] = this.cid;
    });
    this.subviews = subviews;
  },

  getSubview(property) {
    if (property === 'menu') {
      return this.menu;
    }
    if (FEATURE.NEW_UX && property === 'ribbon') {
      return this.ribbon;
    }

    const model = this.collection.find(m => m.cid === property);

    if (model) {
      if (model.get('active')) {
        if (model.get('no_sidebar')) {
          $(this.el).addClass('no-sidebar');
        }
        return model.getOrCreateView();
      }
      return model.get('view');
    }
  },

  showFullscreen() {
    $('#page').addClass('app-page-fullscreen');

    this.fullscreenMode = true;

    chorus.page.trigger('resized');
    _.delay(() => {
      chorus.page.trigger('resized');
    }, 600);

    return false;
  },

  toggleFullscreenMenu() {
    $('#page').toggleClass('app-page-fullscreen-menu');

    const title = $('.app-page-fullscreen_menu_toggle').attr('title');
    $('.app-page-fullscreen_menu_toggle').attr(
      'title', (
        title === 'Show Menu Bar' ? 'Hide Menu Bar' : 'Show Menu Bar'),
    );
  },

  closeFullscreen() {
    $('#page').removeClass('app-page-fullscreen');
    $('#page').removeClass('app-page-fullscreen-menu');
    this.fullscreenMode = false;
    chorus.page.trigger('resized');
  },

  changeTab(tab) {
    if (tab.get('active')) {
      this.$(`.tab-${tab.cid} > a`).tab('show');
      const view = tab.getOrCreateView();

      $(this.el)[tab.get('no_sidebar') ? 'addClass' : 'removeClass']('no-sidebar');

      if (view) {
        const element = this.$(`#tab-${tab.cid} > div`);
        this.renderSubviewToElement(element, view, true);
        this.checkFullscreen();
      }
    }
  },

  changeLabel(tab) {
    const context = _.extend({ model: tab }, tab.attributes);
    this.$(`li.tab-${tab.cid}`).replaceWith(window.HandlebarsTemplates.tab_item(context));
  },

  setSavedState(tab) {
    if (tab.get('unsaved')) {
      chorus.rsh.stateManager.unsavedFiles[tab.cid] = true;
      if (!tab.get('pinnedTab')) {
        this.$(`.tab-${tab.cid}`).addClass('unsaved');
      }
    } else {
      delete chorus.rsh.stateManager.unsavedFiles[tab.cid];
      if (!tab.get('pinnedTab')) {
        this.$(`.tab-${tab.cid}`).removeClass('unsaved');
      }
    }
  },

  addTab(model) {
    const context = _.extend({ model }, model.attributes);

    const tabItem = window.HandlebarsTemplates.tab_item(context);

    if (model.attributes.pinnedTab) {
      const selector = (typeof model.get('location') !== 'undefined') ?
        `ul.app-tabs>li.tab_icon:nth-child(${model.get('location')})` :
        'ul.app-tabs>li.tab_icon:last';
      this.$(selector).after(tabItem);
    } else {
      this.$('.app-newworkfile').before(tabItem);
    }


    this.$('div.app-tabContent').append(window.HandlebarsTemplates.tab_pane(context));
    this.setupSubviews();
  },

  removeTab(tab, collection, indexObj) {
    if (tab.get('active')) {
      const { index } = indexObj;
      this.navigateToNextTab(tab, collection.models, index);
    }

    const view = tab.get('view');
    if (view) {
      view.teardown();
    }
    this.$(`.tab-${tab.cid}`).remove();
    this.setupSubviews();
  },

  destroyTab(e) {
    e.preventDefault();
    const thisTab = this.$(e.target).parent();
    const cid = thisTab.data('target').substring(5);
    const { models } = this.collection;
    let model = null;
    let i = 1; // the first tab should not be closeable
    for (;i < models.length; i += 1) {
      if (models[i].cid === cid) {
        model = models[i];
        break;
      }
    }
    if (model !== null) {
      this.destroyTabModel(model);
    }
  },

  destroyTabModel(model, skipUnsavedConfirm) {
    const closeConfirmationMessage = model.get('close_confirmation_required');
    if (typeof closeConfirmationMessage === 'string') {
      if (window.confirm(closeConfirmationMessage)) {
        this.collection.remove(model);
        return true;
      }
      return false;
    }
    if (!model.get('unsaved') || skipUnsavedConfirm || window.confirm(t('rsh.generic.navigationWarning'))) {
      model.set('unsaved', false);
      this.collection.remove([model]);
      return true;
    }

    return false;
  },

  /*
        destroyTabWhere removes a tab which matches certain conditions. Only removes tabs which are
        closeable.
        param:
            attributes object: the attributes which are passed to the tab collection's findWhere
                method
            skipUnsavedConfirm Boolean: whether to present the user with a JS confirm dialogue if
                there're unsaved changes
        returns:
            true if the user accepted the closing or the tab was already saved
            false if the user declined the closing of the tab
            null if the attributes didn't match any tab
     */
  destroyTabWhere(attributes, skipUnsavedConfirm) {
    const model = this.collection.findWhere(attributes);
    if (model) {
      return this.destroyTabModel(model, skipUnsavedConfirm);
    }
    return null;
  },
  /*
        isTabUnsavedWhere checks to see if the first matching tab is unsaved.
        param:
            attributes object: the attributes which are passed to the tab collection's findWhere
                method
        returns:
            true if the tab is unsaved
            false if the tab is saved
            null if the attributes didn't match any tab
    */
  isTabUnsavedWhere(attributes) {
    const model = this.collection.findWhere(attributes);
    if (model) {
      return model.get('unsaved');
    }
    return null;
  },

  resolveNextTab(models, i) {
    let index = i;
    for (; index < models.length; index += 1) {
      const nextURI = models[index].get('url');
      if (nextURI) {
        return nextURI;
      }
    }
    return null;
  },

  resolvePreviousTab(model, models, i) {
    let index = i;
    // If the user is closing a mini-app tab, redirect them back to the mini-app list tab
    if (model.get('fileType') === 'mini_app') {
      const workspaceID = model.get('workspaceID');
      return `workspaces/${workspaceID}/mini_apps`;
    }

    // And same for containerised-app
    if (model.get('fileType') === 'app') {
      const workspaceID = model.get('workspaceID');
      return `workspaces/${workspaceID}/apps`;
    }


    let previousTab = 'Workfiles';
    if (model.get('fileType') === 'dataset' || model.get('fileType') === 'datasetmetadata') {
      previousTab = 'Datasets';
    }

    // Closing a workfile or results should return to workfiles
    // Closing a Dataset should return to datasets
    for (; index >= 0; index -= 1) {
      const nextRelatedURI = models[index].get('url');
      const name = models[index].get('name');
      if (name === previousTab && nextRelatedURI) {
        return nextRelatedURI;
      }
    }

    // All else fails go to the previous tab with a URL
    for (; index >= 0; index -= 1) {
      const nextURI = models[index].get('url');
      if (nextURI) {
        return nextURI;
      }
    }
    return null;
  },

  navigateToNextTab(model, models, i) {
    const nextURI = models[i - 1].get('url') ||
      this.resolveNextTab(models, i + 1) ||
      this.resolvePreviousTab(model, models, i - 1);
    this.navigate(nextURI);
  },
});
