import AceEditorView from '../core/ace_editor_view';
import View from '../loading_view';

export default View.extend({
  templateName: 'dataset_edit_chorus_view',
  constructorName: 'DatasetEditChorusView',

  subviews: {
    '.editor': 'editor',
  },

  setup() {
    this.adaptModelForCodeEditor();
    this.editor = new AceEditorView({
      model: this.model,
      mode: 'ace/mode/sql',
    });

    this.listenTo(this.model, 'dataset:saveEdit', this.saveModel);
    this.listenTo(this.model, 'dataset:cancelEdit', this.cancelEdit);
    this.model.initialQuery = this.model.get('query');
    this.listenTo(this.model, 'saved', this.navigateToChorusViewShowPage);
    this.listenTo(this.editor, 'blur', this.updateQueryInModel);
  },

  updateQueryInModel() {
    this.model.set({ query: this.editor.getValue() }, { silent: true });
  },

  saveModel() {
    // Don't want to navigate away on invalid model
    chorus.page.stopListening(this.model, 'unprocessableEntity');

    const query = this.editor.getValue();

    this.model.set({ query }, { silent: true });
    this.model.save(undefined, { silent: true });
  },

  cancelEdit() {
    delete this.model.serverErrors;
  },

  navigateToChorusViewShowPage() {
    this.model.trigger('dataset:cancelEdit');
  },

  adaptModelForCodeEditor() {
    this.model.content = function getQuery() { return this.get('query'); };
  },
});
