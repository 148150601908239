import React from 'react';
import { Input } from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';
import { ChorusIdentifierLower64 } from '../../utilities/validation_regexes';
import TooltipMessage from './TooltipMessage';

const DataTableNameForm = ({ dataTableName, handleDataTableNameChange, triggerErrorDataTableNameChange }) => {
  const error = !dataTableName || !dataTableName.match(ChorusIdentifierLower64());
  const component = (
    <>
      <label htmlFor="data_table_name" className="offscreen">{t('dataset.import.data_table_name')}</label>
      <Input
        id="data_table_name"
        defaultValue={dataTableName}
        onBlur={handleDataTableNameChange}
        onFocus={handleDataTableNameChange}
        onChange={triggerErrorDataTableNameChange}
        error={error}
        autoComplete="off"
        autoFocus={dataTableName && dataTableName.length > 0}
      />
    </>
  );
  return <TooltipMessage component={component} target="tableName" error={error} />;
};

const mapModelsToProps = ({ datasetUpload }) => ({
  dataTableName: datasetUpload.get('data_table_name'),
  handleDataTableNameChange: (_e) => {
    datasetUpload.updateDataTableName(_e.target.value);
  },
  triggerErrorDataTableNameChange: (_e) => {
    if (_e.target.value.length <= 1) {
      datasetUpload.updateDataTableName(_e.target.value);
    }
  },
});

const options = {
  debounce: true,
  events: {
    datasetUpload: ['change:data_table_name'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(DataTableNameForm);
