import _ from '../../underscore';
import ListHeaderView from '../core/list_header_view';

export default ListHeaderView.extend({
  constructorName: 'WorkfileFoldersHeaderView',
  templateName: 'workfiles_content_header',
  fileType: '',

  setup(options) {
    this.bind('choice:filter', function filterByType(choice) {
      this.fileType = choice;
      this.collection.trigger('changeType', this.fileType);
    }, this);

    this.bind('choice:sort', function sortFiles(choice) {
      const field = choice === 'alpha' ? 'fileName' : 'userModifiedAt';
      this.collection.sortAsc(field);
      this.collection.fetch();
    }, this);

    this.folder = options.folder;
  },

  postRender() {
    this._super('postRender');
    this.$('ul').find(`[data-folder='${this.folder.topLevelFolder()}']`).addClass('active');
    // temporarily disable the filter for inbox
    if (this.folder.topLevelFolder() === 'inbox') {
      $('.menus', '.workfiles_content_header').addClass('hidden');
      $('.chorus_search_container').css('display', 'none');
      setTimeout(function(){ $('.chorus_search_container').css('display', 'none'); }, 300);
    } else {
      $('.menus', '.workfiles_content_header').removeClass('hidden');
    }
  },

  crumbs() {
    return this.folder.crumbs();
  },

  setFolder(folder) {
    this.folder = folder;
    this.render();
  },

  additionalContext() {
    return {
      folders: this.folderOptions(),
    };
  },

  folderOptions() {
    const workspaceId = this.collection.workspace().id;
    return _.map(this.collection.workspace().workfileFolders(true), folder => ({
      data: folder,
      url: `#/workspaces/${workspaceId}/folders/${folder}`,
    }));
  },
});
