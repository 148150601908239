import ChartConfiguration from './chart_configuration_view';

export default ChartConfiguration.extend({
  templateName: 'chart_configuration',
  additionalClass: 'boxplot',

  columnGroups: [
    {
      type: 'numeric',
      name: 'value',
    },
    {
      type: 'all',
      name: 'category',
      options: true,
    },
  ],

  postRender() {
    this.$('.category option:eq(1)').attr('selected', 'selected');
    this._super('postRender');
  },

  chartOptions() {
    return {
      type: 'boxplot',
      name: this.model.get('objectName'),
      xAxis: this.$('.category select option:selected').text(),
      yAxis: this.$('.value select option:selected').text(),
      bins: this.$('.limiter .selected_value').text(),
    };
  },
});
