import chorus from '../chorus';
import $ from '../jquery';
import Modal from './modals';

export default Modal.extend({
  constructorName: 'Dialog',

  render() {
    if (this.hasBeenClosed) {
      return this;
    }

    this.preRender();

    const header = $("<div class='dialog_header'/>");
    const content = $("<div class='dialog_content'/>");
    const errors = $("<div class='errors hidden'/>");

    this.events = this.events || {};

    this.events['click .form_controls button.cancel'] = 'closeModal';

    header.html($('<h1/>').text(this.title));
    content.html(this.template(this.context()));
    content.attr('data-template', this.className);

    $(this.el)
      .empty()
      .append(header)
      .append(errors)
      .append(content)
      .addClass(this.className)
      .addClass('dialog')
      .addClass(this.additionalClass || '');
    this.delegateEvents();
    this.renderSubviews();
    this.renderHelps();
    this.postRender($(this.el));
    chorus.placeholder(this.$('input[placeholder], textarea[placeholder]'));

    return this;
  },

  modalClosed() {
    this._super('modalClosed');
    this.hasBeenClosed = true;
  },

  revealed() {
    $('#facebox').removeClass().addClass('dialog_facebox');
  },

  showDialogError(errorText) {
    const resource = this.resource || this.model;
    const serverErrors = resource.serverErrors || {};
    serverErrors.message = errorText;
    resource.serverErrors = serverErrors;
    this.displayServerErrors(resource);
  },
});
