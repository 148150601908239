import _ from '../underscore';
import Tag from '../models/tag';
import Collection from './collections';
import Model from '../models/models';

export default Collection.extend({
  urlTemplate: 'api/workspaces/taggings?entity_id={{entity.id}}&entity_type={{entity.entityType}}',
  model: Tag,
  constructorName: 'TaggingSet',

  containsTag(tagName) {
    return this.any(tag => tag.matches(tagName));
  },

  _tagNames() {
    return this.map(tag => tag.name());
  },

  add(models, options) {
    let matchingModels = _.isArray(models) ? models.slice() : [models];
    matchingModels = _.reject(matchingModels, function noMatchingTag(model) {
      const name = model instanceof Model ? model.get('name') : model.name;
      return this.containsTag(name);
    }, this);
    this._super('add', [matchingModels, options]);
  },
});
