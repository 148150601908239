import { marked } from 'marked';
import $ from '../../jquery';
import _ from '../../underscore';
import Handlebars from '../../vendor/handlebars';
import View from '../loading_view';
import { isRichText } from '../../../new_ux/utilities/editor';

export default View.extend({
  constructorName: 'TruncatedText',
  templateName: 'truncated_text',

  events: {
    'click .links a': 'toggleMore',
    'click .original a': 'openLink',
  },

  additionalContext() {
    let value = this.model.get(this.options.attribute);
    if (!isRichText(value) && value) {
      value = marked.parse(value);
    }
    if (this.options.attributeIsHtmlSafe && value) {
      value = new Handlebars.SafeString(value);
    }
    return {
      text: value,
    };
  },

  postRender() {
    this.show();
  },

  show() {
    let numLines = 2;

    _.defer(_.bind(function hideOverflow() {
      if (this.options.extraLine) {
        numLines += 1;
        this.$('.styled_text').addClass('extra_line');
      }

      const text = $(this.el).find('.original');
      const lineHeight = parseInt(text.css('line-height'), 10);
      const heightLimit = (lineHeight * numLines) + 6;
      const textHeight = text.prop('scrollHeight');
      if (textHeight && heightLimit && textHeight > heightLimit) {
        $(this.el).addClass('expandable');
        $(this.el).find('a.more').toggle();
      } else {
        $(this.el).removeClass('expandable');
      }
    }, this));
  },

  toggleMore(e) {
    e && e.preventDefault();
    e.stopPropagation();
    $(this.el).toggleClass('expanded');
    $(this.el).find('a.more').toggle();
    $(this.el).find('a.less').toggle();
  },

  openLink(e) {
    e && e.preventDefault();
    window.open($(e.currentTarget).attr('href'));
  },
});
