import { useState } from 'react';
import Backbone from 'backbone';
import Activity from '../../../../../application/models/activity';
import AttachmentView from '../../../../../application/views/activities/attachment_view';
import ReactToBackboneWrapper from '../../ReactToBackboneWrapper';
import { renameTab } from '../../../tabMonitor';

const LegacyActivityPanel = ({ tab }) => {
  const { component, config, id } = tab;
  const attachment = new Activity({ id: config.args[4] });

  const [view] = useState(() => new AttachmentView({ model: attachment, tab: new Backbone.Model({ active: true }) }));

  const callback = () => renameTab(id, view.model.get('attachments')[0].name);

  return (
    <ReactToBackboneWrapper
      view={view}
      tabId={id}
      component={component}
      onceLoadedCallback={callback}
    />
  );
};

export default LegacyActivityPanel;
