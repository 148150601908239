const OfficeFrame = ({
  fileId,
  fileName,
  documentUrl,
  token,
  onFormLoaded,
}) => {
  const targetName = `collabora-online-viewer-${fileId}`;

  return (
    <>
      <form
        data-testid={`office-form-${fileId}`}
        style={{ display: 'none' }}
        ref={onFormLoaded}
        action={documentUrl.href}
        encType="multipart/form-data"
        method="post"
        target={targetName}
      >
        <input name="access_token" value={token.value} type="hidden" />
        <input name="access_token_ttl" value={token.ttl} type="hidden" />
        <input name="ui_defaults" value="UIMode=tabbed" type="hidden" />
      </form>
      <iframe
        style={{ flex: 'auto' }}
        title={fileName}
        name={targetName}
        allow={`fullscreen ${documentUrl.origin}`}
      />
    </>
  );
};

export default OfficeFrame;
