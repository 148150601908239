import { useMutation } from '@tanstack/react-query';
import { currentUserUrl, virtualMachineUrl } from '../../../constants/api_urls';
import { poster, queryClient, workspaceTokenPatcher } from '../../../adapters/service';
import serverErrorMessage from '../../../utilities/server_errors';
import { refetchInterval, transformData } from '../../helper';
import { queryKey, useVirtualMachines } from '../../service';
import useWorkspaceUser from '../../../adapters/user';

export const useVMListQuery = () => {
  const { user } = useWorkspaceUser();
  return useVirtualMachines({ select: transformData(user?.uuid), refetchInterval });
};

/**
 * Creates a function that can be used to patch the cached data for a VM
 *
 * @param name
 * @param body
 * @returns {function(*): *}
 */
const updateData = (name, body) => {
  // Create a function that will only patch a specific vm that can be mapped over an array
  const updateFn = (vm) => (vm.name === name ? ({ ...vm, ...body }) : vm);

  // create and function that applies the above function to an array (the cached query data)
  return (response) => {
    // react-query might have gotten rid of the response from cache if so then
    // we can't patch anything
    if (!response) {
      return response;
    }

    return { ...response, data: response.data.map(updateFn) };
  };
};

const PATCH_CONTENT = {
  running: {
    cacheUpdate: {
      latest_operation: {
        operation_name: 'start_vm',
        completed_at: null,
      },
    },
    body: {
      power_state: 'running',
    },
  },
  deallocated: {
    cacheUpdate: {
      latest_operation: {
        operation_name: 'deallocate_vm',
        completed_at: null,
      },
    },
    body: {
      power_state: 'deallocated',
    },
  },
};
/**
 * Patches a VM by doing the following:
 * - updating any cached query data with the patch
 * - sending the patch request to the api
 * - invalidating any cached query data once the network response has been received
 * Returns the promised response from the patch request to the api.
 *
 * @param workspaceId
 * @param vmName
 * @param action
 * @returns {Promise<*|{}>}
 */
export const patchVM = async (vmName, action) => {
  const { cacheUpdate, body } = PATCH_CONTENT[action];

  // If there's a pending request it could immediately overwrite what's
  // we're putting in the cache so cancel any currently running queries
  queryClient.cancelQueries({ queryKey });
  queryClient.setQueryData(queryKey, updateData(vmName, cacheUpdate));

  let response;
  try {
    response = await workspaceTokenPatcher(virtualMachineUrl(vmName), body);
  } catch (error) {
    if ([404, 409].includes(error.status)) {
      // This either indicates there is already an operation running on the machine
      // or that the machine in question no longer exists.
      // In either case we do nothing and just let the VM state refresh on its own
    } else {
      throw error;
    }
  }
  await queryClient.invalidateQueries({ queryKey });
  return response;
};

export const useRequestNewVirtualMachine = () => {
  const postFile = (body) => poster(currentUserUrl, body);
  const {
    mutate,
    isError,
    isLoading,
    isSuccess,
    error,
    reset,
  } = useMutation((body) => postFile(body));

  return {
    requestNewVirtualMachine: mutate,
    isError,
    isLoading,
    isSuccess,
    error: error?.message || serverErrorMessage(error),
    resetResponse: reset,
  };
};
