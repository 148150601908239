import chorus from '../chorus';

function Presenter(model, options) {
  this.resource = model;
  this.model = model;
  this.options = options || {};

  this.setup && this.setup();
}

Presenter.extend = chorus.classExtend;

export default Presenter;
