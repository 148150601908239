import DatabaseColumn from '../models/database_column';
import Collection from './collections';

export default Collection.extend({
  constructorName: 'DatabaseColumnSet',
  model: DatabaseColumn,

  modelAdded(model) {
    if (this.dataset) {
      const databaseColumns = model;
      databaseColumns.dataset = this.dataset;
    }
  },

  urlTemplate() {
    return 'api/workspaces/datasets/{{id}}/columns';
  },

  urlParams() {
    return {
      type: this.attributes.type,
    };
  },
});
