import { useState } from 'react';
import { Search } from 'semantic-ui-react';
import { t } from 'i18n-js';
import _ from 'underscore';

// TODO: Make search more useful with more categories and results
const searchResults = (value) => (
  {
    kb: {
    // category name
    // results for that category
      name: t('search.kb.category'),
      results: [
        {
          title: t('kb.filter.title', { value }),
          description: t('kb.filter.description'),
          value: 'kb-search',
        }],
    },
  }
);

const NavBarSearch = () => {
  const initialState = {
    results: [],
    value: '',
  };

  const [search, setSearch] = useState(initialState);

  const handleResultSelect = () => {
    const url = `https://knowledgebase.aridhia.io/search?s=${encodeURIComponent(search.value)}`;
    window.open(url, 'knowledgebase');
  };

  const handleSearchChange = (e, { value }) => {
    const results = value ? searchResults(value) : [];

    setSearch({
      results,
      value,
    });
  };

  return (
    <Search
      id="aridhia-nav-bar-search"
      fluid
      category
      selectFirstResult
      onResultSelect={handleResultSelect}
      onSearchChange={_.debounce(handleSearchChange, 500, { leading: true })}
      results={search.results}
      value={search.value}
      input={{
        size: 'small',
        maxLength: 50,
        icon: 'search',
        placeholder: t('workspace.search'),
      }}
    />
  );
};

export default NavBarSearch;
