import DatabaseTables from '../../../../database_tables/panels/database_tables/components/DatabaseTables';
import ResponsivePanel from '../../ResponsivePanel';

const DatabaseTablesPanel = () => (
  <ResponsivePanel>
    {(panelWidth) => <DatabaseTables panelWidth={panelWidth} />}
  </ResponsivePanel>
);

export default DatabaseTablesPanel;
