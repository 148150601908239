import { useEffect } from 'react';
import syncAppLoadingProgress from '../../adapters/syncAppLoadingProgress';

import AppPlaceHolder from './AppPlaceHolder';

const AppLoading = ({
  appUrl,
  appMgmtUrl,
  workspaceToken,
  message,
  setAppRunState,
  setPodStatus,
}) => {
  useEffect(() => {
    const controller = new AbortController();

    if (appUrl) {
      syncAppLoadingProgress(controller.signal, appUrl, appMgmtUrl, workspaceToken, setAppRunState, setPodStatus);
    }

    return () => controller.abort();
  }, [appUrl]);

  return <AppPlaceHolder message={message} />;
};

export default AppLoading;
