import {
  Label,
  Table,
  Checkbox,
  Icon,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import FilesRowDropdown from './FilesRowDropdown';
import { useFileContext, useFilesContext } from '../Context';
import { workspaceFileListElementUrl } from '../../../../constants/ui_urls';

const FolderNameCell = ({
  fileName,
  fileId,
  fileType,
  folder,
}) => (
  <Table.Cell className="aridhia-table-name-cell">
    <a href={workspaceFileListElementUrl(fileId, fileType, folder, fileName)}>
      <Icon name="folder" color="teal" />
      {fileName}
    </a>
  </Table.Cell>
);

const FileNameCell = ({
  fileName,
  fileId,
  fileType,
  folder,
}) => (
  <Table.Cell className="aridhia-table-name-cell">
    <a href={workspaceFileListElementUrl(fileId, fileType, folder, fileName)}>
      <Icon name="file" />
      {fileName}
    </a>
  </Table.Cell>
);

const FileTypeCell = ({ fileType }) => (
  <Table.Cell>
    <Label size="small" content={fileType} />
  </Table.Cell>
);

const FileSizeCell = ({ fileSizeText }) => (
  <Table.Cell>
    {fileSizeText && <Label size="small" content={fileSizeText} />}
  </Table.Cell>
);

const FileDateCell = ({ fileDate }) => (
  <Table.Cell>
    {fileDate}
  </Table.Cell>
);

const FilesTableRow = () => {
  const {
    selectedFiles,
    handleSelectedFiles,
  } = useFilesContext();
  const {
    id,
    name,
    size,
    type,
    date,
    folder,
  } = useFileContext();

  return (
    <Table.Row aria-label={t('files.accessibility.files_table_row')}>
      <Table.Cell collapsing>
        <Checkbox
          value={id}
          onChange={handleSelectedFiles}
          checked={(selectedFiles?.findIndex((item) => item.id === id)) >= 0}
          name={name}
          filetype={type}
          folder={folder}
        />
      </Table.Cell>
      {type === 'folder' && (
        <FolderNameCell
          fileName={name}
          fileId={id}
          fileType={type}
          folder={folder}
        />
      )}
      {type !== 'folder' && (
        <FileNameCell
          fileName={name}
          fileId={id}
          fileType={type}
          folder={folder}
        />
      )}
      <FileTypeCell fileType={type} />
      <FileSizeCell fileSizeText={size} />
      <FileDateCell fileDate={date} />
      <Table.Cell textAlign="center" collapsing>
        <FilesRowDropdown />
      </Table.Cell>
    </Table.Row>
  );
};

export default FilesTableRow;
