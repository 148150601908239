import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import DatasetsAttach from '../dataset/datasets_attach_dialog';
import WorkfilesAttach from '../workfiles/workfiles_attach_dialog';
import Dialog from '../../dialogs/dialogs';
import WorkfileSet from '../../collections/workfile_set';
import WorkspaceDatasetSet from '../../collections/workspace_dataset_set';
import { instance } from '../../models/config';
import ClEditor from '../../mixins/cl_editor_helpers';
import { renderTemplate } from '../../utilities/handlebars_helpers';

/* Notifications/Recipients tagging removed XAP-13891 */

export default Dialog.include(ClEditor).extend({
  constructorName: 'MemoNew',

  templateName: 'notes_new',
  persistent: true,
  events: {
    'submit form': 'save',
    'click .remove': 'removeAttachment',
    'click .add_workfile': 'launchWorkfileDialog',
    'click .add_dataset': 'launchDatasetDialog',
    'mousedown .dialog_header': 'closeDropdown',
  },

  setup() {
    this.workspace = this.options.workspace;
    this.config = instance;
  },

  postRender() {
    _.defer(_.bind(function renderEditor() {
      this.makeEditor($(this.el), '.toolbar', 'body', { width: 'auto', height: 150 });
    }, this));
  },

  makeModel(...args) {
    this._super('makeModel', args);

    this.listenTo(this.model, 'saved', this.modelSaved);
    this.listenTo(this.model, 'saveFailed', this.saveFailed);
    this.listenTo(this.model, 'validationFailed', this.saveFailed);

    this.workspaceId = this.model.get('workspaceId');
    this.model.datasets = new WorkspaceDatasetSet();
    this.model.workfiles = new WorkfileSet();
  },

  modelSaved() {
    this.saved();
    chorus.PageEvents.trigger('workfiles:changed');
  },

  save(e) {
    if (e) { e.preventDefault(); }
    this.$('.attachment_links').addClass('disabled');
    this.$('button.submit').startLoading('notes.button.uploading');
    this.saving = true;
    this.model.save({
      body: this.getNormalizedText(this.$('textarea[name=body]')),
      recipients: [],
    });
  },

  saved() {
    this.pageModel.trigger('invalidated');
    this.$('button.submit').stopLoading();
    this.closeModal();
  },

  saveFailed() {
    this.$('button.submit').stopLoading();
    this.showErrors();
    if (!this.model.isNew()) {
      this.model.destroy();
      this.model.unset('id');
    }
    this.$('.remove').removeClass('hidden');
    this.$('.form_controls .cancel').removeClass('hidden');
    this.$('.attachment_links').removeClass('disabled');
    this.saving = false;
  },

  showErrors(model) {
    this._super('showErrors');
    this.checkErrorInBody(model || this.resource);
  },

  checkErrorInBody(model) {
    if (model.errors && model.errors.body) {
      const $input = this.$('.cleditorMain');
      this.markInputAsInvalid($input, model.errors.body, true);

      this.$('iframe').contents().find('body').css('margin-right', '20px');
    }
  },

  additionalContext() {
    return {
      formUrl: this.model.url(),
      placeholder: this.placeholder,
      submitButton: this.submitButton || 'Submit',
      allowWorkspaceAttachments: this.options.allowWorkspaceAttachments,
    };
  },

  launchWorkfileDialog(e) {
    e.preventDefault();
    if (!this.saving) {
      const workfileDialog = new WorkfilesAttach({
        workspace: this.workspace,
        crumbs: true,
        folder: this.folder,
      });
      workfileDialog.bind('files:selected', this.workfileChosen, this);
      workfileDialog.bind('folder:changed', this.folderChanged, this);
      this.launchSubModal(workfileDialog);
    }
  },

  launchDatasetDialog(e) {
    e.preventDefault();
    if (!this.saving) {
      const datasetDialog = new DatasetsAttach({ workspaceId: this.workspaceId });
      datasetDialog.bind('datasets:selected', this.datasetsChosen, this);
      this.launchSubModal(datasetDialog);
    }
  },

  workfileChosen(workfiles) {
    this.$('.file_details.workfile').remove();

    _.each(workfiles, function addChosen(workfile) {
      if (!this.model.workfiles.get(workfile.get('id'))) {
        this.model.workfiles.add(workfile);
      }
    }, this);

    this.model.workfiles.each(function showChosen(workfile) {
      const iconUrl = workfile.iconUrl({ size: 'icon' });
      this.showFile(workfile, workfile.get('fileName'), iconUrl);
    }, this);
  },

  folderChanged(folder) {
    this.folder = folder;
  },

  datasetsChosen(datasets) {
    this.$('.dataset_details.dataset').remove();

    _.each(datasets, function addChosen(dataset) {
      if (!this.model.datasets.get(dataset.get('id'))) {
        this.model.datasets.add(dataset);
      }
    }, this);

    this.model.datasets.each(function showChosen(dataset) {
      this.showDataset(dataset);
    }, this);
  },

  showDataset(dataset) {
    const datasetDetailsRow = $(renderTemplate('notes_new_file_attachment').toString());
    this.$('.options_area').append(datasetDetailsRow);

    const iconSrc = dataset.iconUrl({ size: 'icon' });
    datasetDetailsRow.find('img.icon').attr('src', iconSrc);
    datasetDetailsRow.find('span.name').text(dataset.get('objectName')).attr('title', dataset.get('objectName'));
    datasetDetailsRow.data('attachment', dataset);
    datasetDetailsRow.removeClass('hidden');
    datasetDetailsRow.addClass('dataset dataset_details');
  },

  showFile(file, filename, iconSrc, uploadModel) {
    const fileDetailsRow = $(renderTemplate('notes_new_file_attachment').toString());
    this.$('.options_area').append(fileDetailsRow);

    fileDetailsRow.find('img.icon').attr('src', iconSrc);
    fileDetailsRow.find('span.name').text(filename).attr('title', filename);
    fileDetailsRow.data('attachment', file);
    fileDetailsRow.data('uploadModel', uploadModel);
    fileDetailsRow.removeClass('hidden');
    fileDetailsRow.addClass('file_details');
    fileDetailsRow.addClass('workfile');
  },

  removeAttachment(e) {
    e.preventDefault();
    const row = $(e.target).closest('.row');
    const attachment = row.data('attachment');
    row.detach();

    if (row.hasClass('workfile')) {
      this.model.workfiles.remove(attachment);
    } else if (row.hasClass('dataset')) {
      this.model.datasets.remove(attachment);
    }
  },
});
