export const userInitials = (name) => {
  const parts = name.toUpperCase().split(' ');
  if (parts.length > 1) {
    return `${parts[0].charAt(0)}${parts[1].charAt(0)}`;
  }
  return `${parts[0].charAt(0)}`;
};

/**
 * Generate a class name based on user's name
 * Calculate an index based on the number of characters and vowels in the username
 * This keeps it constant each time we calculate but fairly unique
 */
export const avatarBackgroundClassName = (name) => {
  const colours = [
    'orange',
    'rust',
    'red',
    'pink',
    'violet',
    'purple',
    'darkBlue',
    'blue',
    'lightBlue',
    'teal',
    'green',
    'sage',
    'olive',
    'lime',
    'yellow',
    'brown',
  ];

  const vowels = (name.match(/[aeiou]/gi) || []).length;
  const index = (name.length + vowels) % colours.length;

  return colours[index];
};

const avatars = {
  userInitials,
  avatarBackgroundClassName,
};

export default avatars;
