import User from './user';
import Model from './models';
import Routing from '../mixins/routing';

/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true }] */

export default Model.include(Routing).extend({
  constructorName: 'Session',

  user() {
    if (!this._user && this.get('user')) {
      this._user = new User(this.get('user'));
    }

    return this._user;
  },

  loggedIn() {
    return this.get('user') && true;
  },

  fetch(optionsArg) {},

  clear(...rest) {
    delete this.serverErrors;
    delete this._user;
    delete this.id;
    this._super('clear', rest);
  }
});
