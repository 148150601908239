import _ from '../../underscore';
import t from '../../intl';
import ListHeaderView from '../../views/core/list_header_view';
import Dialog from '../../dialogs/dialogs';

export default Dialog.extend({
  constructorName: 'WorkspaceMembersMore',
  templateName: 'workspace_members_more',
  title: t('workspace.members'),
  persistent: true,

  subviews: {
    '.sort_menu': 'sortMenu',
  },

  setup() {
    this.collection = this.pageModel.members();
    this.collection.fetchAllIfNotLoaded();
    this.collection.bind('reset', this.render, this);
    this.setupSortMenu();
  },

  setupSortMenu() {
    this.sortMenu = new ListHeaderView({
      linkMenus: {
        sort: {
          title: t('users.header.menu.sort.title'),
          options: [
            { data: 'firstName', text: t('users.header.menu.sort.first_name') },
            { data: 'lastName', text: t('users.header.menu.sort.last_name') },
          ],
          event: 'sort',
          chosen: 'lastName',
        },
      },
    });

    this.choice = 'lastName';
    this.sortMenu.bind('choice:sort', function onSort(choice) {
      this.choice = choice;
      this.render();
    }, this);
  },

  postRender() {
    if (!this.collection.loaded) {
      this.$('.dialog_content').startLoading();
    }
  },

  additionalContext() {
    const { choice } = this;
    const sortedMembers = _.sortBy(this.collection.models, member => member.get(choice));
    return {
      members: _.map(sortedMembers, member => ({
        displayName: member.displayName(),
        imageUrl: member.fetchImageUrl({ size: 'icon' }),
        showUrl: member.showUrl(),
      })),
    };
  },
});
