import Backbone from 'backbone';
import { podStatusToAppRunState } from '../models/containerised_app';
import ContainerisedAppList from './containerised_app_list';
import ContainerisedAppPods from './containerised_app_pods';

const ContainerisedAppsAndPods = Backbone.Collection.include(Backbone.Events).extend({
  initialize(_models, options) {
    this.apps = new ContainerisedAppList([], { workspace: options.workspace });
    this.pods = new ContainerisedAppPods([], { tokenPromise: options.workspace.token() });

    this.listenTo(this.apps, 'sync', this.appsSynced);
  },

  appsSynced() {
    this.reset(this.apps.models);
    this.trigger('sync');
  },

  mergeModels() {
    this.set(this.apps.models);

    const { pods } = this;

    this.forEach((model) => {
      const pod = pods.findWhere({ imageName: model.get('imageName') });
      const attrs = {};
      if (pod) {
        attrs.podStatus = pod.get('status');
        attrs.runningTag = pod.get('runningTag');
      } else {
        attrs.podStatus = 'inactive';
      }
      attrs.runState = podStatusToAppRunState(attrs.podStatus);
      model.set(attrs);
    });
    this.trigger('appsLoaded');
  },

  fetchIfNotLoaded(options) {
    return this.mergeAfter(() => Promise.all([this.apps.fetchIfNotLoaded(options), this.pods.fetchIfNotLoaded(options)]));
  },

  fetch(options) {
    return this.mergeAfter(() => Promise.all([this.apps.fetch(options), this.pods.fetch(options)]));
  },

  async mergeAfter(asyncTask) {
    try {
      await asyncTask();
      this.statusCode = this.apps.statusCode;
      this.mergeModels();
    } catch (err) {
      console.log(`Error retrieving status of running Containerised-Apps: ${err}`);
      this.forEach((model) => {
        model.set({
          podStatus: 'Stopped'
        });
      });
    }
  },
});

export default ContainerisedAppsAndPods;
