import Task from './task';

export default Task.extend({
  urlTemplateBase: 'api/workspaces/workfiles/{{workfile.id}}/executions',
  constructorName: '',
  paramsToSave: ['checkId', 'sql'],

  name() {
    return this.get('workfile').get('fileName');
  },
});
