import chorus from '../../chorus';
import $ from '../../jquery';
import t from '../../intl';
import MemoNew from '../activities/memo_new_dialog';
import Note from '../../models/note';
import { workspace } from '../../utilities/workspace_assoc';
import Model from '../../models/models';
import { renderTemplate } from '../../utilities/handlebars_helpers';

export default MemoNew.extend({
  constructorName: 'VisualizationNotesNew',

  title: t('notes.new_dialog.title'),
  submitButton: t('notes.button.create'),

  makeModel(...args) {
    this.model = new Note({
      entityId: this.options.entityId,
      entityType: this.options.entityType,
      workspaceId: this.options.workspace.id,
    });
    const subject = this.model.get('entityType');

    this.placeholder = t('notes.placeholder', { noteSubject: subject });
    this._super('makeModel', args);
  },

  postRender(...args) {
    this._super('postRender', args);

    this.showVisualizationData();
  },

  showVisualizationData() {
    const $row = $(renderTemplate('notes_new_file_attachment').toString());
    this.$('.options_area').append($row);

    const visualization = this.options.attachVisualization;

    const iconSrc = 'images/workfiles/icon/img.png';
    $row.find('img.icon').attr('src', iconSrc);
    $row.find('span.name').text(visualization.fileName).attr('title', visualization.fileName);
    $row.data('visualization', visualization);
    $row.find('a.remove').addClass('hidden');
    $row.removeClass('hidden');
    $row.addClass('visualization file_details');
  },

  modelSaved() {
    const note = this.model;
    const svgFile = new Model(this.options.attachVisualization);
    svgFile.url = function noteUrl() {
      // weirdly, the note knows how to generate urls for its attachments;
      return note.url({ isFile: true });
    };
    svgFile.bind('saved', this.svgSaved, this);
    svgFile.save();

    this._super('modelSaved');

    workspace(this.options.pageModel).activities().refresh();
  },

  svgSaved() {
    chorus.toast('dataset.visualization.toast.note_from_chart', { datasetName: this.options.entityName });
  },
});
