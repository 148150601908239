import WorkfileVersion from '../models/workfile_version';
import WorkfileVersionSet from '../collections/workfile_version_set';
import Draft from '../models/draft';
import { attachmentWorkspace, workspace } from './workspace_assoc';
import { FEATURE } from '../../utilities/features';

export const refreshVersionInfo = (workfile, callback) => {
  const version = new WorkfileVersion({
    id: workfile.get('id'),
    versionInfo: workfile.get('versionInfo'),
  });
  version.fetch({
    success: function applyVersion(ver) {
      workfile.set('versionInfo', ver.get('versionInfo'), { silent: true });
      if (callback) {
        callback(workfile, version);
      }
    },
  });
};

export const fileVersions = (workfile) => {
  const associate = workfile;
  if (!associate.versions) {
    associate.versions = new WorkfileVersionSet([], {
      workfileId: associate.get('id'),
    });
    associate.versions.setWorkspace(workspace(associate));
  }

  return associate.versions;
};

export const createDraft = (workfile) => {
  const associate = workfile;
  const draft = new Draft({
    workfileId: associate.get('id'),
    workspaceId: workspace(associate).id,
    content: associate.content(),
  });

  draft.bind('saved', () => {
    associate.isDraft = true;
    associate.set({ hasDraft: true, draftUpdatedAt: new Date() }, { silent: true });
    associate.trigger('change:draftUpdatedAt');
  }, associate);

  return draft;
};

export const folderUrl = workfile => `${workspace(workfile).showUrl()}/folders/${workfile.get('folder')}`;

export const editUrl = workfile => `/#/workspaces/${workspace(workfile).id}/datatable/file/${workfile.get('id')}`;

export const datasetUploadUrl = workfile => FEATURE.NEW_UX ? `/#/workspaces/${workspace(workfile).id}/database_tables/new/${workfile.get('id')}` : `/#/workspaces/${workspace(workfile).id}/dataset_upload/file/${workfile.get('id')}`;

export const analyseUrl = workfile => `${editUrl(workfile)}/analyse`;

export const canEdit = workfile => !workfile.get('readOnly') && !workfile.get('destroying') && workspace(workfile).canUpdate();

export const isInbox = workfile => workfile.get('metadata') && workfile.get('metadata').inbox;

export const attachmentShowUrl = (attachment) => {
  const ws = attachmentWorkspace(attachment);
  const workspaceUrl = ws && ws.get('id') && ws.showUrl();
  const datasetUrl = attachment.dataset() && attachment.dataset().showUrl();
  const workfileUrl = attachment.workfile() && attachment.workfile().showUrl();

  return datasetUrl ||
    (workspaceUrl && workfileUrl) ||
    workspaceUrl;
};
