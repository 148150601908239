import { useState, useEffect } from 'react';
import {
  Layout as FlexLayout, Actions, Model, DockLocation,
} from 'flexlayout-react';
import 'flexlayout-react/style/light.css';
import { t } from 'i18n-js';
import Panel from './Panel';
import { load, save } from '../models/layout';
import { availableActions } from '../constants';
import {
  getUrl, getPageArgsFromUrl, removeTrailingSlash, workspaceId,
} from '../helper';
import useTabMonitor from '../tabMonitor';
import { parseJson } from '../../../utilities/helpers';
import createTab from '../models/tab';
import iconFactory from '../icons';
import { modelChanged, TabListContextProvider } from '../context';
import { afterRender } from '../../utilities/helper';

const navigate = (newTabRoute) => {
  window.location.hash = `#/workspaces/${workspaceId()}/${newTabRoute}`;
  return true;
};

const handleUrlWithoutPageArgs = () => {
  let defaultPage = 'home';
  const loadedSession = localStorage.getItem('layout');
  if (loadedSession) {
    const { layout } = parseJson(loadedSession);
    const tab = layout?.children[0]?.children[0];
    if (tab) {
      if (tab.component === 'folders') {
        defaultPage = `folders/${tab.config.folderPath}`;
      } else {
        defaultPage = tab.id;
      }
    }
  }
  navigate(defaultPage);
};

const handleUrlChange = (model) => {
  const url = removeTrailingSlash(getUrl());
  const pageArgs = getPageArgsFromUrl(url, 3);
  const component = pageArgs[0];

  if (pageArgs.length === 0 || !component) { return handleUrlWithoutPageArgs(); }

  // Redirects /datatable/dataset/{id} to /datatable/dataset/{id}/analyse
  // TODO: Remove when we completely remove edit
  if (component === 'datatable' && pageArgs.length === 3) {
    const editDatatableURL = /^datatable\/((file|dataset)\/[0-9]+)$/;
    const validPath = editDatatableURL.test(pageArgs.join('/'));
    if (validPath) return navigate(`${pageArgs.join('/')}/analyse`);
  }

  // Redirects /workfiles to /folders/files
  // TODO: Remove when we can update home pages to not use workfiles url
  if (component === 'workfiles' && pageArgs.length === 1) {
    const workfilesURL = /^workfiles$/;
    const validPath = workfilesURL.test(pageArgs.join('/'));
    if (validPath) return navigate('folders/files');
  }

  const tab = createTab(url, pageArgs);

  document.title = `${t('application_title')} - ${tab.name}`;

  const node = model.getNodeById(tab.id);

  if (!node) {
    let tabSet = model.getActiveTabset();
    if (!tabSet) {
      model.visitNodes((n) => {
        if (!tabSet && n.getType() === 'tabset') {
          tabSet = n;
        }
      });
    }
    model.doAction(Actions.addNode(tab, tabSet?.getId(), DockLocation.CENTER, -1, true));
  } else {
    model.doAction(Actions.updateNodeAttributes(tab.id, { config: tab.config }));
    model.doAction(Actions.selectTab(tab.id));
  }

  return true;
};

const createUrlListener = (model) => {
  const listenToPopstate = () => afterRender(() => handleUrlChange(model));
  window.addEventListener('popstate', listenToPopstate);

  // Handle initial load
  handleUrlChange(model);

  return () => {
    window.removeEventListener('popstate', listenToPopstate);
  };
};

// The tab content creator.
const factory = (node) => {
  const tab = node.getConfig();
  const tabIsActive = node.isVisible();

  if (tabIsActive) {
    const url = getUrl();
    const tabUrl = tab.config.url;
    if (url !== tabUrl) {
      window.location.hash = tabUrl;
    }
  }

  return <Panel key={tab.id} tab={tab} tabIsActive={tabIsActive} />;
};

const createActionHandler = (model) => (action) => {
  if (!(action.type in availableActions)) {
    return false;
  }

  const { type } = action;
  switch (type) {
    case Actions.SELECT_TAB: {
      return action;
    }
    case Actions.DELETE_TAB: {
      let count = 0;
      model.visitNodes((node) => {
        if (node.getType() === 'tab') {
          count += 1;
        }
      });
      if (count <= 1) {
        return false;
      }

      return action;
    }
    case Actions.MOVE_NODE: {
      return action;
    }
    default: return false;
  }
};

const persistModel = (model) => {
  modelChanged();
  save(model.toJson());
};

const LayoutManager = () => {
  const [model] = useState(() => Model.fromJson(load()));

  useTabMonitor(model);
  useEffect(() => createUrlListener(model), [model]);
  const onAction = createActionHandler(model);

  return (
    <TabListContextProvider model={model}>
      <FlexLayout
        model={model}
        factory={factory}
        iconFactory={iconFactory}
        onAction={onAction}
        onModelChange={persistModel}
      />
    </TabListContextProvider>
  );
};

export default LayoutManager;
