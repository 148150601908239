import { Label, Segment } from 'semantic-ui-react';
import { t } from 'i18n-js';
import RefreshButton from '../../../../components/buttons/RefreshButton';
import { useAppsContext } from '../../../Context';
import { invalidateApps } from '../../../service';

const AppsCount = ({ count = 0 }) => (
  <Label>
    {count === 1
      ? t('apps.apps_panel.singular_count', { count })
      : t('apps.apps_panel.multiple_count', { count })}
  </Label>
);

const AppsCardsHeader = () => {
  const {
    isLoading,
    isRefetching,
    numberOfApps,
    isError,
  } = useAppsContext();

  return (
    <Segment attached data-testid="apps-cards-header">
      {!isLoading && !isError && <AppsCount count={numberOfApps} />}
      <RefreshButton isFetching={isLoading || isRefetching} refetch={invalidateApps} className="float-right" />
    </Segment>
  );
};

export default AppsCardsHeader;
