import { t } from 'i18n-js';
import { Segment } from 'semantic-ui-react';

const AppViewer = ({ appName, appUrl, allowDownload }) => (
  <Segment placeholder attached className="app-content" textAlign="center">
    <iframe
      title={`${t('app.run.title_prefix')}${appName}`}
      id="appIframe"
      src={appUrl}
      frameBorder="0"
      sandbox={
          `allow-same-origin allow-scripts allow-top-navigation allow-popups${allowDownload ? ' allow-downloads' : ''}`
        }
    />
  </Segment>
);

export default AppViewer;
