import TaggingSet from '../collections/tagging_set';
import Collection from '../collections/collections';
import TaggingsUpdater from '../models/taggings_updater';

export default {

  hasTags() {
    return this.tags().length > 0;
  },

  tags() {
    if (!this._tags) {
      this._tags = new TaggingSet(this.get('tags') || [], { entity: this });
      this.on('loaded saved', function onReset() {
        this._tags.reset(this.get('tags') || [], { parse: true });
      });

      this.listenTo(this._tags, 'all', function onChange() {
        this.trigger('change:tags');
      });
    }
    return this._tags;
  },

  updateTags(params, opts) {
    if (!this.taggingsUpdater) {
      this.taggingsUpdater = new TaggingsUpdater({
        collection: new Collection([this]),
      });

      this.listenTo(this.taggingsUpdater, 'updateFailed', function onFail() {
        this.tags().fetchAll();
      });
    }
    this.taggingsUpdater.updateTags(params, opts);
  },
};
