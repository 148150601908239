import _ from '../underscore';
import t from '../intl';
import SearchIndexPage from './search_index_page';
import Tag from '../models/tag';

export default SearchIndexPage.extend({

  crumbs() {
    return [
      { label: t('breadcrumbs.tags'), url: '#/tags' },
      { label: this.tagModel.name() },
    ];
  },

  makeModel(...args) {
    const name = Array.prototype.slice.call(args, -1)[0];
    this._super('makeModel', args);
    this.tagModel = new Tag({ name });
  },

  parseSearchParams(...args) {
    const attrs = this._super('parseSearchParams', args);
    attrs.isTag = true;
    return attrs;
  },

  title() {
    return t('tag.show.title', {
      name: this.tagModel.name(),
    });
  },

  typeOptions(...args) {
    const options = this._super('typeOptions', args);
    _.findWhere(options, { data: 'attachment' }).disabled = true;
    return options;
  },
});
