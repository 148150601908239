import chorus from '../chorus';
import _ from '../underscore';
import $ from '../jquery';
import t from '../intl';
import Confirm from './confirm';
import Base from './base';
import { toAirlock } from '../presenters/activity_presenters';
import Routing from '../mixins/routing';

export default Confirm.include(Routing).extend({
  constructorName: 'ApproveAirlockConfirmAlert',
  focusSelector: 'button.cancel',
  additionalClass: 'error',
  persistent: true, // here for documentation, doesn't actually do anything as we've overwritten bindCallbacks

  events: _.extend({}, Base.prototype.events, {
    'click button.submit': 'approveAirlock',
  }),

  bindCallbacks() {
    this.model.bind('saved', this.airlockApproved, this);
    this.model.bind('saveFailed', this.render, this);
  },

  close() {
    this.model.unbind('saved', this.airlockApproved);
    this.model.unbind('saveFailed', this.render);
  },

  approveAirlock(e) {
    e.preventDefault();
    this.model.save({}, { method: 'create', isApprove: true });
    this.$('button.submit').startLoading('airlock.approve.alert.approving');
  },

  airlockApproved() {
    this.closeModal();
    chorus.toast(this.approveMessage, $.noop);
    chorus.PageEvents.trigger('airlock:saved', this.model);
    if (this.redirectUrl) {
      this.navigate(this.redirectUrl);
    }
  },

  makeModel(...args) {
    this._super('makeModel', args);
    const { activity } = this.options;
    this.model = toAirlock(activity);
    this.text = t('airlock.approve.alert.text');
    this.title = t('airlock.approve.alert.title');
    this.ok = t('airlock.approve.alert.ok');
    this.approveMessage = 'airlock.approve.alert.message';
  },

  postRender() {
    this._super('postRender');
    this.$('button.submit').removeClass('btn-danger');
    this.$('button.submit').addClass('btn-success');
  },
});
