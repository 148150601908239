import DatabaseTableCreation from '../../../../database_tables/panels/database_table_creation/components/DatabaseTableCreation';
import ResponsivePanel from '../../ResponsivePanel';

const DatabaseTableCreationPanel = ({ tab }) => (
  <ResponsivePanel>
    {(panelWidth) => <DatabaseTableCreation fileId={tab.config.params?.file_id} panelWidth={panelWidth} />}
  </ResponsivePanel>
);

export default DatabaseTableCreationPanel;
