import chorus from '../chorus';
import _ from '../underscore';
import $ from '../jquery';
import OrganisationSet from '../collections/organisation_set';
import ChorusView from './chorus_view';
import User from './user';
import Workfile from './workfile';
import WorkfileFolder from './workfile_folder';
import WorkspaceDataset from './workspace_dataset';
import WorkspaceDatasetSet from '../collections/workspace_dataset_set';
import DesktopSet from '../collections/desktop_set';
import WorkfileSet from '../collections/workfile_set';
import CommentSet from '../collections/comment_set';
import MemberSet from '../collections/member_set';
import WorkspaceUserSet from '../collections/workspace_user_set';
import RToolsConsoleUsage from './rtools_console_usage';
import Sandbox from './sandbox';
import { instance } from './config';
import Model from './models';
import Taggable from '../mixins/tags_mixin';
import { UpdateWorkspacePayload, CreateWorkspacePayload } from '../utilities/management_payload';
import { idToken } from '../auth/oauth';

/* eslint no-underscore-dangle: ["error",
{ "allow": [
    "_collections",
    "_owner",
    "_sandbox",
    "_sandboxTables",
    "_datasets",
    "_workspace",
    "_desktops",
    "_workfiles",
    "_comments",
    "_members",
    "_users",
    "_organisations",
    "_consoleUsage"
] }] */

const CACHE_PERIOD = 1000 * 60 * 10;
function cached(fn) {
  let value = null;
  let time = Date.now();

  const expired = () => (Date.now() - time > CACHE_PERIOD);
  const updateState = (x) => { value = x; time = Date.now(); return x; };
  return () => {
    if (!value || expired()) {
      return fn().then(updateState);
    }
    return Promise.resolve(value);
  };
}

export const workspaceToken = w => cached(() => {
  return w.fetch().then(() => w.get('token'));
});

const Workspace = Model.include(Taggable).extend({
  constructorName: 'Workspace',

  showUrlTemplate: 'workspaces/{{id}}',
  nameAttribute: 'name',
  entityType: 'workspace',

  mgmt_workspace() {
    return getManagementWorkspace(this.get('uuid'));
  },

  makeSuccessFunction(options, success) {
    const successFunction = this._super('makeSuccessFunction', [options, success]);

    return (resource, data, fetchOptions) => {
      const complete = () => successFunction(resource, data, fetchOptions);
      const mgmt = this.mgmt_workspace();
      if (mgmt.loaded) {
        return complete();
      } else {
        mgmt.fetch().then(complete);
      }
    }
  },

  activeCollections() {
    if (!this._collections) {
      this._collections = [];
    }
    return this._collections;
  },

  defaultIconUrl() {
    return `<i class='${this.showIcon()} rsh-item'></i>`;
  },

  urlTemplate() {
    if (this.attributes.image_only) {
      return `api/workspaces/workspaces/${this.id}?image_only=1`;
    }

    return `api/workspaces/workspaces/${this.id}`;
  },

  initialize() {
    this.bind('change:owner', function onChange() {
      delete this._owner;
    }, this);
  },

  isArchive() {
    return this.get('archivedAt') != null;
  },

  owner() {
    let ownerAttrs;
    if (_.isObject(this.get('owner'))) {
      ownerAttrs = this.get('owner');
    } else {
      ownerAttrs = {
        id: this.get('ownerId'),
        firstName: this.get('ownerFirstName'),
        lastName: this.get('ownerLastName'),
        email: this.get('ownerEmail'),
      };
    }
    this._owner = new User(ownerAttrs);
    return this._owner;
  },

  workfileFolders(includeInbox = false) {
    if (includeInbox) {
      return instance.get('topLevelFolders').split('|');
    }
    var folders = instance.get('topLevelFolders').split('|').filter((value) => {
      return value !== 'inbox' && value !== 'public inbox';
    });
    return folders;
  },

  defaultWorkfilesFolder() {
    return new WorkfileFolder({ folder: this.workfileFolders()[0], workspaceId: this.id });
  },

  sandbox() {
    if (this._sandbox) return this._sandbox;
    const sandboxInfo = this.get('sandboxInfo');
    if (sandboxInfo && sandboxInfo.id) {
      const attrs = _.extend(
        {},
        sandboxInfo,
        { id: sandboxInfo.id, workspaceId: this.get('id') },
      );
      this._sandbox = new Sandbox(attrs);
      return this._sandbox;
    }
    return null;
  },

  datasetsInDatabase(database) {
    return new WorkspaceDatasetSet([], {
      workspaceId: this.id,
      database,
    });
  },

  sandboxTables(options) {
    this._sandboxTables = this._sandboxTables || new WorkspaceDatasetSet([], _.extend({
      workspaceId: this.id,
      type: 'SANDBOX_TABLE',
      objectType: 'TABLE',
    }, options));

    return this._sandboxTables;
  },

  datasets() {
    if (!this._datasets) {
      this._datasets = new WorkspaceDatasetSet([], { workspaceId: this.id, unsorted: true });
      this._datasets.setWorkspace(this);
      this.activeCollections().push(this._datasets);
    }

    return this._datasets;
  },

  getDataset(datasetId, type) {
    let dataset = this.datasets().get(datasetId);

    if (!dataset) {
      if (type === 'ChorusView') {
        dataset = new ChorusView({ id: datasetId, workspace: { id: this.id } });
      } else {
        dataset = new WorkspaceDataset({ id: datasetId, workspace: { id: this.id } });
      }
    }
    dataset._workspace = this;
    return dataset;
  },

  desktops() {
    if (!this._desktops) {
      this._desktops = new DesktopSet([], { workspaceId: this.id, tokenPromise: this.token() });
    }

    return this._desktops;
  },

  token() {
    return workspaceToken(this.clone());
  },

  workfiles() {
    if (!this._workfiles) {
      this._workfiles = new WorkfileSet([], { workspaceId: this.id });
      this._workfiles.setWorkspace(this);
      this.activeCollections().push(this._workfiles);
    }

    return this._workfiles;
  },

  getWorkfile(workfileId) {
    const workfiles = this.workfiles();
    const workfile = workfiles.get(workfileId) || new Workfile({
      id: workfileId,
      workspace: { id: this.id },
    });

    if (workfiles.loaded) {
      // the workfiles are loaded but the workfile is not found in the workfile list
      // this means we need to refresh the workfile list. By sending false as
      // arg, we tell fetch to set ifModified to false, and avoid returning a 304.
      // This would lead a CSV to not refresh, and stall the dta_modules
      _.delay(() => {
        workfiles.refresh(false);
      }, 500);
    }

    workfile._workspace = this;
    return workfile;
  },

  homepageContent(failure) {
    return idToken().then(token => (
      $.ajax({
        url: `/api/workspaces/workspaces/${this.get('id')}/home`,
        beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        },
      }).fail(failure)
    ));
  },

  comments() {
    this._comments = this._comments || new CommentSet(this.get('latestCommentList'));
    return this._comments;
  },

  members() {
    if (!this._members) {
      this._members = new MemberSet([], { workspaceId: this.get('id') });
      if (this.get('chorusID') || !('chorusID' in this.attributes)) {
        this._members.bind('saved', function onChange() {
          this.trigger('change');
        }, this);
      } else {
        this._members.loaded = true;
      }
    }
    return this._members;
  },

  users() {
    if (!this._users) {
      this._users = new WorkspaceUserSet([], { workspaceId: this.get('id') });
    }
    return this._users;
  },

  declareValidations(newAttrs) {
    this.require('name', newAttrs);
  },

  archiver() {
    return new User(this.get('archiver'));
  },

  organisations() {
    this._organisations = this._organisations || new OrganisationSet(this.get('organisations'));
    return this._organisations;
  },

  organisationNames() {
    return this.organisations().map(model => model.name()).join(', ');
  },

  displayName() {
    return this.get('name');
  },

  fetchImageUrl() {
    const url = this.get('image');
    return url && new window.URI(url).toString();
  },

  createImageUrl() {
    const url = new window.URI(this.url());
    url.path(`${url.path()}/image`);
    return url.toString();
  },

  workfilesUrl() {
    return `${this.showUrl()}/workfiles`;
  },

  attrToLabel: {
    name: 'workspace.validation.name',
  },

  hasImage() {
    if (this.get('image')) {
      const http = new XMLHttpRequest();

      http.open('HEAD', this.get('image'), false);
      http.send();

      return http.status !== 404;
    }
    return false;
  },

  hasHomepage() {
    return this.get('hasHomepage');
  },

  canUpdate() {
    return this.hasPermission('Workspace.Interactive');
  },

  canToggleReadOnly() {
    return this.hasPermission('WorkspaceStorage.Write');
  },

  workspaceAdmin() {
    return this.hasPermission('Workspace.WriteMeta');
  },

  hasPermission(validPermissions) {
    const permissions = typeof validPermissions === 'string' ? [validPermissions] : validPermissions;
    const permissionsToCheck = this.mgmt_workspace().get('permissions');

    return permissions.every(perm => _.contains(permissionsToCheck, perm));
  },

  maxImageSize() {
    return instance.get('fileSizesMbWorkspaceIcon');
  },

  canDestroy() {
    return this.get('canDestroy');
  },

  canArchive() {
    return false;
  },

  canEditMembers() {
    return this.hasPermission('Workspace.WriteMembers');
  },

  canManageSnapshots() {
    return this.hasPermission('WorkspaceExport.Create');
  },

  canAddNotes() {
    return this.hasPermission('Workspace.Interactive');
  },

  canAirlock() {
    return this.hasPermission('WorkspaceAirlock.Create') && this.get('canAirlock');
  },

  // This PRIMARY icon is displayed along side the item
  showIcon() {
    return this.isArchive() ? 'fa fa-archive fa-3x' : 'fa fa-folder fa-3x';
  },

  // This SECONDARY icon overlays the primary
  showIconOverlay() {
    return this.get('entityType') === 'request_workspace' ? 'fa-comments' : '';
  },

  getConsoleUsage() {
    if (!this._consoleUsage) {
      this._consoleUsage = new RToolsConsoleUsage({ workspace: this });
    }
    this._consoleUsage.fetch();
    return this._consoleUsage;
  },

  createWorkspace(params, success, failure) {
    idToken().then((token) => {
      $.ajax({
        url: 'api/admin/workspace',
        type: 'post',
        data: JSON.stringify(CreateWorkspacePayload(params)),
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }).done(() => {
        success();
      }).fail((xhr) => {
        failure(xhr.responseJSON.error);
      });
    });
  },

  updateWorkspace(params, success, failure) {
    idToken().then((token) => {
      $.ajax({
        url: `api/admin/workspace/${params.uuid}`,
        type: 'put',
        data: JSON.stringify(UpdateWorkspacePayload(params)),
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }).done(() => {
        success();
      }).fail((xhr) => {
        failure(xhr.responseJSON.error);
      });
    });
  },
});

let getManagementWorkspace = null;

export const useDependencyHack = (fn) => { getManagementWorkspace = fn; }

export default Workspace;
