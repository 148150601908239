import $ from '../../jquery';
import t from '../../intl';
import PageItemList from '../core/page_item_list_view';
import WorkfileItem from './workfile_item_view';

export default PageItemList.extend({
  eventName: 'workfile',
  constructorName: 'WorkfileListView',
  useLoadingSection: true,
  fileType: '',

  setup(options, ...args) {
    this.options.entityType = 'workfile';
    this.options.EntityViewType = WorkfileItem;
    this.options.listItemOptions = {
      hasActiveWorkspace: this.options.hasActiveWorkspace,
    };

    this.setFolder(options.folder);
    this._super('setup', args);
    this.listenTo(this.collection, 'changeType', this.changeType);
  },

  postRender(...args) {
    const $list = this.$el;
    if (this.collection.length === 0 && this.collection.loaded) {
      const noWorkfileEl = $("<li class='browse_more'></li>");
      noWorkfileEl.append(t('workfiles.none'));
      $list.append(noWorkfileEl);
    }

    this._super('postRender', args);
    this.collection.trigger('filtersChanged', true);
  },

  changeType(choice) {
    if (this.fileType !== choice) {
      this.fileType = choice;
      this.collection.attributes.fileType = this.fileType;
      this.collection.fetch();
    }
  },

  setFolder(choice) {
    if (this.folder !== choice) {
      this.folder = choice.name();
      this.collection.setFolder(this.folder);
    }
  },
});
