import {
  Header, Icon, Segment,
} from 'semantic-ui-react';

const AppsHeader = () => (
  <Segment
    as="header"
    attached="top"
  >
    <Header>
      <Icon name="play" color="blue" data-testid="apps-header-icon" size="large" />
      <Header.Content>
        Apps
      </Header.Content>
    </Header>
  </Segment>
);

export default AppsHeader;
