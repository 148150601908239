import { columnTypes, steps } from './constants';
import { workspaceId } from '../../../layout/helper';

export const databaseTableColumn = (id, name, type, label, description, primary, primaryAdded) => (
  {
    id,
    name: name || '',
    type: type || columnTypes.TEXT,
    label: label || '',
    description: description || '',
    primary: primary || false,
    disabled: false,
    primary_added: primaryAdded || false,
  }
);

export const databaseTableMetadata = () => ({
  source_metadata_file: '',
  source_metadata_file_id: null,
  file_size: null,
  delimiter: ',',
  other_delimiter: '',
  input_has_header: 'true',
  text_qualifier: '"',
  null_qualifier: '',
  encoding: 'utf-8',
  title: '',
  description: '',
  url: '',
  columns: [],
  is_empty: false,
  has_delimiter: false,
  has_columns: false,
});

export const databaseTableParsedContent = () => ({
  splitted_table_header: [],
  splitted_table_rows: [],
  parsing_errors: false,
  columns: [],
});

export const newDatabaseTable = (passedCSVFile) => ({
  table: {
    workspace_id: workspaceId(),
    data_table_name: '',
    data_table_name_from_file: '',
    source_file: '',
    source_file_id: null,
    source_file_content: '',
    source_file_size: 0,
    metadata: databaseTableMetadata(),
    columns: [
      databaseTableColumn(0),
    ],
    columns_rows_mismatch: false,
    columns_to_display_set: false,
    added_primary_column: false,
    parsed_content: databaseTableParsedContent(),
    original_rows_length: 0,
    creation_complete: false,
    tdf_is_linked: false,
    dataset_id: null,
    guessed_columns: [],
    typeguessing_in_progress: false,
  },
  passed_csv_file: passedCSVFile || null,
  validation_errors: [],
  warnings: [],
  next_step_enabled: false,
  current_step: steps.FIRST,
});
