import { Header, Segment } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { PanelHeader, DefaultPanelHeaderIcon } from '../../../../layout';
import VirtualMachinesHeaderButtons from './VirtualMachinesHeaderButtons';

const HeaderIcon = () => DefaultPanelHeaderIcon({ name: 'computer' });
const HeaderText = () => (
  <Header
    as="h3"
    className="truncate"
    content={t('virtual_machines.header_text')}
    title={t('virtual_machines.header_text')}
  />
);
const buttons = (trigger) => VirtualMachinesHeaderButtons({ trigger });

const VirtualMachinesPanel = ({ requestVmTrigger, children }) => (
  <Segment.Group className="aridhia-panel-wrapper" data-testid="virtual-machines">
    <PanelHeader headerIcon={HeaderIcon()} headerText={HeaderText()} headerButtons={buttons(requestVmTrigger)} />
    {children}
  </Segment.Group>
);

export default VirtualMachinesPanel;
