import Collection from "../../../../site/app/javascript/application/collections/workspace_token_collection";

export default Collection.extend({
  urlTemplate: 'api/containerised_apps',
  constructorName: 'ContainerisedAppsPods',

  parse(response) {
    delete this.serverErrors;

    return response.apps.map(app => ({
      imageName: app.image,
      workspace_id: app.workspace_id,
      status: app.status,
      user_uuid: app.user_uuid,
    }));
  },
});
