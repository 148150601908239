import { Dropdown } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { runOffice } from '../../../../constants/ui_urls';
import ImageIcon from '../../../../components/ImageIcon';
import { useOfficeDiscoveryContext } from '../../../OfficeDiscoveryContext';

const OfficeDropdownItem = ({ fileId, fileName }) => {
  const { getAction } = useOfficeDiscoveryContext();
  const action = getAction(fileName);

  if (!action) {
    return null;
  }

  const icon = action.favIconUrl ? <ImageIcon src={action.favIconUrl} /> : 'file outline';
  const text = t('file_list.actions.open_with', { app: t(`office.app.${action.appName}`) });
  const href = runOffice(fileId, action.appName);

  return <Dropdown.Item icon={icon} text={text} href={href} />;
};

export default OfficeDropdownItem;
