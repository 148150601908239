import View from '../loading_view';

export default View.extend({
  constructorName: 'ActivityListHeaderView',
  templateName: 'activity_list_header',
  persistent: true,

  events: {
    'click .all': 'onAllClicked',
    'click .insights': 'onInsightsClicked',
    'click .awaiting_authorisation': 'onPendingDownloadsClicked',
  },

  subviews: {
    '.tag_box': 'tagBox',
  },

  setup() {
    this.subscribePageEvent('note:deleted', this.updateInsightCount);
    this.subscribePageEvent('insight:promoted', this.updateInsightCount);

    if (!this.collection) {
      this.collection = this.model.activities();
    }
    this.insightsCount = this.collection.clone();
    this.insightsCount.attributes.entity = this.collection.attributes.entity;
    this.insightsCount.attributes.insights = true;
    this.insightsCount.fetchPage(1, { per_page: 0 });
    this.requiredResources.add(this.insightsCount);

    this.allTitle = this.options.allTitle;
    this.insightsTitle = this.options.insightsTitle;
    // this.tagBox = this.options.tagBox;
    // XAP-11157 - Temp disabling of tags
    this.tagBox = false;
    this.listenTo(this.collection, 'reset', this.updateInsightCount);
  },

  additionalContext() {
    return {
      title: this.pickTitle(),
      showInsights: this.collection.attributes.insights,
      insightCount: this.insightsCount.totalRecordCount(),
      tagBox: this.tagBox,
      canApproveAirlock: this.model.hasPermission('WorkspaceAirlock.Approve'),
    };
  },

  pickTitle() {
    return this.collection.attributes.insights ? this.insightsTitle : this.allTitle;
  },

  updateInsightCount() {
    this.insightsCount.fetchPage(1, { per_page: 0 });
  },

  reloadCollection() {
    this.collection.loaded = false;
    this.collection.reset();
    this.collection.fetch();
    this.render();
  },

  resetFilterAttributes() {
    this.collection.attributes.insights = false;
    this.collection.attributes.awaiting_authorisation = false;
  },

  onAllClicked(e) {
    e.preventDefault();

    this.resetFilterAttributes();
    this.reloadCollection();
  },

  onInsightsClicked(e) {
    this.filterBy(e, 'insights');
  },

  onDownloadsClicked(e) {
    this.filterBy(e, 'downloads');
  },

  onPendingDownloadsClicked(e) {
    this.filterBy(e, 'awaiting_authorisation');
  },

  filterBy(e, attribute) {
    e.preventDefault();
    this.resetFilterAttributes();
    this.collection.attributes[attribute] = true;
    this.reloadCollection();
  },
});
