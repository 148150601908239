import axios from 'axios';
import _ from '../../underscore';
import Handlebars from '../../vendor/handlebars';
import Workfile from '../../models/workfile';
import Model from '../models';
import SQLResults from '../../mixins/sql_results';
import Routing from '../../mixins/routing';
import Workspace, { workspaceToken } from '../../models/workspace';

export default Model.include(SQLResults, Routing).extend({

  entityType: 'mini_app',
  destroy(includeDependencies) {
    this._super('destroy', [{ url: this.url({ includeDependencies }) }]);
    if (this.collection) {
      this.collection.remove(this);
    }
  },

  url(options) {
    const includeDependencies = (options && options.includeDependencies) ? '?include_dependencies=1' : '';
    if (this.collection) {
      const template = `${this.collection.urlTemplate}/{{ id }}`;
      const context = _.extend({}, this.attributes, { workspaceID: this.collection.attributes.workspaceID });
      const uri = new window.URI(`/${Handlebars.compile(template, { noEscape: true })(context)}`);
      return uri.toString() + includeDependencies;
    }

    const { workspaceID } = this.attributes;
    return `api/workspaces/workspaces/${workspaceID}/mini_app/${this.attributes.id}${includeDependencies}`;
  },

  removeMiniApp() {
    const { name } = this.attributes;
    const workspaceID = this.attributes.workspaceID || this.collection.attributes.workspaceID;
    return this.tokenFunction().then(token => axios
      .delete(`api/mini_apps/${workspaceID}/${encodeURIComponent(name)}`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
      .then(response => response.data));
  },

  getLog() {
    const { name, workspaceID } = this.attributes;
    const options = 'timestamps=true';
    return axios
      .get(`api/mini_apps/${workspaceID}/${encodeURIComponent(name)}/logs?${options}`)
      .then(response => response.data);
  },

  getSocketUrl() {
    const { name, workspaceID } = this.attributes;
    const baseURL = `${(window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host}/api`;
    const options = 'tailLines=100&timestamps=true';
    const wsURL = `${baseURL}/mini_apps/${workspaceID}/${encodeURIComponent(name)}/logs?${options}`;
    return wsURL;
  },

  getWorkfiles() {
    return this.get('workfiles').map(workfileData => new Workfile(workfileData));
  },

  latestFileModificationTimestamp() {
    return this.attributes.lastChange.time;
  },

  latestModifiedFile() {
    return this.attributes.lastChange.file;
  },

  getCurrentName() {
    return this.attributes.name;
  },

  showUrlTemplate() {
    return 'workspaces/{{workspace.id}}/mini_app/{{id}}';
  },

  initializeApp() {
    // return promise of post request to create the mini app
    const { name, workspaceID } = this.attributes;
    return this.tokenFunction().then(token => axios
      .post(`api/mini_apps/${workspaceID}/${encodeURIComponent(name)}`, this.attributes, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
      .then(response => response.data));
  },

  status() {
    const { name, workspaceID } = this.attributes;

    return this.tokenFunction().then(token => axios
      .get(`/api/mini_apps/${workspaceID}/${name}`, {
        timeout: 10000,
        headers: {
          Authorization: `JWT ${token}`,
        },
      }));
  },

  appIsRunning() {
    const { name, workspaceID } = this.attributes;

    return axios
      .get(`/api/mini_apps/${workspaceID}/${name}/app/`, {
        timeout: 5000,
        // only reject the promise on 5XX status codes, allow 502 as that is the expected response from a loading MiniApp
        validateStatus: status => (status === 502 || status < 500),
      });
  },

  tokenFunction() {
    const workspaceID = this.attributes.workspaceID || this.collection.attributes.workspaceID;
    if (!this._tokenFunction) {
      this._tokenFunction = workspaceToken(new Workspace({ id: workspaceID }));
    }
    return this._tokenFunction();
  },
});
