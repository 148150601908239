import { encode, specialEncodeFolderPaths } from '../../utilities/helpers';
import { workspaceId } from '../layout/helper';

const BASE = '/api';

const urls = Object.freeze({
  CURRENT_USER: `${BASE}/admin/user/self`,
  WORKSPACE_ADMIN: `${BASE}/admin/workspace`,
  WORKSPACE_API: `${BASE}/workspaces/workspaces`,
  WORKFILE_API: `${BASE}/workspaces/workfiles`,
  ANNOUNCEMENTS: `${BASE}/admin/serviceannouncements`,
  GUAC: `${BASE}/guac`,
  VM_CONTROL: `${BASE}/vm-control`,
  WOPI_ACCESS: `${BASE}/workspaces/wopi_access`,
});

export const workspaceAdminUrl = (uuid) => `${urls.WORKSPACE_ADMIN}/${uuid}`;
export const workspaceUrl = () => `${urls.WORKSPACE_API}/${workspaceId()}`;
export const currentUserUrl = urls.CURRENT_USER;
export const configUrl = `${BASE}/workspaces/config/`;

// Activity
export const workspaceActivity = (entityType, entityId, page = 1, perPage = 50) => `${BASE}/workspaces/activities?entity_type=${entityType}&entity_id=${entityId}&page=${page}&per_page=${perPage}`;
export const commentUrl = () => `${BASE}/workspaces/comments`;

// Apps
export const workspaceAppsUrl = () => `${workspaceUrl()}/containerised_app`;
export const workspaceAppPodsUrl = () => `${BASE}/containerised_apps`;

// Database Tables
export const workspaceTablesListUrl = (page = 1, namePattern = '', orderBy = 'object_name') => `${workspaceUrl()}/`
  + `datasets?name_pattern=${encode(namePattern)}&page=${page}&per_page=50&order=${orderBy}`;
export const workspaceSaveTableAsCSV = () => `${workspaceUrl()}/datasets/export`;
export const workspaceDeleteTable = (tableId, withDependencies) => `${workspaceUrl()}/datasets/${tableId}?include_dependencies=${withDependencies}`;
export const workspaceTable = (tableId) => `${workspaceUrl()}/datasets/${tableId}`;
export const databaseTableColumns = (tableId, page = 1, perPage = 2000) => `${BASE}/workspaces/datasets/${tableId}/columns?page=${page}&per_page=${perPage}`;
export const newDatabaseTable = () => `${BASE}/workspaces/datasets/import`;
export const databaseTableMetadata = (tableId) => `${BASE}/workspaces/datasets/${tableId}/metadata`;
export const databaseTableStatistics = (tableId) => `${BASE}/workspaces/datasets/${tableId}/statistics`;
export const databaseTablePreview = (tableId) => `${BASE}/workspaces/datasets/${tableId}/previews`;
export const newDBView = (tableId) => `${BASE}/workspaces/chorus_views/${tableId}/convert`;

// Files
export const workspaceWorkfilesUrl = () => `${workspaceUrl()}/workfiles`;
export const workspaceWorkfileUrl = (fileId) => `${urls.WORKFILE_API}/${fileId}`;
export const workspaceFilesListUrl = (folder = 'files', page = 1, namePattern = '', orderBy = '', orderByDirection = '', foldersOnly = '') => `${workspaceUrl()}/`
  + `workfiles?name_pattern=${encode(namePattern)}&file_type&folder=${specialEncodeFolderPaths(folder)}&files_only&`
  + `folders_only=${foldersOnly}&page=${page}&per_page=50&order=${orderBy}%20${orderByDirection}`;

// Office
export const officeDiscoveryUrl = '/office/hosting/discovery';
export const wopiAccessTokenUrl = (fileId) => `${urls.WOPI_ACCESS}/files/${fileId}/tokens`;

// Home
export const homepageUrl = () => `${workspaceUrl()}/home`;

// Tools
export const virtualMachinesUrl = `${urls.VM_CONTROL}/vms`;
export const virtualMachineUrl = (vmName) => `${urls.VM_CONTROL}/vms/${vmName}`;

// Notes
export const workspaceFileAttachmentsUrl = (folder = 'files', page = 1, namePattern = '') => {
  if (folder.startsWith('database tables')) return workspaceTablesListUrl(page, namePattern);
  return workspaceFilesListUrl(folder, page, namePattern);
};
export const noteUrl = () => `${BASE}/workspaces/notes`;

// Audit
export const workspaceAuditUrl = () => `${workspaceUrl()}/audit`;
export const downloadWorkspaceAuditUrl = () => `${workspaceUrl()}/audit_download.csv`;
export const getWorkspaceAuditUrl = (page, sort, sortDir, filteredColumns, filteredValues) => {
  const params = new URLSearchParams(`limit=50&page=${page}&sort=${sort}&sort_dir=${sortDir}`);

  if (filteredColumns.length > 0) {
    filteredColumns.forEach((col) => params.append('cols[]', col));
    filteredValues.forEach((val) => params.append('col_vals[]', val));
  }
  return `${workspaceAuditUrl()}?${params}`;
};

// Metadata
export const workfileContentMetadata = (fileId, typeOfContent, includeMetadata) => `${urls.WORKFILE_API}/${fileId}?include_content=${typeOfContent}&content_is_metadata=${includeMetadata}`;
export const workfileMetadataExtraction = () => `${BASE}/workspaces/metadata_extractions`;
export const workfileMetadataExtractionStatus = (extractionUuid) => `${BASE}/workspaces/metadata_extractions/${extractionUuid}`;

// Snapshot
export const workspaceSnapshot = () => `${workspaceUrl()}/exports`;

export default urls;
