import t from '../../intl';
import _ from '../../underscore';
import Dialog from '../../dialogs/dialogs';
import { fileVersions } from '../../utilities/file';
import Routing from '../../mixins/routing';

/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true }] */

export default Dialog.include(Routing).extend({
  constructorName: 'WorkfileNewVersion',

  templateName: 'workfile_new_version',
  title: t('workfile.new_version_dialog.title'),

  persistent: true,

  events: {
    'submit form': 'saveWorkfileNewVersion',
  },

  setup() {
    this.listenTo(this.model, 'saved', this.saved);
  },

  makeModel(...args) {
    this._super('makeModel', args);
    this.model = this.pageModel;
  },

  saveWorkfileNewVersion(e) {
    e.preventDefault();
    this.$('button.submit').startLoading('actions.saving');
    this.model.set({ commitMessage: _.escape(this.$('[name=commitMessage]').val()) }, { silent: true });
    this.model.save({}, { newWorkfileVersion: true });
  },

  saved() {
    this.$('button.submit').stopLoading();
    this.closeModal();

    fileVersions(this.model).fetch();
    this.model.activities().fetch();

    const targetUri = this.model.showUrl();
    let fragment = window.URI().fragment();
    fragment = fragment[0] === '/' ? `#${fragment}` : `#/${fragment}`;

    if (fragment !== targetUri) {
      this.navigate(targetUri);
    }
  },
});
