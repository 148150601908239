import _ from '../../underscore';
import t from '../../intl';
import ChartConfiguration from './chart_configuration_view';

export default ChartConfiguration.extend({
  templateName: 'chart_configuration',
  additionalClass: 'timeseries',

  postRender() {
    this.$('.category option:eq(1)').attr('selected', 'selected');
    this._super('postRender');
  },

  columnGroups: [
    {
      name: 'value',
      type: 'numeric',
      options: {
        key: 'dataset.visualization.sidebar.group_by',
        values: _.map(['sum', 'min', 'max', 'avg', 'count'], name => t(`dataset.group.${name}`)),
      },
    },
    {
      name: 'time',
      type: 'time',
      options: {
        key: 'dataset.visualization.sidebar.interval',
        values: _.map(['minute', 'hour', 'day', 'week', 'month', 'year'], name => t(`time.${name}`)),
      },
    },
  ],

  chartOptions() {
    const aggMap = {};
    _.each(['sum', 'min', 'max', 'avg', 'count'], (name) => {
      aggMap[t(`dataset.group.${name}`)] = name;
    });

    const xAxis = this.$('.time select option:selected').text();
    const timeColumn = (_.filter(this.columns, column => column.get('name') === xAxis))[0];

    return {
      type: 'timeseries',
      name: this.model.get('objectName'),
      xAxis,
      yAxis: this.$('.value select option:selected').text(),
      aggregation: aggMap[this.$('.value .selected_value').text()],
      timeInterval: this.$('.time .selected_value').text(),
      timeType: timeColumn.get('typeCategory').toLowerCase(),
    };
  },
});
