import { Modal, Button, Header } from 'semantic-ui-react';
import { t } from 'i18n-js';
import './CopyWarningAlert.less';

const CopyWarningAlertModal = ({ open, handleCloseWarning }) => (
  <Modal
    className="semantic"
    id="copywarningalert"
    size="small"
    open={open}
  >
    <Header
      content={t('copy_warning.title')}
      icon="exclamation triangle"
    />
    <Modal.Content>
      <p>
        {t('copy_warning.content')}
        <a href={t('url.knowledgebase_terms')} rel="noopener noreferrer" target="_blank">
          {t('copy_warning.tandc')}
        </a>
        .
      </p>
    </Modal.Content>
    <Modal.Actions>
      <Button
        color="blue"
        onClick={handleCloseWarning}
      >
        {t('copy_warning.accept')}
      </Button>
    </Modal.Actions>
  </Modal>
);

export default CopyWarningAlertModal;
