import _ from '../underscore';
import t from '../intl';
import Model from './models';

export default Model.extend({
  constructorName: 'Tag',
  entityType: 'tag',
  urlTemplate: 'api/workspaces/tags/{{id}}',

  showUrlTemplate(workspaceId) {
    if (workspaceId) {
      return `workspaces/${workspaceId}/tags/{{encode name}}`;
    }
    return 'tags/{{encode name}}';
  },

  matches(tagName) {
    const name = this.get('name');
    return name && _.strip(name.toLowerCase()) === _.strip(tagName.toLowerCase());
  },

  performValidation(newAttrs) {
    this.clearErrors();
    if (newAttrs.name.length > 100) {
      this.setValidationError('name', 'field_error.TOO_LONG', null, { field: t('tag.name'), count: 100 });
      return false;
    } else if (newAttrs.name.length === 0) {
      this.setValidationError('name', 'field_error.BLANK', null, { field: t('tag.name') });
      return false;
    }
    return true;
  },
});
