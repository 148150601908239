import _ from '../underscore';
import Activity from '../models/activity';
import Collection from './collections';

export default Collection.extend({
  constructorName: 'ActivitySet',
  model: Activity,

  setup() {
    this.bind('reset', this.reindexErrors);
  },

  reindexErrors() {
    _.each(this.models, (activity) => {
      activity.reindexError();
    });
  },

  urlTemplate() {
    if (this.attributes.insights) {
      return 'api/workspaces/insights';
    } else if (this.attributes.awaiting_authorisation) {
      return `api/workspaces/workspaces/${this.workspaceId()}/airlock`;
    }

    return 'api/workspaces/activities';
  },

  urlParams() {
    if (this.attributes.awaiting_authorisation) {
      return { status: 'pending' };
    } else if (this.attributes.entity) {
      return { entityType: this.attributes.entity.entityType, entityId: this.attributes.entity.get('id') };
    }
    return { entityType: 'dashboard' };
  },

  workspaceId() {
    return this.attributes.entity.get('id');
  },
});
