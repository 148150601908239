import View from '../loading_view';
import User from '../../models/user';
import { getManagementWorkspace } from '../../models/management/workspace';

const detailsForMember = (member) => {
  const user = new User(member);
  return {
    imageUrl: user.fetchImageUrl({ size: 'icon' }),
    showUrl: user.showUrl(),
    displayName: user.displayName(),
    enabled: user.get('enabled'),
    displayInitials: user.displayInitials(),
    displayAvatarBackgroundColor: user.displayAvatarBackgroundColor(),
  };
};

export default View.extend({
  constructorName: 'WorkspaceMemberList',
  templateName: 'workspace_member_list',
  numberOfMembersToShow: 24,

  setup() {
    if (this.options.collection) {
      this.setCollection(this.options.collection);
      this.listenTo(this.collection, 'workspace:selected', this.setWorkspace);
      this.requiredResources.add(this.collection);
    }
    if (this.options.workspace) {
      this.managementWorkspace = getManagementWorkspace(this.options.workspace.get('uuid'));
      this.managementWorkspace.fetch().then(() => this.setMembers());
    }
  },

  additionalContext() {
    const allMembers = this.members ? this.members : [];
    const activeMembers = this.filterPendingInvitations(allMembers);
    const mappedMembers = activeMembers.length ? activeMembers.map(detailsForMember) : [];
    const filteredMembers = this.filterDuplicateMembers(mappedMembers);

    if (this.collection && this.workspace) {
      return {
        members: filteredMembers,
        active: this.workspace.get('status') === 'active',
      };
    } else if (this.options.show) {
      return {
        members: filteredMembers,
        active: true,
      };
    }

    return {
      active: false,
    };
  },

  setMembers() {
    this.members = this.managementWorkspace.get('members');
    this.render();
  },

  setCollection(collection) {
    if (collection) {
      this.setModel(collection);
      this.collection = collection;
      if (collection.loaded) {
        this.render();
      } else {
        collection.fetchIfNotLoaded({ per_page: this.numberOfMembersToShow, error: () => this.render() });
      }
    }
  },

  setWorkspace(workspace) {
    if (workspace && workspace.isInvited() === false) {
      this.workspace = workspace;
    }
  },

  filterPendingInvitations(members) {
    return members.filter(member => !member.invited);
  },

  filterDuplicateMembers(members) {
    return members.filter((member, index, list) => list.findIndex(t => (t.showUrl === member.showUrl)) === index);
  },
});
