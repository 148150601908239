import IFrame from '../core/iframe_view';
import RCommand from '../../models/r_command';
import Routing from '../../mixins/routing';
import { rCommands } from '../../collections/r_commands';
import uploadFile from '../../utilities/upload_file';

export default IFrame.include(Routing).extend({
  setup(options, ...args) {
    this._super('setup', [options, ...args]);
    this.tab = options.tab;
    this.workspace = options.workspace;
    this.type = options.type;
    this.analyse = options.analyse;

    this.requiredResources.add(this.workspace);

    if (this.type !== 'new') {
      this.itemModel = options.itemModel;
      this.requiredResources.add(this.itemModel);
      this.itemModel.fetchIfNotLoaded();
    } else {
      this.itemModel = null;
      this.resourceName = options.resourceName;
      this.analyse = false;
    }

    this.disableSandbox = true;

    this.additionalClass = 'simple_main_content';
  },

  resourcesLoaded() {
    if (this.itemModel) {
      this.tab.set('label', this.itemModel.name());
      this.url = `/datatable?workspace_id=${encodeURIComponent(this.workspace.id)
      }&analyse=${encodeURIComponent(this.analyse)
      }&type=${encodeURIComponent(this.type)
      }&model_id=${encodeURIComponent(this.itemModel.id)
      }&resource=${encodeURIComponent(this.itemModel.fullName())}`;
    } else {
      this.tab.set('label', this.resourceName);
      this.url = `/datatable?workspace_id=${encodeURIComponent(this.workspace.id)
      }&type=dataset` +
                `&resource=${encodeURIComponent(this.resourceName)}`;
    }
    this.render();
  },

  showAnalyse(analyse) {
    if (this.analyse !== analyse) {
      this.analyse = analyse;
      this.resourcesLoaded();
    }
  },

  reloadFile(resourceId) {
    this.itemModel = this.workspace.getWorkfile(resourceId);
    this.resourceName = null;
    this.itemModel.fetch();
  },

  reloadDataset(resourceId) {
    this.itemModel = this.workspace.getDataset(resourceId);
    this.resourceName = null;
    this.itemModel.fetch();
  },

  reload(resource, resourceType, resourceId, resourceName, resourceFullName, workspaceId) {
    if (resource === this.resourceName ||
            (this.itemModel && resource === this.itemModel.fullName())) {
      this.type = resourceType;

      switch (this.type) {
        case 'file': this.reloadFile(resourceId); break;
        default: this.reloadDataset(resourceId); break;
      }

      this.tab.set('label', resourceName);
      this.url = `/datatable?workspace_id=${encodeURIComponent(workspaceId)}
      &type=${encodeURIComponent(this.type)}
      &model_id=${encodeURIComponent(resourceId)}
      &resource=${encodeURIComponent(resourceFullName)}`;

      this.tab.set('url', `#/workspaces/${workspaceId}/datatable/${resourceType}/${resourceId}`);
    }
  },

  runR(command) {
    const rCommand = new RCommand({
      workspace_id: this.workspace.id,
      command,
      action: 'run',
    });
    rCommands.add(rCommand);
    this.navigate(rCommand.showUrl());
  },

  onMessage(event) {
    this._super('onMessage', [event]);
    switch (event.data.type) {
      case 'save':
        uploadFile(event.data.fileName, event.data.description, event.data.file, this.workspace.id);
        break;
      case 'run_r':
        this.runR(event.data.command);
        break;
      default:
        this.reload(
          event.data.resource,
          event.data.resourceType,
          event.data.resourceId,
          event.data.resourceName,
          event.data.resourceFullName,
          event.data.workspaceId,
        );
        break;
    }
  },
});
