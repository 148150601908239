/* eslint-disable no-debugger, no-undef */

import axios from 'axios';
import Backbone from 'backbone';
import { t } from 'i18n-js';
import { idToken } from '../application/auth/oauth';
import workspaceToken from '../mixins/workspace_token';
import Routing from '../mixins/routing';
import _ from 'underscore';
import { FEATURE } from '../utilities/features';

export const appRunStatus = {
  IDLE: 'IDLE',
  FETCHING: 'FETCHING',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  READY: 'READY',
  STOPPING: 'STOPPING',
};

export const podStatusToAppRunState = (podStatus) => {
  switch (podStatus) {
    case 'inactive':
      return { code: appRunStatus.IDLE };
    case 'failed':
      return { code: appRunStatus.ERROR, message: t('app.initialize.image.error') };
    case 'noresource':
      return { code: appRunStatus.ERROR, message: t('app.load.error.noresource') };
    case 'unknown':
      return { code: appRunStatus.ERROR, message: t('app.load.error.unknown') };
    case 'pulling':
      return { code: appRunStatus.LOADING, message: t('app.pulling_image') };
    case 'ready':
      return { code: appRunStatus.LOADING, message: t('app.testing_connection') };
    case 'loading':
      default:
      return { code: appRunStatus.LOADING, message: t('app.loading') };
  }
}

const ContainerisedApp = Backbone.Model
  .extend({
    url() {
      const workspaceId = this.get('workspaceID');
      const id = this.get('uuid');
      if (id) {
        return `api/workspaces/workspaces/${workspaceId}/containerised_app/${id}`;
      }
      return `api/workspaces/workspaces/${workspaceId}/containerised_app`;
    },

    defaults: () => ({
      name: '',
      imageName: '',
      port: '',
      envVars: [],
      dbAccess: false,
      imageTileUrl: '',
      fileMountPath: '',
      allowDownload: false,
      filesRequired: false,
      readOnlyFiles: false,
      isEditing: false,
      externalAccess: false,
      token: '',
      tokenValidUntil: '',
      created: '',
      modified: '',
      startedBy: '',
      type: '',
      showToken: false,
      developer: true,
      isSaving: false,
      logsEnabled: false,
      workspace: null,
      displayPicture: null,
      podStatus: '',
      managementUrl: '',
      runState: { code: appRunStatus.IDLE },
      additionalInfo: '',
      license: '',
    }),

    initialize() {
      this._refreshMinimumDataEntryMet();
      this.setShowToken = this.setShowToken.bind(this);
      this.closeTab = this.closeTab.bind(this);
      this._updateIsSaving = this._updateIsSaving.bind(this);
      this.tokenFunction = this.tokenFunction.bind(this);
      this.setRunState = this.setRunState.bind(this);
      if (this.collection && !this.has('workspace')) {
        this.set('workspaceID', this.collection.workspace.id);
        this.set('workspace', this.collection.workspace);
      }
      this.timeouts = [];
    },

    parse(resp) {
      const response = resp.response || resp;

      const { filesRequired, readOnlyFiles } = this._parseFilesAccess(response);
      const envVars = this._parseEnvironmentVariables(response);

      const attrs = {
        id: response.id,
        uuid: response.uuid,
        name: response.name,
        imageName: response.image_name,
        imageTileUrl: response.image_tile,
        port: response.port,
        allowDownload: response.allow_download === true,
        filesRequired,
        readOnlyFiles,
        fileMountPath: response.file_mount_path,
        dbAccess: response.db_access,
        type: response.type,
        envVars,
        displayPicture: response['display_picture'],
        appUrl: response.app_url,
        managementUrl: response.management_url,
        additionalInfo: response.additional_info,
        license: response.license,
      };

      return attrs;
    },

    _parseFilesAccess(response) {
      switch (response.files_access) {
        case ContainerisedApp.filesAccessLevel.READ_ONLY:
          return {
            filesRequired: true,
            readOnlyFiles: true,
          };
        case ContainerisedApp.filesAccessLevel.FULL:
          return {
            filesRequired: true,
            readOnlyFiles: false,
          };
        case ContainerisedApp.filesAccessLevel.NONE:
        default:
          return {
            filesRequired: false,
            readOnlyFiles: false,
          };
      }
    },

    _parseEnvironmentVariables(response) {
      const envs = response.environment_variables;
      return Object.keys(envs).map((key) => this._createEnvVar(key, envs[key]));
    },

    toJSON() {
      return {
        id: this.get('id'),
        name: this.get('name'),
        image_name: this.get('imageName'),
        image_tile: this.get('imageTileUrl'),
        port: this.get('port'),
        allow_download: this.get('allowDownload'),
        files_access: this._serialiseFilesAccess(),
        file_mount_path: this.get('fileMountPath'),
        db_access: this.get('dbAccess'),
        environment_variables: this._serialiseEnvironmentVariables(),
      };
    },

    _serialiseFilesAccess() {
      const filesRequired = this.get('filesRequired');

      if (filesRequired) {
        const readOnlyFiles = this.get('readOnlyFiles');
        return readOnlyFiles
          ? ContainerisedApp.filesAccessLevel.READ_ONLY
          : ContainerisedApp.filesAccessLevel.FULL;
      }

      return ContainerisedApp.filesAccessLevel.NONE;
    },

    _serialiseEnvironmentVariables() {
      const envs = {};
      this.get('envVars').forEach((env) => Object.assign(envs, { [env.name]: env.value }));
      return envs;
    },

    async save(attributes, options = {}) {
      this._updateIsSaving(true);

      try {
        await Backbone.Model.prototype.save.call(this, attributes, options);
        this.closeTab();
      } catch (errResponse) {
        this._updateIsSaving(false);
      }
    },

    navigateToAppRun() {
      Routing.navigate(`workspaces/${this.get('workspaceID')}/app/${this.get('uuid')}`);
    },

    isSystemApp() {
      return this.get('type') === 'system';
    },

    navigateToAppsList() {
      Routing.navigate(`workspaces/${this.get('workspaceID')}/apps`);
    },

    updateName(name) {
      this.set('name', name);
      this._refreshMinimumDataEntryMet();
    },

    updateImageName(imageName) {
      this.set('imageName', imageName);
      this._refreshMinimumDataEntryMet();
    },

    updateImageTileUrl(url) {
      this.set('imageTileUrl', url);
    },

    updatePortNumber(port) {
      this.set('port', port);
      this._refreshMinimumDataEntryMet();
    },

    toggleAllowDownload() {
      const initialState = this.get('allowDownload');
      this.set('allowDownload', !initialState);
    },

    toggleFilesRequired() {
      const initialState = this.get('filesRequired');
      this.set('filesRequired', !initialState);
      this._refreshMinimumDataEntryMet();
    },

    updateFileMountPath(fileMountPath) {
      this.set('fileMountPath', fileMountPath);
      this._refreshMinimumDataEntryMet();
    },

    toggleReadOnlyFiles() {
      const initialState = this.get('readOnlyFiles');
      this.set('readOnlyFiles', !initialState);
    },

    toggleDbRequired() {
      const initialState = this.get('dbAccess');
      this.set('dbAccess', !initialState);
    },

    _envCount: 0,

    _createEnvVar(name, value) {
      this._envCount += 1;
      return {
        id: this._envCount, // assign id to be used as react key
        name,
        value,
      };
    },

    addEnvVar(name, value) {
      const newEnvVar = this._createEnvVar(name, value);
      const envVars = this.get('envVars');
      const index = envVars.findIndex((envVar) => envVar.name === name);

      if (index > -1) {
        envVars[index] = newEnvVar;
      } else {
        envVars.unshift(newEnvVar);
        envVars.sort((a, b) => a.name.localeCompare(b.name));
      }

      this.set('envVars', envVars);
      this.trigger('change:envVars');
    },

    updateVar(index, partial) {
      const envVars = this.get('envVars');
      Object.assign(envVars[index], partial);
      this.trigger('change:envVars');
    },

    removeVar(index) {
      const envVars = this.get('envVars');
      envVars.splice(index, 1);
      this.set('envVars', envVars);
      this.trigger('change:envVars');
    },

    toggleExternalAccess() {
      const initialState = this.get('externalAccess');
      this.set('externalAccess', !initialState);
    },

    getValidToken() {
      if (!this.validTokenExists(this.attributes)) {
        this.set('token', this.generateToken());
        this.set('tokenValidUntil', this.generateTokenDate());
      }
    },

    validTokenExists(attrs) {
      const now = new Date().valueOf();
      const dateStillValid = attrs && attrs.tokenValidUntil && now < new Date(attrs.tokenValidUntil).valueOf();

      return dateStillValid && attrs.token;
    },

    generateToken() {
    // TODO token will include externalAccess selection info
      const randomTokenNum = Math.floor(Math.random() * Math.floor(1000));
      return `This is sample token ${randomTokenNum.toString(10)}`;
    },

    generateTokenDate() {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      return currentDate.toUTCString();
    },

    requiredFieldsCompleted() {
      return this.get('name') !== ''
           && this.get('imageName') !== ''
           && this.get('port') !== '';
    },

    filesRequestCompleted() {
    // either no "files" access is required or, if it is, a file mount path has been provided
      const filesRequired = this.get('filesRequired');
      return !filesRequired || (filesRequired && !!this.get('fileMountPath'));
    },

    _refreshMinimumDataEntryMet() {
      const ready = this.requiredFieldsCompleted() && this.filesRequestCompleted();
      if (ready !== this.get('minimumDataEntryMet')) {
        this.set('minimumDataEntryMet', ready);
      }
    },

    setShowToken() {
      this.set('showToken', this.validTokenExists(this.attributes));
    },

    closeTab() {
      chorus.PageEvents.trigger('containerised_app:close', this);
    },

    _updateIsSaving(value) {
      this.set('isSaving', value);
    },

    setRunState(code, message) {
      this.set('runState', { code, message });
    },

    tokenFunction() {
      const workspace = this.get('workspace');
      if (!this._tokenFunction) {
        this._tokenFunction = workspaceToken(workspace);
      }
      return this._tokenFunction();
    },

    async refreshCookie() {
      try {
        const token = await idToken();
        await axios.get(
          `${this.attributes.managementUrl}/auth`,
          {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          },
        );
        return true;
      } catch {
        this.setRunState(appRunStatus.ERROR, t('app.auth_error'));
        return false;
      }
    },

    async fetchTags() {
      try {
        const token = await this.tokenFunction();
        const response = await axios.get(
          `${this.attributes.managementUrl}/tags?system_app=${this.isSystemApp()}`,
          {
            headers: { Authorization: `JWT ${token}` },
            withCredentials: true,
          },
        );

        const tags = response.data;
        if (tags && tags.length > 0) {
          return tags;
        }
        this.setRunState(appRunStatus.ERROR, t('app.run.no_tags'));
      } catch {
        this.setRunState(appRunStatus.ERROR, t('app.run.no_tags'));
      }

      return null;
    },

    async start() {
      const { port, fileMountPath, dbAccess } = this.attributes;

      try {
        const token = await this.tokenFunction();
        await axios.post(
          this.attributes.managementUrl,
          {
            system_app: this.isSystemApp(),
            port,
            files_access: this._serialiseFilesAccess(),
            file_mount_path: fileMountPath,
            db_access: dbAccess.toString(),
            environment_variables: this._serialiseEnvironmentVariables(),
          },
          {
            headers: { Authorization: `JWT ${token}` },
            withCredentials: true,
          },
        );
        this.setRunState(appRunStatus.LOADING, t('app.loading'));
      } catch {
        this.setRunState(appRunStatus.ERROR, t('app.initialize.error'));
      }
    },

    async stop(navigate = true) {
      this.setRunState(appRunStatus.STOPPING, t('app.stopping'));

      try {
        await this.refreshCookie();
        const token = await this.tokenFunction();
        const response = await axios.delete(
          this.attributes.managementUrl,
          {
            headers: { Authorization: `JWT ${token}` },
            withCredentials: true,
          },
        );

        if (!response.data.success) {
          throw new Error('Failed to stop app.');
        }

        this.setRunState(appRunStatus.IDLE);
        if (!FEATURE.NEW_UX) {
          chorus.PageEvents.trigger('containerised_app:stop', this);
          if (navigate) {
            this.navigateToAppsList();
          }
        }
      } catch {
        this.setRunState(appRunStatus.ERROR, t('app.stop.error'));
      }
    },
  },
  {
    filesAccessLevel: {
      NONE: 'noaccess',
      READ_ONLY: 'readonlyaccess',
      FULL: 'readwriteaccess',
    },
  });

export default ContainerisedApp;
