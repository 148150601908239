import { Button } from 'semantic-ui-react';
import { t } from 'i18n-js';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import InboxMultipleRejectModal from './modals/InboxMultipleRejectModal';
import InboxMultipleApproveModal from './modals/InboxMultipleApproveModal';
import Can from '../../../../permissions';
import { useInboxContext } from '../Context';

const ApproveButton = (
  <Button
    icon="thumbs up"
    basic
    size="small"
    content={t('file_list.actions.approve')}
  />
);

const RejectButton = (
  <Button
    icon="thumbs down"
    basic
    size="small"
    content={t('file_list.actions.reject_selected')}
  />
);

const InboxMultiSelectActions = () => {
  const { selectedFiles } = useInboxContext();

  return selectedFiles.length > 0 && (
    <Can I="Review" this="WorkspaceInboundAirlock">
      <ModalWrapper
        size="small"
        trigger={ApproveButton}
      >
        <InboxMultipleApproveModal />
      </ModalWrapper>
      <ModalWrapper trigger={RejectButton}>
        <InboxMultipleRejectModal />
      </ModalWrapper>
    </Can>
  );
};

export default InboxMultiSelectActions;
