import Backbone from 'backbone';
import CopyWarningAuditModel from './copy_warning_audit';
import chorus from '../../../../site/app/javascript/application/chorus';

const CopyWarning = Backbone.Model.extend({
  defaults: {
    open: false,
    workspaceID: '',
    tabs: null,
    resource: '',
  },

  setResource() {
    const tabs = this.get('tabs')
    const activeTab = tabs.getActive();
    const tabName = activeTab.get('name');
    let fileType = activeTab.get('file_type');
    if (tabName !== 'Datasets' ) {
      if (!fileType) {
        fileType = activeTab.get('fileType');
      }
    } else {
      fileType = tabName;
    }
    this.set('resource', fileType)
    return fileType
  },

  generateWorkspaceName() {
    const wId = this.get('workspaceID');
    const storageWorkspaceName = 'workspace' + wId;
    return storageWorkspaceName;
  },

  logOnApprovedTab(actualtab) {
    switch(actualtab) {
      case 'workfile':
      case 'dataset':
      case 'mini_app':
      case 'app':
      case 'Datasets':
        return true;
      default:
        return false;
    }
  },

  registerCopyEvent() {
    const actualResource =  this.get('resource');
    const wId = this.get('workspaceID') ;
    const copyWarningAuditModel = new CopyWarningAuditModel({
      resource: actualResource,
    });
    copyWarningAuditModel.save(null, {
      wait : true,
      workspaceID: wId,
      success : function(){
        sessionStorage.setItem(this.generateWorkspaceName(), true);
      }.bind(this),
      error : function(e){
        chorus.toast('copy_warning.error', { e, toastOpts: { theme: 'bad_activity' } });
      },
    });
  },

  closeCopyWarningAlert() {
    this.set('open', false);
    this.registerCopyEvent();
  },

  openCopyWarningAlert(warningEnabled) {
    const warningIssued = sessionStorage.getItem(this.generateWorkspaceName());
    const actualtab = this.setResource();
    if (!warningIssued && this.logOnApprovedTab(actualtab) && warningEnabled) {
      this.set('open', true);
    } else if (this.logOnApprovedTab(actualtab)) {
      this.registerCopyEvent();
    }
  },

});

export default CopyWarning;
