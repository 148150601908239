import { t } from 'i18n-js';
import {
  Segment, Label, Icon, Header,
} from 'semantic-ui-react';
import { listIcon } from '../../utilities/helper';

const AttachedItems = ({
  attachedItems,
  handleRemoveAttachedItems,
  hasItemsAttached = false,
}) => (
  <div className="margin-top-small">
    <Header
      block
      attached="top"
      as="h6"
      content={hasItemsAttached ? t('file_list.actions.linked_items') : t('file_list.actions.to_link_items')}
    />
    <Segment attached="bottom" className="aridhia-attached-items">
      {attachedItems.length === 0 && (
        <Label basic>
          <Icon name="file outline" color="grey" />
          {t('file_list.accessibility.no_files_linked')}
        </Label>
      )}
      {attachedItems.map((item) => (
        <Label
          color={hasItemsAttached ? 'green' : null}
          key={`attachedItems_${item.itemName}${item.itemId}`}
        >
          <Icon name={listIcon(item.itemType)} />
          {item.itemName}
          <Icon
            data-testid="remove-item-label"
            name="delete"
            onClick={() => handleRemoveAttachedItems(item)}
          />
        </Label>
      ))}
    </Segment>
  </div>
);

export default AttachedItems;
