import * as d3 from 'd3';
import $ from '../../jquery';
import View from '../loading_view';
import Axes from './axes';
import { Timeseries } from '../../presenters/visualization_data_presenter';

export default View.extend({
  render() {
    const $el = $(this.el);
    $el.html('');
    $el.addClass('visualization');
    const data = new Timeseries(this.model).present();

    const svg = d3.select(this.el)
      .append('svg')
      .attr('class', 'chart timeseries')
      .attr('width', 925)
      .attr('height', 340);

    let timeFormat;

    if (this.model.get('timeType').toLowerCase() === 'date') {
      timeFormat = d3.timeFormat('%Y-%m-%d');
    } else if (this.model.get('timeType').toLowerCase() === 'time') {
      timeFormat = d3.timeFormat('%H:%M:%S');
    } else {
      timeFormat = d3.timeFormat('%Y-%m-%d %H:%M:%S');
    }

    this.axes = new Axes({
      el: svg,
      yScaleType: 'numeric',
      xScaleType: 'time',
      maxYValue: data.maxY,
      minYValue: data.minY,
      minXValue: data.minX,
      maxXValue: data.maxX,
      xAxisLabel: this.model.get('xAxis'),
      yAxisLabel: this.model.get('yAxis'),
      hasYGrids: true,
      paddingX: 35,
      paddingY: 35,
      timeFormat,
      timeType: this.model.get('timeType').toLowerCase(),
    });

    if (!$el.isOnDom()) return;

    this.axes.render();

    const scales = this.axes.scales();

    const line = d3.line()
      .x(d => scales.x(Date.parse(d.time)))
      .y(d => scales.y(d.value));

    svg.append('svg:path')
      .attr('class', 'series')
      .attr('d', line(data));
  },
});

