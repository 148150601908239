import Backbone from '../vendor/backbone';
import _ from '../underscore';
import { inboxFileName } from '../utilities/url_helpers';

export default Backbone.Model.extend({

  name() {
    return this.get('folder');
  },

  topLevelFolder() {
    return this.name().split('/')[0];
  },

  crumbs() {
    const workspaceId = this.get('workspaceId');
    const crumbs = [];
    const parts = this.name().split('/');
    _.each(parts, (data, idx) => {
      if (!data.startsWith('?')) {
        if (this.topLevelFolder() === 'inbox' || this.topLevelFolder() === 'public inbox') {
          data = inboxFileName(data);
        }
        const folderPath = parts.slice(0, idx + 1).map(encodeURIComponent).join('/');
        crumbs.push({ label: data, url: `#/workspaces/${workspaceId}/folders/${folderPath}` });
      }
    });
    crumbs.shift();
    return crumbs;
  },

});
