import View from '../loading_view';

export default View.extend({
  constructorName: 'SubNavToolsView',
  templateName: 'sub_nav_tools',
  tagName: 'div',

  setup(options) {
    this.model = this.workspace || options.workspace;
    this.requiredResources.add(this.model);
  },

  additionalContext() {
    return { id: this.model.id };
  },
});
