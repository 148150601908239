import React from 'react';
import { BackboneProvider } from 'connect-backbone-to-react';
import $ from '../../jquery';
import View from '../loading_view';
import FetchErrors from '../../mixins/fetch_errors';
import UploadTokenContainer from 'components/upload_token/UploadTokenContainer';
import t from '../../intl';
import UploadToken from 'models/upload_token';
import UploadTokenSet from '../../collections/upload_token';
import UploadTokenList from 'models/upload_token_list';
import ButtonState from '../../models/button_state';
import chorus from '../../chorus';
import Routing from '../../mixins/routing';
import ManagementUser from '../../models/management/user';
import ManagementWorkspace from '../../models/management/workspace';
import { instance } from '../../models/config';

const findUser = (uuid, users) => users.find(user => user.get('uuid') === uuid);
const findMember = (uuid, users) => users.find(user => user.uuid === uuid);

export default View.include(Routing, FetchErrors).extend({
  bindCallbacks: $.noop,
  className: 'app-uploadTokenShow',

  setup(options) {
    this.workspace = options.model;

    this.uploadToken = new UploadToken();
    this.uploadTokenList = new UploadTokenList();

    this.buttonState = new ButtonState();
    this.uploadToken.on('save', () => {
      this.buttonState.set('saving', false);
    });

    this.collection = new UploadTokenSet([], {
      workspaceID: this.workspace.id,
      viewArchived: false,
    });

    this.usersCollection = [];
    this.managementWorkspace = new ManagementWorkspace({ uuid: this.workspace.get('uuid') });
    this.managementWorkspace.fetchIfNotLoaded();
    this.onceLoaded(this.managementWorkspace, () => {
      this.collection.fetchAllIfNotLoaded();
      this.handleFetchErrorsFor(this.collection);
      this.onceLoaded(this.collection, () => {
        this.getUsers();
        this.uploadTokenList.set({ tokens: this.collection, loaded: true });
        this.postRender();
      });
    });
    this.requiredResources.push(this.managementWorkspace);
  },

  dependentResourceUnavailable() {
    chorus.toast('upload_token.unavailable', { toastOpts: { theme: 'bad_activity' } });
    this.collection.loaded = true;
    this.postRender();
  },

  handleFetchErrorsFor(resource) {
    this.listenTo(resource, 'resourceNotFound', this.dependentResourceUnavailable);
    this.listenTo(resource, 'resourceForbidden', this.dependentResourceUnavailable);
    this.listenTo(resource, 'unprocessableEntity', this.dependentResourceUnavailable);
  },

  render() {
    if (!this.requiredResources.allResponded()) {
      return this;
    }

    const toggleView = () => {
      if (!this.collection.attributes.viewArchived) {
        this.collection.attributes.viewArchived = true;
        this.collection.fetchAll();
        this.onceLoaded(this.collection, () => {
          this.getUsers();
          this.uploadTokenList.set({ tokens: this.collection, viewArchived: true, page: 1 });
        });
      } else {
        this.collection.attributes.viewArchived = false;
        this.collection.fetchAll();
        this.onceLoaded(this.collection, () => {
          this.getUsers();
          this.uploadTokenList.set({ tokens: this.collection, viewArchived: false, page: 1 });
        });
      }
    };

    const createToken = () => {
      this.buttonState.set('saving', true);
      this.uploadToken.save({}, {
        workspaceID: this.workspace.id,
        publicInbox: this.uploadToken.get('publicInbox'),
        success: function onSuccess(_response, data) {
          this.buttonState.set('saving', false);
          chorus.toast('upload_token.save.success');
          this.scrollToTop();
          this.collection.attributes.viewArchived = false;
          this.collection.fetchAll();
          this.onceLoaded(this.collection, () => {
            const model = this.collection.get(data.id);
            model.set('token', data.token);
            this.getUsers();
            this.uploadToken.set({ id: null });
            this.uploadTokenList.set({ tokens: this.collection, viewArchived: false, page: 1 });
            this.uploadTokenList.trigger('change:tokens');
          });
        }.bind(this),
        error: function onFail(_model, response) {
          this.buttonState.set('saving', false);
          chorus.toast('upload_token.save.failed', {
            reason: response.statusText,
            toastOpts: { theme: 'bad_activity' },
          });
        }.bind(this),
      });
    };

    const deleteToken = ({ tokenID, publicInbox }) => {
      const uploadToken = new UploadToken({ id: tokenID });
      uploadToken.destroy({
        workspaceID: this.workspace.id,
        id: tokenID,
        publicInbox,
        success: function onSuccess() {
          chorus.toast('upload_token.delete.success');
          this.collection.fetchAll();
          this.onceLoaded(this.collection, () => {
            this.getUsers();
            this.uploadTokenList.set({ tokens: this.collection });
            this.uploadTokenList.trigger('change:tokens');
          });
        }.bind(this),
        error: function onFail(_model, response) {
          chorus.toast('upload_token.delete.failed', {
            reason: response.statusText,
            toastOpts: { theme: 'bad_activity' },
          });
        },
      });
    };

    const tokenCopied = () => {
      chorus.toast('upload_token.copied');
    };

    const changePage = ({ page }) => {
      this.uploadTokenList.set('page', page);
    };

    const modelsMap = {
      uploadToken: this.uploadToken,
      uploadTokenList: this.uploadTokenList,
      buttonState: this.buttonState,
    };

    const permissions = this.managementWorkspace.get('permissions');
    this.root.render(
      <BackboneProvider models={modelsMap}>
        <UploadTokenContainer
          toggleView={toggleView}
          changePage={changePage}
          createToken={createToken}
          deleteToken={deleteToken}
          tokenCopied={tokenCopied}
          publicInboxVisible={instance.get('topLevelFolders').includes('public inbox')}
          canSetDestination={permissions.includes('WorkspaceInboundAirlock.AutomatedApprove')}
        />
      </BackboneProvider>,
    );

    this.postRender();
    return this;
  },

  getUsers() {
    this.collection.each((model) => {
      try {
        const user_uuid = model.get('user') ? model.get('user').uuid : null;
        this.setUser(model, 'user', user_uuid);
      } catch (err) {}
    }, this);
  },

  lookupUser(uuid) {
    const user = findUser(uuid, this.usersCollection);
    if (user) { return user; }
    const non_member = new ManagementUser({ uuid });
    this.usersCollection.push(non_member);
    non_member.fetch();
    return non_member;
  },

  setUser(model, attribute, uuid) {
    const user = { firstName: t('actions.removed_user.first_name'), lastName: t('actions.removed_user.last_name'), uuid };
    if (!uuid) {
      model.set(attribute, user);
    } else {
      const member = findMember(uuid, this.managementWorkspace.get('members'));
      if (member) {
        model.set(attribute, member);
      } else {
        model.set(attribute, user);
        const non_member = this.lookupUser(uuid);
        this.onceLoaded(non_member, () => {
          model.set(attribute, { firstName: non_member.get('firstName'), lastName: non_member.get('lastName') });
          this.uploadTokenList.set({ tokens: this.collection });
        });
      }
    }
  },

  scrollToTop() {
    this.$el.find('.jspPane').css('top', '0');
  },
});
