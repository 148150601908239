import chorus from '../../chorus';
import $ from '../../jquery';
import View from '../loading_view';

export default View.extend({
  constructorName: 'DataTabDatasetColumnListView',
  templateName: 'data_tab_dataset_column_list',
  useLoadingSection: true,

  setup() {
    this.resource = this.options.dataset.columns();
    this.collection = this.resource;
    this.collection.fetchAll();
  },

  postRender() {
    this.setupDragging();
    chorus.search({
      list: this.$('ul'),
      input: this.$('input.search'),
    });
    chorus.page.trigger('resized');
  },

  setupDragging() {
    this.$('li').draggable({
      cursor: 'move',
      cursorAt: { top: 0, left: 0 },
      containment: 'document',
      appendTo: 'body',
      helper: this.dragHelper,
    });
  },

  dragHelper(e) {
    return $(e.currentTarget).clone().addClass('drag_helper');
  },

  collectionModelContext(column) {
    return {
      cid: column.cid,
      fullName: column.toText(),
      type: column.get('dataType'),
    };
  },
});
