import chorus from '../chorus';
import _ from '../underscore';
import Handlebars from '../vendor/handlebars';
import t, { I18n } from '../intl';
import Workfile from '../models/workfile';
import WorkspaceDataset from '../models/workspace_dataset';
import Schema from '../models/schema';
import Dataset from '../models/dataset';
import Workspace from '../models/workspace';
import Attachment from './attachment_presenter';
import Airlock from '../models/airlock';
import dynamicDataset from '../models/dynamic_dataset';
import { activityWorkspace, workspace } from '../utilities/workspace_assoc';
import { schema } from '../utilities/schema_assoc';
import Presenter from './presenters';
import { linkTo } from '../utilities/url_helpers';
import { unsafeT, relativeTimestamp } from '../utilities/helpers';
import { userInitials, avatarBackgroundColour } from '../mixins/avatars';

const headerDefinitions = {
  ContainerisedAppCreated: {
    links: ['actor'],
    computed: ['containerisedAppsLink', 'workspaceLink'],
  },
  ContainerisedAppDeleted: {
    links: ['actor'],
    attrs: ['name'],
    computed: ['workspaceLink'],
  },
  CredentialsInvalid: {
    links: ['dataSource'],
    computed: ['updateCredentialsLink'],
  },
  DataSourceChangedName: {
    links: ['actor', 'dataSource'],
    attrs: ['newName', 'oldName'],
  },

  DataSourceCreated: {
    links: ['actor', 'dataSource'],
  },

  DataSourceDeleted: {
    links: ['actor', 'dataSource'],
  },

  DataSourceChangedOwner: {
    links: ['actor', 'dataSource', 'newOwner'],
  },

  PublicWorkspaceCreated: {
    links: ['actor'],
    computed: ['workspaceLink'],
  },

  PrivateWorkspaceCreated: {
    links: ['actor'],
    computed: ['workspaceLink'],
  },

  WorkspaceMakePublic: {
    links: ['actor'],
    computed: ['workspaceLink'],
  },

  WorkspaceMakePrivate: {
    links: ['actor'],
    computed: ['workspaceLink'],
  },

  WorkspaceArchived: {
    links: ['actor'],
    computed: ['workspaceLink'],
  },

  WorkspaceDeleted: {
    links: ['actor'],
    computed: ['workspaceLink'],
  },

  WorkspaceUnarchived: {
    links: ['actor'],
    computed: ['workspaceLink'],
  },

  WorkfileCreated: {
    links: ['actor', 'workfile'],
    computed: ['versionWorkfileLink', 'versionLink', 'folder', 'fileorfolder', 'workspaceLink'],
  },

  WorkfileDeleted: {
    links: ['actor', 'workfile'],
    computed: ['folder', 'fileorfolder', 'workspaceLink'],
  },

  WorkfileReadOnly: {
    links: ['actor', 'workfile'],
    attrs: ['reason'],
    computed: ['folder', 'fileorfolder', 'workspaceLink'],
  },

  WorkfileReadWrite: {
    links: ['actor', 'workfile'],
    attrs: ['reason'],
    computed: ['folder', 'fileorfolder', 'workspaceLink'],
  },

  WorkfileUploading: {
    links: ['actor'],
    attrs: ['fileName', 'commitMessage', 'folder'],
    computed: ['workspaceLink'],
  },

  WorkfileUploadFailed: {
    links: ['actor'],
    attrs: ['fileName', 'commitMessage', 'folder', 'errorMessage'],
    computed: ['workspaceLink'],
  },

  WorkspaceAddSandbox: {
    links: ['actor'],
    computed: ['workspaceLink'],
  },

  SourceTableCreated: {
    links: ['actor', 'dataset', 'workspace'],
    computed: ['datasetType'],
  },

  UserAdded: {
    links: ['newUser'],
  },

  NOTE: {
    links: ['actor'],
    attrs: ['destinationName'],
    computed: ['noteObjectType', 'noteObjectLocation', 'workspaceTargetLink', 'workspaceLink', 'noteObjectLink'],
  },

  FileImportCreated: {
    links: ['actor', 'dataset'],
    attrs: ['importType'],
    computed: ['importSourceLink', 'datasetType', 'destObjectOrName', 'workspaceLink'],
  },

  FileImportSuccess: {
    links: ['dataset'],
    attrs: ['importType'],
    computed: ['importSourceLink', 'datasetType', 'destObjectOrName', 'workspaceLink'],
  },

  FileImportFailed: {
    attrs: ['importType', 'destinationTable'],
    computed: ['importSourceLink', 'datasetType', 'destObjectOrName', 'workspaceLink'],
  },

  DatasetExportCreated: {
    links: ['actor', 'dataset'],
    attrs: ['exportType'],
    computed: ['exportObjectOrName', 'workspaceLink'],
  },

  DatasetExportSuccess: {
    links: ['dataset'],
    attrs: ['exportType'],
    computed: ['exportObjectOrName', 'workspaceLink'],
  },

  DatasetExportFailed: {
    links: ['dataset'],
    attrs: ['exportType'],
    computed: ['exportObjectOrName', 'workspaceLink'],
  },

  DatasetDeleted: {
    links: ['actor', 'dataset'],
    computed: ['datasetType', 'workspaceLink'],
  },

  WorkspaceExportCreated: {
    links: ['actor'],
    attrs: ['exportType', 'reason'],
    computed: ['exportDestination'],
  },

  WorkspaceExportSuccess: {
    attrs: ['exportType'],
    computed: ['exportDestination', 'workspaceLink'],
  },

  WorkspaceExportFailed: {
    attrs: ['exportType'],
    computed: ['exportDestination', 'workspaceLink'],
  },

  MembersAdded: {
    links: ['actor', 'member'],
    computed: ['count', 'memberRole', 'workspaceLink'],
  },

  MembersInvited: {
    links: ['actor', 'member'],
    computed: ['countInvited', 'memberRole', 'workspaceLink'],
  },

  MembersInviteAccept: {
    links: ['actor', 'member'],
    computed: ['count', 'memberRole', 'workspaceLink'],
  },

  MembersInviteDecline: {
    links: ['actor', 'member'],
    computed: ['count', 'memberRole', 'workspaceLink'],
  },

  MembersDeleted: {
    links: ['actor', 'member'],
    computed: ['count', 'memberRole', 'workspaceLink'],
  },

  WorkspaceImportCreated: {
    links: ['actor', 'workspace', 'dataset'],
    attrs: ['sourceTable'],
    computed: ['importSourceDatasetLink', 'datasetType', 'destObjectOrName'],
  },

  ImportScheduleUpdated: {
    links: ['actor', 'workspace', 'dataset'],
    attrs: ['sourceTable'],
    computed: ['importSourceDatasetLink', 'datasetType', 'destObjectOrName'],
  },

  ImportScheduleDeleted: {
    links: ['actor', 'workspace', 'dataset'],
    attrs: ['sourceTable'],
    computed: ['importSourceDatasetLink', 'datasetType', 'destObjectOrName'],
  },

  WorkspaceImportSuccess: {
    links: ['workspace', 'dataset'],
    attrs: ['sourceTable'],
    computed: ['importSourceDatasetLink', 'datasetType'],
  },

  WorkspaceImportFailed: {
    links: ['workspace'],
    attrs: ['sourceDataset'],
    computed: ['importSourceDatasetLink', 'datasetType', 'destObjectOrName'],
  },

  SchemaImportSuccess: {
    computed: ['destinationDatasetInSchemaLink', 'sourceDatasetInSchemaLink', 'destinationSchemaLink'],
  },

  SchemaImportFailed: {
    computed: ['sourceDatasetInSchemaLink', 'destinationSchemaLink', 'destObjectOrNameInSchema'],
  },

  SchemaImportCreated: {
    links: ['actor', 'schema'],
    computed: ['sourceDatasetInSchemaLink', 'datasetType', 'destObjectOrNameInSchema'],
  },

  WorkfileUpgradedVersion: {
    links: ['actor', 'workfile'],
    computed: ['versionLink', 'versionWorkfileLink', 'folder', 'fileorfolder', 'workspaceLink'],
  },

  WorkfileVersionDeleted: {
    links: ['actor', 'workfile'],
    attrs: ['versionNum'],
    computed: ['folder', 'fileorfolder', 'workspaceLink'],
  },

  ChorusViewCreated: {
    links: ['actor', 'dataset'],
    computed: ['chorusViewSourceLink', 'chorusViewSourceType', 'workspaceLink'],
  },

  ChorusViewChanged: {
    links: ['actor', 'dataset'],
    computed: ['workspaceLink'],
  },

  ViewCreated: {
    links: ['actor', 'dataset'],
    computed: ['chorusViewLink', 'workspaceLink'],
  },

  WorkspaceChangeName: {
    links: ['actor', 'workspace'],
    computed: ['workspaceOldName'],
  },

  SUB_COMMENT: {
    links: ['author'],
  },

  MiniAppCreated: {
    links: ['actor', 'miniapp'],
    computed: ['workspaceLink'],
  },

  MiniAppPublished: {
    links: ['actor', 'miniapp'],
    computed: ['workspaceLink'],
  },

  MiniAppUnpublished: {
    links: ['actor', 'miniapp'],
    computed: ['workspaceLink'],
  },

  MiniAppDeleted: {
    links: ['actor', 'miniapp'],
    computed: ['workspaceLink'],
  },

  AirlockExportFailed: {
    links: ['dataset'],
    attrs: ['exportType'],
    computed: ['exportObjectOrName', 'workspaceLink'],
  },

  AirlockCopyScheduled: {
    links: ['actor'],
    attrs: ['reason', 'sourceWorkspace', 'targetWorkspace'],
  },

  AirlockCopySuccess: {
    links: ['actor'],
    attrs: ['reason', 'sourceWorkspace', 'targetWorkspace'],
  },

  AirlockCopyFailed: {
    links: ['actor'],
    attrs: ['reason', 'errorMessage', 'sourceWorkspace', 'targetWorkspace'],
  },

  WorkfileRenameFailure: {
    links: ['workfile', 'workspace'],
    attrs: ['errorMessage'],
  },

  WorkfileRenameSuccess: {
    links: ['actor', 'workfile'],
    computed: ['versionLink', 'versionWorkfileLink', 'fileorfolder', 'renamedFile'],
  },

  FileExtractSuccess: {
    links: ['actor'],
    attrs: ['destinationFolder', 'sourceFile'],
  },

  FileExtractFailed: {
    links: ['actor'],
    attrs: ['errorMessage', 'destinationFolder', 'sourceFile'],
  },

  UploadTokenCreated: {
    links: ['actor'],
    attrs: ['reason'],
    computed: ['workspaceLink'],
  },
};

export const chorusViewSourceModel = (self) => {
  const object = self.model.get('sourceObject');
  let model;
  if (object.fileName) {
    model = new Workfile(object);
  } else {
    model = new WorkspaceDataset(object);
    const workspaceModel = workspace(self.model);
    model.set({ workspaceModel });
  }
  return model;
};

export const chorusViewModel = (self) => {
  const object = self.model.get('sourceDataset');
  const model = new WorkspaceDataset(object);
  const workspaceModel = workspace(self.model);

  model.set({ workspaceModel });
  return model;
};

const presenterHelpers = {
  headerParams(self) {
    const { model } = self;
    const action = model.get('action');

    const params = {};
    const options = headerDefinitions[action];
    _.each(options.links, (name) => {
      const associatedModel = model[name]();
      params[`${name}Link`] = presenterHelpers.modelLink(associatedModel);
    });

    _.each(options.attrs, (name) => {
      params[name] = model.get(name);
    });

    _.each(options.computed, (name) => {
      params[name] = presenterHelpers[name](self);
    });

    return params;
  },

  defaultStyle(self, isNotification) {
    if (self.get('action') === 'MembersAdded' || self.get('action') === 'MembersDeleted') {
      if (isNotification === true) return 'notification.default';

      switch (self.get('action') === 'MembersAdded' ? self.get('numAdded') : self.get('numDeleted')) {
        case 1:
          return 'one.default';
        case 2:
          return 'two.default';
        default:
          return 'many.default';
      }
    } else if (self.get('action') === 'MembersInvited') {
      if (isNotification === true) return 'notification.default';

      switch (self.get('action') === 'MembersInvited' ? self.get('numAdded') : self.get('numDeleted')) {
        case 1:
          return 'one.default';
        case 2:
          return 'two.default';
        default:
          return 'many.default';
      }
    } else if (activityWorkspace(self).id && self.get('actionType') !== 'NoteOnWorkspace') {
      return 'default';
    } else {
      return 'without_workspace';
    }
  },

  displayStyle(self, style, isNotification) {
    if (self.get('action') === 'MembersAdded' || self.get('action') === 'MembersDeleted') {
      if (isNotification === true) return (`notification.${style}`);

      const n = self.get('action') === 'MembersAdded' ? self.get('numAdded') : self.get('numDeleted');
      switch (n) {
        case 1:
          return (`one.${style}`);
        case 2:
          return (`two.${style}`);
        default:
          return (`many.${style}`);
      }
    } else if (self.get('action').lastIndexOf('FileImport', 0) === 0 ||
            self.get('action').lastIndexOf('WorkspaceImport', 0) === 0) {
      return '';
    } else {
      return style;
    }
  },

  headerTranslationKey(self) {
    const isNotification = self.isNotification();
    let mainKey = `activity.header.${self.model.get('action')}`;
    const possibleStyles = _.compact(_.flatten([
      presenterHelpers.displayStyle(self.model, self.options.displayStyle, isNotification),
      presenterHelpers.defaultStyle(self.model, isNotification), 'default',
    ]));

    if (self.isDownload()) {
      mainKey += '.airlock.download';
    } else if (self.isCopy()) {
      mainKey += '.airlock.copy';
    } else if (isNotification) {
      mainKey += '.notification';
    } else if (self.model.get('actionType') === 'NoteOnDatasetImport') {
      mainKey += '.dataset_import';
    }

    const keys = [...new Set(possibleStyles)].map(c => `${mainKey}.${c}`);
    return keys.find(key => I18n.lookup(key));
  },

  datasetType(self) {
    const type = self.model.dataset().metaType();
    return t(`dataset.entitySubtypes.${type}`);
  },

  count(self) {
    // count Excluding Named which is displaye
    const n = self.model.get('action') === 'MembersAdded' ? self.model.get('numAdded') : self.model.get('numDeleted');
    return n - 1;
  },

  memberRole(self) {
    return self.model.get('roleName') || 'Standard';
  },

  countInvited(self) {
    // count Excluding Named which is displaye
    const n = self.model.get('action') === 'MembersInvited' ? self.model.get('numAdded') : self.model.get('numDeleted');
    return n - 1;
  },

  noteWorkspaceLink(self) {
    const model = new Workspace();
    model.set(self.model.get('workspace'));
    return linkTo(model.showUrl(), model.name());
  },

  noteDatasetLink(self) {
    const model = dynamicDataset(self.model.get('dataset'));
    return linkTo(model.showUrl(), model.name());
  },

  noteWorkspaceDatasetLink(self) {
    const model = new WorkspaceDataset();
    model.set(self.model.get('dataset'));
    model.setWorkspace(self.model.get('workspace'));
    return linkTo(model.showUrl(), model.name());
  },

  noteWorkFileLink(self) {
    const model = new Workfile();
    model.set(self.model.get('workfile'));
    return linkTo(model.showUrl(), model.name());
  },

  noteAirlockLink(self) {
    const model = new Airlock();
    model.set(self.model.get('workspace'));
    return linkTo(model.showUrl(), model.name());
  },

  noteObjectLink(self) {
    switch (self.model.get('actionType')) {
      case 'NoteOnWorkspace': return this.noteWorkspaceLink(self);
      case 'NoteOnDatasetImport':
      case 'NoteOnDataset': return this.noteDatasetLink(self);
      case 'NoteOnWorkspaceDataset': return this.noteWorkspaceDatasetLink(self);
      case 'NoteOnWorkfile': return this.noteWorkFileLink(self);
      case 'Airlock': return this.noteAirlockLink(self);
      default: return null;
    }
  },

  noteObjectType(self) {
    const actionType = self.model.get('actionType');
    switch (actionType) {
      case 'NoteOnDataSource':
      case 'NoteOnWorkspace':
        return 'workspace';
      case 'NoteOnWorkfile':
        return 'file';
      case 'NoteOnDataset':
      case 'NoteOnWorkspaceDataset':
      case 'NoteOnDatasetImport':
        return presenterHelpers.datasetType(self);
      case 'Airlock':
        return 'workspace';
      default:
        return '';
    }
  },

  noteObjectLocation(self) {
    const actionType = self.model.get('actionType');
    switch (actionType) {
      case 'NoteOnWorkfile':
        return `in ${this.folder(self)}`;
      default:
        return '';
    }
  },

  importSourceLink(self) {
    return self.model.get('fileName');
  },

  importSourceDatasetLink(self) {
    const dataset = self.model.get('sourceDataset');
    dataset.workspace = self.model.get('workspace');

    const datasetModel = new WorkspaceDataset(dataset);
    return datasetModel.showLink();
  },

  chorusViewSourceLink(self) {
    return chorusViewSourceModel(self).showLink();
  },

  chorusViewLink(self) {
    return chorusViewModel(self).showLink();
  },

  chorusViewSourceType(self) {
    const model = chorusViewSourceModel(self);
    return model.get('fileName') ? 'workfile' : t(`dataset.entitySubtypes.${chorusViewSourceModel(self).metaType()}`);
  },

  updateCredentialsLink(self) {
    if (self.model.get('dataSource').isDeleted) {
      return t('dataset.credentials.missing.linkText');
    }
    return linkTo('#', t('dataset.credentials.missing.linkText'), { class: 'update_credentials' });
  },

  versionLink(self) {
    return this.versionLinkX(self, 'workfile.version_title');
  },

  folder(self) {
    if (self.model.get('workfile') === null || !self.model.get('workfile').folder) {
      return t('workspace.workfiles');
    }
    return self.model.get('workfile').folder;
  },

  fileorfolder(self) {
    if (self.model.get('workfile') === null) {
      return t('workfile.type.file');
    }
    return self.model.get('workfile').fileType === 'folder' ? t('workfile.type.folder') : t('workfile.type.file');
  },

  versionWorkfileLink(self) {
    return this.versionLinkX(self, 'workfile.version_and_workfile_title');
  },

  workspaceLink(self) {
    const model = activityWorkspace(self.model);
    return linkTo(model.showUrl(), model.name());
  },

  containerisedAppsLink(self) {
    const appName = self.model.get('name');
    const targetWorkspace = activityWorkspace(self.model);
    return linkTo(`${targetWorkspace.showUrl()}/containerised_apps`, appName);
  },

  versionLinkX(self, title) {
    const versionNum = self.model.get('versionNum');
    const versionId = self.model.get('versionId');
    const versionIsDeleted = self.model.get('versionIsDeleted');
    const workfile = self.model.get('workfile');
    const isDeleted = !workfile || workfile.isDeleted || versionIsDeleted;

    // Only the one version, use the default workfile display
    if (versionId === undefined) {
      return this.modelLink(self.model.workfile());
    }

    const workfileVersion = new Workfile({
      versionInfo: { id: versionId },
      id: workfile.id,
      workspace: workfile.workspace,
      isDeleted,
    });

    return workfileVersion.showLink(t(title, { versionNum, workfileName: workfile.fileName }));
  },

  destinationSchemaLink(self) {
    let datasetSchema = schema(self.model.dataset());
    if (!datasetSchema) {
      datasetSchema = new Schema(self.model.get('schema'));
    }
    return datasetSchema.showLink();
  },

  destinationDatasetInSchemaLink(self) {
    return (new Dataset(self.model.dataset().attributes)).showLink();
  },

  sourceDatasetInSchemaLink(self) {
    return (new Dataset(self.model.importSource().attributes)).showLink();
  },

  datasetLink(self) {
    return self.model.get('destinationTable');
  },

  workspaceTargetLink(self) {
    const workspaceModel = new Workspace(self.model.get('target'));
    return workspaceModel.get('name');
  },

  workspaceSourceLink(self) {
    const workspaceModel = new Workspace(self.model.get('source'));
    return workspaceModel.showLink();
  },

  exportObjectOrName(self) {
    return self.model.get('fileName');
  },

  exportDestination(self) {
    return `${self.model.get('folder', 'fileorfolder')}`;
  },

  renamedFile(self) {
    return `${self.model.get('folder', 'fileorfolder')}/${self.model.get('fileName')}`;
  },

  // this is the one that returns a WorkspaceDataset
  destObjectOrName(self) {
    const dataset = self.model.dataset();
    if (dataset.get('id')) {
      return presenterHelpers.modelLink(dataset);
    }
    return self.model.get('destinationTable');
  },

  // this is the one that returns a Dataset
  destObjectOrNameInSchema(self) {
    const dataset = self.model.dataset();
    if (dataset.get('id')) {
      return (new Dataset(dataset.attributes)).showLink();
    }
    return self.model.get('destinationTable');
  },

  modelLink(model) {
    return model.get('isDeleted') ? model.name() : linkTo(model.showUrl(), model.name());
  },

  workspaceOldName(self) {
    return self.model.get('workspaceOldName');
  },
};

export const toAirlock = activity => new Airlock({
  id: activity.get('errorModelId') || activity.id,
  workspaceId: activity.attributes.workspace.id,
  reason: activity.get('reason'),
});

export default Presenter.extend({

  headerHtml() {
    const string = t(presenterHelpers.headerTranslationKey(this), presenterHelpers.headerParams(this));
    return new Handlebars.SafeString(string);
  },

  displayName() {
    if (this.model.get('author')) {
      return [this.model.get('author').firstName, this.model.get('author').lastName].join(' ');
    }
  },

  displayInitials() {
    return userInitials(this.displayName());
  },

  displayAvatarBackgroundColor() {
    return avatarBackgroundColour(this.displayName());
  },

  timestamp() {
    return relativeTimestamp(this.model.get('timestamp'));
  },

  iconSrc() {
    return this.model.author().fetchImageUrl({ size: 'icon' });
  },

  iconHref() {
    if (this.isFailure()) {
      return this.model.author().showUrl();
    } else if (this.model.isSuccessfulImport()) {
      return this.model.dataset().showUrl();
    }
    return this.model.author().showUrl();
  },

  canBePromotedToInsight() {
    const isValid = true;
    return isValid && this.model.isNote() && !this.model.isInsight() && !this.model.isAirlock();
  },

  isInsight() {
    return this.model.isInsight();
  },

  isUserGenerated() {
    return this.model.isUserGenerated();
  },

  hasCommitMessage() {
    return this.model.hasCommitMessage();
  },

  isPublished() {
    return this.model.isPublished();
  },

  attachments() {
    return this.model.attachments().map(attachment => new Attachment(attachment));
  },

  // Reduce the file list to folders when multiple files in a folder
  reduceFilesToFolders(files) {
    // Only do this for airlock requests
    if (!this.model.isAirlock()) {
      return files;
    }
    const fileList = [];
    let currentFolder = '';
    files.forEach((workfile) => {
      const folder = workfile.model.get('folder');
      // Don't create a folder from the top level or datasets
      if (folder === 'files' || folder === 'blobs' || folder === undefined) {
        fileList.push(workfile);
      } else {
        // Only reduce the list of files if there are more than 10 files in a folder
        const count = files.filter(obj => obj.model.get('folder') === folder).length;
        if (count > 10 && currentFolder !== folder) {
          currentFolder = folder;
          const folderName = folder.split('/');
          workfile.model.set({ fileType: 'folder', fileName: folderName.pop(), folder: folderName.join('/') });
          fileList.push(workfile);
        } else {
          // Flag files that should be hidden as a folder will be displayed instead
          if (currentFolder === folder) {
            workfile.model.set({ hidden: true });
          }
          fileList.push(workfile);
        }
      }
    });
    return fileList.filter(obj => !obj.model.get('hidden'));
  },

  firstFewAttachments() {
    const files = this.reduceFilesToFolders(this.attachments());
    return files.slice(0, 3);
  },

  restOfAttachments() {
    const files = this.reduceFilesToFolders(this.attachments());
    return files.slice(3);
  },

  expandAttachments() {
    return this.model.get('expandAttachments');
  },

  canEdit() {
    return this.model.isOwner() &&
      this.model.get('actionType') !== 'NoteOnDatasetImport';
  },

  isNote() {
    return this.model.get('action') === 'NOTE';
  },

  isCopy() {
    return this.isAirlockDestination('workspace');
  },

  isDownload() {
    return this.isAirlockDestination('download');
  },

  isAirlockDestination(destination) {
    return this.isAirlock() && this.model.get('destination') === destination;
  },

  isAirlock() {
    return this.model.isAirlock();
  },

  modelStatus() {
    return this.model.get('status');
  },

  airlockStatus() {
    const key = `airlock.activity.badge.${this.model.get('status')}`;
    return I18n.lookup(key);
  },

  isRequestedAirlock() {
    return this.model.get('status') === 'requested';
  },

  isRejectedAirlock() {
    return this.model.get('status') === 'rejected';
  },

  isApprovedPendingAirlock() {
    return this.model.get('status') === 'approved_pending';
  },

  isApprovedAvailableAirlock() {
    return this.model.get('status') === 'approved_available';
  },

  isFailedAirlock() {
    return this.model.get('status') === 'failed';
  },

  airlockFilepath() {
    const modelId = this.model.get('errorModelId') || this.model.id;
    return `api/workspaces/workspaces/${workspace(this.model).id}/airlock/${modelId}/download`;
  },

  canAuthoriseAirlock() {
    return workspace(this.model).hasPermission('WorkspaceAirlock.Approve');
  },

  canCopyfromAirlock() {
    return this.canReleasefromAirlock('workspace');
  },

  canDownloadfromAirlock() {
    return this.canReleasefromAirlock('download');
  },

  canReleasefromAirlock(destination) {
    const currentUser = chorus.session.user();
    return this.model.get('destination') === destination &&
                workspace(this.model).canAirlock &&
                this.model.get('actor').uuid === currentUser.get('uuid');
  },

  canDelete() {
    return (this.model.get('actionType') !== 'NoteOnDatasetImport' && !this.isAirlock() && this.model.isOwner());
  },

  canPublish() {
    return this.canDelete();
  },

  iconClass() {
    if (this.isFailure()) {
      return 'fa fa-exclamation-circle fa-error';
    } else if (this.model.isSuccessfulImport()) {
      return 'fa fa-check fa-success';
    }
    return 'profile';
  },

  isNotification() {
    return this.options.isNotification || false;
  },

  isReadOnly() {
    return this.options.isReadOnly || false;
  },

  isFailure() {
    return this.model.isFailure();
  },

  hasError() {
    return this.model.has('errorObjects') || this.model.has('errorMessage');
  },

  id() {
    return this.model.id;
  },

  promotionDetails() {
    return unsafeT(this, 'insight.promoted_by', {
      promoterLink: this.model.promoterLink(),
      relativeTimestamp: this.model.promotionTimestamp(),
    });
  },

  airlockDetails() {
    if (this.model.promoterLink() && this.model.promotionTimestamp()) {
      const key = `airlock.${this.model.get('status')}_by`;
      return unsafeT(this, key, {
        promoterLink: this.model.promoterLink(),
        relativeTimestamp: this.model.promotionTimestamp(),
      });
    }
    // the following is called when an admin approves a download request as there's no round
    // trip to the server to get the promoterLink and relativeTimestamp data
    return t(`airlock.activity.${this.model.get('status')}`);
  },

});

