import Desktop from '../models/desktop';
import Collection from './workspace_token_collection';

export default Collection.extend({
  constructorName: 'DesktopSet',
  model: Desktop,
  urlTemplate: 'api/guac/vm',

  urlParams(options) {
    const opts = options;
    delete opts.per_page;
    delete opts.page;
    return {
      template: this.attributes.template,
    };
  },

  setTemplate(newValue) {
    this.attributes.template = newValue;
    this.trigger('changedTemplate', newValue);
  },
});
