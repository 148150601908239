import { useState } from 'react';
import { t } from 'i18n-js';
import { Modal, Icon, Header } from 'semantic-ui-react';
import ModalActionButtons from '../../../../../components/buttons/ModalActionButtons';
import FileNavigation from '../../../../../components/navigation/FileNavigation';
import HeaderError from '../../../../../components/errors/HeaderError';
import ModalWrapper from '../../../../../components/modals/ModalWrapper';
import { useFilesContext } from '../../Context';
import { useMultipleRename } from '../../../service_batch';
import NewFolderSubmodal, { NewFolderSubmodalButton }
  from '../../../../../components/modals/NewFolderSubmodal';
import toastMessage from '../../../../../utilities/toast';

const MultipleMoveModal = ({ setModalClose, setIsBusy }) => {
  const {
    selectedFiles, setSelectedFiles,
  } = useFilesContext();
  const [destinationFolder, setDestinationFolder] = useState('files');

  const isValidEntry = !!destinationFolder;

  const selectedFilesWithBody = selectedFiles.map((file) => (
    {
      ...file,
      body: {
        workfile: {
          id: file.id,
          file_name: file.name,
          folder: destinationFolder,
        },
      },
    }
  ));

  const {
    renameFiles,
    isLoading,
    isError,
    error,
    resetResponse,
    failedToRename,
    successfulRename,
  } = useMultipleRename(selectedFilesWithBody);

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleSubmitting = () => {
    setIsBusy(true);
    renameFiles(null, {
      onSuccess: () => {
        if (failedToRename.length > 0) {
          const failedFiles = failedToRename.map((file) => file.name).join(', ');
          const message = failedToRename.length === 1
            ? t('file_list.actions.move_selected_single_error', { file: failedFiles })
            : t('file_list.actions.move_selected_error', { count: failedToRename.length, files: failedFiles });
          toastMessage('error', message, 'moveError');
        } else {
          const successFiles = successfulRename.map((file) => file.name).join(', ');
          const message = successfulRename.length === 1
            ? t('file_list.actions.move_selected_single_success', { file: successFiles })
            : t('file_list.actions.move_selected_success', { count: successfulRename.length, files: successFiles });
          toastMessage('success', message, 'moveSuccess');
        }
        setSelectedFiles(failedToRename.length > 0 ? failedToRename : []);
        setIsBusy(false);
        handleModalClose();
      },
      onError: () => {
        setIsBusy(false);
      },
    });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="arrow circle right" title={t('file_list.actions.move')} />
        <Header.Content content={t('file_list.actions.move')} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <FileNavigation
          currentFolder={destinationFolder}
          handleSetFolder={(activeFolder) => setDestinationFolder(activeFolder)}
        />
      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmitting}
        isValidEntry={isValidEntry}
        error={isError}
        submitText={t('file_list.actions.move')}
      >
        <ModalWrapper
          trigger={NewFolderSubmodalButton}
        >
          <NewFolderSubmodal
            destinationFolder={destinationFolder}
            handleSetFolder={(activeFolder) => setDestinationFolder(activeFolder)}
          />
        </ModalWrapper>
      </ModalActionButtons>
    </>
  );
};

export default MultipleMoveModal;
