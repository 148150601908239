import $ from '../../jquery';
import ListHeaderView from '../core/list_header_view';
import TagBox from '../tags/tag_box_view';
import WorkspaceDataset from '../../models/workspace_dataset';

export default ListHeaderView.extend({
  templateName: 'dataset_show_content_header',
  additionalClass: 'taggable_header progess_header',

  subviews: {
    '.tag_box': 'tagBox',
  },

  setup() {
    // this.tagBox = new TagBox({
    //   model: this.model,
    //   workspaceIdForTagLink: this.options.workspaceId,
    // });
    // XAP-11157 - Temp disabling of tags
    this.tagBox = false;
    this.requiredResources.add(this.model);
  },

  additionalContext() {
    return {
      dataset: new WorkspaceDataset(this.model),
      showLocation: this.options && this.options.showLocation,
      title: this.model.name(),
      imageUrl: this.model.iconUrl(),
      importing: this.model.isImporting(),
      importProgress: this.model.get('importProgress'),
    };
  },

  postRender(...args) {
    this._super('postRender', args);
    if (this.model.importFrequency && this.model.importFrequency()) {
      $(this.el).addClass('has_import');
    }
    this.menu(this.$('.found_in .open_other_menu'), {
      content: this.$('.found_in .other_menu'),
      classes: 'found_in_other_workspaces_menu',
    });
    this.menu(this.$('.published_to .open_other_menu'), {
      content: this.$('.published_to .other_menu'),
      classes: 'found_in_other_workspaces_menu',
    });
  },
});
