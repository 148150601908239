import $ from '../../jquery';
import t from '../../intl';
import DatasetsAttach from '../../dialogs/dataset/datasets_attach_dialog';
import WorkfilesAttach from '../../dialogs/workfiles/workfiles_attach_dialog';
import View from '../loading_view';

export default View.extend({
  templateName: 'airlock/airlock_tab',
  events: {
    'submit form': 'submitRequest',
    'click button[name=approve_request]': 'autoApprove',
    'click button[name=next]': 'nextSection',
    'click button[name=close_tab]': 'closeTab',
    'change input[name=destination]': 'saveFormValues',
    'change select[name=workspace]': 'saveFormValues',
    'click .add_workfile': 'launchWorkfileDialog',
    'click .add_dataset': 'launchDatasetDialog',
    'click .remove': 'removeItem',
  },

  setup(options) {
    this.tabModel = options.tabModel;
    this.workspace = this.options.workspaceModel;
    this.listenTo(this.model, 'change:files', this.render);
    this.listenTo(this.model, 'change:datasets', this.render);
  },

  context(model, ...args) {
    if (model) {
      // used when an error occurs when the request is saved
      if (model.statusCode === 403) {
        return { serverErrors: { message: t('forbidden.title') } };
      }
      return this._super('context', args);
    }
    return this.model.viewContext();
  },

  saveFormValues(options) {
    const active = this.model.get('activeSection');
    if (active === 1) {
      const update = {
        destination: this.$('input[name=destination]:checked').val(),
        reason: this.$('textarea[name=reason]').val(),
      };
      const workspace = this.$('select[name=workspace]');
      if (workspace) {
        update.workspace = workspace.val();
      }
      this.model.set(update, options);
    }
  },

  closeTab(e) {
    e.preventDefault();

    this.tabModel.remove();
  },

  nextSection(e) {
    e.preventDefault();
    this.saveFormValues({ silent: true });
    this.model.nextSection();
  },

  launchWorkfileDialog(e) {
    e.preventDefault();

    const workfileDialog = new WorkfilesAttach({
      workspace: this.workspace,
      crumbs: true,
      folder: this.folder,
    });
    workfileDialog.on('files:selected', this.model.addFiles, this.model);
    workfileDialog.launchModal();
  },

  launchDatasetDialog(e) {
    e.preventDefault();

    const datasetDialog = new DatasetsAttach({ workspaceId: this.workspace.id, type: 'SANDBOX_TABLE' });
    datasetDialog.on('datasets:selected', this.model.addDatasets, this.model);
    datasetDialog.launchModal();
  },

  removeItem(e) {
    e.preventDefault();

    const item = $(e.currentTarget);
    const type = item.data('type');
    const id = item.data('attachment');

    if (type === 'file') {
      this.model.removeFile(id);
    } else {
      this.model.removeDataset(id);
    }
  },

  makeRequest(e, autoApprove) {
    e.preventDefault();

    if (this.$(':invalid').size() > 0) {
      this.$('form').addClass('highlight_required');
      return;
    }

    this.saveFormValues({ silent: true });

    const request = this.model.createRequest(autoApprove);
    this.listenTo(request, 'change', this.render);
    this.listenTo(request, 'saved', function saveInvalidate() {
      this.workspace.trigger('invalidated');
    });

    this.listenTo(request, 'saveFailed validationFailed', function displayErrors() {
      this.model.set('activeSection', 1);
      this.showErrors(request);
    });
    request.save(request.attributes);

    this.model.set('activeSection', 2);
  },

  autoApprove(e) {
    const form = this.$('form')[0];
    if (form.checkValidity()) {
      this.makeRequest(e, true);
    }
  },

  submitRequest(e) {
    this.makeRequest(e, false);
  },

});
