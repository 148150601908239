/* eslint-disable react/jsx-props-no-spreading */
import { t } from 'i18n-js';
import {
  Dropdown, Message, Form,
} from 'semantic-ui-react';
import StandardModal, { StandardModalContent } from '../../standard_modal';
import { initialState, useOnChange } from '../hooks';
import { createUseSnapshotCreation } from '../adapters';

const CreatePanel = ({ CommentField }) => (
  <Form>
    <Message info data-testid="snapshot-message">
      {t('modals.snapshot.content')}
      <a href={t('modals.snapshot.service_desk_url')} rel="noopener noreferrer" target="_blank">
        {t('modals.snapshot.service_desk')}
      </a>
    </Message>
    {CommentField}
  </Form>
);

const CommentInput = (props) => (
  <Form.Input
    label={t('modals.snapshot.label')}
    placeholder={t('modals.snapshot.placeholder')}
    input={{
      maxLength: 255,
    }}
    type="text"
    name="reason"
    {...props}
  />
);

const CommentForm = ({ mutationParameters, setMutationEnabled, isLoading }) => {
  const onChange = useOnChange(mutationParameters, setMutationEnabled);
  return (
    <CreatePanel
      CommentField={<CommentInput onChange={onChange} disabled={isLoading} />}
    />
  );
};

const CreateSnapshotContent = (props) => (
  <StandardModalContent
    headerProps={{ icon: 'camera', title: t('workspaces.launcher.snapshot') }}
    useMutationHook={createUseSnapshotCreation()}
    actionProps={{ confirmationText: t('modals.snapshot.confirm_text') }}
    defaultMutationParameters={initialState()}
    initiallyEnabled={false}
    {...props}
  >
    <CommentForm />
  </StandardModalContent>
);

const SnapshotModal = (props) => (
  <StandardModal
    trigger={<Dropdown.Item {...props} />}
  >
    <CreateSnapshotContent />
  </StandardModal>
);

export default SnapshotModal;
