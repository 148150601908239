import Dialog from "../dialogs";
import t from "../../intl";

export default Dialog.extend({
  constructorName: 'StaticContentView',
  templateName: 'static_content_view_dialog',
  additionalClass: 'dialog about_dialog',
  events: {
    'click .app-btnClose': 'dialogClose',
  },

  dialogClose() {
    this.closeModal();
  },

  postRender() {
    let content = `${t('actions.convert_to_database_message')} <a href="/#/workspaces/${this.options.workspaceId}/dataset_upload/file/${this.options.workfileId}">${t('actions.convert_to_database_link')}</a>.`;
    this.$('.dialog_header h1').html(t('actions.convert_to_database_header'));
    this.$('.app-staticContentBody').html(content);
  },

});