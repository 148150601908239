import Workspace from '../models/workspace';
import chorus from '../chorus';
import _ from '../underscore';

/* eslint no-underscore-dangle: ["error", { "allow": ["_workspace"] }] */

export const workspace = (entity) => {
  const associate = entity;
  if (!associate._workspace) {
    if (associate.collection) {
      if (typeof associate.collection.workspace === 'function') {
        associate._workspace = associate.collection.workspace();
      } else if (associate.collection.workspace) {
        associate._workspace = associate.collection.workspace;
      }
    }
    if (!associate._workspace) {
      associate._workspace = new Workspace(associate.get('workspace'));
    }
  }

  return associate._workspace;
};

export const activityWorkspace = (activity) => {
  const associate = activity;
  if (!associate._workspace) {
    let ws = associate.get('workspace');
    if (!ws && associate.get('workfile')) {
      ws = associate.get('workfile').workspace;
    }

    if (!ws && associate.get('dataset')) {
      ws = associate.get('dataset').workspace;
    }

    const model = new Workspace(ws);

    if (chorus.page.workspace && chorus.page.workspace.id === model.id) {
      associate._workspace = chorus.page.workspace;
    } else {
      associate._workspace = model;
    }
  }
  return associate._workspace;
};

export const attachmentWorkspace = (attachment) => {
  const associate = attachment;
  if (!associate._workspace) {
    if (associate.workfile()) {
      associate._workspace = workspace(associate.workfile());
    } else {
      associate._workspace = associate.get('workspace') &&
                !_.isEmpty(associate.get('workspace')) &&
                new Workspace(associate.get('workspace'));
    }
  }
  return associate._workspace;
};


export const searchWorkspace = (search) => {
  const associate = search;
  const workspaceId = associate.get('workspaceId');
  if (!associate._workspace && workspaceId) {
    associate._workspace = new Workspace({ id: workspaceId });
  }
  return associate._workspace;
};

export const sandbox = workfile => workspace(workfile).sandbox();
