import Task from '../models/tasks/task';
import Collection from './collections';

const Tasks = Collection.extend({
  constructorName: 'TaskSet',
  model: Task,
});

export const sqlResults = new Tasks();

export default Tasks;
