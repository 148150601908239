import { useState } from 'react';
import { t } from 'i18n-js';
import {
  Modal, Icon, Form, Header, Message, Checkbox,
} from 'semantic-ui-react';
import ModalActionButtons from '../../../components/buttons/ModalActionButtons';
import HeaderError from '../../../components/errors/HeaderError';
import FormValidationError from '../../../components/errors/FormValidationError';
import {
  findErrors, hasError, invalidCharactersIn, invalidCharacters, invalidFileNames,
} from '../../../utilities/validation';
import { validationFields, validationLengths } from '../../../constants/validation';
import { useExtract } from '../../panels/service';
import toastMessage from '../../../utilities/toast';
import { workspaceId } from '../../../layout/helper';

const ExtractModal = ({
  setModalClose,
  setIsBusy,
  folder,
  name,
  id,
}) => {
  const [extractFolder, setExtractFolder] = useState(name.split('.')[0]);
  const [removeFile, setRemoveFile] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const handleFolderChange = (e, { value }) => {
    setExtractFolder(value);
    const newErrors = validationErrors.filter((err) => err.field !== validationFields.NAME);
    setValidationErrors(newErrors);
  };

  const handleCheckboxChange = (event, data) => {
    setRemoveFile(data.checked);
  };

  const checkForErrors = (newFolder) => {
    const errors = [];
    if (newFolder.length > validationLengths.NAME) {
      errors.push({
        field: 'name',
        message: (t('field_error.TOO_LONG', {
          field: t('modals.extract.folder_name'),
          count: validationLengths.NAME,
        })),
      });
    }

    if (newFolder && invalidCharactersIn(newFolder)) {
      errors.push({
        field: 'name',
        message: (
          t('field_error.INVALID_CHARACTERS', {
            field: t('modals.extract.folder_name'),
            characters: invalidCharacters,
          })),
      });
    }

    if (newFolder && invalidFileNames(newFolder)) {
      errors.push({
        field: 'name',
        message: (t('field_error.INVALID_NAME')),
      });
    }
    return errors;
  };

  const {
    createExtract,
    isLoading,
    isError,
    error,
    resetResponse,
  } = useExtract(id);

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleSubmitting = () => {
    setIsBusy(true);
    const errors = checkForErrors(extractFolder);
    setValidationErrors(errors);

    if (errors.length > 0) {
      setIsBusy(false);
      return;
    }

    const extractData = {
      workfile: {
        workspace: {
          id: workspaceId(),
        },
        destination_folder: `${folder}/${extractFolder}`,
        extract: true,
        id,
        remove_zip: removeFile,
        folder,
        file_name: name,
      },
    };

    createExtract(extractData, {
      onSuccess: () => {
        setIsBusy(false);
        handleModalClose();
        toastMessage('success', t('workfiles.extract_files.succeeded'), 'extractToast');
      },
      onError: () => {
        setIsBusy(false);
      },
    });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="file text" data-testid="file" />
        <Header.Content content={t('modals.extract.title')} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <Message>{t('modals.extract.text', { folder })}</Message>
        <Form>
          <Form.Field>
            <label htmlFor="name">
              {t('modals.extract.folder_name')}
              {hasError(validationErrors, validationFields.NAME) && (
              <FormValidationError errorMsg={(findErrors(validationErrors, validationFields.NAME)[0]).message} />
              )}
            </label>
            <Form.Input
              id="name"
              autoFocus
              placeholder={t('modals.extract.folder_name_placeholder')}
              maxLength={validationLengths.NAME}
              value={extractFolder}
              onChange={handleFolderChange}
              error={hasError(validationErrors, validationFields.NAME)}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              value={removeFile.toString()}
              checked={removeFile}
              onChange={handleCheckboxChange}
              label={t('modals.extract.checkbox')}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmitting}
        isValidEntry
        error={validationErrors.length > 0}
        submitText={t('actions.unzip')}
      />
    </>
  );
};

export default ExtractModal;
