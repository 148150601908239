import chorus from '../chorus';
import { instance } from './config';
import Model from './models';
import { userInitials, avatarBackgroundColour } from '../mixins/avatars';

export default Model.extend({
  constructorName: 'User',

  urlTemplate: 'api/workspaces/users/{{uuid}}',
  showUrlTemplate: 'users/{{uuid}}',
  nameFunction: 'displayName',
  entityType: 'user',

  isSessionUser() {
    return (this.get('uuid') === chorus.session.user().get('uuid'));
  },

  declareValidations(newAttrs) {
    this.require('firstName', newAttrs);
    this.require('lastName', newAttrs);

    this.require('email', newAttrs);
    this.requireValidEmailAddress('email', newAttrs);

    if (!this.ldap) {
      this.requireConfirmationForChange('password', newAttrs);
    }
  },

  requireConfirmationForChange(name, newAttrs) {
    if (this.isNew() || (newAttrs && Object.prototype.hasOwnProperty.call(newAttrs, name))) {
      this.require(name, newAttrs);
      this.requireConfirmation(name, newAttrs);
    }
  },

  hasImage() {
    return true;
  },

  fetchImageUrl(options) {
    const size = (options && options.size) || 'original';
    let url = this.get('image') && this.get('image')[size];

    if (typeof (url) === 'undefined') {
      url = '/images/default-user-icon.png';
    }
    return url && new window.URI(url)
      .toString();
  },

  createImageUrl() {
    const url = new window.URI(this.url());
    url.path(`${url.path()}/image`);
    return url.toString();
  },

  currentUserCanEdit() {
    const currentUser = chorus.session.user();
    return this.get('uuid') === currentUser.get('uuid');
  },

  isAdmin() {
    return !!this.get('admin');
  },

  isTenantAdmin() {
    return !!this.get('organisationAdmin');
  },

  picklistImageUrl() {
    return this.fetchImageUrl();
  },

  displayName() {
    if (!this.get('firstName') && !this.get('lastName')) {
      if (this.get('fullName')) {
        return this.get('fullName');
      }
      return [this.get('first_name'), this.get('last_name')].join(' ');
    }
    return [this.get('firstName'), this.get('lastName')].join(' ');
  },

  displayInitials() {
    return userInitials(this.displayName());
  },

  displayAvatarBackgroundColor() {
    return avatarBackgroundColour(this.displayName());
  },

  department() {
    return this.get('department');
  },

  enabled() {
    return this.get('enabled');
  },

  hasEnabled() {
    return this.enabled() !== undefined;
  },

  displayShortName(length) {
    const len = length || 20;

    const name = this.displayName();
    return (name.length < len) ? name : `${this.get('firstName')} ${this.get('lastName')[0]}.`;
  },

  maxImageSize() {
    return instance.get('fileSizesMbUserIcon');
  },

  attrToLabel: {
    email: 'users.email',
    firstName: 'users.first_name',
    lastName: 'users.last_name',
    password: 'users.password',
    title: 'users.title',
    department: 'users.department',
    admin: 'users.administrator',
  },
});
