import { t } from 'i18n-js';
import { sanitisedTableTypes, supportedTableTypes } from './constants';

export const sanitiseTableType = (tableType) => (sanitisedTableTypes[tableType] || tableType);

export const isTable = (tableType) => (tableType === supportedTableTypes.TABLE);

export const isImporting = (importStatus, importProgress) => {
  if (importStatus === null) {
    return false;
  }
  return importProgress < 100 && importStatus < 2;
};

export const isCustomView = (tableType) => (tableType === supportedTableTypes.CHORUS_VIEW);

const isExternal = (tableType) => ((/EXT_TABLE$/).test(tableType));

export const canAnalyze = (importStatus, importProgress, tableType) => (
  !isImporting(importStatus, importProgress) && !isCustomView(tableType) && !isExternal(tableType)
);

export const createValidTableName = (tableName) => (
  tableName.replace(/ /g, '_').replace(/\W/g, '').replace(/^\d*/, '').toLowerCase()
);

export const isDependencyError = (error) => (error?.includes(t('psqlexception.depend')));
