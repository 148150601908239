import { Breadcrumb } from 'semantic-ui-react';
import { workspaceFoldersUrl } from '../../../../constants/ui_urls';
import { useInboxContext } from '../Context';
import inboxDisplayFileName from '../helper';
import { capitalise } from '../../../../utilities/helper';

const InboxBreadcrumbs = () => {
  const { baseFolderPath } = useInboxContext();
  const folders = baseFolderPath.split('/');
  let url = workspaceFoldersUrl();

  const sections = folders.map((folder, index, { length }) => {
    const getUrl = () => {
      url = `${url}/${folder}`;
      if (index === length - 1) { return null; }
      return url;
    };

    const folderName = inboxDisplayFileName(folder);

    return {
      key: `${folder}_${index}`,
      content: index === 0 ? capitalise(folderName) : folderName,
      title: folderName,
      active: index === length - 1,
      href: getUrl(),
    };
  });

  return <Breadcrumb className="padding-none" size="large" icon="right angle" as="nav" sections={sections} />;
};

export default InboxBreadcrumbs;
