import React from 'react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { Grid, Header } from 'semantic-ui-react';
import { t } from 'i18n-js';
import ExpectedOutcome from './ExpectedOutcome';
import DataTableNameForm from './DataTableNameForm';
import DelimiterForm from './DelimiterForm';
import HeaderRowForm from './HeaderRowForm';
import TextQualifierForm from './TextQualifierForm';
import NullQualifierForm from './NullQualifierForm';
import EncodingForm from './EncodingForm';

function DatasetUploadForm({ sourceFile, minimumDataEntryMet }) {
  return (
    <Grid divided="vertically" columns="equal">
      <Grid.Row columns={3}>
        <Grid.Column width={5}>
          <Grid divided="vertically">
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header as="h2">
                  {t('dataset.import.source_file')}
                  :
                </Header>
              </Grid.Column>
              <Grid.Column>
                {sourceFile}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column width={5}>
          <Grid divided="vertically">
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header as="h2">
                  {t('dataset.import.data_table_name')}
                  :
                </Header>
              </Grid.Column>
              <Grid.Column>
                <DataTableNameForm />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header as="h2" id="delimeter">
                  {t('dataset.import.delimiter.title')}
                  :
                </Header>
              </Grid.Column>
              <Grid.Column className="top-margin">
                <DelimiterForm />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column width={1} />
        <Grid.Column className="top-margin" width={6}>
          <HeaderRowForm />
          <TextQualifierForm />
          <NullQualifierForm />
          <EncodingForm />
        </Grid.Column>
        <Grid.Column width={4}>
          <ExpectedOutcome minimumDataEntryMet={minimumDataEntryMet} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

const mapModelsToProps = ({ workfile }) => ({
  sourceFile: workfile.fullName(),
});

const options = {
  debounce: false,
  events: {
    workfile: ['change:fileName'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(DatasetUploadForm);
