import React from 'react';
import { Form, Select } from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';

const encodingForm = ({ encoding, handleEncodingChange }) => (
  <Form.Field inline>
    <label htmlFor="encoding">{t('dataset.import.encoding')}</label>
    <Select
      id="encoding"
      defaultValue={encoding}
      options={[
        { key: 'UTF-8', value: 'UTF-8', text: t('rsh.upload_data.parse_data.encoding.utf8') },
        { key: 'ISO-8859-1', value: 'ISO-8859-1', text: t('rsh.upload_data.parse_data.encoding.iso88591') },
        { key: 'Windows-1252', value: 'Windows-1252', text: t('rsh.upload_data.parse_data.encoding.windows1252') },
      ]}
      onChange={handleEncodingChange}
      className="small-size"
    />
  </Form.Field>
);

const mapModelsToProps = ({ datasetUpload }) => ({
  encoding: datasetUpload.get('encoding'),
  handleEncodingChange: (_e, { value }) => {
    datasetUpload.updateEncoding(value);
  },
});

const options = {
  debounce: true,
  events: {
    datasetUpload: ['change:encoding'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(encodingForm);
