import { useContext, createContext } from 'react';

const AuditContext = createContext();

export const useAuditContext = () => useContext(AuditContext);

export const AuditProvider = ({ children, value }) => (
  <AuditContext.Provider value={value}>
    {children}
  </AuditContext.Provider>
);
