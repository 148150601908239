import Files from '../../../../files/panels/files/components/Files';
import ResponsivePanel from '../../ResponsivePanel';

const BlobsPanel = ({ tab }) => {
  const { config } = tab;

  const folderPath = config.params.folder_path ? `blobs/${config.params.folder_path}` : 'blobs';

  return (
    <ResponsivePanel>
      {(panelWidth) => <Files panelWidth={panelWidth} folderPath={folderPath} />}
    </ResponsivePanel>
  );
};

export default BlobsPanel;
