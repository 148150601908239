import WorkspaceDataset from './workspace_dataset';
import ChorusView from './chorus_view';
import Dataset from './dataset';

const Entities = {
  dataset: Dataset,
  chorusView: ChorusView,
  workspaceDataset: WorkspaceDataset,
};

export default (attrs, options) => {
  let entityType = 'dataset';
  if (attrs) {
    if (attrs.entitySubtype === 'CHORUS_VIEW') {
      entityType = 'chorusView';
    } else if (attrs.workspace) {
      entityType = 'workspaceDataset';
    }
  }
  return new Entities[entityType](attrs, options);
};
