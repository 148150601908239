/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import $ from '../../../application/jquery';

class ReactToBackboneWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.view = props.view;
    this.tabId = props.tabId;
    this.component = props.component;
    this.onceLoadedCallback = props.onceLoadedCallback;
  }

  componentDidMount() {
    if (this.view) {
      $(this.el).html(this.view.el);
      this.view.render();

      if (this.onceLoadedCallback) {
        // This logic performs fetching operations via Backbone methods to get the required data and hook it to the passed callback.
        const self = this;
        if (self.component === 'datatable' || self.component === 'analyse') {
          // DTE workaround
          this.view.itemModel?.fetch().then(() => {
            self.onceLoadedCallback.apply(self, [self.tabId, self.component, self.view.itemModel]);
          });
        } else if (self.component === 'app') {
          // Containerised apps workaround
          this.view.app.fetch().then(() => {
            self.onceLoadedCallback.apply(self, [self.tabId, self.component, self.view.app]);
          });
        } else if (this.onceLoadedCallback) {
          this._onceLoaded();
        }
      }
    }
  }

  componentWillUnmount() {
    this.view?.teardown();
  }

  _onceLoaded() {
    // uses the onceLoaded method from bare_view.js and bind a callback prop to it so that the Flexlayout can consume async backbone data.
    const self = this;
    this.view.onceLoaded(this.view.model, () => {
      self.onceLoadedCallback.apply(self, [self.tabId, self.component, self.view.model]);
    });
  }

  render() {
    return (
      <div className="aridhia-legacy" ref={(el) => { this.el = el; }} />
    );
  }
}

export default ReactToBackboneWrapper;
