import Filter from './filter';
import {
  StringFilter, BooleanFilter, DateFilter,
  NumericFilter, OtherFilter, TimeFilter, TimestampFilter,
} from './dataset_filter_maps';

export default Filter.extend({
  constructorName: 'DatasetFilter',

  getFilterMap() {
    switch (this.has('column') && this.get('column').get('typeCategory')) {
      case 'STRING':
      case 'LONG_STRING':
        return new StringFilter();
      case 'BOOLEAN':
        return new BooleanFilter();
      case 'WHOLE_NUMBER':
      case 'REAL_NUMBER':
        return new NumericFilter();
      case 'DATE':
        return new DateFilter();
      case 'TIME':
        return new TimeFilter();
      case 'DATETIME':
        return new TimestampFilter();
      default:
        return new OtherFilter();
    }
  },

  sqlString() {
    const columnName = this.get('column') && this.get('column').quotedName();
    const comparatorName = this.get('comparator');
    const inputValue = this.get('input') && this.get('input').value;

    return (columnName && comparatorName) ?
      this.getFilterMap().comparators[comparatorName].generate(columnName, inputValue) :
      '';
  },

  isComplete() {
    const { usesInput } = this.getFilterMap().comparators[this.get('comparator')];
    const input = this.get('input') && this.get('input').value;
    return !!(!usesInput || input);
  },
});
