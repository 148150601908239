import { Button, Icon } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { useWorkspaceContext } from '../../../../adapters/Workspace';
import { useDatabaseTableContext } from '../../../Context';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import AddNoteModal from '../../../../activities/components/modals/AddNoteModal';
import Activities from '../../../../activities/components/Activities';
import Can from '../../../../permissions';
import { useActivities } from '../../../../activities/service';
import RefreshButton from '../../../../components/buttons/RefreshButton';
import TableHeaderError from '../../../../components/errors/TableHeaderError';

const DatabaseTableNotes = () => {
  const { tableName, tableId } = useDatabaseTableContext();
  const { workspaceUuid } = useWorkspaceContext();
  const {
    activities,
    isFetching,
    refetch,
    isError,
  } = useActivities(workspaceUuid, 'dataset', tableId);

  const addNoteItem = (
    <Button size="tiny" compact>
      <Icon name="sticky note" title={t('tables_list.actions.add_note')} />
      {t('tables_list.actions.add_note')}
    </Button>
  );

  return (
    <>
      <div className="position-top-right margin-none" data-testid="database-table-notes">
        {isError && <TableHeaderError />}
        <Can I="Interact with" this="Workspace">
          <ModalWrapper trigger={addNoteItem}>
            <AddNoteModal
              entityType="dataset"
              entityId={tableId}
              entityName={tableName}
            />
          </ModalWrapper>
        </Can>
        <RefreshButton isFetching={isFetching} refetch={refetch} size="tiny" compact />
      </div>
      <Activities
        entityType="dataset"
        entityId={tableId}
        entityName={tableName}
        activities={activities}
      />
    </>
  );
};

export default DatabaseTableNotes;
