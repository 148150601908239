import { useDatabaseTableCreationContext } from '../Context';
import { useCreateNewDatabaseTable, useCreateNewDatabaseTableStatus } from '../service';
import DatabaseTableCreationSummaryMessage from './DatabaseTableCreationSummaryMessage';
import DatabaseTableCreationSummarySegment from './DatabaseTableCreationSummarySegment';

const DatabaseTableCreationSummary = () => {
  const { newDatabaseTableState, dispatch } = useDatabaseTableCreationContext();

  const { newDatabaseTable, newDatabaseTableIsError } = useCreateNewDatabaseTable(newDatabaseTableState.table);
  const {
    newDatabaseTableStatus,
    newDatabaseTableStatusIsError,
  } = useCreateNewDatabaseTableStatus(
    newDatabaseTableState.table,
    dispatch,
    newDatabaseTable.dataset_id,
  );

  const tableSize = newDatabaseTableStatus.size ? String(newDatabaseTableStatus.size).concat('  Kb') : '0 Kb';
  let totalRows = newDatabaseTableState.table.original_rows_length;
  if (newDatabaseTableState.table.source_file_size > 10240) {
    totalRows = Math.floor(totalRows * (newDatabaseTableState.table.source_file_size / 10240));
  }

  return (
    <>
      <DatabaseTableCreationSummaryMessage
        errorStart={newDatabaseTableIsError}
        errorStatus={newDatabaseTableStatusIsError}
        creationComplete={newDatabaseTableState.table.creation_complete}
      />
      {newDatabaseTableState.table.creation_complete && (
        <DatabaseTableCreationSummarySegment
          title={newDatabaseTableState.table.metadata.title}
          subtitle={
            newDatabaseTableState.table.data_table_name || newDatabaseTableState.table.data_table_name_from_file
          }
          description={newDatabaseTableState.table.metadata.description}
          url={newDatabaseTableState.table.metadata.url}
          sourceFile={newDatabaseTableState.table.source_file}
          sourceMetadataFile={newDatabaseTableState.table.metadata.source_metadata_file}
          tableSize={tableSize}
          rowsLength={totalRows}
          columnsLength={newDatabaseTableState.table.columns.filter((column) => !column.disabled).length}
        />
      )}

    </>
  );
};

export default DatabaseTableCreationSummary;
