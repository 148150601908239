import _ from '../../underscore';
import $ from '../../jquery';
import TruncatedText from '../core/truncated_text_view';
import WorkspaceDataset from '../../models/workspace_dataset';
import { lastComment } from '../../utilities/collaboration';
import View from '../loading_view';
import TagsContext from '../../mixins/tags_context';

export default View.include(TagsContext).extend({
  constructorName: 'DatasetItemView',
  templateName: 'dataset_item',
  tagName: 'div',

  subviews: {
    '.comment .body': 'commentBody',
  },

  setup(...args) {
    this._super('setup', args);
    this.listenTo(this.model, 'invalidated', function reFetch() { this.model.fetch(); });
  },

  setupSubviews() {
    this.commentBody = new TruncatedText({
      model: lastComment(this.model),
      attribute: 'body',
      attributeIsHtmlSafe: true,
    });
  },

  postRender() {
    $(this.el).attr('data-database-object-id', this.model.id); // selenium handle
    $(this.el).attr('data-id', this.model.id);
    $(this.el).attr('data-type', this.model.entityType);
  },

  additionalContext() {
    // For database objects that are not in workspaces, active workspace is undefined, but the Dataset should be viewable
    const url = this.model.showUrl();

    const ctx = {
      name: this.model.name(),
      dataset: new WorkspaceDataset(this.model),
      url,
      iconUrl: this.model.iconUrl(),
      isDataset: true,
      importing: this.model.isImporting(),
    };

    _.extend(ctx, this.additionalContextForTags());

    return ctx;
  },
});
