import _ from '../underscore';
import t, { I18n } from '../intl';

export default {
  serverErrorMessages() {
    let output = [];
    if (!this.serverErrors) { return output; }

    if (this.serverErrors.fields) {
      _.each(this.serverErrors.fields, (errors, field) => {
        _.each(errors, (context, error) => {
          const fullKey = `field_error.${field}.${error}`;
          const genericKey = `field_error.${error}`;
          let message;

          if (I18n.lookup(fullKey)) {
            message = t(fullKey, context);
          } else {
            const ctx = context;
            ctx.field = _.humanize(field);
            message = t(genericKey, ctx);
          }

          output.push(message);
        });
      });
    } else if (this.serverErrors.record) {
      const fullKey = `record_error.${this.serverErrors.record}`;

      if (I18n.lookup(fullKey)) {
        output = [t(fullKey, this.serverErrors)];
      }
    } else if (this.serverErrors.service) {
      const fullKey = `service_error.${this.serverErrors.service}`;

      if (I18n.lookup(fullKey)) {
        output = [t(fullKey)];
      }
    }
    if (this.serverErrors.message) {
      output.push(this.serverErrors.message);
    }

    return output;
  },

  serverErrorMessage() {
    return this.serverErrorMessages().join('\n');
  },
};
