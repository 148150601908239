import $ from '../../jquery';
import WorkspaceShowSidebar from './workspace_show_sidebar';
import MainContentView from '../core/main_content_view';
import WorkspaceShow from './workspace_show_view';
import WorkspaceSummaryContentHeader from './workspace_summary_content_header_view';
import View from '../loading_view';

export default View.extend({

  bindCallbacks: $.noop,
  subviews: {
    '.app-content': 'mainContent',
    '.app-sidebarBasic': 'sidebar',
  },

  setup(options) {
    this.className = 'app-workspaceSummary';
    this.templateName = 'tab_content';

    this.model = options.model;
    this.sidebar = new WorkspaceShowSidebar({ model: this.model });
    this.mainContent = new MainContentView({
      model: options.model,
      content: new WorkspaceShow({ model: options.model }),
      contentHeader: new WorkspaceSummaryContentHeader({ model: options.model }),
    });
  },
});
