import axios from 'axios';
import { idToken } from '../auth/oauth';
import chorus from '../chorus';

function getRedirectUrl(url) {
  return idToken().then((token) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    return axios.get(url, config);
  }).then(r => r.data.url)
    .catch(() => {
    throw Error('workfiles.load.error');
  });
}

export function openFileTab(workspaceId, name) {
  const lookup = `/api/workspaces/${workspaceId}/resources?response=json&type=file&name=${encodeURI(name)}`;
  getRedirectUrl(lookup).then((tabUrl) => {
    chorus.router.navigate(tabUrl.substring(2));
  }).catch(e => chorus.toast(e.message));
}