import { Icon, Message } from 'semantic-ui-react';

const AppRunError = ({ errorMessage }) => (
  <Message warning attached="bottom" className="app-run-error">
    <Icon name="warning" />
    {errorMessage}
  </Message>
);

export default AppRunError;
