import chorus from '../chorus';
import _ from '../underscore';
import $ from '../jquery';
import t from '../intl';
import Confirm from './confirm';
import Base from './base';
import Routing from '../mixins/routing';

export default Confirm.include(Routing).extend({
  events: _.extend({}, Base.prototype.events, {
    'click button.submit': 'deleteModel',
  }),
  constructorName: 'ModelDelete',

  focusSelector: 'button.cancel',

  additionalClass: 'error',

  persistent: true, // here for documentation, doesn't actually do anything as we've overwritten bindCallbacks

  bindCallbacks() {
    this.model.bind('destroy', this.modelDeleted, this);
    this.model.bind('destroyFailed', this.render, this);
  },

  close() {
    this.model.unbind('destroy', this.modelDeleted);
    this.model.unbind('destroyFailed', this.render);
  },

  deleteModel(e) {
    e.preventDefault();

    if (this.model.canDestroy()) {
      this.model.destroy();
      this.$('button.submit').startLoading('actions.deleting');
    } else {
      this.$('.content').append(`<span>${t('actions.delete_workspace_has_child')}</span>`);
      this.$('button.submit').hide();
    }
  },

  deleteMessageParams: $.noop,

  modelDeleted() {
    this.closeModal();
    chorus.toast(this.deleteMessage, this.deleteMessageParams());
    chorus.PageEvents.trigger(`${this.model.entityType}:deleted`, this.model);
    if (this.redirectUrl) {
      this.navigate(this.redirectUrl);
    }
  },
});
