export default {

  additionalContextForTags() {
    const self = this;
    const presentedTags = this.model.tags().map(tag => ({
      tagLink: tag.showUrl(self.options && self.options.workspaceIdForTagLink),
      tagName: tag.name(),
    }));

    return { tags: presentedTags };
  },
};
