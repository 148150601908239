import ace from 'ace-builds/src-noconflict/ace';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-emmet';

import _ from '../../underscore';
import t from '../../intl';
import ResultsConsole from '../../views/workfiles/results_console_view';
import Dialog from '../../dialogs/dialogs';
import { preview } from '../../utilities/schema_assoc';

export default Dialog.extend({
  constructorName: 'SqlPreview',

  templateName: 'sql_preview',
  title: t('sql_preview.dialog.title'),
  additionalClass: 'dialog_wide',

  events: {
    'click .preview': 'previewData',
    'click button.cancel': 'cancelTask',
  },

  subviews: {
    '.results_console': 'resultsConsole',
  },

  setup() {
    this.resultsConsole = new ResultsConsole({
      titleKey: 'dataset.data_preview',
      enableExpander: true,
      enableClose: true,
      enableResize: false,
    });

    this.subscribePageEvent('action:closePreview', this.hidePreviewData);
    this.subscribePageEvent('modal:closed', this.cancelTask);
  },

  makeACEOptions() {
    return {
        minLines: 1,
        fontSize: 14,
        wrap: true,
        showPrintMargin: false,
        animatedScroll: false,
        fixedWidthGutter: true,
        highlightActiveLine: false,
        mode: this.options.mode,
        theme: 'ace/theme/chrome',
        autoScrollEditorIntoView: true,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: false,
        enableSnippets: true,
        mode: 'ace/mode/pgsql',
        readOnly: true,
        highlightGutterLine: false,
    };
  },

  postRender() {
    _.defer(_.bind(function setupACE() {
      const editor_div = this.$('.text_editor')[0];
      this.editor = ace.edit(editor_div);
      this.editor.setOptions(this.makeACEOptions());
      // Prevent access to ACE style pop-up menu
      this.editor.commands.removeCommand('showSettingsMenu');
      this.editor.commands.removeCommand('goToPreviousError');
      this.editor.commands.removeCommand('goToNextError');

      //this.editor.renderer.$cursorLayer.element.style.display = "none"
      editor_div.style.height = "330px";
      this.editor.resize();
    }, this));
    this.hidePreviewData();
    if (this.options && this.options.flags) {
      if (!this.options.flags.editSQL) {
        this.$('button.edit').addClass('hidden');
      }
      if (!this.options.flags.previewData) {
        this.$('button.preview').addClass('hidden');
      }
      if (!this.options.flags.previewBar) {
        this.$('div.preview_bar').parent().addClass('hidden');
      }
    }
  },

  hidePreviewData() {
    this.$('.results_console').addClass('hidden');
    this.$('button.preview').removeClass('invisible');
  },

  previewData(e) {
    e && e.preventDefault();
    this.$('.results_console').removeClass('hidden');
    this.$('button.preview').addClass('invisible');
    const previewToExecute = preview(this.model).set({ query: this.sql() }, { silent: true });
    this.resultsConsole.execute(previewToExecute);
  },

  sql() {
    return this.model.get('query');
  },

  cancelTask() {
    this.resultsConsole.cancelExecution();
  },
});
