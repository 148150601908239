import View from '../../loading_view';
import t from '../../../intl';

export default View.extend({
  templateName: 'mini_apps/apps-sidebar-info',
  additionalContext() {
    let statusMessage = '';
    if (this.model.attributes.currentStatus) {
      statusMessage = t(`mini_app.list.sidebar.status_${this.model.attributes.currentStatus}`);
    }

    const startedByName = this.model.attributes.startedByUser ? this.model.attributes.startedByUser.displayName() : '';

    return {
      lastModified: this.model.latestFileModificationTimestamp(),
      statusMessage,
      started: this.model.attributes.running || this.model.attributes.loading,
      startedByName,
    };
  },
});
