import {
  Segment, Icon, Dropdown,
} from 'semantic-ui-react';
import PanelFullscreen from './PanelFullscreen';
import { useTabContext } from '../context';

export const DefaultPanelHeaderIcon = ({ name }) => (
  <Icon name={name} size="large" color="blue" data-testid="panel-header-icon" />
);

const PanelHeaderView = ({
  headerIcon,
  headerText,
  headerDropdown,
  headerButtons,
}) => (
  <Segment as="header" attached="top" className="aridhia-panel-header">
    <div className="flex justify-space-between align-center gap-width">
      <div className="shrink-width align-center overflow-visible">
        {headerIcon}
        {headerText}
        {headerDropdown}
      </div>
      {headerButtons}
      <PanelFullscreen />
    </div>
  </Segment>
);

const toMenuItem = (tab) => (
  <Dropdown.Item
    text={tab.title}
    href={tab.url}
    className={tab.className}
    selected={tab.selected}
    key={tab.key}
    as="a"
  />
);

const PanelHeaderDropdown = () => {
  const tabs = useTabContext() || [];
  const menuItems = tabs.map(toMenuItem);

  if (menuItems.length <= 1) {
    return null;
  }

  return (
    <Dropdown className="not-fullscreen-hidden">
      <Dropdown.Menu>
        {menuItems}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const PanelHeader = ({
  headerIcon,
  headerText,
  headerButtons,
}) => (
  PanelHeaderView({
    headerDropdown: <PanelHeaderDropdown />,
    headerIcon,
    headerText,
    headerButtons,
  })
);

export default PanelHeader;
