import chorus from '../chorus';
import _ from '../underscore';
import $ from '../jquery';
import View from '../views/loading_view';

const Modal = View.extend({
  constructorName: 'Modal',
  verticalPadding: 30,
  focusSelector: 'input:eq(0)',

  launchModal() {
    if (chorus.modal && this !== chorus.modal) {
      chorus.modal.launchSubModal(this);
    } else {
      this.launchNewModal();
    }
  },

  launchNewModal() {
    this.render();
    $(document).one('reveal.facebox', _.bind(this.beginReveal, this));
    $.facebox(this.el);
    this.previousModal = chorus.modal;
    this.restore();
    this.showErrors();
  },

  launchSubModal(subModal) {
    this.ignoreFacebox();
    this.background();

    $.facebox.settings.inited = false;
    subModal.launchNewModal();
  },

  resize(windowWidth, windowHeight) {
    const $popup = $('#facebox .popup');
    const $facebox = $('#facebox');
    const $window = $(window);

    const height = windowHeight || $window.height();

    $facebox.css('top', `${this.verticalPadding}px`);
    const popupHeight = height - (this.verticalPadding * 2);
    $popup.css('max-height', `${popupHeight}px`);
    $('#facebox').css('left', ($(window).width() / 2) - ($('#facebox .popup').width() / 2));
  },

  preRender(...args) {
    const result = this._super('preRender', args);

    this.listenTo(chorus.page, 'resized', _.bind(this.resize, this));

    this.preventScrollingBody();

    return result;
  },

  postRender() {
    this._super('postRender');
    $('#facebox').css('left', ($(window).width() / 2) - ($('#facebox .popup').width() / 2));
  },

  makeModel(options) {
    if (options && options.pageModel) {
      this.pageModel = options.pageModel;
      this.model = this.model || this.pageModel;
    }
  },

  closeModal() {
    $(document).trigger('close.facebox');
    if (typeof this.previousModal !== 'undefined') {
      return true;
    }
    return null;
  },

  keydownHandler(e) {
    if (e.keyCode === 27) {
      this.escapePressed();
    }
  },

  escapePressed() {
    this.closeModal();
  },

  modalClosed() {
    if (this === chorus.modal) {
      this.close();
      $('#facebox').remove();
      $.facebox.settings.inited = false;
      chorus.PageEvents.trigger('modal:closed', this);

      delete chorus.modal;
      this.ignoreFacebox();

      if (this.previousModal) {
        this.previousModal.restore();
      } else {
        this.restoreScrollingBody();
      }
    }
    this.teardown();
  },

  restore() {
    chorus.modal = this;
    this.foreground();
    this.listenToFacebox();
    _.defer(_.bind(this.resize, this));
  },

  foreground() {
    $(`#facebox-${this.faceboxCacheId}`).attr('id', 'facebox').removeClass('hidden');
    $(`#facebox_overlay-${this.faceboxCacheId}`).attr('id', 'facebox_overlay');
    this.resize();
  },

  background() {
    this.faceboxCacheId = Math.floor((Math.random() * 1e8) + 1).toString();
    $('#facebox').attr('id', `facebox-${this.faceboxCacheId}`).addClass('hidden');
    $('#facebox_overlay').attr('id', `facebox_overlay-${this.faceboxCacheId}`);
  },

  listenToFacebox() {
    this.boundModalClosed = _.bind(this.modalClosed, this);
    this.boundKeyDownHandler = _.bind(this.keydownHandler, this);
    $(document).one('close.facebox', this.boundModalClosed);
    $(document).bind('keydown.facebox', this.boundKeyDownHandler);
  },

  ignoreFacebox() {
    $(document).unbind('close.facebox', this.boundModalClosed);
    $(document).unbind('keydown.facebox', this.boundKeyDownHandler);
    delete this.boundModalClosed;
    delete this.boundKeyDownHandler;
  },

  preventScrollingBody() {
    $('body').css('overflow', 'hidden');
  },

  restoreScrollingBody() {
    $('body').css('overflow', 'visible');
  },

  close: $.noop,
  revealed: $.noop,

  beginReveal() {
    this.revealed();
    if (this.focusSelector) {
      this.$(this.focusSelector).focus();
    }
  },

  saveFailed(model) {
    this.$('button.submit').stopLoading();
    this.$('button.cancel').prop('disabled', false);
    if (model) {
      this.showErrors(model);
    } else {
      this.showErrors();
    }
  },
});

if (window.jasmine) {
  Modal.prototype.preventScrollingBody = $.noop;
  Modal.prototype.restoreScrollingBody = $.noop;
}

export default Modal;
