import { t } from 'i18n-js';
import { Message } from 'semantic-ui-react';

const DeleteModalMessage = ({ title }) => (
  <Message
    className="margin-top-none"
    icon="warning circle"
    warning
    header={title}
    content={t('modals.delete.warning')}
  />
);

export default DeleteModalMessage;
