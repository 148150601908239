import { t } from 'i18n-js';
import { Form } from 'semantic-ui-react';
import FormValidationError from '../../../components/errors/FormValidationError';
import { findErrors, hasError } from '../../../utilities/validation';
import { validationLengths, validationFields } from '../../../constants/validation';

const SaveAsCSVNameForm = ({ validationErrors, name, handleNameChange }) => (
  <Form.Field data-testid="name-form">
    <label htmlFor="name">
      {t('modals.save_as_csv.csv.name')}
      {hasError(validationErrors, validationFields.NAME) && (
        <FormValidationError errorMsg={(findErrors(validationErrors, validationFields.NAME)[0]).message} />
      )}
    </label>
    <Form.Input
      id="name"
      autoFocus
      placeholder={name}
      maxLength={validationLengths.NAME}
      value={name}
      onChange={handleNameChange}
      error={hasError(validationErrors, validationFields.NAME)}
    />
  </Form.Field>
);

export default SaveAsCSVNameForm;
