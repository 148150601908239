import { t } from 'i18n-js';
import { Header } from 'semantic-ui-react';
import { PanelHeader, DefaultPanelHeaderIcon } from '../../../../layout';

const DatabaseTableCreationHeader = () => (
  <PanelHeader
    headerIcon={<DefaultPanelHeaderIcon name="table" />}
    headerText={<Header as="h3" className="truncate" content={t('database_tables_new.header')} />}
  />
);

export default DatabaseTableCreationHeader;
