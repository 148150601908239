/* eslint-disable react/jsx-props-no-spreading */
import { t } from 'i18n-js';
import { Button } from 'semantic-ui-react';
import React from 'react';
import { combineHooks } from '../../../../../utilities/helpers';

export const ActionButton = ({ name, ...props }) => (<Button size="tiny" compact {...props} />);
export const MaxUsersConnectedButton = ({ href, ...props }) => (
  <ActionButton
    className="aridhia-disabled"
    title={t('virtual_machines.warning.max_user_connected.header')}
    aria-labelledby={t('virtual_machines.warning.max_user_connected.header')}
    {...props}
  />
);

export const StartingButton = () => (
  <ActionButton
    disabled
    content={t('virtual_machines.actions.starting')}
    icon={{
      name: 'sync alternate',
      loading: true,
      color: 'green',
      'data-testid': 'virtual-machines-start-icon',
    }}
  />
);

export const StartButton = ({ onClick }) => (
  <ActionButton
    onClick={onClick}
    content={t('virtual_machines.actions.start')}
    icon={{
      name: 'play',
      color: 'green',
      'data-testid': 'virtual-machines-start-icon',
    }}
  />
);

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const StoppingButton = () => (
  <ActionButton
    disabled
    content={t('virtual_machines.actions.stopping')}
    icon={{
      name: 'sync alternate',
      loading: true,
      color: 'red',
      'data-testid': 'virtual-machines-stop-icon',
    }}
  />
);

export const StopButton = ({ disabled, onClick }) => (
  <ActionButton
    disabled={disabled}
    onClick={onClick}
    content={t('virtual_machines.actions.stop')}
    icon={{
      name: 'stop',
      color: 'red',
      'data-testid': 'virtual-machines-stop-icon',
    }}
  />
);

const maxUsersButtonIfFull = ({ isFull }) => ({ as: isFull ? MaxUsersConnectedButton : ActionButton });

/**
 * Button for connecting to a terminal
 *
 * @param isFull if true rendered as MaxUsersConnectedButton otherwise ActionButton
 * @param href link to panel to open
 * @returns {JSX.Element}
 * @constructor
 */
export const TerminalButton = combineHooks(maxUsersButtonIfFull, ({ as, href, onClick }) => as({
  content: t('virtual_machines.actions.terminal'),
  icon: { name: 'code', color: 'blue', 'data-testid': 'virtual-machines-terminal-icon' },
  href,
  onClick,
}));

/**
 * Button for connecting to a desktop
 *
 * @param isFull if true rendered as MaxUsersConnectedButton otherwise ActionButton
 * @param href link to panel to open
 * @returns {JSX.Element}
 * @constructor
 */
export const DesktopButton = combineHooks(maxUsersButtonIfFull, ({ as, href }) => as({
  content: t('virtual_machines.actions.desktop'),
  icon: { name: 'desktop', color: 'green', 'data-testid': 'virtual-machines-desktop-icon' },
  href,
}));
