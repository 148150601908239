import { t } from 'i18n-js';
import { parseJson } from '../../../utilities/helpers';
import toastMessage from '../../utilities/toast';

export const LAYOUT_STORAGE_KEY = 'layout2';

export const save = (newLayout) => {
  localStorage.setItem(LAYOUT_STORAGE_KEY, JSON.stringify(newLayout));
};

export const load = () => {
  const model = {
    layout: {},
    global: {
      tabEnableRename: false,
      tabEnableDrag: false,
    },
    borders: [],
  };

  const storedLayout = localStorage.getItem(LAYOUT_STORAGE_KEY);
  if (storedLayout) {
    const { layout, global, borders } = parseJson(storedLayout);
    if (layout && global && borders) {
      model.layout = layout;
    } else {
      toastMessage('error', t('layout.error.load_error'), 'layoutError');
    }
  }

  if (!model.layout || Object.keys(model.layout).length === 0) {
    model.layout = {
      type: 'row',
      weight: 100,
      children: [],
    };
  }
  return model;
};

export default load;
