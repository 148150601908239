import RCommand from '../models/r_command';
import Collection from './collections';

const RCommands = Collection.extend({
  constructorName: 'RCommands',
  model: RCommand,
});

export const rCommands = new RCommands();

export default RCommands;
