import { Segment, Grid, Label } from 'semantic-ui-react';
import { t } from 'i18n-js';
import AccordionWrapper from '../../../../components/AccordionWrapper';
import DatabaseTableMetadata from './DatabaseTableMetadata';
import DatabaseTableNotes from './DatabaseTableNotes';
import { useDatabaseTableContext } from '../../../Context';

const DatabaseTableInformation = () => {
  const { table } = useDatabaseTableContext();

  return (
    <Segment attached className="aridhia-database-table-information">
      <Grid stackable columns={table.query ? 3 : 2}>
        <Grid.Column>
          <AccordionWrapper title={t('database_table.metadata.header')} className="position-relative">
            <DatabaseTableMetadata />
          </AccordionWrapper>
        </Grid.Column>
        <Grid.Column>
          <AccordionWrapper title={t('database_table.notes.header')} className="position-relative">
            <DatabaseTableNotes />
          </AccordionWrapper>
        </Grid.Column>
        {table.query && (
          <Grid.Column>
            <AccordionWrapper title={t('database_table.query.header')}>
              <Label as="code" className="white-space-wrap" content={table.query} data-testid="database-table-query" />
            </AccordionWrapper>
          </Grid.Column>
        )}
      </Grid>
    </Segment>
  );
};

export default DatabaseTableInformation;
