/* eslint-disable max-lines */
import { t } from 'i18n-js';
import { types } from './actions';
import { validationFields } from '../../../constants/validation';
import { steps } from './constants';
import {
  databaseTableColumn,
  databaseTableParsedContent,
  databaseTableMetadata,
} from './state';
import {
  keysToLowercase,
  typesToLowercase,
  reorderColumnsId,
  filterFields,
  setPrimaryKey,
  setParsedContent,
  setUpdatedColumns,
  setAddColumns,
  setColumnsRowsMismatch,
  setUpdatedMetadataAttributes,
  setColumnsToDisplay,
  setErrors,
  getColumnId,
} from './helper';

const reducer = (state, action) => {
  let errors;
  let warnings;
  let updatedParsedContent;
  let columnId;
  let updatedColumns;
  let metadataFromTDF;
  let newColumnsFromTDF;
  let updatedMetadata;
  let updatedMetadataAttributes;
  let updateGuessedColumns;
  switch (action.type) {
    case types.SET_TABLE_NAME_FROM_SCRATCH:
      return {
        ...state,
        table: {
          ...state.table,
          data_table_name: action.tableName,
        },
        next_step_enabled: !!action.tableName,
      };
    case types.SET_TABLE_NAME_FROM_FILE:
      return {
        ...state,
        table: {
          ...state.table,
          data_table_name_from_file: action.tableNameFromFile,
        },
        next_step_enabled: !!action.tableNameFromFile,
      };
    case types.SET_TABLE_CONTENT_FROM_CSV:
      errors = [];
      updatedParsedContent = setParsedContent(
        action.contentFromCSV.newContent,
        state.table.metadata,
        state.table.added_primary_column,
        errors,
      );
      return {
        ...state,
        table: {
          ...state.table,
          data_table_name_from_file: action.contentFromCSV.selectedCSVFile.tableName,
          source_file: action.contentFromCSV.selectedCSVFile.tablePath,
          source_file_id: action.contentFromCSV.selectedCSVFile.fileId,
          data_table_name: '',
          source_file_content: action.contentFromCSV.newContent,
          source_file_size: action.contentFromCSV.selectedCSVFile.fileSize,
          added_primary_column: false,
          parsed_content: updatedParsedContent.parsedContent,
          original_rows_length: updatedParsedContent.originalRowsLength,
          tdf_is_linked: false,
          metadata: state.table.metadata,
          columns: [databaseTableColumn(0)],
          columns_to_display_set: false,
        },
        validation_errors: updatedParsedContent.parsingError,
        next_step_enabled: !!action.contentFromCSV.selectedCSVFile && !!action.contentFromCSV.newContent,
      };
    case types.REMOVE_CSV:
      return {
        ...state,
        table: {
          ...state.table,
          data_table_name: '',
          data_table_name_from_file: '',
          source_file: '',
          source_file_id: null,
          source_file_content: '',
          source_file_size: 0,
          metadata: databaseTableMetadata(),
          columns: [databaseTableColumn(0)],
          columns_rows_mismatch: false,
          columns_to_display_set: false,
          added_primary_column: false,
          parsed_content: databaseTableParsedContent(),
          creation_complete: false,
          tdf_is_linked: false,
          dataset_id: null,
          guessed_columns: [],
        },
        validation_errors: [],
        next_step_enabled: false,
      };
    case types.SET_METADATA_CONTENT_FROM_TDF:
      newColumnsFromTDF = action.contentFromTDF.newMetadata?.Columns?.Column || [];
      newColumnsFromTDF = keysToLowercase(newColumnsFromTDF);
      newColumnsFromTDF = typesToLowercase(newColumnsFromTDF);
      newColumnsFromTDF = reorderColumnsId(newColumnsFromTDF);
      metadataFromTDF = action.contentFromTDF;
      updatedMetadata = {
        ...state.table.metadata,
        title: metadataFromTDF.newMetadata?.Title || state.table.metadata.title,
        description: metadataFromTDF.newMetadata?.Description || state.table.metadata.description,
        url: metadataFromTDF.newMetadata?.Source || state.table.metadata.url,
        source_metadata_file: metadataFromTDF.selectedTDFFile.filePath,
        source_metadata_file_id: metadataFromTDF.selectedTDFFile.fileId,
        file_size: metadataFromTDF.fileSize,
        text_qualifier: metadataFromTDF.newMetadata?.Format?.TextQualifier
          || state.table.metadata.text_qualifier,
        null_qualifier: metadataFromTDF.newMetadata?.Format?.NullQualifier
          || state.table.metadata.null_qualifier,
        delimiter: metadataFromTDF.newMetadata?.Format?.Delimiter || state.table.metadata.delimiter,
        encoding: metadataFromTDF.newMetadata?.Format?.Encoding?.toLowerCase() || state.table.metadata.encoding,
        input_has_header: metadataFromTDF.newMetadata?.Format?.Header?.toString()
          || state.table.metadata.input_has_header,
        columns: newColumnsFromTDF,
        is_empty: !metadataFromTDF.newMetadata,
        has_delimiter: !!metadataFromTDF.newMetadata?.Format?.Delimiter,
        has_columns: !!metadataFromTDF.newMetadata?.Columns?.Column,
      };
      updatedParsedContent = setParsedContent(
        state.table.source_file_content,
        updatedMetadata,
        state.table.added_primary_column,
        state.validation_errors,
      );
      return {
        ...state,
        table: {
          ...state.table,
          columns_rows_mismatch:
            setColumnsRowsMismatch(
              state.table,
              updatedMetadata,
              updatedParsedContent.parsedContent,
            ),
          tdf_is_linked: action.contentFromTDF.tdfIsLinked,
          metadata: updatedMetadata,
          columns_to_display_set: false,
          added_primary_column: false,
          parsed_content: updatedParsedContent.parsedContent,
          original_rows_length: updatedParsedContent.originalRowsLength,
        },
        validation_errors: updatedParsedContent.parsingError,
        next_step_enabled: true,
      };
    case types.REMOVE_METADATA_TDF:
      errors = filterFields(state.validation_errors, validationFields.COLUMNS_MISMATCH);
      updatedMetadata = databaseTableMetadata();
      updatedParsedContent = setParsedContent(
        state.table.source_file_content,
        updatedMetadata,
        state.table.added_primary_column,
        errors,
      );
      return {
        ...state,
        table: {
          ...state.table,
          added_primary_column: false,
          columns_rows_mismatch: false,
          tdf_is_linked: false,
          metadata: databaseTableMetadata(),
          columns_to_display_set: false,
          parsed_content: updatedParsedContent.parsedContent,
          original_rows_length: updatedParsedContent.originalRowsLength,
          columns: [databaseTableColumn(0)],
        },
        validation_errors: errors.concat(updatedParsedContent.parsingError),
        warnings: [],
        next_step_enabled: true,
      };
    case types.SET_METADATA_TITLE:
      return {
        ...state,
        table: {
          ...state.table,
          metadata: {
            ...state.table.metadata,
            title: action.metadataTitle,
          },
        },
      };
    case types.SET_METADATA_DESCRIPTION:
      return {
        ...state,
        table: {
          ...state.table,
          metadata: {
            ...state.table.metadata,
            description: action.metadataDescription,
          },
        },
      };
    case types.SET_METADATA_SOURCE:
      errors = filterFields(state.validation_errors, validationFields.URL);
      return {
        ...state,
        table: {
          ...state.table,
          metadata: {
            ...state.table.metadata,
            url: action.metadataSource,
          },
        },
        validation_errors: errors,
        next_step_enabled: true,
      };
    case types.REMOVE_FROM_SCRATCH_COLUMN:
      updatedColumns = setUpdatedColumns(state.table.columns, action.columnId);
      return {
        ...state,
        table: {
          ...state.table,
          columns: updatedColumns.updatedColumns,
          added_primary_column: updatedColumns.isPrimaryAdded ? false : state.table.added_primary_column,
        },
        next_step_enabled: true,
      };
    case types.ADD_FROM_SCRATCH_COLUMN:
      updatedColumns = setAddColumns(state.table.columns);
      return {
        ...state,
        table: {
          ...state.table,
          columns: updatedColumns,
        },
        next_step_enabled: true,
      };
    case types.SET_COLUMN_PROPERTY:
      columnId = getColumnId(action.updatedColumn.domId);
      updatedColumns = Array.from(state.table.columns);
      if (action.updatedColumn.propertyToChange === 'type' && action.updatedColumn.value === 'boolean') {
        updatedColumns[columnId].primary = false;
      }
      if (action.updatedColumn.propertyToChange === 'primary') {
        updatedColumns.forEach((_, id) => {
          if (id !== columnId) {
            updatedColumns[id][action.updatedColumn.propertyToChange] = false;
          }
        });
        if (updatedColumns[columnId]?.disabled) {
          updatedColumns[columnId].disabled = false;
        }
      }
      updatedColumns[columnId][action.updatedColumn.propertyToChange] = action.updatedColumn.value;
      errors = filterFields(state.validation_errors, action.updatedColumn.domId);
      warnings = filterFields(state.warnings, action.updatedColumn.domId);
      if (
        !!state.table.guessed_columns.length
        && action.updatedColumn.propertyToChange === 'type'
        && !state.table.guessed_columns[columnId].value.includes(action.updatedColumn.value.toLowerCase())
      ) {
        warnings.push(
          {
            field: `type-${columnId}`,
            message: t('field_error.TYPE_MISMATCH_SELECTION', {
              sourceType: action.updatedColumn.value.toLowerCase(),
              guessedtypeFlattened: state.table.guessed_columns[columnId].value.join(),
            }),
          },
        );
      }
      return {
        ...state,
        table: {
          ...state.table,
          columns: updatedColumns,
        },
        validation_errors: errors,
        warnings,
        next_step_enabled: true,
      };
    case types.SET_TABLE_COLUMNS_FROM_TYPEGUESSING:
      return {
        ...state,
        table: {
          ...state.table,
          columns: action.columnsData.updatedColumns,
          guessed_columns: action.columnsData.guessedColumns,
          typeguessing_in_progress: false,
        },
        warnings: action.columnsData.typeWarnings,
        next_step_enabled: true,
      };
    case types.SET_TYPEGUESSING_IN_PROGRESS:
      return {
        ...state,
        table: {
          ...state.table,
          typeguessing_in_progress: action.inProgress,
        },
        next_step_enabled: !action.inProgress && state.validation_errors.length === 0,
      };
    case types.SET_METADATA_ATTRIBUTES:
      updatedMetadataAttributes = setUpdatedMetadataAttributes(
        state.table.metadata,
        action.metadataData.propertyToChange,
        action.metadataData.value,
      );
      errors = [];
      updatedParsedContent = setParsedContent(
        state.table.source_file_content,
        updatedMetadataAttributes,
        state.table.added_primary_column,
        errors,
      );
      return {
        ...state,
        table: {
          ...state.table,
          columns: updatedParsedContent.parsedContent.columns,
          metadata: updatedMetadataAttributes,
          parsed_content: updatedParsedContent.parsedContent,
          original_rows_length: updatedParsedContent.originalRowsLength,
          columns_rows_mismatch:
            setColumnsRowsMismatch(
              state.table,
              updatedMetadataAttributes,
              updatedParsedContent.parsedContent,
            ),
        },
        validation_errors: updatedParsedContent.parsingError,
        next_step_enabled: !updatedParsedContent.parsedContent.parsing_errors,
      };
    case types.ADD_PRIMARY_KEY:
      errors = filterFields(state.validation_errors, validationFields.PRIMARY_KEY);
      warnings = filterFields(state.warnings, validationFields.PRIMARY_KEY);
      updateGuessedColumns = state.table.guessed_columns;
      updateGuessedColumns.unshift({
        id: state.table.guessed_columns.length,
        value: ['integer'],
      });
      updateGuessedColumns = reorderColumnsId(updateGuessedColumns);
      return {
        ...state,
        table: {
          ...state.table,
          columns: setPrimaryKey(state.table),
          added_primary_column: true,
          guessed_columns: updateGuessedColumns,
        },
        validation_errors: errors,
        warnings,
        next_step_enabled: true,
      };
    case types.SET_PRIMARY_KEY:
      errors = filterFields(state.validation_errors, validationFields.PRIMARY_KEY);
      return {
        ...state,
        validation_errors: errors,
        next_step_enabled: true,
      };
    case types.SET_CREATION_COMPLETE:
      return {
        ...state,
        table: {
          ...state.table,
          creation_complete: true,
          dataset_id: action.datasetId,
        },
      };
    case types.SET_CURRENT_STEP:
      return {
        ...state,
        current_step: action.currentStep,
      };
    case types.DECREMENT_STEP:
      return {
        ...state,
        current_step: state.current_step - 1,
        next_step_enabled: true,
      };
    case types.INCREMENT_STEP:
      errors = setErrors(state);
      return {
        ...state,
        table: {
          ...state.table,
          columns: (state.current_step === steps.SECOND && !state.table.columns_to_display_set)
            ? setColumnsToDisplay(state.table) : state.table.columns,
          columns_to_display_set: (state.current_step === steps.SECOND && !state.table.columns_to_display_set)
            ? true : state.table.columns_to_display_set,
        },
        validation_errors: errors,
        next_step_enabled: state.current_step === steps.SECOND
          ? (!errors.length && !state.table.columns_rows_mismatch && !state.table.parsed_content.parsing_errors)
          : !errors.length,
        current_step: !errors.length ? state.current_step + 1 : state.current_step,
      };
    default:
      throw new Error('Error updating newDatabaseTableState state');
  }
};

export default reducer;
