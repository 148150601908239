import { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';

const AccordionWrapper = ({
  children,
  title,
  className = '',
  icon = 'dropdown',
  fluid = true,
  styled = true,
  open = false,
}) => {
  const [active, setActive] = useState(open);

  return (
    <Accordion
      fluid={fluid}
      styled={styled}
      className={className}
    >
      {title && (
        <Accordion.Title
          as="h4"
          active={active}
          onClick={() => setActive(!active)}
        >
          {icon && <Icon name={icon} />}
          {title}
        </Accordion.Title>
      )}
      <Accordion.Content active={active}>
        {children}
      </Accordion.Content>
    </Accordion>
  );
};

export default AccordionWrapper;
