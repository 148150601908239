import _ from '../../underscore';
import $ from '../../jquery';
import TextWorkfileContent from './text_workfile_content_view';
import RCommand from '../../models/r_command';
import { workspace } from '../../utilities/workspace_assoc';
import View from '../loading_view';
import Routing from '../../mixins/routing';
import { rCommands } from '../../collections/r_commands';
import { FEATURE } from '../../../utilities/features';

export default View.include(Routing).extend({
  templateName: 'r_workfile_content',
  bindCallbacks: $.noop,

  subviews: {
    '.app-textContent': 'textContent',
  },

  setup(options, ...args) {
    this._super('setup', args);
    this.textContent = new TextWorkfileContent({
      model: this.model, hotkeys: this.hotkeys(), ref: this.model.id, tab: this.options.tab,
    });
  },

  hotkeys() {
    if (workspace(this.model).canUpdate() && !this.model.isInbox()) {
      return {
        r: _.bind(this.runInDefault, this),
        e: _.bind(this.runSelected, this),
      };
    }
    return null;
  },

  runSelected() {
    const sel = this.getSelectedText();
    if (sel !== '') {
      this.run(sel);
    }
  },

  runInDefault() {
    if (FEATURE.NEW_UX) {
      this.navigate(`workspaces/${this.model.get('workspace').id}/rtools/${this.model.id}`);
      return;
    }
    this.run(this.model.content());
  },

  run(content) {
    const command = new RCommand({ workfile: this.model, command: content, action: 'run' });
    rCommands.add(command);
    this.navigate(command.showUrl());
  },

  replaceCurrentVersion() {
    this.textContent.replaceCurrentVersion();
  },

  setEditorOption(option, value) {
    this.textContent.setEditorOption(option, value);
  },

  createNewVersion() {
    this.textContent.createNewVersion();
  },

  createNewFile() {
    this.textContent.createNewFile();
  },

  newWorkfile(content, options) {
    this.textContent.newWorkfile(content, options);
  },

  getSelectedText() {
    const editor = this.textContent.editor;
    const sel = editor.getSelection();
    if (sel.isEmpty()) {
      return '';
    }
    const txt = editor.getSession().doc.getTextRange(sel.getRange());
    return txt;
  },
});
