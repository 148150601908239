/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  DropdownItem, Icon, Modal, Table, Header, Segment,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import StandardModal, { StandardHeader } from '../../../standard_modal';
import { useWorkspaceContext, useWorkspaceAdmin } from '../../../adapters/Workspace';
import { CloseButton } from '../../buttons/ModalActionButtons';

// eslint-disable-next-line no-prototype-builtins
const checkKeys = (object, keys) => keys.every((key) => object.hasOwnProperty(key));

const RestrictionsTable = ({
  restrictions,
}) => (
  <Table className="review_restrictions" striped unstackable fixed data-testid="view-restrictions-table">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={2}>{t('restrictions.restrictions')}</Table.HeaderCell>
        <Table.HeaderCell width={8}>{t('restrictions.description')}</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {Object.values(restrictions).map((restriction) => (
        <Table.Row key={`restriction-${restriction.uuid}`} verticalAlign="top">
          <Table.Cell>
            <a target="_blank" rel="noreferrer" href={t(`restrictions.${restriction.condition_code}.ontology_uri`)}>
              {t(`restrictions.${restriction.condition_code}.label`)}
            </a>
          </Table.Cell>
          <Table.Cell><DescriptionText restriction={restriction} /></Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

const NoRestrictionsPresent = () => (
  <Segment placeholder data-testid="no-restrictions-segment">
    <Header as="h4" icon>
      <Icon name="unlock" data-testid="no-restrictions-icon" />
      {t('restrictions.no_restrictions_present')}
    </Header>
  </Segment>
);

export const AcceptedRestrictionsContent = ({
  restrictions,
}) => (
  <div data-testid="view-restrictions-modal-content">
    {(Object.keys(restrictions).length === 0 || restrictions === null) && (
      <div>
        <NoRestrictionsPresent />
      </div>
    )}
    {(Object.keys(restrictions).length >= 1)
      && (
        <>
          <div>
            <h5 data-testid="view-restrictions-subheader">{t('restrictions.subheader')}</h5>
          </div>
          <RestrictionsTable restrictions={restrictions} />
        </>
      )}
  </div>
);

const DescriptionText = ({ restriction }) => {
  if (restriction.condition_code === t('restrictions.ADDUC:0005.condition_code')) {
    if (checkKeys(restriction.properties, ['text', 'file_uri'])) {
      return (
        <div>
          {restriction.properties.text}
          <UserDefinedRestrictionAttachment file_uri={restriction.properties.file_uri} />
        </div>
      );
    }

    if (checkKeys(restriction.properties, ['text'])) {
      return <div>{restriction.properties.text}</div>;
    }

    if (checkKeys(restriction.properties, ['file_uri'])) {
      return <UserDefinedRestrictionAttachment file_uri={restriction.properties.file_uri} />;
    }
  }
  return t(`restrictions.${restriction.condition_code}.description`);
};

const UserDefinedRestrictionAttachment = ({ file_uri }) => (
  <p>
    <a target="_blank" rel="noreferrer" href={file_uri}>
      {t('restrictions.attached_document')}
    </a>
  </p>
);

export const AgreedRestrictionsModalContent = ({ setModalClose }) => {
  const { workspaceUuid } = useWorkspaceContext();
  const { workspace } = useWorkspaceAdmin(workspaceUuid);
  // this component is only valid after the workspace has been loaded.
  if (workspace === undefined) {
    return null;
  }
  const { restrictions } = workspace;
  const workspaceName = workspace.name;

  return (
    <>
      <StandardHeader icon="lock" title={t('restrictions.header', { workspace: workspaceName })} />
      <Modal.Content scrolling>
        <AcceptedRestrictionsContent restrictions={restrictions} />
      </Modal.Content>
      <Modal.Actions>
        <CloseButton onClose={setModalClose} />
      </Modal.Actions>
    </>
  );
};

const ViewAgreedRestrictions = (props) => (
  <StandardModal
    size="large"
    closeOnDimmerClick={false}
    trigger={<DropdownItem {...props} />}
  >
    <AgreedRestrictionsModalContent />
  </StandardModal>
);

export default ViewAgreedRestrictions;
