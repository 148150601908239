import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import DataGrid from '../core/data_grid_view';
import View from '../loading_view';
import Routing from '../../mixins/routing';
import { sqlResults } from '../../collections/task_set';

export default View.include(Routing).extend({
  templateName: 'results_console',
  constructorName: 'ResultsConsole',

  events: {
    'click .cancel': 'cancelExecution',
    'click a.minimize_arrow_up': 'toggleMinimizedTable',
    'click a.minimize_arrow_down': 'toggleMinimizedTable',
    'click a.compress': 'collapseTable',
    'click a.expand': 'expandTable',
    'click a.closed': 'clickClose',
    'click .app-saveAsView': 'createChorusView',
    'click .app-saveAsWorkfile': 'createNewWorkfile',
    'click .app-openInTab': 'pushToTab',
  },

  defaultBoundingContainer(view) {
    return {
      getAvailableHeight() {
        return $(window).height() - this.distanceFromTopOfWindow() - this.distanceFromBottomOfWindow();
      },

      distanceFromTopOfWindow() {
        const distance = view.$('.data_grid').offset().top;
        return distance - $(window).scrollTop();
      },

      distanceFromBottomOfWindow() {
        let verticalDialogPadding = 0;
        if (view.options.verticalDialogPadding) {
          ({ verticalDialogPadding } = view.options);
        }

        return verticalDialogPadding + this.bottomGutterHeight() + this.footerSize();
      },

      bottomGutterHeight() {
        const bottomGutter = view.$('.bottom_gutter');
        if (bottomGutter.is(':visible')) {
          return bottomGutter.height();
        }
        return 0;
      },

      footerSize() {
        if (view.options.footerSize) {
          return view.options.footerSize();
        }
        return 0;
      },
    };
  },

  setup() {
    this.boundingContainer = this.options.boundingContainer || this.defaultBoundingContainer(this);
    this.dataset = this.options.dataset;
    this.lastExecutionTime = { success: { time: null, rows: null }, failure: { time: null, rows: null } };
  },

  setModel(model, ...args) {
    if (this.model) {
      this.stopListening(this.model, 'saved', this.executionSucceeded);
      this.stopListening(this.model, 'saveFailed', this.executionFailed);
    }
    this._super('setModel', [model, ...args]);
    this.executionStarted();
    this.listenTo(this.model, 'saved', this.executionSucceeded);
    this.listenTo(this.model, 'saveFailed', this.executionFailed);
  },

  execute(model) {
    this.setModel(model);
    model.save();
  },

  teardown(...args) {
    if (this.model) { this.model.cancel(); }
    this._super('teardown', args);
  },

  executionStarted() {
    this.$el.addClass('executing open').removeClass('error success hidden');
    this.executionStartedTime = $.now();
    this.$('.spinner').addClass('hidden').startLoading();
    _.delay(_.bind(this.showSpinner, this), 250);
    this.$('.elapsed_time').text('');
    this.elapsedTimer = setInterval(_.bind(this.updateElapsedTime, this), 1000);
  },

  executionStopped() {
    this.cancelTimers();
    this.$('.spinner').addClass('hidden').stopLoading();
    this.$el.removeClass('executing');
    chorus.page.trigger('resized');
  },

  executionSucceeded() {
    this.executionStopped();
    this.$el.addClass('success').removeClass('error');
    this.lastExecutionTime.success.time = ($.now() - this.executionStartedTime);
    this.lastExecutionTime.success.rows = (this.model.attributes.rows.length);
    const executionTime = this.lastExecutionTime.success.time + t('results_console_view.last_execution_units');
    const executionRows = `${this.lastExecutionTime.success.rows} ${t('results_console_view.last_execution_rows')}`;

    this.$('.app-successfulExecutionTime').text(executionTime);
    this.$('.app-successfulExecutionRows').text(executionRows);
    this.initializeDataGrid(this.model);
    chorus.page.trigger('resized');
  },

  executionFailed() {
    this.showErrors();
    this.executionStopped();
    this.$el.addClass('error').removeClass('success');
    chorus.page.trigger('resized');
  },

  showSpinner() {
    this.$('.spinner').removeClass('hidden');
    chorus.page.trigger('resized');
  },

  updateElapsedTime() {
    const seconds = Math.round(($.now() - this.executionStartedTime) / 1000);
    this.$('.elapsed_time').text(t('results_console_view.elapsed', { sec: seconds }));
  },

  createNewWorkfile(e) {
    if (e) { e.preventDefault(); }
    this.parentView.newWorkfile(this.model.get('sql'), { fileType: 'sql', editInline: false, toast: true });
  },

  pushToTab(e) {
    if (e) { e.preventDefault(); }

    sqlResults.add(this.resource);
    this.navigate(this.resource.showUrl());
  },

  createChorusView(e) {
    if (e) { e.preventDefault(); }
    this.parentView.newChorusViewWithContent(this.model.get('sql'), {
      editSQL: false, previewData: false, previewBar: false, toast: true,
    });
  },

  initializeDataGrid() {
    if (this.dataGrid) { this.dataGrid.teardown(); }
    this.dataGrid = new DataGrid({ model: this.model, sortable: false });
    this.registerSubView(this.dataGrid);
    this.$('.result_table').html(this.dataGrid.el);
    this.dataGrid.render();

    if (!this.$el.hasClass('expanded') &&
            !this.$el.hasClass('collapsed')) {
      this.collapseTable();
      this.dataGrid.render();
    }
  },

  showErrors() {
    const self = this;
    self.$('.message').empty();
    self.$('.execution').addClass('sql_errors');

    _.each(this.model.serverErrors.fields.query, (error, key) => {
      const message = `<span class="error_key">${key}</span><span>${error.message}</span>`;
      self.$('.message').append(message);
    });
    chorus.page.trigger('resized');
  },

  cancelExecution(event) {
    this.cancelTimers();
    if (event) { event.preventDefault(); }
    if (this.model) { this.model.cancel(); }
    this.clickClose();
  },

  cancelTimers() {
    if (this.elapsedTimer) {
      clearInterval(this.elapsedTimer);
      delete this.elapsedTimer;
    }
  },

  getDesiredDataGridHeight() {
    const baseHeight = this.boundingContainer.getAvailableHeight();
    const arbitrarySpacing = 20; // to eliminate spurious y-scrollbar
    return baseHeight - arbitrarySpacing;
  },

  collapseTable(e) {
    if (e) { e.preventDefault(); }
    this.parentView.$('.text_workfile_content').addClass('expanded').removeClass('collapsed');
    this.$el.addClass('collapsed').removeClass('expanded minimized');
    this.resizeGrid('');

    chorus.page.trigger('resized');
  },

  expandTable(e) {
    if (e) { e.preventDefault(); }
    this.parentView.$('.text_workfile_content').addClass('collapsed').removeClass('expanded');
    this.$el.addClass('expanded').removeClass('minimized collapsed');

    chorus.page.trigger('resized');
  },

  minimizeTable() {
    this.parentView.$('.text_workfile_content').addClass('expanded').removeClass('collapsed');
    this.$el.addClass('minimized').removeClass('expanded collapsed');

    chorus.page.trigger('resized');
  },

  toggleMinimizedTable(e) {
    if (e) { e.preventDefault(); }
    if (this.$el.hasClass('minimized')) {
      this.collapseTable();
    } else {
      this.minimizeTable();
    }
  },

  clickClose(e) {
    if (e) { e.preventDefault(); }
    this.parentView.$('.text_workfile_content').addClass('expanded').removeClass('collapsed');
    this.$el.addClass('hidden').removeClass('executing open error success expanded collapsed');

    chorus.page.trigger('resized');
  },

  delayedResize() {
    const grid = this.$('.grid');
    const resizer = this.dataGrid;
    grid.width(grid.width() + 1);
    _.delay(() => {
      grid.css('width', '');
      resizer.resizeGridToResultsConsole();
    }, 100);
  },

  resizeGrid(gridHeight) {
    this.$('.data_grid').css('height', gridHeight);
    if (this.dataGrid) {
      this.dataGrid.resizeGridToResultsConsole();
    }
  },

  additionalContext() {
    return {
      titleKey: this.options.titleKey || 'results_console_view.title',
      enableClose: this.options.enableClose,
      enableResize: this.options.enableResize,
      enableExpander: this.options.enableExpander,
      hideExecution: this.options.hideExecution,
      saveActions: this.options.saveActions || false,
      hasResults: this.model && this.model.hasResults(),
    };
  },
});
