/* eslint-disable react/no-array-index-key */
import { Table, Form } from 'semantic-ui-react';
import { useDatabaseTableCreationContext } from '../Context';
import DatabaseTableCreationColumnButtons, { DatabaseTablesRemoveColumnButtons } from './DatabaseTableCreationColumnButtons';
import DatabaseTableCreationColumnsProperties from './DatabaseTableCreationColumnsProperties';
import { hasError } from '../../../../utilities/validation';
import { validationFields } from '../../../../constants/validation';
import ConfirmWrapper from '../../../../components/modals/ConfirmWrapper';
import PrimaryKeyModal from './modals/PrimaryKeyModal';

const DatabaseTableCreationColumnsFromScratch = () => {
  const { newDatabaseTableState } = useDatabaseTableCreationContext();

  return (
    <>
      {hasError(newDatabaseTableState.validation_errors, validationFields.PRIMARY_KEY) && (
        <ConfirmWrapper size="small">
          <PrimaryKeyModal />
        </ConfirmWrapper>
      )}
      <Form className="aridhia-database-tables-new-columns">
        <Table singleLine compact celled striped unstackable>
          <Table.Header fullWidth>
            <DatabaseTableCreationColumnsProperties />
          </Table.Header>
          <Table.Body className="truncate">
            <Table.Row>
              {newDatabaseTableState.table.columns.map((column, index) => (
                <Table.Cell key={`remove-column-button-cell-${index}`}>
                  <DatabaseTablesRemoveColumnButtons column={column} />
                </Table.Cell>
              ))}
              <Table.Cell>
                <DatabaseTableCreationColumnButtons />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Form>
    </>
  );
};
export default DatabaseTableCreationColumnsFromScratch;
