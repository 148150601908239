import React from 'react';
import {
  Container, Header, List, Segment, Checkbox,
} from 'semantic-ui-react';
import { FEATURE, FEATURES_AVAILABLE_TO_TOGGLE } from '../utilities/features';

const toggle = (e, { label }) => {
  const currentFeatures = FEATURE;
  currentFeatures[label] = !currentFeatures[label];
  sessionStorage.setItem('workspaceFeatures', JSON.stringify(currentFeatures));
  window.location.reload(false);
};

const Features = () => (
  <div className="main_content app-content semantic semantic-body" role="main" aria-label="features" id="uploadtoken">
    <Container className="content">
      <Header as="h1" className="hidden">Features</Header>
      <Segment>
        <Header as="h2">Select features of UI to enable/disable (will trigger page reload)</Header>
        <List divided relaxed>
          {Object.keys(FEATURE).map((key) => {
            if (FEATURES_AVAILABLE_TO_TOGGLE.includes('ALL') || FEATURES_AVAILABLE_TO_TOGGLE.includes(key)) {
              return (
                <List.Item key={key}>
                  <Checkbox toggle checked={FEATURE[key]} label={key} onChange={toggle} />
                </List.Item>
              );
            }
            return true;
          })}
        </List>
      </Segment>
    </Container>
  </div>
);

export default Features;
