import chorus from '../../../chorus';
import _ from '../../../underscore';
import $ from '../../../jquery';
import t from '../../../intl';
import MiniAppsSidebar from './mini_apps_sidebar';
import MiniApps from '../../../collections/mini_apps_and_pods';
import MiniAppsList from '../../../models/mini_apps/list';
import View from '../../loading_view';
import Routing from '../../../mixins/routing';
import mini_app_deprecation_alert from '../../../alerts/mini_app_deprecation_alert';

export default View.include(Routing).extend({

  templateName: 'mini_apps/apps',

  events: {
    'click .app': 'selectApp',
    'click .run-app': 'runApp',
    'click .stop-app': 'stopApp',
    'click .show-all': 'showAll',
  },

  subviews: {
    '.app-sidebarBasic': 'sidebar',
    '.filterMenu': 'filterMenu',
  },

  additionalContext() {
    return {
      apps: this.apps.toJSON(),
      total_apps: this.apps.length,
    };
  },

  collectionModelContext(app) {
    return {
      isSelected: app.id === this.model.get('selected'),
    };
  },

  setup(options) {
    this.tabModel = options.tabModel;
    this.apps = new MiniApps([], {
      workspace: options.model,
    });
    this.workspaceID = options.model.id;
    this.apps.fetch();
    this.collection = this.apps;
    this.model = new MiniAppsList({ apps: this.apps });

    this.setupListeners();
    this.setupSidebar();
  },

  setupListeners() {
    const refetch = () => { this.apps.fetch(); };
    this.listenTo(this.apps, 'sync', this.appsSynced);
    this.listenTo(this.apps, 'appsLoaded', this.appsSynced);
    this.listenTo(this.apps, 'refresh', refetch);
    this.listenTo(chorus.PageEvents, 'mini_app:created', refetch);
    this.listenTo(chorus.PageEvents, 'mini_app:defaulted', refetch);
    this.listenTo(chorus.PageEvents, 'change:miniAppName', refetch);
    this.listenTo(chorus.PageEvents, 'mini_app:deleted', refetch);
    this.listenTo(this.tabModel, 'change:active', (_model, active) => {
      if (active) {
        refetch();
      }
    });
    this.listenTo(this.model, 'change', this.changeSelected);
    this.listenTo(this.apps, 'request', this.spinnerShow);
    this.listenTo(this.apps, 'sync', this.spinnerHide);
  },

  setupSidebar() {
    this.sidebar = new MiniAppsSidebar({
      apps: this.apps,
      workspace: this.options.model,
    });
  },

  spinnerShow() {
    this.$el.find('.spinner').show();
  },

  spinnerHide() {
    this.$el.find('.spinner').hide();
  },

  appsSynced() {
    if (this.apps.length && !this.apps.get(this.model.get('selected'))) {
      this.model.set('selected', this.apps.at(0).id, { silent: true });
    }
    this.render();
    this.changeSelected();
  },

  changeSelected() {
    const selected = this.apps.get(this.model.get('selected'));
    this.sidebar.selectApp(selected);
    if (selected) {
      this.$el.find('.app').removeClass('selected').filter(`[data-id=${selected.id}]`).addClass('selected');
    }
  },

  selectApp(e) {
    const selected = this.model.get('selected');
    const appID = $(e.currentTarget).data('id');

    if (
      selected === appID && e.target.innerText !== t('mini_app.list.stop') && e.target.outerHTML !== '<span></span>'
    ) {
      this.runApp(e, true);
    } else {
      this.model.set('selected', appID);
    }
  },

  runApp(e, openAlert = false) {
    /* openAlert is an optional argument since this function is triggered twice
      when clicking on "Start App". So, only the invocation from selectApp is considered
      for the alert trigger. This is due to .app element having .run-app as child
      considering it as a click on both elements
    */
    e.preventDefault();
    const appID = $(e.currentTarget).data('id');
    let app = this.apps.miniApps.models.filter((model) => model['id'] === appID)[0]
    if (app.attributes.deprecated !== null){
      const deprecation = app.attributes.deprecated;
      const alert = new mini_app_deprecation_alert();
      alert.htmltext = `<a href="${deprecation.url}" target="_blank">${deprecation.url}</a>`;
      if (openAlert)
        alert.launchModal();
    } else {
      this.navigate(`workspaces/${this.workspaceID}/mini_app/${appID}`);
    }

  },

  stopApp(e) {
    e.preventDefault();

    const appID = $(e.currentTarget).data('id');
    const app = this.apps.get(appID);
    app.set({
      workspaceID: this.workspaceID,
      loading: false,
      stopping: true,
      currentStatus: 'stopping',
    });
    this.render();

    const failAction = () => {
      app.set({
        running: false,
        stopping: false,
        statusError: true,
        currentStatus: 'unknown',
      });
      this.render();
      chorus.toast('mini_app.list.stopfailed', {
        toastOpts: { theme: 'bad_activity' },
      });
    };

    app.removeMiniApp()
      .then((response) => {
        if (response.success) {
          app.set({
            running: false,
            stopping: false,
            currentStatus: 'unknown',
            image: '',
            user: '',
            startedByUser: null,
          });
          this.render();
        } else {
          throw new Error();
        }
      })
      .catch(() => failAction());
  },

});
