import { useEffect, useState, useCallback } from 'react';
import { t } from 'i18n-js';
import {
  Header,
  Segment,
} from 'semantic-ui-react';
import NotAuthorised from '../../components/errors/NotAuthorised';
import { usePermissions } from '../../permissions';
import { actions, instances } from '../../permissions/constants';
import AuditTable from './AuditTable';
import { AuditProvider } from '../Context';
import { useAudit } from '../service';
import { PanelHeader, DefaultPanelHeaderIcon } from '../../layout';
import AuditHeaderButtons from './AuditHeaderButtons';

const updateFilters = (column, value) => (filters) => (
  { ...filters, [column]: value }
);

const Audit = ({ panelWidth }) => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [resetCount, setResetCount] = useState(0);
  const [orderBy, setOrderBy] = useState('When');
  const [orderByDirection, setOrderByDirection] = useState('desc');

  const {
    auditRows,
    pagination,
    membersList,
    isFetching,
    refetch,
    isError,
  } = useAudit(page, orderBy, orderByDirection, Object.keys(filters), Object.values(filters));

  useEffect(() => {
    if (page > pagination.total) { setPage(pagination.total); }
  }, [pagination, page]);

  const handleRefreshButton = () => {
    refetch();
    setPage(1);
  };

  const handleFilterChange = useCallback(({ column, value }) => {
    setFilters(updateFilters(column, value));
    setPage(1);
  }, [setFilters, setPage]);

  const handleClearFilters = () => {
    setFilters({});
    setResetCount(resetCount + 1);
    setPage(1);
  };

  const handleSort = ([column, direction]) => {
    const orderDirection = direction === 'descending' ? 'desc' : 'asc';
    setOrderBy(column);
    setOrderByDirection(orderDirection);
  };

  const hasActiveFilters = Object.keys(filters).length > 0;

  if (!usePermissions(actions.READ, instances.AUDIT)) {
    return (<NotAuthorised />);
  }

  const state = {
    auditRows,
    pagination,
    membersList,
    isFetching,
    handleRefreshButton,
    isError,
    page,
    setPage,
    panelWidth,
    handleFilterChange,
    handleClearFilters,
    hasActiveFilters,
    resetCount,
    orderBy,
    orderByDirection,
    handleSort,
  };

  return (
    <AuditProvider value={state}>
      <Segment.Group className="aridhia-panel-wrapper" data-testid="audit-panel">
        <PanelHeader
          headerIcon={<DefaultPanelHeaderIcon name="history" />}
          headerText={<Header as="h3" className="truncate" content={t('audit.audit_panel.header')} title={t('audit.audit_panel.header')} />}
          headerButtons={<AuditHeaderButtons />}
        />
        <Segment
          attached
          as="article"
          className="aridhia-panel-content"
        >
          <AuditTable />
        </Segment>
      </Segment.Group>
    </AuditProvider>
  );
};

export default Audit;
