import chorus from '../../chorus';
import DataTabDatasetColumnList from './data_tab_dataset_column_list_view';
import { toText } from '../../utilities/schema_assoc';
import View from '../loading_view';

export default View.extend({
  constructorName: 'DataTabDatasetView',
  templateName: 'data_tab_dataset',
  tagName: 'li',

  events: {
    'click .toggle_visibility': 'toggleVisibility',
  },

  postRender() {
    this.$el[0].setAttribute('data-fullname', toText(this.model));
    this.$el.data('fullname', toText(this.model));
    this.$el.data('name', this.model.name());
    chorus.page.trigger('resized');
  },

  setup() {
    this.columnsVisible = false;
  },

  teardown(...args) {
    this.$el.qtip('destroy');
    this._super('teardown', args);
  },

  additionalContext() {
    return {
      name: this.model.name(),
    };
  },

  toggleVisibility() {
    if (!this.columnsVisible) {
      this.columnList = this.buildColumnList();
      this.registerSubView(this.columnList);
      this.columnList.render();
    } else {
      this.columnList.teardown(true);
    }
    this.columnsVisible = !this.columnsVisible;
    this.updateArrowIcon();
  },

  updateArrowIcon() {
    const icon = this.columnsVisible ? 'icon-arrow-down' : 'icon-arrow-right';
    this.$('.toggle_visibility').removeClass('icon-arrow-right icon-arrow-down').addClass(icon);
    // We've populated a list, so we need to tell the UI to resize
    // to ensure scrollable lists are displayed correctly.
    chorus.page.trigger('resized');
  },

  buildColumnList() {
    return new DataTabDatasetColumnList({
      el: this.$('.column_list'),
      dataset: this.model,
    });
  },
});
