import { useEffect, useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { useFiles } from '../../service';
import InboxTable from './InboxTable';
import { InboxProvider } from '../Context';
import { sanitiseFolderPath } from '../../../helper';
import { selectedItemsChecker } from '../../../../utilities/helper';
import { usePermissions } from '../../../../permissions';
import { actions, instances } from '../../../../permissions/constants';
import NotAuthorised from '../../../../components/errors/NotAuthorised';
import { PanelHeader, DefaultPanelHeaderIcon } from '../../../../layout';
import InboxBreadcrumbs from './InboxBreadcrumbs';

const Inbox = ({ folderPath }) => {
  const [page, setPage] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const baseFolderPath = sanitiseFolderPath(folderPath);

  const {
    files,
    pagination,
    isFetching,
    isError,
    refetch,
  } = useFiles(baseFolderPath, page, '');

  useEffect(() => {
    if (page > pagination.total) { setPage(pagination.total); }
  }, [pagination, page]);

  const handleRefreshButton = () => {
    refetch();
    setPage(1);
    setSelectedFiles([]);
  };

  const handleSelectedFiles = (e, {
    value, checked, name, filetype,
  }) => {
    setSelectedFiles(selectedItemsChecker(selectedFiles, value, checked, name, files, filetype, null, true));
  };

  const state = {
    files,
    isFetching,
    isError,
    baseFolderPath,
    pagination,
    selectedFiles,
    setSelectedFiles,
    handleRefreshButton,
    handleSelectedFiles,
    setPage,
  };

  if (!usePermissions(actions.VIEW, instances.INBOX)) {
    return (<NotAuthorised />);
  }

  return (
    <InboxProvider state={state}>
      <Segment.Group
        className="aridhia-panel-wrapper"
        data-testid="inbox"
      >
        <PanelHeader
          headerIcon={<DefaultPanelHeaderIcon name="box" />}
          headerText={<InboxBreadcrumbs />}
        />
        <Segment
          attached
          as="article"
          className="aridhia-panel-content"
        >
          <InboxTable />
        </Segment>
      </Segment.Group>
    </InboxProvider>
  );
};

export default Inbox;
