/* eslint-disable react/no-array-index-key */
import { t } from 'i18n-js';
import {
  Modal, Icon, Header, Segment, Table,
} from 'semantic-ui-react';
import { useTablePreview } from '../../service';
import { CancelButton } from '../../../components/buttons/ModalActionButtons';
import { useDatabaseTableContext } from '../../Context';
import RefreshButton from '../../../components/buttons/RefreshButton';
import {
  ColumnsHeader,
  EmptyMessage,
  ErrorComponent,
  TableHeader,
  TableWrapper,
} from '../../../query_table';

const NoDatabaseTableRows = () => (
  <Segment placeholder>
    <Header as="h4" icon>
      <Icon name="table" data-testid="database-table-icon" />
      {t('database_table.no_rows')}
    </Header>
  </Segment>
);

const renderBodyRow = (row, index) => (
  <Table.Row key={`row-${index}`}>
    {row.map((value, j) => (
      <Table.Cell
        className="two-line-truncate"
        key={`row-${index}-cell-${j}`}
      >
        {value}
      </Table.Cell>
    ))}
  </Table.Row>
);

const columns = (columnData) => columnData.map((column) => (
  { name: column.name, content: column.name }
));

const PreviewTableModal = ({ setModalClose }) => {
  const { tableId, tableName } = useDatabaseTableContext();
  const {
    columnsData,
    rowsData,
    isLoading,
    isRefetching,
    refetch,
    isError,
  } = useTablePreview(tableId);

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="eye" title="preview" />
        <Header.Content content={t('database_table.data_preview', { tableName })} />
      </Header>
      <Modal.Content>
        <Segment as="article" className="aridhia-table-wrapper-modal">
          {!isLoading && (
            <TableWrapper
              tableData={rowsData}
              columns={columns(columnsData)}
              tableHeader={(
                <TableHeader
                  columns={columns(columnsData)}
                  errorComponent={<ErrorComponent isError={isError} />}
                  refresh={<RefreshButton isFetching={isRefetching} refetch={refetch} />}
                />
              )}
              columnsHeader={<ColumnsHeader columns={columns(columnsData)} />}
              renderBodyRow={renderBodyRow}
              emptyWrapper={(
                <EmptyMessage
                  isFetching={isRefetching}
                  tableData={rowsData}
                  columns={columns(columnsData)}
                  emptyContent={<NoDatabaseTableRows />}
                />
              )}
            />
          )}
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <CancelButton
          onClose={setModalClose}
          cancelText={t('actions.close')}
        />
      </Modal.Actions>
    </>
  );
};

export default PreviewTableModal;
