import chorus from '../../chorus';
import $ from '../../jquery';
import t from '../../intl';
import Dialog from '../../dialogs/dialogs';

export default Dialog.extend({
  templateName: 'change_password',
  title: t('user.change_password.title'),
  events: {
    'submit form': 'save',
  },
  persistent: true,

  makeModel(...args) {
    this._super('makeModel', args);
    this.model = this.model.clone();
  },

  setup() {
    this.listenTo(this.model, 'saveFailed', this.saveFailed);
    this.listenTo(this.model, 'saved', this.saved);
    this.requiredResources.add(this.model);
    this.model.fetchIfNotLoaded();
  },

  save(e) {
    e.preventDefault();

    const validated = this.model.save(
      {
        password: this.$('input[name=password]').val(),
        passwordConfirmation: this.$('input[name=passwordConfirmation]').val(),
        oldPassword: this.$('input[name=oldPassword]').val(),
        validateUsername: false,
      },
      {

        silent: true, unprocessableEntity: $.noop,
      },

    );
    if (validated) {
      this.$('button.submit').startLoading('actions.saving');
    }
  },

  saved() {
    this.saveCompleted();
    this.closeModal();
  },

  saveFailed() {
    this.showErrors();
    this.$('button.submit').stopLoading();
  },

  saveCompleted() {
    if (this.save) {
      chorus.toast('users.success');
    }
  },


  additionalContext() {
    const currentUserId = chorus.session.user().get('id');
    const currentUserIsAdmin = chorus.session.user().get('admin');
    const changingUserId = this.model.get('id');
    return {
      showOldPassword: !currentUserIsAdmin || currentUserId === changingUserId,
    };
  },

  render() {
    this._super('render');
  },
});
