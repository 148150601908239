import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import View from '../loading_view';

export default View.extend({
  constructorName: 'TabControlView',
  templateName: 'tab_control',

  events: { 'click .tabs li': 'clickTab' },

  setup(tabNames) {
    this.tabNames = tabNames;
  },

  additionalContext() {
    return {
      tabKeys: _.map(this.tabNames, tabName => ({ name: tabName, text: t(`tabs.${tabName}`) })),
    };
  },

  clickTab(evt) {
    this.setSelectedTab($(evt.target));
    if (chorus.page) {
      chorus.page.trigger('resized');
    }
  },

  setSelectedTab(tab) {
    this.$('.tabs li').removeClass('selected');
    tab.addClass('selected');

    const tabName = tab.data('name');
    this.selectedTabName = tabName;
    this.toggleTabbedArea();
    this.trigger(`selected:${tabName}`);
    this.trigger('selected');

    chorus.PageEvents.trigger(`selected:${tabName}`);
  },

  resetSelectedTab() {
    const firstTab = this.$('li:first');
    this.setSelectedTab(firstTab);
  },

  postRender() {
    _.each(this.tabNames, function addTab(tabName) {
      const view = this[tabName];
      if (view) {
        this.registerSubView(view);
        this.$('.tabbed_area').append(view.render().el);
        view.delegateEvents();
      }
    }, this);

    const tab = this.selectedTabName ? this.$(`li[data-name=${this.selectedTabName}]`) : this.$('li:first');
    this.setSelectedTab(tab);
  },

  toggleTabbedArea() {
    _.each(this.tabNames, function hideTab(tabName) {
      const view = this[tabName];
      if (view) $(view.el).addClass('hidden');
    }, this);

    if (this.selectedTabName) {
      const view = this[this.selectedTabName];
      if (view) $(view.el).removeClass('hidden');
    }
  },
});
