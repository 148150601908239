import { t } from 'i18n-js';
import { Header, Segment } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { useTable, invalidateTable } from '../service';
import { renameTab } from '../../../../layout/tabMonitor';
import { DatabaseTableProvider } from '../../../Context';
import Loader from '../../../../components/Loader';
import ContentLoadingError from '../../../../components/errors/ContentLoadingError';
import NotAuthorised from '../../../../components/errors/NotAuthorised';
import { usePermissions } from '../../../../permissions';
import { actions, instances } from '../../../../permissions/constants';
import './DatabaseTable.less';
import LoadingError from '../../../../components/errors/LoadingError';
import DatabaseTableInformation from './DatabaseTableInformation';
import DatabaseTableColumnsTable from './DatabaseTableColumnsTable';
import { sanitiseTableType } from '../../../helper';
import PanelHeader from '../../../../layout/components/PanelHeader';
import DatabaseTableHeaderButtons from './DatabaseTableHeaderButtons';
import TableIcon from '../../../components/TableIcon';

const DatabaseTable = ({ tableId, tabId, panelWidth }) => {
  const {
    table,
    columns,
    isLoading,
    isTableError,
    isColumnsError,
    columnsError,
    tableErrorCode,
    isRefetching,
  } = useTable(tableId);

  const [filterTerm, setFilterTerm] = useState('');

  const tabName = table?.object_name;

  useEffect(() => {
    if (tabName) {
      renameTab(tabId, tabName);
    }
  }, [tabId, tabName]);

  if (!usePermissions(actions.READ, instances.DATABASE)) {
    return (<NotAuthorised />);
  }

  if (isLoading) {
    return (<Loader />);
  }

  if (isTableError) {
    return (<ContentLoadingError errorCode={tableErrorCode} />);
  }

  if (isColumnsError) {
    return (<LoadingError message={columnsError} title={t('database_table.error.title', { tableType: sanitiseTableType(table.object_type) })} />);
  }

  const filterColumns = () => columns.filter((column) => (
    column.name.toLowerCase().includes(filterTerm.toLowerCase())
    || column.data_type.toLowerCase().includes(filterTerm.toLowerCase())
    || column.label?.toLowerCase().includes(filterTerm.toLowerCase())
    || column.description?.toLowerCase().includes(filterTerm.toLowerCase())
  ));

  const filteredColumns = filterTerm ? filterColumns() : columns;

  const handleRefreshButton = () => invalidateTable(tableId);

  const tableName = table.object_name;
  const tableType = table.object_type;

  const state = {
    isColumnsError,
    tableId,
    table,
    tableType,
    tableName,
    columns: filteredColumns,
    isRefetching,
    handleRefreshButton,
    filterTerm,
    setFilterTerm,
    panelWidth,
  };

  return (
    <DatabaseTableProvider table={state}>
      <Segment.Group className="aridhia-panel-wrapper" data-testid="database-table">
        <PanelHeader
          headerIcon={<TableIcon tableType={tableType} size="large" color="blue" />}
          headerText={<Header as="h3" className="truncate" content={tableName} title={tableName} />}
          headerButtons={<DatabaseTableHeaderButtons />}
        />
        <Segment
          attached
          as="article"
          className="aridhia-panel-content"
        >
          <DatabaseTableInformation />
          <DatabaseTableColumnsTable />
        </Segment>
      </Segment.Group>
    </DatabaseTableProvider>
  );
};

export default DatabaseTable;
