import t from '../intl';
import ModelDelete from './model_delete_alert';

export default ModelDelete.extend({
  constructorName: 'TagDelete',

  text: t('tag.delete.text'),
  ok: t('tag.delete.button'),
  deleteMessage: 'tag.delete.toast',

  setup() {
    this.title = t('tag.delete.title', this.deleteMessageParams());
  },

  deleteMessageParams() {
    return { tagName: this.model.get('name') };
  },
});
