import _ from '../../underscore';
import $ from '../../jquery';
import TextWorkfileContent from './text_workfile_content_view';
import ResultsConsole from './results_console_view';
import VerifyChorusView from '../../dialogs/dataset/verify_chorus_view_dialog';
import ChorusView from '../../models/chorus_view';
import WorkfileExecutionTask from '../../models/tasks/workfile_execution_task';
import { workspace } from '../../utilities/workspace_assoc';
import View from '../loading_view';

export default View.extend({
  templateName: 'sql_workfile_content',
  bindCallbacks: $.noop,

  subviews: {
    '.app-textContent': 'textContent',
    '.app-resultsConsole': 'resultsConsole',
  },

  setup(options, ...args) {
    this._super('setup', args);
    this.autoRun = options.action === 'run';
    this.textContent = new TextWorkfileContent({
      model: this.model, hotkeys: this.hotkeys(), ref: this.model.id, tab: this.options.tab,
    });
    this.resultsConsole = new ResultsConsole({
      enableResize: true,
      enableExpander: true,
      saveActions: true,
    });
  },

  hotkeys() {
    if (workspace(this.model).canUpdate() && !this.model.isInbox()) {
      return {
        r: _.bind(this.runInDefault, this),
        e: _.bind(this.runSelected, this),
      };
    }
    return null;
  },

  postRender() {
    this._super('postRender');
    const content = this.model.content();
    if (this.autoRun && content != null) {
      this.autoRun = false;
      this.runInDefault({ content });
    }
  },

  runSelected() {
    if (this.getSelectedText() !== '') {
      const runOptions = { selection: true };
      const schema = this.model.executionSchema();
      if (schema) {
        runOptions.schemaId = schema.get('id');
      }
      this.run(runOptions);
    }
  },

  runInDefault(options) {
    const opts = options || {};
    if (!this.model.executionSchema()) return;
    this.run(_.extend(opts, {
      schemaId: this.model.executionSchema().get('id'),
    }));
  },

  run(options) {
    const opts = options || {};
    if (this.executing) {
      return;
    }
    if (opts.selection) {
      opts.selection = this.getSelectedText();
    } else if (opts.content) {
      opts.selection = opts.content;
    }
    this.createTask(opts.taskClass || WorkfileExecutionTask);
    this.executing = true;
    this.task.set({
      sql: opts.selection ? opts.selection : this.textContent.editor.getValue(),
      workfile: this.model,
      schemaId: opts.schemaId,
      checkId: (new Date().getTime().toString()),
      numOfRows: opts.numOfRows,
    }, { silent: true });

    this.task.save({}, { method: 'create' });
  },

  createTask(TaskClass) {
    if (this.task) {
      this.stopListening(this.task, 'saved', this.executionSucceeded);
      this.stopListening(this.task, 'saveFailed', this.executionFailed);
    }
    this.task = new TaskClass({ });
    this.listenTo(this.task, 'saved', this.executionSucceeded);
    this.listenTo(this.task, 'saveFailed', this.executionFailed);
    this.resultsConsole.setModel(this.task);
  },

  newChorusView() {
    return this.newChorusViewWithContent(this.textContent.editor.getValue(), {
      editSQL: false, previewData: false, previewBar: false, toast: true,
    });
  },

  newSelectionChorusView() {
    return this.newChorusViewWithContent(this.getSelectedText(), {
      editSQL: false, previewData: false, previewBar: false, toast: true,
    });
  },

  replaceCurrentVersion() {
    this.textContent.replaceCurrentVersion();
  },

  setEditorOption(option, value) {
    this.textContent.setEditorOption(option, value);
  },

  createNewVersion() {
    this.textContent.createNewVersion();
  },

  createNewFile() {
    this.textContent.createNewFile();
  },

  newChorusViewWithContent(content, flags) {
    const executionSchema = this.model.executionSchema();

    this.chorusView = new ChorusView({
      sourceObjectId: this.model.id,
      sourceObjectType: 'workfile',
      workspace: this.model.get('workspace'),
      query: content,
    });
    this.chorusView.sourceObject = this.model;
    this.chorusView.setSchema(executionSchema);

    const dialog = new VerifyChorusView({ model: this.chorusView, flags });
    dialog.launchModal();
  },

  newWorkfile(content, options) {
    this.textContent.newWorkfile(content, options);
  },

  getSelectedText() {
    const editor = this.textContent.editor;
    const sel = editor.getSelection();
    if (sel.isEmpty()) {
      return '';
    }
    const txt = editor.getSession().doc.getTextRange(sel.getRange());
    return txt;
  },

  executionSucceeded() {
    this.executing = false;
  },

  executionFailed() {
    this.executing = false;
  },
});
