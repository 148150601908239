import { t } from 'i18n-js';
import { Table } from 'semantic-ui-react';
import { TableHeader } from '../../../../query_table';
import ProgressBar from '../../../../components/ProgressBar';
import { calculateIsNarrow } from '../../../../helpers/panel_widths';
import { useDatabaseTableContext } from '../../../Context';

const DatabaseTableColumnsTableHeader = ({
  columns,
  errorComponent,
  filter,
  refresh,
}) => {
  const { table, panelWidth } = useDatabaseTableContext();
  const importing = (table.import_progress && table.import_progress < 100);
  const isNarrow = calculateIsNarrow(panelWidth);

  if (isNarrow) {
    return (
      <Table.Row aria-label={t('table.accessibility.table_header_row')}>
        <Table.HeaderCell className="aridhia-non-sortable-header-cell">
          {importing && <ProgressBar progress={Math.round(table.import_progress)} />}
          {!importing && (
            <>
              {errorComponent}
              <div className="float-right">
                {filter}
                {refresh}
              </div>
            </>
          )}
        </Table.HeaderCell>
      </Table.Row>
    );
  }

  if (table.import_progress && table.import_progress < 100) {
    return (
      <Table.Row aria-label={t('table.accessibility.table_header_row')}>
        <Table.HeaderCell colSpan={columns.length} className="aridhia-non-sortable-header-cell">
          <ProgressBar progress={Math.round(table.import_progress)} />
        </Table.HeaderCell>
      </Table.Row>
    );
  }

  return (
    <TableHeader
      columns={columns}
      errorComponent={errorComponent}
      filter={filter}
      refresh={refresh}
    />
  );
};

export default DatabaseTableColumnsTableHeader;
