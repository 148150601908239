export const statuses = Object.freeze({
  STARTING: 'starting',
  RUNNING: 'running',
  STOPPING: 'stopping',
  STOPPED: 'stopped',
  DEALLOCATING: 'deallocating',
  DEALLOCATED: 'deallocated',
  PAUSING: 'pausing',
  PAUSED: 'paused',
  UNKNOWN: 'unknown',
});

export const supportedVirtualMachineTypes = window?.customizationVariables?.supportedVirtualMachineTypes
  || Object.freeze({
    LINUX: 'Linux',
    WINDOWS: 'Windows',
    LINUX_DATA_SCIENCE_IMAGE: 'Linux Data Science image',
    WINDOWS_DATA_SCIENCE_IMAGE: 'Windows Data Science image',
  });

export const operationToStatusMapping = Object.freeze({
  deallocate_vm: statuses.DEALLOCATING,
  start_vm: statuses.STARTING,
});

export const statusesIndicatingChange = Object.freeze([
  statuses.STARTING, statuses.STOPPING, statuses.DEALLOCATING, statuses.PAUSING, statuses.UNKNOWN,
]);
