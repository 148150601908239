import {
  encode,
  specialEncodeFolderPaths,
  virtualMachineOsShortName,
  virtualMachineRandomIdentifier,
} from '../../utilities/helpers';
import { supportedTableTypes } from '../database_tables/constants';
import { workspaceId } from '../layout/helper';

export const workspaceUrl = () => `#/workspaces/${workspaceId()}`;
const panelUrl = (name) => () => `#/workspaces/${workspaceId()}/${name}`;

// Ribbon links
export const workspaceHomeUrl = panelUrl('home');
export const workspaceSummaryUrl = panelUrl('summary');
export const workspaceFoldersUrl = panelUrl('folders');
export const workspaceFilesUrl = panelUrl('folders/files');
export const workspaceBlobsUrl = panelUrl('folders/blobs');
export const workspaceInboxUrl = panelUrl('folders/inbox');
export const workspaceDatabaseUrl = panelUrl('datasets');
export const workspaceSQLFilesUrl = panelUrl('folders/files/?name_pattern=.sql');
export const workspaceRToolsUrl = panelUrl('rtools');
export const workspaceAppsUrl = panelUrl('apps');
export const workspaceAppUrl = (appId) => `${workspaceUrl()}/app/${appId}`;
export const workspaceShinyAppsUrl = panelUrl('mini_apps');
export const workspaceDesktopsUrl = panelUrl('desktops');
export const workspaceUploadTokenUrl = panelUrl('upload_token');
export const workspaceAuditUrl = panelUrl('audit');
export const secretsUrl = panelUrl('secrets');
export const portalUIEditUrl = (portalUrl, workspaceUuid) => `${portalUrl}/#/workspaces/${workspaceUuid}/edit`;

// Files
export const workspaceFileListElementUrl = (fileId, fileType, folder, fileName) => {
  switch (fileType) {
    case 'folder':
      return `${workspaceUrl()}/folders/${specialEncodeFolderPaths(folder)}/${encode(fileName)}`;
    default:
      return `${workspaceUrl()}/workfiles/${fileId}`;
  }
};
export const openFileAsText = (fileId) => `${workspaceFileListElementUrl(fileId, '', '', '')}/text`;
export const openFileEditData = (fileId) => (`${workspaceUrl()}/datatable/file/${fileId}`);
export const openFileAnalyseData = (fileId) => (`${openFileEditData(fileId)}/analyse`);
export const runSQL = (fileId, fileType) => `${workspaceFileListElementUrl(fileId, fileType)}/run`;
export const runR = (fileId) => `${workspaceRToolsUrl()}/${fileId}`;
export const runOffice = (fileId) => `${workspaceUrl()}/workfiles/${fileId}/office`;

export const airlockFile = (fileId) => (`${workspaceUrl()}/airlock_new/workfile/${fileId}`);
export const uploadFile = (destination) => (`${workspaceUrl()}/upload/${+new Date()}/${destination}`);
export const activityAttachmentFileUrl = (activityId, attachmentId) => (
  `${workspaceUrl()}/activity/${activityId}/attachment/${attachmentId}`
);

// Database
export const databaseTableElementUrl = (tableId, tableType) => {
  if (tableType === supportedTableTypes.CHORUS_VIEW) {
    return `${workspaceUrl()}/custom_views/${tableId}`;
  }

  if (tableType === supportedTableTypes.VIEW) {
    return `${workspaceUrl()}/database_views/${tableId}`;
  }

  return `${workspaceUrl()}/datasets/${tableId}`;
};
export const airlockDatabaseTable = (tableId) => (`${workspaceUrl()}/airlock_new/dataset/${tableId}`);
export const editDatabaseTableMetadata = (tableId) => (`${workspaceUrl()}/dataset_metadata/${tableId}`);
export const analyseDatabaseTableData = (tableId) => (`${workspaceUrl()}/datatable/dataset/${tableId}/analyse`);
export const newDatabaseTableUrl = (resource) => (`${workspaceUrl()}/datatable/new/${encodeURIComponent(resource)}`);
export const newView = (tableId) => `${workspaceUrl()}/datasets/${tableId}/new_view`;
export const newDatabaseTablePanel = () => (`${workspaceUrl()}/database_tables/new`);
export const newDatabaseTablePanelFromCSV = (fileId) => (`${workspaceUrl()}/database_tables/new/${fileId}`);

// Tools
export const virtualMachineUrl = (
  virtualMachineName,
  virtualMachineOS,
  isDesktopButton = false,
) => {
  const random = isDesktopButton ? 'aaaaa' : virtualMachineRandomIdentifier();
  const os = virtualMachineOsShortName(virtualMachineOS);
  const vmIdentifier = `${virtualMachineName}-${os}-${random}`;
  const suffix = isDesktopButton && virtualMachineOS === 'Linux' ? '/vnc' : '/';
  return `${workspaceUrl()}/desktop/${vmIdentifier}${suffix}`;
};
