import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import DatasetSidebar from './dataset_sidebar';
import DatasetDelete from '../../alerts/dataset_delete_alert';
import MainContentList from '../core/main_content_list_view';
import EditTags from '../../dialogs/tags/edit_tags_dialog';
import MultipleSelectionSidebarMenu from '../core/multiple_selection_sidebar_menu';
import View from '../loading_view';
import { FEATURE } from '../../../utilities/features';
import Routing from '../../mixins/routing';

export default View.include(Routing).extend({

  bindCallbacks: $.noop,
  subviews: {
    '.app-content': 'mainContent',
    '.app-sidebarBasic': 'sidebar',
    '.app-sidebarMultiple': 'multiSelectSidebarMenu',
  },

  events: {
    'click a[href="#refresh"]': 'fetchCollection',
  },

  setup(options) {
    this.className = 'app-datasetsShow';
    this.templateName = 'tab_content';
    
    this.collection = options.model.datasets();
    this.collection.sortAsc('objectName');
    this.collection.fetchAllIfNotLoaded();

    this.workspace = options.model;

    this.subscribePageEvent('note:deleted', function refresh() {
      this.model.trigger('invalidated');
    });

    this.subscribePageEvent('csv_import:started', this.fetchCollection);

    this.subscribePageEvent('datasets:changed', this.fetchCollection);

    this.subscribePageEvent('dataset:deleted', this.reloadandFetch);

    this.subscribePageEvent('datasetmeta:changed', this.fetchCollection);

    this.listenTo(this.collection, 'searched', function searchComplete() {
      this.mainContent.content.render();
      this.mainContent.contentFooter.render();
      this.mainContent.contentDetails.updatePagination();
    });

    const onTextChangeFunction = _.debounce(_.bind(function searchFor(e) {
      this.mainContent.contentDetails.startLoading('.count');
      this.collection.search($(e.target).val());
    }, this), 300);

    this.buildSidebar();

    this.listenTo(this.collection, 'dataset:selected', this.setModel);
    this.listenTo(this.collection, 'dataset:checked', this.setCheckedModel);

    this.mainContent = new MainContentList({
      modelClass: 'Dataset',
      collection: this.collection,
      model: this.workspace,
      useCustomList: true,
      title: t('dataset.title'),
      contentDetailsOptions: { multiSelect: true },
      search: {
        placeholder: t('workspace.search'),
        onTextChange: onTextChangeFunction,
      },
      linkMenus: {
        sort: {
          title: t('datasets.header.menu.sort.title'),
          options: [
            { data: 'alpha', text: t('datasets.header.menu.sort.alphabetically') },
            { data: 'date', text: t('datasets.header.menu.sort.by_date') },
          ],
          event: 'sort',
        },
      },
    });

    this.mainContent.contentHeader.bind('choice:filter', function filterOn(choice) {
      this.collection.attributes.type = choice;
      this.collection.fetch();
    }, this);

    this.mainContent.contentHeader.bind('choice:sort', function sortOn(choice) {
      const field = choice === 'alpha' ? 'name' : 'createdAt';
      this.collection.sortAsc(field);
      this.collection.fetchAll();
    }, this);

    this.sidebar = new DatasetSidebar({ workspace: this.workspace, listMode: true });

    this.onceLoaded(this.model, this.workspaceLoaded);
  },

  // This prevents a 422 on a single Dataset from redirecting the entire page.
  unprocessableEntity: $.noop,

  fetchCollection(e) {
    if (e) { e.preventDefault(); }
    this.reloadandFetch();
  },

  reloadandFetch() {
    this.$('.action_bar_primary .rsh-loading').first().removeClass('hidden');
    this.collection.fetch({ success() { chorus.PageEvents.trigger('datasets:deselectAll'); } });
  },

  workspaceLoaded() {
    this.mainContent.contentHeader.options.sandbox = this.workspace.sandbox();

    if (!this.workspace.isArchive()) {
      this.mainContent.content.options.hasActiveWorkspace = true;
    }
  },

  sidebarMultiselectActions() {
    const actions = [];
    if (this.workspace.canAirlock()) {
      actions.push('<a class="workfile_download btn">{{t "workspace.airlock"}}</a>');
    }
    if(this.workspace.canUpdate()){
      actions.push('<a class="delete app-alert btn btn-warning">{{t "dataset.delete_multiple.button"}}</a>');
    }
    return actions;
  },

  sidebarMultiselectActionEvents() {
    const actionEvents = {
      'click .edit_tags': _.bind(function editTags() {
        new EditTags({ collection: this.multiSelectSidebarMenu.selectedModels }).launchModal();
      }, this),
    };
    if (this.workspace.canAirlock()) {
      actionEvents['click .workfile_download'] = _.bind(function startAirlock() {
        this.workspace.trigger('airlock', this.multiSelectSidebarMenu.selectedModels);
        if (FEATURE.NEW_UX) {
          const datasetList = this.multiSelectSidebarMenu.selectedModels.models.map(a => a.id).join(',');
          this.navigate(`workspaces/${this.workspace.id}/airlock_new/dataset/${datasetList}`);
        }
      }, this);
    }
    if (this.workspace.canUpdate()) {
      actionEvents['click .delete'] = _.bind((e) => {
        if (e) { e.preventDefault(); }
        new DatasetDelete({ model: this.multiSelectSidebarMenu.selectedModels }).launchModal();
      }, this);
    }
    return actionEvents;
  },

  buildSidebar() {
    this.multiSelectSidebarMenu = new MultipleSelectionSidebarMenu({
      collection: this.collection,
      selectEvent: 'dataset:checked',
      actions: this.sidebarMultiselectActions(),
      actionEvents: this.sidebarMultiselectActionEvents(),
    });
  },

  setModel(dataset) {
    this.model = dataset;
    this.dataset = dataset;
    this.sidebar.setDataset(dataset);
  },

  setCheckedModel() {
    const actions = [];
    const actionEvents = {};
    const datasets = this.multiSelectSidebarMenu.selectedModels;
    if (datasets.length === 1) {
      this.setModel(datasets.models[0]);
    }
  },
});
