import React from 'react';
import {
  Container, Form, Header, Grid, Modal, Message
} from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';
import FormValidFrom from './FormValidFrom';
import FormValidTo from './FormValidTo';
import FormPublicInbox from './FormPublicInbox';
import FormDataUpload from './FormDataUpload';
import FormReason from './FormReason';
import { SaveButton } from '../Buttons';
import FormDestination from './FormDestination';

const CreateButtonStateless = ({createToken, minimumDataEntryMet }) => (
  <Modal
    trigger={<SaveButton minimumDataEntryMet={minimumDataEntryMet} saveText={t('upload_token.actions.create')} />}
    header={t('upload_token.upload_form.confirm')}
    content={t('upload_token.upload_form.confirm_text')}
    actions={[t('actions.cancel'), {
      key: 'confirm', content: t('actions.confirm'), positive: true, onClick: createToken,
    }]}
    className="confirm"
    size="tiny"
    mountNode={document.querySelector('#modal-wrapper') || document.body}
  />
);

const buttonPropsMapping = ({ uploadToken }) => ({ minimumDataEntryMet: uploadToken.get('readyToUpload') });
const buttonEventOptions = {
  debounce: true,
  events: {
    uploadToken: ['change:readyToUpload']
  },
};

const CreateButton = connectBackboneToReact(buttonPropsMapping, buttonEventOptions)(CreateButtonStateless);

const FormContent = ({error, publicInboxVisible, createToken, canSetDestination}) => {
  return (
    <Container>
      <Form autoComplete="off">
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <Header as="h2" size="medium">
                {t('upload_token.upload_form.title')}
              </Header>
              {error
                && (
                  <Message
                    negative
                    content={t('upload_token.upload_form.error')}
                  />
                )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <FormValidFrom />
            </Grid.Column>
            <Grid.Column>
              <FormValidTo />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>

          <Grid.Column>
              {publicInboxVisible && (
                <FormPublicInbox />
            )}
              <FormDataUpload />
            {canSetDestination && (
                <FormDestination />
            )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <FormReason />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {`${t('upload_token.upload_form.info_start')} `}
              <a href={t('url.knowledgebase_uploading_files_via_an_api')} target="_blank" rel="noreferrer">{t('upload_token.upload_form.info_link')}</a>
              {` ${t('upload_token.upload_form.info_end')}`}
            </Grid.Column>
            <Grid.Column textAlign="right">
              <CreateButton createToken={createToken} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Container>
  );
}

const mapModelsToProps = ({
  uploadToken, publicInboxVisible,
}) => ({
  error: uploadToken.get('error'),
  publicInboxVisible,
});

const options = {
  debounce: true,
  events: {
    uploadToken: ['change:error']
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(FormContent);
