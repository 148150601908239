import { useState } from 'react';
import { t } from 'i18n-js';
import {
  Modal, Form, Icon, Header, Message, Button,
} from 'semantic-ui-react';
import ModalActionButtons from '../buttons/ModalActionButtons';
import HeaderError from '../errors/HeaderError';
import FormValidationError from '../errors/FormValidationError';
import {
  findErrors,
  hasError,
  invalidCharacters,
  invalidCharactersIn,
  invalidFileNames,
} from '../../utilities/validation';
import { validationLengths, validationFields } from '../../constants/validation';
import { useNewFile, invalidateFiles } from '../../files/panels/service';
import { workspaceId } from '../../layout/helper';

export const NewFolderSubmodalButton = (
  <Button
    content={t('file_list.actions.new_folder')}
    aria-label={t('file_list.actions.new_folder')}
    className="margin-left-none"
    primary
    floated="left"
    icon="folder"
    labelPosition="left"
  />
);

const NewFolderSubmodal = ({
  destinationFolder,
  handleSetFolder,
  setModalClose,
  setIsBusy,
}) => {
  const [folderName, setFolderName] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);

  const isValidEntry = !!folderName && !!destinationFolder;

  const handleNameChange = (e, { value }) => {
    setFolderName(value);
    const newErrors = validationErrors.filter((err) => err.field !== validationFields.NAME);
    setValidationErrors(newErrors);
  };

  const checkForErrors = (name) => {
    const errors = [];
    if (!name) {
      errors.push({
        field: 'name',
        message: (t('field_error.BLANK', { field: t('modals.new_folder.name') })),
      });
    }

    if (name?.length > validationLengths.NAME) {
      errors.push({
        field: 'name',
        message: (t('field_error.TOO_LONG', { field: t('modals.new_folder.name'), count: validationLengths.NAME })),
      });
    }

    if (name && invalidCharactersIn(name)) {
      errors.push({
        field: 'name',
        message: (
          t('field_error.INVALID_CHARACTERS', {
            field: t('modals.new_folder.name'),
            characters: invalidCharacters,
          })),
      });
    }

    if (name && invalidFileNames(name)) {
      errors.push({
        field: 'name',
        message: (t('field_error.INVALID_NAME')),
      });
    }
    return errors;
  };

  const {
    createNewFile,
    isLoading,
    isError,
    error,
    resetResponse,
  } = useNewFile();

  const handleModalClose = () => {
    setFolderName('');
    setModalClose();
    resetResponse();
  };

  const handleSubmitting = () => {
    setIsBusy(true);
    const errors = checkForErrors(folderName);
    setValidationErrors(errors);

    if (errors.length > 0) { return; }

    const folderData = {
      workfile: {
        workspace: {
          id: workspaceId(),
        },
        file_name: folderName,
        folder: destinationFolder,
        source: 'empty',
        entity_subtype: 'folder',
        content_type: 'folder',
      },
    };

    createNewFile(folderData, {
      onSuccess: () => {
        invalidateFiles();
        handleSetFolder(`${destinationFolder}/${folderName}`);
        handleModalClose();
      },
      onSettled: () => {
        setIsBusy(false);
      },
    });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="folder" title="folder" />
        <Header.Content content={t('modals.new_folder.title')} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label htmlFor="folder">
              {t('modals.new_folder.name')}
              {hasError(validationErrors, validationFields.NAME) && (
                <FormValidationError errorMsg={(findErrors(validationErrors, validationFields.NAME)[0]).message} />
              )}
            </label>
            <Form.Input
              id="name"
              autoFocus
              placeholder={t('modals.new_folder.name_placeholder')}
              maxLength={validationLengths.NAME}
              value={folderName}
              onChange={handleNameChange}
              error={hasError(validationErrors, validationFields.NAME)}
            />
          </Form.Field>
        </Form>
        <Message
          size="small"
          info
        >
          {t('modals.new_folder.recap')}
          <strong className="overflow-wrap">
            {destinationFolder}
          </strong>
        </Message>
      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmitting}
        isValidEntry={isValidEntry}
        error={validationErrors.length > 0}
        submitText={t('actions.create_folder')}
      />
    </>
  );
};

export default NewFolderSubmodal;
