import Model from './models';

const humanTypeMap = {
  WHOLE_NUMBER: 'numeric',
  REAL_NUMBER: 'numeric',
  STRING: 'string',
  LONG_STRING: 'string',
  BINARY: 'binary',
  BOOLEAN: 'boolean',
  DATE: 'date',
  TIME: 'time',
  DATETIME: 'date_time',
  OTHER: 'other',
};

export default Model.extend({
  constructorName: 'DatabaseColumn',
  urlTemplate() {
    return ['api', 'workspaces', 'datasets', this.dataset.id, 'columns'].join('/');
  },

  urlParams() {
    return {
      filter: this.get('name'),
    };
  },

  initialize() {
    this.set({
      typeClass: humanTypeMap[this.get('typeCategory')],
      typeString: this.get('dataType').replace(/numeric/gi, 'decimal'),
    });
  },

  toText() {
    return this.safePGName(this.get('name'));
  },

  quotedName() {
    return this.dataset &&
            this.get('name') &&
            this.safePGName(this.dataset.selectName(), this.get('name'));
  },
});
