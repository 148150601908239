import { t } from 'i18n-js';
import {
  Modal, Icon, Header, Message,
} from 'semantic-ui-react';
import ModalActionButtons from '../../../../../components/buttons/ModalActionButtons';
import HeaderError from '../../../../../components/errors/HeaderError';
import { useDelete } from '../../../service';
import toastMessage from '../../../../../utilities/toast';
import { useInboxFileContext } from '../../Context';

const InboxRejectModal = ({ setModalClose, setIsBusy }) => {
  const {
    displayName, id, folder, type,
  } = useInboxFileContext();
  const fileOrFolder = type === 'folder' ? 'folder' : 'file';

  const headerContent = type === 'folder'
    ? t('file_list.actions.reject_folder')
    : t('file_list.actions.reject_file');

  const {
    deleteFile,
    isLoading,
    isError,
    error,
    resetResponse,
  } = useDelete(id, folder.startsWith('public inbox'));

  const handleModalClose = () => {
    setModalClose(false);
    resetResponse();
  };

  const handleSubmitting = () => {
    setIsBusy(true);
    deleteFile(null, {
      onSuccess: () => {
        handleModalClose();
        toastMessage('success', t('file_list.actions.reject_success', { file: displayName }), 'rejectSuccess');
      },
      onSettled: () => {
        setIsBusy(false);
      },
    });
  };
  const isValidEntry = !!id;

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="thumbs down" data-testid="reject-icon" title="reject" />
        <Header.Content content={headerContent} />
        {isError && (
        <HeaderError
          error={error}
          onDismiss={() => resetResponse()}
        />
        )}
      </Header>
      <Modal.Content>
        <Message
          icon="warning circle"
          warning
          content={t('file_list.actions.reject_warning')}
        />
        <p>
          {t('file_list.actions.reject_sure', { fileOrFolder, fileName: displayName })}
        </p>
      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmitting}
        isValidEntry={isValidEntry}
        error={!!error}
        submitText={t('actions.reject')}
        negativeSentiment
      />
    </>
  );
};

export default InboxRejectModal;
