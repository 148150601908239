import { t } from 'i18n-js';

export const steps = Object.freeze({
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  FOURTH: 4,
  LAST: 5,
});

export const columnTypes = Object.freeze({
  TEXT: 'text',
  INTEGER: 'integer',
  DECIMAL: 'decimal',
  BOOLEAN: 'boolean',
  DATE: 'date',
  TIMESTAMP: 'timestamp',
  TIMESTAMPTZ: 'timestamptz',
  TIME: 'time',
  TIMETZ: 'timetz',
});

export const separatorTypes = Object.freeze({
  COMMA: ',',
  TAB: '\t',
  SEMICOLON: ';',
  SPACE: ' ',
  OTHER: 'other',
});

export const encodingTypes = Object.freeze({
  UTF8: 'utf-8',
  ISO88591: 'iso-8859-1',
  WINDOWS1252: 'windows-1252',
});

export const typeOptions = [
  { key: 'text', value: columnTypes.TEXT, text: t('database_tables_new.columns.column_types.text') },
  { key: 'integer', value: columnTypes.INTEGER, text: t('database_tables_new.columns.column_types.integer') },
  { key: 'decimal', value: columnTypes.DECIMAL, text: t('database_tables_new.columns.column_types.decimal') },
  { key: 'boolean', value: columnTypes.BOOLEAN, text: t('database_tables_new.columns.column_types.boolean') },
  { key: 'date', value: columnTypes.DATE, text: t('database_tables_new.columns.column_types.date') },
  { key: 'timestamp', value: columnTypes.TIMESTAMP, text: t('database_tables_new.columns.column_types.timestamp') },
  { key: 'timestamptz', value: columnTypes.TIMESTAMPTZ, text: t('database_tables_new.columns.column_types.timestamptz') },
  { key: 'time', value: columnTypes.TIME, text: t('database_tables_new.columns.column_types.time') },
  { key: 'timetz', value: columnTypes.TIMETZ, text: t('database_tables_new.columns.column_types.timetz') },
];

export const separatorOptions = [
  { key: 'comma', value: separatorTypes.COMMA, text: t('database_tables_new.columns.metadata.separators.comma') },
  { key: 'tab', value: separatorTypes.TAB, text: t('database_tables_new.columns.metadata.separators.tab') },
  { key: 'semicolon', value: separatorTypes.SEMICOLON, text: t('database_tables_new.columns.metadata.separators.semicolon') },
  { key: 'space', value: separatorTypes.SPACE, text: t('database_tables_new.columns.metadata.separators.space') },
  { key: 'other', value: separatorTypes.OTHER, text: t('database_tables_new.columns.metadata.separators.other') },
];

export const encodingOptions = [
  { key: 'utf-8', value: encodingTypes.UTF8, text: t('database_tables_new.columns.metadata.encodings.utf-8') },
  { key: 'iso-8859-1', value: encodingTypes.ISO88591, text: t('database_tables_new.columns.metadata.encodings.iso-8859-1') },
  { key: 'windows-1252', value: encodingTypes.WINDOWS1252, text: t('database_tables_new.columns.metadata.encodings.windows-1252') },
];
