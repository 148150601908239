/* eslint-disable camelcase */
// TODO: Updade customisation to use Camelcase
import { t } from 'i18n-js';
import { Dropdown, Icon, Menu } from 'semantic-ui-react';
import { useCustomisationContext } from '../../customisation/Context';
import { signout } from '../../../application/auth/oauth';
import UserInfo from './UserInfo';
import { FEATURE } from '../../../utilities/features';
import ModalWrapper from '../modals/ModalWrapper';
import AboutModal from '../modals/AboutModal';

const PrivacyDropdownItem = () => (
  <Dropdown.Item
    href={t('url.knowledgebase_privacy')}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Icon data-testid="privacy-option-icon" name="shield" />
    {t('header.privacy')}
  </Dropdown.Item>
);

const CookiePolicyDropdownItem = () => (
  <Dropdown.Item
    href={t('url.cookie_policy')}
    target="_blank"
    rel="noopener noreferrer"
  >
    <i className="cookie icon" data-testid="cookie-option-icon" />
    {t('header.cookie_settings')}
  </Dropdown.Item>
);

const TermsDropdownItem = () => (
  <Dropdown.Item
    href={t('url.knowledgebase_terms')}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Icon data-testid="terms-option-icon" name="handshake" />
    {t('header.terms')}
  </Dropdown.Item>
);

const AboutDropdownItem = (
  <Dropdown.Item>
    <Icon data-testid="about-option-icon" name="info circle" />
    {t('header.about')}
  </Dropdown.Item>
);

const SupportDropdownItem = () => (
  <Dropdown.Item
    href={t('url.knowledgebase_service_desk')}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Icon data-testid="support-option-icon" name="commenting" />
    {t('header.support')}
  </Dropdown.Item>
);

const FeedbackDropdownItem = ({ feedbackUrl }) => (
  <Dropdown.Item
    href={feedbackUrl}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Icon data-testid="feedback-option-icon" name="bullhorn" />
    {t('header.feedback')}
  </Dropdown.Item>
);

const LogoutDropdownItem = () => (
  <Dropdown.Item onClick={signout}>
    <Icon data-testid="logout-option-icon" name="sign out" />
    {t('header.sign_out')}
  </Dropdown.Item>
);

const ProfileDropdown = () => {
  const { feedbackUrl } = useCustomisationContext();

  return (
    <Menu.Item id="aridhia-profile-dropdown">
      <Dropdown
        icon={null}
        trigger={<UserInfo />}
      >
        <Dropdown.Menu>
          <PrivacyDropdownItem />
          {FEATURE.SITE_TRACKING_ENABLED && <CookiePolicyDropdownItem />}
          <TermsDropdownItem />
          <ModalWrapper trigger={AboutDropdownItem}>
            <AboutModal />
          </ModalWrapper>
          <SupportDropdownItem />
          {feedbackUrl && <FeedbackDropdownItem feedbackUrl={feedbackUrl} />}
          <Dropdown.Divider />
          <LogoutDropdownItem />
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  );
};

export default ProfileDropdown;
