import chorus from '../chorus';
import t from '../intl';
import Backbone from '../vendor/backbone';

export const DataSourceCredentialsModel = {
  dataSourceRequiringCredentials() {
    this.invalidCredentials = (this.serverErrors.record === 'INVALID_CREDENTIALS');
    return new chorus.models.DynamicDataSource(this.serverErrors.modelData);
  },
};

export const DataSourceCredentialsPage = {
  unauthorizedErrorPageOptions() {
    return {
      title: t('data_sources.shared_account_invalid.title'),
      text: t('data_sources.shared_account_invalid.text'),
    };
  },

  dependentResourceForbidden(resource, ...args) {
    const dataSource = resource.dataSourceRequiringCredentials && resource.dataSourceRequiringCredentials();
    if (dataSource) {
      chorus.pageOptions = this.unauthorizedErrorPageOptions();
      Backbone.history.loadUrl('/forbidden');
    } else {
      this._super('dependentResourceForbidden', [resource, ...args]);
    }
  },
};
