import * as d3 from 'd3';
import chorus from '../chorus';
import t from '../intl';
import _ from '../underscore';
import { linkTo } from './url_helpers';
import Handlebars from '../vendor/handlebars';

const templates = {}; // for memoizing handlebars helpers templates

export const renderErrors = (serverErrors) => {
  const output = ['<ul>'];
  const errorMessages = chorus.Mixins.ServerErrors.serverErrorMessages.call({ serverErrors });

  _.each(errorMessages, (message) => {
    output.push(`<li>${Handlebars.Utils.escapeExpression(message)}</li>`);
  });

  output.push('</ul>');
  return new Handlebars.SafeString(output.join(''));
};

export const breaklines = (text) => {
  let txt = Handlebars.Utils.escapeExpression(text);
  txt = txt.replace(/(\r\n|\n|\r)/gm, '<br>');
  return new Handlebars.SafeString(txt);
};

export const breaklinesT = (text, replacers) => breaklines(t(text, replacers).replace(/\\n/g, '\n'));

export const translate = (self, ...args) => {
  const context = args;
  if (context[context.length - 1].hash) {
    context[context.length - 1] = context[context.length - 1].hash;
  }
  return t.apply(self, context);
};

export const unsafeT = (self, ...args) => new Handlebars.SafeString(translate(self, ...args));

export const humanizedDatasetType = (dataset, statistics) => {
  if (!dataset) {
    return '';
  }
  const keys = ['dataset.entitySubtypes', dataset.entitySubtype];
  if (statistics instanceof chorus.models.DatasetStatistics && statistics.get('objectType')) {
    keys.push(statistics.get('objectType'));
  } else if (dataset.entitySubtype === 'CHORUS_VIEW' || dataset.entitySubtype === 'SOURCE_TABLE') {
    keys.push(dataset.objectType);
  } else if (dataset.entitySubtype === 'SANDBOX_TABLE') {
    if (dataset.objectType === 'TABLE') {
      keys.push(`BASE_${dataset.objectType}`);
    } else {
      keys.push(dataset.objectType);
    }
  } else {
    return t('loading');
  }
  const key = keys.join('.');
  return t(key);
};

export const percentage = (value) => {
  const number = 10 ** 2;
  const result = Math.round(value * number) / number;
  return `${result}%`;
};

export const moreLink = (collection, max, moreKey, lessKey) => {
  if (collection && collection.length > max) {
    templates.moreLinks = templates.moreLinks || Handlebars.compile(["<ul class='morelinks'>",
      "<li><a class='more' href='#'>{{t moreKey count=more_count}}</a></li>",
      "<li><a class='less' href='#'>{{t lessKey count=more_count}}</a></li>",
      '</ul>'].join(''));

    return templates.moreLinks({
      moreKey,
      more_count: collection.length - max,
      lessKey,
    });
  }
  return '';
};

export const escapeAllowingHtmlTag = (stringToEscape, htmlTag) => {
  let escapedString = Handlebars.Utils.escapeExpression(stringToEscape);

  const openTagRegex = new RegExp(`&lt;${htmlTag}&gt;`, 'g');
  const closeTagRegex = new RegExp(`&lt;/${htmlTag}&gt;`, 'g');

  const openTag = `<${htmlTag}>`;
  const closeTag = `</${htmlTag}>`;
  escapedString = escapedString.replace(openTagRegex, openTag);
  escapedString = escapedString.replace(closeTagRegex, closeTag);
  return new Handlebars.SafeString(escapedString);
};

export const parseDateFromApi = (str) => {
  const format = d3.utcParse('%Y-%m-%dT%H:%M:%S');
  return str && format(str);
};

const toRelativeTime = function(date, now_threshold) {
  var delta = new Date() - date;
  now_threshold = parseInt(now_threshold, 10);
  if (isNaN(now_threshold)) {
    now_threshold = 0;
  }
  if (Math.abs(delta) <= now_threshold) {
    return 'Just now';
  }
  var units = null;
  var conversions = {
    millisecond: 1, // ms    -> ms
    second: 1000,   // ms    -> sec
    minute: 60,     // sec   -> min
    hour:   60,     // min   -> hour
    day:    24,     // hour  -> day
    month:  30,     // day   -> month (roughly)
    year:   12      // month -> year
  };
  for (var key in conversions) {
    if (Math.abs(delta) < conversions[key]) {
      break;
    } else {
      units = key; // keeps track of the selected key over the iteration
      delta = delta / conversions[key];
    }
  }
  // pluralize a unit when the difference is greater than 1.
  delta = delta > 0 ? Math.floor(delta) : Math.ceil(delta);
  if (Math.abs(delta) !== 1) { units += "s"; }
  if (delta > 0) {
    return [delta, units, "ago"].join(" ");
  } else {
    return ["in", -delta, units].join(" ");
  }
};

export const relativeTimestamp = (str) => {
  const date = parseDateFromApi(str.substr(0, 19));
  return date ? toRelativeTime(date, 60000) : '';
};

export const userProfileLink = user => linkTo(user.showUrl(), user.displayName(), { class: 'user' });
