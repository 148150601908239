import { Header, Icon, Segment } from 'semantic-ui-react';

const AppPlaceHolder = ({ message }) => (
  <Segment placeholder attached className="app-content" textAlign="center">
    <Header icon color="grey">
      <Icon loading name="spinner" size="massive" color="grey" />
      {message}
    </Header>
  </Segment>
);

export default AppPlaceHolder;
