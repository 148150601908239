import Backbone from 'backbone';

const UploadToken = Backbone.Model.extend({
  url: (options) => {
    const url = `api/workspaces/workspaces/${options.workspaceID}/upload_token/`;
    if (options.id) {
      if (options.publicInbox) {
        return `${url}${options.id}?publicInbox=true`;
      }
      return `${url}${options.id}`;
    }
    return url;
  },

  defaults: {
    readyToUpload: false,
    validFrom: '',
    validTo: '',
    reason: '',
    publicInbox: false,
    dataUpload: false,
    deleted: false,
    error: false,
    destination: null,
    useDestination: false,
  },

  initialize(attrs) {
    if (attrs && attrs.validFrom && attrs.validTo) {
      this.set({ validFrom: attrs.validFrom.split('T')[0] });
      this.set({ validTo: attrs.validTo.split('T')[0] });
    } else {
      const currentDate = new Date();
      this.set({ validFrom: currentDate.toISOString().split('T')[0] });
      currentDate.setDate(currentDate.getDate() + 30);
      this.set({ validTo: currentDate.toISOString().split('T')[0] });
      this.setReadyToUpload = this.setReadyToUpload.bind(this);
    }
  },

  setReadyToUpload() {
    let readyToUpload = false;
    let error = false;
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (this.get('reason') && this.get('validFrom').match(regEx) && this.get('validTo').match(regEx)) {
      error = true;
      const validFrom = Date.parse(this.get('validFrom'));
      const validTo = Date.parse(this.get('validTo'));
      const diff = Math.floor(( validTo - validFrom ) / 86400000);
      if (diff >= 0 && diff <= 30) {
        readyToUpload = true;
        error = false;
      }
    }
    this.set({ readyToUpload, error });
  },

  updateValidFrom(value) {
    this.set({ validFrom: value });
    this.setReadyToUpload();
  },

  updateValidTo(value) {
    this.set({ validTo: value });
    this.setReadyToUpload();
  },

  updateReason(value) {
    this.set({ reason: value });
    this.setReadyToUpload();
  },

  updatePublicInbox(value) {
    this.set({ publicInbox: value });
    if (value) {
      this.set({ dataUpload: false });
      this.set({ destination: null });
      this.set({ useDestination: false });
    }
  },

  updateDataUpload(value) {
    this.set({ dataUpload: value });
    if (value) {
      this.set({ publicInbox: false });
      this.set({ destination: null });
      this.set({ useDestination: false });
    }
  },

  updateUseDestination(value) {
    this.set({ useDestination: value });
    if (value) {
      this.set({ dataUpload: false });
      this.set({ publicInbox: false });
    } else {
      this.set({ destination: null });
    }
  },

  updateDestination(value) {
    this.set({ destination: value });
  },
});

export default UploadToken;
