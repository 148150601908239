/* eslint-disable camelcase */
import { Button } from 'semantic-ui-react';
import { t } from 'i18n-js';
import MultipleDeleteModal from './modals/MultipleDeleteModal';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import MultipleMoveModal from './modals/MultipleMoveModal';
import { airlockFile } from '../../../../constants/ui_urls';
import { isFileFolder } from '../../../helper';
import { useWorkspaceContext } from '../../../../adapters/Workspace';
import Can from '../../../../permissions/index';
import { useFilesContext } from '../Context';

const AirlockButton = ({ selectedFiles }) => (
  <Button
    icon="unlock"
    basic
    size="small"
    content={t('file_list.actions.airlock')}
    // TODO: Refactor URL not to pass full list of files
    href={airlockFile(selectedFiles.map((file) => file.id).join())}
  />
);

const DeleteButton = (
  <Button
    basic
    size="small"
    icon="trash alternate outline"
    content={t('file_list.actions.delete')}
  />
);

const MoveButton = (
  <Button
    icon="arrow circle right"
    basic
    size="small"
    content={t('file_list.actions.move')}
  />
);

const FilesMultiSelectActions = () => {
  const { workspace: { can_airlock } } = useWorkspaceContext();
  const { baseFolderPath, selectedFiles } = useFilesContext();

  return selectedFiles.length > 0 && (
    <>
      {can_airlock && (
      <Can I="Create" this="WorkspaceAirlock">
        <AirlockButton selectedFiles={selectedFiles} />
      </Can>
      )}
      <Can I="Write" this="WorkspaceStorage">
        <ModalWrapper size="small" trigger={DeleteButton}>
          <MultipleDeleteModal />
        </ModalWrapper>
        {isFileFolder(baseFolderPath) && (
          <ModalWrapper className="move-modal" size="small" trigger={MoveButton}>
            <MultipleMoveModal />
          </ModalWrapper>
        )}
      </Can>
    </>
  );
};

export default FilesMultiSelectActions;
