import _ from '../underscore';
import $ from '../jquery';
import t from '../intl';

const tools = 'bold italic | bullets numbering | link unlink';

export default {
  makeEditor($container, controlSelector, inputName, options) {
    const controls = ['bold', 'italic', 'bullets', 'numbers', 'link', 'unlink'];
    $container.find(controlSelector).empty();
    _.each(controls, function addToolbar(control, i) {
      const $controlContainer = $container.find(controlSelector);
      $controlContainer.append($(`<a class="${control}" href="#"></a>`)
        .text(t(`workspace.settings.toolbar.${control}`)));
      if (i < controls.length - 1) {
        $controlContainer.append($('<span>|</span>'));
      }
      $container.find(`a.${control}`).unbind('click').bind(
        'click',
        _.bind(this[`onClickToolbar${_.capitalize(control)}`], $container),
      );
    }, this);

    const opts = options || {};
    const editorOptions = _.extend(opts, { controls: tools, docCSSFile: 'assets/cleditor_iframe.css' });
    const editor = $container.find(`textarea[name='${inputName}']`).cleditor(editorOptions)[0];
    return editor;
  },

  onClickToolbarBold(e) {
    e && e.preventDefault();
    this.find(".cleditorButton[title='Bold']").click();
  },

  onClickToolbarItalic(e) {
    e && e.preventDefault();
    this.find(".cleditorButton[title='Italic']").click();
  },

  onClickToolbarBullets(e) {
    e && e.preventDefault();
    this.find(".cleditorButton[title='Bullets']").click();
  },

  onClickToolbarNumbers(e) {
    e && e.preventDefault();
    this.find(".cleditorButton[title='Numbering']").click();
  },

  onClickToolbarLink(e) {
    e && e.preventDefault();
    this.find(".cleditorButton[title='Insert Hyperlink']").click();
    e.stopImmediatePropagation();
  },

  onClickToolbarUnlink(e) {
    e && e.preventDefault();
    this.find(".cleditorButton[title='Remove Hyperlink']").click();
  },

  getNormalizedText($textarea) {
    return $textarea.val()
      .replace(/(<div><br><\/div>)+$/, '')
      .replace(/^<br>$/, '');
  },
};
