import chorus from '../../chorus';
import $ from '../../jquery';
import _ from '../../underscore';
import Activity from './activity_view';
import View from '../loading_view';
import { getManagementWorkspace } from '../../models/management/workspace';
import ManagementUser from '../../models/management/user';
import t from '../../intl';

const findUser = (uuid, users) => users.find(user => user.get('uuid') === uuid);
const findMember = (uuid, users) => users.find(user => user.uuid === uuid);

export default View.extend({
  constructorName: 'ActivityListView',
  templateName: 'activity_list',
  useLoadingSection: true,

  events: {
    'click .morelinks a.more, .morelinks a.less': 'toggleCommentList',
    'click .more_items a': 'fetchMoreActivities',
  },

  setup() {
    this.subscribePageEvent('note:deleted', this.noteDeleted);
    this.subscribePageEvent('note:saved', this.noteSaved);
    this.subscribePageEvent('airlock:saved', this.airlockSaved);
    this.subscribePageEvent('airlock:deleted', this.noteDeleted);

    this.workspace = this.options.workspace;
    this.managementWorkspace = getManagementWorkspace(this.workspace.get('uuid'));
    this.managementWorkspace.fetchIfNotLoaded();
    this.requiredResources.add(this.managementWorkspace);

    this.isWorkspaceOwner = chorus.session.user().get('uuid') === this.workspace.get('owner')?.uuid;
    this.usersCollection = [];
  },

  noteDeleted(note) {
    if (this.collection.get(note.id)) {
      this.collection.remove(note);
      this.collection.fetch();
    }
  },

  noteSaved(note) {
    const collectionNote = this.collection.get(note.id);
    if (collectionNote) {
      collectionNote.set('body', note.get('body'));
      this.render();
    }
  },

  airlockSaved(airlock) {
    const collectionNote = this.collection.get(airlock.id);
    if (collectionNote) {
      collectionNote.set('reason', airlock.get('reason'));
      collectionNote.set('status', airlock.get('status'));
      this.render();
    }
  },

  toggleCommentList(event) {
    event.preventDefault();
    $(event.target).closest('.comments').toggleClass('more');
    chorus.PageEvents.trigger('content:changed');
  },

  fetchMoreActivities(ev) {
    ev.preventDefault();
    this.$('.more_btn').text(t('actions.loading'));
    const pageToFetch = parseInt(this.collection.pagination.page, 10) + 1;
    this.collection.fetchPage(pageToFetch, { reset: false, remove: false, success: _.bind(this.render, this) });
  },

  additionalContext() {
    const ctx = { activityType: this.options.type };
    if (this.collection.loaded && this.collection.pagination) {
      const page = parseInt(this.collection.pagination.page, 10);
      const total = parseInt(this.collection.pagination.total, 10);

      if (parseInt(this.collection.pagination.total, 10) !== -1) {
        ctx.showMoreLink = total > page;
      } else {
        const maxSize = this.collection.attributes.pageSize * page;
        ctx.showMoreLink = this.collection.length === maxSize;
      }
    } else {
      ctx.showMoreLink = false;
    }
    return ctx;
  },

  cleanupActivities() {
    _.each(this.activities, (activity) => {
      activity.teardown();
    });
  },

  lookup_user(uuid) {
    const user = findUser(uuid, this.usersCollection);
    if (user) { return user; }
    const non_member = new ManagementUser({ uuid });
    this.usersCollection.push(non_member);
    non_member.fetch();
    return non_member;
  },

  setAuthor(model, attribute, uuid){
    const author = { firstName: t('actions.removed_user.first_name'), lastName: t('actions.removed_user.last_name'), uuid };
    if (!uuid) {
      model.set(attribute, author);
    } else {
      const member = findMember(uuid, this.managementWorkspace.get('members'));
      if (member) {
        model.set(attribute, member);
      } else {
        model.set(attribute, author);
        const non_member = this.lookup_user(uuid);
        this.onceLoaded(non_member, () => {
          model.set(attribute, { firstName: non_member.get('firstName'), lastName: non_member.get('lastName')});
        });
      }
    };
  },

  postRender() {
    this.cleanupActivities();
    $(this.el).addClass(this.options.additionalClass);
    const ul = this.$('ul');
    this.activities = [];

    this.collection.each(function addListItem(modelOrig) {
      try {
        const model = modelOrig.clone();
        const author_uuid = model.author() ? model.author().get('uuid') : null;
        this.setAuthor(model, 'author', author_uuid);
        // promotion / insight
        if (model.get('promotedBy') && model.get('promotedBy').uuid) {
          this.setAuthor(model, 'promotedBy', model.get('promotedBy').uuid);
        }

        const view = new Activity({
          model,
          displayStyle: this.options.displayStyle,
          isNotification: this.options.isNotification,
          isReadOnly: this.options.isReadOnly,
          usersCollection: this.usersCollection,
          managementWorkspace: this.managementWorkspace
        });
        this.activities.push(view);
        this.registerSubView(view);
        ul.append(view.render().el);
      } catch (err) {
        if (chorus.isDevMode()) {
          const model = modelOrig.clone();
          console.log(err.message);
          chorus.log(err.message, err, 'processing activity', model);
          let action;
          let id;
          try {
            action = model.get('action');
            ({ id } = model);
          } catch (err2) {
            // continue regardless of error
          }
          chorus.toast('bad_activity', { type: action, id, toastOpts: { theme: 'bad_activity' } });
        }
      }
    }, this);
    this.$('.more_btn').text(t('actions.more'));
  },

  show() {
    _.each(this.activities, (activity) => {
      activity.show();
    });
  },
});
