/* eslint-disable no-param-reassign */
// the client is inherently stateful and it is useful to put some of the persistent state
// on the client object.
import Guacamole from 'guacamole-common-js';
import {
  STATE_CONNECTED, STATE_CONNECTING, STATE_DISCONNECTED, STATE_IDLE, STATE_WAITING, TUNNEL_PATH,
} from '../constants';
import { resize } from './messages';
import { addAuth, removeAuth } from './auth';
import { filteredSendMouseState, recordGuacError } from '../utils';

// The linking seems to be slightly broken, in theory this should be no different to
// having it in the import but there was a consistent problem with the WebSocketTunnel
// being undefined when doing this as part of the import.
const {
  Client, Keyboard, Mouse, WebSocketTunnel,
} = Guacamole;

/**
 * Authorised connection function that inserts the current width and height as
 * additional data parameters.
 * The width and height are passed to the additionalDataFn so that other data
 * parameters can be added independently from this function.
 *
 * @param client
 * @param additionalDataFn
 * @returns {(function(): Promise<void>)|*}
 */
const createConnectFn = (client, additionalDataFn) => async () => {
  const w = client.previousWidth || 640;
  const h = client.previousHeight || 480;
  await addAuth();
  client.lastError = null;
  client.connect(additionalDataFn(w, h));
};

const MAX_RETRIES = 10;

/**
 * Creates a function to be used as a handler for state changes
 *
 * @param client
 * @returns {(function(*): void)|*}
 */
const createOnStateChange = (client) => (state) => {
  // Retry up to MAX_RETRIES because the first connection isn't always healthy
  // This can also handle automatic reconnects for dropped connections
  if (client.lastError !== null && client.retries < MAX_RETRIES && [STATE_IDLE, STATE_DISCONNECTED].includes(state)) {
    client.retries += 1;
    setTimeout(client.authorisedConnect, 1000);
    return;
  }

  // Always update the overlay message on state changes
  client.updateOverlayMessage?.(state);

  // Unless we are currently connecting then ensure any auth is removed
  if (state !== STATE_CONNECTING && state !== STATE_WAITING) {
    client.removeAuth();
  }

  // When we are connected trigger sending the size so that any resize while
  // connecting can be handled nicely
  if (state === STATE_CONNECTED) {
    client.resendSize();
  }
};

const safeDisconnect = (client, tunnel) => {
  // when disconnecting the client tries to send a message down the tunnel which can fail
  // temporarily overwrite the sendMessage with a nop and then restore later.
  const tmp = tunnel.sendMessage;
  tunnel.sendMessage = (...args) => {
    try { tmp.bind(tunnel)(...args); } catch {
      // ignore any errors and continue
    }
  };
  try { client.disconnect(); } catch {
    // ignore any errors and continue
  }
  tunnel.sendMessage = tmp;
};

const createClient = (additonalDataFn, createPasteFn) => {
  // Instantiate client, using an HTTP tunnel for communications.
  const tunnel = new WebSocketTunnel(TUNNEL_PATH);

  const client = new Client(tunnel);
  client.retries = 0;
  client.lastError = null;
  tunnel.onerror = (error) => {
    client.lastError = error;
    safeDisconnect(client, tunnel);
    recordGuacError(error);
  };

  const display = client.getDisplay().getElement();
  display.setAttribute('tabindex', '0');

  // Add resize event handler that will be used by resize monitor
  const { resendSize, onResize } = resize(client);
  client.resendSize = resendSize;
  client.onResize = onResize;

  // Add state change monitor which will remove auth cookie, update overlay
  // and any other event handlers that we specify
  client.updateOverlayMessage = null;
  client.onstatechange = createOnStateChange(client);

  // Add connect wrapper method that sets auth cookie
  client.authorisedConnect = createConnectFn(client, additonalDataFn);
  client.authorisedReconnect = () => {
    client.retries = 0;
    client.authorisedConnect();
  };
  client.safeDisconnect = () => safeDisconnect(client, tunnel);
  client.removeAuth = removeAuth;

  // Keyboard
  const keyboard = new Keyboard(display);
  keyboard.onkeydown = (keysym) => { client.sendKeyEvent(1, keysym); };
  keyboard.onkeyup = (keysym) => { client.sendKeyEvent(0, keysym); };

  // Mouse
  const mouse = new Mouse(display);
  const mFun = filteredSendMouseState(client.sendMouseState.bind(client));
  ['mousedown', 'mouseup', 'mousemove'].forEach((event) => mouse.on(event, mFun));
  mouse.on('mouseout', () => {
    keyboard.reset();
    display.blur();
  });
  display.addEventListener('mouseover', () => {
    display.focus();
  });

  // Add paste handler directly to client so that it can be used elsewhere
  client.onPaste = createPasteFn(client, mouse);

  return client;
};

export default createClient;
