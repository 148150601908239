import React from 'react';
import {
  Visibility, Form, Header, Button, Grid,
} from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';
import DatasetUploadForm from './DatasetUploadForm';
import DatasetUploadTable from './DatasetUploadTable';
import Buttons from '../Buttons';
import Loading from '../Loading';
import './Dataset.less';

function DatasetUploadContainer({ loaded, tdfSourceFile, ...props }) {
  if (loaded) {
    return (
      <div className="main_content app-content semantic semantic-body" id="datasetingress">
        <Visibility className="content">
          <Form autoComplete="off">
            <Header as="h1">
              {t('dataset.import.page_title')}
            </Header>
            <Grid divided="vertically" columns="equal">
              <Grid.Row columns={3}>
                <Grid.Column width={5}>
                  <Grid divided="vertically">
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Button onClick={props.importTDFFile} className="import" size="small">
                          {t('dataset.import.import_from_file.button')}
                        </Button>
                      </Grid.Column>
                      <Grid.Column verticalAlign="middle">
                        {tdfSourceFile}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <DatasetUploadForm {...props} />
            <DatasetUploadTable />
            <Buttons {...props} />
          </Form>
        </Visibility>
      </div>
    );
  }
  return (
    <Loading />
  );
}

const mapModelsToProps = ({ workfile, buttonState, datasetUpload }) => ({
  tdfSourceFile: datasetUpload && datasetUpload.get('tdf_source_file'),
  loaded: workfile.loaded && datasetUpload && datasetUpload.get('loaded'),
  saving: buttonState.get('saving'),
  minimumDataEntryMet: datasetUpload && datasetUpload.get('ready_to_upload'),
});

const options = {
  debounce: false,
  events: {
    workfile: ['sync'],
    buttonState: ['change:saving'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(DatasetUploadContainer);
