import { useState } from 'react';
import FeaturesView from '../../../../../application/views/features/features_show_view';
import ReactToBackboneWrapper from '../../ReactToBackboneWrapper';

const FeaturesPanel = ({ tab }) => {
  const { component, id } = tab;
  const [view] = useState(() => new FeaturesView());

  return (
    <ReactToBackboneWrapper
      view={view}
      tabId={id}
      component={component}
    />
  );
};

export default FeaturesPanel;
