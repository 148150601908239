import _ from '../../underscore';
import $ from '../../jquery';
import MainContentView from './main_content_view';
import ListHeaderView from './list_header_view';
import ListContentDetails from '../core/list_content_details_view';
import PageItemList from '../core/page_item_list_view';
import Workfile from '../workfiles/workfiles_list_view';
import DatasetColumn from '../dataset/database_column_view';
import Dataset from '../dataset/dataset_list_view';
import Activity from '../activities/activity_list_view';
import UserItem from '../users/user_item_view';
import DatasetItem from '../dataset/dataset_item_view';
import TagItem from '../tags/tag_item_view';
import WorkfileItem from '../workfiles/workfile_item_view';

const Lists = {
  Workfile,
  DatasetColumn,
  Dataset,
  Activity,
};

const Items = {
  UserItem,
  WorkfileItem,
  DatasetItem,
  TagItem,
};

const buildForType = (entityType, options) => new Lists[entityType](options);

const buildForItem = entityType => Items[entityType];

export default MainContentView.extend({
  constructorName: 'MainContentListView',
  additionalClass: 'main_content_list rsh-borderless-header',
  bindCallbacks: $.noop,

  setup(options) {
    const { modelClass } = options;
    const { collection } = this;

    if (options.useCustomList) {
      this.content = buildForType(modelClass, _.extend({ collection }, options.contentOptions));
    } else {
      this.content = new PageItemList(_.extend({
        collection,
        entityType: _.underscored(modelClass),
        EntityViewType: buildForItem(`${modelClass}Item`),
      }, options.contentOptions));
    }

    this.contentHeader = options.contentHeader || new ListHeaderView({
      title: options.title || (!options.emptyTitleBeforeFetch && (`${modelClass}s`)),
      linkMenus: options.linkMenus,
      imageUrl: options.imageUrl,
      sandbox: options.sandbox,
    });

    if (Object.prototype.hasOwnProperty.call(options, 'persistent')) {
      this.persistent = options.persistent;
    }

    if (options.contentDetails) {
      this.contentDetails = options.contentDetails;
    } else {
      this.contentDetails = new ListContentDetails(_.extend({
        collection,
        modelClass,
        buttons: options.buttons,
        search: options.search && _.extend({ list: $(this.content.el) }, options.search),
      }, options.contentDetailsOptions));

      this.contentFooter = new ListContentDetails({
        collection,
        modelClass,
        hideCounts: true,
        hideIfNoPagination: true,
      });
    }
  },
});
