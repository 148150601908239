import Model from '../models';
import WorkfileNew from '../../dialogs/workfiles/workfile_new_dialog';
import FolderNew from '../../dialogs/workfiles/folder_new_dialog';
import DataTableEditorCreate from '../../dialogs/dataset/data_table_editor_create_dialog';
import MiniAppCreate from '../../dialogs/mini_apps/mini_app_create_dialog';

export default Model.extend({
  workspace: null,
  selectedModels: [],
  collection: null,
  filterOnType: 'all',
  searchTerm: '',
  folder: null,

  initialize(options) {
    this.workspace = options.workspace;
    this.clearSearch = this.clearSearch.bind(this);
    this.uploadLink = this.uploadLink.bind(this);
    this.createNewFileModal = this.createNewFileModal.bind(this);
    this.createNewFolderModal = this.createNewFolderModal.bind(this);
    this.createNewDatasetModal = this.createNewDatasetModal.bind(this);
    this.createNewDatasetModal = this.createNewDatasetModal.bind(this);
    this.createNewMiniAppModal = this.createNewMiniAppModal.bind(this);
  },

  context() {
    return this.get('context');
  },

  actions() {
    return this.get('actions');
  },

  clearSearch() {
    if (this.collection && this.searchTerm !== '') {
      this.collection.attributes.namePattern = '';
      this.collection.fetch();
    }
    this.searchTerm = '';
  },

  uploadLink() {
    let folderPath = 'files';
    if (this.folder) {
      folderPath = this.folder.name().split('/?')[0];
    }
    return `#/workspaces/${this.workspace.id}/upload/${+new Date()}/${folderPath}`;
  },

  createNewFileModal(e, component) {
    e && e.preventDefault();
    new WorkfileNew({
      workspaceId: this.workspace.id,
      editInline: true,
      folder: this.folder,
      defaultFileType: component.name === 'new_sql_query_launcher' ? 'sql': 'r',
    }).launchModal();
  },

  createNewFolderModal(e) {
    e && e.preventDefault();
    new FolderNew({
      workspace: this.workspace,
      editInline: true,
      folder: this.folder,
    }).launchModal();
  },

  createNewDatasetModal(e) {
    e && e.preventDefault();
    new DataTableEditorCreate({
      workspaceId: this.workspace.id,
    }).launchModal();
  },

  createNewMiniAppModal(e) {
    e && e.preventDefault();
    new MiniAppCreate({
      workspaceId: this.workspace.id,
      token: this.workspace.token(),
    }).launchModal();
  },
});
