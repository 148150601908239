import _ from 'underscore';
import { validTypes } from './datatable/types';

const generateColumnNames = (rows) => _.map(rows[0], (column, i) => `column_${i + 1}`);

export const normalize = (str) => str.trim().toLowerCase()
  .replace(/[\s.]/g, '_').replace(/[^a-z0-9_]/g, '')
  .substring(0, 64);

export const getColumnOrientedData = (data, options) => {
  const csvRows = data;
  let columnNames;

  if (options.hasHeader) {
    columnNames = data.shift().map((c) => normalize(c));
  } else {
    columnNames = generateColumnNames(csvRows);
  }

  if (options.columnNameOverrides) {
    columnNames = options.columnNameOverrides;
  }

  const validColumnTypes = validTypes(data, columnNames);
  validColumnTypes.forEach((types, index) => {
    validColumnTypes[index] = types.map((type) => type.postgreSQL);
  });

  return columnNames.map((columnName, i) => {
    const columnValues = [];
    csvRows.forEach((row) => {
      columnValues.push(row[i] || '');
    });
    return {
      values: columnValues,
      name: columnName,
      type: validColumnTypes[i] && validColumnTypes[i][0] ? validColumnTypes[i][0] : 'text',
      validTypes: validColumnTypes[i] ? validColumnTypes[i] : ['text'],
    };
  });
};
