import { t } from 'i18n-js';
import Backbone from 'backbone';
import _ from 'underscore';
import $ from 'jquery';

export default {

  navigateByElement(e) {
    e && e.preventDefault(e);
    const url = $(e.target).attr('href')
        || $(e.target).closest('a').attr('href');
    this.navigate(url);
  },

  navigate(fragment, pageOptions) {
    let frag = fragment.match(/#?(.*)/)[1];
    const fragComparison = frag.match(/\/?(.*)/)[1];
    const historyFragment = Backbone.history.fragment;
    const newURI = fragComparison.split('/')[0];
    const currentURI = historyFragment.split('/')[0];
    const decodedURI = decodeURIComponent(newURI);
    const fragMatch = (currentURI === newURI || currentURI === decodedURI);
    const loadURI = historyFragment === fragComparison || historyFragment === decodedURI;

    if ((_.size(chorus.rsh.stateManager.unsavedFiles) === 0)
            || fragMatch
            || window.confirm(t('rsh.generic.navigationWarning'))) {
      chorus.pageOptions = pageOptions;

      if (loadURI) { Backbone.history.loadUrl(frag); } else if (!fragMatch) {
        chorus.rsh.stateManager.unsavedFiles = {};
      }

      const opts = pageOptions || { trigger: true };
      // new ux flex layout needs the urls to all be in the same format
      if (frag.startsWith('#')) {
        if (!frag.startsWith('#/')) {
          frag = `#/${frag.substring(1)}`;
        }
      } else if (frag.startsWith('/')) {
        frag = `#${frag}`;
      } else {
        frag = `#/${frag}`;
      }
      Backbone.Router.prototype.navigate.call(this, frag, opts);
    }
  },
};
