import t from '../intl';
import Error from './error_page';
import { instance } from '../models/config';

export default Error.extend({
  title: t('invalid_route.title'),
  text: t('invalid_route.text'),
  setup() {
    if (!Backbone.history.getFragment()) {
      window.location = instance.get('administrationUrl');
    }
  },
});
