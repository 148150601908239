import React from 'react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import {
  Button,
  Icon,
  Popup,
  Label,
  Loader,
  CardContent,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import { appRunStatus } from '../../models/containerised_app';

const StoppedAppFooter = ({ handleRun }) => (
  <CardContent extra>
    <Label>
      <Icon name="stop circle outline" />
      {t('app.card.footer.idle')}
    </Label>
    <Popup
      position="top right"
      popper={{ className: 'ui semantic' }}
      content={t('app.form.run_app')}
      hideOnScroll
      trigger={(
        <Button
          icon
          compact
          floated="right"
          aria-label={t('app.form.run_app')}
          onClick={handleRun}
        >
          <Icon name="play" color="green" />
        </Button>
      )}
    />
  </CardContent>
);

const RunningAppFooter = ({ handleConnect, handleStop }) => (
  <CardContent extra>
    <Label>
      <Icon name="chevron circle right" color="green" />
      {t('app.card.footer.running')}
    </Label>
    <Button.Group icon compact floated="right">
      <Popup
        position="top right"
        popper={{ className: 'ui semantic' }}
        content={t('app.card.footer.popup_stop')}
        hideOnScroll
        trigger={(
          <Button onClick={handleStop} aria-label={t('app.card.footer.popup_stop')}>
            <Icon color="red" name="stop" />
          </Button>
        )}
      />
      <Popup
        position="top right"
        popper={{ className: 'ui semantic' }}
        content={t('app.card.footer.popup_connect')}
        hideOnScroll
        trigger={(
          <Button onClick={handleConnect} aria-label={t('app.card.footer.popup_connect')}>
            <Icon color="blue" name="plug" />
          </Button>
        )}
      />
    </Button.Group>
  </CardContent>
);

const LoadingAppFooter = () => (
  <CardContent extra>
    <Loader active inline="centered" size="mini" />
  </CardContent>
);

// Not currently in use - storybook only
export const UploadImageFooter = () => (
  <CardContent extra>
    <Button fluid basic>
      <Icon color="orange" name="upload" />
      <strong>{t('app.card.footer.popup_disabled_play')}</strong>
    </Button>
  </CardContent>
);

// Not currently in use - storybook only
export const NoImageFooter = () => (
  <CardContent extra>
    <Popup
      content={t('app.card.footer.popup_upload')}
      trigger={(
        <div style={{ width: '100%' }}>
          <Button fluid className="disabled">
            <Icon color="orange" name="upload" />
            {t('app.card.footer.popup_no_image')}
          </Button>
        </div>
      )}
    />
  </CardContent>
);

const SingleCardFooter = ({
  runState, handleRun, handleStop,
}) => {
  switch (runState.code) {
    case appRunStatus.IDLE:
      return <StoppedAppFooter handleRun={handleRun} />;
    case appRunStatus.FETCHING:
    case appRunStatus.STOPPING:
      return <LoadingAppFooter />;
    default:
      return (
        <RunningAppFooter handleConnect={handleRun} handleStop={handleStop} />
      );
  }
};

const mapModelsToProps = ({ containerisedApp }) => ({
  runState: containerisedApp.get('runState'),
  handleRun: () => containerisedApp.navigateToAppRun(),
  handleStop: () => containerisedApp.stop(false),
});

export default connectBackboneToReact(mapModelsToProps)(SingleCardFooter);
