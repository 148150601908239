import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import DatasetSidebarPresenter from '../../presenters/dataset_sidebar_presenter';
import ActivityList from '../activities/activity_list_view';
import DatasetStatistics from './dataset_statistics_view';
import Analyze from '../../alerts/confirm_analyze_alert';
import DatasetDelete from '../../alerts/dataset_delete_alert';
import TabControl from '../core/tab_control_view';
import Sidebar from '../core/sidebar_view';
import CreateDatabaseView from '../../dialogs/dataset/create_database_view_dialog';
import DatasetDownload from '../../dialogs/dataset/dataset_download_dialog';
import DatasetPreview from '../../dialogs/dataset/dataset_preview';
import VerifyChorusView from '../../dialogs/dataset/verify_chorus_view_dialog';
import NotesNew from '../../dialogs/activities/notes_new_dialog';
import EditTags from '../../dialogs/tags/edit_tags_dialog';
import ChorusView from '../../models/chorus_view';
import DatasetMeta from '../../models/dataset_meta';
import { workspace } from '../../utilities/workspace_assoc';
import Collection from '../../collections/collections';
import { FEATURE } from '../../../utilities/features';
import Routing from '../../mixins/routing';

export default Sidebar.include(Routing).extend({
  constructorName: 'DatasetSidebarView',
  templateName: 'dataset_sidebar',
  useLoadingSection: true,

  events: {
    'click .dataset_preview': 'launchDatasetPreviewDialog',
    'click .dataset_export': 'launchDatasetExportDialog',
    'click .dataset_download': 'launchDatasetAirlock',
    'click .analyze': 'launchAnalyzeAlert',
    'click a.delete_dataset': 'launchDatasetDeleteAlert',
    'click a.duplicate': 'launchDuplicateChorusView',
    'click .edit_tags': 'startEditingTags',
    'click a.new_note': 'launchNotesNewDialog',
    'click a.create_database_view': 'launchCreateDatabaseViewDialog',
  },

  subviews: {
    '.tab_control': 'tabs',
  },

  setup() {
    this.tabs = new TabControl(['statistics', 'activity']);
    this.registerSubView(this.tabs);
  },

  render(...args) {
    if (!this.disabled) {
      this._super('render', args); // eslint-disable-line no-underscore-dangle
    }
  },

  setColumn(column) {
    if (column) {
      this.selectedColumn = column;
      this.tabs.statistics.column = column;
    } else {
      delete this.selectedColumn;
      delete this.tabs.statistics.column;
    }

    this.render();
  },

  createActivitiesTab(dataset) {
    const activities = dataset.activities();
    activities.fetch();

    this.tabs.activity = new ActivityList({
      workspace: workspace(this.resource),
      collection: activities,
      additionalClass: 'sidebar',
      displayStyle: ['without_workspace'],
      type: t(`database_object.${dataset.get('objectType')}`),
    });
    this.tabs.registerSubView(this.tabs.activity);
  },

  createStatisticsTab(dataset) {
    this.tabs.statistics = new DatasetStatistics({
      model: dataset,
      column: this.selectedColumn,
    });
    this.tabs.registerSubView(this.tabs.statistics);

    const statistics = dataset.statistics();
    statistics.fetchIfNotLoaded();
  },

  setColumnSet(columnSet) {
    this.columnSet = columnSet;
    this.listenTo(columnSet, 'column:selected', this.setColumn);
  },

  setDataset(dataset) {
    this.resource = dataset;
    if (this.tabs.statistics) { this.tabs.statistics.teardown(); }
    if (this.tabs.activity) { this.tabs.activity.teardown(); }
    if (dataset) {
      this.createActivitiesTab(dataset);
      this.createStatisticsTab(dataset);
      this.meta = new DatasetMeta({ model: dataset });
      this.listenTo(dataset, 'analyze:running', this.resetStatistics);
      this.listenTo(dataset, 'start:visualization', this.enterVisualizationMode);
      this.listenTo(dataset, 'cancel:visualization', this.endVisualizationMode);
    } else {
      delete this.tabs.statistics;
      delete this.tabs.activity;
    }

    this.render();
  },

  resetStatistics() {
    this.resource.statistics().fetch();
  },

  additionalContext() {
    return new DatasetSidebarPresenter(this.resource, this.options);
  },

  postRender() {
    const $actionLinks = this.$('a.download, a.delete');
    $actionLinks.data('dataset', this.resource);
    $actionLinks.data('workspace', this.resource && workspace(this.resource));
    this._super('postRender'); // eslint-disable-line no-underscore-dangle
  },

  launchDatasetPreviewDialog(e) {
    e.preventDefault();

    new DatasetPreview({ model: this.resource }).launchModal();
  },

  launchDatasetAirlock(e) {
    e.preventDefault();

    if (FEATURE.NEW_UX) {
      return this.navigate(`workspaces/${workspace(this.resource).id}/airlock_new/dataset/${this.resource.get('id')}`);
    }

    workspace(this.resource).trigger('airlock', this.resource);
  },

  launchAnalyzeAlert(e) {
    if (e) { e.preventDefault(); }
    new Analyze({ model: this.resource }).launchModal();
  },

  launchDatasetDeleteAlert(e) {
    if (e) { e.preventDefault(); }
    new DatasetDelete({
      pageModel: this.resource,
      keyPrefix: $(e.currentTarget).data('key-prefix'),
    }).launchModal();
  },

  launchDuplicateChorusView(e) {
    if (e) { e.preventDefault(); }
    const dialog = new VerifyChorusView();
    dialog.setModel(this.createDuplicateChorusView());
    dialog.launchModal();
  },

  createDuplicateChorusView() {
    const attrs = _.extend({}, this.resource.attributes, {
      objectName: t('dataset.chorusview.copy_name', { name: this.resource.get('objectName') }),
      sourceObjectId: this.resource.id,
    });
    delete attrs.id;
    const chorusView = new ChorusView(attrs);
    chorusView.duplicate = true;
    chorusView.sourceObject = this.resource;
    return chorusView;
  },

  launchNotesNewDialog(e) {
    if (e) { e.preventDefault(); }
    const dialogOptions = {
      entityId: this.resource.id,
      entityType: 'dataset',
      pageModel: this.resource,
      displayEntityType: this.resource.metaType(),
    };

    if (workspace(this.resource)) {
      _.extend(dialogOptions, {
        workspace: workspace(this.resource),
        allowWorkspaceAttachments: true,
      });
    }

    const dialog = new NotesNew(dialogOptions);
    dialog.launchModal();
  },

  launchCreateDatabaseViewDialog(e) {
    if (e) { e.preventDefault(); }
    const dialog = new CreateDatabaseView({ pageModel: this.resource });
    dialog.launchModal();
  },

  launchDatasetExportDialog(e) {
    if (e) { e.preventDefault(); }
    const dialog = new DatasetDownload({ pageModel: this.resource });
    dialog.launchModal();
  },

  enterVisualizationMode() {
    $(this.el).addClass('visualizing');
  },

  endVisualizationMode() {
    $(this.el).removeClass('visualizing');
  },

  startEditingTags(e) {
    e.preventDefault();
    new EditTags({ collection: new Collection([this.resource]) }).launchModal();
  },
});
