/* eslint-disable max-lines */
import { useState } from 'react';
import { t } from 'i18n-js';
import {
  Modal, Icon, Header, Form,
} from 'semantic-ui-react';
import ModalActionButtons from '../../../components/buttons/ModalActionButtons';
import HeaderError from '../../../components/errors/HeaderError';
import {
  invalidCharactersIn, invalidCharacters, invalidFileNames, hasError, findErrors,
} from '../../../utilities/validation';
import { validationLengths, validationFields } from '../../../constants/validation';
import { supportedFileTypes } from '../../constants';
import { useNewFile } from '../../panels/service';
import { calculateFileNameExtension } from '../../helper';
import { workspaceFileListElementUrl } from '../../../constants/ui_urls';
import { useFilesContext } from '../../panels/files/Context';
import FolderLocationDropdown from './FolderLocationDropdown';
import FileTypeDropdown from '../FileTypeDropdown';
import FormValidationError from '../../../components/errors/FormValidationError';
import { workspaceId } from '../../../layout/helper';

const initialState = (folderPath, fileType) => ({
  name: '',
  folderLocation: folderPath || 'files',
  type: fileType || supportedFileTypes.OTH,
});

export const NewFileModal = ({ setModalClose, setIsBusy }) => {
  const { baseFolderPath, fixedFileType } = useFilesContext();

  const [file, setFile] = useState(initialState(baseFolderPath, fixedFileType));
  const [validationErrors, setValidationErrors] = useState([]);

  const headerText = fixedFileType ? t(`file_list.actions.new_file.${fixedFileType}`) : t('file_list.actions.new_file.default');
  const isValidEntry = !!file.name && !!file.folderLocation && !!file.type;

  const handleNameChange = (e, { value }) => {
    setFile({ ...file, name: value });
    const newErrors = validationErrors.filter((err) => err.field !== validationFields.NAME);
    setValidationErrors(newErrors);
  };

  const createFilename = () => {
    const fileNameType = calculateFileNameExtension(file.name);
    if (file.type === supportedFileTypes.OTH) {
      return `${file.name}`;
    }
    if (file.type === fileNameType) {
      const newName = file.name.replace(`.${fileNameType}`, '');
      return `${newName}.${file.type}`;
    }
    return `${file.name}.${file.type}`;
  };

  const checkForErrors = (data) => {
    const errors = [];
    if (!data.name) {
      errors.push({
        field: 'name',
        message: (t('field_error.BLANK', { field: t('modals.new_workfile.name') })),
      });
    }

    if (data.name?.length > validationLengths.NAME) {
      errors.push({
        field: 'name',
        message: (t('field_error.TOO_LONG', { field: t('modals.new_workfile.name'), count: validationLengths.NAME })),
      });
    }

    if (data.name && invalidCharactersIn(data.name)) {
      errors.push({
        field: 'name',
        message: (
          t('field_error.INVALID_CHARACTERS', {
            field: t('modals.new_workfile.name'),
            characters: invalidCharacters,
          })),
      });
    }

    if (data.name && invalidFileNames(data.name)) {
      errors.push({
        field: 'name',
        message: (t('field_error.INVALID_NAME')),
      });
    }
    return errors;
  };

  const {
    createNewFile,
    isLoading,
    isError,
    error,
    resetResponse,
  } = useNewFile();

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleSubmitting = () => {
    setIsBusy(true);
    const errors = checkForErrors(file);
    setValidationErrors(errors);

    if (errors.length > 0) {
      setIsBusy(false);
      return;
    }

    const fileData = {
      workfile: {
        workspace: {
          id: workspaceId(),
        },
        file_name: createFilename(),
        folder: file.folderLocation,
        source: 'empty',
        content: '',
      },
    };

    createNewFile(fileData, {
      onSuccess: ({ response }) => {
        setIsBusy(false);
        handleModalClose();
        const newFileUrl = workspaceFileListElementUrl(
          response.id,
          response.file_type,
          response.folder,
          response.file_name,
        );
        window.location.href = newFileUrl;
      },
      onError: () => {
        setIsBusy(false);
      },
    });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="file alternate" title="file" />
        <Header.Content content={headerText} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label htmlFor="name">
              {t('modals.new_workfile.name')}
              {hasError(validationErrors, validationFields.NAME) && (
                <FormValidationError errorMsg={(findErrors(validationErrors, validationFields.NAME)[0]).message} />
              )}
            </label>
            <Form.Input
              id="name"
              autoFocus
              placeholder={t('modals.new_workfile.name_placeholder')}
              maxLength={validationLengths.NAME}
              value={file.name}
              onChange={handleNameChange}
              error={hasError(validationErrors, validationFields.NAME)}
            />
          </Form.Field>
          <FolderLocationDropdown
            baseFolderPath={baseFolderPath}
            handleDropdownChange={(e, { value }) => setFile({ ...file, folderLocation: value })}
            value={file.folderLocation}
            isFile
          />
          {!fixedFileType && (
            <FileTypeDropdown
              handleDropdownChange={(e, { value }) => setFile({ ...file, type: value })}
              value={file.type}
            />
          )}
        </Form>
        {' '}

      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmitting}
        isValidEntry={isValidEntry}
        error={validationErrors.length > 0}
        submitText={t('actions.create_workfile')}
      />
    </>
  );
};

export default NewFileModal;
