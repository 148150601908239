import { useQuery } from '@tanstack/react-query';
import urls from '../../constants/api_urls';
import { getter } from '../../adapters/service';

const useAnnouncements = () => {
  const fetchAnnouncements = () => getter(urls.ANNOUNCEMENTS);
  const { data, error, status } = useQuery(['announcement'], fetchAnnouncements);

  return {
    announcements: (data && data.data) ? data.data.serviceBanners : [],
    isError: error,
    isLoading: status === 'loading',
  };
};

export default useAnnouncements;
