import React from 'react';
import { Form, Checkbox } from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';

const FormDataUpload = ({ dataUpload, handleDataUploadChange }) => (
  <Form.Field inline>
    <Checkbox
      id="data_upload"
      label={t('upload_token.upload_form.for_data_upload')}
      checked={dataUpload}
      onChange={handleDataUploadChange}
    />
  </Form.Field>
);

const mapModelsToProps = ({ uploadToken }) => ({
  dataUpload: uploadToken.get('dataUpload'),
  handleDataUploadChange: (_e, value) => {
    uploadToken.updateDataUpload(value.checked);
  },
});

const options = {
  debounce: true,
  events: {
    uploadToken: ['change:dataUpload'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(FormDataUpload);
