import { blockname, isCancel, cancelTokenSource, getBlockList, commitBlockList, put, getUploadParams, createTriggerScan } from './operations';

/* eslint no-mixed-operators: ["error", {"allowSamePrecedence": true}] */

const BLOCK_BLOB_MAX_STAGE_BLOCK_BYTES = 1024 * 1024 * 100;
const BLOCK_BLOB_MAX_BLOCKS = 50000;
const DEFAULT_BLOB_BLOCK_BYTES = 1024 * 1024 * 10;
const DEFAULT_FILE_BLOCK_BYTES = 1000 * 1000 * 4;

function withConcurrency(n, f) {
  return Promise.all(Array(n).fill(f).map(fn => fn()));
}

function createStepFunction(n, fn) {
  let i = 0;

  const step = () => {
    if (i === n) {
      return Promise.resolve();
    }
    return fn(i++).then(step);
  };
  return step;
}

function progressTracker(emit) {
  let progress = 0;

  return (length) => {
    progress += length;
    emit(progress);
    return length;
  };
}

function createUploadPart(paramsFactory, size, progress, uploadedParts) {
  const blockSize = Math.max(Math.ceil(size / BLOCK_BLOB_MAX_BLOCKS), DEFAULT_BLOB_BLOCK_BYTES);
  const numBlocks = Math.floor((size - 1) / blockSize) + 1;

  return {
    numBlocks,
    stepFn: createStepFunction(numBlocks, (i) => {
      const start = blockSize * i;
      const end = i === numBlocks - 1 ? size : start + blockSize;
      const contentLength = end - start;
      const name = blockname(i);

      if (uploadedParts.find(part => (part.name === name))) return Promise.resolve(progress(contentLength));

      return paramsFactory(name, start, contentLength).then(put).then(() => progress(contentLength));
    }),
  };
}

function upload(uploadPart) {
  return withConcurrency(2, uploadPart);
}

export function validateFile(file) {
  if (file.isRemote) {
    throw new Error('Remote files are not supported');
  }

  if (file.size > BLOCK_BLOB_MAX_STAGE_BLOCK_BYTES * BLOCK_BLOB_MAX_BLOCKS) {
    throw new Error(`${file.size} is too large to upload.`);
  }
}

function uploadPath(file) {
  if (file.meta && file.meta.relativePath) {
    const path = file.meta.relativePath;
    if (path[0] === '/') {
      return path.slice(1);
    }
    return path;
  }
  return file.name;
}

export class BlobUploader {
  constructor({
    success, progress, error, presignEndpoint, prefix, file, batchId
  }) {
    this.file = file;
    this.success = success;
    this.progress = progress;
    this.error = error;
    this.cancelToken = cancelTokenSource();
    this.current = null;
    const path = uploadPath(file);
    this.getParams = getUploadParams(presignEndpoint, `${prefix}/${path}`, this.file.type, this.cancelToken.token, batchId);
    this.triggerScan = createTriggerScan(presignEndpoint, `${prefix}/${path}`, this.file.type, batchId);
    this.uploadParams = (name, start, contentLength) => this.getParams().then(({ url, headers }) => ({
      url: `${url}&comp=block&blockid=${name}`,
      headers,
      data: this.file.data.slice(start, start + contentLength),
      cancelToken: this.cancelToken.token,
    }));
    this.handleErrorOrCancel = this.handleErrorOrCancel.bind(this);
    this.uploadRemaining = this.uploadRemaining.bind(this);
    this.commit = this.commit.bind(this);
    this.pauseResume = this.pauseResume.bind(this);
    this.createBlob = this.createBlob.bind(this);
  }

  start() {
    if (this.file.size === 0) {
      this.current = this.createBlob()
        .then(this.triggerScan)
        .then(this.success)
        .catch(this.handleErrorOrCancel)
        .then(() => { this.current = null; });
    } else {
      this.current = this.listParts()
        .then(this.uploadRemaining)
        .then(this.triggerScan)
        .then(this.success)
        .catch(this.handleErrorOrCancel)
        .then(() => { this.current = null; });
    }
  }

  createBlob() {
    return this.getParams().then(({ url, headers }) => put({
      url,
      headers: Object.assign({}, headers, {
        'x-ms-content-length': this.file.size,
        'x-ms-type': 'file',
        'x-ms-content-type': 'application/octet-stream',
      }),
      data: '',
      cancelToken: this.cancelToken.token,
    }));
  }

  cancel() {
    this.cancelToken.cancel();
    this.cancelToken = cancelTokenSource();
  }

  pauseResume(isPaused) {
    if (isPaused) {
      this.cancel();
    } else if (this.current) {
      this.current.then(this.start);
    } else {
      this.start();
    }
  }

  //----

  listParts() {
    return this.getParams().then(getBlockList);
  }

  uploadRemaining(parts) {
    const { numBlocks, stepFn } =
      createUploadPart(this.uploadParams, this.file.size, progressTracker(this.progress), parts);
    return upload(stepFn).then(() => (this.commit(numBlocks)));
  }

  commit(numBlocks) {
    return this.getParams()
      .then(({ url }) => (
        commitBlockList({
          url: `${url}&comp=blocklist`,
          headers: {
            'x-ms-blob-content-type': this.file.name.endsWith('.svg') ? 'image/svg+xml' : 'application/octet-stream',
          },
          numBlocks,
          cancelToken: this.cancelToken.token,
        })));
  }

  handleErrorOrCancel(e) {
    if (!isCancel(e)) {
      this.error(e);
    }
  }
}
