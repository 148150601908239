import Collection from './workspace_token_collection';

export default Collection.extend({
  urlTemplate: 'api/consoles/sessions',
  constructorName: 'ShellSessions',

  getSelected() {
    return this.models.find(m => m.get('selected')) || this.models[0];
  },

  getSelectedId() {
    const model = this.getSelected();
    return model ? model.get('id') : 'r';
  },

  urlParams() {
    const params = {};

    if (this.attributes.workspace && this.attributes.workspace.has('activeRSessionId')) {
      params.session_id = this.attributes.workspace.get('activeRSessionId');
      params.session_status = this.attributes.workspace.get('activeRSessionStatus') || 'okay';
    }

    return params;
  },

});
