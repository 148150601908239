import chorus from '../../chorus';
import _ from '../../underscore';
import t from '../../intl';
import Dialog from '../../dialogs/dialogs';
import MiniAppSet from '../../collections/mini_app';
import TemplateSet from '../../collections/template_set';
import Workfile from '../../models/workfile';
import ShinyImages from '../../collections/shiny_images';
import DialogFormHelpers from '../../mixins/dialog_form_helpers';
import Routing from '../../mixins/routing';

export default Dialog.include(DialogFormHelpers, Routing).extend({

  templateName: 'mini_apps/create_app_dialog',

  title: t('mini_app.button.create'),

  persistent: true,

  makeModel() {
    this.collection = new MiniAppSet([], { workspaceID: this.options.workspaceId });
  },

  setup() {
    this.listenTo(this.collection, 'saved', this.saved);
    this.listenTo(this.collection, 'saveFailed', this.saveFailed);
    this.disableFormUnlessValid({
      formSelector: 'form',
      inputSelector: 'input[name=name]',
    });
    this.templateList = new TemplateSet();
    this.templateList.fetch();
    this.requiredResources.add(this.templateList);

    this.images = new ShinyImages();
    this.images.fetchIfNotLoaded();
    this.requiredResources.add(this.images);
  },

  additionalContext() {
    let i = 1;
    this.images.each((model) => {
      i++;
      const version = model.get('id').split('-')[0];
      model.set({ version });
    });
    return {
      templates: _.map(this.templateList.models, model => model.attributes),
      images: _.map(this.images.models, model => model.attributes),
    };
  },

  postRender() {
    _.delay(_.bind(function setFocus() {
      this.$('.app-modal-create-mini-app').focus();
    }, this), 100);
  },

  create() {
    this.$('button.submit').startLoading('actions.adding');

    const appName = this.$('input[name=name]').val().trim();
    const templateName = this.$('select[name=template]').val().trim();
    const consoleImage = this.$('select[name=image]').val().trim();

    this.app = this.collection.create({ name: appName, template: templateName, image: consoleImage });
  },

  saved() {
    this.closeModal();

    // Get all the newly created workfiles
    const workfiles = this.collection.at(0).get('workfiles');

    if (workfiles) {
      workfiles.forEach((workfile) => {
        // Check if a new workfile, one level deep, has been created that matches a
        // item in the filesToOpenAfterSave array.
        if (workfile.contentType === 'r') {
          // Create a new workfile model and set our new data, then navigate to the tab
          const file = new Workfile({ workspace: { id: this.options.workspaceId } });
          file.set(workfile);

          this.navigate(file.showUrl());
        }
      });
    }

    chorus.PageEvents.trigger('mini_app:created', { app: this.app.get('app') });
    chorus.toast('mini_app.create.success.notification');
    chorus.PageEvents.trigger('workfiles:changed');
  },

  saveFailed() {
    this.$('button.submit').stopLoading();
  },
});
