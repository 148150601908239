import WorkspaceDataset from './workspace_dataset';
import Model from './models';

export default Model.extend({
  initialize(attrs, options) {
    this.options = options || {};
    this.from = this.options.from;
  },

  databaseView() {
    // server response to the 'convert' call is the created db view,
    // so this model's attributes suffice to build a dataset
    return new WorkspaceDataset(this.attributes);
  },

  url() {
    return `/api/workspaces/chorus_views/${this.from.get('id')}/convert`;
  },
});
