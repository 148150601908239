import { t } from 'i18n-js';
import {
  Header, Segment, Form, Input, Dimmer,
} from 'semantic-ui-react';
import { useDatabaseTableCreationContext, useDatabaseTableCreationQueryContext } from '../Context';
import { validationLengths } from '../../../../constants/validation';
import { validTableName } from '../../../../utilities/validation';
import DatabaseTableCreationCSVSelect from './DatabaseTableCreationCSVSelect';
import { DatabaseTableCreationTableInvalidNameWarning, DatabaseTableCreationTableCsvIsEmpty } from './DatabaseTableCreationTableWarnings';
import Loader from '../../../../components/Loader';
import { setTableNameFromFile } from '../actions';

const DatabaseTableCreationTableFromCSV = () => {
  const { newDatabaseTableState, dispatch } = useDatabaseTableCreationContext();
  const { isFetchingTDF } = useDatabaseTableCreationQueryContext();
  const tableName = newDatabaseTableState.table.data_table_name_from_file;
  const fileHasContent = Boolean(newDatabaseTableState.table.source_file_content);
  const showWarning = !!(tableName && !validTableName(tableName) && fileHasContent);
  const showEmptyWarning = !!(tableName && !fileHasContent);

  const handleNameChange = (e, { value }) => {
    dispatch(setTableNameFromFile(value));
  };

  return (
    <>
      {isFetchingTDF && (
        <Dimmer inverted active>
          <Loader inverted />
        </Dimmer>
      )}
      <Segment vertical basic>
        <Header
          as="h5"
          content={t('database_tables_new.new_table.from_csv.header')}
          className="italic"
          textAlign="center"
          color="grey"
        />
      </Segment>
      <Segment vertical basic>
        <Form>
          <DatabaseTableCreationCSVSelect showEmptyWarning={showEmptyWarning} />
          {newDatabaseTableState.table.source_file
            && (
            <Form.Field
              className={(showWarning || showEmptyWarning) ? 'warning' : ''}
            >
              <label htmlFor="selectedName">
                {t('database_tables_new.new_table.from_csv.label_name')}
                *
              </label>
              <Input
                id="selectedName"
                placeholder={t('database_tables_new.new_table.from_csv.placeholder_name')}
                maxLength={validationLengths.NAME}
                value={tableName}
                onChange={handleNameChange}
              />
            </Form.Field>
            )}
        </Form>
        {showWarning && (
          <DatabaseTableCreationTableInvalidNameWarning />
        )}
        {showEmptyWarning && (
          <DatabaseTableCreationTableCsvIsEmpty />
        )}
      </Segment>
    </>
  );
};

export default DatabaseTableCreationTableFromCSV;
