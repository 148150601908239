import ChartTask from './chart_task';

export default ChartTask.extend({
  chartType: 'frequency',
  columnLabels: {
    bucket: 'dataset.visualization.frequency.bucket',
    count: 'dataset.visualization.frequency.count',
  },

  beforeSave() {
    this._super('beforeSave');
    this.set({
      yAxis: this.get('yAxis'),
      bins: this.get('bins'),
    });
  },
});
