import PickItems from '../pick_items_dialog';
import t from '../../intl';
import WorkspaceDatasetSet from '../../collections/workspace_dataset_set';
import _ from '../../underscore';
import $ from '../../jquery';

export default PickItems.extend({
  title: t('dataset.meta.copy_from_dataset.dialog_title'),
  dialogDescriptionKey: 'dataset.meta.copy_from_dataset.dialog_description',
  constructorName: 'MetadataCopyFromDataset',
  submitButtonTranslationKey: 'actions.confirm',
  emptyListTranslationKey: 'dataset.none',
  searchPlaceholderKey: 'dataset.dialog.search',
  selectedEvent: 'dataset:selected',
  modelClass: 'WorkspaceDataset',
  multiSelection: false,

  makeModel(...args) {
    this._super('makeModel', args);
    this.options = _.extend({
      collection: new WorkspaceDatasetSet([], {
        workspaceId: this.options.workspaceId,
        withMetadata: this.options.withMetadata,
        excludeDataset: this.options.excludeDataset,
      }),
    }, this.options);
  },

  setup(...args) {
    this._super('setup', args);
    this.datasetMeta = this.options.pageModel;

    const onTextChangeFunction = _.debounce(_.bind(function search(e) {
      this.paginationView.collection.attributes.namePattern = $(e.target).val();
      this.paginationView.fetchFirstPage();
    }, this), 300);

    this.options = _.extend({
      onTextChange: onTextChangeFunction,
    }, this.options);
    this.paginationView.fetchFirstPage();
  },

  collectionModelContext(model) {
    return {
      name: model.get('objectName'),
      imageUrl: model.iconUrl({ size: 'icon' }),
      metaDescription: model.get('description') || t('dataset.meta.copy_from_dataset.list_item_no_description'),
    };
  },
});
