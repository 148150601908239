import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient, getter, poster } from '../adapters/service';
import { commentUrl, noteUrl, workspaceActivity } from '../constants/api_urls';
import serverErrorMessage from '../utilities/server_errors';
import { createMessage, findMember } from './helper';
import { useWorkspaceAdmin } from '../adapters/Workspace';

export const invalidateActivity = (entityId) => {
  queryClient.invalidateQueries(['activities', entityId]);
};

export const useActivities = (uuid, entityType, entityId, page, perPage) => {
  const { workspace } = useWorkspaceAdmin(uuid);
  const fetchActivity = () => getter(workspaceActivity(entityType, entityId, page, perPage));
  const {
    data, isFetching, refetch, isError,
  } = useQuery(['activities', entityId], fetchActivity);

  const getAuthor = (activity) => {
    const user = findMember(workspace.members, activity.actor);
    const comments = activity.comments?.map((comment) => ({
      ...comment,
      user: findMember(workspace.members, comment.author),
    }));
    return { user, comments };
  };

  const addUserAndMessage = (activity) => ({
    ...activity,
    ...getAuthor(activity),
    ...createMessage(activity),
  });

  const activities = data?.response.map(addUserAndMessage);

  return {
    activities,
    isFetching,
    refetch,
    isError,
  };
};

export const useComment = (entityId) => {
  const postFile = (body) => poster(commentUrl(), body);
  const {
    mutate,
    isError,
    isLoading,
    error,
    reset,
  } = useMutation((body) => postFile(body), {
    onSuccess: () => invalidateActivity(entityId),
  });

  return {
    addComment: mutate,
    isLoading,
    isError,
    error: error?.message || serverErrorMessage(error),
    resetResponse: reset,
  };
};

export const useNote = (entityId) => {
  const postNote = (body) => poster(noteUrl(), body);
  const {
    mutate,
    isError,
    isLoading,
    error,
    reset,
  } = useMutation((body) => postNote(body), {
    onSuccess: () => invalidateActivity(entityId),
  });

  return {
    addNote: mutate,
    isLoading,
    isError,
    error: error?.message || serverErrorMessage(error),
    resetResponse: reset,
  };
};
