import moment from 'moment';
import chorus from '../../../chorus';
import $ from '../../../jquery';
import MiniAppsSidebarInfo from './mini_apps_sidebar_info';
import MiniAppsSidebarWorkfiles from './mini_apps_sidebar_workfiles';
import MiniAppDelete from '../../../alerts/mini_app_delete_alert';
import TabControl from '../../core/tab_control_view';
import Sidebar from '../../core/sidebar_view';
import RenameMiniApp from '../../../dialogs/mini_apps/rename_mini_app_dialog';
import Routing from '../../../mixins/routing';

export default Sidebar.include(Routing).extend({
  entityType: 'MiniAppsSidebar',
  templateName: 'mini_apps/apps-sidebar',

  events: {
    'click .refresh-apps': 'refreshApps',
    'click .delete-app': 'deleteApp',
    'click .publish-app': 'publishApp',
    'click .unpublish-app': 'unpublishApp',
    'click .upload-app': 'uploadToMiniApp',
    'click .preview-app': 'previewMiniApp',
    'click .rename-app': 'renameApp',
  },

  subviews: {
    '.tab_control': 'tabs',
  },

  setup(options) {
    this.allApps = options.apps;
    this.listenTo(this.allApps, 'appsLoaded', this.render);
    this._super('setup', options);
  },

  additionalContext() {
    const context = {
      onlyShowPublished: false,

    };
    if (this.model) {
      if (this.model.latestFileModificationTimestamp() && this.model.get('lastPublished')) {
        context.modifiedAfterPublishedorUnpublished = (
          moment(this.model.latestFileModificationTimestamp()).isAfter(moment(this.model.get('lastPublished'))));
      }
      context.modifiedAfterPublishedorUnpublished = (
        context.modifiedAfterPublishedorUnpublished ||
                !this.model.get('published')
      );
    }
    return context;
  },

  selectApp(model) {
    this.model = model;
    this.resource = model;
    this.setupTabs();
    this.render();
  },

  refreshApps(e) {
    if (e) { e.preventDefault(); }
    this.allApps.trigger('refresh');
    return false;
  },

  deleteApp(e) {
    if (e) { e.preventDefault(); }
    new MiniAppDelete({ model: this.model }).launchModal();
    return false;
  },

  publishApp(e) {
    $(e.currentTarget).attr('disabled', true).toggleClass('loading');

    this.model.publish({
      success: function onSuccess() { this.resetButton(e); }.bind(this),
      errors: function onFail() { this.resetButton(e); }.bind(this),
    });

    return false;
  },

  setupTabs() {
    this.setupAllTabs();
    this.registerSubView(this.tabs);
  },

  setupPublishedTabs() {
    this.tabs = new TabControl(['information']);
    this.tabs.information = new MiniAppsSidebarInfo({ model: this.model, workspace: this.options.workspace });
  },

  setupAllTabs() {
    this.tabs = new TabControl(['information', 'workfiles']);
    this.tabs.information = new MiniAppsSidebarInfo({ model: this.model, workspace: this.options.workspace });
    this.tabs.workfiles = new MiniAppsSidebarWorkfiles({
      model: this.model, workspaceId: this.options.workspace.id,
    });
  },

  renameApp() {
    if (!this.model.get('published')) {
      new RenameMiniApp({ model: this.model }).launchModal();
    }
    return false;
  },

  unpublishApp(e) {
    $(e.currentTarget).attr('disabled', true).toggleClass('loading');

    this.model.unpublish({
      success: function onSuccess() { this.resetButton(e); }.bind(this),
      errors: function onFail() { this.resetButton(e); }.bind(this),
    });

    return false;
  },

  uploadToMiniApp(e) {
    if (e) { e.preventDefault(); }
    this.navigate(`workspaces/${this.options.workspace.id}/upload/${+ new Date()}/files/${this.model.name()}`);
    return false;
  },

  previewMiniApp(e) {
    $(e.currentTarget).attr('disabled', true).toggleClass('loading');

    this.model.preview({
      success: function onSuccess() {
        this.resetButton(e);
        chorus.PageEvents.trigger('mini_app:run');
      }.bind(this),
      errors: function onFail() {
        this.resetButton(e);
      }.bind(this),
    });


    return false;
  },

  resetButton(e) {
    $(e.currentTarget).attr('disabled', false).toggleClass('loading');
  },

});
