import { useState } from 'react';
import { Container, Message } from 'semantic-ui-react';
import Cookies from 'js-cookie';
import { t } from 'i18n-js';
import { getDomain } from '../../../utilities/helpers';
import './Announcement.less';
import AnnouncementIcon from './AnnouncementIcon';
import AnnouncementContent from './AnnouncementContent';
import AnnouncementCount from './AnnouncementCount';
import AnnouncementNav from './AnnouncementNav';

const Announcement = ({ announcements }) => {
  const [dismissed, setDismissed] = useState(Cookies.get('announcementDismissedTime'));
  const [index, setIndex] = useState(1);
  let allAnnouncements = announcements;

  if (dismissed) {
    allAnnouncements = announcements.filter((a) => new Date(a.publishedAt).getTime() >= dismissed);
  }

  const total = allAnnouncements ? allAnnouncements.length : 0;

  const handleDismiss = () => {
    const now = new Date();
    const future = new Date();
    future.setDate(now.getDate() + 30);
    // js-cookie package was not setting the cookie, applying an expired value rejected it, using pure javascript works
    // eslint-disable-next-line max-len
    document.cookie = `announcementDismissedTime=${now.getTime()};expires=${future.toUTCString()};path=/;domain=${getDomain()}`;
    setDismissed(now.getTime());
  };

  if (!total) {
    return null;
  }

  const announcement = allAnnouncements[(index - 1)];

  return (
    <div
      className="aridhia-alert"
      role="alert"
      aria-label={t('accessibility.announcement')}
    >
      <Container>
        <Message icon onDismiss={handleDismiss}>
          <AnnouncementIcon level={announcement?.messageStatus} />
          <AnnouncementContent announcement={announcement} />
          {total > 1
            && <AnnouncementCount index={index} total={total} />}
          <AnnouncementNav index={index} total={total} setIndex={setIndex} />
        </Message>
      </Container>
    </div>
  );
};

export default Announcement;
