/* eslint-disable camelcase */
import { Button, Icon } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { useWorkspaceContext } from '../../../../adapters/Workspace';
import { useDatabaseTableContext } from '../../../Context';
import {
  canAnalyze, isCustomView, isImporting, isTable,
} from '../../../helper';
import { airlockDatabaseTable, analyseDatabaseTableData, newView } from '../../../../constants/ui_urls';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import SaveAsCSVModal from '../../../components/modals/SaveAsCSVModal';
import DeleteTableModal from '../../../components/modals/DeleteTableModal';
import PreviewTableModal from '../../../components/modals/PreviewTableModal';
import Can from '../../../../permissions';
import TableIcon from '../../../components/TableIcon';
import ButtonGroupWrapper from '../../../../components/buttons/ButtonGroupWrapper';
import { supportedTableTypes } from '../../../constants';
import NewDBViewModal from '../../../components/modals/NewDBViewModal';
import { calculateIsNarrow } from '../../../../helpers/panel_widths';

const AnalyseDataButton = ({ isNarrow, tableId }) => (
  <Button href={analyseDatabaseTableData(tableId)} title={isNarrow ? t('tables_list.actions.analyse_data') : null}>
    <Icon name="chart pie" className={isNarrow ? 'margin-none' : null} />
    {!isNarrow && t('tables_list.actions.analyse_data')}
  </Button>
);

const NewViewButton = ({ isNarrow, tableId }) => (
  <Button href={newView(tableId)} title={isNarrow ? t('tables_list.actions.new_view') : null}>
    <TableIcon tableType={supportedTableTypes.CHORUS_VIEW} className={isNarrow ? 'margin-none' : 'margin-right-tiny'} />
    {!isNarrow && t('tables_list.actions.new_view')}
  </Button>
);

const AirlockButton = ({ isNarrow, tableId }) => (
  <Button href={airlockDatabaseTable(tableId)} title={isNarrow ? t('tables_list.actions.airlock') : null}>
    <Icon name="unlock" className={isNarrow ? 'margin-none' : null} />
    {!isNarrow && t('tables_list.actions.airlock')}
  </Button>
);

const DatabaseTableHeaderButtons = () => {
  const { workspace: { can_airlock } } = useWorkspaceContext();
  const {
    table,
    tableId,
    tableType,
    panelWidth,
  } = useDatabaseTableContext();
  const isNarrow = calculateIsNarrow(panelWidth);

  const isNotImporting = !isImporting(table.import_status, table.import_progress);
  const analyseEnabled = canAnalyze(table.import_status, table.import_progress, tableType);
  const saveAsCSVEnabled = !isCustomView(tableType) && isNotImporting;

  const previewDataButton = (
    <Button title={isNarrow ? t('tables_list.actions.preview_data') : null}>
      <Icon name="eye" className={isNarrow ? 'margin-none' : null} />
      {!isNarrow && t('tables_list.actions.preview_data')}
    </Button>
  );

  const saveAsCSVButton = (
    <Button title={isNarrow ? t('tables_list.actions.save_as_csv') : null}>
      <Icon name="share" className={isNarrow ? 'margin-none' : null} />
      {!isNarrow && t('tables_list.actions.save_as_csv')}
    </Button>
  );

  const deleteTableButton = (
    <Button title={isNarrow ? t('tables_list.actions.delete') : null}>
      <Icon name="trash alternate" className={isNarrow ? 'margin-none' : null} />
      {!isNarrow && t('tables_list.actions.delete')}
    </Button>
  );

  const newDBViewButton = (
    <Button title={isNarrow ? t('tables_list.actions.new_db_view') : null}>
      <TableIcon tableType={supportedTableTypes.VIEW} className={isNarrow ? 'margin-none' : null} />
      {!isNarrow && t('tables_list.actions.new_db_view')}
    </Button>
  );

  return (
    <ButtonGroupWrapper testId="database-table-menu">
      {isNotImporting && (
        <Can I="Read" this="WorkspaceDatabase">
          <ModalWrapper trigger={previewDataButton} size="large">
            <PreviewTableModal />
          </ModalWrapper>
        </Can>
      )}
      {analyseEnabled && (
        <Can I="Write" this="WorkspaceDatabase">
          <AnalyseDataButton isNarrow={isNarrow} tableId={tableId} />
        </Can>
      )}
      {isNotImporting && isTable(tableType) && (
        <Can I="Write" this="WorkspaceDatabase">
          <NewViewButton isNarrow={isNarrow} tableId={tableId} tableType={tableType} />
        </Can>
      )}
      {isNotImporting && isCustomView(tableType) && (
        <Can I="Write" this="WorkspaceDatabase">
          <ModalWrapper trigger={newDBViewButton}>
            <NewDBViewModal />
          </ModalWrapper>
        </Can>
      )}
      {saveAsCSVEnabled && (
        <Can I="Export" this="WorkspaceDatabase">
          <ModalWrapper trigger={saveAsCSVButton} size="small">
            <SaveAsCSVModal />
          </ModalWrapper>
        </Can>
      )}
      {isNotImporting && can_airlock && (
        <Can I="Create" a="WorkspaceAirlock">
          <AirlockButton isNarrow={isNarrow} tableId={tableId} />
        </Can>
      )}
      <Can I="Write" this="WorkspaceDatabase">
        <ModalWrapper trigger={deleteTableButton}>
          <DeleteTableModal />
        </ModalWrapper>
      </Can>
    </ButtonGroupWrapper>
  );
};

export default DatabaseTableHeaderButtons;
