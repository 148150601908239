import { t } from 'i18n-js';
import TableHeaderError from '../../../../components/errors/TableHeaderError';

const FilesTableHeaderError = ({ isFilesError, isOfficeError }) => {
  if (isFilesError) {
    return <TableHeaderError />;
  }

  if (isOfficeError) {
    return <TableHeaderError message={t('office.discovery.failed_to_load')} severity="warning" />;
  }

  return null;
};

export default FilesTableHeaderError;
