import React from 'react';

import { Form } from 'semantic-ui-react';

/*
  Form.Input with browser and LastPass autocompletion disabled.
  Also allows refs, which can't normally be used with Form.Input.
*/
const NoCompletionInput = React.forwardRef((
  { children, ...props },
  ref,
) => (
  <Form.Input autoComplete="off" {...props}>
    <input data-lpignore ref={ref} />
    {children}
  </Form.Input>
));

export default NoCompletionInput;
