import { t } from 'i18n-js';
import { Icon, Button } from 'semantic-ui-react';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import AddNoteModal from '../../../components/modals/AddNoteModal';
import RefreshButton from '../../../../components/buttons/RefreshButton';
import ButtonGroupWrapper from '../../../../components/buttons/ButtonGroupWrapper';
import Can from '../../../../permissions';

const ActivityHeaderButtons = ({ isFetching, refetch, isNarrow }) => {
  const AddNoteButton = (
    <Button disabled title={isNarrow ? t('activity.actions.add_note') : null}>
      <Icon name="sticky note" data-testid="add-note-button-icon" className={isNarrow ? 'margin-none' : null} />
      {!isNarrow && t('activity.actions.add_note')}
    </Button>
  );

  return (
    <Can I="Interact with" this="Workspace">
      <ButtonGroupWrapper>
        <ModalWrapper trigger={AddNoteButton}>
          <AddNoteModal />
        </ModalWrapper>
      </ButtonGroupWrapper>
      <RefreshButton
        isFetching={isFetching}
        refetch={refetch}
        compact
      />
    </Can>
  );
};

export default ActivityHeaderButtons;
