import _ from '../underscore';
import Handlebars from '../vendor/handlebars';
import { linkTo } from '../utilities/url_helpers';

export default {

  encodeUrl(template) {
    const url = Handlebars.compile(template, { noEscape: true })(this.attributes);
    const encodedFragment = new window.URI(url).normalize().toString();

    return `#/${encodedFragment}`;
  },

  // Build the link url for a model based on the urlTemplate method on that model.
  showUrl(...args) {
    if (this.isDeleted()) return null;

    if (!this.showUrlTemplate) {
      throw new Error('No showUrlTemplate defined');
    }

    const template = _.isFunction(this.showUrlTemplate) ?
      this.showUrlTemplate(...args) :
      this.showUrlTemplate;

    return this.encodeUrl(template);
  },

  showLink(text) {
    return linkTo(this.showUrl(), text || this.name());
  },

  // Build the link url for a model based on the urlTemplate method on that model.
  editUrl(...args) {
    if (this.isDeleted()) return null;

    if (!this.editUrlTemplate) {
      throw new Error('No editUrlTemplate defined');
    }

    const template = _.isFunction(this.editUrlTemplate) ?
      this.editUrlTemplate(...args) :
      this.editUrlTemplate;
    return this.encodeUrl(template);
  },

  editLink(text) {
    return linkTo(this.editUrl(), text || this.name());
  },

};
