import React from 'react';
import $ from '../../jquery';
import View from '../react_view';
import Features from 'pages/Features';

export default View.extend({
  bindCallbacks: $.noop,
  className: 'app-featuresShow',

  renderOnce() {
    this.root.render(
      <Features />,
    );
  },
});
