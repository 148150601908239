import { t } from 'i18n-js';
import { Segment, Button } from 'semantic-ui-react';
import { useDatabaseTableCreationContext, useDatabaseTableCreationQueryContext } from '../Context';
import { databaseTableElementUrl } from '../../../../constants/ui_urls';
import { supportedTableTypes } from '../../../constants';
import { steps } from '../constants';
import { decrementStep, incrementStep } from '../actions';
import KnowledgeBaseButton from '../../../../components/buttons/KnowledgeBaseButton';

const DatabaseTableCreationFooter = () => {
  const { newDatabaseTableState, dispatch } = useDatabaseTableCreationContext();
  const { isFetchingTDF } = useDatabaseTableCreationQueryContext();

  const handlePreviousStep = () => {
    dispatch(decrementStep);
  };

  const handleNextStep = () => {
    dispatch(incrementStep);
  };

  return (
    <Segment attached="bottom" className="border-none">
      <KnowledgeBaseButton
        targetUrl={t('url.knowledgebase_creating_datasets')}
        title={t('database_tables_new.footer.knowledge_base')}
        className="float-left margin-left-none"
      />
      {newDatabaseTableState.current_step < steps.LAST && (
        <Button
          floated="right"
          primary
          content={t('database_tables_new.footer.next')}
          icon="arrow right"
          labelPosition="right"
          disabled={!newDatabaseTableState.next_step_enabled || isFetchingTDF}
          onClick={handleNextStep}
        />
      )}
      {(newDatabaseTableState.current_step > steps.FIRST && newDatabaseTableState.current_step < steps.LAST) && (
        <Button
          floated="right"
          icon="arrow left"
          content={t('database_tables_new.footer.back')}
          labelPosition="left"
          onClick={handlePreviousStep}
        />
      )}
      {(newDatabaseTableState.current_step === steps.LAST && newDatabaseTableState.table.dataset_id) && (
        <Button
          floated="right"
          positive
          icon="check"
          content={t('database_tables_new.footer.open_database')}
          labelPosition="right"
          href={databaseTableElementUrl(newDatabaseTableState.table.dataset_id, supportedTableTypes.TABLE)}
        />
      )}
    </Segment>
  );
};

export default DatabaseTableCreationFooter;
