import t from '../intl';
import Confirm from './confirm';

export default Confirm.extend({
  constructorName: 'DataSourceChangedOwner',

  text: t('data_sources.confirm_change_owner.text'),
  ok: t('data_sources.confirm_change_owner.change_owner'),

  setup() {
    this.title = t('data_sources.confirm_change_owner.title', { displayName: this.model.displayName() });
  },

  confirmAlert() {
    this.trigger('confirmChangeOwner', this.model);
    this.closeModal();
  },
});

