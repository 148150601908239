import { useAuditContext } from '../Context';
import { calculateIsNarrow } from '../../helpers/panel_widths';
import ButtonGroupWrapper from '../../components/buttons/ButtonGroupWrapper';
import DownloadWorkspaceAuditModal from '../modals/DownloadWorkspaceAuditModal';

const AuditHeaderButtons = () => {
  const { panelWidth } = useAuditContext();
  const isNarrow = calculateIsNarrow(panelWidth);

  return (
    <ButtonGroupWrapper>
      <DownloadWorkspaceAuditModal isNarrow={isNarrow} />
    </ButtonGroupWrapper>
  );
};

export default AuditHeaderButtons;
