import chorus from '../chorus';
import _ from '../underscore';
import $ from '../jquery';
import Bare from './bare_view';
import Handlebars from '../vendor/handlebars';

export default Bare.extend({
  collectionModelContext: $.noop,
  additionalContext() {
    return {};
  },

  // Bind various callbacks to the view's resource (model or collection)
  bindCallbacks() {
    if (this.resource) {
      this.listenTo(this.resource, 'saveFailed validationFailed', this.showErrors);
      this.listenTo(this.resource, 'validated', this.clearErrors);
      if (!this.persistent) {
        this.listenTo(this.resource, 'reset sort', this.render);

        if (!this.suppressRenderOnChange) {
          this.listenTo(this.resource, 'change', this.render);
        }
      }
    }
  },

  // Remove default bindings from view
  unbindCallbacks() {
    if (this.resource) {
      this.stopListening(this.resource, 'saveFailed', this.showErrors);
      this.stopListening(this.resource, 'validationFailed', this.showErrors);
      this.stopListening(this.resource, 'validated', this.clearErrors);
      this.stopListening(this.resource, 'change', this.render);
      this.stopListening(this.resource, 'reset', this.render);
      this.stopListening(this.resource, 'sort', this.render);
    }
  },

  // A JSON object with view attributes for handlebars to render within.
  context: function context(resource) {
    const res = resource || this.resource;
    let ctx;
    const self = this;

    if (res) {
      ctx = _.clone(res.attributes);
      ctx.resource = res;
      ctx.loaded = res.loaded;
      if (this.collection) {
        ctx.models = _.map(
          this.collection.models,
          model => _.extend({ model }, model.attributes, self.collectionModelContext(model)),
        );
      }
      if (res.serverErrors) ctx.serverErrors = res.serverErrors;
      $.extend(ctx, this.additionalContext(ctx));
    } else {
      ctx = this.additionalContext({});
    }

    ctx.view = self;
    return ctx;
  },

  render(...args) {
    const result = this._super('render', args);
    chorus.placeholder(this.$('input[placeholder], textarea[placeholder]'));
    return result;
  },

  showErrors(model) {
    const self = this;

    const isModal = $(this.el).closest('.dialog').length;

    this.clearErrors();

    const resource = model || this.resource;

    if (resource) {
      _.each(resource.errors, (val, key) => {
        const $input = self.$(`input[name=${key}], form textarea[name=${key}]`);
        self.markInputAsInvalid($input, val, isModal);
      });
      this.displayServerErrors(resource);
    }
  },

  displayServerErrors(model) {
    const context = this.context(model);
    if (context.serverErrors) {
      const errors = Handlebars.VM.invokePartial(
        Handlebars.partials.errorDiv,
        'errorDiv',
        context,
        Handlebars.helpers,
        Handlebars.partials,
      );
      this.$('.errors').replaceWith(errors);
      this.$('.errors').removeClass('hidden');
    }
  },

  markInputAsInvalid($input, message, isModal) {
    const classes = isModal ? 'tooltip-modal rsh-tooltip-warning' : 'tooltip-error rsh-tooltip-warning';
    $input.addClass('has_error');

    $input.qtip({
      content: {
        text: message,
      },
      show: 'mouseover focus',
      hide: 'mouseout blur',
      style: {
        classes,
        tip: {
          width: 12,
          height: 12,
        },
      },
      position: {
        my: 'left center',
        at: 'right center',
        container: this.el,
      },
    });
  },

  clearErrors() {
    this.clearPopupErrors();
    this.$('.errors').empty().addClass('hidden');
  },

  clearPopupErrors() {
    const errors = this.$('.has_error');
    errors.qtip('destroy', true);
    errors.removeData('qtip');
    errors.removeClass('has_error');
  },

  setModel(model) {
    this.unbindCallbacks();
    this.resource = model;
    this.model = model;
    this.bindCallbacks();
  },
});
