import NotFound from '../../../../components/errors/NotFound';
import ResponsivePanel from '../../ResponsivePanel';

const NotFoundPanel = () => (
  <ResponsivePanel>
    {() => (
      <section className="aridhia-panel">
        <NotFound compact />
      </section>
    )}
  </ResponsivePanel>
);

export default NotFoundPanel;
