import { memo } from 'react';
import { useVMListQuery } from './service';
import VirtualMachinePanelContent from './components/VirtualMachinePanelContent';
import { combineHooks } from '../../../../utilities/helpers';
import VirtualMachinesPanel from './components/VirtualMachinesPanel';
import { RequestVirtualMachineButton } from './components/VirtualMachinesHeaderButtons';
import { useIsNarrow } from '../../../layout';

// The intent is for this file to put together state and leave the components defined
// in the components directory for presentation only.
//
// TODO: refactor some of the request vm modal code to conform to this pattern too

/**
 * The panel content for the list of virtual machines.
 * This component connects the query hook to the component that does the presentation
 * There are no parameters.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const VirtualMachineContent = combineHooks(useVMListQuery, VirtualMachinePanelContent);

/**
 * Connects the ResponsiveContext to the trigger button
 *
 * @returns {JSX.Element}
 * @constructor
 */
const RequestVmTrigger = combineHooks(useIsNarrow, RequestVirtualMachineButton);

/**
 * Virtual Machines panel
 * Always worth memo-ising the top component that takes this in.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const VirtualMachines = memo(() => (
  <VirtualMachinesPanel requestVmTrigger={<RequestVmTrigger />}>
    <VirtualMachineContent />
  </VirtualMachinesPanel>
));

export default VirtualMachines;
