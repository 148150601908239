/* eslint-disable max-lines */
import { useState } from 'react';
import { t } from 'i18n-js';
import {
  Modal, Form, Icon, Header,
} from 'semantic-ui-react';
import ModalActionButtons from '../../../../../components/buttons/ModalActionButtons';
import HeaderError from '../../../../../components/errors/HeaderError';
import FormValidationError from '../../../../../components/errors/FormValidationError';
import {
  findErrors, hasError, invalidCharacters, invalidCharactersIn, invalidFileNames,
} from '../../../../../utilities/validation';
import { validationLengths, validationFields } from '../../../../../constants/validation';
import { useRename } from '../../../service';
import { useFileContext } from '../../Context';
import toastMessage from '../../../../../utilities/toast';
import FileTypeDropdown from '../../../../components/FileTypeDropdown';
import { calculateFileNameExtension, calculateSupportedFileType } from '../../../../helper';
import { supportedFileTypes } from '../../../../constants';
import FileNavigation from '../../../../../components/navigation/FileNavigation';
import ModalWrapper from '../../../../../components/modals/ModalWrapper';
import NewFolderSubmodal, { NewFolderSubmodalButton }
  from '../../../../../components/modals/NewFolderSubmodal';

const SaveAsModal = ({ setModalClose, setIsBusy }) => {
  const {
    folder, name, id, type,
  } = useFileContext();
  const calculatedFileType = calculateSupportedFileType(name, type);
  const [newFileName, setNewFileName] = useState(name);
  const [newFileType, setNewFileType] = useState(calculatedFileType);
  const [validationErrors, setValidationErrors] = useState([]);
  const [destinationFolder, setDestinationFolder] = useState(folder);

  const handleNameChange = (e, { value }) => {
    setNewFileName(value);
    const newErrors = validationErrors.filter((err) => err.field !== validationFields.NAME);
    setValidationErrors(newErrors);
  };

  const createFilename = () => {
    const fileExtension = calculateFileNameExtension(newFileName);
    if (newFileType === supportedFileTypes.OTH || newFileType === fileExtension) {
      return newFileName;
    }

    return `${newFileName}.${newFileType}`;
  };

  const checkValidEntry = () => {
    const newName = createFilename();
    if (destinationFolder === folder) {
      return (!((newFileName === '' || newName === name)));
    }
    return (newFileName !== '');
  };

  const isValidEntry = checkValidEntry();

  const checkForErrors = (fileName) => {
    const errors = [];
    if (!fileName) {
      errors.push({
        field: 'name',
        message: (t('field_error.BLANK', { field: t('modals.save_as.new_name') })),
      });
    }

    if (fileName?.length > validationLengths.NAME) {
      errors.push({
        field: 'name',
        message: (t('field_error.TOO_LONG', { field: t('modals.save_as.new_name'), count: validationLengths.NAME })),
      });
    }

    if (fileName && invalidCharactersIn(fileName)) {
      errors.push({
        field: 'name',
        message: (
          t('field_error.INVALID_CHARACTERS', {
            field: t('modals.save_as.new_name'),
            characters: invalidCharacters,
          })),
      });
    }

    if (fileName && invalidFileNames(fileName)) {
      errors.push({
        field: 'name',
        message: (t('field_error.INVALID_NAME')),
      });
    }
    return errors;
  };

  const {
    renameFile,
    isLoading,
    isError,
    error,
    resetResponse,
  } = useRename(id);

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleSubmitting = () => {
    setIsBusy(true);
    const newName = createFilename();
    const errors = checkForErrors(newFileName);
    setValidationErrors(errors);

    if (errors.length > 0) {
      setIsBusy(false);
      return;
    }

    const fileData = {
      workfile: {
        id,
        file_name: newName,
        folder: destinationFolder,
        copy: 'true',
      },
    };

    renameFile(fileData, {
      onSuccess: () => {
        setIsBusy(false);
        handleModalClose();
        toastMessage(
          'success',
          t('file_list.actions.save_as_success', { fileName: name, newName, destinationFolder }),
          'saveAsSuccess',
        );
      },
      onError: () => {
        setIsBusy(false);
      },
    });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="save" title={t('file_list.actions.save_as')} />
        <Header.Content content={t('file_list.actions.save_as')} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <Form className="margin-bottom-none">
          <Form.Field>
            <label htmlFor="name">
              {t('modals.save_as.new_name')}
              {hasError(validationErrors, validationFields.NAME) && (
                <FormValidationError errorMsg={(findErrors(validationErrors, validationFields.NAME)[0]).message} />
              )}
            </label>
            <Form.Input
              id="name"
              autoFocus
              placeholder={t('modals.save_as.name_placeholder')}
              maxLength={validationLengths.NAME}
              value={newFileName}
              onChange={handleNameChange}
              error={hasError(validationErrors, validationFields.NAME)}
            />
          </Form.Field>
          <FileTypeDropdown
            handleDropdownChange={(e, { value }) => setNewFileType(value)}
            value={newFileType}
          />
        </Form>
        <FileNavigation
          currentFolder={destinationFolder}
          handleSetFolder={(activeFolder) => setDestinationFolder(activeFolder)}
        />
      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmitting}
        isValidEntry={isValidEntry}
        error={validationErrors.length > 0}
        submitText={t('file_list.actions.save_as')}
      >
        <ModalWrapper
          size="tiny"
          trigger={NewFolderSubmodalButton}
        >
          <NewFolderSubmodal
            destinationFolder={destinationFolder}
            handleSetFolder={(activeFolder) => setDestinationFolder(activeFolder)}
          />
        </ModalWrapper>
      </ModalActionButtons>
    </>
  );
};

export default SaveAsModal;
