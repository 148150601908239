import React from 'react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';
import { Table } from 'semantic-ui-react';

import EnvVarTableAddRow from './EnvVarTableAddRow';
import EnvVarTableRow from './EnvVarTableRow';

import './EnvVarTable.less';

function EnvVarTableContent({
  entries,
  addEntry,
  updateEntry,
  removeEntry,
  developer,
}) {
  return (
    <Table unstackable compact>
      <Table.Header>
        { developer && <EnvVarTableAddRow onAdd={addEntry} /> }
      </Table.Header>
      <Table.Body>
        { entries.map((entry, i) => (
          <EnvVarTableRow
            key={entry.id}
            entry={entry}
            onUpdate={(partialEntry) => updateEntry(i, partialEntry)}
            onRemove={() => removeEntry(i)}
            developer={developer}
          />
        ))}
      </Table.Body>
    </Table>
  );
}

function NoEnvVarsMessage() {
  return (
    <div className="center">
      <em>{t('app.form.vars.none')}</em>
    </div>
  );
}

function EnvVarTable({
  entries,
  addEntry,
  updateEntry,
  removeEntry,
  developer,
}) {
  const hasRows = developer || entries.length > 0;

  return (
    <div id="app-env-table">
      { hasRows
        ? (
          <EnvVarTableContent
            entries={entries}
            addEntry={addEntry}
            updateEntry={updateEntry}
            removeEntry={removeEntry}
            developer={developer}
          />
        )
        : <NoEnvVarsMessage />}
    </div>
  );
}

const mapModelsToProps = ({ containerisedApp }) => ({
  entries: containerisedApp.get('envVars'),
  developer: containerisedApp.get('developer'),
  addEntry: (name, value) => containerisedApp.addEnvVar(name, value),
  updateEntry: (index, partialEntry) => containerisedApp.updateVar(index, partialEntry),
  removeEntry: (index) => containerisedApp.removeVar(index),
});

const options = {
  debounce: true,
  events: {
    containerisedApp: [
      'change:envVars',
    ],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(EnvVarTable);
