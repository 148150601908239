/* eslint-disable object-curly-newline */
import React from 'react';
import { Segment, Header, Button, Icon } from 'semantic-ui-react';
import { t } from 'i18n-js';
import SecretsTable from './SecretsTable';
import CreateSecret from './CreateSecret';
import { PanelHeader } from '../../layout';
import ButtonGroupWrapper from '../../components/buttons/ButtonGroupWrapper';

const NoSecrets = () => (
  <Segment placeholder>
    <Header as="h3" icon>
      <Icon name="user secret" />
      {t('secrets.empty')}
    </Header>
    <CreateSecret trigger={<Button icon="add" primary content={t('secrets.new')} />} />
  </Segment>
);

const Panel = ({ title, actions, icon, content }) => (
  <div className="semantic ux-update">
    <Segment.Group className="semantic ux-update aridhia-panel-wrapper">
      <PanelHeader
        headerIcon={<Icon name={icon} color="blue" size="large" data-testid="header-icon" />}
        headerText={<Header as="h3" className="truncate" content={title} />}
        headerButtons={(
          <ButtonGroupWrapper>
            {actions}
          </ButtonGroupWrapper>
    )}
      />
      <Segment attached as="article" className="aridhia-panel-content">
        {content}
      </Segment>
    </Segment.Group>
  </div>
);

const SecretsPanel = React.memo(() => (
  <Panel
    title={t('secrets.title')}
    icon="user secret"
    actions={<CreateSecret />}
    content={<SecretsTable empty={<NoSecrets />} />}
  />
));

export default SecretsPanel;
