import { useEffect, useState } from 'react';

const IFramedContent = ({
  content, title, sandbox, className,
}) => {
  const [contentRef, setContentRef] = useState(null);

  useEffect(() => {
    if (contentRef && content) {
      contentRef.contentWindow.document.body.innerHTML = content;
    }
  }, [contentRef, content]);

  return (
    <iframe
      title={title}
      ref={setContentRef}
      sandbox={sandbox}
      className={className}
      data-testid="iframe-content"
    />
  );
};

export default IFramedContent;
