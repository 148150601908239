import chorus from '../../chorus';
import _ from '../../underscore';
import t from '../../intl';
import Dialog from '../../dialogs/dialogs';

export default Dialog.extend({
  constructorName: 'RenameTagDialog',
  templateName: 'rename_tag',
  title: t('rename_tag.title'),

  events: {
    'submit form': 'submit',
    'keyup input': 'checkInput',
  },

  setup() {
    this.listenTo(this.model, 'saveFailed', this.saveFailed);
    this.listenTo(this.model, 'saved', this.saved);
  },

  postRender() {
    this.input = this.$('.rename_tag_input');
    this.submitButton = this.$('button[type=submit]');
  },

  getName() {
    return this.input.val().trim();
  },

  submit(e) {
    e.preventDefault();
    this.submitButton.startLoading('actions.renaming');
    this.model.save({ name: this.getName() }, {
      silent: true,
      unprocessableEntity() {
      // skip the default redirection on unprocessable entity
      },
    });
  },

  saved() {
    this.model.trigger('change');
    chorus.toast('tag.rename', { name: this.model.name() });
    this.closeModal();
  },

  saveFailed() {
    this.submitButton.stopLoading();
    this.showErrors();
  },

  checkInput() {
    this.clearErrors();
    const newAttributes = _.extend(_.clone(this.model.attributes), {
      name: this.getName(),
    });
    const valid = this.model.performValidation(newAttributes);
    if (!valid) {
      this.markInputAsInvalid(this.input, this.model.errors.name, true);
    }
    this.submitButton.prop('disabled', !valid);
  },
});
