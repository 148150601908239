import chorus from '../chorus';
import { createRoot } from 'react-dom/client';
import _ from '../underscore';
import $ from '../jquery';
import Backbone from '../vendor/backbone';
import Events from '../mixins/events';
import { renderTemplate } from '../utilities/handlebars_helpers';
import RequiredResources from '../collections/required_resources';

/* eslint no-underscore-dangle: ["error", { "allow": [
"_events",
"_initializeHeaderAndBreadcrumbs",
"_super",
"_wrapRemove"
] }] */

const Bare = Backbone.View.include(Events).extend(
  {
    constructorName: 'View',
    scrollingRecalculationRequired: null,

    // The order in which setup methods are called on views is as follows:
    // - _configure
    // - _ensureElement
    // - initialize
    // -> preInitialize
    // -> -> makeModel
    // -> _initializeHeaderAndBreadcrumbs
    // -> setup
    // -> bindCallbacks
    // -> bindHotKeys
    // - delegateEvents
    preInitialize(...args) {
      this.makeModel(...args);
      this.resource = this.model || this.collection;
    },

    initialize: function initialize(...args) {
      this.preInitialize(...args);
      chorus.viewsToTearDown.push(this);
      this.subViewObjects = [];

      this._initializeHeaderAndBreadcrumbs();
      this.setup(...args);

      this.bindCallbacks();
      this.bindHotkeys();

      if (this.requiredResources.length !== 0 && this.requiredResources.allResponded()) {
        this.resourcesLoaded();
      }

      this.root = createRoot(this.el);
    },

    _initializeHeaderAndBreadcrumbs: $.noop,
    makeModel: $.noop,
    setup: $.noop,
    postRender: $.noop,
    bindCallbacks: $.noop,
    preRender: $.noop,
    setupSubviews: $.noop,
    resourcesLoaded: $.noop,
    displayLoadingSection: $.noop,
    contentResize: $.noop,
    root: $.noop,

    // Subviews that don't require any configuration should be included in the subviews hash.
    // Subviews can also be manually added (for example within callbacks) using this.registerSubView(view)
    // in the parent view after creating the new view
    //
    // See wiki
    registerSubView(view) {
      if (_.indexOf(this.subViewObjects, view) === -1) {
        this.subViewObjects.push(view);
        chorus.unregisterView(view);
        const assignableView = view;
        assignableView.parentView = this;
      }
    },

    getSubViews() {
      return this.subViewObjects;
    },

    torndown: false,


    teardownSubViews() {
      while (!_.isEmpty(this.subViewObjects)) {
        const subViewObject = this.subViewObjects.pop();
        subViewObject.teardown();
      }
    },

    // Remove a view from the dom, unbind any events, and hopefully remove it from memory.
    teardown(preserveContainer) {
      this.torndown = true;

      chorus.unregisterView(this);
      this.unbind();
      this.stopListening();
      this.undelegateEvents();
      this.requiredResources.cleanUp(this);
      this.$el.unbind();
      if (preserveContainer) {
        $(this.el).children().remove();
        $(this.el).html('');
      } else {
        $(this.el).remove();
      }

      this.teardownSubViews();

      if (this.parentView) {
        const { subViewObjects } = this.parentView;
        const index = subViewObjects.indexOf(this);
        if (index > -1) subViewObjects.splice(index, 1);
        delete this.parentView;
      }
    },

    bindHotkeys() {
      const keydownEventName = `keydown.${this.cid}`;
      _.each(this.hotkeys, _.bind((eventName, hotkey) => {
        $(document).bind(keydownEventName, `${chorus.hotKeyMeta}+${hotkey}`, (event) => {
          chorus.PageEvents.trigger(eventName, event);
        });
      }));

      if (this.hotkeys) {
        chorus.afterNavigate(() => {
          $(document).unbind(keydownEventName);
        });
      }
    },

    context: {},
    subviews: {},

    // Sets backbone view options and creates a listener for completion of requiredResources
    _configure(options, ...args) {
      const opts = [options, ...args];
      let backboneOptions = [{}];
      if (opts.length > 0 && _.isObject(opts[0])) {
        backboneOptions = [opts[0]];
      }
      this._super('_configure', backboneOptions);

      this.requiredResources = new RequiredResources();

      this.listenTo(this.requiredResources, 'allResourcesResponded', function onResponse() {
        this.resourcesLoaded();
        this.render();
      });

      this.requiredResources.reset(options.requiredResources);
    },

    // Creates a modal of a given type and launches it.
    createModal(e, modalType) {
      e.preventDefault();
      const button = $(e.target).closest('button, a');
      let ModalClass = chorus[`${modalType}s`][button.data(modalType)];
      if (typeof ModalClass === 'undefined') {
        ModalClass = chorus[`${modalType}s`][button.data(`${modalType}-general`)];
      }
      const options = _.extend(button.data(), { pageModel: this.model, pageCollection: this.collection });
      const modal = new ModalClass(options);
      modal.launchModal();
    },

    createDialog(e) {
      this.createModal(e, 'dialog');
    },

    createAlert(e) {
      this.createModal(e, 'alert');
    },

    // Render the view and all subviews, if all requiredResources have responded from the server
    // Calls pre/postRender
    render({ teardownSubViews = false } = {}) {
      this.preRender();
      if (teardownSubViews) { this.teardownSubViews(); }

      let evaluatedContext = {};
      if (!this.displayLoadingSection()) {
        if (!this.requiredResources.allResponded()) {
          return this;
        }
        // The only template rendered when loading section is displayed is the loading section itself, so no context is needed.
        evaluatedContext = _.isFunction(this.context) ? this.context() : this.context;
      }

      $(this.el).html(this.template(evaluatedContext))
        .addClass(this.className || '')
        .addClass(this.additionalClass || '')
        .attr('data-template', this.templateName);
      this.renderSubviews();
      this.postRender($(this.el));
      this.renderHelps();

      if (!chorus.contentChanged) {
        chorus.contentChanged = true;
        _.defer(() => {
          chorus.PageEvents.trigger('content:changed');
          chorus.contentChanged = false;
        });
      }
      return this;
    },

    renderSubviews() {
      this.setupSubviews();
      let subviews;
      if (this.displayLoadingSection()) {
        subviews = { '.loading_section': 'makeLoadingSectionView' };
      } else {
        ({ subviews } = this);
      }

      _.each(subviews, function render(property, selector) {
        const subview = this.getSubview(property);
        if (subview) this.registerSubView(subview);
        this.renderSubview(property, selector);
      }, this);
    },

    renderSubviewToElement(element, view, onlyIfDirty) {
      if (element[0] !== view.el) {
        const id = element.attr('id');
        const klass = element.attr('class');
        $(view.el).attr('id', id);
        $(view.el).addClass(klass);
        element.replaceWith(view.el);
      }

      if (!view.requiredResources || view.requiredResources.allResponded()) {
        view.render(onlyIfDirty);
      }
      view.delegateEvents();
    },

    renderSubview(property, selector) {
      const view = this.getSubview(property);
      let sel = selector;
      if (view) {
        if (!sel) {
          _.each(this.subviews, (value, key) => {
            if (value === property) {
              sel = key;
            }
          });
        }
        const element = this.$(sel);
        if (element.length) {
          this.renderSubviewToElement(element, view);
        }
      }
    },

    getSubview(property) {
      return _.result(this, property);
    },

    renderHelps() {
      let classes;
      const helpElements = this.$('.help');
      if (helpElements.length) {
        if ($(this.el).closest('.dialog').length) {
          classes = 'tooltip-help tooltip-modal';
        } else {
          classes = 'tooltip-help';
        }
      }
      _.each(helpElements, (element) => {
        $(element).qtip({
          content: $(element).data('text'),
          show: 'mouseover',
          hide: {
            delay: 1000,
            fixed: true,
            event: 'mouseout',
          },
          position: {
            viewport: $(window),
            my: 'bottom center',
            at: 'top center',
          },
          style: {
            classes,
            tip: {
              width: 20,
              height: 13,
            },
          },
        });
      });
    },

    subscribePageEvent(eventName, callback) {
      const events = chorus.PageEvents._events || {};
      const self = this;
      const alreadyBound = _.any(
        events[eventName],
        subscription => (subscription.callback === callback) && (subscription.context === self),
      );
      if (!alreadyBound) {
        this.listenTo(chorus.PageEvents, eventName, callback);
      }
    },

    template: function template(context) {
      if (this.displayLoadingSection()) {
        return '<div class="loading_section"/>';
      }
      return renderTemplate(this.templateName, context).toString();
    },

    onceLoaded(subject, callback) {
      if (subject.loaded) {
        callback.apply(this);
      } else {
        subject.once('loaded', _.bind(callback, this));
      }
    },

    _wrapRemove(element) {
      return function onRemove() {
        $(element).remove();
      };
    },

    menuClass: '',

    menu(selector, options) {
      const menuElement = this.$(selector);
      chorus.afterNavigate(this._wrapRemove(menuElement));

      const opts = options || {};
      const classes = (opts.classes || '').trim();
      let my = 'top center';
      let offset = 0;

      if (opts.orientation === 'right') {
        my = 'top left';
        offset = 40;
      } else if (opts.orientation === 'left') {
        my = 'top right';
        offset = 40;
      }

      const qtipArgs = {
        content: opts.content,
        show: {
          event: 'click',
          delay: 0,
        },
        hide: 'unfocus',
        position: {
          container: opts.container,
          my,
          at: 'bottom center',
        },
        style: _.extend({
          classes,
          tip: {
            mimic: opts.mimic || 'top center',
            width: 20,
            height: 15,
            offset,
          },
        }, opts.style),
      };

      _.extend(qtipArgs, opts.qtipArgs);

      if (opts.position) {
        _.extend(qtipArgs.position, opts.position);
      }

      if (opts.contentEvents) {
        const self = this;
        qtipArgs.events || (qtipArgs.events = {});
        qtipArgs.events.render = function onRender(event, api) {
          _.each(opts.contentEvents, (callback, contentSelector) => {
            const wrappedCallback = function onCallback(callBackEvent) {
              callBackEvent.preventDefault();
              callBackEvent.stopPropagation();
              callback.call(self, callBackEvent, api);
              api.hide();
            };
            $(api.elements.content).find(contentSelector).click(wrappedCallback);
          });
        };
      }

      menuElement.click((e) => {
        e.preventDefault();
      });

      menuElement.qtip(qtipArgs);
    },
  },
  {
    extended(subclass) {
      const proto = subclass.prototype;
      if (proto.templateName) {
        proto.className = proto.templateName.replace(/\//g, '_');
      }

      _.defaults(proto.events, this.prototype.events);
    },
  },
);

Bare.extend = chorus.classExtend;

export default Bare;
