import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';

const MetadataUrlForm = ({ url, handleUrlChange }) => (
  <Form.Field inline>
    <label htmlFor="url">{t('dataset.meta.url')}</label>
    <Input
      id="url"
      defaultValue={url}
      onBlur={handleUrlChange}
    />
  </Form.Field>
);

const mapModelsToProps = ({ datasetMeta }) => ({
  url: datasetMeta.get('url'),
  handleUrlChange: (_e) => {
    datasetMeta.updateUrl(_e.target.value);
  },
});

const options = {
  debounce: true,
  events: {
    datasetMeta: ['change:informationUrl'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(MetadataUrlForm);
