import t from '../intl';
import ModelDelete from './model_delete_alert';
import Workfile from '../models/workfile';
import { folderUrl } from '../utilities/file';
import {FEATURE} from "../../utilities/features";

export default ModelDelete.extend({
  constructorName: 'WorkfileDelete',

  text: t('workfile.delete.text'),
  ok: t('workfile.delete.button'),

  bindCallbacks: $.noop,
  close: $.noop,

  makeModel() {
    if(!this.collection && !this.model){
      this.model = new Workfile({
        id: this.options.workfileId,
        fileName: this.options.workfileName,
        folder: this.options.folderName,
        fileType: this.options.workfileFolder ? 'folder' : '',
        workspace: { id: this.options.workspaceId },
      });
    }
  },

  deleteModel(e) {
    e.preventDefault();
    if ((this.model && this.model.canDestroy()) || (this.collection && this.collection.canDestroy())) {
      if(this.collection) {
        this.deleteMessage = 'workfile.delete_multiple.toast';
        const total = this.collection.length;
        let current = 0;
        this.collection.each((workfile) => {
          workfile.destroy({
            success: () => {
              current++;
              chorus.PageEvents.trigger(`workfile:deleted`, workfile, current >= total);
              if (current >= total){
                this.modelDeleted();
              }
            },
            error: (model) => {
              current = 0;
              this.destroyFailed(model.attributes.fileName);
            }
          });
        });
      } else {
        this.model.destroy({
          success: (_workfile, response) => {
            if (response.status === 'scheduled') {
              this.deleteMessage = 'workfile.delete.scheduled.toast';
            } else {
              this.deleteMessage = 'workfile.delete.toast';
              if (FEATURE.NEW_UX) {
                this.deleteMessage = 'workfile.delete.toast_new_ux';
              }
            }
            chorus.PageEvents.trigger(`workfile:deleted`, this.model, true);
            this.modelDeleted();
          },
          error: (model) => {
            this.destroyFailed(model.attributes.fileName);
          },
        });
      }
      this.$('button.submit').startLoading('actions.deleting');
    } else {
      this.$('.content').append(`<span>${t('actions.delete_workspace_has_child')}</span>`);
      this.$('button.submit').hide();
    }
  },

  setup() {
    if(this.collection){
      this.title = t('workfile.delete_multiple.title', { workfileCount: this.collection.length });
      this.deleteMessage = 'workfile.delete_multiple.toast';
      this.text = t('workfile.delete_multiple.text');
      this.ok = t('workfile.delete_multiple.button');
      this.redirectUrl = folderUrl(this.collection.models[0]);
    } else if (this.model.isFolder()) {
      this.title = t('workfolder.delete.title', { workfolderTitle: this.model.get('fileName') });
      this.deleteMessage = 'workfolder.delete.toast';
      this.text = t('workfolder.delete.text');
      this.ok = t('workfolder.delete.button');
    } else {
      this.title = t('workfile.delete.title', { workfileTitle: this.model.get('fileName') });
    }
    this.redirectUrl = this.redirectUrl || folderUrl(this.model || this.collection);
  },

  destroyFailed(fileName) {
    this.$('.errors').append(`<ul><li>${t('workfile.delete.failed', { fileName: fileName })}</li></ul><br>`);
    this.$('.errors').removeClass('hidden');
    this.$('button.submit').hide();
  },

  modelDeleted() {
    this.closeModal();
    chorus.toast(this.deleteMessage, this.deleteMessageParams());

    if (this.redirectUrl) {
      this.navigate(this.redirectUrl);
    }
  }
});
