import { useState } from 'react';
import { t } from 'i18n-js';
import {
  Accordion, Dropdown, Checkbox, Input, Form, Icon,
} from 'semantic-ui-react';
import { separatorOptions, encodingOptions } from '../constants';
import { useDatabaseTableCreationContext } from '../Context';
import validationLengths from '../validations';
import { setMetadataAttributes } from '../actions';

const DatabaseTableCreationMetadataSelector = () => {
  const { newDatabaseTableState, dispatch } = useDatabaseTableCreationContext();
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleMetadataChange = (propertyToChange, value) => {
    dispatch(setMetadataAttributes(propertyToChange, String(value)));
  };

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <Accordion>
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={(e, titleProps) => handleClick(e, titleProps)}
      >
        <Icon name="dropdown" />
        {t('database_tables_new.columns.metadata.accordion_label')}
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0}>
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <label htmlFor="metadataSeparator">
                {t('database_tables_new.columns.metadata.separator')}
              </label>
              <Dropdown
                data-testid="separator-options"
                id="metadataSeparator"
                placeholder={t('database_tables_new.columns.metadata.separator')}
                options={separatorOptions}
                onChange={(e, { value }) => handleMetadataChange('delimiter', value)}
                value={newDatabaseTableState.table.metadata.delimiter}
                selection
                compact
                upward={false}
                disabled={newDatabaseTableState.table.typeguessing_in_progress}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="metadataSeparatorOther">
                {t('database_tables_new.columns.metadata.other_separator')}
              </label>
              <Input
                id="metadataSeparatorOther"
                maxLength={validationLengths.OTHERPARAMETER}
                disabled={newDatabaseTableState.table.metadata.delimiter !== 'other' || newDatabaseTableState.table.typeguessing_in_progress}
                value={newDatabaseTableState.table.metadata.other_delimiter}
                onChange={(e, { value }) => handleMetadataChange('other_delimiter', value)}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="metadataEncoding">
                {t('database_tables_new.columns.metadata.encoding')}
              </label>
              <Dropdown
                id="metadataEncoding"
                data-testid="encoding-options"
                options={encodingOptions}
                onChange={(e, { value }) => handleMetadataChange('encoding', value)}
                value={newDatabaseTableState.table.metadata.encoding}
                selection
                compact
                upward={false}
                disabled={newDatabaseTableState.table.typeguessing_in_progress}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="textQualifier">
                {t('database_tables_new.columns.metadata.text_qualifier')}
              </label>
              <Input
                id="textQualifier"
                maxLength={validationLengths.TEXTQUALIFIER}
                value={newDatabaseTableState.table.metadata.text_qualifier}
                onChange={(e, { value }) => handleMetadataChange('text_qualifier', value)}
                disabled={newDatabaseTableState.table.typeguessing_in_progress}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="nullQualifier">
                {t('database_tables_new.columns.metadata.null_qualifier')}
              </label>
              <Input
                id="nullQualifier"
                maxLength={validationLengths.NULLQUALIFIER}
                value={newDatabaseTableState.table.metadata.null_qualifier}
                onChange={(e, { value }) => handleMetadataChange('null_qualifier', value)}
                disabled={newDatabaseTableState.table.typeguessing_in_progress}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="metadataIncludeHeader">
                {t('database_tables_new.columns.metadata.include_header_row')}
              </label>
              <Checkbox
                id="metadataIncludeHeader"
                data-testid="include-header-checkbox"
                name="checkboxIncludeHeaderRow"
                value="true"
                onChange={(e, { checked }) => handleMetadataChange('input_has_header', checked)}
                checked={newDatabaseTableState.table.metadata.input_has_header === 'true'}
                disabled={newDatabaseTableState.table.typeguessing_in_progress}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Accordion.Content>
    </Accordion>
  );
};

export default DatabaseTableCreationMetadataSelector;
