import chorus from '../../chorus';
import _ from '../../underscore';
import t from '../../intl';
import Dialog from '../../dialogs/dialogs';
import DatasetDownloadConfiguration from '../../models/tabular_data_download_configuration';
import { workspace } from '../../utilities/workspace_assoc';
import { normalize } from '../../utilities/csv_parser_utilities';

export default Dialog.extend({
  constructorName: 'DatasetDownload',
  templateName: 'dataset_download',
  additionalClass: 'file_export_csv dialog_medium',
  submitKey: 'actions.export',
  includeHeader: true,
  delimiter: ',',

  events: {
    'click button.submit': 'submitDownload',
    'click button.overwrite': 'submitOverwrite',
    'change #hasHeader': 'setHeader',
    'keyup input[name=fileName]': 'checkOverwrite',
    'paste input[name=fileName]': 'checkOverwrite',
    'keyup input.delimiter[name=custom_delimiter]': 'setOtherDelimiter',
    'paste input.delimiter[name=custom_delimiter]': 'setOtherDelimiter',
    'click input.delimiter[type=radio]': 'setDelimiter',
    'click input#delimiter_other': 'focusOtherInputField',
  },

  setup(...args) {
    this._super('setup', args);
    this.dataset = this.options.pageModel;
    this.model = new DatasetDownloadConfiguration({
      workspaceId: workspace(this.dataset).id,
      datasetId: this.dataset.id,
      hasHeader: true,
      fileName: `${normalize(this.dataset.get('objectName'))}.csv`,
    });
    this.resource = this.model;
    this.setTitle();

    this.listenTo(this.model, 'saved', this.saved);
    this.listenTo(this.model, 'saveFailed', this.saveFailed);
    this.listenTo(this.model, 'validationFailed', this.saveFailed);
  },

  setTitle() {
    this.title = t('dataset.download.title', { datasetName: this.dataset.name() });
  },

  postRender() {
    if (_.contains([',', '\t', ';', ' '], this.delimiter)) {
      this.$(`input.delimiter[value='${this.delimiter}']`).prop('checked', true);
    } else {
      this.$('input#delimiter_other').prop('checked', true);
    }
    this.checkOverwrite();
  },

  submitDownload(e, overwrite) {
    e.preventDefault();

    this.updateModel(overwrite);

    if (overwrite) {
      this.$('button.overwrite').startLoading(this.loadingKey);
      this.$('button.submit').prop('disabled', true);
    } else {
      this.$('button.submit').startLoading(this.loadingKey);
    }

    this.model.save();
  },

  submitOverwrite(e) {
    this.submitDownload(e, true);
  },

  checkOverwrite() {
    const hasConflict = this.model.statusCode === 409;
    const checkedName = this.model.get('fileName');
    const currentName = this.$('.directions input:text').val().trim();

    if ((hasConflict) && checkedName === currentName) {
      this.$('button.overwrite').removeClass('hidden');
      this.showErrors();
    } else {
      this.$('button.overwrite').addClass('hidden');
    }
  },

  updateModel(overwrite) {
    const newFileName = this.$('.directions input:text').val().trim();
    this.model.set({
      hasHeader: !!(this.$('#hasHeader').prop('checked')),
      delimiter: this.delimiter,
      fileName: newFileName,
      rowLimit: this.rowLimit(),
    });
    if (overwrite) {
      this.model.set({ overwrite });
    }
  },

  saved() {
    this.closeModal();
    chorus.toast('dataset.export.started');
  },

  saveFailed() {
    if (this.model.serverErrors) {
      this.showErrors();
    }

    this.checkOverwrite();


    this.$('button.submit').stopLoading();
    this.$('button.overwrite').stopLoading();
  },

  validateInput() {
    this.model.set({ rowLimit: this.rowLimit() }, { silent: true });
    if (this.model.performValidation()) {
      return true;
    }
    this.showErrors();
    return false;
  },

  rowLimit() {
    return this.specifyAll() ? 0 : parseInt(this.$('input[name=custom_row]').val(), 10);
  },

  specifyAll() {
    return this.$('input[type=radio][id=row_all]').prop('checked');
  },

  focusOtherInputField() {
    this.$('input[name=custom_delimiter]').focus();
  },

  setDelimiter(e) {
    if (e.target.value === 'other') {
      this.delimiter = this.$('input[name=custom_delimiter]').val();
      this.other_delimiter = true;
    } else {
      this.delimiter = e.target.value;
      this.other_delimiter = false;
    }
  },

  setOtherDelimiter() {
    this.$('input.delimiter[type=radio]').prop('checked', false);
    const otherRadio = this.$('input#delimiter_other');
    otherRadio.prop('checked', true);
    otherRadio.click();
  },
});

