import { Breadcrumb } from 'semantic-ui-react';
import { capitalise } from '../../utilities/helper';

const FileNavigationBreadcrumbs = ({ folders, handleSetFolder }) => {
  let destinationFolder = '';

  const sections = folders.map((folder, index, { length }) => {
    destinationFolder = index === 0 ? `${folder}/` : `${destinationFolder}${folder}/`;
    const destination = destinationFolder.slice(0, -1);

    return {
      key: `${folder}_${index}`,
      content: index === 0 ? capitalise(folder) : folder,
      title: folder,
      active: index === length - 1,
      onClick: index === length - 1 ? null : () => { handleSetFolder(destination); },
    };
  });

  return <Breadcrumb className="padding-none" data-testid="breadcrumb" icon="right angle" as="nav" sections={sections} />;
};

export default FileNavigationBreadcrumbs;
