import { useState } from 'react';
import { t } from 'i18n-js';
import {
  Modal, Icon, Header,
} from 'semantic-ui-react';
import HeaderError from '../../../components/errors/HeaderError';
import MarkdownEditor from '../../../components/text_editors/MarkdownEditor';
import ModalActionButtons from '../../../components/buttons/ModalActionButtons';
import { useComment } from '../../service';
import toastMessage from '../../../utilities/toast';

const CommentModal = ({
  setModalClose, setIsBusy, entityId, eventId,
}) => {
  const [newComment, setNewComment] = useState('');

  const isValidEntry = !!newComment && newComment !== '<div></div>';

  const handleCommentChange = (value) => {
    setNewComment(value);
  };

  const {
    addComment,
    isLoading,
    isError,
    error,
    resetResponse,
  } = useComment(entityId);

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleSubmit = () => {
    setIsBusy(true);

    const commentData = {
      comment: {
        body: newComment,
        event_id: eventId,
      },
    };

    addComment(commentData, {
      onSuccess: () => {
        setIsBusy(false);
        handleModalClose();
        toastMessage('success', t('activities.actions.add_comment_success'), 'commentSuccess');
      },
      onError: () => {
        setIsBusy(false);
      },
    });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="comment" title={t('activities.actions.add_comment')} />
        <Header.Content content={t('activities.actions.add_comment')} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <MarkdownEditor
          value={newComment}
          onChange={handleCommentChange}
        />
      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmit}
        isValidEntry={isValidEntry}
        error={isError}
        submitText={t('activities.actions.add_comment')}
      />
    </>
  );
};

export default CommentModal;
