import Announcement from './Announcement';
import useAnnouncements from './service';

const Announcements = () => {
  const { announcements, isError, isLoading } = useAnnouncements();

  if (isError || isLoading) {
    return null;
  }

  return (<Announcement announcements={announcements} />);
};

export default Announcements;
