import Workfile from '../models/workfile';
import Collection from './collections';

export default Collection.extend({
  constructorName: 'WorkfileSet',
  model: Workfile,
  urlTemplate: 'api/workspaces/workspaces/{{workspaceId}}/workfiles',
  showUrlTemplate: 'workspaces/{{workspaceId}}/workfiles',

  urlParams() {
    const params = {
      namePattern: this.attributes.namePattern,
      fileType: this.attributes.fileType,
      folder: this.attributes.folder,
      filesOnly: this.attributes.filesOnly,
      foldersOnly: this.attributes.foldersOnly,
      matching: this.attributes.matching,
    };

    if (params.folder.includes('/?file_type=')) {
      const url = params.folder.split('/?file_type=');
      params.folder = url[0];
      params.fileType = url[1];
    }

    if (this.attributes.succinct) {
      params.succinct = true;
    }
    return params;
  },

  search(term) {
    const self = this;
    self.attributes.namePattern = term;
    self.fetch({ silent: true, success() { self.trigger('searched'); } });
  },

  clearAttributes() {
    this.attributes.namePattern = null;
    this.attributes.fileType = null;
    this.attributes.folder = null;
    this.attributes.filesOnly = null;
    this.attributes.foldersOnly = null;
  },

  setFolder(folder) {
    this.clearAttributes();
    this.attributes.folder = folder;
    this.fetch();
  },

  workspace() {
    return this._workspace;
  },

  setWorkspace(workspace) {
    this._workspace = workspace;
  },
});
