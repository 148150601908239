import Files from '../../../../files/panels/files/components/Files';
import ResponsivePanel from '../../ResponsivePanel';

const FilesPanel = ({ tab }) => {
  const { config } = tab;
  const folderPath = config.params.folder_path ? `files/${config.params.folder_path}` : 'files';

  return (
    <ResponsivePanel>
      {(panelWidth) => <Files panelWidth={panelWidth} folderPath={folderPath} />}
    </ResponsivePanel>
  );
};

export default FilesPanel;
