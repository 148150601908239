import React from 'react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import {
  Button, Container, Form, Header,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import { CancelButton, SaveButton } from '../Buttons';
import EnvVarTable from './EnvVarTable';
import RuntimeOptions from './RuntimeOptions';
import NoCompletionInput from '../Forms';
import './ContainerisedApp.less';

const AppDetailsForm = ({
  name,
  imageName,
  imageTileUrl,
  portNumber,
  isEditing,
  isSaving,
  minimumDataEntryMet,
  handleNameChange,
  handleImageNameChange,
  handleTileUrlChange,
  handlePortNumberChange,
  handleSave,
  handleCancel,
  developer,
}) => (
  <Container id="app-details-container">
    <Header as="h3">{t('app.form.title.details')}</Header>
    <Form noValidate className="flex-col grow">
      <div className="flex-row grow">
        <div className="left-col">
          <NoCompletionInput
            required
            defaultValue={name}
            readOnly={isEditing}
            onChange={handleNameChange}
            fluid
            label={t('app.form.name')}
            id="app-form-name"
          />
          <NoCompletionInput
            defaultValue={imageTileUrl}
            onChange={handleTileUrlChange}
            fluid
            label={t('app.form.image_tile')}
            action
            id="app-form-image-tile"
          >
            <Button>{t('app.form.file_browse')}</Button>
          </NoCompletionInput>
          <NoCompletionInput
            required
            defaultValue={imageName}
            readOnly={isEditing}
            onChange={handleImageNameChange}
            fluid
            label={t('app.form.image_name')}
            id="app-form-image-name"
          />
          <Form.Input
            defaultValue={portNumber}
            onChange={handlePortNumberChange}
            required
            disabled={!developer}
            label={t('app.form.port_number')}
            className="short"
            id="app-form-port-number"
          />
          <RuntimeOptions />
        </div>
        <div className="right-col">
          <Form.Field
            control={EnvVarTable}
            label={<label>{t('app.form.vars.label')}</label>}
            className="grow"
          />
        </div>
      </div>
      <div className="form-buttons">
        <Button.Group floated="right">
          <CancelButton
            onClose={handleCancel}
            saving={isSaving}
            cancelText={t('app.form.cancel')}
          />
          <SaveButton
            onSave={handleSave}
            saving={isSaving}
            minimumDataEntryMet={minimumDataEntryMet}
            saveText={isEditing ? t('app.form.done') : t('app.form.create')}
          />
        </Button.Group>
      </div>
    </Form>
  </Container>
);

const mapModelsToProps = ({ containerisedApp }) => ({
  name: containerisedApp.get('name'),
  imageName: containerisedApp.get('imageName'),
  imageTileUrl: containerisedApp.get('imageTileUrl'),
  portNumber: containerisedApp.get('port'),
  minimumDataEntryMet: containerisedApp.get('minimumDataEntryMet'),
  isEditing: containerisedApp.get('isEditing'),
  isSaving: containerisedApp.get('isSaving'),
  developer: containerisedApp.get('developer'),
  handleNameChange: (_, data) => {
    containerisedApp.updateName(data.value);
  },
  handleImageNameChange: (_, data) => {
    containerisedApp.updateImageName(data.value);
  },
  handleTileUrlChange: (_, data) => {
    containerisedApp.updateImageTileUrl(data.value);
  },
  handlePortNumberChange: (_, data) => {
    containerisedApp.updatePortNumber(data.value);
  },
  handleSave: () => containerisedApp.save(),
  handleCancel: () => containerisedApp.closeTab(),
});

const options = {
  debounce: true,
  events: {
    containerisedApp: [
      'change:name',
      'change:imageName',
      'change:imageTileUrl',
      'change:portNumber',
      'change:minimumDataEntryMet',
      'change:isSaving',
      'change:developer',
    ],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(AppDetailsForm);
