import Activity from './activity';

export default Activity.extend({
  constructorName: 'Comment',
  entityType: 'comment',

  urlTemplate() {
    return 'api/workspaces/comments/{{id}}';
  },

  initialize(...args) {
    this._super('initialize', args);
    this.files = [];
  },

  declareValidations(newAttrs) {
    this.require('body', newAttrs);
  },

  attrToLabel: {
    body: 'activity.comment',
  },

  note() {
    return this.get('action') && this.get('action') === 'NOTE';
  },
});
