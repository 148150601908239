/* eslint-disable max-lines, camelcase */
import { Dropdown } from 'semantic-ui-react';
import { t } from 'i18n-js';
import {
  isZip,
  enableOpenAsText,
  isCSV,
  isFileFolder,
  isBlob,
  isFolder,
  isR,
  isSQL,
} from '../../../helper';
import {
  workspaceFileListElementUrl,
  openFileAsText,
  openFileAnalyseData,
  runSQL,
  airlockFile,
  newDatabaseTablePanelFromCSV,
} from '../../../../constants/ui_urls';
import MoveModal from './modals/MoveModal';
import SaveAsModal from './modals/SaveAsModal';
import DeleteModal from './modals/DeleteModal';
import ExtractModal from '../../../components/modals/ExtractModal';
import AddNoteModal from '../../../../activities/components/modals/AddNoteModal';
import RenameModal from './modals/RenameModal';
import { useFileContext } from '../Context';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import { validationLengths } from '../../../../constants/validation';
import { useWorkspaceContext } from '../../../../adapters/Workspace';
import Can from '../../../../permissions';
import OfficeDropdownItem from './OfficeDropdownItem';

import { rCommands } from '../../../../../application/collections/r_commands';
import RCommand from '../../../../../application/models/r_command';
import { workspaceId } from '../../../../layout/helper';

const DeleteOption = (
  <Dropdown.Item
    icon="trash alternate"
    text={t('file_list.actions.delete')}
  />
);

const ExtractOption = (
  <Dropdown.Item
    icon="file text"
    text={t('file_list.actions.extract')}
  />
);

const MoveOption = (
  <Dropdown.Item
    icon="arrow circle right"
    text={t('file_list.actions.move')}
  />
);

const RenameOption = (
  <Dropdown.Item
    icon="edit"
    text={t('file_list.actions.rename')}
  />
);

const AddNoteOption = (
  <Dropdown.Item
    icon="sticky note"
    text={t('file_list.actions.add_note')}
  />
);

const SaveAsOption = (
  <Dropdown.Item
    icon="save"
    text={t('file_list.actions.save_as')}
  />
);

const handleRunFile = (fileName, folder, fileType) => {
  const rCommand = new RCommand({
    workspace_id: workspaceId(),
    fileName,
    folder,
    scriptType: fileType,
    action: 'run',
  });
  rCommands.add(rCommand);

  window.location.hash = rCommand.showUrl();
};

const FilesRowDropdown = () => {
  const { workspace: { can_airlock } } = useWorkspaceContext();
  const {
    id,
    name,
    size,
    type,
    folder,
    isEditable,
    isDeleting,
  } = useFileContext();

  return (
    <Dropdown
      icon={{ name: 'ellipsis vertical', color: 'blue' }}
      className="icon"
      inline
      pointing="right"
    >
      <Dropdown.Menu>
        {!isZip(name) && (
          <Dropdown.Item
            icon="file outline"
            text={t('file_list.actions.open')}
            href={workspaceFileListElementUrl(id, type, folder, name)}
          />
        )}
        {!isFolder(type) && (
          <OfficeDropdownItem fileId={id} fileName={name} />
        )}
        {isZip(name) && (
          <ModalWrapper trigger={ExtractOption}>
            <ExtractModal folder={folder} id={id} name={name} />
          </ModalWrapper>
        )}
        {enableOpenAsText(type, name) && (
          <Dropdown.Item
            icon="file text"
            text={t('file_list.actions.open_as_text')}
            href={openFileAsText(id)}
          />
        )}
        <Can I="Interact with" this="Workspace">
          <ModalWrapper trigger={AddNoteOption}>
            <AddNoteModal
              entityType="workfile"
              entityId={id}
              entityName={name}
            />
          </ModalWrapper>
        </Can>
        {isEditable && isCSV(name) && (
          <Can I="Write" this="WorkspaceStorage">
            <Dropdown.Item
              icon="chart pie"
              text={t('file_list.actions.analyse_data')}
              href={size >= validationLengths.MAX_EDITABLE_CSV ? null : openFileAnalyseData(id)}
              title={size >= validationLengths.MAX_EDITABLE_CSV ? t('file_list.actions.disabled_analyse_data') : ''}
              className={size >= validationLengths.MAX_EDITABLE_CSV ? 'aridhia-disabled' : ''}
            />
            <Dropdown.Item
              icon="table"
              text={t('file_list.actions.convert_to_database')}
              href={newDatabaseTablePanelFromCSV(id)}
            />
          </Can>
        )}
        {isR(name) && !isBlob(folder) && (
          <Dropdown.Item
            icon="play circle outline"
            text={t('file_list.actions.run_file')}
            onClick={() => handleRunFile(name, folder, type)}
          />
        )}
        {isSQL(type) && (
          <Dropdown.Item
            icon="play circle outline"
            text={t('file_list.actions.run_file')}
            href={runSQL(id, type)}
          />
        )}
        {!isDeleting && can_airlock && (
          <Can I="Create" a="WorkspaceAirlock">
            <Dropdown.Item
              icon="unlock"
              text={t('file_list.actions.airlock')}
              href={airlockFile(id)}
            />
          </Can>
        )}
        {isEditable && (
          <Can I="Write" this="WorkspaceStorage">
            {isFileFolder(folder) && !isFolder(type) && (
              <ModalWrapper trigger={SaveAsOption} size="small">
                <SaveAsModal />
              </ModalWrapper>
            )}
            {isFileFolder(folder) && (
              <ModalWrapper trigger={MoveOption} className="move-modal" size="small">
                <MoveModal />
              </ModalWrapper>
            )}
            {!isBlob(folder) && (
              <ModalWrapper trigger={RenameOption}>
                <RenameModal />
              </ModalWrapper>
            )}
            <ModalWrapper trigger={DeleteOption}>
              <DeleteModal />
            </ModalWrapper>
          </Can>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FilesRowDropdown;
