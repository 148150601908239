import ListContentDetails from '../../views/core/list_content_details_view';
import View from '../../views/loading_view';

export default View.extend({
  constructorName: 'PaginatedJoinTablesList',

  templateName: 'paginated_join_tables',

  subviews: {
    '.join_pagination': 'joinTablePaginator',
  },

  setup() {
    this.joinTablePaginator = new ListContentDetails({
      collection: this.collection,
      modelClass: 'WorkspaceDataset',
      hideIfNoPagination: true,
    });

    this.listenTo(this.collection, 'searched', this.render);
  },

  collectionModelContext(model) {
    return {
      columns: model.has('columns') && model.get('columns'),
      isView: model.metaType() === 'view',
      iconUrl: model.iconUrl({ size: 'icon' }),
    };
  },
});
