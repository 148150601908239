import _ from 'underscore';
import $ from 'jquery';
import chorus from '../chorus';

const transformKeys = (keyFn) => {
  const transformer = function onTransform(hash) {
    const result = _.isArray(hash) ? [] : {};
    _.each(hash, (val, key) => {
      const newKey = keyFn(key);
      if (_.isObject(val) && newKey !== 'errorObjects') {
        result[newKey] = transformer(val);
      } else {
        result[newKey] = val;
      }
    }, this);
    return result;
  };

  return transformer;
};

export default {
  fetchIfNotLoaded(options) {
    if (this.loaded) {
      return Promise.resolve();
    }
    if (!this.fetching) {
      delete this.statusCode;
      return this.fetch(options);
    }
  },

  fetchAllIfNotLoaded() {
    if (this.loaded) {
      if (this.models.length >= this.pagination.records) {
        return Promise.resolve();
      }
      this.loaded = false;
    }
    if (!this.fetching) {
      delete this.statusCode;
      return this.fetchAll();
    }
    return Promise.resolve();
  },

  makeSuccessFunction(options, success) {
    return function onSuccess(resource, data, fetchOptions) {
      if (chorus.debug) { chorus.log('<<+', resource.constructorName); }
      const res = resource;
      res.loaded = true;
      res.statusCode = 200;
      if (!options.silent) {
        res.trigger('loaded');
        res.trigger('serverResponded');
      }
      if (success) {
        success(res, data, fetchOptions);
      }
    };
  },

  underscoreKeys: transformKeys(_.underscored),
  camelizeKeys: transformKeys(_.camelize),

  // Use Chorus custom success and error callbacks for calls to model/collection `fetch`
  // Then calls super
  fetch(options) {
    this.fetching = true;
    const opts = options || {};
    opts.parse = true;
    opts.reset = opts.reset !== false;
    if (opts.reset && !opts.refresh) {
      this.loaded = false;
    }

    // Do not cache unless it is explicitly wanted
    if (!opts.ifModified) {
      opts.cache = false;
    }

    const { success, error, beforeSend } = opts;
    opts.success = this.makeSuccessFunction(opts, success);
    opts.error = function onError(collectionOrModel, xhr) {
      collectionOrModel.handleRequestFailure('fetchFailed', xhr, opts);
      if (error) error(collectionOrModel, xhr);
    };
    const complete = () => { this.fetching = false; };
    opts.beforeSend = function f(xhr) {
      if (beforeSend) {
        beforeSend.apply(this, xhr);
      }
      xhr.always(complete);
    };
    return this._super('fetch', [opts]);
  },

  parse(data) {
    this.loaded = true;
    this.pagination = data.pagination;
    delete this.serverErrors;
    const response = Object.prototype.hasOwnProperty.call(data, 'response') ? data.response : data;
    return this.camelizeKeys(response);
  },

  handleRequestFailure(failureEvent, xhr, options) {
    this.statusCode = parseInt(xhr.status, 10);

    try {
      const data = this.statusCode !== 500 &&
          xhr.responseText &&
          !!xhr.responseText.trim() &&
          JSON.parse(xhr.responseText);
      this.parseErrors(data);
    } catch (ignore) {}
    this.trigger(failureEvent, this);
    this.respondToErrors(xhr.status, options);
  },

  parseErrors(data) {
    const dataToParse = data;
    if (dataToParse && dataToParse.errors && dataToParse.errors.model_data) {
      dataToParse.errors.modelData = this.camelizeKeys(dataToParse.errors.model_data);
    }
    if (dataToParse && dataToParse.errors) {
      this.serverErrors = dataToParse.errors;
    }
    this.afterParseErrors(dataToParse);
  },

  afterParseErrors: $.noop,

  respondToErrors(status, options) {
    const opts = options || {};

    if (status === 401 || status === 403) {
      this.trigger('resourceForbidden');
    } else if (status === 404) {
      if (opts.notFound) {
        opts.notFound();
      } else {
        this.trigger('resourceNotFound');
      }
    } else if (status === 422) {
      if (opts.unprocessableEntity) {
        opts.unprocessableEntity();
      } else {
        this.trigger('unprocessableEntity');
      }
    } else if (status === 409) {
      const toastOpts = {theme: 'bad_activity'};
      if (window.INTEGRATION_MODE) {
        toastOpts.sticky = true;
      }
      if (!opts.noToast) {
        const errorStrings = ['FileLockConflict', 'SharingViolation'];
        if (errorStrings.some(v => this.serverErrors.message.join().includes(v))) {
          chorus.toast('file_lock_error', {toastOpts});
        } else {
          chorus.toast('conflict_error', {toastOpts});
        }
      }
    } else if (status === 500) {
      const toastOpts = { theme: 'bad_activity' };
      if (window.INTEGRATION_MODE) { toastOpts.sticky = true; }
      if (!opts.noToast) {
        chorus.toast('server_error', { toastOpts });
      }
    }
    if (!opts.silent) {
      this.trigger('serverResponded');
    }
  },
};

