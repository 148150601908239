import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import View from '../loading_view';

export default View.extend({
  constructorName: 'ColumnSelectView',
  persistent: true,
  templateName: 'column_select',

  events: {
    'change select': 'columnSelected',
  },

  setup() {
    if (this.collection) {
      this.listenTo(this.collection, 'join:added', this.render);
    }
  },

  postRender() {
    const selectEl = this.$('select');
    const { showAliasedName } = this.options;
    _.defer(() => {
      chorus.styleSelect(selectEl, {
        menuWidth: 240,
        format(text, option) {
          const aliasedName = $(option).data('aliasedName');
          const html = chorus.htmlEncode(text);
          if (aliasedName && showAliasedName) {
            return `<span class="aliased_name"><span class="letter">${aliasedName}</span></span>${html}`;
          }
          return html;
        },
      });
    });
  },

  collectionModelContext(model) {
    return {
      quotedName: (model.quotedName && model.quotedName()) || model.get('name'),
      disable: model.get('typeCategory') === 'OTHER' && this.options.disableOtherTypeCategory,
      selected: model === this.selectedColumn,
    };
  },

  getSelectedColumn() {
    const selectedCid = this.$('select option:selected').data('cid');
    return this.collection.get(selectedCid);
  },

  selectColumn(column) {
    if (column) {
      this.$(`select option[data-cid=${column.cid}]`).prop('selected', true).change();
    } else {
      this.$('select option:eq(0)').prop('selected', true).change();
    }
  },

  columnSelected() {
    this.selectedColumn = this.getSelectedColumn();
    this.trigger('columnSelected', this.selectedColumn);
  },

  valid() {
    if (!this.selectedColumn) {
      return true;
    }
    return this.collection.include(this.selectedColumn);
  },
});
