import Activity from './activity';

export default Activity.extend({
  constructorName: 'Airlock',
  entityType: 'airlock',
  showUrlTemplate: 'workspaces/{{id}}',

  urlTemplate(options) {
    let suffix = '';
    if (options && options.isReject) {
      suffix = '/reject';
    } else if (options && options.isApprove) {
      suffix = '/approve';
    } else if (options && options.isCopy) {
      suffix = '/copy';
    }

    return `api/workspaces/workspaces/{{workspaceId}}/airlock/{{id}}${suffix}`;
  },

  declareValidations(newAttrs) {
    this.require('reason', newAttrs);
  },

  attrToLabel: {
    reason: 'airlock.label.reason',
  },
});
