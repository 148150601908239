import { I18n } from '../../intl';
import View from '../loading_view';
import { humanizedDatasetType } from '../../utilities/helpers';

export default View.extend({
  templateName: 'dataset_statistics',

  setup(options) {
    this.column = options.column;

    this.statistics = this.model.statistics();
    this.statistics.fetchIfNotLoaded();
    this.meta = this.model.metadata();
    if (this.model.get('hasMetadata')) {
      this.meta.fetchIfNotLoaded();
    }

    this.listenTo(this.statistics, 'loaded', this.render);
    this.listenTo(this.meta, 'loaded change:columns', this.render);

    this.listenTo(this.model, 'invalidated', function reFetch() {
      this.statistics.fetch();
      this.meta.fetch();
    });
  },

  context() {
    const ctx = {
      column: this.column && this.column.attributes,
    }

    if (this.meta.get('columns')) {
      ctx.columnMeta = this.column && this.meta.get('columns')[this.column.attributes.name];
    }

    ctx.objectName = this.model.get('objectName');
    ctx.typeString = humanizedDatasetType(
      this.model && this.model.attributes,
      this.model.statistics(),
    );

    if (!this.statistics) { return ctx; }

    ctx.statistics = this.statistics.attributes;
    if (ctx.statistics.rows === 0) {
      ctx.statistics.rows = '0';
    }

    if (ctx.statistics.partitions > 0) {
      delete ctx.statistics.rows;
    }

    if (ctx.statistics.columns === 0) {
      ctx.statistics.columns = '0';
    }

    ctx.statistics.formattedDiskSize = I18n.toHumanSize(
      this.statistics.get('onDiskSize'),
      { precision: 0, format: '%n %u' },
    );

    ctx.meta = this.meta.attributes;

    return ctx;
  },
});
