import t from '../../intl';
import Handlebars from '../../vendor/handlebars';
import SearchItemBase from './search_item_base';
import { workspace } from '../../utilities/workspace_assoc';

export default SearchItemBase.extend({
  constructorName: 'SearchAttachment',
  templateName: 'search_attachment',

  additionalContext() {
    const workspaceLink = workspace(this.model) &&
        workspace(this.model).get('id') &&
        workspace(this.model).showLink();
    const datasetLink = this.model.dataset() && this.model.dataset().showLink();
    const workfileLink = this.model.workfile() && this.model.workfile().showLink();
    const dataSourceLink = this.model.dataSource() && this.model.dataSource().showLink();

    let composedLinkString = '';
    if (workspaceLink && datasetLink) {
      composedLinkString = t(
        'attachment.found_in.dataset_in_workspace',
        { workspaceLink, datasetLink },
      );
    } else if (datasetLink) {
      composedLinkString = t(
        'attachment.found_in.dataset_not_in_workspace',
        { datasetLink },
      );
    } else if (workspaceLink && workfileLink) {
      composedLinkString = t(
        'attachment.found_in.workfile_in_workspace',
        { workfileLink, workspaceLink },
      );
    } else if (workspaceLink) {
      composedLinkString = t(
        'attachment.found_in.workspace',
        { workspaceLink },
      );
    } else if (dataSourceLink) {
      composedLinkString = t(
        'attachment.found_in.data_source',
        { dataSourceLink },
      );
    }

    return {
      downloadUrl: this.model.downloadUrl(),
      iconUrl: this.model.iconUrl(),
      composedLinkString: new Handlebars.SafeString(composedLinkString),
    };
  },
});
