import t from '../intl';
import Base from './base';

export default Base.extend({
  constructorName: 'Error',
  cancel: t('actions.close_window'),
  additionalClass: 'error',

  makeModel(...args) {
    this._super('makeModel', args);
    this.options = this.options || {};
    this.body = this.options.body || this.model.serverErrorMessage();
    this.title = this.options.title || this.title;
  },

  postRender() {
    this._super('postRender');
    this.$('.errors').addClass('hidden');
    // We don't want the submit button to be displayed on error alerts, so
    // lets hide that (see alert.jst.hbs) -- Sheru
    this.$('.app-alertBtnSubmit').hide();
  },
});
