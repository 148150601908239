import { useState } from 'react';
import { t } from 'i18n-js';
import { Modal, Form, Header } from 'semantic-ui-react';
import ModalActionButtons from '../../../components/buttons/ModalActionButtons';
import HeaderError from '../../../components/errors/HeaderError';
import { validationFields, validationLengths } from '../../../constants/validation';
import { findErrors, hasError, validTableName } from '../../../utilities/validation';
import FormValidationError from '../../../components/errors/FormValidationError';
import TableIcon from '../TableIcon';
import { supportedTableTypes } from '../../constants';
import { invalidateTableLists, useNewDBView } from '../../service';
import { useDatabaseTableContext } from '../../Context';
import toastMessage from '../../../utilities/toast';
import { DatabaseTableCreationTableInvalidNameWarning } from '../../panels/database_table_creation/components/DatabaseTableCreationTableWarnings';

const NewDBViewModal = ({
  setModalClose,
  setIsBusy,
}) => {
  const { tableId } = useDatabaseTableContext();
  const [viewName, setViewName] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);
  const [viewNameWarning, setViewNameWarning] = useState(false);

  const handleNameChange = (e, { value }) => {
    setViewName(value);
    const newErrors = validationErrors.filter((err) => err.field !== validationFields.NAME);
    setValidationErrors(newErrors);
    setViewNameWarning(!validTableName(value));
  };

  const checkForErrors = (name) => {
    const errors = [];
    if (!name) {
      errors.push({
        field: 'name',
        message: (t('field_error.BLANK', { field: t('modals.new_db_view.name') })),
      });
    }

    if (name?.length > validationLengths.TABLE_NAME) {
      errors.push({
        field: 'name',
        message: (t('field_error.TOO_LONG', { field: t('modals.new_db_view.name'), count: validationLengths.TABLE_NAME })),
      });
    }
    return errors;
  };

  const {
    createNewDBView,
    isLoading,
    isError,
    error,
    resetResponse,
  } = useNewDBView(tableId);

  const handleModalClose = () => {
    setViewName('');
    setModalClose();
    resetResponse();
  };

  const handleSubmitting = () => {
    setIsBusy(true);
    const errors = checkForErrors(viewName);
    setValidationErrors(errors);

    if (errors.length > 0) {
      setIsBusy(false);
      return;
    }

    createNewDBView({ object_name: viewName }, {
      onSuccess: () => {
        invalidateTableLists();
        handleModalClose();
        toastMessage('success', t('database_table.view_created', { viewName }), 'newViewToast');
      },
      onSettled: () => {
        setIsBusy(false);
      },
    });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <TableIcon tableType={supportedTableTypes.VIEW} className="margin-right-small" />
        <Header.Content content={t('modals.new_db_view.title')} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <Form role="form" warning={!!(viewName && viewNameWarning)}>
          <Form.Field>
            <label htmlFor="name">
              {t('modals.new_db_view.name')}
              {hasError(validationErrors, validationFields.NAME) && (
                <FormValidationError errorMsg={(findErrors(validationErrors, validationFields.NAME)[0]).message} />
              )}
            </label>
            <Form.Input
              id="name"
              autoFocus
              placeholder={t('modals.new_db_view.name_placeholder')}
              value={viewName}
              maxLength={validationLengths.TABLE_NAME}
              onChange={handleNameChange}
              error={hasError(validationErrors, validationFields.NAME)}
            />
            <DatabaseTableCreationTableInvalidNameWarning />
          </Form.Field>
        </Form>
      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmitting}
        isValidEntry={!!viewName}
        error={validationErrors.length > 0}
        submitText={t('actions.create_db_view')}
      />
    </>
  );
};

export default NewDBViewModal;
