/* eslint-disable object-curly-newline,no-underscore-dangle */
import { Button, Checkbox, Icon, Table } from 'semantic-ui-react';
import React, { useCallback, useEffect, useState } from 'react';

const createMultiActions = (selected) => ({ icon, title, modal, onClick }) => {
  if (modal) {
    const Component = modal;
    const button = (<Button basic size="small" icon={icon} content={title} />);

    return <Component trigger={button} selected={selected} />;
  }
  return (<Button basic size="small" icon={icon} onClick={() => onClick(selected)} content={title} />);
};

const updateState = ({ name, checked }) => (state) => {
  const newState = new Map(state);
  if (checked) {
    newState.set(name, true);
  } else {
    newState.delete(name);
  }
  return newState;
};

export const MultiSelectActions = ({ actions, selected }) => {
  const keys = Array.from(selected.keys());
  if (keys.length === 0) {
    return null;
  }

  return (
    <Button.Group basic size="small">
      {actions?.map(createMultiActions(keys))}
    </Button.Group>
  );
};

const clearObsoleteSelections = (tableData, selectedValues, setSelectedValues, getAllKeys) => {
  if (selectedValues.size === 0) {
    return;
  }
  const allKeys = getAllKeys();
  const newState = new Map(selectedValues);
  newState.forEach((_, key) => { if (!allKeys.has(key)) { newState.delete(key); } });
  if (newState.size !== selectedValues.size) {
    setSelectedValues(newState);
  }
};

const getAllKeys = (tableData, renderBodyRow) => new Map(tableData.map(renderBodyRow).map(({ key }) => ([key, true])));
export const useMultiSelect = ({ tableData, renderBodyRow, columns, multiSelectActions }) => {
  const [selectedValues, setSelectedValues] = useState(new Map());
  const onClick = useCallback((e, data) => setSelectedValues(updateState(data)), [setSelectedValues]);
  const onClickAll = (e, { checked }) => {
    setSelectedValues(checked ? getAllKeys(tableData, renderBodyRow) : new Map());
  };

  useEffect(
    () => {
      clearObsoleteSelections(
        tableData,
        selectedValues,
        setSelectedValues,
        () => getAllKeys(tableData, renderBodyRow),
      );
    },
    [tableData, selectedValues, setSelectedValues, renderBodyRow],
  );

  if (!multiSelectActions) {
    return ({
      renderBodyRow: (row) => {
        const { key, columns: newColumns } = renderBodyRow(row);
        return (
          <Table.Row key={key}>
            {newColumns}
          </Table.Row>
        );
      },
    });
  }
  const selectedItems = selectedValues.size;
  return ({
    multiSelectIcon: selectedItems > 0 && <Icon name="check" color="blue" />,
    multiSelectActions: <MultiSelectActions actions={multiSelectActions} selected={selectedValues} />,
    columns: [{
      name: '_multi',
      content: <Checkbox name="_all" checked={selectedItems > 0 && selectedItems === tableData.length} onClick={onClickAll} />,
      unsortable: true,
      collapsing: true,
      tooltip: 'Select All',
    }].concat(columns),
    renderBodyRow: (row) => {
      const { key, columns: newColumns } = renderBodyRow(row);
      return (
        <Table.Row key={key}>
          <Table.Cell collapsing textAlign="right">
            <Checkbox
              name={key}
              checked={Boolean(selectedValues.get(key))}
              onClick={onClick}
            />
          </Table.Cell>
          {newColumns}
        </Table.Row>
      );
    },
  });
};
