import { Table } from 'semantic-ui-react';
import { databaseTableElementUrl } from '../../../../constants/ui_urls';
import { useDatabaseTableContext } from '../../../Context';
import TableIcon from '../../../components/TableIcon';

const DatabaseTablesRowNameCell = () => {
  const {
    tableId,
    tableName,
    tableType,
  } = useDatabaseTableContext();

  return (
    <Table.Cell className="aridhia-table-name-cell">
      <a href={databaseTableElementUrl(tableId, tableType)}>
        <TableIcon tableType={tableType} color="blue" />
        {tableName}
      </a>
    </Table.Cell>
  );
};

export default DatabaseTablesRowNameCell;
