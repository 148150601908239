import {
  memo, cloneElement, useMemo,
} from 'react';
import { Modal } from 'semantic-ui-react';
import { useModalState } from '../../utilities/hooks';

const ConfirmWrapper = memo(({
  children,
  className = '',
  size = 'tiny',
  closeOnDimmerClick = true,
  handleCloseModal,
}) => {
  const [open, handleOpen, handleClose] = useModalState(true);

  const setModalClose = handleCloseModal || handleClose;

  const child = useMemo(
    () => (cloneElement(children, { setModalClose })),
    [setModalClose, children],
  );

  const mountNode = document.querySelector('#modal-wrapper') || document.body;

  return (
    <Modal
      as="dialog"
      mountNode={mountNode}
      open={open}
      onOpen={handleOpen}
      onClose={setModalClose}
      closeOnDimmerClick={closeOnDimmerClick}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
      size={size}
      data-testid="confirm-wrapper"
      className={className}
    >
      {child}
    </Modal>
  );
});

export default ConfirmWrapper;
