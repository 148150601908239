import t from '../../intl';
import MemoNew from './memo_new_dialog';
import Note from '../../models/note';

export default MemoNew.extend({
  constructorName: 'NotesNew',

  title: t('notes.new_dialog.title'),
  submitButton: t('notes.button.create'),

  makeModel(...args) {
    this.model = new Note({
      entityId: this.options.entityId,
      entityType: this.options.entityType,
      workspaceId: this.options.workspace.id,
    });

    this.pageModel = this.options.pageModel;

    const subject = this.options.displayEntityType || this.options.entityType;
    this.placeholder = t('notes.placeholder', { noteSubject: subject });
    this._super('makeModel', args);
  },
});
