import { t } from 'i18n-js';
import { Card, Image } from 'semantic-ui-react';
import { useAppContext } from '../../../Context';
import MarkdownRender from '../../../../components/text_editors/MarkdownRender';
import dataImage from '../../../../../../../public/images/datatile.png';
import { formatDayMonthYearDate, relativeDate } from '../../../../helpers/dates';
import { isRichText } from '../../../../utilities/editor';

const AppsCardContent = () => {
  const {
    name,
    displayPictureSrc,
    podStatus,
    type,
    additionalInfo,
    license,
    created,
  } = useAppContext();

  const createdAt = created ? formatDayMonthYearDate(created) : null;
  const imageSrc = displayPictureSrc || dataImage;

  return (
    <Card.Description data-testid="apps-card-content">
      <ul>
        <Image
          src={imageSrc}
          alt={`${name} ${t('apps.apps_panel.logo.alt')}`}
          size="tiny"
          floated="right"
        />
        <li aria-label={t('apps.apps_panel.label.type')}>
          {`${t('apps.apps_panel.label.type')}: `}
          <strong>{t(`apps.apps_panel.type.${type}`)}</strong>
        </li>
        {createdAt && (
          <li aria-label={t('apps.apps_panel.label.created')}>
            {`${t('apps.apps_panel.label.created')}: `}
            <strong title={relativeDate(created)}>{createdAt}</strong>
          </li>
        )}
        {license && (
          <li aria-label={t('apps.apps_panel.label.license')}>
            {`${t('apps.apps_panel.label.license')}: `}
            <MarkdownRender value={license} isRichText={isRichText(license)} />
          </li>
        )}
        {additionalInfo && (
          <li aria-label={t('apps.apps_panel.label.additional_info')}>
            {`${t('apps.apps_panel.label.additional_info')}: `}
            <MarkdownRender value={additionalInfo} isRichText={isRichText(license)} />
          </li>
        )}
        <li aria-label={t('apps.apps_panel.label.status')}>
          {`${t('apps.apps_panel.label.status')}: `}
          <strong>{t(`apps.apps_panel.status.${podStatus}`)}</strong>
        </li>
      </ul>
    </Card.Description>
  );
};

export default AppsCardContent;
