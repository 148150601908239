import $ from '../jquery';
import Bare from './bare_view';

/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true }] */
/* eslint no-restricted-syntax: ["error", "FunctionExpression"] */

export default importChunk => (Bare.extend({
  templateName: 'loading_section',
  bindCallbacks: $.noop,
  wrappedView: null,

  setup(options) {
    Object.assign(options, { el: this.el });
    importChunk().then((module) => {
      const View = module.default;
      const tmp = this.parentView;
      this.parentView = null;
      this.teardown(true); // teardown will be directed to wrappedView after this
      for (const f in this) {
        if (f !== 'el') {
          delete this[f];
        }
      }
      this.parentView = tmp;
      this.wrappedView = new View(options);
      for (const f in this.wrappedView) {
        if ($.isFunction(this.wrappedView[f])) {
          this[f] = (...args) => (this.wrappedView[f](...args));
        }
      }
      this.wrappedView.render();
    });
  },
}));

