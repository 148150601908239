import Workspace from '../models/workspace';
import Collection from './collections';

export default Collection.extend({
  constructorName: 'WorkspaceSet',
  model: Workspace,
  urlTemplate: 'api/workspaces/workspaces/',

  urlParams() {
    const params = {};

    if (this.attributes.active) {
      params.active = true;
    }

    if (this.attributes.userId) {
      params.user_id = this.attributes.userId;
    }

    if (this.attributes.showLatestComments) {
      params.showLatestComments = true;
    }

    params.succinct = this.attributes.succinct;

    return params;
  },
});
