import { Comment, Icon } from 'semantic-ui-react';
import {
  workspaceFileListElementUrl, activityAttachmentFileUrl, databaseTableElementUrl,
} from '../../constants/ui_urls';

const Attachment = ({ activityId, entity }) => {
  let name = '';
  let url = '';
  let icon = 'file';

  switch (entity.entity_type) {
    case 'attachment':
      name = entity.name;
      url = activityAttachmentFileUrl(activityId, entity.id);
      break;
    case 'dataset':
      name = entity.object_name;
      url = databaseTableElementUrl(entity.id, entity.object_type);
      icon = 'table';
      break;
    default:
      name = entity.file_name;
      url = workspaceFileListElementUrl(entity.id, entity.file_type, entity.folder, entity.file_name);
  }

  return (
    <Comment.Action href={url}>
      <Icon name={icon} color="blue" />
      {name}
    </Comment.Action>
  );
};

export default Attachment;
