/* eslint-disable no-underscore-dangle */

export const observeSize = (element, onResize) => {
  const observer = new window.ResizeObserver(onResize);
  observer.observe(element);

  return () => { observer.disconnect(); };
};

export const sleep = (ms) => new Promise((resolve) => { setTimeout(resolve, ms); });

export const additionalDataFn = (desktopId, name, connectionType) => (w, h) => `name=${desktopId}&width=${w}&height=${h}&fullname=${name}${connectionType}`;

export const recordGuacError = (error) => {
  // If Motomo is available this will be picked up and sent asychronously
  window._paq = window._paq || [];
  const { _paq } = window;

  _paq.push(['trackEvent', 'Guacamole', 'Error', `${error.code}: ${error.message}`]);
};

/**
 * Filters out mouse events with a y of 0 or less to prevent problems in the remote
 * desktop
 *
 * @param fn
 * @returns {(function(*): void)|*}
 */
export const filteredSendMouseState = (fn) => (mouseState) => {
  const { state } = mouseState;
  if (state.y > 0) {
    fn(mouseState.state, false);
  }
};
