import { Button } from 'semantic-ui-react';
import { t } from 'i18n-js';

const KnowledgeBaseButton = ({
  targetUrl,
  title = t('kb.accessibility'),
  size = 'medium',
  className,
}) => (
  <Button
    circular
    size={size}
    title={title}
    className={className}
    icon="question"
    href={targetUrl}
    target="_knowledgebase"
    rel="noopener noreferrer"
  />
);

export default KnowledgeBaseButton;
