import { Menu } from 'semantic-ui-react';
import StopAppModal from './StopAppModal';

const AppHeader = ({ appName, stopApp }) => (
  <Menu attached="top" className="aridhia-action-buttons">
    <Menu.Item header>{appName}</Menu.Item>
    <Menu.Menu position="right">
      <StopAppModal stopApp={stopApp}>
        <Menu.Item
          content="Stop"
          icon="stop"
          as="a"
        />
      </StopAppModal>
    </Menu.Menu>
  </Menu>
);

export default AppHeader;
