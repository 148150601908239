import chorus from '../chorus';
import t from '../intl';
import ModelDelete from './model_delete_alert';

export default ModelDelete.extend({

  text: t('mini_app.delete.confirm.text'),
  ok: t('mini_app.delete.confirm.button'),
  deleteMessage: 'mini_app.delete.success.notification',
  templateName: 'mini_app_delete_alert',
  focusSelector: 'button.submit-no-workfiles',

  events: {
    'click button.cancel': 'cancelAlert',
    'click button.submit-no-workfiles': 'deleteModel',
    'click button.submit-workfiles': 'deleteModelAndWorkfiles',
  },

  bindCallbacks() {
    this.model.bind('destroy', this.modelDeleted, this);
    this.model.bind('destroyFailed', this.destroyFailed, this);
  },

  setup() {
    this.title = t('mini_app.delete.confirm.title');
    this.redirectUrl = `/workspaces/${this.model.collection.attributes.workspaceID}/mini_apps`;
  },

  destroyFailed() {
    this.$('button.submit').hide();
    this.resource.serverErrors = { fields: { base: { UNDELETABLE_FILE: {} } } };
    this.showErrors();
  },


  deleteModel() {
    this.model.removeMiniApp();
    this.model.destroy(false);
    chorus.PageEvents.trigger('mini_app:destroyed', this.model); // Todo: remove this and use mini_app:deleted instead
    this.deletingWorkfiles = false;
    this.$('button.submit-no-workfiles').startLoading('actions.deleting');
    return false;
  },

  deleteModelAndWorkfiles() {
    this.model.removeMiniApp();
    this.model.destroy(true);
    chorus.PageEvents.trigger('mini_app:destroyed', this.model); // Todo: remove this and use mini_app:deleted instead
    this.deletingWorkfiles = true;
    this.$('button.submit-workfiles').startLoading('actions.deleting');
    return false;
  },

  modelDeleted() {
    this._super('modelDeleted');
    if (this.deletingWorkfiles === true) {
      this.model.getWorkfiles().forEach((workfile) => {
        chorus.PageEvents.trigger('workfile:deleted', workfile);
      });
    }
  },
});
