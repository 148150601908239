/* eslint-disable max-lines */
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  officeDiscoveryUrl,
  wopiAccessTokenUrl,
  workspaceFilesListUrl,
  workspaceWorkfileUrl,
  workspaceWorkfilesUrl,
} from '../../constants/api_urls';
import {
  queryClient,
  getter,
  poster,
  deleter,
  putter,
  officeDiscoveryGetter,
} from '../../adapters/service';
import serverErrorMessage from '../../utilities/server_errors';
import { workspaceId } from '../../layout/helper';
import { FEATURE } from '../../../utilities/features';

const normaliseData = (data) => {
  const normalisedData = data;
  data.forEach((file, index) => {
    if (file.metadata && file.metadata.content_length) {
      normalisedData[index].content_length = file.metadata.content_length;
    }
  });
  return normalisedData;
};

export const invalidateFiles = () => queryClient.invalidateQueries(['files', workspaceId()]);

export const invalidateFileLists = () => {
  queryClient.invalidateQueries(['files', workspaceId()]);
  queryClient.invalidateQueries(['attach', workspaceId()]);
};

export const useFiles = (folder, page, namePattern, orderBy, orderByDirection, foldersOnly) => {
  const fetchFiles = () => getter(
    workspaceFilesListUrl(folder, page, namePattern, orderBy, orderByDirection, foldersOnly),
  );

  const {
    data,
    isError,
    isFetching,
    refetch,
  } = useQuery([
    'files', workspaceId(), folder, page, namePattern, orderBy, orderByDirection, foldersOnly,
  ], fetchFiles);

  return {
    files: data ? normaliseData(data.response) : [],
    pagination: data ? data.pagination : {},
    isError,
    isFetching,
    refetch,
  };
};

export const useFile = (fileId) => {
  const fetchFile = () => getter(workspaceWorkfileUrl(fileId));

  const {
    data,
    isError,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['file', fileId],
    queryFn: fetchFile,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  return {
    file: data?.response,
    isError,
    isFetching,
    refetch,
  };
};

export const useNewFile = () => {
  const postFile = (body) => poster(workspaceWorkfilesUrl(), body);
  const {
    mutate,
    isError,
    isLoading,
    error,
    reset,
  } = useMutation((body) => postFile(body), {
    onSuccess: invalidateFileLists,
  });

  return {
    createNewFile: mutate,
    isLoading,
    isError,
    error: error?.message || serverErrorMessage(error),
    resetResponse: reset,
  };
};

export const useRename = (id) => {
  const putFile = (body) => putter(workspaceWorkfileUrl(id), body);
  const {
    mutate,
    isError,
    isLoading,
    error,
    reset,
  } = useMutation((body) => putFile(body), {
    onSuccess: invalidateFileLists,
    onError: invalidateFiles,
  });

  return {
    renameFile: mutate,
    isLoading,
    isError,
    error: error?.message || serverErrorMessage(error),
    resetResponse: reset,
  };
};

export const useDelete = (fileId, publicInbox = false) => {
  const deleteFile = () => deleter(workspaceWorkfileUrl(fileId, publicInbox));
  const {
    mutate,
    isError,
    isLoading,
    error,
    reset,
  } = useMutation((body) => deleteFile(body), {
    onSuccess: invalidateFileLists,
  });

  return {
    deleteFile: mutate,
    isLoading,
    isError,
    error: error?.message || serverErrorMessage(error),
    resetResponse: reset,
  };
};

export const useExtract = (fileId) => {
  const putFile = (body) => putter(workspaceWorkfileUrl(fileId), body);
  const {
    mutate,
    isError,
    isLoading,
    error,
    reset,
  } = useMutation((body) => putFile(body));

  return {
    createExtract: mutate,
    isLoading,
    isError,
    error: error?.message || serverErrorMessage(error),
    resetResponse: reset,
  };
};

const parseDiscovery = (xmlString) => {
  const dom = new DOMParser().parseFromString(xmlString, 'text/xml');
  const error = dom.querySelector('parsererror');

  if (error) {
    throw new Error('Parser error.');
  }

  return dom;
};

export const useOfficeDiscovery = () => {
  const fetchDiscovery = async () => {
    if (!FEATURE.OFFICE) {
      return new Document();
    }

    const discoveryXML = await officeDiscoveryGetter(officeDiscoveryUrl);
    return parseDiscovery(discoveryXML);
  };

  const {
    isError,
    isFetching,
    refetch,
    data: discoveryDOM,
  } = useQuery({
    queryKey: ['office', 'discovery'],
    queryFn: fetchDiscovery,
    refetchOnWindowFocus: false,
  });

  return {
    isError,
    isFetching,
    refetch,
    discoveryDOM,
  };
};

export const useWopiAccessToken = (fileId) => {
  const createAccessToken = (body) => poster(wopiAccessTokenUrl(fileId), body);
  const {
    mutate: createToken,
    data,
    isIdle,
    isLoading,
    isError,
  } = useMutation(createAccessToken);

  const token = data && {
    value: data.response.access_token,
    ttl: data.response.access_token_ttl,
    renewAfter: data.response.renew_after,
  };

  return {
    createToken,
    token,
    isIdle,
    isLoading,
    isError,
  };
};
