import { useState } from 'react';
import { Modal, Header, Icon } from 'semantic-ui-react';
import { t } from 'i18n-js';
import ModalActionButtons from '../../../../../components/buttons/ModalActionButtons';
import BaseFolderSelection from '../../../../../components/navigation/BaseFolderSelection';
import { baseFolders } from '../../../../../components/navigation/constants';
import FileNavigation from '../../../../../components/navigation/FileNavigation';
import HeaderError from '../../../../../components/errors/HeaderError';
import { useDatabaseTableCreationContext } from '../../Context';
import { fileNameFromPath, pathFromFullName } from '../../helper';
import { setMetadataContentFromTDF } from '../../actions';
import { useFileContent } from '../../service';

const ImportTDFFileModal = ({ setModalClose, setIsBusy }) => {
  const { newDatabaseTableState, dispatch } = useDatabaseTableCreationContext();
  const startLocation = newDatabaseTableState.table.source_file ? pathFromFullName(newDatabaseTableState.table.source_file) : 'files';
  const [folder, setFolder] = useState(startLocation);
  const [selectedFile, setSelectedFile] = useState({ fileId: null, filePath: '', fileName: '' });
  const includeContent = 'true';
  const includeMetadata = 'true';

  const useWorkfileContentOptions = {
    refetchOnWindowFocus: false,
    enabled: false,
    cacheTime: 2000,
    onSuccess: (data) => {
      dispatch(setMetadataContentFromTDF(selectedFile, data.response.version_info));
      setIsBusy(false);
      setModalClose();
    },
    onError: () => {
      setIsBusy(false);
    },
  };

  const {
    isError,
    isFetching,
    error,
    refetch,
  } = useFileContent(selectedFile.fileId, includeContent, includeMetadata, useWorkfileContentOptions);

  const handleSelectFile = (item) => {
    setSelectedFile({
      fileId: item.itemId,
      filePath: item.fullPath,
      fileName: fileNameFromPath(item.fullPath),
    });
  };

  const handleConfirm = () => {
    setIsBusy(true);
    refetch();
  };

  const handleRemove = () => {
    setSelectedFile({ fileId: null, filePath: '', fileName: '' });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="file text" />
        <Header.Content content={t('database_tables_new.metadata.from_tdf.modal_header')} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={handleRemove}
          />
        )}
      </Header>
      <Modal.Content>
        <BaseFolderSelection
          baseFolders={[baseFolders.FILES, baseFolders.BLOBS]}
          folder={folder}
          handleSetFolder={setFolder}
        />
        <FileNavigation
          currentFolder={folder}
          handleSetFolder={setFolder}
          handleSelectFile={handleSelectFile}
          selectedFile={selectedFile.fileName}
        />
      </Modal.Content>
      <Modal.Actions>
        <ModalActionButtons
          loading={isFetching}
          onClose={setModalClose}
          onSubmit={handleConfirm}
          isValidEntry={!!selectedFile.fileName}
          submitText={t('database_tables_new.metadata.from_tdf.action')}
          error={error}
        />
      </Modal.Actions>
    </>
  );
};

export default ImportTDFFileModal;
