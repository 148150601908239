import chorus from '../../chorus';
import Account from '../users/account_dialog';

export default Account.extend({
  constructorName: 'DataSourceAccount',

  translationKeys: {
    cancel: 'actions.cancel',
    body: 'data_sources.account.enter_credentials',
  },

  setup() {
    this.title = this.options.title;
  },

  makeModel(...args) {
    const { dataSource } = this.options;
    this.model = dataSource.accountForCurrentUser();
    this._super('makeModel', args);
  },

  modalClosed(...args) {
    this._super('modalClosed', args);
    if (this.options.reload && this.savedSuccessfully) {
      chorus.router.reload();
    }
    if (this.options.goBack && !this.savedSuccessfully) {
      window.history.back();
    }
  },

  saved() {
    this.savedSuccessfully = true;
    this._super('saved');
  },
});
