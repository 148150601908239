import _ from '../underscore';
import DatasetSet from '../collections/search/dataset_set';
import WorkfileSet from '../collections/search/workfile_set';
import WorkspaceSet from '../collections/search/workspace_set';
import WorkspaceItemSet from '../collections/search/workspace_item_set';
import UserSet from '../collections/search/user_set';
import AttachmentSet from '../collections/search/attachment_set';
import Model from './models';

const searchHandlers = {
  datasets: DatasetSet,
  workfiles: WorkfileSet,
  workspaces: WorkspaceSet,
  workspaceItems: WorkspaceItemSet,
  users: UserSet,
  other_files: AttachmentSet,
};

const makeCollection = (methodName) => {
  const SearchSet = searchHandlers[methodName];
  let collection;
  const memoizedName = `_${methodName}`;

  return function getResults() {
    const { searchKey } = SearchSet.prototype;

    if (!this[memoizedName]) {
      collection = new SearchSet([], { search: this });
      this[memoizedName] = collection;
      collection.loaded = true;
      if (this.get(searchKey)) {
        collection.refreshFromSearch();
      }
    }

    return this[memoizedName];
  };
};

export default Model.extend({
  constructorName: 'SearchResult',
  numResultsPerPage: 50,
  workfiles: makeCollection('workfiles'),
  datasets: makeCollection('datasets'),
  workspaces: makeCollection('workspaces'),
  dataSources: makeCollection('data_sources'),
  users: makeCollection('users'),
  workspaceItems: makeCollection('workspaceItems'),
  attachments: makeCollection('other_files'),

  initialize() {
    this.bind('invalidated', function invalidateItem() {
      this.selectedItem.trigger('invalidated');
    });
  },

  urlTemplate() {
    if (this.isScopedToSingleWorkspace()) {
      return 'api/workspaces/workspaces/{{workspaceId}}/search/';
    } else if (this.isScopedToUserWorkspaces()) {
      return 'api/workspaces/search/workspaces/';
    }
    return 'api/workspaces/search/';
  },

  currentPageNumber() {
    return this.get('page') || 1;
  },

  showUrlTemplate() {
    let prefix = '';
    const workspaceId = this.get('workspaceId');

    if (workspaceId) {
      prefix = `workspaces/${workspaceId}/`;
    }

    if (this.get('isTag')) {
      prefix += 'tags/';
    } else {
      prefix += 'search/';
    }

    if (this.isConstrained()) {
      return `${prefix + this.searchIn()}/${this.entityType()}/${encodeURIComponent(this.get('query'))}`;
    }
    return prefix + encodeURIComponent(this.get('query'));
  },

  download(options) {
    this.selectedItem.download(options);
  },

  name() {
    return this.selectedItem.name();
  },

  isScoped() {
    return this.isScopedToSingleWorkspace() || this.isScopedToUserWorkspaces();
  },

  isConstrained() {
    return this.isScoped() || this.hasSpecificEntityType();
  },

  isScopedToSingleWorkspace() {
    return this.searchIn() === 'this_workspace';
  },

  isScopedToUserWorkspaces() {
    return this.searchIn() === 'my_workspaces';
  },

  isPaginated() {
    return this.hasSpecificEntityType() || this.isScopedToSingleWorkspace();
  },

  hasSpecificEntityType() {
    return this.entityType() && (this.entityType() !== 'all');
  },

  entityType() {
    return this.get('entityType') || 'all';
  },

  searchIn() {
    return this.get('searchIn') || 'all';
  },

  urlParams() {
    const params = { query: this.get('query') };

    if (this.get('isTag')) {
      params.tag = true;
    }
    if (this.hasSpecificEntityType()) {
      params.entityType = this.entityType();
    }
    if (this.has('workspaceId')) {
      params.workspaceId = this.get('workspaceId');
    }
    if (this.isPaginated()) {
      params.per_page = this.numResultsPerPage;
      params.page = this.currentPageNumber();
    } else {
      params.per_type = 0;
    }
    return params;
  },

  displayShortName(length) {
    const len = length || 20;

    const name = this.get('query') || '';
    return (name.length < len) ? name : `${name.slice(0, len)}...`;
  },

  getResults() {
    if (this.isScopedToSingleWorkspace()) {
      return this.workspaceItems();
    }

    switch (this.entityType()) {
      case 'user':
        return this.users();
      case 'workspace':
        return this.workspaces();
      case 'workfile':
        return this.workfiles();
      case 'dataset':
        return this.datasets();
      case 'attachment':
        return this.attachments();
      default:
        return null;
    }
  },

  numPages(totalFound) {
    return Math.ceil(totalFound / this.numResultsPerPage);
  },

  total() {
    return _.reduce(_.values(this.attributes), (sum, results) => {
      if (results && results.numFound) {
        return sum + results.numFound;
      }
      return sum;
    }, 0);
  },
});

