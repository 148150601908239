import React from 'react';
import { Form, Checkbox } from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';

const FormPublicInbox = ({ publicInbox, handlePublicInboxChange }) => (
  <Form.Field inline>
    <Checkbox
      id="public_inbox"
      label={t('upload_token.upload_form.for_public_inbox')}
      checked={publicInbox}
      onChange={handlePublicInboxChange}
    />
  </Form.Field>
);

const mapModelsToProps = ({ uploadToken }) => ({
  publicInbox: uploadToken.get('publicInbox'),
  handlePublicInboxChange: (_e, value) => {
    uploadToken.updatePublicInbox(value.checked);
  },
});

const options = {
  debounce: true,
  events: {
    uploadToken: ['change:publicInbox'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(FormPublicInbox);
