import Dialog from './dialogs';

export default Dialog.extend({
  constructorName: 'StaticContentView',
  templateName: 'static_content_view_dialog',
  additionalClass: 'dialog about_dialog',
  events: {
    'click .app-btnClose': 'dialogClose',
  },

  dialogClose() {
    this.closeModal();
  },

  postRender() {
    let version = "3.55.0-117182.2516700"; 
    let year = new Date().getFullYear();
    let content = "<p>XAP-UI Version: " + version + "</p><p> Copyright © " + year + " Aridhia Informatics.</p>";
    this.$('.dialog_header h1').html('<i aria-hidden="true" class="info circle icon"></i> About Aridhia DRE');
    this.$('.app-staticContentBody').html(content);
  },

});