import chorus from '../chorus';
import $ from '../jquery';
import Dialog from './dialogs';

export default Dialog.extend({
  constructorName: 'StaticContentView',

  templateName: 'static_content_view_dialog',
  additionalClass: 'dialog_wide',
  events: {
    'click .app-btnClose': 'dialogClose',
  },

  setup() {

  },

  dialogClose() {
    this.closeModal();
  },

  postRender() {
    const title = this.options.contentTitle || '';
    let url = this.options.contentURL || '';
    url += `?buster=${chorus.cachebuster()}`; // TODO if URL has param, this may break
    const self = this;
    $.get(url, (data) => {
      self.$('.app-staticContentBody').html(data);
      self.$('.dialog_header h1').html(title);
    });
  },

  // Fired AFTER modal is closed
  modalClosed() {
  },

});
