import Presenter from './presenters';

export default Presenter.extend({
  url() {
    return this.model.hasOwnPage() ? this.model.showUrl() : this.model.downloadUrl();
  },

  iconSrc() {
    const iconSize = this.options.iconSize || 'icon';
    return this.model.iconUrl({ size: iconSize });
  },

  name() {
    return this.model.get('name') || this.model.get('objectName') || this.model.get('fileName');
  },

  useExternalLink() {
    return this.model.useExternalLink();
  },
});
