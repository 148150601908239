const readStorage = () => {
  try {
    return JSON.parse(sessionStorage.getItem('workspaceFeatures') || '{}');
  } catch {
    return {};
  }
};

const userCustomization = readStorage();

export const FEATURE = {
  NEW_UX: true,
  WIP_PANELS: false,
  SITE_TRACKING_ENABLED: false,
  OFFICE: true,
  ...window.customizationVariables?.features || {},
  ...userCustomization,
};

export const FEATURES_AVAILABLE_TO_TOGGLE = window.customizationVariables?.featuresAvailableToToggle || ['NEW_UX', 'WIP_PANELS', 'OFFICE'];

// In order to reduce the runtime cost of features this should be used as follows
// const Component = feature(FEATURE.EXAMPLE)(ComponentWithFeature, ComponentWithoutFeature);
// the feature function will run only the once when Component is defined instead of on every use.
const feature = (toggle) => {
  if (toggle) {
    return (on) => on;
  }
  return (on, off = () => null) => off;
};

// this can be imported similar to p.u. Like in line 7 'const Component'.

export default feature;
