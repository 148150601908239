import chorus from '../../chorus';
import t from '../../intl';
import Dialog from '../../dialogs/dialogs';

export default Dialog.extend({
  constructorName: 'PreviewColumns',

  templateName: 'preview_columns',
  additionalClass: 'with_sub_header',
  title: t('dataset.manage_join_tables.title'),
  useLoadingSection: true,

  setup() {
    this.resource = this.model.columns();
    this.collection = this.resource;
    this.listenTo(this.collection, 'fetchFailed', this.columnFetchFailed);
    this.collection.fetchAll();
  },

  columnFetchFailed() {
    this.model.serverErrors = this.collection.serverErrors;
    this.closeModal();
    this.previousModal.showErrors(this.model);
  },

  postRender() {
    chorus.search({
      input: this.$('input.search'),
      list: this.$('.list'),
      selector: '.name, .comment',
    });
  },

  additionalContext() {
    return {
      objectName: this.model.get('objectName'),
      count: this.collection.models.length,
    };
  },
});
