import { t } from 'i18n-js';
import { Table, Icon } from 'semantic-ui-react';
import FileNavigationBreadcrumbs from './FileNavigationBreadcrumbs';
import { getHeaderIcon } from './helper';
import { ErrorComponent, Filter } from '../../query_table';
import RefreshButton from '../buttons/RefreshButton';

const FileNavigationTableHeader = ({
  isFetching,
  handleRefreshButton,
  isError,
  handleFilterChange,
  currentFolder,
  handleSetFolder,
}) => {
  const folders = currentFolder.split('/');

  return (
    <Table.Row aria-label={t('table.accessibility.table_header_row')}>
      <Table.HeaderCell colSpan={1} className="aridhia-non-sortable-header-cell" data-testid="table-actions">
        <div className="flex justify-space-between align-baseline gap-width">
          <div className="shrink-width">
            <Icon name={getHeaderIcon(folders[0])} size="large" color="blue" />
            <FileNavigationBreadcrumbs folders={folders} handleSetFolder={handleSetFolder} />
          </div>
          <ErrorComponent isError={isError} />
          <div className="flex">
            <Filter onFilter={handleFilterChange} />
            <RefreshButton isFetching={isFetching} refetch={handleRefreshButton} />
          </div>
        </div>
      </Table.HeaderCell>
    </Table.Row>
  );
};

export default FileNavigationTableHeader;
