import React from 'react';
import { BackboneProvider } from 'connect-backbone-to-react';
import View from '../react_view';
import $ from '../../jquery';
import ContainerisedApp from '../../../models/containerised_app';
import AppDetailsForm from 'components/containerised_apps/AppDetailsForm';

export default View.extend({
  bindCallbacks: $.noop,
  className: 'app-containerisedAppCreate semantic main_content',

  setup({ model, tab }) {
    this.workspace = model;
    this.tab = tab;
  },

  renderOnce() {
    const app = new ContainerisedApp({
      workspace: this.workspace, workspaceID: this.workspace.get('id'),
    });

    const modelsMap = {
      containerisedApp: app,
    };

    this.root.render(
      <BackboneProvider models={modelsMap}>
        <AppDetailsForm />
      </BackboneProvider>,
    );
  },
});
