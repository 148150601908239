import ace from 'ace-builds/src-noconflict/ace';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-emmet';
import $ from '../../jquery';
import SimpleResultsConsole from './simple_results_console_view';
import View from '../loading_view';

export default View.extend({
  templateName: 'sql_result',

  subviews: {
    '.app-textContent': 'textContent',
    '.app-resultsConsole': 'resultsConsole',
  },

  setup() {
    this.resultsConsole = new SimpleResultsConsole({ dataset: this.model });
  },

  setupACE() {
    const editor_div = this.$('.text_editor')[0];
    this.editor = ace.edit(editor_div);
    this.editor.setOptions({
        minLines: 1,
        fontSize: 14,
        wrap: true,
        showPrintMargin: false,
        animatedScroll: false,
        fixedWidthGutter: true,
        highlightActiveLine: false,
        theme: 'ace/theme/chrome',
        autoScrollEditorIntoView: true,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        mode: 'ace/mode/pgsql',
        readonly: true,
    });
    editor_div.style.height = "50px";
    this.editor.resize();
    //this.editor.setSize(null, 50);
  },

  postRender() {
    this.setupACE();
  },
});
