import _ from '../underscore';
import Schema from '../models/schema';
import ChorusViewPreviewTask from '../models/tasks/chorus_view_preview_task';
import DataPreviewTask from '../models/tasks/data_preview_task';

/* eslint no-underscore-dangle: ["error", { "allow": ["_schema"] }] */

export const joinMap = [
  { value: 'inner', sqlText: 'INNER JOIN', text: 'dataset.manage_join_tables.inner' },
  { value: 'left', sqlText: 'LEFT JOIN', text: 'dataset.manage_join_tables.left' },
  { value: 'right', sqlText: 'RIGHT JOIN', text: 'dataset.manage_join_tables.right' },
  { value: 'outer', sqlText: 'FULL OUTER JOIN', text: 'dataset.manage_join_tables.outer' },
];

const joinSqlText = type => _.find(joinMap, joinType => joinType.value === type).sqlText;

export const schema = (dataset) => {
  const associate = dataset;
  const datasetSchema = associate._schema || (associate.get('schema') && new Schema(associate.get('schema')));
  if (associate.loaded) {
    associate._schema = datasetSchema;
  }
  return datasetSchema;
};

export const toText = (dataset) => {
  if (dataset.has('query')) {
    const query = dataset.get('query').trim().replace(/;$/, '').trim();
    return `(${query}) AS ${dataset.safePGName(dataset.name())}`;
  }
  return `${dataset.safePGName(schema(dataset).name())}.${dataset.safePGName(dataset.name())}`;
};

const fromClauseBody = (dataset) => {
  if (dataset.has('query')) {
    return `(${dataset.get('query')})`;
  }
  return `${dataset.safePGName(schema(dataset).name())}.${dataset.quotedName()}`;
};

const fromClause = (dataset) => {
  if (dataset.aliased()) {
    return `${fromClauseBody(dataset)} AS ${dataset.alias()}`;
  }
  return fromClauseBody(dataset);
};

export const generateFromClause = (dataset) => {
  let result = `FROM ${fromClause(dataset.sourceObject)}`;
  _.each(dataset.joins, _.bind((join) => {
    result += `\n\t${joinSqlText(join.joinType)} ${fromClause(join.destinationColumn.dataset)
    } ON ${join.sourceColumn.quotedName()} = ${join.destinationColumn.quotedName()}`;
  }, dataset));

  return result;
};

export const preview = (dataset) => {
  if (dataset.isChorusView() && (dataset.isNew() || dataset.unsavedChanges().query)) {
    return new ChorusViewPreviewTask({
      query: dataset.query(),
      schemaId: schema(dataset).id,
      objectName: dataset.name(),
    });
  }

  return new DataPreviewTask({
    dataset: { id: dataset.id },
    objectName: dataset.name(),
  });
};
