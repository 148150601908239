import chorus from '../../chorus';
import _ from '../../underscore';
import t from '../../intl';
import Dialog from '../../dialogs/dialogs';
import DialogFormHelpers from '../../mixins/dialog_form_helpers';

export default Dialog.include(DialogFormHelpers).extend({
  constructorName: 'ReadonlyWorkfileDialog',
  templateName: 'readonly_workfile_dialog',

  submitButton: t('workfile.make_read_only'),

  setup() {
    this.title = t(this.model.get('readOnly') ?
      'workfile.readonly_dialog.readwrite.title' :
      'workfile.readonly_dialog.readonly.title');

    this.listenTo(this.model, 'saved', this.saved);
    this.listenTo(this.model, 'saveFailed', this.saveFailed);
    this.disableFormUnlessValid({
      formSelector: 'form',
      inputSelector: 'input',
    });
    _.defer(() => {
      this.toggleSubmitDisabled();
    });
  },

  saved() {
    this.closeModal();
    chorus.PageEvents.trigger('change:readOnly', this.model);
  },

  saveFailed() {
    const readOnly = this.model.get('readOnly');
    this.model.set({
      readOnly: !readOnly,
      read_only_reason: null,
    });
  },

  create() {
    const readOnly = this.model.get('readOnly');
    this.model.set({
      readOnly: !readOnly,
      read_only_reason: this.$('input').val(),
    });
    this.model.save();
    this.$('button.submit').startLoading(readOnly ? 'actions.unlocking' : 'actions.locking');
  },
});
