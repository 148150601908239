import { useContext, createContext } from 'react';

const AppsContext = createContext();

export const useAppsContext = () => useContext(AppsContext);

export const AppsProvider = ({ children, apps }) => (
  <AppsContext.Provider value={apps}>
    {children}
  </AppsContext.Provider>
);

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children, app }) => (
  <AppContext.Provider value={app}>
    {children}
  </AppContext.Provider>
);
