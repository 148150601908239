import { Dropdown, Icon } from 'semantic-ui-react';
import { t } from 'i18n-js';
import NewFolderModal from './modals/NewFolderModal';
import { FilesProvider } from '../panels/files/Context';
import ModalWrapper from '../../components/modals/ModalWrapper';

const NewFolderOption = (
  <Dropdown.Item>
    <Icon name="folder" />
    <span className="text">{t('workspaces.launcher.new_folder')}</span>
  </Dropdown.Item>
);
const NewFolderLauncher = () => (
  <FilesProvider value={{ baseFolderPath: 'files' }}>
    <ModalWrapper trigger={NewFolderOption}>
      <NewFolderModal />
    </ModalWrapper>
  </FilesProvider>
);

export default NewFolderLauncher;
