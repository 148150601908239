// This mapping actually maps file extensions AND workfile 'fileType' attributes to filename values.
import $ from '../jquery';
import Handlebars from '../vendor/handlebars';

const map = {
  afm: 'afm',
  c: 'c',
  'c++': 'cpp',
  cc: 'cpp',
  cxx: 'cpp',
  cpp: 'cpp',
  csv: 'csv',
  doc: 'doc',
  docx: 'doc',
  excel: 'xls',
  xls: 'xls',
  xlsx: 'xls',
  h: 'c',
  hpp: 'cpp',
  hxx: 'cpp',
  jar: 'jar',
  java: 'java',
  pdf: 'pdf',
  ppt: 'ppt',
  r: 'r',
  rtf: 'rtf',
  txt: 'txt',
  sql: 'sql',
  image: 'img',
  yml: 'yml',
  yaml: 'yml',
  m: 'm',
  pl: 'pl',
  py: 'py',
  sas: 'sas',
  tsv: 'tsv',
  gif: 'img',
  png: 'img',
  jpg: 'img',
  jpeg: 'img',
  twb: 'tableau',
  pmml: 'pmml',
  xml: 'xml',
  js: 'js',
  md: 'md',
  pig: 'pig',
  rb: 'rb',
  rnw: 'rnw',
  folder: 'folder',
  ipynb: 'ipynb',
  json: 'json',
  sh: 'sh',
  version: 'txt',
};

export default (key, size) => {
  const fileType = key && key.toLowerCase();
  const imageName = `${map[fileType] || 'plain'}.png`;
  return `/images/workfiles/${size || 'large'}/${imageName}`;
};

export const linkTo = (url, text, attributes) => {
  const link = $('<a></a>').attr('href', url).attr(attributes || {});

  link.html(Handlebars.Utils.escapeExpression(text));

  return new Handlebars.SafeString(link.outerHtml());
};

export const inboxFileName = filename => {
  const names = filename.split(':');
  if (names.length >= 3) {
    return names.splice(2, names.length).join(':');
  }
  return filename;
};

export const inboxFileIdentity = filename => {
  const names = filename.split(':');
  if (names.length >= 3) {
    return names.splice(0, 2).join(':');
  }
  return filename;
};
