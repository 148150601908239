import Backbone from 'backbone';

const CopyWarningAudit = Backbone.Model.extend({
  url: (options) => `api/workspaces/workspaces/${options.workspaceID}/audit`,

  defaults: {
    resource: '',
  },

});

export default CopyWarningAudit;
