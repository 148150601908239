import { t } from 'i18n-js';
import { Grid, Divider } from 'semantic-ui-react';
import DatabaseTableCreationMetadataFromScratch from './DatabaseTableCreationMetadataFromScratch';
import DatabaseTableCreationMetadataFromTDF from './DatabaseTableCreationMetadataFromTDF';

const DatabaseTableCreationMetadata = () => (
  <Grid columns={2} className="aridhia-grid">
    <Divider vertical>{t('database_tables_new.or')}</Divider>
    <Grid.Row>
      <Grid.Column>
        <DatabaseTableCreationMetadataFromScratch />
      </Grid.Column>
      <Grid.Column>
        <DatabaseTableCreationMetadataFromTDF />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default DatabaseTableCreationMetadata;
