import { useContext, createContext } from 'react';

const DatabaseTablesContext = createContext();

export const useDatabaseTablesContext = () => useContext(DatabaseTablesContext);

export const DatabaseTablesProvider = ({ children, value }) => (
  <DatabaseTablesContext.Provider value={value}>
    {children}
  </DatabaseTablesContext.Provider>
);
