/* eslint-disable object-curly-newline,react/jsx-props-no-spreading,camelcase */
import React from 'react';
import { t } from 'i18n-js';
import { Accordion, Button, Form, FormField, Label } from 'semantic-ui-react';
import {
  convertNameToValidEnv,
  initialState,
  useEditFormState,
  useFormState,
  useOnChange,
  useOnEditChange,
} from '../hooks';
import StandardModal, { StandardModalContent } from '../../standard_modal';
import { useSecretCreation } from '../adapters';
import { requirePermission } from '../../permissions';
import { SemanticMarkdownEditor } from '../../components/text_editors/MarkdownEditor';
import { combineHooks, createUseInputValidity } from '../../../utilities/helpers';

const CreatePanel = ({ NameField, ValueField, EnvironmentNameField, DescriptionField }) => (
  <Form>
    {NameField}
    {ValueField}
    <Accordion panels={[{
      key: 'optional',
      title: 'Optional details',
      content: {
        content: (
          <>
            {EnvironmentNameField}
            {DescriptionField}
          </>
        ),
      },
    }]}
    />
  </Form>
);

const NameInputRaw = (props) => (
  <Form.Input
    required
    type="text"
    name="name"
    label={t('secrets.name')}
    aria-label={t('secrets.name')}
    input={{
      maxLength: 100,
      pattern: '[a-zA-Z0-9\\-]+',
    }}
    {...props}
  />
);
const NameInput = combineHooks(createUseInputValidity('secrets.name_validation'), NameInputRaw);
const useEnvironmentNameValidity = createUseInputValidity('secrets.environment_name_validation');
const EnvironmentNameInputRaw = ({ placeholder, ...props }) => (
  <Form.Input
    type="text"
    name="environment_name"
    label={t('secrets.environment_name')}
    aria-label={t('secrets.environment_name')}
    input={{
      maxLength: 100,
      placeholder,
      pattern: '[_a-zA-Z][_a-zA-Z0-9]*',
    }}
    {...props}
  />
);
const EnvironmentNameInput = combineHooks(useEnvironmentNameValidity, EnvironmentNameInputRaw);

const DescriptionInput = (props) => (
  <FormField>
    <label htmlFor="description">{t('secrets.description')}</label>
    <SemanticMarkdownEditor name="description" {...props} />
  </FormField>
);

const SecretInput = ({ remaining, ...props }) => (
  <>
    <Form.TextArea
      name="value"
      label={t('secrets.secret')}
      aria-label={t('secrets.secret')}
      {...props}
    />
    <Label content={t('secrets.length_remaining', { remaining })} />
  </>
);

const AddButton = () => (
  <Button icon="user secret" content={t('secrets.new')} />
);

const SecretForm = ({ mutationParameters, setMutationEnabled, isLoading }) => {
  const [{ name, valueError, remaining }, setFormState] = useFormState();
  const onChange = useOnChange(mutationParameters, setMutationEnabled, setFormState);

  return (
    <CreatePanel
      NameField={<NameInput key="name" disabled={isLoading} onChange={onChange} />}
      ValueField={(
        <SecretInput
          key="secret"
          required
          disabled={isLoading}
          onChange={onChange}
          error={valueError}
          remaining={remaining}
        />
)}
      EnvironmentNameField={(
        <EnvironmentNameInput
          placeholder={convertNameToValidEnv(name)}
          onChange={onChange}
          disabled={isLoading}
        />
)}
      DescriptionField={<DescriptionInput key="description" disabled={isLoading} onChange={onChange} />}
    />
  );
};

const EditSecretForm = ({ mutationParameters, setMutationEnabled, isLoading, secret }) => {
  const [{ valueError, remaining }, setFormState] = useEditFormState();
  const onChange = useOnEditChange(secret, mutationParameters, setMutationEnabled, setFormState);
  const { name, description, environment_name } = secret;
  return (
    <CreatePanel
      ValueField={(
        <SecretInput
          key="secret"
          placeholder={t('secrets.edit_blank_value')}
          disabled={isLoading}
          onChange={onChange}
          error={valueError}
          remaining={remaining}
        />
)}
      EnvironmentNameField={(
        <EnvironmentNameInput
          placeholder={convertNameToValidEnv(name)}
          defaultValue={environment_name}
          disabled={isLoading}
          onChange={onChange}
        />
)}
      DescriptionField={(
        <DescriptionInput
          key="description"
          defaultValue={description}
          disabled={isLoading}
          onChange={onChange}
        />
)}
    />
  );
};

const CreateSecretContent = ({ useMutationHook = useSecretCreation, ...props }) => (
  <StandardModalContent
    headerProps={{ icon: 'user secret', title: t('secrets.add.title') }}
    useMutationHook={useMutationHook}
    initiallyEnabled={false}
    defaultMutationParameters={initialState}
    actionProps={{ confirmationText: t('secrets.add.confirm') }}
    {...props}
  >
    <SecretForm />
  </StandardModalContent>
);

export const EditSecretContent = ({ useMutation, secret, ...props }) => (
  <StandardModalContent
    headerProps={{ icon: 'user secret', title: t('secrets.edit.title', secret) }}
    useMutationHook={useMutation}
    initiallyEnabled={false}
    defaultMutationParameters={{ ...initialState, ...secret }}
    actionProps={{ confirmationText: t('secrets.edit.confirm') }}
    {...props}
  >
    <EditSecretForm secret={secret} />
  </StandardModalContent>
);

const CreateSecret = requirePermission('Write', 'WorkspaceSecret')(({ trigger, ...props }) => {
  const trig = trigger || AddButton();

  return (
    <StandardModal trigger={trig}>
      <CreateSecretContent {...props} />
    </StandardModal>
  );
});

export default CreateSecret;
