import { Dropdown } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { workspaceAuditUrl, portalUIEditUrl, secretsUrl } from '../../../constants/ui_urls';
import { usePermissionCheck } from '../../../permissions';
import { useWorkspaceContext } from '../../../adapters/Workspace';
import useWorkspaceConfig from '../../../adapters/config';
import SnapshotModal from '../../../snapshot_modal';
import ViewAgreedRestrictions from '../../restrictions';

const isPermitted = ({ permitted }) => permitted;
const toItemProps = ({ permitted, ...item }) => ({ key: item.icon, selected: false, ...item });

const useAdministrationItems = () => {
  const { workspaceUuid } = useWorkspaceContext();
  const { config } = useWorkspaceConfig();
  const can = usePermissionCheck();

  const portalUrl = config?.administration_url;

  return [
    {
      text: t('workspaces.launcher.audit'),
      href: workspaceAuditUrl(),
      icon: { name: 'history', 'data-testid': 'audit-launcher-icon' },
      permitted: can('Read', 'WorkspaceAudit'),
      key: 'audit',
    },
    {
      text: t('workspaces.launcher.edit_workspace'),
      href: portalUIEditUrl(portalUrl, workspaceUuid),
      target: '_blank',
      icon: { name: 'edit', 'data-testid': 'edit-launcher-icon' },
      permitted: can('write meta or members', 'Workspace'),
      key: 'edit_workspace',
    },
    {
      text: t('workspaces.launcher.secrets'),
      href: secretsUrl(),
      icon: { name: 'user secret', 'data-testid': 'user-secret-launcher-icon' },
      permitted: can('Read', 'WorkspaceSecret'),
      key: 'secrets',
    },
    {
      text: t('workspaces.launcher.snapshot'),
      icon: { name: 'camera', 'data-testid': 'snapshot-launcher-icon' },
      permitted: can('Create', 'WorkspaceExport'),
      as: SnapshotModal,
      key: 'snapshot',
    },
    {
      text: t('restrictions.view_agreed_restrictions'),
      icon: { name: 'lock', 'data-testid': 'view-restrictions-icon' },
      permitted: can('Read', 'Workspace'),
      as: ViewAgreedRestrictions,
      key: 'restrictions',
    },
  ].filter(isPermitted).map(toItemProps);
};

const AdministerLauncherMenu = () => {
  const items = useAdministrationItems();

  if (items.length === 0) {
    return null;
  }

  return (
    <Dropdown
      aria-label={t('workspaces.launcher.administer')}
      icon={{ name: 'cog', title: t('workspaces.launcher.administer') }}
      text={t('workspaces.launcher.administer')}
      direction="left"
      item
      options={items}
    />
  );
};

export default AdministerLauncherMenu;
