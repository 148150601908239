import View from '../../loading_view';

export default View.extend({

  templateName: 'mini_apps/app_pagination',

  additionalContext() {
    return {
      disablePagination: this.apps.length <= 1,
      workspaceID: this.apps.attributes.workspaceID,
      nextAppID: this.nextApp(),
      prevAppID: this.prevApp(),
    };
  },

  setup(options) {
    this.apps = options.apps;
  },

  nextApp() {
    let newIndex = this.apps.indexOf(this.model) + 1;

    // Check if there is a next app, if not go back to the first one
    if (newIndex >= this.apps.length) {
      newIndex = 0;
    }
    const model = this.apps.models[newIndex];
    return model && model.id;
  },

  prevApp() {
    let newIndex = this.apps.indexOf(this.model) - 1;

    // Check if there is a previous app, if not go to the last one
    if (newIndex < 0) {
      newIndex = this.apps.length - 1;
    }
    const model = this.apps.models[newIndex];
    return model && model.id;
  },

});
