import chorus from '../chorus';
import _ from '../underscore';
import t from '../intl';
import $ from '../jquery';
import Backbone from '../vendor/backbone';
import ArtifactListSidebar from '../views/artifact_list_sidebar';
import DatasetSidebar from '../views/dataset/dataset_sidebar';
import WorkfileSidebar from '../views/workfiles/workfile_sidebar';
import UserSidebar from '../views/users/user_sidebar';
import MainContentView from '../views/core/main_content_view';
import ListContentDetails from '../views/core/list_content_details_view';
import ListHeaderView from '../views/core/list_header_view';
import EditTags from '../dialogs/tags/edit_tags_dialog';
import MultipleSelectionSidebarMenu from '../views/core/multiple_selection_sidebar_menu';
import SearchResults from '../views/search/search_results_view';
import BasePage from './base_page';
import SearchResult from '../models/search_result';
import { searchWorkspace } from '../utilities/workspace_assoc';
import Routing from '../mixins/routing';

export default BasePage.include(Routing).extend({
  crumbs: [
    { label: t('breadcrumbs.search_results') },
  ],
  helpId: 'search',

  parseSearchParams(searchParams) {
    const attrs = {
      query: (searchParams[2] || searchParams[0]),
    };
    if (searchParams.length === 3) {
      const searchIn = searchParams[0];
      const entityType = searchParams[1];
      attrs.searchIn = searchIn;
      attrs.entityType = entityType;
    }
    return attrs;
  },

  makeModel(...args) {
    const searchParams = _.toArray(args);
    this.model = new SearchResult(this.parseSearchParams(searchParams));
    this.search = this.model;
  },

  setup() {
    this.buildMainContent();
    this.listenTo(this.model, 'loaded', this.resourcesLoaded);
    this.handleFetchErrorsFor(this.model);
    this.model.fetch();
  },

  unprocessableEntity() {
    chorus.pageOptions = {
      title: t('search.bad_entity_type.title'),
      text: t('search.bad_entity_type.text'),
    };
    Backbone.history.loadUrl('/unprocessableEntity');
  },

  searchInMenuOptions() {
    return [
      { data: 'all', text: t('search.in.all') },
      { data: 'my_workspaces', text: t('search.in.my_workspaces') },
    ];
  },

  typeOptions() {
    return [
      { data: 'all', text: t('search.type.all') },
      { data: 'workfile', text: t('search.type.workfile') },
      { data: 'attachment', text: t('search.type.attachment') },
      { data: 'dataset', text: t('search.type.dataset') },
      { data: 'workspace', text: t('search.type.workspace') },

    ];
  },

  title() {
    return t('search.index.title', {
      query: this.model.displayShortName(),
    });
  },

  buildMultiSelectSidebar() {
    const self = this;
    this.multiSelectSidebarMenu = new MultipleSelectionSidebarMenu({
      collections: this.sidebars,
      actions: [
        '<a class="edit_tags">{{t "sidebar.edit_tags"}}</a>',
      ],
      actionEvents: {
        'click .edit_tags': _.bind(function editTags() {
          new EditTags({ collection: this.multiSelectSidebarMenu.selectedModels }).launchModal();
        }, this),
      },
    });

    $.each(self.sidebars, (key, value) => {
      self.listenTo(value.data, value.selectEvent, self.setCheckedModel);
    });
  },

  initialiseSideBars() {
    const self = this;
    this.sidebars = {
      attachment: { data: this.model.attachments(), selectEvent: 'checked', selectModel: 'attachment:selected' },
      dataset: { data: this.model.datasets(), selectEvent: 'checked', selectModel: 'dataset:selected' },
      workfile: { data: this.model.workfiles(), selectEvent: 'checked', selectModel: 'workfile:selected' },
      workspace: { data: this.model.workspaces(), selectEvent: 'checked', selectModel: 'workspace:selected' },
      user: { data: this.model.users(), selectEvent: 'checked', selectModel: 'user:selected' },
    };

    $.each(self.sidebars, (key, value) => {
      self.listenTo(value.data, value.selectModel, self.setModel);
    });
  },

  buildSidebars() {
    this.initialiseSideBars();
    this.sidebars.attachment.sidebar = new ArtifactListSidebar();
    this.sidebars.dataset.sidebar = new DatasetSidebar({
      listMode: true,
      searchPage: true,
      collection: this.sidebars.dataset.data,
    });
    this.sidebars.workfile.sidebar = new WorkfileSidebar({
      collection: this.sidebars.workfile.data,
      showEditingLinks: false,
    });
    this.sidebars.user.sidebar = new UserSidebar({ listMode: true, collection: this.sidebars.user.data });
  },

  buildSidebar() {
    this.buildSidebars();
    this.buildMultiSelectSidebar();
  },

  buildMainContent() {
    this.mainContent = new MainContentView({
      contentDetails: new ListContentDetails({
        collection: this.model,
        modelClass: 'SearchResult',
        hideCounts: true,
      }),
      contentHeader: new ListHeaderView({
        title: this.title(),
        linkMenus: {
          search_in: {
            title: t('search.search_in'),
            options: this.searchInMenuOptions(),
            chosen: this.search.searchIn(),
            event: 'search_in',
          },
          type: {
            title: t('search.show'),
            chosen: this.search.entityType(),
            options: this.typeOptions(),
            event: 'filter',
          },
        },
      }),
    });
  },

  resourcesLoaded() {
    this.mainContent.content = new SearchResults({ model: this.model });
    if (this.search.isPaginated() && !searchWorkspace(this.search)) {
      this.mainContent.contentDetails = new ListContentDetails({
        collection: this.search.getResults(),
        modelClass: 'SearchResult',
        multiSelect: true,
      });
      this.mainContent.contentFooter = new ListContentDetails({
        collection: this.search.getResults(),
        modelClass: 'SearchResult',
        hideCounts: true,
        hideIfNoPagination: true,
      });
    } else { this.mainContent.contentDetails = undefined; }

    this.buildSidebar();
    this.subscribePageEvent('choice:search_in', this.scopeSearchResults);
    this.subscribePageEvent('choice:filter', this.filterSearchResults);

    this.render();
  },

  setModel(model) {
    const { sidebar } = this.sidebars[model.entityType];
    switch (model.entityType) {
      case 'attachment': sidebar.setArtifact(model); break;
      case 'dataset': sidebar.setDataset(model); break;
      case 'user': sidebar.setUser(model); break;
      default: sidebar.setWorkfile(model); break;
    }
    this.renderSidebar(sidebar);
  },

  setCheckedModel() {
    const models = this.multiSelectSidebarMenu.selectedModels;
    if (models.length === 1) {
      this.setModel(models.models[0]);
    }
  },

  renderSidebar(sidebar) {
    if (!this.sidebar || this.sidebar !== sidebar) {
      const classes = 'attachment_list_sidebar dataset_list_sidebar user_list_sidebar';
      this.sidebar && $(this.sidebar.el).removeClass(classes);
      this.sidebar = sidebar;
      this.renderSubview('sidebar');
      this.trigger('resized');
    }
  },

  postRender() {
    this.$('.result_item').eq(0).click();
  },

  scopeSearchResults(data) {
    this.search.set({ searchIn: data });
    this.navigate(this.search.showUrl());
  },

  filterSearchResults(entityType) {
    this.search.set({ entityType });
    this.navigate(this.search.showUrl());
  },
});
