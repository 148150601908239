import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import PickItems from '../pick_items_dialog';
import WorkfileSet from '../../collections/workfile_set';
import WorkfileFolder from '../../models/workfile_folder';
import Workfile from '../../models/workfile';

export default PickItems.extend({
  constructorName: 'WorkfolderDestination',
  templateName: 'workfolders_attach',

  title: t('workfolder.destination'),
  submitButtonTranslationKey: 'actions.confirm',
  emptyListTranslationKey: 'workfolders.none',
  selectedEvent: 'files:selected',
  modelClass: 'Workfile',
  multiSelection: false,
  folder: 'files',

  events: {
    'click .workfile-folder': 'changeRootFolder',
    'click .workfile-breadcrumb-folder': 'changeFolderViaBreadcrumb',
  },

  makeModel(...args) {
    this._super('makeModel', args);
    this.options = _.extend({
      collection: new WorkfileSet([], {
        workspaceId: this.options.workspace.id,
        succinct: true,
      }),
      crumbs: true,
    }, this.options);
  },

  setup() {
    this.workspace = this.options.workspace;

    this._super('setup');

    if (this.options.folder) {
      this.setFolder(this.createFolder(this.options.folder));
    } else {
      this.setFolder(this.workspace.defaultWorkfilesFolder());
    }
  },

  postRender() {
    this._super('postRender');
    this.$('ul').find(`[data-folder='${this.folder.topLevelFolder()}']`).addClass('active');
  },

  collectionModelContext(model) {
    return {
      name: model.get('fileName'),
      imageUrl: model.iconUrl({ size: 'icon' }),
    };
  },

  additionalContext() {
    let fileName = this.workfile ? this.workfile.getFileName() : null;
    if (!fileName && this.options.workfile) {
      fileName = this.options.workfile.getFileName();
    }
    return _.extend(this._super('additionalContext'), {
      folders: this.folderOptions(),
      fileName,
      excludeFileName: this.excludeFileName,
    });
  },

  folderOptions() {
    return _.map(this.workspace.workfileFolders(), folder => ({ data: folder }));
  },

  changeRootFolder(e) {
    e.preventDefault();
    this.$el.find('li').removeClass('active');
    $(e.target).addClass('active');
    this.setFolder(this.createFolder($(e.target).data('folder')));
  },

  changeFolderViaBreadcrumb(e) {
    e.preventDefault();
    this.setFolder(this.createFolder($(e.target).data('folder')));
  },

  itemSelected(selectedItems) {
    let selectedItem = selectedItems;
    if (this.multiSelection) {
      selectedItem = selectedItems.slice(-1)[0];
    }
    if (selectedItem && selectedItem.isFolder()) {
      this.setFolder(this.createFolder(`${selectedItem.get('folder')}/${selectedItem.get('fileName')}`));
    } else {
      this.multiSelection ? this._super('itemSelected', selectedItems) : this._super('itemSelected', selectedItem);
    }
  },

  crumbs() {
    const crumbs = [];
    const parts = this.folder.name().split('/');

    _.each(parts, function addCrumb(data, idx) {
      crumbs.push({ label: data, data_folder: this.slice(0, idx + 1).join('/') });
    }, parts);

    crumbs.shift();
    return crumbs;
  },

  createFolder(folder) {
    return new WorkfileFolder({ folder, workspaceId: this.workspace.id });
  },

  setFolder(choice) {
    this.folder = choice;
    this.trigger('folder:changed', this.folder.name());
    this.paginationView.collection.attributes.foldersOnly = true;
    this.paginationView.collection.attributes.folder = this.folder.name();
    this.paginationView.fetchFirstPage();
  },

  resetSelection() {
    this.renderSubviews();
    this._super('resetSelection');
  },

  enableOrDisableSubmitButton() {
    this.$('button.submit').removeAttr('disabled');
  },

  submit() {
    this.$('button.submit').attr('disabled', 'disabled');
    this.closeModal();
  },
});
