import { useQueries, useQuery } from '@tanstack/react-query';
import { getter, queryClient, workspaceTokenGetter } from '../adapters/service';
import { workspaceAppsUrl, workspaceAppPodsUrl } from '../constants/api_urls';
import { appPodStatuses } from './constants';

const fetchApps = (id) => getter(workspaceAppsUrl(id));
const fetchAppPods = (id) => workspaceTokenGetter(workspaceAppPodsUrl(id), id);

export const invalidateApps = (id) => {
  queryClient.invalidateQueries(['apps', id]);
  queryClient.invalidateQueries(['appPods', id]);
};

export const useApps = (id) => {
  const {
    data,
    isError,
    isLoading,
    isRefetching,
  } = useQuery(['containerisedApps'], () => fetchApps(id));

  return {
    apps: data?.response || [],
    isLoading,
    isError,
    isRefetching,
  };
};

export const mergeAppData = (apps, appPods) => (
  apps.map((app) => {
    const appPod = appPods.find((pod) => pod.image === app.image_name);

    const data = {
      ...app,
      displayPictureSrc: app.display_picture,
      additionalInfo: app.additional_info,
      podStatus: appPod?.status || appPodStatuses.INACTIVE,
    };
    delete data.display_picture;
    delete data.additional_info;

    return data;
  })
);

export const useAppsData = (id) => {
  const [apps, statuses] = useQueries({
    queries: [
      {
        queryKey: ['apps', id],
        queryFn: () => fetchApps(id),
      },
      {
        queryKey: ['appPods', id],
        queryFn: () => fetchAppPods(id),
      },
    ],
  });

  const isLoading = apps.isLoading || statuses.isLoading;
  const isError = apps.isError || statuses.isError;
  const isRefetching = apps.isRefetching || statuses.isRefetching;

  let appsData;
  if (!isLoading && !isError && apps.data?.response && statuses.data?.apps) {
    appsData = mergeAppData(apps.data.response, statuses.data.apps);
  }

  return {
    appsData,
    pagination: apps.data?.pagination || {},
    isLoading,
    isError,
    isRefetching,
  };
};
