import { t } from 'i18n-js';
import {
  Header, Form, Input, Segment,
} from 'semantic-ui-react';
import { useDatabaseTableCreationContext } from '../Context';
import { validationLengths } from '../../../../constants/validation';
import { validTableName } from '../../../../utilities/validation';
import { DatabaseTableCreationTableInvalidNameWarning } from './DatabaseTableCreationTableWarnings';
import { setTableNameFromScratch } from '../actions';

const DatabaseTableCreationTableFromScratch = () => {
  const { newDatabaseTableState, dispatch } = useDatabaseTableCreationContext();
  const showWarning = !!(newDatabaseTableState.table.data_table_name
    && !validTableName(newDatabaseTableState.table.data_table_name));

  const handleNameChange = (e, { value }) => {
    dispatch(setTableNameFromScratch(value));
  };

  return (
    <>
      <Segment vertical basic>
        <Header
          as="h5"
          content={t('database_tables_new.new_table.from_scratch.header')}
          className="italic"
          textAlign="center"
          color="grey"
        />
      </Segment>
      <Segment vertical basic>
        <Form>
          <Form.Field
            className={showWarning ? 'warning' : ''}
          >
            <label htmlFor="name">
              {t('database_tables_new.new_table.from_scratch.label')}
              *
            </label>
            <Input
              id="name"
              disabled={!!newDatabaseTableState.table.source_file || !!newDatabaseTableState.passed_csv_file}
              placeholder={t('database_tables_new.new_table.from_scratch.placeholder')}
              maxLength={validationLengths.NAME}
              value={newDatabaseTableState.table.data_table_name}
              onChange={handleNameChange}
            />
          </Form.Field>
        </Form>
        {showWarning && (
          <DatabaseTableCreationTableInvalidNameWarning />
        )}
      </Segment>
    </>
  );
};

export default DatabaseTableCreationTableFromScratch;
