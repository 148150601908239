import chorus from '../chorus';
import _ from '../underscore';
import $ from '../jquery';
import PickItemsList from './pick_items_list';
import ListContentDetails from '../views/core/list_content_details_view';
import BreadcrumbsView from '../views/core/breadcrumbs_view';
import Dialog from './dialogs';

export default Dialog.extend({
  constructorName: 'PickItems',

  templateName: 'pick_items',
  useLoadingSection: true,
  additionalClass: 'with_sub_header',
  returnModels: false,

  events: {
    'click button.submit': 'submitClicked',
    'dblclick li': 'doubleClick',
  },

  subviews: {
    '.pick_items_list': 'pickItemsList',
    '.breadcrumbs': 'breadcrumbs',
    '.pagination': 'paginationView',
  },

  setup() {
    this.configurePagination();
    this.pickItemsList = new PickItemsList({
      collection: this.options.collection,
      modelClass: this.modelClass,
      emptyListTranslationKey: this.emptyListTranslationKey,
      multiSelection: this.multiSelection,
      defaultSelection: this.options.defaultSelection,
    });

    // forwarding inheritance on to pickItemsList
    this.pickItemsList.baseCollectionModelContext = this.collectionModelContext;

    this.listenTo(this.options.collection, 'reset', this.resetSelection);
    this.listenTo(this.pickItemsList, 'item:selected', this.itemSelected);
  },

  configureSearch() {
    const self = this;

    const onTextChangeFunction = _.debounce(_.bind((e) => {
      self.paginationView.search($(e.target).val());
    }, this), 300);

    chorus.search({
      input: this.$('.search'),
      onTextChange: this.options.onTextChange || onTextChangeFunction,
    });
  },

  configurePagination() {
    this.paginationView = new ListContentDetails({
      collection: this.options.collection,
      modelClass: this.modelClass,
      hideIfNoPagination: false,
    });
  },

  selectionFinished() {
    let selectedItems = null;
    if (this.returnModels) {
      selectedItems = this.pickItemsList.selectedItemModels;
    } else {
      const listOrItem = this.selectedItem();
      selectedItems = _.isArray(listOrItem) ? listOrItem : [listOrItem];
    }

    this.trigger(this.selectedEvent, selectedItems);
    this.closeModal();
  },

  postRender() {
    this._super('postRender');
    this.configureSearch();
    if (this.options.crumbs) {
      this.configureBreadcrumbs();
    }
    this.enableOrDisableSubmitButton();
  },

  collectionModelContext(model) {
    return {
      name: model.name(),
      location: '',
      imageUrl: '',
    };
  },

  additionalContext() {
    return {
      pagination: this.pagination,
      placeholderTranslation: this.searchPlaceholderKey || 'pickitem.dialog.search.placeholder',
      submitButtonTranslationKey: this.submitButtonTranslationKey || 'pickitem.dialog.submit',
      dialogDescriptionKey: this.dialogDescriptionKey || '',
    };
  },

  resetSelection() {
    this.enableOrDisableSubmitButton();
    this.pickItemsList.resetSelection();
  },

  enableOrDisableSubmitButton() {
    if (this.$('li.selected').length > 0) {
      this.$('button.submit').removeAttr('disabled');
    } else {
      this.$('button.submit').attr('disabled', 'disabled');
    }
  },

  itemSelected(item) {
    this.trigger('item:selected', item);
    this.enableOrDisableSubmitButton();
  },

  selectedItem() {
    return this.pickItemsList.selectedItem();
  },

  doubleClick(e) {
    this.pickItemsList.itemClicked(e);
    this.submit();
  },

  submitClicked(e) {
    e.preventDefault();
    this.submit();
  },

  submit() {
    this.selectionFinished();
  },

  configureBreadcrumbs() {
    this.breadcrumbs = new BreadcrumbsView({
      breadcrumbs: _.isFunction(this.crumbs) ? _.bind(this.crumbs, this) : this.options.crumbs,
    });
  },
});
