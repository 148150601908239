import _ from '../../underscore';
import WorkspaceMemberList from './workspace_member_list_view';
import WorkspaceSidebarBasic2 from './workspace_sidebar_basic_view2';
import Sidebar from '../core/sidebar_view';

export default Sidebar.extend({
  constructorName: 'WorkspaceShowSidebarView',
  templateName: 'workspace_show_sidebar',
  consoleSessionsAvailable: '?',

  subviews: {
    '.workspace_member_list': 'workspaceMemberList',
    '.workspace_sidebar_basic': 'workspaceSidebarBasic',
  },

  setup() {
    this.workspaceMemberList = new WorkspaceMemberList({ workspace: this.model, show: true });
    this.workspaceSidebarBasic = new WorkspaceSidebarBasic2({ model: this.model });

    this.listenTo(this.model.getConsoleUsage(), 'sync', this.consoleUsageUpdate);
  },

  postRender() {
    const self = this;
    this.$('.workspace_image').load(() => {
      self.$('.after_image').removeClass('hidden');
    });
    this._super('postRender');
  },

  additionalContext() {
    return {
      consoleSessionsAvailable: this.consoleSessionsAvailable,
    };
  },

  consoleUsageUpdate(model) {
    const resourceSessions = model.get('consoleSessions');

    this.$('.app-consoleSessionsAvailable').text(resourceSessions);

    this.consoleSessionsAvailable = resourceSessions;
  },
});
