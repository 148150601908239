import { useDatabaseTableCreationContext } from '../Context';
import DatabaseTableCreationColumnsFromCSV from './DatabaseTableCreationColumnsFromCSV';
import DatabaseTableCreationColumnsFromScratch from './DatabaseTableCreationColumnsFromScratch';

const DatabaseTableCreationColumns = () => {
  const { newDatabaseTableState } = useDatabaseTableCreationContext();

  if (newDatabaseTableState.table.source_file_id) {
    return <DatabaseTableCreationColumnsFromCSV />;
  }

  return <DatabaseTableCreationColumnsFromScratch />;
};

export default DatabaseTableCreationColumns;
