import t from '../../intl';
import WorkspaceSearchResultPageItemList from './workspace_search_result_page_item_list';
import SearchResultList from './search_result_list';
import { searchWorkspace } from '../../utilities/workspace_assoc';
import Routing from '../../mixins/routing';

export default SearchResultList.include(Routing).extend({
  constructorName: 'WorkspaceSearchResultList',

  title() {
    return t('search.type.this_workspace', { name: searchWorkspace(this.search).get('name') });
  },

  buildList() {
    return new WorkspaceSearchResultPageItemList({
      collection: this.collection,
      selectedModels: this.selectedModels,
      listItemOptions: { search: this.options.search },
    });
  },

  showAll(e) {
    e.preventDefault();
    this.search.set({ searchIn: 'this_workspace' });
    this.navigate(this.search.showUrl());
  },
});

