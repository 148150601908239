import { loadNavbarState } from '../layout/layoutState';

const PageWrapper = ({ children }) => {
  const isNavHidden = loadNavbarState();

  let className = 'logged_in_layout_with_tabs';
  if (isNavHidden) {
    className += ' hidden-nav';
  }

  return (
    <div data-testid="layout-page" id="layout-page" className={className}>
      {children}
    </div>
  );
};

export default PageWrapper;
