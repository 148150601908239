import { useQuery } from '@tanstack/react-query';
import { workspaceFileAttachmentsUrl } from '../../constants/api_urls';
import { getter } from '../../adapters/service';
import { workspaceId } from '../../layout/helper';

export const useFilesOrTables = (folder, page, namePattern) => {
  const fetchFiles = () => getter(workspaceFileAttachmentsUrl(folder, page, namePattern));
  const {
    data,
    isFetching,
    isError,
    refetch,
  } = useQuery(['attach', workspaceId(), folder, page, namePattern], fetchFiles);

  return {
    files: data ? data.response : [],
    pagination: data ? data.pagination : {},
    isError,
    isFetching,
    refetch,
  };
};

export default useFilesOrTables;
