import {
  Icon, Header, Segment,
} from 'semantic-ui-react';
import { t } from 'i18n-js';

const NoAppsMessage = () => (
  <Segment placeholder>
    <Header as="h4" icon>
      <Icon name="play" color="blue" data-testid="no-apps-icon" />
      {t('apps.apps_panel.message.no_apps')}
    </Header>
  </Segment>
);

export default NoAppsMessage;
