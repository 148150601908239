import { useQuery } from '@tanstack/react-query';
import { rawGetter } from '../adapters/service';
import { homepageUrl } from '../constants/api_urls';

const useHomepage = (options) => useQuery({
  queryKey: ['homepage'],
  queryFn: () => rawGetter(homepageUrl()),
  ...options,
});

export default useHomepage;
