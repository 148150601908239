import _ from '../underscore';
import { SafePgName } from '../../utilities/validation_regexes.js';

export default {
  safePGName(...args) {
    function encode(name) {
      const isSafe = name.match(SafePgName());
      return isSafe ? name : `"${name}"`;
    }

    return _.map(args, arg => encode(arg)).join('.');
  },

  sqlEscapeString(string) {
    return string.replace(/'/g, "''");
  },
};
