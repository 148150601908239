import { useState } from 'react';
import Backbone from 'backbone';
import MiniAppsShowView from '../../../../../application/views/mini_apps/apps/mini_apps';
import ReactToBackboneWrapper from '../../ReactToBackboneWrapper';
import NotAuthorised from '../../../../components/errors/NotAuthorised';

const ShinyAppsPanel = ({ tab, workspace }) => {
  const { component, id } = tab;
  const [view] = useState(() => new MiniAppsShowView({
    model: workspace,
    tabModel: new Backbone.Model({ active: true }),
  }));

  if (!workspace.hasPermission('WorkspaceShinyApp.Read')) {
    return <NotAuthorised />;
  }

  return (
    <ReactToBackboneWrapper
      view={view}
      tabId={id}
      component={component}
    />
  );
};

export default ShinyAppsPanel;
