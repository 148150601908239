import { useState } from 'react';
import WorkspaceSummary from '../../../../../application/views/workspaces/workspace_summary_view';
import ReactToBackboneWrapper from '../../ReactToBackboneWrapper';

const SummaryPanel = ({ tab, workspace }) => {
  const { component, id } = tab;
  const [view] = useState(() => new WorkspaceSummary({ model: workspace }));

  return (
    <ReactToBackboneWrapper
      view={view}
      tabId={id}
      component={component}
    />
  );
};

export default SummaryPanel;
