import {
  Modal, Button, Header, Icon,
} from 'semantic-ui-react';
import { t } from 'i18n-js';

const CopyWarningModal = ({ setModalClose }) => (
  <>
    <Header as="header" className="aridhia-modal-header">
      <Icon name="exclamation triangle" data-testid="copy-warning-modal-header-icon" />
      <Header.Content>{t('copy_warning.title')}</Header.Content>
    </Header>
    <Modal.Content>
      <p>
        {t('copy_warning.content')}
        <a href={t('url.knowledgebase_terms')} rel="noopener noreferrer" target="_blank">
          {t('copy_warning.tandc')}
        </a>
      </p>
    </Modal.Content>
    <Modal.Actions>
      <Button
        color="blue"
        onClick={setModalClose}
      >
        {t('copy_warning.accept')}
      </Button>
    </Modal.Actions>
  </>
);

export default CopyWarningModal;
