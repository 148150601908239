import React from 'react';
import View from '../react_view';
import MarkdownRender from 'new_ux/components/text_editors/MarkdownRender';
import _ from '../../underscore';
import chorus from '../../chorus';

export default View.extend({
  constructorName: 'markdown_workfile_content',

  setup(options) {
    this.scrollPanelDisable = false;
    this._super('setup', options);
  },

  renderOnce() {
    this.root.render(
      <MarkdownRender value={this.model.content()} className={'markdown-workfile-content'} />,
    );
  },

  postRender() {
    _.delay(_.bind(function setScroll() {
      chorus.page.trigger('resized');
    }, this), 500);
  },
});
