import _ from '../underscore';
import dynamicDataset from './dynamic_dataset';
import ChorusView from './chorus_view';
import Workfile from './workfile';
import WorkspaceDataset from './workspace_dataset';
import Model from './models';
import Attachment from '../mixins/attachment';
import fileIconUrl from '../utilities/url_helpers';

export default Model.include(Attachment).extend({
  constructorName: 'Attachment',
  entityType: 'attachment',

  showUrlTemplate: 'workspaces/{{workspace_id}}/activity/{{activity_id}}/attachment/{{id}}',

  iconUrl(options) {
    if (this.get('iconUrl')) {
      return this.get('iconUrl');
    }
    return fileIconUrl(this.get('type') || this.get('fileType'), options && options.size);
  },

  hasOwnPage() {
    return true;
  },

  downloadUrl() {
    return this.get('contentUrl');
  },

  thumbnailUrl() {
    return `/file/${this.get('fileId') || this.get('id')}/thumbnail`;
  },

  isImage() {
    return this.get('type') === 'IMAGE' || this.get('fileType') === 'IMAGE';
  },

  workfile() {
    if (!this._workfile) {
      this._workfile = this.get('workfile') && new Workfile(this.get('workfile'));
    }
    return this._workfile;
  },

  dataset() {
    if (!this._dataset) {
      const dataset = this.get('dataset');
      if (dataset) {
        if (dataset.entitySubtype === 'CHORUS_VIEW') {
          this._dataset = new ChorusView(dataset);
        } else if (_.isEmpty(this.get('workspace'))) {
          this._dataset = dynamicDataset(dataset);
        } else {
          this._dataset = new WorkspaceDataset(dataset);
          this._dataset.set({ workspace: this.get('workspace') });
        }
      }
    }
    return this._dataset;
  },
});
