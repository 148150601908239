import View from '../loading_view';

export default View.extend({
  constructorName: 'SubNavView',
  templateName: 'sub_nav',
  tagName: 'ul',

  setup(options) {
    this.resource = this.resource || options.workspace;
  },

  postRender() {
    this.$('li').removeClass('active');
    const selected = this.$(`li.${this.options.tab}`);
    selected.addClass('active');
  },
});
