import _ from '../underscore';
import BarePage from './bare_page';
import Header from '../views/core/header';
import BreadcrumbsView from '../views/core/breadcrumbs_view';

export default BarePage.extend({
  constructorName: 'Page',
  templateName: 'logged_in_layout',

  subviews: {
    '#announcement': 'announcement',
    '#tracking': 'tracking',
    '#copywarningalert': 'copywarningalert',
    '#header': 'header',
    '#main_content': 'mainContent',
    '.breadcrumbs': 'breadcrumbs',
    '#sidebar .sidebar_content.primary': 'sidebar',
    '#sidebar .sidebar_content.secondary': 'secondarySidebar',
    '#sidebar .multiple_selection': 'multiSelectSidebarMenu',
    '#sub_nav': 'subNav',
    '#sub_nav_tools': 'subNavTools',
    '#toast': 'toast',
  },

  // Instantiate and attached the header and breadcrumb views for the page.
  _initializeHeaderAndBreadcrumbs() {
    this.header = this.header || new Header({ model: this.model });
    const page = this;
    this.breadcrumbs = new BreadcrumbsView({
      breadcrumbs: _.isFunction(page.crumbs) ? _.bind(page.crumbs, page) : page.crumbs,
    });
  },
});
