import {
  Button,
  Comment,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import Activity from './Activity';
import AddNoteModal from './modals/AddNoteModal';
import Can from '../../permissions';
import ModalWrapper from '../../components/modals/ModalWrapper';

const NoActivitesMessage = ({ entityId, entityName }) => (
  <Segment placeholder>
    <Header as="h4" icon>
      <Icon name="calendar check outline" data-testid="activity-icon" />
      {t('activities.no_activities')}
    </Header>
    <Can I="Interact with" this="Workspace">
      <ModalWrapper trigger={<Button icon={{ name: 'add', 'data-testid': 'add-note-icon' }} primary content={t('tables_list.actions.add_note')} />}>
        <AddNoteModal
          entityType="dataset"
          entityId={entityId}
          entityName={entityName}
        />
      </ModalWrapper>
    </Can>
  </Segment>
);

const Activities = ({ activities, entityId, entityName }) => (
  <Comment.Group threaded data-testid="activities">
    {activities?.map((activity) => (
      <Activity
        key={activity.id}
        activity={activity}
        entityId={entityId}
      />
    ))}
    {activities?.length === 0 && (
    <NoActivitesMessage
      entityId={entityId}
      entityName={entityName}
    />
    )}
  </Comment.Group>
);

export default Activities;
