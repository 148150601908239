import { Icon } from 'semantic-ui-react';

const ICONS = {
  home: 'home',
  secrets: 'user secret',
  new_view: ['table', 'eye'],
  custom_views: ['table', 'eye'],
  database_views: ['database', 'eye'],
  database_table: 'table',
  database_table_creation: 'table',
  desktops: 'computer',
};

const lookupIcon = (component) => (ICONS[component]);

/**
 * Produce icons for Flexlayout tabs.
 *
 * If the component attribute on the tab node has an associated entry in the ICONS
 * lookup, the associated value(s) will be applied to react-semantic-ui `Icon`s.
 *
 * If ICONS has no entry for this component, `undefined` is returned to instruct
 * FlexLayout to fall back to using the icon attribute, which will be used as the
 * `src` for an `img`.
 * See https://github.com/caplin/FlexLayout/blob/88cbbf55d005a94e9fc469c5ad7fd56fc363d31b/src/view/Utils.tsx
 *
 * @param {TabNode} node - the FlexLayout tab node.
 * @returns {ReactElement | undefined}
 */
const iconFactory = (node) => {
  const component = node.getComponent();
  const iconName = lookupIcon(component);

  if (!iconName) {
    return undefined;
  }

  if (Array.isArray(iconName)) {
    return (
      <Icon.Group>
        <Icon name={iconName[0]} />
        <Icon corner="top right" name={iconName[1]} />
      </Icon.Group>
    );
  }

  return <Icon name={iconName} className="margin-right-none" />;
};

export default iconFactory;
