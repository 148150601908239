import { Grid } from 'slickgrid-es6';
import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import View from '../loading_view';

export default View.extend({
  templateName: 'data_grid',
  constructorName: 'DataGrid',
  events: { 'click .slick-cell': 'selectCell' },
  columnStartingWidth: 100,

  slickStyleColumns(sortable) {
    const self = this;
    return _.map(this.model.getColumns(), column => ({
      name: chorus.htmlEncode(column.name),
      field: column.uniqueName,
      id: column.uniqueName,
      sortable,
      minWidth: self.columnStartingWidth,
    }));
  },

  postRender() {
    if (typeof this.options.sortable === 'undefined') {
      this.options.sortable = true;
    }
    const columns = this.slickStyleColumns(this.options.sortable);
    this.rows = this.model.getRows();

    this.grid && this.grid.destroy();
    this.grid = new Grid(this.$('.grid'), this.rows, columns, this.mergeGridOptions(columns));
    if (this.options.sortable) {
      this.grid.onSort.subscribe(_.bind(this.sortFunction, this));
    }
  },

  resizeGridToResultsConsole() {
    this.grid.resizeCanvas();
    this.grid.invalidate();
  },

  cellFormatter(row, cell, value) {
    if (!value) { return value; }

    return $('<span/>').attr('title', value).text(value).outerHtml();
  },

  selectCell(e) {
    if (window.getSelection && document.createRange) {
      const sel = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(e.currentTarget);
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (document.selection && document.body.createTextRange) {
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(e.currentTarget);
      textRange.select();
    }
  },

  sortFunction(e, args) {
    const { field } = args.sortCol;

    const sort = function sortOn(a, b) {
      let result = 0;
      if (a[field] < b[field]) { result = -1; }
      if (a[field] > b[field]) { result = 1; }

      return args.sortAsc ? result : -result;
    };

    this.rows.sort(sort);
    this.grid.invalidate();
  },

  teardown() {
    this.grid && this.grid.destroy();
    this._super('teardown');
  },

  forceFitColumns(columns) {
    return (columns.length * this.columnStartingWidth) <= this.$el.width();
  },

  mergeGridOptions(columns) {
    return {
      defaultColumnWidth: 130,
      defaultFormatter: this.cellFormatter,
      enableCellNavigation: false,
      enableColumnReorder: false,
      enableTextSelectionOnCells: true,
      forceFitColumns: this.forceFitColumns(columns),
      syncColumnCellResize: true,
    };
  },
});
