import WorkfileVersion from '../models/workfile_version';
import Collection from './collections';
import MultiModelSet from '../mixins/multi_model_set';

export default Collection.include(MultiModelSet).extend({
  constructorName: 'WorkfileVersionSet',
  urlTemplate: 'api/workspaces/workfiles/{{workfileId}}/versions',
  model: WorkfileVersion,
  comparator(model) {
    return -model.get('versionInfo').versionNum;
  },

  // used by the MultiModelSet mixin
  idTemplate(model) {
    return `${model.id}v${model.get('versionInfo').versionNum}`;
  },

  workspace() {
    return this._workspace;
  },

  setWorkspace(workspace) {
    this._workspace = workspace;
  },
});
