import parse from 'csv-parse/lib/es5/sync';
import Backbone from 'backbone';
import { getColumnOrientedData } from '../utilities/csv_parser_utilities';
import { ChorusIdentifier64, ChorusIdentifierLower64 } from '../utilities/validation_regexes';
import { sizeAsString } from '../utilities/helpers';
import chorus from '../application/chorus';

const DatasetUpload = Backbone.Model.extend({
  url: () => 'api/workspaces/datasets/import',

  defaults: {
    workspace_id: 0,
    source_file: '',
    data_table_name: '',
    delimiter: ',',
    custom_delimiter: '',
    input_has_header: true,
    text_qualifier: '"',
    null_qualifier: '',
    encoding: 'UTF-8',
    columns: [],
    original_columns: [],
    add_key_column: true,
    key_column_is_primary: true,
    preview_content: '',
    parser_error_exists: false,
    ready_to_upload: false,
    loaded: true,
    tdf_source_file: null,
    tdf_source_file_id: null,
    title: '',
    url: '',
    description: '',
  },

  setReadyToUpload() {
    let readyToUpload = true;
    if (!this.get('data_table_name') || !this.get('data_table_name').match(ChorusIdentifier64())) {
      readyToUpload = false;
    } else if (this.get('parser_error_exists')) {
      readyToUpload = false;
    } else {
      let hasActiveColumns = false;
      const columns = this.get('columns');
      for (let i = 0; i < columns.length; i++) {
        if (!columns[i].disabled && (!columns[i].name || columns[i].error || !columns[i].name.match(ChorusIdentifierLower64()))) {
          readyToUpload = false;
        }
        if (!columns[i].disabled) {
          hasActiveColumns = true;
        }
      }
      if (!hasActiveColumns) {
        readyToUpload = false;
      }
    }
    this.set('ready_to_upload', readyToUpload);
  },

  prepare(content, tdfSourceFileId, tdfSourceFile) {
    let delimiter = this.get('delimiter');
    let delimiterChange = false;
    let customDelimiter = this.get('custom_delimiter');
    let inputHasHeader = this.get('input_has_header');
    let textQualifier = this.get('text_qualifier');
    let nullQualifier = this.get('null_qualifier');
    let encoding = this.get('encoding');
    if (content.Format) {
      if (delimiter !== content.Format.Delimiter) {
        delimiterChange = true;
      }
      delimiter = content.Format.Delimiter;
      if (!(/[,:\s]/.test(delimiter))) {
        customDelimiter = content.Format.Delimiter;
      }
      inputHasHeader = content.Format.Header.toLowerCase() === 'true';
      textQualifier = content.Format.TextQualifier;
      nullQualifier = content.Format.NullQualifier;
      if (/^ISO/i.test(content.Format.Encoding)) {
        encoding = 'ISO-8859-1';
      } else if (/^Win/i.test(content.Format.Encoding)) {
        encoding = 'Windows-1252';
      }
      this.set({
        delimiter,
        custom_delimiter: customDelimiter,
        text_qualifier: textQualifier,
        null_qualifier: nullQualifier,
        encoding,
      }, { silent: true });
      if (delimiterChange) {
        this.getPreviewData();
        setTimeout(() => this.prepare(content, tdfSourceFileId, tdfSourceFile), 1000);
        return;
      }
    }
    const columns = this.get('columns').slice();
    if (content.Columns) {
      content.Columns.Column.forEach((column, i) => {
        if (i in columns) {
          let columnName = column.Name.toLowerCase();
          let columnType = column.Type.toLowerCase();
          if (columnType === 'numeric') columnType = 'decimal';
          else if (columnType === 'timestamp without time zone') columnType = 'timestamp';
          else if (columnType === 'timestamp with time zone') columnType = 'timestamptz';
          else if (columnType === 'time without time zone') columnType = 'time';
          else if (columnType === 'time with time zone') columnType = 'timetz';
          columns[i].name = columnName;
          columns[i].disabled = false;
          columns[i].primary = !!column.Primary;
          columns[i].type = columnType;
          columns[i].description = column.Description;
          columns[i].label = column.Label;
        }
      });
    }
    for (let i = 0; i < columns.length; i++) {
      columns[i].error = this.hasDuplicateColumnName(i, columns[i].name, columns);
      columns[i].typeError = false;
      if (!columns[i].validTypes.includes(columns[i].type)) {
        columns[i].typeError = true;
      }
    }
    const inputHasHeaderChange = inputHasHeader !== this.get('input_has_header');
    this.set({
      loaded: true,
      tdf_source_file: tdfSourceFile,
      tdf_source_file_id: tdfSourceFileId,
      data_table_name: content.TableName,
      columns,
      title: content.Title,
      url: content.Url,
      description: content.Description,
    }, { silent: true });
    if (inputHasHeaderChange) {
      this.updateInputHasHeader(inputHasHeader);
    } else {
      this.updateOriginalColumns(columns);
      this.setReadyToUpload();
      this.trigger('change:previewData');
    }
  },

  updateDataTableName(value) { this.set('data_table_name', value); this.setReadyToUpload(); },
  updateDelimiter(value) {
    this.set({
      delimiter: value,
      tdf_source_file: null,
      tdf_source_file_id: null,
    });
    this.trigger('change:previewData');
  },
  updateCustomDelimiter(value) { this.set('custom_delimiter', value); this.trigger('change:previewData'); },
  updateInputHasHeader(value) {
    this.set('input_has_header', value);
    const columns = this.get('columns').slice();
    if (value) {
      const originalColumns = this.get('original_columns');
      if (columns.length === originalColumns.length) {
        for (let i = 0; i < columns.length; i++) {
          columns[i] = Object.assign(columns[i], { name: originalColumns[i] });
          columns[i].error = this.hasDuplicateColumnName(i, originalColumns[i], columns);
        }
      } else {
        this.updateOriginalColumns(columns);
      }
    } else {
      for (let i = 0; i < columns.length; i++) {
        columns[i] = Object.assign(columns[i], { name: `column_${(i + 1)}` });
      }
    }
    this.set('columns', columns);
    this.setReadyToUpload();
    this.trigger('change:previewData');
  },
  updateTextQualifier(value) { this.set('text_qualifier', value); this.trigger('change:previewData'); },
  updateNullQualifier(value) { this.set('null_qualifier', value); },
  updateEncoding(value) { this.set('encoding', value); },
  updateAddKeyColumn(value) {
    this.set('add_key_column', value);
    const columns = this.get('columns').slice();
    for (let i = 0; i < columns.length; i++) {
      columns[i].error = this.hasDuplicateColumnName(i, columns[i].name, columns);
    }
    this.set('columns', columns);
  },
  updateKeyColumnIsPrimary() {
    this.set('add_key_column', true);
    const columns = this.get('columns').slice();
    for (let i = 0; i < columns.length; i++) {
      columns[i] = Object.assign(columns[i], { primary: false });
      columns[i].error = this.hasDuplicateColumnName(i, columns[i].name, columns);
    }
    this.set('columns', columns);
    this.set('key_column_is_primary', true);
  },
  updatePrimary(target) {
    this.set('add_key_column', false);
    const columns = this.get('columns').slice();
    for (let i = 0; i < columns.length; i++) {
      columns[i] = Object.assign(columns[i], { primary: false });
      columns[i].error = this.hasDuplicateColumnName(i, columns[i].name, columns);
    }
    if (columns[target]) {
      columns[target] = Object.assign(columns[target], { disabled: false, primary: true });
      this.set('key_column_is_primary', false);
    }
    this.set('columns', columns);
    this.trigger('change:columns');
  },
  updateColumnDisabled(target, value) {
    const columns = this.get('columns').slice();
    const column = +target.replace(/[^0-9]/g, '');
    if (columns[column]) {
      columns[column] = Object.assign(columns[column], { disabled: value });
      if (!value && columns[column].primary) {
        this.set('add_key_column', true);
        this.set('key_column_is_primary', true);
        columns[column] = Object.assign(columns[column], { primary: false });
      }
    }
    for (let i = 0; i < columns.length; i++) {
      columns[i].error = this.hasDuplicateColumnName(i, columns[i].name, columns);
    }
    this.set('columns', columns);
    this.trigger('change:columns');
  },
  updateColumnName(target, value) {
    const columns = this.get('columns').slice();
    const column = +target.replace(/[^0-9]/g, '');
    if (columns[column]) {
      columns[column] = Object.assign(columns[column], { name: value.toLowerCase() });
    }
    for (let i = 0; i < columns.length; i++) {
      columns[i].error = this.hasDuplicateColumnName(i, columns[i].name, columns);
    }
    this.set('columns', columns);
    this.setReadyToUpload();
    this.trigger('change:columns');
  },
  updateColumnType(target, value) {
    const columns = this.get('columns').slice();
    const column = +target.replace(/[^0-9]/g, '');
    if (columns[column]) {
      columns[column] = Object.assign(columns[column], { type: value });
    }
    columns[column].typeError = false;
    if (!columns[column].validTypes.includes(value)) {
      columns[column].typeError = true;
    }
    this.set('columns', columns);
    this.trigger('change:columns');
  },
  updateOriginalColumns(columns) {
    const originalColumns = [];
    for (let i = 0; i < columns.length; i++) {
      originalColumns.push(columns[i].name);
    }

    this.set('original_columns', originalColumns, { silent: true });
  },
  updateParserError(error = null) {
    if (error) {
      this.set({ parser_error_exists: true });
    } else {
      this.set({ parser_error_exists: false });
    }
    this.setReadyToUpload();
  },
  getPreviewData() {
    const csvParser = this.getCsvParser();

    if (csvParser && csvParser.length > 0) {
      const columns = getColumnOrientedData(
        csvParser,
        {
          hasHeader: this.get('input_has_header'),
          columnNameOverrides: this.get('columnNameOverrides'),
        },
      );
      if (columns.length !== this.get('original_columns').length) {
        this.updateOriginalColumns(columns);
      }

      const updatedColumns = this.get('columns');
      for (let i = 0; i < columns.length; i++) {
        if (updatedColumns.length === columns.length && updatedColumns[i]) {
          columns[i] = updatedColumns[i];
        } else {
          columns[i].id = i;
          columns[i].name = columns[i].name.toLowerCase();
          columns[i].disabled = false;
          columns[i].primary = false;
          columns[i].unique = this.hasUniqueData(columns[i].values);
          columns[i].error = this.hasDuplicateColumnName(i, columns[i].name, columns);
          columns[i].typeError = false;
          delete columns[i].values;
        }
      }
      this.set('columns', columns, { silent: true });

      // limit rows to 10 for preview
      let rows = csvParser;
      if (csvParser.length > 10) {
        rows = csvParser.slice(0, 10);
      }

      return {
        rows,
        columns,
      };
    }
    this.setReadyToUpload();
    return null;
  },

  getDelimiter() {
    return this.get('custom_delimiter') ? this.get('custom_delimiter') : this.get('delimiter');
  },

  getCsvParser() {
    const previewContent = this.get('preview_content');
    const csvParseOpts = {
      delimiter: this.getDelimiter(),
      hasHeader: this.get('input_has_header'),
      types: [],
      quote: this.get('text_qualifier'),
      escape: this.get('text_qualifier'),
    };

    try {
      return parse(previewContent, csvParseOpts);
    } catch (e) {
      chorus.toast('dataset.import.invalid', {
        reason: e,
        toastOpts: { theme: 'bad_activity' },
      });
      return this.parseAsLinePerRow();
    }
  },

  parseAsLinePerRow() {
    const previewContent = this.get('preview_content');
    const lines = previewContent.split('\n');
    const data = [];
    for (let i = 0; i < lines.length; i++) {
      data.push([lines[i]]);
    }
    return data;
  },

  hasDuplicateColumnName(position, name, columns) {
    if (this.get('add_key_column') && name === 'id') {
      return true;
    }
    if (columns[position] && columns[position].disabled) {
      return false;
    }
    for (let i = 0; i < columns.length; i++) {
      if (position !== i && columns[i].name === name && !columns[i].disabled) {
        return true;
      }
    }
    return false;
  },

  hasUniqueData(array) {
    if (array.includes('')) {
      return false;
    }
    return (new Set(array)).size === array.length;
  },

  getFileSize(workfile) {
    if (workfile.isCsv()) {
      return workfile.get('versionInfo').fileSize;
    }
    return 0;
  },

  formatNumberAsLocale(n) { return n.toLocaleString(navigator.locale); },

  getNumberOfRows(workfile) {
    if (workfile.isCsv()) {
      const previewContent = this.get('preview_content');
      if (previewContent.length === 0) { return '0'; }
      const parser = this.getCsvParser();

      if (parser) {
        let totalRows = parser.length;
        const fileSize = this.getFileSize(workfile);

        if (fileSize > 10240) {
          totalRows = Math.floor(totalRows * (fileSize / 10240));
        }
        // toggling hasHeader should show a change in the numRows even for large files
        if (this.get('input_has_header')) { totalRows -= 1; }

        return this.formatNumberAsLocale(totalRows);
      }
    }
    return '0';
  },

  getFirstRow(workfile) {
    if (workfile.isCsv()) {
      const previewData = this.getPreviewData();
      if (previewData && Object.values(previewData).length > 0) {
        return previewData.columns.map(column => column.name);
      }
    }
    return [];
  },

  getNumberOfColumns(workfile) {
    if (workfile.isCsv()) {
      const numColumns = this.getFirstRow(workfile).length;
      return this.formatNumberAsLocale(numColumns + 1);
    }
    return '0';
  },

  getSizeAsString(workfile) {
    const size = this.getFileSize(workfile);
    return sizeAsString(size);
  },
});

export default DatasetUpload;
