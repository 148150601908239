import _ from '../../underscore';
import $ from '../../jquery';
import SearchResultCommentList from './search_result_comment_list';
import View from '../loading_view';
import TagsContext from '../../mixins/tags_context';

export default View.include(TagsContext).extend({
  tagName: 'div',
  additionalClass: 'result_item',

  events: {
    'click a.show_more_comments': 'showMoreComments',
    'click a.show_fewer_comments': 'showFewerComments',
  },

  additionalContext() {
    return this.additionalContextForTags();
  },

  postRender() {
    const commentsView = this.makeCommentList();
    this.$('.comments_container').append(commentsView.render().el);
    $(this.el).attr('data-id', this.model.get('id'));
    $(this.el).attr('data-type', this.model.get('entityType'));
  },

  makeCommentList() {
    return new SearchResultCommentList({
      comments: this.getComments(),
      columns: this.getColumns(),
      columnDescriptions: this.getColumnDescriptions(),
      tableDescription: this.getTableDescription(),
    });
  },

  getTableDescription() {
    const items = this.model.get('tableDescription') || [];
    _.each(items, (item) => {
      const description = item;
      description.subType = 'table_description';
    });

    return items;
  },

  getColumns() {
    const items = this.model.get('columns') || [];
    _.each(items, (item) => {
      const column = item;
      column.subType = 'column';
    });

    return items;
  },

  getColumnDescriptions() {
    const items = this.model.get('columnDescriptions') || [];
    _.each(items, (item) => {
      const description = item;
      description.subType = 'column_description';
    });

    return items;
  },

  getComments() {
    return this.model.get('comments') || [];
  },

  showMoreComments(evt) {
    evt && evt.preventDefault();
    this.$('.has_more_comments').addClass('hidden');
    this.$('.more_comments').removeClass('hidden');
  },

  showFewerComments(evt) {
    evt && evt.preventDefault();
    this.$('.has_more_comments').removeClass('hidden');
    this.$('.more_comments').addClass('hidden');
  },
});
