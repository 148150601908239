import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import VisualizationError from '../../alerts/visualization_error_alert';
import Visualization from '../../dialogs/visualizations/visualization_dialog';
import View from '../loading_view';

export default View.extend({
  additionalClass: 'chart_configuration',

  events: {
    'click button.create': 'launchVisualizationDialog',
    'click button.cancel': 'cancelVisualization',
  },

  setup() {
    const alphaSort = function sortOn(column) {
      return column.get('name') && column.get('name').toLowerCase();
    };
    this.columns = _.sortBy(this.collection.models, alphaSort);

    function filterColumns(types, columns) {
      return _.filter(columns, (col) => {
        const category = col.get('typeCategory');
        return _.include(types, category);
      });
    }

    this.numericalColumns = filterColumns(['WHOLE_NUMBER', 'REAL_NUMBER'], this.columns);
    this.datetimeColumns = filterColumns(['DATE', 'TIME', 'DATETIME'], this.columns);

    this.subscribePageEvent('cancel:visualization', this.cancelVisualization);
  },

  additionalContext() {
    const ctx = {};
    ctx.columnGroups = _.map(this.columnGroups, function mapColumn(group) {
      return {
        columnNames: this.columnNamesOfType(group.type),
        groupName: group.name,
        hint: this.hintTextForColumnGroup(group),
        label: t(`dataset.visualization.sidebar.${group.name}`),
        noColumnMessage: t(`dataset.visualization.sidebar.no_columns.${group.type}`),
        secondaryOption: group.options,
      };
    }, this);

    ctx.noColumnsAvailable = _.any(ctx.columnGroups, group => _.isEmpty(group.columnNames));

    return ctx;
  },

  hintTextForColumnGroup(group) {
    if (group.name === 'category') {
      return t('dataset.visualization.sidebar.hint.category');
    }
    return t(`dataset.visualization.sidebar.hint.${group.type}`);
  },

  columnNamesOfType(type) {
    switch (type) {
      case 'numeric':
        return _.map(this.numericalColumns, col => col.get('name'));
      case 'time':
        return _.map(this.datetimeColumns, col => col.get('name'));
      default:
        return _.map(this.columns, col => col.get('name'));
    }
  },

  teardown(...args) {
    this.clearSqlErrors();
    this._super('teardown', args);
  },

  postRender() {
    chorus.styleSelect(this.$('select'));

    const $a = this.$('.limiter a');
    const $el = $(this.el);
    $.each($a, _.bind(function buildMenu(index, link) {
      const $link = $(link);
      this.menu($link, {
        content: $link.parent().find('.limiter_menu_container').html(),
        container: $el,
        contentEvents: {
          li: this.limiterSelected,
        },
      });
    }, this));

    this._super('postRender');
  },

  limiterSelected(e, api) {
    api.elements.target.find('.selected_value').text($(e.target).text());
  },

  launchVisualizationDialog(e) {
    e && e.preventDefault && e.preventDefault();
    this.clearSqlErrors();
    this.startVisualization();

    const func = `make${_.capitalize(this.chartOptions().type)}Task`;
    this.task = this.model[func](this.chartOptions());
    this.task.set({ filters: this.filters && this.filters.sqlStrings() });

    this.dialog = new Visualization({
      task: this.task,
      model: this.model,
      chartOptions: this.chartOptions(),
      filters: this.filters,
      columnSet: this.collection,
    });

    this.task.bindOnce('saved', this.createVisualization, this);
    this.task.bindOnce('saveFailed', this.showSqlErrors, this);

    this.task.bindOnce('saved', this.cleanupVisualization, this);
    this.task.bindOnce('saveFailed', this.cleanupVisualization, this);

    this.task.save();
  },

  cancelVisualization(e) {
    e && e.preventDefault && e.preventDefault();
    if (this.task) {
      this.task.unbind('saveFailed');
      this.task.cancel();
      this.cleanupVisualization();
    }
  },

  createVisualization() {
    this.dialog.launchModal();
    this.dialog.drawChart();
    this.task.unbind('saveFailed');
  },

  startVisualization() {
    this.$('button.create').startLoading('visualization.creating');
    this.$('button.cancel').removeClass('hidden');
  },

  cleanupVisualization() {
    this.$('button.cancel').addClass('hidden');
    this.$('button.create').stopLoading();
    delete this.task;
  },

  showSqlErrors() {
    this.options.errorContainer.showErrorWithDetailsLink(this.task, VisualizationError);
  },

  clearSqlErrors() {
    this.options.errorContainer && this.options.errorContainer.closeErrorWithDetailsLink();
  },
});
