import React from 'react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';
import ErrorMessage from './ErrorMessage';

function ExpectedOutcome({
  numColumns, numRows, fileSize, parserError, parserErrorExists, minimumDataEntryMet,
}) {
  return (
    <>
      <h2>
        {t('dataset.import.expected_outcome_title')}
        :
      </h2>
      {parserErrorExists ? (
        <ErrorMessage error={parserError} />
      ) : (
        <p>{t('dataset.import.expected_outcome', { numColumns, numRows, fileSize })}</p>
      )}
      {fileSize > 0 && !minimumDataEntryMet() && !parserErrorExists
        && <p className="alert">{t('dataset.import.generic_warning')}</p>}
    </>
  );
}

const mapModelsToProps = ({ workfile, datasetUpload }) => {
  const fileSize = datasetUpload.getSizeAsString(workfile);
  try {
    datasetUpload.updateParserError();
    return {
      numRows: datasetUpload.getNumberOfRows(workfile),
      numColumns: datasetUpload.getNumberOfColumns(workfile),
      fileSize,
      parserErrorExists: false,
    };
  } catch (e) {
    // let container know there is an error in order to disable confirm button
    datasetUpload.updateParserError(e);
    return {
      parserError: e,
      parserErrorExists: true,
    };
  }
};

const options = {
  debounce: true,
  events: {
    workfile: ['change:content'],
    datasetUpload: ['change:delimiter', 'change:input_has_header'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(ExpectedOutcome);
