import { toast, Slide } from 'react-toastify';

const toastOptions = (id) => ({
  transition: Slide,
  hideProgressBar: true,
  position: 'top-right',
  draggable: false,
  autoClose: true,
  closeOnClick: true,
  closeButton: true,
  toastId: id,
});

const toastMessage = (type, content, id) => {
  switch (type) {
    case 'success':
      return toast.success(content, toastOptions(id));
    case 'error':
      return toast.error(content, toastOptions(id));
    case 'warn':
      return toast.warn(content, toastOptions(id));
    case 'info':
      return toast.info(content, toastOptions(id));
    default:
      return toast(content, toastOptions(id));
  }
};

export default toastMessage;
