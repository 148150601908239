import Sidebar from '../core/sidebar_view';
import RenameTag from '../../dialogs/tags/rename_tag_dialog';
import TagDelete from '../../alerts/tag_delete_alert';

export default Sidebar.extend({
  constructorName: 'TagListSidebar',
  templateName: 'tag_list_sidebar',

  events: {
    'click .delete_tag_link': 'deleteSelectedTag',
    'click .rename_tag_link': 'renameSelectedTag',
  },

  setup() {
    this.listenTo(this.options.collection, 'tag:selected', function onSelect(tag) {
      this.setTag(tag);
    });
    this.listenTo(this.options.collection, 'tag:deselected', function onDeSelect() {
      this.setTag(null);
    });
  },

  setTag(tag) {
    this.tag = tag;
    this.render();
  },

  additionalContext() {
    return {
      hasTag: this.tag !== null,
      name: this.tag && this.tag.get('name'),
    };
  },

  deleteSelectedTag(e) {
    e.preventDefault();
    new TagDelete({ model: this.tag }).launchModal();
  },

  renameSelectedTag(e) {
    e.preventDefault();
    new RenameTag({ model: this.tag }).launchModal();
  },
});
