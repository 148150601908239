import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import WorkfileContentDetails from './workfile_content_details_view';
import { workspace } from '../../utilities/workspace_assoc';
import { FEATURE } from '../../../utilities/features';

export default WorkfileContentDetails.extend({
  templateName: 'r_workfile_content_details',
  constructorName: 'RWorkContentDetailsView',

  setup(...args) {
    this._super('setup', args);
    this.listenTo(this.model, 'file:selectionPresent', _.bind(this.onSelectedText, this));
    this.listenTo(this.model, 'file:selectionEmpty', _.bind(this.onNoSelectedText, this));
    this.contentView = this.options.contentView;
  },

  postRender() {
    this._super('postRender');

    // cannot currently run R blobs in Azure
    if (this.model.canRun()) {
      this.menu(this.$('.run_file'), {
        classes: 'tooltip-modal', // FIXME needs to be kept in sync with style defined line 20 of menu.js
        content: this.$('.run_workfile'),
        orientation: 'right',
        qtipArgs: {
          events: {
            show: _.bind(function enableOtions() {
              $('.run_workfile .run_selection')
                .toggleClass('disabled', !this.enableRunSelection());
            }, this),
          },
          style: {
            tip: false,
            classes: 'rsh-qtip-corner',
          },
          position: {
            my: 'top left',
            at: 'bottom left',
          },
        },
        contentEvents: {
          'a.run_default': this.runContent,
          'a.run_selection': this.runSelectedContent,
        },
      });
    } else {
      this.$('.run_file').addClass('hidden');
    }

    // Turn off run selected for new ux - not possible without integration
    if (FEATURE.NEW_UX) {
      this.$('.run_menu li:nth-child(2)').addClass('hidden');
    }

    if (!workspace(this.model).canUpdate()) {
      this.$('.run_file').attr('disabled', 'disabled');
      this.$('.save button').attr('disabled', 'disabled');
    }
  },

  enableRunSelection() {
    return !!(this.contentView.getSelectedText());
  },

  onSelectedText() {
    this.$('.run_file .run_description').text(t('workfile.content_details.run_selected'));
  },

  onNoSelectedText() {
    this.$('.run_file .run_description').text(t('workfile.content_details.run_file'));
  },

  runContent() {
    this.contentView.runInDefault();
  },

  runSelectedContent() {
    if (this.enableRunSelection()) {
      this.contentView.runSelected();
    }
  },
});

