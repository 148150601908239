import { useState } from 'react';
import Backbone from 'backbone';
import ContainerisedAppsShowView from '../../../../../application/views/containerised_apps/containerised_apps_view';
import ReactToBackboneWrapper from '../../ReactToBackboneWrapper';

const AppsPanel = ({ tab, workspace }) => {
  const { component, id } = tab;
  const [view] = useState(
    () => new ContainerisedAppsShowView({
      model: workspace,
      tabModel: new Backbone.Model({ active: true }),
      defaultApp: null,
    }),
  );

  return (
    <ReactToBackboneWrapper
      view={view}
      tabId={id}
      component={component}
    />
  );
};

export default AppsPanel;
