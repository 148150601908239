import { Tab } from 'semantic-ui-react';
import { usePermissions } from '../../../../permissions';
import { actions, instances } from '../../../../permissions/constants';
import NotAuthorised from '../../../../components/errors/NotAuthorised';

const ShinyApps = () => {
  if (!usePermissions(actions.READ, instances.SHINY_APPS)) {
    return (<NotAuthorised />);
  }

  return (
    <Tab.Pane data-testid="shiny-apps">
      Shiny apps!
    </Tab.Pane>
  );
};

export default ShinyApps;
