import React from 'react';
import { Select } from 'semantic-ui-react';
import { t } from 'i18n-js';

const datatypeOptions = [
  { key: 'text', value: 'text', text: t('rsh.upload_data.describe_data.text') },
  { key: 'integer', value: 'integer', text: t('rsh.upload_data.describe_data.integer') },
  { key: 'decimal', value: 'decimal', text: t('rsh.upload_data.describe_data.decimal') },
  { key: 'boolean', value: 'boolean', text: t('rsh.upload_data.describe_data.boolean') },
  { key: 'date', value: 'date', text: t('rsh.upload_data.describe_data.date') },
  { key: 'timestamp', value: 'timestamp', text: t('rsh.upload_data.describe_data.datetime') },
  { key: 'timestamptz', value: 'timestamptz', text: t('rsh.upload_data.describe_data.datetimezone') },
  { key: 'time', value: 'time', text: t('rsh.upload_data.describe_data.time') },
  { key: 'timetz', value: 'timetz', text: t('rsh.upload_data.describe_data.timezone') },
];

const DatatypeDropdown = ({
  name, datatype, handleColumnTypeChange, disabled, error,
}) => (
  <Select
    id={name}
    name={name}
    defaultValue={datatype}
    options={[
      { key: 'text', value: 'text', text: t('rsh.upload_data.describe_data.text') },
      { key: 'integer', value: 'integer', text: t('rsh.upload_data.describe_data.integer') },
      { key: 'decimal', value: 'decimal', text: t('rsh.upload_data.describe_data.decimal') },
      { key: 'boolean', value: 'boolean', text: t('rsh.upload_data.describe_data.boolean') },
      { key: 'date', value: 'date', text: t('rsh.upload_data.describe_data.date') },
      { key: 'timestamp', value: 'timestamp', text: t('rsh.upload_data.describe_data.datetime') },
      { key: 'timestamptz', value: 'timestamptz', text: t('rsh.upload_data.describe_data.datetimezone') },
      { key: 'time', value: 'time', text: t('rsh.upload_data.describe_data.time') },
      { key: 'timetz', value: 'timetz', text: t('rsh.upload_data.describe_data.timezone') },
    ]}
    className="small-size"
    onChange={handleColumnTypeChange}
    disabled={disabled}
    upward={false}
    error={error}
  />
);

export default DatatypeDropdown;
