import { useState } from 'react';
import { Modal, Header, Icon } from 'semantic-ui-react';
import { t } from 'i18n-js';
import ModalActionButtons from '../../../../../components/buttons/ModalActionButtons';
import HeaderError from '../../../../../components/errors/HeaderError';
import ModalWrapper from '../../../../../components/modals/ModalWrapper';
import { useRename } from '../../../service';
import { useFileContext } from '../../Context';
import toastMessage from '../../../../../utilities/toast';
import NewFolderSubmodal, { NewFolderSubmodalButton }
  from '../../../../../components/modals/NewFolderSubmodal';
import '../../../../../components/errors/errors.less';
import FileNavigation from '../../../../../components/navigation/FileNavigation';

const MoveModal = ({ setModalClose, setIsBusy }) => {
  const {
    folder, name, id, type,
  } = useFileContext();
  const [destinationFolder, setDestinationFolder] = useState(folder.split('/')[0]);

  const headerContent = type === 'folder'
    ? t('file_list.actions.move_folder_with_name', { folderName: name })
    : t('file_list.actions.move_with_name', { fileName: name });
  const isValidEntry = !!destinationFolder;

  const {
    renameFile,
    isLoading,
    isError,
    error,
    resetResponse,
  } = useRename(id);

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleSubmitting = () => {
    setIsBusy(true);

    const fileData = {
      workfile: {
        id,
        file_name: name,
        folder: destinationFolder,
      },
    };

    renameFile(fileData, {
      onSuccess: () => {
        setIsBusy(false);
        handleModalClose();
        toastMessage('success', t('file_list.actions.move_success', { fileName: name, destinationFolder }), 'moveSuccess');
      },
      onError: () => {
        setIsBusy(false);
      },
    });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="arrow circle right" title={t('file_list.actions.move')} />
        <Header.Content content={headerContent} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <FileNavigation
          currentFolder={destinationFolder}
          handleSetFolder={(newFolder) => setDestinationFolder(newFolder)}
        />
      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmitting}
        isValidEntry={isValidEntry}
        error={isError}
        submitText={t('file_list.actions.move')}
      >
        <ModalWrapper
          trigger={NewFolderSubmodalButton}
        >
          <NewFolderSubmodal
            destinationFolder={destinationFolder}
            handleSetFolder={(newFolder) => setDestinationFolder(newFolder)}
          />
        </ModalWrapper>
      </ModalActionButtons>
    </>
  );
};

export default MoveModal;
