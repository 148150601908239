import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import WorkfileContentDetails from './workfile_content_details_view';
import { workspace, sandbox } from '../../utilities/workspace_assoc';

export default WorkfileContentDetails.extend({
  templateName: 'sql_workfile_content_details',
  constructorName: 'SqlWorkContentDetailsView',

  setup(...args) {
    this._super('setup', args);
    this.listenTo(this.model, 'file:selectionPresent', _.bind(this.onSelectedText, this));
    this.listenTo(this.model, 'file:selectionEmpty', _.bind(this.onNoSelectedText, this));
    this.contentView = this.options.contentView;
  },

  events: {
    'click a.change_workfile_schema': 'changeWorkfileSchema',
  },

  postRender() {
    this._super('postRender');
    const menuOptions = '.run_workfile .run_selection, .run_workfile .run_selection_and_download';

    if (this.model.canRun()) {
      this.menu(this.$('.run_file'), {
        classes: 'tooltip-modal', // FIXME needs to be kept in sync with style defined line 20 of menu.js
        content: this.$('.run_workfile'),
        orientation: 'right',
        qtipArgs: {
          events: {
            show: _.bind(function enableOptions() {
              $(menuOptions).toggleClass('disabled', !this.enableRunSelection());
            }, this),
          },
          style: {
            tip: false,
            classes: 'rsh-qtip-corner',
          },
          position: {
            my: 'top left',
            at: 'bottom left',
          },
        },
        contentEvents: {
          '.run_default': this.runInExecutionSchema,
          'a.run_selection': this.runSelectedInExecutionSchema,
        },
      });
  } else {
    this.$('.run_file').addClass('hidden');
  }

    if (!workspace(this.model).canUpdate()) {
      this.$('.run_file').attr('disabled', 'disabled');
      this.$('.save button').attr('disabled', 'disabled');
      this.$('.change_workfile_schema').remove();
    }

    if (!this.hasValidExecutionSchema()) {
      this.fileMenu.disableItem('newChorusView');
      this.fileMenu.disableItem('newSelectionChorusView');
    }

    this.fileMenu.disableItem('newSelectionChorusView');
  },

  fileMenuItems(...args) {
    const items = this._super('fileMenuItems', args);

    // If the editor is SQL mode...
    items.push({
      data: 'newChorusView',
      text: t('workfile.content_details.save_as_chorus_view'),
    });
    items.push({
      data: 'newSelectionChorusView',
      text: t('workfile.content_details.save_selection_as_chorus_view'),
    });

    return items;
  },

  enableRunSelection() {
    return !!(this.contentView.getSelectedText() && this.hasValidExecutionSchema());
  },

  hasValidExecutionSchema() {
    return !!(this.model.executionSchema());
  },

  additionalContext() {
    const ctx = this._super('additionalContext');

    const executionSchema = this.model.executionSchema();
    const sandboxSchema = sandbox(this.model) && sandbox(this.model).schema();
    return _.extend(ctx, {
      schemaName: executionSchema && executionSchema.canonicalName(),
      executionSchemaIsSandbox: (executionSchema && sandboxSchema && executionSchema.isEqualToSchema(sandboxSchema)),
    });
  },

  onSelectedText() {
    this.$('.run_file .run_description').text(t('workfile.content_details.run_selected'));

    if (this.hasValidExecutionSchema()) {
      this.fileMenu.enableItem('newSelectionChorusView');
    }
  },

  onNoSelectedText() {
    this.$('.run_file .run_description').text(t('workfile.content_details.run_file'));
    this.fileMenu.disableItem('newSelectionChorusView');
  },

  newChorusView() {
    this.contentView.newChorusView();
  },

  newSelectionChorusView() {
    this.contentView.newSelectionChorusView();
  },

  runInExecutionSchema() {
    this.contentView.runInDefault();
  },

  runSelectedInExecutionSchema() {
    if (this.enableRunSelection()) {
      this.contentView.runSelected();
    }
  },
});

