import { useCallback } from 'react';
import { workspaceId } from '../layout/helper';

export const initialState = () => ({ reason: '', workspace_id: workspaceId() });

// eslint-disable-next-line no-param-reassign
const updateState = ([element, { value }], state) => { state[element.target.name] = value; };

const validate = ({ reason }) => reason.length > 0;

export const useOnChange = (mutationParameters, setMutationEnabled) => (
  useCallback((...event) => {
    updateState(event, mutationParameters.current);
    const complete = validate(mutationParameters.current);
    setMutationEnabled(complete);
  }, [mutationParameters, setMutationEnabled])
);
