import chorus from '../chorus';
import _ from '../underscore';
import $ from '../jquery';
import Backbone from '../vendor/backbone';
import Handlebars from '../vendor/handlebars';
import TaggingsUpdater from '../models/taggings_updater';
import Urls from '../mixins/urls';
import Events from '../mixins/events';
import ServerErrors from '../mixins/server_errors';
import Fetching from '../mixins/fetching';

const Collection = Backbone.Collection.include(
  Urls,
  Events,
  Fetching,
  ServerErrors,
).extend({
  constructorName: 'Collection',

  initialize(models, options, ...args) {
    this.attributes = options || {};
    this.setup([models, options, ...args]);
  },

  clone() {
    const clone = this._super('clone');
    clone.attributes = _.clone(this.attributes);
    return clone;
  },

  canDestroy() {
    return this.models.every((m) => m.canDestroy());
  },

  setup: $.noop,

  url(options) {
    let opts = options;
    opts = _.extend({
      per_page: this.per_page !== undefined ? this.per_page : 50,
      page: 1,
    }, opts);
    const template = _.isFunction(this.urlTemplate) ? this.urlTemplate(opts) : this.urlTemplate;
    const compiledTemplateWith = Handlebars.compile(template, { noEscape: true });

    const uri = new window.URI(`/${compiledTemplateWith(this.attributes)}`);

    if (this.urlParams) {
      const params = _.isFunction(this.urlParams) ? this.urlParams(opts) : this.urlParams;
      uri.addSearch(this.underscoreKeys(params));
    }

    uri.addSearch({
      page: opts.page,
      per_page: opts.per_page,
    });

    if (opts.order) {
      uri.addSearch({
        order: _.underscored(opts.order),
      });
    }

    if (this.order) {
      uri.addSearch({
        order: _.underscored(this.order),
      });
    }

    this.lastUrlUsed = uri.toString();
    return this.lastUrlUsed;
  },

  isDeleted() {
    return false;
  },

  shouldTriggerImmediately(eventName) {
    if (eventName === 'loaded') {
      return this.loaded;
    }

    return false;
  },

  destroy() {
    this.invoke('destroy');
  },

  fetchPage(page, options, ...args) {
    let opts = options;
    if (opts && opts.per_page !== undefined) {
      this.per_page = opts.per_page;
      delete opts.per_page;
    }
    const url = this.url({ page });
    opts = _.extend({}, opts, { url });
    const success = opts.success || $.noop;
    opts.success = _.bind(function onPaginate() {
      this.trigger('paginate');
      success([page, options, ...args]);
    }, this);
    this.fetch(opts);
  },


  fetchAll: (function onFetchAll(callBack) {
    const fetchPage = function onFetch(page) {
      this.fetch({
        url: this.url({ page, per_page: 2000 }),
        silent: true,
        reset: page === 1,
        remove: false,
        success(collection, data) {
          if (callBack) {
            callBack();
          }

          const items = collection;
          const total = data.pagination ? parseInt(data.pagination.total, 10) : 1;
          const current = data.pagination ? parseInt(data.pagination.page, 10) : 1;
          if (current >= total) {
            items.trigger('reset', items);
            items.trigger('loaded');
            items.trigger('serverResponded');
          } else {
            items.loaded = false;
            fetchPage.call(items, current + 1);
          }
        },
        error(collection) {
          collection.trigger('reset', collection);
          collection.trigger('loaded');
          collection.trigger('serverResponded');
        },
      });
    };

    return function onSuccess() {
      fetchPage.call(this, 1, callBack);
    };
  }()),

  refresh(forceIfModified = true) {
    if (this.lastUrlUsed) {
      this.fetch({
        url: this.lastUrlUsed,
        refresh: true,
        silent: true,
        ifModified: forceIfModified,
        success(collection) {
          collection.trigger('refresh');
        },
      });
    }
  },

  totalRecordCount() {
    return (this.pagination && this.pagination.records) || this.models.length;
  },

  sortDesc(idx) {
    // Not used. We only do ascending sort for now.
    this._sort(idx, 'desc');
  },

  sortAsc(idx) {
    // We only support ascending sort at the moment.
    this._sort(idx, 'asc');
  },

  updateTags(options) {
    if (!this.taggingsUpdater) {
      this.taggingsUpdater = new TaggingsUpdater({
        collection: this,
      });

      this.listenTo(this.taggingsUpdater, 'updateFailed', function onError(modelWithServerErrors) {
        this.trigger('updateTagsFailed', modelWithServerErrors);
        this.each((model) => {
          model.tags().fetchAll();
        });
      });
    }

    this.taggingsUpdater.updateTags(options);
  },

  remove(models, options) {
    const items = _.isArray(models) ? models.slice() : [models];
    const opts = options || {};
    const removed = [];

    for (let i = 0; i < items.length; i += 1) {
      const model = this.get(items[i]);
      if (model) {
        delete this._byId[model.id];
        delete this._byId[model.cid];
        const index = this.indexOf(model);
        this.models.splice(index, 1);
        this.length -= 1;
        if (this.pagination) this.pagination.records -= 1;
        if (!opts.silent) {
          opts.index = index;
          model.trigger('remove', model, this, opts);
        }
        removed.push(model);
        this._removeReference(model);
      }
    }
    if (!opts.silent && removed.length) {
      opts.changes = { added: [], merged: [], removed };
      this.trigger('update', this, opts);
    }
    return this;
  },

  reset(models, options) {
    if (options && options.pagination) {
      this.pagination = _.clone(options.pagination);
    } else if (this.pagination) {
      this.pagination.records = (models || []).length;
    }
    return this._super('reset', [models || [], options]);
  },

  _prepareModel(...args) {
    const model = this._super('_prepareModel', args);
    this.attributes || (this.attributes = {});
    if (_.isFunction(this.modelAdded)) this.modelAdded(model);
    return model;
  },

  _sort(idx) {
    // order argument not used at this time. We only support ascending sort for now.
    this.order = idx;
  },
});

Collection.extend = chorus.classExtend;

export default Collection;
