export default {
  // backbone enforces id uniqueness within a collection.
  // use padded IDs to ignore ID collisions within collections of multiple types
  // todo: Cloning this collection will mangle the model ids
  _prepareModel(...args) {
    const model = this._super('_prepareModel', args);
    model.id = this.idTemplate(model);
    return model;
  },

  idTemplate(model) {
    return model.id + model.constructorName;
  },
};
