import _ from '../underscore';
import Schema from './schema';
import User from './user';
import Model from './models';
import Attachment from '../mixins/attachment';
import Taggable from '../mixins/tags_mixin';
import fileIconUrl, { inboxFileName, inboxFileIdentity } from '../utilities/url_helpers';

const extensionRegex = /\.([^.]+)$/;
const IMAGE = 'image';
const SQL = 'sql';
const FOLDER = 'folder';
const CODE = 'code';
const TEXT = 'text';
const XML_TYPE = 'xml';
const R = 'r';
const RNW = 'rnw';
const PDF = 'pdf';
const CSV = 'csv';
const ZIP = 'zip';
const OTHER = 'other';
const IPYNB = 'ipynb';
const TAR = 'tar';
const TGZ = 'tgz';
const MD = 'md';

/* eslint no-underscore-dangle: ["error",
{ "allow": [
    "_workspace",
    "_executionSchema",
    "_super"
] }] */

export default Model.include(Attachment, Taggable).extend({
  constructorName: 'Workfile',
  nameAttribute: 'fileName',
  entityType: 'workfile',

  baseShowUrl() {
    return this.showUrl({ baseOnly: true });
  },

  urlTemplate(options) {
    const includeContent = options.includeContent ? options.includeContent : 'false';
    const contentIsMetadata = options.contentIsMetadata ? options.contentIsMetadata : 'false';

    if (this.isNew()) {
      return 'api/workspaces/workspaces/{{workspace.id}}/workfiles';
    } else if (this.isLatestVersion()) {
      if (this.isInbox()) {
        return `api/workspaces/workfiles/{{id}}?public_inbox=${this.isPublicInbox()}`;
      }
      return `api/workspaces/workfiles/{{id}}?include_content=${includeContent}&content_is_metadata=${contentIsMetadata}`;
    }
    return `api/workspaces/workfiles/{{id}}/versions/{{versionInfo.id}}?include_content=${includeContent}`;
  },

  showUrlTemplate(options) {
    const opts = options || {};
    const versionInfo = this.get('versionInfo');
    if (this.isFolder()) {
      return 'workspaces/{{workspace.id}}/folders/{{specialEncodeFolderPaths folder}}/{{encode fileName}}';
    } else if (opts.openAsText === true) {
      return 'workspaces/{{workspace.id}}/workfiles/{{id}}/text';
    } else if (versionInfo == null || (this.isLatestVersion() && !opts.version) || opts.baseOnly) {
      if (opts.run === true && this.isSql()) {
        return 'workspaces/{{workspace.id}}/workfiles/{{id}}/run';
      }
      return 'workspaces/{{workspace.id}}/workfiles/{{id}}';
    }
    const version = opts.version || versionInfo.id;
    return `workspaces/{{workspace.id}}/workfiles/{{id}}/versions/${version}`;
  },

  updateExecutionSchema(schema) {
    delete this._executionSchema;
    return this.save({ executionSchema: { id: schema.get('id') } }, { wait: true });
  },

  schema() {
    return this.executionSchema();
  },

  executionSchema() {
    if (!this._executionSchema) {
      const executionSchema = this.get('executionSchema');
      this._executionSchema = executionSchema && new Schema(executionSchema);
    }
    return this._executionSchema;
  },

  modifier() {
    return new User(this.get('versionInfo') && this.get('versionInfo').modifier);
  },

  content(newContent, options) {
    if (newContent) {
      this.get('versionInfo').content = newContent;
      this.set({ content: newContent }, options);
      return null;
    }
    const versionInfo = this.get('versionInfo');
    return versionInfo ? this.get('versionInfo').content : null;
  },

  setVersion(versionId, options) {
    // we need to change the version
    const ver = versionId || 'latest';
    this.set({ versionInfo: { id: ver } }, { silent: true });
    this.fetch(options);
  },

  declareValidations(newAttrs) {
    this.require('fileName', newAttrs);
  },

  attrToLabel: {
    fileName: 'workfiles.validation.name',
  },

  isImage() {
    return this.get('fileType') === IMAGE;
  },

  isSql() {
    return this.get('fileType') === SQL;
  },

  isR() {
    return this.extension() === R;
  },

  isFolder() {
    return this.get('fileType') === FOLDER;
  },

  isBlob() {
    return this.get('folder').split('/')[0] === 'blobs';
  },

  isInbox() {
    return !!(this.get('metadata') && this.get('metadata').inbox);
  },

  isPublicInbox() {
    return !!(this.get('metadata') && this.get('metadata').publicInbox);
  },

  getInboxIdentity() {
    return inboxFileIdentity(this.get('fileName'));
  },

  getFileName() {
    if (this.isInbox()) {
      return inboxFileName(this.get('fileName'));
    }
    return this.get('fileName');
  },

  canRun() {
    if (this.isInbox()) {
      return
    }
    return this.isSql() || (this.isR() && !this.isBlob());
  },

  isText() {
    return _.include([SQL, TEXT, CODE, RNW, R, XML_TYPE, IPYNB, MD], this.get('fileType'));
  },

  isPartialFile() {
    return this.get('versionInfo') && !!this.get('versionInfo').partialFile;
  },

  isBinary() {
    return this.get('fileType') === OTHER;
  },

  isPdf() {
    return this.extension() === PDF;
  },

  isCsv() {
    return this.extension() === CSV;
  },

  isZip() {
    return [ZIP, TAR, TGZ].includes(this.extension());
  },

  isMd() {
    return this.extension() === MD;
  },

  extension() {
    const fileName = this.get('fileName');
    const matches = fileName && fileName.match(extensionRegex);
    return matches && matches[1].toLowerCase();
  },

  downloadUrl() {
    if (this.isLatestVersion()) {
      return `/api/workspaces/workfiles/${this.get('id')}/download`;
    }
    return `/api/workspaces/workfiles/${this.get('id')}/download?version_id=${this.get('versionInfo').id}`;
  },

  clone() {
    const temp = this._super('clone');
    // We really wanted a shallow clone so set the _workspace back if its available
    if (this._workspace) {
      temp._workspace = this._workspace;
    }
    return temp;
  },

  isLatestVersion() {
    const versionNum = this.get('versionInfo') && this.get('versionInfo').id;
    return (!versionNum || String(versionNum) === String(this.get('latestVersionId')));
  },

  save(attrs, options) {
    let opts = options || {};
    const attributes = attrs || {};
    let overrides = {};

    if (opts.updateWorkfileVersion) {
      overrides.url = `api/workspaces/workfiles/${this.get('id')}/versions/${this.get('versionInfo').id}`;
      attributes.lastUpdatedStamp = this.get('versionInfo').lastUpdatedStamp;
    }

    if (opts.newWorkfileVersion) {
      overrides = {
        method: 'create',
        url: `api/workspaces/workfiles/${this.get('id')}/versions`,
      };
    }

    opts = _.omit(opts, 'updateWorkfileVersion', 'newWorkfileVersion');

    return this._super('save', [attributes, _.extend(opts, overrides)]);
  },

  iconUrl(options) {
    if (this.isFolder()) {
      return fileIconUrl('folder', options && options.size);
    }
    return fileIconUrl(this.extension(), options && options.size);
  },

  hasOwnPage() {
    return true;
  },

  hasConflict() {
    return this.serverErrors &&
        this.serverErrors.fields &&
        this.serverErrors.fields.version &&
        this.serverErrors.fields.version.INVALID;
  },

  nameWithoutFolders() {
    return this.get('name');
  },

  fullName() {
    return `${this.get('folder')}/${this.get('fileName')}`;
  },

  afterParseErrors(errors) {
    if (errors.removed) {
      this.set('removed', errors.removed);
    }
  },

  listUrl() {
    if (this.get('destroying') || (this.isInbox() && !this.isFolder())) { return null; }
    return this.hasOwnPage() ? this.showUrl() : this.downloadUrl();
  },
});
