/* eslint-disable react/jsx-filename-extension */
import { convertToHTML } from 'draft-convert';

export const isRichText = (text) => (/<\/?[a-z][\s\S]*>/i.test(text));

export const convertRaw = (editorState) => {
  const htmlString = convertToHTML({
    styleToHTML: (style) => {
      if (style === 'BOLD') {
        return <strong />;
      }
      if (style === 'ITALIC') {
        return <em />;
      }
      return null;
    },
    blockToHTML: (block) => {
      if (block.type === 'unstyled') {
        return <div />;
      }
      return null;
    },
    entityToHTML: (entity, originalText) => {
      if (entity.type === 'LINK') {
        return <a href={entity.data.url} target="_blank" rel="noreferrer">{originalText}</a>;
      }
      return originalText;
    },
  })(editorState.getCurrentContent());
  return htmlString;
};

export default convertRaw;
