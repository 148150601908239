import { Dropdown } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { isZip, enableOpenAsText } from '../../../helper';
import { workspaceFileListElementUrl, openFileAsText } from '../../../../constants/ui_urls';
import ExtractModal from '../../../components/modals/ExtractModal';
import InboxRejectModal from './modals/InboxRejectModal';
import InboxApproveModal from './modals/InboxApproveModal';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import { useInboxFileContext } from '../Context';
import Can from '../../../../permissions';

const InboxApproveOption = (
  <Dropdown.Item
    icon="thumbs up"
    text={t('file_list.actions.approve')}
  />
);

const InboxRejectOption = (
  <Dropdown.Item
    icon="thumbs down"
    text={t('file_list.actions.reject')}
  />
);

const ExtractOption = (
  <Dropdown.Item
    icon="file text"
    text={t('file_list.actions.extract')}
  />
);

const InboxRowDropdown = () => {
  const {
    id, name, folder, type, displayName,
  } = useInboxFileContext();

  return (
    <Dropdown
      icon={{ name: 'ellipsis vertical', color: 'blue' }}
      className="icon"
      inline
      pointing="right"
    >
      <Dropdown.Menu>
        <Can I="View" this="WorkspaceInboundAirlock">
          <Dropdown.Item
            icon="file outline"
            text={t('file_list.actions.open')}
            href={workspaceFileListElementUrl(id, type, folder, name)}
          />
        </Can>
        {isZip(name) && (
          <ModalWrapper trigger={ExtractOption}>
            <ExtractModal folder={folder} id={id} name={displayName} />
          </ModalWrapper>
        )}
        {(enableOpenAsText(type, name) || isZip(name)) && (
          <Dropdown.Item
            icon="file text"
            text={t('file_list.actions.open_as_text')}
            href={openFileAsText(id)}
          />
        )}
        <Can I="Review" this="WorkspaceInboundAirlock">
          <ModalWrapper trigger={InboxApproveOption} size="small">
            <InboxApproveModal />
          </ModalWrapper>
          <ModalWrapper trigger={InboxRejectOption}>
            <InboxRejectModal />
          </ModalWrapper>
        </Can>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default InboxRowDropdown;
