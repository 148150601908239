import * as d3 from 'd3';
import _ from '../../underscore';
import $ from '../../jquery';
import View from '../loading_view';
import Axes from './axes';
import { Boxplot } from '../../presenters/visualization_data_presenter';

export default View.extend({
  constructorName: 'BoxplotVisualizationView',
  rangeIncrement: 0,

  render() {
    const $el = $(this.el);
    $el.html('');
    $el.addClass('visualization');
    const data = new Boxplot(this.model).present();
    this.postRender();

    const svg = d3.select(this.el)
      .append('svg')
      .attr('class', 'chart boxplot')
      .attr('width', 925)
      .attr('height', 340);


    this.axes = new Axes({
      el: svg,
      yScaleType: 'numeric',
      xScaleType: 'ordinal',
      maxYValue: data.maxY,
      minYValue: data.minY,
      xLabels: _.pluck(data, 'label'),
      xLongLabels: _.pluck(data, 'longLabel'),
      xAxisLabel: this.model.get('xAxis'),
      yAxisLabel: this.model.get('yAxis'),
      hasYGrids: true,
      paddingX: 35,
      paddingY: 35,
    });

    if (!$el.isOnDom()) return;

    this.axes.render();

    this.boxes = svg.selectAll('g.box')
      .data(data).enter()
      .append('g')
      .attr('class', 'box');

    this.scales = this.axes.scales();
    this.boxWidth = 0.4 * this.scales.x.bandwidth();

    this.quartileRectangles();
    this.medianline();
    this.midline();
    this.whiskers();
  },

  centerScale(d) {
    return this.scales.x(d) + (this.scales.x.bandwidth() / 2);
  },

  quartileRectangles() {
    this.boxes.append('rect')
      .attr('width', this.boxWidth)
      .attr('class', 'quartile')
      .attr('height', d => Math.abs(this.scales.y(d.thirdQuartile) - this.scales.y(d.firstQuartile)))
      .attr('x', d => this.centerScale(d.longLabel) - (this.boxWidth / 2))
      .attr('y', d => this.scales.y(d.thirdQuartile));
  },

  midline() {
    this.boxes
      .append('svg:line')
      .attr('class', 'midline')
      .attr('x1', d => this.centerScale(d.longLabel))
      .attr('x2', d => this.centerScale(d.longLabel))
      .attr('y1', d => this.scales.y(d.max))
      .attr('y2', d => this.scales.y(d.min));
  },

  medianline() {
    this.boxes
      .append('svg:line')
      .attr('class', 'median')
      .attr('x1', d => this.centerScale(d.longLabel) - (this.boxWidth / 2))
      .attr('x2', d => this.centerScale(d.longLabel) + (this.boxWidth / 2))
      .attr('y1', d => this.scales.y(d.median))
      .attr('y2', d => this.scales.y(d.median));
  },

  whiskers() {
    this.boxes
      .append('svg:line')
      .attr('class', 'whisker max')
      .attr('x1', d => this.centerScale(d.longLabel) - (this.boxWidth / 4))
      .attr('x2', d => this.centerScale(d.longLabel) + (this.boxWidth / 4))
      .attr('y1', d => this.scales.y(d.max))
      .attr('y2', d => this.scales.y(d.max));

    this.boxes
      .append('svg:line')
      .attr('class', 'whisker min')
      .attr('x1', d => this.centerScale(d.longLabel) - (this.boxWidth / 4))
      .attr('x2', d => this.centerScale(d.longLabel) + (this.boxWidth / 4))
      .attr('y1', d => this.scales.y(d.min))
      .attr('y2', d => this.scales.y(d.min));
  },

});

