/* eslint-disable max-lines */
import React from 'react';
import {
  Menu, Card, Container, Dropdown, Image, Icon, Label, Breadcrumb,
} from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import 'xap-ui-custom-semantic-theme/semantic.less';
import { t } from 'i18n-js';
import NavBarSearch from './NavBarSearch';
import { userInitials, avatarBackgroundColour } from '../mixins/avatars';
import { FEATURE } from '../utilities/features';
import externalLink from '../utilities/navigation';
import './NavBar.less';

// XAP-11226 - imagePath may become a base64 encoded string when we get images from Azure
const UserCard = ({
  imagePath, notifications, name, subtitle,
}) => (
  <Card>
    <Card.Content>
      <Image
        src={imagePath}
        alt={t('header.user.avatar_alt')}
        floated="right"
        size="mini"
        style={{ backgroundColor: avatarBackgroundColour(name) }}
      />
      <div className="avatar-initials">
        {userInitials(name)}
      </div>
      {notifications > 0
      && (
      <Label floating color="blue">
        {notifications}
      </Label>
      )}
      <Card.Header>
        {name}
      </Card.Header>
      <Card.Meta>
        {subtitle}
      </Card.Meta>
    </Card.Content>
  </Card>
);

const WorkspaceBreadcrumbs = ({ workspacePath, workspaceName, navigateFunc }) => {
  if (!workspacePath || !workspaceName) { return (<div />); }
  return (
    <Menu.Item className="menu-breadcrumb">
      <Breadcrumb size="mini">
        <Breadcrumb.Section as="a" href="#" onClick={(e) => navigateFunc(e)}>
          <span className="visuallyhidden">{t('header.workspaces')}</span>
          <Icon name="grid layout" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section className="workspace-crumb" href={workspacePath}>
          {workspaceName}
        </Breadcrumb.Section>
      </Breadcrumb>
    </Menu.Item>
  );
};

const HelpLink = ({ windowparameters }) => {
  const kbUrlValue = windowparameters.kbUrl ? windowparameters.kbUrl : t('url.knowledgebase');
  return (
    <Menu.Item className="help">
      <a
        className="knowledgebase"
        href={kbUrlValue}
        target="_knowledgebase"
        rel="noopener noreferrer"
        data-tooltip={t('header.visit_knowledgebase')}
        data-variation="mini"
        data-position="bottom center"
      >
        <span className="visuallyhidden">{t('header.visit_knowledgebase')}</span>
        <Icon name="question circle outline" size="large" inverted />
      </a>
    </Menu.Item>
  );
};

const NotificactionOption = ({ count, navigateFunc }) => {
  const title = count > 0 ? t('header.notifications.many', { count }) : t('header.notifications.none');
  return (
    <Dropdown.Item as="a" href="#/notifications" onClick={(e) => navigateFunc(e)}>
      <Icon name="flag" />
      {title}
    </Dropdown.Item>
  );
};

const DropdownItems = ({
  navigateFunc, showAboutDialog, windowparameters,
}) => (
  <Dropdown.Menu>
    <Dropdown.Item
      as="a"
      href={t('url.knowledgebase_privacy')}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="shield" />
      {t('header.privacy')}
    </Dropdown.Item>
    {FEATURE.SITE_TRACKING_ENABLED && (
      <Dropdown.Item
        as="a"
        href={externalLink('url.cookie_policy')}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i aria-hidden="true" className="cookie icon" />
        {t('header.cookie_settings')}
      </Dropdown.Item>
    )}
    <Dropdown.Item
      as="a"
      href={t('url.knowledgebase_terms')}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="handshake" />
      {t('header.terms')}
    </Dropdown.Item>
    <Dropdown.Item
      as="a"
      href="#"
      onClick={(e) => showAboutDialog(e)}
    >
      <Icon name="info circle" />
      {t('header.about')}
    </Dropdown.Item>
    <Dropdown.Item
      href={t('url.knowledgebase_terms')}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="commenting" />
      {t('header.support')}
    </Dropdown.Item>
    {windowparameters.customization && windowparameters.feedbackUrl
      && (
      <Dropdown.Item
        href={windowparameters.feedbackUrl}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={t('header.feedback')}
      >
        <Icon name="bullhorn" />
        {t('header.feedback')}
      </Dropdown.Item>
      )}
    <Dropdown.Divider />
    <Dropdown.Item as="a" href="#/logout" onClick={(e) => navigateFunc(e)}>
      <Icon name="sign out" />
      {t('header.sign_out')}
    </Dropdown.Item>
  </Dropdown.Menu>
);

const ProfileDropDown = ({
  unreadNotifications = 0,
  name,
  subtitle,
  imagePath,
  navigateFunc,
  showAboutDialog,
  windowparameters,
}) => (
  <Dropdown
    simple
    className="item user-info"
    title={t('header.right_menu_title')}
    icon={null}
    trigger={(
      <UserCard
        imagePath={imagePath}
        unreadNotifications={unreadNotifications}
        name={name}
        subtitle={subtitle}
      />
    )}
  >
    <DropdownItems
      navigateFunc={navigateFunc}
      showAboutDialog={showAboutDialog}
      windowparameters={windowparameters}
    />
  </Dropdown>
);

const HomeLogo = ({ windowparameters, navigateFunc }) => {
  const logoSrc = windowparameters.homeLogo || '/images/refresh/logo.svg';
  const homeUrl = windowparameters.homeUrl ? windowparameters.homeUrl : '#';
  const onClickHome = !windowparameters.homeUrl ? (e) => navigateFunc(e) : null;

  return (
    <a href={homeUrl} onClick={onClickHome} className="header item">
      <Image src={logoSrc} className="logo" alt={t('header.icon_alt_text')} />
    </a>
  );
};

const FairLink = ({ windowparameters }) => (
  <a
    href={windowparameters.fairUrl}
    className="aridhia-fair-link item"
    target="_blank"
    rel="noopener noreferrer"
  >
    <Image src="/images/FAIRLogo2020-white.svg" className="icon" />
    Data Services
  </a>
);

const CustomLink = ({ windowparameters }) => (
  <a
    href={windowparameters.customLink.href}
    className="aridhia-custom-link item"
    target="_blank"
    rel="noopener noreferrer"
    aria-label={windowparameters.customLink.alt}
  >
    <Image
      src={windowparameters.customLink.src}
      alt={windowparameters.customLink.alt}
      className="icon"
    />
  </a>
);

const LocationDropDown = ({ windowparameters }) => (
  <Dropdown
    simple
    className="item locationlist"
    title="Workspaces"
    text="Workspaces"
  >
    <>
      <Image
        src="/images/refresh/workspacesLogo2020-white.svg"
        className="icon"
        style={{ left: '16px', top: '10px', position: 'absolute' }}
      />
      <Dropdown.Menu>
        {
          windowparameters.workspaceLocations.map((obj, key) => <Dropdown.Item as="a" key={key} href={obj.url}>{obj.name}</Dropdown.Item>)
        }
      </Dropdown.Menu>
    </>
  </Dropdown>
);

const options = {
  debounce: false,
  events: {
    workspaces: ['sync'],
    notifications: ['loaded'],
    user: ['sync'],
  },
};

const mapModelsToProps = (models, props) => (
  {
    user: models.user,
    workspace: models.workspace,
    notifications: models.notifications,
    ...props,
  }
);

const nullWorkspace = {
  name: () => null,
  showUrl: () => null,
};

const NavBarWithModels = ({
  user,
  notifications,
  workspace = nullWorkspace,
  navigateFunc,
  popupFunc,
  showAboutDialog,
}) => (
  <NavBar
    unreadNotifications={notifications.pagination ? notifications.pagination.records : 0}
    name={user.displayName()}
    imagePath={user.fetchImageUrl()}
    userPath={user.showUrl()}
    workspaceName={workspace.name()}
    workspacePath={workspace.showUrl()}
    navigateFunc={navigateFunc}
    popupFunc={popupFunc}
    showAboutDialog={showAboutDialog}
    windowparameters={window.customizationVariables}
  />
);

export const BackboneNavbar = connectBackboneToReact(mapModelsToProps, options)(NavBarWithModels);
export const ReactNavbar = NavBarWithModels;

export const NavBar = ({
  unreadNotifications = 0,
  name,
  subtitle,
  imagePath,
  userPath,
  workspaceName,
  workspacePath,
  navigateFunc,
  showAboutDialog,
  windowparameters,
}) => (
  <Menu inverted className="nav fixed no-full-screen">
    <Container>
      <HomeLogo
        navigateFunc={navigateFunc}
        windowparameters={windowparameters}
      />
      <WorkspaceBreadcrumbs
        workspacePath={workspacePath}
        workspaceName={workspaceName}
        navigateFunc={navigateFunc}
      />
      {windowparameters.customization && windowparameters.fairUrl
        && <FairLink windowparameters={windowparameters} />}
      {windowparameters.customization && windowparameters.customLink
        && <CustomLink windowparameters={windowparameters} />}
      {windowparameters.workspaceLocations && windowparameters.workspaceLocations.length > 1
        && <LocationDropDown windowparameters={windowparameters} />}
      <NavBarSearch />
      <HelpLink windowparameters={windowparameters} />
      <ProfileDropDown
        unreadNotifications={unreadNotifications}
        name={name}
        subtitle={subtitle}
        imagePath={imagePath}
        userPath={userPath}
        navigateFunc={navigateFunc}
        showAboutDialog={showAboutDialog}
        windowparameters={windowparameters}
      />
    </Container>
  </Menu>
);
