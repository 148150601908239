import { t } from 'i18n-js';
import React from 'react';
import {
  Icon, Button, Message,
} from 'semantic-ui-react';
import { useMutation } from '@tanstack/react-query';
import StandardModal, { StandardModalContent } from '../../standard_modal';
import { downloadAudit } from '../service';
import { downloadWorkspaceAuditUrl } from '../../constants/api_urls';

const DownloadButton = ({ isNarrow, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Button title={isNarrow ? t('audit.audit_panel.buttons.download') : null} {...props} data-testid="download-button">
    <Icon name="download" data-testid="download-button-icon" className={isNarrow ? 'margin-none' : null} />
    {!isNarrow && t('audit.audit_panel.buttons.download')}
  </Button>
);

const DownloadWorkspaceAuditModalContent = (props) => {
  const download = () => (
    downloadAudit(downloadWorkspaceAuditUrl())
  );
  const useMutationHook = (options) => useMutation(download, options);
  return (
    <StandardModalContent
      headerProps={{ icon: 'download', title: t('audit.download.header') }}
      useMutationHook={useMutationHook}
      actionProps={{ confirmationText: t('audit.download.buttons.download') }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Message
        warning
        className="modal-message"
        size="small"
        icon="warning circle"
        content={t('audit.download.message')}
      />
    </StandardModalContent>
  );
};

const DownloadWorkspaceAuditModal = ({
  isNarrow,
}) => (
  <StandardModal trigger={<DownloadButton isNarrow={isNarrow} />}>
    <DownloadWorkspaceAuditModalContent />
  </StandardModal>
);

export default DownloadWorkspaceAuditModal;
