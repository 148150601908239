import { useCallback, useState } from "react";
import { t } from "i18n-js";

export const sizeAsString = (size) => {
  if (!size) {
    return '0 bytes';
  } if (size < 1024) {
    return `${size} bytes`;
  } if (size >= 1024 && size < (1024 * 1024)) {
    const numKilobytes = (size / 1024).toFixed(2);
    return `${numKilobytes} KB`;
  } if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) {
    const numMegabytes = (size / (1024 * 1024)).toFixed(2);
    return `${numMegabytes} MB`;
  }
  const numGigabytes = (size / (1024 * 1024 * 1024)).toFixed(2);
  return `${numGigabytes} GB`;
};

// Returns the base domain excluding any sub domain portion or paths
export const getDomain = () => {
  const urlParts = window.location.hostname.split('.');
  return urlParts
    .slice(2)
    .join('.');
};

export const encode = (value) => (value ? encodeURIComponent(value) : '');

export const specialEncodeFolderPaths = (folder) => folder.split('/').map(encodeURIComponent).join('/');

export const parseJson = (str) => {
  let jsonObject = {};
  try {
    jsonObject = JSON.parse(str);
  } catch (e) {
    console.log(e);
  }
  return jsonObject;
};

export const virtualMachineRandomIdentifier = () => Math.random().toString(36).substr(2, 5);

export const virtualMachineOsShortName = (name) => name.substr(0, 3);

/**
 * Converts the properties of an object that are functions into a lazy get so that the functions are
 * only ever called the once
 * @param {object} obj - the object with properties to convert
 * @example
 * const LazyConstants = lazify({
 *  Year: () = { ... some code to calculate the year ... },
 *  Five: 5
 *  });
 * LazyConstants.Year will be evaluated on first use and not recalculated for any other use
 * LazyConstants.Five will just be 5, unaltered by being lazified.
 */
export const lazify = (obj) => {
  const result = {};

  for (const [key, value] of Object.entries(obj)) {
    if (value instanceof Function) {
      Object.defineProperty(result, key, {
        get: () => {
          const actualData = value();

          // re-define the property with the value once known
          Object.defineProperty(result, key, {
            value: actualData,
            writable: false,
            configurable: false,
          });

          return actualData;
        },
        configurable: true,
        enumerable: true,
      });
    } else {
      result[key] = value;
    }
  }
  return result;
};

/**
 * Returns the property descriptors but with them set to configurable so that when used in the mergeObjects function
 * they can be overridden
 * @param obj
 * @returns {{}}
 */
const changeableProperties = (obj) => {
  const properties = Object.getOwnPropertyDescriptors(obj);
  for (const [, value] of Object.entries(properties)) {
    if (value) {
      value.configurable = true;
    }
  }
  return properties;
};

/**
 * Handy function for outputting parameters going into a function and the result of the function
 * @param fn
 * @returns {function(...[*]): *}
 */
export const debugFn = (fn) => (...args) => {
  const result = fn(...args);
  console.debug(fn, args, result);
  return result;
};

/**
 * This can be used instead of {...objA, ...objB} to merge the properties of two objects.
 * The important difference is that getters are preserved and not evaluated by this function unlike the spread
 * option.
 * @param objA
 * @param objB
 * @returns {{}}
 */
export const mergeObjects = (objA, objB) => (
  Object.defineProperties(
    Object.defineProperties({}, changeableProperties(objA)),
    changeableProperties(objB),
  )
);

const applyHookReducer = (acc, fn) => mergeObjects(acc, fn(acc));

/**
 * Takes a list of functions as parameters that process properties and returns a single function that performs all of
 * provided functions in order.
 * This can be used to combine react hooks together.
 * @example
 * const eg1 = ({ a }) => ({ a: (a * 2)});
 * const eg2 = ({ b }) => ({ c: a + b});
 * const bothEg1AndEg2 = combineHooks(eg1, eg2);
 * const result = bothEg1AndEg2({ a: 1, b: 10, d: 20});
 *
 * The result will be { a: 2, b: 10, c: 12, d: 20 }
 * a is replaced by eg1
 * b is used by eg2 but unchanged
 * c is the result of adding the a from the output of eg1 to b
 * d is not used by anything and just passed through to the result
 * @param hooks
 * @returns {function(*): *}
 */
export const combineHooks = (...hooks) => (props) => hooks.reduce(applyHookReducer, props);

/**
 * Can be used to create a hook that wraps the onChange event for a component and uses the built in browser
 * input validity to validate the change.
 * @example
 * const useNameCheck = createUseInputValidity('translation.when.in.error');
 * const InputWithPattern = (props) => <Form.Input input={{pattern: 'abc'}} {...props} />;
 * // use it as a normal hook to wrap the onChange and provide error
 * const NameComponent = ({ onChange }) => {
 *   const { onChange: handleChange, error } = useNameCheck({ onChange });
 *   return <InputWithPattern onChange={handleChange} error={error} />
 * }
 * // or use combineHooks to apply the wrapper for you
 * const NameComponent2 = combineHooks(useNameCheck, InputWithPattern);
 *
 * @param message
 * @returns {(function({onChange: *}): ({onChange: *, error: {content: *}}))|*}
 */
export const createUseInputValidity = (message) => ({ onChange }) => {
  const [error, setError] = useState(false);

  const changeHandler = useCallback((event, component) => {
    const { value } = component;
    const isValid = event.target.checkValidity();
    if (value.length === 0 || isValid) {
      setError(false);
    } else {
      setError(true);
    }
    onChange(event, component);
  }, [setError, onChange])

  if (error) {
    return {
      onChange: changeHandler,
      error: { content: t(message) }
    };
  }
  return {
    onChange: changeHandler
  };
}

