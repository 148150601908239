/* eslint-disable react/jsx-props-no-spreading,object-curly-newline */
import { Button, Container, Header, Icon, Segment } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { getUserProfile } from '../../application/auth/oauth';
import PromisedComponent from '../components/PromisedComponent';
import useWorkspaceConfig from '../adapters/config';

const CenteredContent = ({ children }) => (
  <Container className="semantic ux-update full-height">
    <Segment placeholder className="full-height">
      {children}
    </Segment>
  </Container>
);

const errorToString = (error) => {
  if (error.info && error.info.error) {
    return error.info.error;
  }
  if (error.message) {
    return error.message;
  }
  return String(error);
};

const ErrorPage = ({ header, error }) => (
  <CenteredContent>
    <Header as="h2" icon color="red">
      <Header.Content>
        <Icon name="warning sign" />
        {header}
      </Header.Content>
      <Header.Subheader>
        {errorToString(error)}
      </Header.Subheader>
    </Header>
    <Button icon="refresh" primary onClick={() => window.location.reload()} content="Retry" />
  </CenteredContent>
);

const userDetails = async () => {
  const { email, oid } = await getUserProfile();
  return t('unauthorized.text', { email, uuid: oid });
};

export const UnuathorizedPage = () => {
  const { config } = useWorkspaceConfig();
  const portalUrl = config?.administration_url;
  return (
    <CenteredContent>
      <Header as="h2" icon color="red">
        <Header.Content>
          <Icon name="warning sign" />
          {t('unauthorized.title')}
        </Header.Content>
        <Header.Subheader>
          <Container text textAlign="left" style={{ width: '55ch', whiteSpace: 'pre-wrap', userSelect: 'text' }}>
            <PromisedComponent promise={userDetails()} />
          </Container>
        </Header.Subheader>
      </Header>
      { portalUrl && (<Button as="a" href={portalUrl} primary content={t('actions.home')} />) }
    </CenteredContent>
  );
};

export default ErrorPage;
