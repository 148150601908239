import chorus from '../../chorus';
import View from '../loading_view';

export default View.extend({
  templateName: 'iframe',
  constructorName: 'IFrame',

  setup(options) {
    this._super('setup', options);
    this.url = options.url;
    this.content = options.content;
    this.listenTo(chorus.frameEvent, 'frame:event', this.onFrameReceivedMessage);
    this.disableSandbox = !!options.disableSandbox;
  },

  postRender() {
    const view = this;
    const fadeLoadView = () => {
      view.$el.find('.loading').fadeOut(500);
    };
    if (this.content) {
      this.content.then((content) => {
        view.$el.find('iframe').contents().find('body').html(content);
        fadeLoadView();
      });
    } else {
      this.$el.find('iframe').load(fadeLoadView);
    }
  },

  additionalContext() {
    return {
      url: this.url,
      disableSandbox: this.disableSandbox,
    };
  },

  recalculateScrolling() {
    if (this.$el.is(':visible')) {
      const newHeight = document.body.offsetHeight - this.$el.offset().top - 6;
      this.$el.height(newHeight);
    }
  },

  onFrameReceivedMessage(event) {
    if (event.data !== 'frameCopyEvent' && this.$('iframe')[0]?.contentWindow === event.source && event.data.type !== 'error' ) {
      event.stopImmediatePropagation();
      this.onMessage(event);
    }
  },

  onMessage(event) {
    if (event.data.type === 'error' && this.tab.get('active')) {
      chorus.toast('workspaces.dte.error', {
        message: event.data.message,
        toastOpts: { theme: 'bad_activity' },
      });
    }
  },

});
