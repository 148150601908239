import inboxDisplayFileName from '../files/panels/inbox/helper';

export const selectedItemsChecker = (checkedItems, value, checked, name, items, fileType, folder, isInbox = false) => {
  const displayName = isInbox && name ? inboxDisplayFileName(name) : name;
  const newItem = {
    id: value,
    name: displayName,
    fileType,
    folder,
  };
  let updatedList = [...checkedItems];
  if (value === 'all' && checked) {
    updatedList = items.map((item) => {
      const itemName = item.file_name || item.object_name;
      const cleanName = isInbox ? inboxDisplayFileName(itemName) : itemName;
      return ({
        id: item.id,
        name: cleanName,
        fileType: item.file_type,
        folder: item.folder,
      });
    });
  } else if (value === 'all' && !checked) {
    updatedList = [];
  } else if (value !== 'all' && checked) {
    updatedList = [...checkedItems, newItem];
  } else if (value !== 'all' && !checked) {
    updatedList.splice(checkedItems.findIndex((item) => item.id === value), 1);
  }
  return updatedList;
};

export const listIcon = (filetype) => {
  switch (filetype) {
    case 'folder':
      return 'folder';
    case 'dataset':
      return 'table';
    default:
      return 'file';
  }
};

export const returnBaseFolder = (filePath) => filePath.split('/')[0];

export const sizeAsString = (size) => {
  const reg = /^\d+$/;
  if (!reg.test(size)) {
    return '';
  } if (size <= 0) {
    return '0 bytes';
  } if (size < 1024) {
    return `${size} bytes`;
  } if (size >= 1024 && size < (1024 * 1024)) {
    const numKilobytes = (size / 1024).toFixed(2);
    return `${numKilobytes} KB`;
  } if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) {
    const numMegabytes = (size / (1024 * 1024)).toFixed(2);
    return `${numMegabytes} MB`;
  }
  const numGigabytes = (size / (1024 * 1024 * 1024)).toFixed(2);
  return `${numGigabytes} GB`;
};

export const capitalise = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const afterRender = (fn) => setTimeout(fn, 0);
