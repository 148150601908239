import Note from './note';

export default Note.extend({
  constructorName: 'Insight',
  parameterWrapper: 'note',

  urlTemplate(options) {
    const action = this.get('action');

    if (options && options.isFile) {
      return 'api/workspaces/notes/{{id}}/attachments';
    } else if (action === 'create') {
      return 'api/workspaces/insights';
    } else if (action === 'publish' || action === 'unpublish') {
      return `api/workspaces/insights/${action}`;
    } else if (action === 'destroy') {
      return 'api/workspaces/insights/{{id}}';
    }
    return 'api/workspaces/notes/{{id}}';
  },
  attrToLabel: {
    body: 'insight.title',
  },
  initialize() {
    this._super('initialize');
    this.set({ isInsight: true });
  },

  declareValidations(newAttrs) {
    if (newAttrs.validateBody !== false) {
      this.require('body', newAttrs);
    }
  },
});
