import _ from '../../underscore';
import Handlebars from '../../vendor/handlebars';
import SearchItemBase from './search_item_base';
import SearchResultCommentList from './search_result_comment_list';
import { workspace } from '../../utilities/workspace_assoc';

export default SearchItemBase.extend({
  constructorName: 'SearchWorkfileView',
  templateName: 'search_workfile',

  additionalContext() {
    return _.extend(this._super('additionalContext'), {
      showUrl: this.model.showUrl(),
      iconUrl: this.model.iconUrl(),
      workspaces: [workspace(this.model).attributes],
    });
  },

  makeCommentList() {
    const comments = this.model.get('comments') || [];
    const versionComments = this.model.get('highlightedAttributes') &&
        this.model.get('highlightedAttributes').versionComments;
    _.each(versionComments || [], (versionComment) => {
      comments.push({ isVersionComment: true, body: new Handlebars.SafeString(versionComment) });
    }, this);

    return new SearchResultCommentList({ comments });
  },
});
