import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { t } from 'i18n-js';

export const CancelButton = ({ onClose, saving, cancelText }) => {
  const text = cancelText || t('actions.cancel');
  const onClick = saving ? null : onClose;

  return (
    <Button onClick={onClick} disabled={saving} content={text} />
  );
};

export const SaveButton = ({
  onSave, saving, minimumDataEntryMet, saveText, secondary, otherSaveOptionSelected, disable, iconName, ...props
}) => {
  const disabled = saving || !minimumDataEntryMet || disable;
  const onClick = disabled ? null : onSave;
  const text = saveText || t('actions.confirm');
  const loading = saving && !otherSaveOptionSelected;
  const name = iconName || 'checkmark';

  return (
    <Button
      icon
      labelPosition="right"
      positive={!secondary}
      secondary={secondary}
      loading={loading}
      onClick={onClick}
      disabled={disabled}
      className="margin-right-none"
      {...props}
    >
      {text}
      <Icon name={name} />
    </Button>
  );
};

export default ({
  onClose, onSave, saving, minimumDataEntryMet, saveText, cancelText,
}) => (
  <div className="flex justify-end">
    <CancelButton onClose={onClose} saving={saving} cancelText={cancelText} />
    <SaveButton onSave={onSave} saving={saving} minimumDataEntryMet={minimumDataEntryMet} saveText={saveText} />
  </div>
);
