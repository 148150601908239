import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import Dialog from '../../dialogs/dialogs';
import Note from '../../models/note';
import ClEditor from '../../mixins/cl_editor_helpers';

export default Dialog.include(ClEditor).extend({
  constructorName: 'EditNoteDialog',
  templateName: 'edit_note',
  persistent: true,

  events: {
    'submit form': 'submit',
  },

  setup() {
    this.activity = this.options.activity;
    this.title = this.activity.isInsight() ? t('notes.edit_dialog.insight_title') : t('notes.edit_dialog.note_title');
    this.resource = this.toNote();
    this.model = this.resource;

    this.listenTo(this.resource, 'validationFailed', this.showErrors);
    this.listenTo(this.resource, 'saved', this.submitSucceeds);
  },

  showErrors(model) {
    this.$('button.submit').stopLoading();
    this._super('showErrors');
    let errorModel = model;

    if (!errorModel) {
      errorModel = this.resource;
    }

    if (errorModel.errors && errorModel.errors.body) {
      const $input = this.$('.cleditorMain');
      this.markInputAsInvalid($input, errorModel.errors.body, true);

      this.$('iframe').contents().find('body').css('margin-right', '20px');
    }
  },

  postRender() {
    this.$('textarea').val(this.activity.get('body'));

    _.defer(_.bind(function buildEditor() {
      this.makeEditor($(this.el), '.toolbar', 'body', { width: 'auto', height: 150 });
    }, this));
  },

  toNote() {
    return new Note({
      id: this.activity.id,
      body: this.activity.get('body'),
    });
  },

  submit(e) {
    e && e.preventDefault();
    this.$('button.submit').startLoading('actions.saving');

    let newText = this.getNormalizedText(this.$('textarea'));
    const cleanText = _.trim($.stripHtml(newText));

    if (cleanText === '') {
      // if the user only has whitespace, force validation failure on the model
      newText = '';
    }

    this.model.save({ body: newText });
  },

  submitSucceeds() {
    this.$('button.submit').stopLoading();
    this.closeModal();
    chorus.PageEvents.trigger('note:saved', this.model);
  },
});
