import { useContext, createContext } from 'react';

const CustomisationContext = createContext();

// TODO: Use context for production code not jsut for tests
export const useCustomisationContext = () => {
  const context = useContext(CustomisationContext);

  if (!context) { return window.customizationVariables; }

  return context;
};

export const CustomisationProvider = ({ children, value }) => (
  <CustomisationContext.Provider value={value}>
    {children}
  </CustomisationContext.Provider>
);
