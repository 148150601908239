/* eslint-disable max-lines */
import { useState, useEffect } from 'react';
import { t } from 'i18n-js';
import {
  Modal, Message, Icon, Header, Form,
} from 'semantic-ui-react';
import HeaderError from '../../../components/errors/HeaderError';
import { useSaveAsCSV } from '../../service';
import DelimiterForm from './DelimiterForm';
import RowsForm from '../../panels/database_tables/components/RowsForm';
import ModalActionButtons, { PositiveButton } from '../../../components/buttons/ModalActionButtons';
import toastMessage from '../../../utilities/toast';
import { invalidCharacters, invalidCharactersIn, invalidFileNames } from '../../../utilities/validation';
import { validationLengths, validationFields } from '../../../constants/validation';
import { calculateFileNameExtension, supportedFileTypes } from '../../../files';
import { useDatabaseTableContext } from '../../Context';
import SaveAsCSVNameForm from './SaveAsCSVNameForm';
import { sanitiseTableType } from '../../helper';
import { workspaceId } from '../../../layout/helper';

const checkForErrors = (data) => {
  const errors = [];
  if (!data.name) {
    errors.push({
      field: 'name',
      message: (t('field_error.BLANK', { field: t('modals.save_as_csv.csv.name') })),
    });
  }

  if (data.name?.length > validationLengths.NAME) {
    errors.push({
      field: 'name',
      message: (t('field_error.TOO_LONG', { field: t('modals.save_as_csv.csv.name'), count: validationLengths.NAME })),
    });
  }

  if (data.name && invalidCharactersIn(data.name)) {
    errors.push({
      field: 'name',
      message: (
        t('field_error.INVALID_CHARACTERS', {
          field: t('modals.save_as_csv.csv.name'),
          characters: invalidCharacters,
        })),
    });
  }

  if (data.name && invalidFileNames(data.name)) {
    errors.push({
      field: 'name',
      message: (t('field_error.INVALID_NAME')),
    });
  }

  if (data.rowLimit && data.rowLimit < 0) {
    errors.push({
      field: 'rowLimit',
    });
  }
  return errors;
};

const initialState = (tableName) => ({
  name: `${tableName}.csv`,
  delimiter: ',',
  rowLimit: 0,
  includeHeader: true,
});

const SaveAsCSVModal = ({ setModalClose, setIsBusy }) => {
  const { tableId, tableName, tableType } = useDatabaseTableContext();
  const [csv, setCSV] = useState(initialState(tableName));
  const [overwrite, setOverwrite] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const {
    saveTableAsCSV,
    isLoading,
    isError,
    error,
    resetResponse,
  } = useSaveAsCSV();

  const duplicateNameError = t('field_error.file_name.DUPLICATE', { value: csv.name });

  useEffect(() => {
    if (error === duplicateNameError) { setOverwrite(true); }
  }, [error, duplicateNameError]);

  const handleNameChange = (e, { value }) => {
    setCSV({ ...csv, name: value });
    setOverwrite(false);
    const newErrors = validationErrors.filter((err) => err.field !== validationFields.NAME);
    setValidationErrors(newErrors);
  };

  const handleRowLimitChange = (value) => {
    setCSV({ ...csv, rowLimit: parseInt(value, 10) });
    const newErrors = validationErrors.filter((err) => err.field !== validationFields.ROW_LIMIT);
    setValidationErrors(newErrors);
  };

  const isValidEntry = !!csv.name && !!csv.delimiter && Number.isInteger(csv.rowLimit);

  const createCSVname = () => {
    const ext = calculateFileNameExtension(csv.name);

    if (ext === supportedFileTypes.CSV) {
      const newName = csv.name.replace(`.${ext}`, '');
      return `${newName}.${supportedFileTypes.CSV}`;
    }

    return `${csv.name}.${supportedFileTypes.CSV}`;
  };

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleSubmitting = () => {
    setIsBusy(true);
    setCSV({ ...csv, name: createCSVname(csv.name) });
    const errors = checkForErrors(csv);
    setValidationErrors(errors);
    if (errors.length > 0) { return; }

    const tableData = {
      csv_export: {
        dataset_id: tableId,
        delimiter: csv.delimiter,
        file_name: createCSVname(csv.name),
        has_header: csv.includeHeader,
        overwrite,
        row_limit: csv.rowLimit,
        workspace_id: workspaceId(),
      },
    };

    saveTableAsCSV(tableData, {
      onSuccess: () => {
        setIsBusy(false);
        handleModalClose();
        toastMessage('success', t('modals.save_as_csv.saving.started'), 'saveAsCSVSuccess');
      },
      onError: () => {
        setIsBusy(false);
      },
    });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header" data-testid="save-as-csv-modal">
        <Icon name="save" title="save" />
        <Header.Content content={t('modals.save_as_csv.title', { tableType: sanitiseTableType(tableType).toLowerCase() })} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <Message info content={t('modals.save_as_csv.save_notice', { tableName })} className="margin-top-none" />
        <Form>
          <SaveAsCSVNameForm validationErrors={validationErrors} name={csv.name} handleNameChange={handleNameChange} />
          <DelimiterForm
            delimiter={csv.delimiter}
            handleDelimiterChange={(value) => setCSV({ ...csv, delimiter: value })}
          />
          <RowsForm
            validationErrors={validationErrors}
            rowLimit={csv.rowLimit}
            setRowLimit={handleRowLimitChange}
            includeHeader={csv.includeHeader}
            handleIncludeHeader={() => setCSV({ ...csv, includeHeader: !csv.includeHeader })}
          />
        </Form>
      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmitting}
        isValidEntry={isValidEntry && !overwrite}
        error={validationErrors.length > 0}
        submitText={t('actions.save_as_csv')}
      >
        {overwrite && (
          <PositiveButton
            loading={isLoading}
            onSubmit={handleSubmitting}
            isValidEntry={isValidEntry}
            submitText={t('actions.overwrite')}
            error={validationErrors.length > 0}
          />
        )}
      </ModalActionButtons>
    </>
  );
};

export default SaveAsCSVModal;
