import chorus from '../../chorus';
import { getManagementWorkspace } from '../../models/management/workspace';
import ManagementUsers from '../../collections/management/users';
import NotesNew from '../../dialogs/activities/notes_new_dialog';
import InsightsNew from '../../dialogs/activities/insights_new_dialog';
import EditWorkspace from '../../dialogs/workspaces/edit_workspace_dialog';
import View from '../loading_view';
import { portalUIEditUrl } from '../../../new_ux/constants/ui_urls';
import Routing from '../../mixins/routing';
import { FEATURE } from '../../../utilities/features';

export default View.include(Routing).extend({
  constructorName: 'WorkspaceSidebarBasic2',
  templateName: 'workspace_sidebar_basic2',

  setup() {
    this.subscribePageEvent('workspace:selected', this.setWorkspace);
    this.listenTo(this.model, 'image:change', this.render);
    this.requiredResources.add(this.model);

    if (this.model) {
      this.collection = this.model.activities();
      this.listenTo(this.collection, 'invalidated', function fetchActivitie() { this.collection.fetch(); });
    } else {
      delete this.collection;
    }
  },

  events: {
    'click .edit_workspace': 'launchEditWorkspaceDialog',
    'click .edit_workspace_members': 'launchEditWorkspaceMembersDialog',
    'click .new_note': 'launchAddNoteDialog',
    'click .new_insight': 'launchAddInsightDialog',
    'click .new_airlock': 'navigateToAirlock',
    'click .activity_refresh': 'activityRefresh',
  },

  launchEditWorkspaceDialog(e) {
    e && e.preventDefault();
    const managementWorkspace = getManagementWorkspace(this.model.get('uuid'));
    managementWorkspace.fetch().then(() => {
      this.editWorkspaceDialog = new EditWorkspace({ model: this.model, managementWorkspace });
      this.editWorkspaceDialog.launchModal();
    });
  },

  launchEditWorkspaceMembersDialog(e) {
    e && e.preventDefault();
    this.users_collection = new ManagementUsers([], {
      page_size: 10, status: 'active', enabled: true,
    });

    this.managementWorkspace = getManagementWorkspace(this.model.get('uuid'));

    this.editWorkspaceMembersDialog = new chorus.dialogs.WorkspaceEditMembers({
      model: this.model,
      managementWorkspace: this.managementWorkspace,
      collection: this.users_collection,
    });

    this.editWorkspaceMembersDialog.launchModal();
  },

  additionalContext() {
    return {
      workspaceAdmin: this.model.workspaceAdmin(),
      hasImage: this.model.hasImage(),
      hasSandbox: !!this.model.sandbox(),
      canUpdate: this.model.canUpdate(),
      canEditMembers: this.model.canEditMembers(),
      canEditWorkspace: this.model.workspaceAdmin() || this.model.canEditMembers(),
      canAddNotes: this.model.canAddNotes(),
      canManageSnapshots: this.model.canManageSnapshots(),
      canAirlock: this.model.canAirlock(),
      isDevMode: chorus.isDevMode(),
      portalUIEditUrl: portalUIEditUrl(sessionStorage.getItem('workspaceListUri'), this.model.get('uuid')),
      newUx: FEATURE.NEW_UX,
    };
  },

  launchAddNoteDialog(e) {
    e && e.preventDefault();

    new NotesNew({
      entityId: this.model.id,
      entityType: 'workspace',
      workspace: this.model,
      allowWorkspaceAttachments: true,
      pageModel: this.collection,
    }).launchModal();
    return false;
  },

  launchAddInsightDialog(e) {
    e && e.preventDefault();

    new InsightsNew({
      entityId: this.model.id,
      entityType: 'workspace',
      workspace: this.model,
      allowWorkspaceAttachments: true,
      pageModel: this.collection,
    }).launchModal();
    return false;
  },

  navigateToAirlock(e) {
    e && e.preventDefault();

    this.navigate(`workspaces/${this.model.id}/airlock_new`);
  },

  activityRefresh(e) {
    e && e.preventDefault();
    this.collection.fetch();
  },
});
