/* eslint-disable object-curly-newline,react/jsx-props-no-spreading,camelcase */
import { DropdownItem, Table } from 'semantic-ui-react';
import { t } from 'i18n-js';
import React from 'react';
import moment from 'moment';
import { QueryTable, ActionMenu } from '../../query_table';
import { useSecretsDelete, useSecretsEdit, useSecretsQuery } from '../adapters';
import StandardModal, { StandardModalContent } from '../../standard_modal';
import { lazify } from '../../../utilities/helpers';
import { usePermissions } from '../../permissions';
import DeleteModalMessage from '../../components/modals/DeleteModalMessage';
import { formatDateTime, relativeDate } from '../../helpers/dates';
import MarkdownRender from '../../components/text_editors/MarkdownRender';
import { EditSecretContent } from './CreateSecret';
import { convertNameToValidEnv } from '../hooks';

const ConfirmDeletion = ({ content, ...props }) => {
  const actionProps = {
    positive: false,
    confirmationText: t('actions.delete'),
  };
  const headerProps = {
    title: t('secrets.delete.title'),
    icon: 'user secret',
  };

  return (
    <StandardModalContent headerProps={headerProps} actionProps={actionProps} {...props}>
      <DeleteModalMessage title={content} />
    </StandardModalContent>
  );
};

const DeleteSecret = ({ useMutation, name, ...props }) => {
  const content = t('secrets.delete.one', { name });

  return ConfirmDeletion({
    defaultMutationParameters: name,
    useMutationHook: useMutation,
    content,
    ...props,
  });
};

const MultiDeleteSecret = ({ useMutation, selected, ...props }) => {
  const content = t('secrets.delete.many', { count: selected.length });

  return ConfirmDeletion({
    defaultMutationParameters: selected,
    useMutationHook: useMutation,
    content,
    ...props,
  });
};

const DeleteButton = ({ useMutation, name }) => {
  const Trash = <DropdownItem icon="trash" text={t('secrets.delete.button')} />;
  return (
    <StandardModal trigger={Trash}>
      <DeleteSecret useMutation={useMutation} name={name} />
    </StandardModal>
  );
};

const EditButton = ({ useMutation, secret }) => {
  const Edit = <DropdownItem icon="pencil" text={t('secrets.edit.button')} />;
  return (
    <StandardModal trigger={Edit}>
      <EditSecretContent useMutation={useMutation} secret={secret} />
    </StandardModal>
  );
};

const MultiDeleteSecretModal = ({ selected, trigger }) => (
  <StandardModal trigger={trigger}>
    <MultiDeleteSecret selected={selected} useMutation={useSecretsDelete} />
  </StandardModal>
);

const SecretActions = ({ secret }) => (
  <ActionMenu>
    <EditButton useMutation={useSecretsEdit(secret)} secret={secret} />
    <DeleteButton useMutation={useSecretsDelete} name={secret.name} />
  </ActionMenu>
);

const autoGeneratedName = (name) => (
  <em title={t('secrets.environment_name_autogenerated')}>
    {convertNameToValidEnv(name)}
  </em>
);
const createRow = (canWrite) => (secret) => {
  const { name, environment_name, description, created_at } = secret;
  const date = moment.unix(created_at);
  const env = environment_name || autoGeneratedName(name);

  return (
    {
      key: name,
      columns: [
        <Table.Cell className="aridhia-table-name-cell">{name}</Table.Cell>,
        <Table.Cell className="aridhia-table-name-cell">{env}</Table.Cell>,
        <Table.Cell><MarkdownRender value={description} /></Table.Cell>,
        <Table.Cell title={relativeDate(date)}>{formatDateTime(date)}</Table.Cell>,
        <Table.Cell textAlign="right">
          {canWrite && <SecretActions secret={secret} />}
        </Table.Cell>,
      ],
    }
  );
};

const createHeaderObj = (props) => ({ content: t(`secrets.${props.name}`), ...props });

const Constants = lazify({
  HEADERS: () => [
    { name: 'name', unsortable: false },
    { name: 'environment_name', unsortable: false, tooltip: t('secrets.environment_name_tooltip') },
    { name: 'description', unsortable: false },
    { name: 'created', unsortable: false },
    { name: 'actions', unsortable: true },
  ].map(createHeaderObj),
});

const SecretsList = ({ empty }) => {
  const canWrite = usePermissions('Write', 'WorkspaceSecret');
  const Row = createRow(canWrite);
  const multiActions = canWrite ? [
    {
      icon: 'trash',
      title: 'Delete',
      modal: MultiDeleteSecretModal,
    },
  ] : null;

  return (
    <QueryTable
      columns={Constants.HEADERS}
      renderBodyRow={Row}
      useQuery={useSecretsQuery}
      multiSelectActions={multiActions}
      emptyContent={empty}
    />
  );
};

export default SecretsList;
