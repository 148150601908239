import t from '../intl';
import Confirm from './confirm';

export default Confirm.extend({
  constructorName: 'MiniAppDeprecationAlert',
  focusSelector: 'button.cancel',
  additionalClass: 'error',
  persistent: true,

  text: t('mini_app.deprecation.alert.text'),
  title: t('mini_app.deprecation.alert.title'),

  confirmAlert(e) {
    e.preventDefault();
    this.closeModal();
  },

  postRender() {
    this._super('postRender');
    this.$('button.submit').addClass('hidden');
  },
});
