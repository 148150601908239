/* eslint-disable react/jsx-props-no-spreading */
import { Icon, Button } from 'semantic-ui-react';
import { t } from 'i18n-js';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import ButtonGroupWrapper from '../../../../components/buttons/ButtonGroupWrapper';
import RequestVirtualMachineModal from './modals/RequestVirtualMachineModal';
import Can from '../../../../permissions';

/**
 * Forwards all props through to button while providing defaults appropriate for
 * styling a button for requesting a virtual machine.
 *
 * @param isNarrow
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const RequestVirtualMachineButton = ({ isNarrow, ...props }) => (
  <Button title={isNarrow ? t('virtual_machines.actions.request') : null} {...props}>
    <Icon name="desktop" data-testid="virtual-machines-request-icon" className={isNarrow ? 'margin-none' : null} />
    {!isNarrow && t('virtual_machines.actions.request')}
  </Button>
);

const VirtualMachinesHeaderButtons = ({ trigger }) => (
  <ButtonGroupWrapper>
    <Can I="Request" a="VirtualMachine">
      <ModalWrapper trigger={trigger}>
        <RequestVirtualMachineModal />
      </ModalWrapper>
    </Can>
  </ButtonGroupWrapper>
);

export default VirtualMachinesHeaderButtons;
