/* eslint-disable no-param-reassign */
// the client is inherently stateful and it is useful to put some of the persistent state
// on the client object.
import { useEffect, useRef, useState } from 'react';
import createClient from './guacamole/client';
import { createPasteFn } from './guacamole/messages';
import { additionalDataFn, observeSize } from './utils';
import { VM } from './constants';
import { useVmStatusQuery } from './service';

export const useGuacamoleClient = ({ config }) => {
  const desktopId = config.args[4].slice(0, -10);
  const os = config.args[4].slice(-9, -6);
  const connection = config.args.length > 5 ? config.args[5] : null;

  const name = window.chorus.session.user().get('fullName');
  const connectionType = connection === 'vnc' ? '&type=Linux-vnc' : '';

  const [client] = useState(() => createClient(additionalDataFn(desktopId, name, connectionType), createPasteFn(os)));
  client.vmName = desktopId;
  return client;
};

export const useClientState = (client) => {
  const [state, setState] = useState(null);

  useEffect(() => {
    client.updateOverlayMessage = setState;
    return () => { client.updateOverlayMessage = null; };
  }, [client, setState]);

  return state;
};

export const useRefForClientDisplay = (client) => {
  const ref = useRef(null);
  useEffect(() => {
    ref.current.appendChild(client.getDisplay().getElement());
    client.authorisedConnect(); // promise result is thrown away
    return () => {
      client.safeDisconnect();
    };
  }, [client]);

  return ref;
};

export const useObserveSizeRef = (onResize) => {
  const ref = useRef(null);
  useEffect(() => observeSize(ref.current, onResize), [ref, onResize]);
  return ref;
};

export const useVmStatus = (client) => useVmStatusQuery(client.vmName).data || VM.FETCHING;
