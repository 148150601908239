import { memo } from 'react';
import { FullHeightDisplay, VMDisplay, VMPaste } from '../components';
import FullscreenButton from '../../../../layout/components/Fullscreen';
import { useGuacamoleClient, useObserveSizeRef, useRefForClientDisplay } from '../hooks';
import Overlay from './Overlay';

/**
 * A check to see if the browser supports the clipboard api necessary for paste integration
 *
 * @returns {boolean}
 */
const clipboardSupported = Boolean(window.navigator?.clipboard?.readText);

/**
 * Component for triggering paste, passes through onClick
 * @param props
 * @returns {false|JSX.Element}
 * @constructor
 */
const Paste = clipboardSupported ? (props) => VMPaste(props) : () => false;

/**
 * Component for holding the display element of Guacamole
 * @param client
 * @returns {JSX.Element}
 * @constructor
 */
const Display = memo(({ client }) => {
  const ref = useRefForClientDisplay(client);
  return VMDisplay(ref);
});

/**
 * Component for containing all of the other components and monitoring how much space is
 * available for them. Will trigger onResize when the space available changes.
 *
 * @param onResize
 * @param fullScreenButton
 * @param pasteButton
 * @param display
 * @param overlay
 * @returns {JSX.Element}
 * @constructor
 */
export const Panel = ({
  onResize, fullScreenButton, pasteButton, display, overlay,
}) => {
  const ref = useObserveSizeRef(onResize);
  return FullHeightDisplay({
    fullScreenButton, pasteButton, display, overlay,
  }, ref);
};

/**
 * Top level component for the Remote Desktop display, configures the guacamole client and then creates
 * the rest of the components.
 *
 * @param tab
 * @param workspace
 * @returns {JSX.Element}
 * @constructor
 */
export const VirtualMachinePanel = ({ tab }) => {
  const client = useGuacamoleClient(tab);

  return Panel({
    onResize: client.onResize,
    fullScreenButton: FullscreenButton(),
    pasteButton: Paste({ onClick: client.onPaste }),
    display: <Display client={client} />,
    overlay: <Overlay client={client} />,
  });
};
