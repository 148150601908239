export const types = {
  SET_TABLE_NAME_FROM_SCRATCH: 'SET_TABLE_NAME_FROM_SCRATCH',
  SET_TABLE_NAME_FROM_FILE: 'SET_TABLE_NAME_FROM_FILE',
  SET_TABLE_CONTENT_FROM_CSV: 'SET_TABLE_CONTENT_FROM_CSV',
  REMOVE_CSV: 'REMOVE_CSV',
  SET_METADATA_CONTENT_FROM_TDF: 'SET_METADATA_CONTENT_FROM_TDF',
  REMOVE_METADATA_TDF: 'REMOVE_METADATA_TDF',
  SET_METADATA_TITLE: 'SET_METADATA_TITLE',
  SET_METADATA_DESCRIPTION: 'SET_METADATA_DESCRIPTION',
  SET_METADATA_SOURCE: 'SET_METADATA_SOURCE',
  REMOVE_FROM_SCRATCH_COLUMN: 'REMOVE_FROM_SCRATCH_COLUMN',
  ADD_FROM_SCRATCH_COLUMN: 'ADD_FROM_SCRATCH_COLUMN',
  SET_COLUMN_PROPERTY: 'SET_COLUMN_PROPERTY',
  SET_TABLE_COLUMNS_FROM_TYPEGUESSING: 'SET_TABLE_COLUMNS_FROM_TYPEGUESSING',
  SET_TYPEGUESSING_IN_PROGRESS: 'SET_TYPEGUESSING_IN_PROGRESS',
  SET_TABLE_COLUMNS_WITH_FIELD: 'SET_TABLE_COLUMNS_WITH_FIELD',
  SET_TABLE_COLUMNS_UPDATED: 'SET_TABLE_COLUMNS_UPDATED',
  SET_METADATA_ATTRIBUTES: 'SET_METADATA_ATTRIBUTES',
  ADD_PRIMARY_KEY: 'ADD_PRIMARY_KEY',
  SET_PRIMARY_KEY: 'SET_PRIMARY_KEY',
  SET_CREATION_COMPLETE: 'SET_CREATION_COMPLETE',
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  DECREMENT_STEP: 'DECREMENT_STEP',
  INCREMENT_STEP: 'INCREMENT_STEP',
};

export const setTableNameFromScratch = (
  tableName,
) => ({ type: types.SET_TABLE_NAME_FROM_SCRATCH, tableName });

export const setTableNameFromFile = (
  tableNameFromFile,
) => ({ type: types.SET_TABLE_NAME_FROM_FILE, tableNameFromFile });

export const setTableContentFromCSV = (
  selectedCSVFile,
  newContent,
) => ({ type: types.SET_TABLE_CONTENT_FROM_CSV, contentFromCSV: { selectedCSVFile, newContent } });

export const removeCSV = { type: types.REMOVE_CSV };

export const setMetadataContentFromTDF = (
  selectedTDFFile,
  response,
  tdfIsLinked = false,
) => {
  const newMetadata = response.content?.DatasetDefinition || null;
  const fileSize = response.file_size;
  return (
    {
      type: types.SET_METADATA_CONTENT_FROM_TDF,
      contentFromTDF: {
        selectedTDFFile,
        newMetadata,
        fileSize,
        tdfIsLinked,
      },
    }
  );
};

export const setMetadataContentFromLinkedTDF = (response) => {
  const selectedTDFFile = {
    fileId: response.id,
    filePath: `${response.folder}/${response.file_name}`,
    fileName: response.file_name,
  };
  const newMetadata = response.version_info.content.DatasetDefinition;
  const fileSize = response.version_info.file_size;
  const tdfIsLinked = true;
  return (
    {
      type: types.SET_METADATA_CONTENT_FROM_TDF,
      contentFromTDF: {
        selectedTDFFile,
        newMetadata,
        fileSize,
        tdfIsLinked,
      },
    }
  );
};

export const removeMetadataTDF = { type: types.REMOVE_METADATA_TDF };

export const setMetadataTitle = (
  metadataTitle,
) => ({ type: types.SET_METADATA_TITLE, metadataTitle });

export const setMetadataDescription = (
  metadataDescription,
) => ({ type: types.SET_METADATA_DESCRIPTION, metadataDescription });

export const setMetadataSource = (
  metadataSource,
) => ({ type: types.SET_METADATA_SOURCE, metadataSource });

export const removeFromScratchColumn = (
  columnId,
) => ({ type: types.REMOVE_FROM_SCRATCH_COLUMN, columnId });

export const addFromScratchColumn = { type: types.ADD_FROM_SCRATCH_COLUMN };

export const setColumnProperty = (
  id,
  value,
  propertyToChange,
) => ({ type: types.SET_COLUMN_PROPERTY, updatedColumn: { domId: id, value, propertyToChange } });

export const setTableColumnsFromTypeGuessing = (
  updatedColumns,
  typeWarnings,
  guessedColumns,
) => ({
  type: types.SET_TABLE_COLUMNS_FROM_TYPEGUESSING,
  columnsData: {
    updatedColumns,
    typeWarnings,
    guessedColumns,
  },
});

export const setTypeguessinginProgress = (
  inProgress,
) => ({ type: types.SET_TYPEGUESSING_IN_PROGRESS, inProgress });

export const setMetadataAttributes = (
  propertyToChange,
  value,
) => ({ type: types.SET_METADATA_ATTRIBUTES, metadataData: { propertyToChange, value } });

export const addPrimaryKey = { type: types.ADD_PRIMARY_KEY };
export const setPrimaryKey = { type: types.SET_PRIMARY_KEY };

export const setCreationComplete = (
  datasetId,
) => ({ type: types.SET_CREATION_COMPLETE, datasetId });

export const setCurrentStep = (
  currentStep,
) => ({ type: types.SET_CURRENT_STEP, currentStep });

export const decrementStep = { type: types.DECREMENT_STEP };

export const incrementStep = { type: types.INCREMENT_STEP };
