import $ from '../../jquery';
import t from '../../intl';
import PageItemList from '../core/page_item_list_view';
import workfile from './search_workfile_view';
import dataset from './search_dataset_view';
import SearchWorkspace from './search_workspace_view';
import attachment from './search_attachment_view';
import { searchWorkspace } from '../../utilities/workspace_assoc';
import View from '../loading_view';
import Routing from '../../mixins/routing';

const SearchHandlers = {
  workfile,
  linked_tableau_workfile: workfile,
  dataset,
  chorusView: dataset,
  workspace: SearchWorkspace,
  attachment,
};

const buildForType = entityType => SearchHandlers[entityType];

export default View.include(Routing).extend({
  constructorName: 'SearchResultList',
  templateName: 'search_result_list',

  events: {
    'click a.show_all': 'showAll',
  },

  subviews: {
    '.list': 'list',
  },

  setup() {
    this.search = this.options.search;
    this.entityType = this.options.entityType;
    this.selectedModels = this.options.selectedModels;
    this.list = this.buildList();
  },

  buildList() {
    return new PageItemList({
      collection: this.collection,
      EntityViewType: buildForType(this.options.entityType),
      listItemOptions: {
        workspaceIdForTagLink: this.options.search.get('workspaceId'),
      },
      selectedModels: this.selectedModels,
      multiListMode: true,
    });
  },

  additionalContext() {
    return {
      entityType: this.entityType,
      shown: this.collection.models.length,
      total: this.collection.pagination.records,
      hideHeaders: this.search && this.search.isPaginated() && !searchWorkspace(this.search),
      moreResults: (this.collection.models.length < this.collection.pagination.records),
      title: this.title(),
    };
  },

  title() {
    return t(`search.type.${this.entityType}`);
  },

  showAll(e) {
    e.preventDefault();
    this.search.set({ entityType: $(e.currentTarget).data('type') });
    delete this.search.attributes.workspaceId;
    this.navigate(this.search.showUrl());
  },
});
