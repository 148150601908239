import { t } from 'i18n-js';
import { Button, Icon } from 'semantic-ui-react';
import { toggleFullscreen } from '../layoutState';

const PanelFullscreen = () => (
  <Button
    title={t('layout.fullscreen.accessibility')}
    className="aridhia-tab-fullscreen-button margin-none"
    icon
    compact
    size="small"
    onClick={toggleFullscreen}
  >
    <Icon name="expand" />
  </Button>
);

export default PanelFullscreen;
