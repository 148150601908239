import { t } from 'i18n-js';
import { Table } from 'semantic-ui-react';
import { useDefaultComponents, useTableHeader } from './DefaultComponents';
import { useMultiSelect } from './MultiSelect';
import { combineHooks } from '../../../utilities/helpers';

export const TableTemplate = ({
  tableData,
  columns,
  tableHeader,
  columnsHeader,
  renderBodyRow,
  emptyWrapper,
  stats,
  pagination,
}) => (
  <Table className="aridhia-fixed-header-and-footer" unstackable compact sortable selectable={tableData.length > 0}>
    <Table.Header aria-label={t('table.accessibility.table_header')}>
      {tableHeader}
      {columnsHeader}
    </Table.Header>
    <Table.Body aria-label={t('table.accessibility.table_body')}>
      {tableData.map((data, index) => Table.Row.create(renderBodyRow(data, index)))}
      {emptyWrapper}
    </Table.Body>
    {(stats || pagination) && (
      <Table.Footer aria-label={t('table.accessibility.table_footer')}>
        <Table.Row>
          <Table.HeaderCell colSpan={columns.length}>
            {stats}
            {pagination}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    )}
  </Table>
);

const useApplyQuery = (props) => props.useQuery(props);
const QueryTable = combineHooks(useApplyQuery, useMultiSelect, useDefaultComponents, useTableHeader, TableTemplate);

export default QueryTable;
