import { WOPI_HOST, supportedFileTypes } from './constants';

export const sanitiseFolderPath = (folder) => folder.split('?')[0].replace(/\/+$/, '');

export const calculateFileNameExtension = (fileName) => {
  const parts = fileName.split('.');
  const fileExtension = parts.length > 1
    ? parts[parts.length - 1].trim().toLowerCase() : null;
  return fileExtension;
};

export const calculateSupportedFileType = (fileName, fileType) => {
  if (fileType === 'folder') {
    return 'folder';
  } if (Object.values(supportedFileTypes).includes(fileType)) {
    return fileType;
  }

  const fileExtension = calculateFileNameExtension(fileName);
  if (Object.values(supportedFileTypes).includes(fileExtension)) {
    return fileExtension;
  }
  return supportedFileTypes.OTH;
};

const isText = (fileType) => ['sql', 'text', 'code', 'xml'].includes(fileType);

const isMarkdown = (fileName) => calculateFileNameExtension(fileName) === 'md';

export const isSQL = (fileType) => fileType === 'sql';

const isImage = (fileType) => fileType === 'image';

export const isR = (fileName) => calculateFileNameExtension(fileName) === 'r';

export const isFolder = (fileType) => fileType === 'folder';

export const isCSV = (fileName) => calculateFileNameExtension(fileName) === 'csv';

export const isZip = (fileName) => ['zip', 'tar', 'tgz'].includes(calculateFileNameExtension(fileName));

export const isBlob = (filePath) => filePath.split('/')[0] === 'blobs';

export const isFileFolder = (filePath) => filePath.split('/')[0] === 'files';

export const enableOpenAsText = (fileType, fileName) => (
  !isFolder(fileType) && !isImage(fileType) && !isZip(fileName) && (!isText(fileType) || isMarkdown(fileName))
);

export const isInbox = (folder) => folder.startsWith('inbox');

export const constructOfficeDocumentUrl = (urlSrc, fileId) => {
  const url = new URL(urlSrc);
  url.searchParams.append('WOPISrc', `http://${WOPI_HOST}/wopi/files/${fileId}`);
  return url;
};
