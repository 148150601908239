export const instances = Object.freeze({
  WORKSPACE: 'Workspace',
  AUDIT: 'WorkspaceAudit',
  STORAGE: 'WorkspaceStorage',
  INBOX: 'WorkspaceInboundAirlock',
  DATABASE: 'WorkspaceDatabase',
  CONTAINERISED_APPS: 'ContainerisedApp',
  SHINY_APPS: 'WorkspaceShinyApp',
  AIRLOCK: 'WorkspaceAirlock',
  WORKSPACE_EXPORT: 'WorkspaceExport',
  VIRTUAL_MACHINE: 'VirtualMachine',
  SECRET: 'WorkspaceSecret',
});

export const actions = Object.freeze({
  READ: 'Read',
  WRITE: 'Write',
  CREATE: 'Create',
  UPLOAD: 'Upload to',
  REVIEW: 'Review',
  VIEW: 'View',
  WRITE_META: 'WriteMeta',
  WRITE_MEMBERS: 'WriteMembers',
  CREATE_TOKENS: 'Create tokens for',
  INTERACTIVE: 'Interactive',
  EXPORT: 'Export',
  WRITE_META_OR_MEMBERS: 'write meta or members',
  REQUEST: 'Request',
});
