/**
 * This file is for functions that send content to the remote desktop
 * mostly pasting, a little bit of resize.
 */
import Guacamole from 'guacamole-common-js';
import { sleep } from '../utils';

const { StringWriter } = Guacamole;

export const sendCtrlV = (client) => async () => {
  client.sendKeyEvent(1, 65507); // Ctrl
  client.sendKeyEvent(1, 118); // v
  await sleep(100);
  client.sendKeyEvent(0, 118);
  client.sendKeyEvent(0, 65507);
};

export const sendMiddleClick = (client, mouse) => async () => {
  const { currentState: newState } = mouse;
  newState.middle = true;
  client.sendMouseState(newState);
  await sleep(100);
  newState.middle = false;
  client.sendMouseState(newState);
};

export const createPasteFn = (os) => (client, mouse) => {
  const sendPasteEvent = os.toLowerCase() === 'win' ? sendCtrlV(client) : sendMiddleClick(client, mouse);

  return async () => {
    const text = await window.navigator.clipboard.readText();
    const stream = client.createClipboardStream('text/plain');
    const writer = new StringWriter(stream);
    writer.sendText(text);
    writer.sendEnd();
    await sleep(500);
    await sendPasteEvent();
  };
};

export const resize = (client) => ({
  resendSize: () => { client.sendSize(client.previousWidth, client.previousHeight); },

  onResize: (resizeEvent) => {
    if (resizeEvent[0].target.parentElement.style.display === 'none') {
      // Don't send a guacamole resize event when switching tab
      return;
    }

    const size = resizeEvent[0].contentRect;
    const actualW = client.getDisplay().getWidth();
    const actualH = client.getDisplay().getHeight();

    let desiredW = Math.max(Math.round(size.width), 640);
    let desiredH = Math.max(Math.round(size.height), 480);

    // if we are already the correct size (or very close to it with regards to width) then we can skip the resize
    // Windows in particular can perform badly when asked to resize a lot
    const widthRange = [desiredW - 1, desiredW, desiredW + 1];
    if (desiredH === actualH && widthRange.includes(actualW)) {
      return;
    }

    // This is a workaround for the client not always updating correctly when a resize event is sent, including
    // this tiny amount of jitter 'fixes' the issue
    if (desiredH === client.previousHeight) {
      desiredH += 1;
    }
    if (desiredW === client.previousWidth) {
      desiredW += 1;
    }

    /* eslint-disable no-param-reassign */
    client.previousWidth = desiredW;
    client.previousHeight = desiredH;

    client.sendSize(desiredW, desiredH);
  },
});
