export const WOPI_HOST = 'workspaces-wopi-host';

export const folderNames = /^(files|blobs|inbox.*|public inbox.*)$/;

export const supportedFileTypes = Object.freeze({
  SQL: 'sql',
  CSV: 'csv',
  R: 'r',
  RNW: 'rnw',
  IPYNB: 'ipynb',
  TXT: 'txt',
  OTH: 'oth',
});

export const orderTypes = Object.freeze({
  FILE_NAME: 'file_name',
  UPDATED_AT: 'updated_at',
});

export const orderDirections = Object.freeze({
  ASC: 'asc',
  DESC: 'desc',
});
