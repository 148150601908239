import { t } from 'i18n-js';
import { Label } from 'semantic-ui-react';
import { useDatabaseTableContext } from '../../../Context';
import { isTable } from '../../../helper';

const DatabaseTableStats = () => {
  const { table, tableType } = useDatabaseTableContext();

  // Status of the conversion from csv to datatable (null: Not converted, 1: in progress, 2: complete)
  return (
    <>
      {!!table.columns && (
        <Label>
          {t('database_table.description.columns')}
          <Label.Detail>
            {table.columns?.toLocaleString()}
          </Label.Detail>
        </Label>
      )}
      {(isTable(tableType) && (!!table.rows || table.import_status === null || table.import_status === 2)) && (
        <Label>
          {t('database_table.description.rows')}
          <Label.Detail>
            {table.rows?.toLocaleString()}
          </Label.Detail>
        </Label>
      )}
      {!!table.size && (
        <Label>
          {t('database_table.description.size')}
          <Label.Detail>
            {table.size}
          </Label.Detail>
        </Label>
      )}
    </>
  );
};

export default DatabaseTableStats;
