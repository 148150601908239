import { t } from 'i18n-js';
import { Segment, Step } from 'semantic-ui-react';
import { useDatabaseTableCreationContext } from '../Context';
import { steps } from '../constants';
import { setCurrentStep } from '../actions';

const DatabaseTableCreationSteps = () => {
  const { newDatabaseTableState, dispatch } = useDatabaseTableCreationContext();

  return (
    <Segment>
      <Step.Group
        as="nav"
        size="tiny"
        unstackable
        fluid
      >
        <Step
          role="link"
          active={newDatabaseTableState.current_step === steps.FIRST}
          disabled={newDatabaseTableState.current_step === steps.LAST}
          onClick={() => dispatch(setCurrentStep(steps.FIRST))}
          completed={newDatabaseTableState.current_step > steps.FIRST}
          icon="table"
          title={t('database_tables_new.steps.step1')}
        />
        <Step
          role="link"
          active={newDatabaseTableState.current_step === steps.SECOND}
          disabled={
            newDatabaseTableState.current_step < steps.SECOND || newDatabaseTableState.current_step === steps.LAST
          }
          onClick={() => dispatch(setCurrentStep(steps.SECOND))}
          completed={newDatabaseTableState.current_step > steps.SECOND}
          icon="tag"
          title={t('database_tables_new.steps.step2')}
        />
        <Step
          role="link"
          active={newDatabaseTableState.current_step === steps.THIRD}
          disabled={
            newDatabaseTableState.current_step < steps.THIRD || newDatabaseTableState.current_step === steps.LAST
          }
          onClick={() => dispatch(setCurrentStep(steps.THIRD))}
          completed={newDatabaseTableState.current_step > steps.THIRD}
          icon="columns"
          title={t('database_tables_new.steps.step3')}
        />
        <Step
          role="link"
          active={newDatabaseTableState.current_step === steps.FOURTH}
          disabled={newDatabaseTableState.current_step !== steps.FOURTH}
          completed={newDatabaseTableState.current_step > steps.FOURTH}
          icon="thumbs up"
          title={t('database_tables_new.steps.step4')}
        />
        <Step
          role="link"
          active={newDatabaseTableState.current_step === steps.LAST}
          disabled={newDatabaseTableState.current_step < steps.LAST}
          icon="info circle"
          title={t('database_tables_new.steps.step5')}
        />
      </Step.Group>
    </Segment>
  );
};

export default DatabaseTableCreationSteps;
