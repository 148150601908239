import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import DataTabDatasetList from './data_tab_dataset_list_view';
import DatabaseSidebarList from './database_sidebar_list_view';

export default DatabaseSidebarList.extend({
  constructorName: 'DataTabView',
  templateName: 'data_tab',
  useLoadingSection: true,

  subviews: {
    '.list_contents': 'listview',
  },

  setup(...args) {
    this._super('setup', args);
    this.collection = this.schema.datasets();
    this.collection.fetchIfNotLoaded({ success: _.bind(this.render, this) });
    this.listview = new DataTabDatasetList({ collection: this.collection });
    this.listenTo(this.listview, 'fetch:more', this.fetchMoreDatasets);
  },

  postRender(...args) {
    this._super('postRender', args);

    chorus.search({
      list: this.$('ul'),
      input: this.$('input.search'),
      onTextChange: _.debounce(_.bind(this.searchTextChanged, this), 400),
    });
  },

  additionalContext(...args) {
    const ctx = this._super('additionalContext', args);
    ctx.isWorkspaceSchema = true;
    return ctx;
  },

  fetchMoreDatasets(e) {
    e && e.preventDefault();
    const next = parseInt(this.collection.pagination.page, 10) + 1;
    this.collection.fetchPage(next, { reset: false, remove: false, success: _.bind(this.render, this) });
  },

  searchTextChanged(e) {
    this.collection.search($(e.target).val());
  },

  displayLoadingSection() {
    return this.schema && !(this.collection && (this.collection.loaded || this.collection.serverErrors));
  },
});
