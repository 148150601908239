import { t } from 'i18n-js';

export const findMember = (members, author) => (members.find((member) => member.uuid === author?.uuid));

const errorMsgs = {
  DatasetExportFailed: 'databaseTable',
};

const systemMsgs = {
  DatasetExportSuccess: 'databaseTable',
  DatasetExportCreated: 'databaseTable',
  ViewCreated: 'databaseTable',
  ChorusViewCreated: 'databaseTable',
  NoteOnDatasetImport: 'databaseTable',
  WorkfileCreated: 'file',
  WorkfileDeleted: 'file',
  FileExtractSuccess: 'file',
};

const activityMsgs = Object.assign(systemMsgs, errorMsgs);

const generateDynamicContent = (activity, type) => {
  if (activityMsgs[type] === 'databaseTable') {
    const source = activity.source_dataset?.object_name || activity.source_object?.object_name;
    return t(`activities.dynamic_content.${type}`, {
      databaseTable: activity.dataset.object_name || source,
      source: source || '',
      saveType: activity.export_type,
      saveName: activity.file_name,
    });
  }
  return type;
};

export const createMessage = (activity) => {
  // activity types can be defined with an action or action_type
  const type = activity.action in activityMsgs ? activity.action : activity.action_type;
  // Most notes and comments have bodies otherwise we have to create them
  const body = activity.body || generateDynamicContent(activity, type);
  return {
    body,
    isErrorMsg: type in errorMsgs,
    isSystemMsg: type in systemMsgs,
  };
};
