import NotFound from './NotFound';
import LoadingError from './LoadingError';

const ContentLoadingError = ({ errorCode }) => {
  switch (errorCode) {
    case (404):
      return (<NotFound />);
    default:
      return (<LoadingError />);
  }
};

export default ContentLoadingError;
