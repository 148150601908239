import $ from '../jquery';
import Model from './models';

export default Model.extend({
  constructorName: 'DatasetMeta',
  urlTemplate: 'api/workspaces/datasets/{{datasetId}}/metadata',
  nestParams: false,

  defaults: {
    copiedFromDatasetId: null,
    copiedFromWorkfileId: null,
    readyToUpload: false,
  },

  TABLE_PATHS: {
    title: ['Title'],
    url: ['Url'],
    description: ['Description'],
    catalogueUuid: ['CatalogueIdentifier', 'Uuid'],
    catalogueHref: ['CatalogueIdentifier', 'Href'],
  },

  COLS_PATH: ['Columns', 'Column'],
  COL_KEY: ['Name'],
  COL_ATTRS: {
    description: ['Description'],
    name: ['Name'],
    label: ['Label'],
    type: ['Type'],
    deidentify: ['Deidentify'],
  },

  setReadyToUpload() {
    this.set('readyToUpload', !!this.get('title'));
  },

  loadContent(data) {
    let { content } = data.response;
    if (content) {
      const keys = Object.keys(content);
      if (keys.length) {
        content = content[keys[0]];
      }
    }

    $.each(this.TABLE_PATHS, (name, path) => {
      this.set(name, this.lookup(content, path));
    });
    this.loadColumns(content);
  },

  loadColumns(content) {
    const cols = {};
    if (content) {
      const columns = this.lookup(content, this.COLS_PATH);
      if (columns) {
        columns.forEach((column) => {
          const key = this.lookup(column, this.COL_KEY);
          cols[key] = this.loadColumn(column);
        });
      }
    }
    this.set('columns', cols);
  },

  loadColumn(column) {
    const attrs = {};

    $.each(this.COL_ATTRS, (name, path) => {
      attrs[name] = this.lookup(column, path);
    });

    return attrs;
  },

  lookup(tree, path) {
    let found = tree;
    for (let i = 0; i < path.length; i += 1) {
      if (!found) {
        break;
      }
      const part = path[i];
      found = found[part];
    }
    return found;
  },

  parse(data, ...args) {
    if (data) {
      this.loadContent(data);
      const attrs = this._super('parse', [data, ...args]);
      return attrs;
    }
  },

  prepare(columnSet, dataset, selectedDatasetMeta = this, mapByColumnPosition = false) {
    const content = selectedDatasetMeta.get('content');
    const datasetDefinition = content ? content.DatasetDefinition : {};
    const title = datasetDefinition.Title ? datasetDefinition.Title : dataset.get('objectName');
    const description = datasetDefinition.Description ? datasetDefinition.Description : '';
    const url = datasetDefinition.Url ? datasetDefinition.Url : '';
    const columns = [];
    const metaColumns = datasetDefinition.Columns ? datasetDefinition.Columns.Column : [];
    for (let i = 0; i < columnSet.length; i++) {
      columns[i] = {
        name: columnSet.models[i].get('name'),
        example: columnSet.models[i].get('example'),
        type: columnSet.models[i].get('dataType'),
        label: '',
        description: '',
      };
      if (mapByColumnPosition) {
        if (metaColumns[i]) {
          columns[i].label = metaColumns[i].Label;
          columns[i].description = metaColumns[i].Description;
        }
      } else if (metaColumns) {
        for (let j = 0; j < metaColumns.length; j++) {
          if (metaColumns[j].Name === columnSet.models[i].get('name')) {
            columns[i].label = metaColumns[j].Label;
            columns[i].description = metaColumns[j].Description;
          }
        }
      }
    }

    this.set({
      title,
      url,
      description,
      columns,
    });
    this.setReadyToUpload();
    columnSet.loaded = true;
    columnSet.trigger('loaded');
  },

  updateTitle(value) {
    this.set('title', value, { silent: true });
    this.setReadyToUpload();
    this.trigger('change:informationTitle');
  },

  updateUrl(value) {
    this.set('url', value, { silent: true });
    this.trigger('change:informationUrl');
  },

  updateDescription(value) {
    this.set('description', value, { silent: true });
    this.trigger('change:informationDescription');
  },

  updateColumnLabel(target, value) {
    const columns = this.get('columns').slice();
    const column = +target.replace(/[^0-9]/g, '');
    if (columns[column]) {
      columns[column] = Object.assign(columns[column], { label: value });
    }
    this.set('columns', columns);
    this.trigger('change:columns');
  },

  updateColumnDescription(target, value) {
    const columns = this.get('columns').slice();
    const column = +target.replace(/[^0-9]/g, '');
    if (columns[column]) {
      columns[column] = Object.assign(columns[column], { description: value });
    }
    this.set('columns', columns);
    this.trigger('change:columns');
  },

});
