import $ from '../../jquery';
import WorkspaceShowSidebar from './workspace_show_sidebar';
import IFrame from '../core/iframe_view';
import View from '../loading_view';
import { FEATURE } from '../../../utilities/features';

export default View.extend({
  templateName: 'tab_content',
  bindCallbacks: $.noop,
  subviews: {
    '.app-content': 'mainContent',
    '.app-sidebarBasic': 'sidebar',
  },

  setup() {
    this.sidebar = new WorkspaceShowSidebar({ model: this.model });
    this.mainContent = new IFrame({ url: 'about:blank', content: this.model.homepageContent(this.loadFailed.bind(this)) });
    this.mainContent.additionalClass = 'simple_main_content';
  },

  loadFailed() {
    if (FEATURE.NEW_UX) {
      this.templateName = 'new_ux_not_found';
      this.render();
    }
  },
});
