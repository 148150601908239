import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import SiteTrackingConsentToast from '../../../new_ux/components/sitetracking/SiteTrackingConsentToast';
import View from '../react_view';
import { FEATURE } from '../../../utilities/features';

export default View.extend({
  constructorName: 'toastView',

  renderOnce() {
    if (FEATURE.SITE_TRACKING_ENABLED && Cookies.get('workspacesTrackingConsent') === undefined) {
      toast(<SiteTrackingConsentToast />, {
        hideProgressBar: true,
        position: 'bottom-center',
        draggable: false,
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        toastId: 'SiteTrackingConsentToast',
      });
    }

    this.root.render(
      <ToastContainer />,
    );
  }
});
