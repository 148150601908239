import chorus from '../chorus';
import t from '../intl';
import { getManagementWorkspace } from '../models/management/workspace';
import MainContentView from '../views/core/main_content_view';
import WorkspaceManagementManage from '../views/workspaces/management/manage';
import WorkspaceManagementSidebar from '../views/workspaces/management/sidebar';
import ListHeaderView from '../views/core/list_header_view';
import BasePage from './base_page';
import Services from '../collections/services';
import _ from '../underscore';

export default BasePage.extend({
  setup(uuid) {
    if (!chorus.session.user().isTenantAdmin()) {
      window.location.href = '#/forbidden';
    } else {
      this.model = getManagementWorkspace(uuid);
      this.model.fetch({ success: _.bind(this.buildContent, this) });
    }
  },

  buildContent() {
    this.services = new Services(this.model.services());
    this.admins = this.model.users(['Owner', 'Administrator']);
    const content = new WorkspaceManagementManage({
      model: this.model,
      services: this.services,
      admins: this.admins,
      editing: true,
    });

    this.mainContent = new MainContentView({
      model: this.model,
      content,
      contentHeader: new ListHeaderView(),
    });

    this.sidebar = new WorkspaceManagementSidebar({
      services: this.services,
      pageTitle: t('workspaces.admin.workspace_edit.header'),
    });

    this.render({ teardownSubViews: true });
  },
});
