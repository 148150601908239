import { useState } from 'react';
import {
  Modal, Header, Icon, Message,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import ModalActionButtons from '../../../../../components/buttons/ModalActionButtons';
import HeaderError from '../../../../../components/errors/HeaderError';
import { useRename } from '../../../service';
import toastMessage from '../../../../../utilities/toast';
import ModalWrapper from '../../../../../components/modals/ModalWrapper';
import BaseFolderSelection from '../../../../../components/navigation/BaseFolderSelection';
import NewFolderSubmodal, { NewFolderSubmodalButton }
  from '../../../../../components/modals/NewFolderSubmodal';
import { baseFolders } from '../../../../../components/navigation/constants';
import FileNavigation from '../../../../../components/navigation/FileNavigation';
import { useInboxFileContext } from '../../Context';

const InboxApproveModal = ({ setModalClose, setIsBusy }) => {
  const {
    name,
    displayName,
    id,
    type,
  } = useInboxFileContext();
  const [destinationFolder, setDestinationFolder] = useState('files');

  const headerContent = type === 'folder'
    ? t('file_list.actions.approve_folder', { folderName: displayName })
    : t('file_list.actions.approve_file', { fileName: displayName });
  const isValidEntry = !!destinationFolder;

  const {
    renameFile,
    isLoading,
    isError,
    error,
    resetResponse,
  } = useRename(id);

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleSubmitting = () => {
    setIsBusy(true);
    const fileData = {
      workfile: {
        id,
        file_name: name,
        folder: destinationFolder,
        file_type: type,
      },
    };

    renameFile(fileData, {
      onSuccess: () => {
        handleModalClose();
        toastMessage(
          'success',
          t('file_list.actions.approve_success', { fileName: displayName, destinationFolder }),
          'approveSuccess',
        );
      },
      onSettled: () => {
        setIsBusy(false);
      },
    });
  };

  const handleSetFolder = (activeFolder) => {
    setDestinationFolder(activeFolder);
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="thumbs up" title={t('file_list.actions.approve')} />
        <Header.Content content={headerContent} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content scrolling>
        <Message
          icon="folder"
          info
          content={t('file_list.actions.approve_info')}
        />
        <BaseFolderSelection
          baseFolders={[baseFolders.FILES, baseFolders.BLOBS]}
          folder={destinationFolder}
          handleSetFolder={handleSetFolder}
        />
        <FileNavigation
          currentFolder={destinationFolder}
          handleSetFolder={handleSetFolder}
        />
      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmitting}
        isValidEntry={isValidEntry}
        error={isError}
        submitText={t('file_list.actions.approve')}
      >
        <ModalWrapper
          size="tiny"
          trigger={NewFolderSubmodalButton}
        >
          <NewFolderSubmodal
            destinationFolder={destinationFolder}
            handleSetFolder={handleSetFolder}
          />
        </ModalWrapper>
      </ModalActionButtons>
    </>
  );
};

export default InboxApproveModal;
