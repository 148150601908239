import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import TurndownService from 'turndown';
import './MarkdownRender.less';

const allowedElements = [
  'br',
  'p',
  'em',
  'strong',
  'hr',
  'blockquote',
  'a',
  'ol',
  'ul',
  'li',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'code',
  'pre',
  'table',
  'thead',
  'tbody',
  'tr',
  'td',
  'th',
  'img',
];

const MarkdownRender = ({ value, className = '', isRichText }) => {
  let markdownContent = value;
  if (isRichText) {
    const turndownService = new TurndownService();
    markdownContent = turndownService.turndown(value);
  }

  return (
    <ReactMarkdown
      linkTarget="_blank"
      skipHtml
      allowedElements={allowedElements}
      remarkPlugins={[remarkGfm]}
      className={`aridhia-markdown-render ${className}`}
    >
      {markdownContent}
    </ReactMarkdown>
  );
};

export default MarkdownRender;
