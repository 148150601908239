import View from '../base_view';
import $ from '../../jquery';
import _ from "../../underscore";
import chorus from "../../chorus";

export default View.extend({
  constructorName: 'AttachmentView',
  templateName: 'read_only_text_content',

  setup() {
    this.additionalClass = 'simple_main_content';
    this.model.fetchIfNotLoaded();
    this.onceLoaded(this.model, this.onLoadModel.bind(this));
  },

  onLoadModel() {
    const a = this.attachment();
    $.ajax({ url: a.get('contentUrl'), dataType: 'text' }).done(this.onLoadContent.bind(this));
  },

  onLoadContent(data, status) {
    const a = this.attachment();
    this.options.tab.set('label', a.get('name'));
    this.content = data;
    this.render();
  },

  attachment() {
    return this.model.attachments()[0];
  },

  additionalContext() {
    return {
      additionalClass: 'app-scrollWrapper',
      content: this.content,
    };
  },
  
});
