import t from '../intl';
import ModelDelete from './model_delete_alert';
import Workfile from '../models/workfile';
import WorkfileSet from '../collections/workfile_set';
import { folderUrl } from '../utilities/file';

export default ModelDelete.extend({
  constructorName: 'InboundAirlockRejectAlert',

  text: t('inbound_airlock.reject.file.text'),
  ok: t('inbound_airlock.reject.file.button'),
  deleteMessage: 'inbound_airlock.reject.file.toast',

  bindCallbacks() {
    this.model.bind('destroy', this.modelDeleted, this);
    this.model.bind('destroyFailed', this.destroyFailed, this);
  },

  makeModel() {
    this.model = this.model || new Workfile({
      id: this.options.workfileId,
      fileName: this.options.workfileName,
      folder: this.options.folderName,
      fileType: this.options.workfileFolder ? 'folder' : '',
      workspace: { id: this.options.workspaceId },
    });
  },

  setup() {
    if (this.model instanceof WorkfileSet) {
      this.title = t('inbound_airlock.reject.selection.title');
      this.deleteMessage = 'inbound_airlock.reject.selection.toast';
      this.text = t('inbound_airlock.reject.selection.text');
      this.ok = t('inbound_airlock.reject.selection.button');
    } else if (this.model.isFolder()) {
      this.title = t('inbound_airlock.reject.folder.title', { workfolderTitle: this.model.getFileName() });
      this.deleteMessage = 'inbound_airlock.reject.folder.toast';
      this.text = t('inbound_airlock.reject.folder.text');
      this.ok = t('inbound_airlock.reject.folder.button');
    } else {
      this.title = t('inbound_airlock.reject.file.title', { workfileTitle: this.model.getFileName() });
    }
  },

  deleteModel(e) {
    e.preventDefault();
    this.model.destroy();
    this.$('button.submit').startLoading('actions.deleting');
  },

  modelDeleted() {
    chorus.toast(this.deleteMessage, this.deleteMessageParams());
    chorus.PageEvents.trigger('workfiles:deselectAll');
    setTimeout(() => {
      chorus.PageEvents.trigger(`workfile:deleted`, this.model);
      this.closeModal();
    }, 1000);
  },

  destroyFailed() {
    this.$('button.submit').hide();
    if (this.model instanceof WorkfileSet) {
      this.resource.serverErrors = { fields: { base: { UNREJECTABLE_FILE: {} } } };
    } else if (this.model.isFolder()) {
      this.resource.serverErrors = { fields: { base: { UNREJECTABLE_FOLDER: {} } } };
    } else {
      this.resource.serverErrors = { fields: { base: { UNREJECTABLE_FILE: {} } } };
    }
    this.showErrors();
  },
});
