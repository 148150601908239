import {
  memo, cloneElement, useMemo, useState,
} from 'react';
import { Modal } from 'semantic-ui-react';
import { useModalState } from '../../utilities/hooks';

const ModalWrapper = memo(({
  children,
  className = '',
  size = 'tiny',
  trigger,
}) => {
  const [isBusy, setIsBusy] = useState(false);
  const [open, handleOpen, handleClose] = useModalState();

  const child = useMemo(
    () => (cloneElement(children, { setModalClose: handleClose, setIsBusy })),
    [handleClose, setIsBusy, children],
  );

  const mountNode = document.querySelector('#modal-wrapper') || document.body;

  const isOpen = open || isBusy;

  return (
    <Modal
      as="dialog"
      mountNode={mountNode}
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
      size={size}
      trigger={trigger}
      data-testid="modal-wrapper"
      className={className}
    >
      {child}
    </Modal>
  );
});

export default ModalWrapper;
