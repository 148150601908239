/* eslint-disable object-curly-newline,no-underscore-dangle */
import { useEffect, useState } from 'react';
import _ from 'underscore';

export const useClientSort = ({ tableData, columns }) => {
  const [value, onSortBy] = useState(null);
  const isSorting = value != null && value[1] != null;

  if (!isSorting) {
    return {
      onSortBy, tableData, columns,
    };
  }
  const [sortingBy, direction] = value;

  let sortedData = _.sortBy(tableData, (row) => row[sortingBy]);
  if (direction === 'descending') {
    sortedData = sortedData.reverse();
  }

  return {
    tableData: sortedData,
    onSortBy,
    columns: columns.map((c) => (c.name === sortingBy ? { ...c, direction } : c)),
    fetchMoreIfPossible: true,
  };
};

export const createClientFilter = (filterFn) => ({ tableData, total }) => {
  const [value, onFilter] = useState('');

  if (value === '') {
    return { onFilter, shown: total };
  }

  const filteredData = tableData.filter(filterFn(value));
  const filteredTotal = filteredData.length;

  return {
    tableData: filteredData,
    shown: filteredTotal,
    onFilter,
    fetchMoreIfPossible: tableData.length !== total,
  };
};

const nameFilterFn = (value) => (x) => x.name.includes(value);

export const useNameFilter = createClientFilter(nameFilterFn);

export const provideClientPaging = (clientPageSize) => ({ tableData, total, fetchMoreIfPossible }) => {
  const [activePage, onPageChange] = useState(1);
  const startIndex = clientPageSize * (activePage - 1);
  const maxPage = Math.max(1, Math.ceil(total / clientPageSize));
  const jumpToLastPage = activePage > maxPage;

  useEffect(
    () => {
      if (jumpToLastPage) {
        onPageChange(maxPage);
      }
    },
    [onPageChange, jumpToLastPage, maxPage],
  );

  if (total < clientPageSize) {
    return ({ tableData, total });
  }

  const needsMore = tableData && ((startIndex + clientPageSize) > tableData.length);
  const pagedData = tableData && tableData.slice(startIndex, startIndex + clientPageSize);

  return {
    tableData: pagedData,
    activePage,
    onPageChange,
    pageSize: clientPageSize,
    total,
    fetchMoreIfPossible: fetchMoreIfPossible || needsMore,
  };
};

export const useFetchMore = ({ fetchMoreIfPossible, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage }) => {
  // if there are more pages available from the server that we need for paging or sorting
  useEffect(
    () => {
      if (!isFetching && !isFetchingNextPage && hasNextPage && fetchMoreIfPossible) {
        fetchNextPage();
      }
    },
    [isFetching, isFetchingNextPage, hasNextPage, fetchNextPage, fetchMoreIfPossible],
  );

  return ({});
};

export const useTableDataFromInfiniteQuery = ({ data }) => {
  const { pages } = data || {};
  const { records } = (pages && pages[pages.length - 1]?.pagination) || { records: 0 };
  const tableData = records ? pages.flatMap((res) => res.data) : [];
  return { tableData, total: records };
};

export const useTableDataFromQuery = ({ data }) => {
  const tableData = data?.response || [];
  const total = tableData.length;

  return { tableData, total };
};
