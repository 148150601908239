import { t } from 'i18n-js';
import { DisconnectedMessage, OverlayLoadingMessage } from '../components';
import {
  STATE_CONNECTED,
  STATE_CONNECTING,
  STATE_DISCONNECTED,
  STATE_DISCONNECTING,
  STATE_IDLE,
  STATE_WAITING,
} from '../constants';
import { useClientState, useVmStatus } from '../hooks';

const Disconnected = ({ client }) => {
  const vmStatus = useVmStatus(client);
  return DisconnectedMessage({ vmStatus, reconnect: client.authorisedReconnect });
};

const createMessageFromGuacamoleState = (state, client) => {
  switch (state) {
    case STATE_IDLE:
    case STATE_CONNECTING:
    case STATE_DISCONNECTING:
    case STATE_WAITING:
      return OverlayLoadingMessage({ message: t(`desktop.state.${state}`) });
    case STATE_DISCONNECTED:
      return <Disconnected client={client} />;
    case STATE_CONNECTED:
    default:
      return null;
  }
};

/**
 * Component for providing feedback to the user in an overlay over the display element
 *
 * @param client
 * @returns {JSX.Element}
 * @constructor
 */
const Overlay = ({ client }) => {
  const state = useClientState(client);

  return createMessageFromGuacamoleState(state, client);
};

export default Overlay;
