import View from '../../loading_view';

export default View.extend({
  templateName: 'mini_apps/app_notification',

  additionalContext() {
    return {
      notificationMessage: this.notificationMessage,
      notificationType: this.notificationType,
      notificationId: this.cid,
    };
  },

  setup(options) {
    this._super('setup', options);
    this.notificationMessage = options.notificationMessage;
    this.notificationType = options.notificationType;
  },
});
