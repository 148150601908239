import React from 'react';
import { Radio, Input } from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';

function includesDelimiter(delimiter) {
  return [',', '\t', ';', ' '].includes(delimiter);
}

const DelimiterForm = ({
  delimiter, customDelimiter, handleDelimiterChange, handleCustomDelimiterChange, triggerErrorCustomDelimiterChange,
}) => (
  <>
    <div role="radiogroup" aria-labelledby="delimeter">
      <Radio
        label={t('dataset.import.delimiter.comma')}
        id="comma"
        name="delimiter"
        value=","
        checked={delimiter === ','}
        onChange={handleDelimiterChange}
        role="radio"
        aria-checked={delimiter === ',' ? 'true' : 'false'}
      />
      <Radio
        label={t('dataset.import.delimiter.tab')}
        id="tab"
        name="delimiter"
        value="&#09;"
        checked={delimiter === '\t'}
        onChange={handleDelimiterChange}
        role="radio"
        aria-checked={delimiter === '\t' ? 'true' : 'false'}
      />
      <Radio
        label={t('dataset.import.delimiter.semicolon')}
        id="semicolon"
        name="delimiter"
        value=";"
        checked={delimiter === ';'}
        onChange={handleDelimiterChange}
        role="radio"
        aria-checked={delimiter === ';' ? 'true' : 'false'}
      />
      <Radio
        label={t('dataset.import.delimiter.space')}
        id="space"
        name="delimiter"
        value=" "
        checked={delimiter === ' '}
        onChange={handleDelimiterChange}
        role="radio"
        aria-checked={delimiter === ' ' ? 'true' : 'false'}
      />
      <Radio
        label={t('dataset.import.delimiter.other')}
        id="other"
        name="delimiter"
        value="other"
        checked={!includesDelimiter(delimiter)}
        onChange={handleDelimiterChange}
        className="inline"
        role="radio"
        aria-checked={delimiter === 'other' ? 'true' : 'false'}
      />
    </div>
    <label htmlFor="custom_delimiter" className="offscreen">{t('dataset.import.delimiter.custom_delimiter')}</label>
    <Input
      id="custom_delimiter"
      defaultValue={customDelimiter}
      onBlur={handleCustomDelimiterChange}
      onChange={triggerErrorCustomDelimiterChange}
      className="custom-delimiter"
    />
  </>
);

const mapModelsToProps = ({ datasetUpload }) => ({
  delimiter: datasetUpload.get('delimiter'),
  customDelimiter: datasetUpload.get('custom_delimiter'),
  handleDelimiterChange: (_e) => {
    datasetUpload.updateDelimiter(_e.target.value);
    if (includesDelimiter(_e.target.value)) {
      datasetUpload.updateCustomDelimiter('');
    }
  },
  handleCustomDelimiterChange: (_e) => {
    datasetUpload.updateDelimiter(_e.target.value);
    datasetUpload.updateCustomDelimiter(_e.target.value);
  },
  triggerErrorCustomDelimiterChange: (_e) => {
    if (_e.target.value.length <= 1) {
      datasetUpload.updateDelimiter(_e.target.value);
      datasetUpload.updateCustomDelimiter(_e.target.value);
    }
  },
});

const options = {
  debounce: true,
  events: {
    datasetUpload: ['change:delimiter', 'change:custom_delimiter'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(DelimiterForm);
