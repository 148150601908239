import React from 'react';
import { t } from 'i18n-js';
import {
  Button, Icon, Input, Table,
} from 'semantic-ui-react';

function EnvVarTableRow({
  entry, onUpdate, onRemove, developer,
}) {
  const handleChange = (_, data) => {
    onUpdate({ [data.name]: data.value });
  };

  return (
    <Table.Row>
      <Table.Cell>
        <Input
          disabled
          fluid
          transparent
          defaultValue={entry.name}
          onChange={handleChange}
          name="name"
          className="opaque"
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          disabled={!developer}
          fluid
          defaultValue={entry.value}
          onChange={handleChange}
          name="value"
        />
      </Table.Cell>
      <Table.Cell collapsing textAlign="right">
        { developer && (
          <Button
            onClick={onRemove}
            compact
            basic
            icon
            floated="right"
          >
            <Icon
              name="minus"
              color="blue"
              alt={t('app.form.vars.remove')}
            />
          </Button>
        )}
      </Table.Cell>
    </Table.Row>
  );
}

export default EnvVarTableRow;
