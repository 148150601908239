import { Dropdown } from 'semantic-ui-react';
import { t } from 'i18n-js';
import NewSQLFileLauncher from '../../components/ribbon/ribbon-launchers/NewSQLFileLauncher';
import { workspaceDatabaseUrl, newDatabaseTablePanel } from '../../constants/ui_urls';
import { workspaceId } from '../../layout/helper';
import Can from '../../permissions';

const DatabaseTablesLauncher = () => (
  <Dropdown.Item
    text={(t('workspaces.launcher.view_database_table'))}
    href={workspaceDatabaseUrl()}
    icon="list alternate"
  />
);

const NewDatabaseTableLauncher = () => (
  <Dropdown.Item
    text={t('workspaces.launcher.new_database_table')}
    href={newDatabaseTablePanel()}
    icon="table"
  />
);

const DatabaseLauncherMenu = () => (
  <Dropdown
    aria-label={t('workspaces.launcher.database')}
    icon={{ name: 'database', title: t('workspaces.launcher.database') }}
    text={t('workspaces.launcher.database')}
    item
  >
    <Dropdown.Menu>
      <Can I="Read" this="WorkspaceDatabase">
        <DatabaseTablesLauncher />
      </Can>
      <Can I="Write" this="WorkspaceDatabase">
        <NewDatabaseTableLauncher />
      </Can>
      <Can I="Write" this="WorkspaceStorage">
        <NewSQLFileLauncher />
      </Can>
      <Can I="Upload to" this="WorkspaceStorage">
        <Dropdown.Item
          text={(t('workspaces.launcher.upload_data'))}
          href={`#/workspaces/${workspaceId()}/upload/${+new Date()}/files`}
          icon="upload"
        />
      </Can>
    </Dropdown.Menu>
  </Dropdown>
);

export default DatabaseLauncherMenu;
