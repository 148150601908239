import chorus from '../../chorus';
import $ from '../../jquery';
import RemoveJoinConfirmAlert from '../../alerts/remove_join_confirm_alert';
import Sidebar from '../core/sidebar_view';
import ManageJoinTables from '../../dialogs/dataset/manage_join_tables_dialog';
import VerifyChorusView from '../../dialogs/dataset/verify_chorus_view_dialog';
import SqlPreview from '../../dialogs/dataset/sql_preview_dialog';
import ChorusView from '../../models/chorus_view';
import { schema, generateFromClause } from '../../utilities/schema_assoc';

export default Sidebar.extend({
  templateName: 'dataset_create_chorus_view_sidebar',

  events: {
    'click button.create': 'createChorusView',
    'click a.remove': 'removeColumnClicked',
    'click i.delete': 'removeJoinClicked',
    'click a.preview': 'previewSqlLinkClicked',
    'click a.add_join': 'addJoin',
  },

  setup() {
    this.listenTo(this.options.aggregateColumnSet, 'column:selected', this.addColumn);
    this.listenTo(this.options.aggregateColumnSet, 'column:deselected', this.removeColumn);
    this.chorusView = this.deriveChorusView();
    this.chorusView.aggregateColumnSet = this.options.aggregateColumnSet;
    this.listenTo(this.chorusView, 'change', this.render);
  },

  teardown() {
    this.options.aggregateColumnSet.each((column) => {
      const col = column;
      delete col.selected;
    });
    this._super('teardown');
  },

  postRender() {
    this.$('a.preview, button.create').data('parent', this);
    this._super('postRender');
  },

  additionalContext() {
    return {
      columns: this.chorusView.sourceObjectColumns,
      joins: this.chorusView.joins,
    };
  },

  deriveChorusView() {
    const chorusView = new ChorusView({
      sourceObjectId: this.model.id,
      sourceObjectType: 'dataset',
      workspace: this.model.get('workspace'),
      objectName: this.model.name(),
    });
    chorusView.setSchema(schema(this.model));
    chorusView.sourceObject = this.model;
    return chorusView;
  },

  addColumn(column) {
    this.chorusView.addColumn(column);
    this.$('button.create').prop('disabled', false);
  },

  removeColumn(column) {
    if (!column) {
      return;
    }
    this.chorusView.removeColumn(column);
  },

  removeColumnClicked(e) {
    e.preventDefault();
    const $li = $(e.target).closest('li');
    const column = this.chorusView.aggregateColumnSet.get($li.data('cid'));
    this.removeColumn(column);
    chorus.PageEvents.trigger('column:removed', column);
  },

  addJoin(e) {
    e.preventDefault();
    const dialog = new ManageJoinTables({
      model: this.model,
      chorusView: this.chorusView,
      schema: schema(this.model),
    });
    dialog.launchModal();
  },

  removeJoinClicked(e) {
    const cid = $(e.target).closest('div.join').data('cid');
    const dialog = new RemoveJoinConfirmAlert({
      dataset: this.chorusView.getJoinDatasetByCid(cid),
      chorusView: this.chorusView,
    });
    dialog.launchModal();
  },

  previewSqlLinkClicked(e) {
    e.preventDefault();
    this.chorusView.set({ query: this.sql() });
    const dialog = new SqlPreview({ model: this.chorusView });
    dialog.launchModal();
  },

  createChorusView(e) {
    e && e.preventDefault();
    this.chorusView.set({ query: this.sql() });
    const dialog = new VerifyChorusView({ model: this.chorusView });
    dialog.launchModal();
  },

  whereClause() {
    return this.filters.whereClause();
  },

  sql() {
    return [
      this.chorusView.generateSelectClause(),
      generateFromClause(this.chorusView),
      this.whereClause(),
    ].join('\n');
  },
});
