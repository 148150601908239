import $ from '../jquery';
import t from '../intl';
import WorkspaceSummary from '../views/workspaces/workspace_summary_view';
import WorkspaceHome from '../views/workspaces/workspace_home_view';
import Desktops from '../views/desktops/desktops';
import MiniAppShowView from '../views/mini_apps/app/mini_app';
import MiniAppsShowView from '../views/mini_apps/apps/mini_apps';
import ContainerisedAppsShowView from '../views/containerised_apps/containerised_apps_view';
import ChorusViewShowView from '../views/dataset/chorus_view_show_view';
import DatasetShowView from '../views/dataset/dataset_show_view';
import DatasetsShow from '../views/dataset/datasets_show_view';
import DataTableEditor from '../views/workspaces/data_table_editor_view';
import AirlockNewView from '../views/airlock/airlock_new_view';
import SqlResultView from '../views/workfiles/sql_results_view';
import WorkfilesShow from '../views/workfiles/workfiles_show_view';
import WorkfileShowView from '../views/workfiles/workfile_show_view';
import InboundAirlockShowView from '../views/inbound_airlock/inbound_airlock_show_view';
import UploadTokenShowView from '../views/upload_token/upload_token_show_view';
import DatasetUploadView from '../views/dataset/dataset_upload_show_view';
import DatasetMetadataView from '../views/dataset/dataset_metadata_show_view';
import NotFound from '../views/core/not_found';
import Tab from './layout/tab';
import WorkfileFolder from './workfile_folder';
import { instance } from './config';
import RTools from '../views/rtools/rtools_view';
import { sqlResults } from '../collections/task_set';
import Activity from './activity';
import AttachmentView from '../views/activities/attachment_view';
import ContainerisedAppCreateView from '../views/containerised_apps/containerised_app_create_view';
import ContainerisedAppView from '../views/containerised_apps/containerised_app_view';
import FeaturesView from '../views/features/features_show_view';
import { FEATURE } from '../../utilities/features';

export const datatableTabConfiguration = (workspace, entityID, viewID) => {
  switch (entityID) {
    case 'file':
      return {
        itemModel: workspace.getWorkfile(viewID),
        resourceName: null,
        viewID: `file_dte${viewID}`,
      };
    case 'dataset':
      return {
        itemModel: workspace.getDataset(viewID),
        resourceName: null,
        viewID: `dataset_dte${viewID}`,
      };
    default:
      return {
        itemModel: null,
        resourceName: viewID,
        viewID: `new_dte${viewID}`,
      };
  }
};

const WorkspaceRouter = function build(page, model) {
  this.page = page;
  this.model = model;
  this.defaultApp = null;
};

WorkspaceRouter.prototype = {
  constructor: WorkspaceRouter,

  listenTo(model, action, fun) {
    this.page.listenTo(model, action, fun);
  },

  defaultTabs() {
    const tabs = [];
    if (this.model.hasHomepage()) {
      tabs.push(this.homeTab());
    }
    tabs.push(this.summaryTab());

    if (this.model.hasPermission('WorkspaceShinyApp.Read')) {
      tabs.push(this.miniAppsListTab());
    }

    tabs.push(this.containerisedAppsListTab());
    tabs.push(this.datasetsTab());
    tabs.push(this.workfilesTab());

    if (this.model.hasPermission('Workspace.Interactive') && !FEATURE.NEW_UX) {
      tabs.push(this.rConsoleTab());
    }

    if (!FEATURE.NEW_UX) {
      tabs.push(this.spacerTab());
    }

    return tabs;
  },

  getTab(args) {
    const url = this.getUrl(args);

    return new Tab(this.getTabParams(url, args));
  },

  getUrl(args) {
    let url = `#/workspaces/${encodeURIComponent(args[0])}`;
    let i = 1;

    while (i < args.length) {
      url += `/${encodeURIComponent(args[i])}`;
      i += 1;
    }
    return url;
  },

  getTabParams(url, args) {
    if (args.length <= 2) {
      if (!this.model.hasPermission('Workspace.Interactive') && args[1] === 'rtools') {
        return this.notFoundTab(url);
      }

      if (args.length === 1) {
        if (this.model.hasHomepage()) {
          args.push('home');
        } else {
          args.push('summary');
        }
      }
      // #/workspaces/:workspaceId
      // #/workspaces/:workspaceId/:listName
      switch (args[1]) {
        case 'home':
          return this.model.hasHomepage() ? this.homeTab() : this.notFoundTab(url);
        case 'summary':
          return this.summaryTab();
        case 'desktops':
          return this.desktopsTab();
        case 'rtools':
          return this.rConsoleTab();
        case 'airlock_new':
          return this.airlockNewTab();
        case 'documents':
        case 'scripts':
          args.splice(1, 0, 'folders');
          return this.foldersTab(url, args);
        case 'upload_token':
          return this.uploadTokenTab();
        case 'app':
          return this.appTab(url);
        case 'features':
          return this.featuresTab();
        default:
          return this.notFoundTab(url); // pinned tabs that can't be deleted
      }
    } else {
      // #/workspaces/:workspaceId/:objectType/:objectId
      // #/workspaces/:workspaceId/:objectType/:objectId/version/:versionId
      if (!this.model.hasPermission('WorkspaceShinyApp.Read') && args[1] === 'mini_app') {
        return this.notFoundTab(url);
      }

      if (args[1] === 'dataset_upload' && !args[3]) {
        return this.notFoundTab(url);
      }

      switch (args[1]) {
        case 'folders':
          return this.foldersTab(url, args);
        case 'activity':
          return this.attachmentTab(url, args);
        case 'workfiles':
          return this.workfileTab(url, args);
        case 'upload':
          return this.inboundAirlockTab(url, args);
        case 'chorus_views':
          return this.chorusViewTab(url, args);
        case 'datasets':
          return this.datasetTab(url, args);
        case 'datatable':
          return this.datatableTab(url, args);
        case 'sqlresults':
          return this.resultsTab(url, args);
        case 'mini_app':
          return this.miniAppTab(url, args);
        case 'app':
          return this.appRunTab(url, args);
        case 'rtools':
          return this.rConsoleTabWithUrl(url, args[2]);
        case 'dataset_upload':
          return this.datasetUploadTab(url, args);
        case 'dataset_metadata':
          return this.datasetMetadataTab(url, args);
        default:
          return this.notFoundTab(url);
      }
    }
  },

  summaryTab() {
    return {
      icon: 'comments',
      name: 'Summary',
      pinnedTab: true,
      icontext: 'rsh.tabs.tooltip.summary',
      url: `#/workspaces/${this.model.id}/summary`,
      viewFun: function buildTab(tab) {
        this.listenTo(this.model.activities(), 'refresh', () => {
          tab.set('dirty', true);
        });

        return new WorkspaceSummary({ model: this.model });
      }.bind(this),
      hidden: FEATURE.NEW_UX,
    };
  },

  homeTab() {
    return {
      icon: 'home',
      name: 'Home',
      location: 0,
      pinnedTab: true,
      icontext: 'rsh.tabs.tooltip.home',
      url: `#/workspaces/${this.model.id}/home`,
      viewFun: function buildTab(tab) {
        this.page.subscribePageEvent('homepage:changed', () => {
          tab.set('dirty', true);
        });

        return new WorkspaceHome({ model: this.model });
      }.bind(this),
      hidden: FEATURE.NEW_UX,
    };
  },

  desktopsTab() {
    const location = this.model.hasHomepage() ? 2 : 1;

    return {
      icon: 'desktop',
      name: 'Desktops',
      pinnedTab: true,
      location,
      icontext: 'rsh.tabs.tooltip.desktops',
      url: `#/workspaces/${this.model.id}/desktops`,
      viewFun: function buildTab() {
        const desktops = this.model.desktops();
        return new Desktops({ workspace: this.model, model: desktops, collection: desktops });
      }.bind(this),
      hidden: FEATURE.NEW_UX,
    };
  },

  miniAppsListTab() {
    return {
      icon: 'caret-square-o-right',
      name: 'Mini-apps',
      pinnedTab: true,
      icontext: 'rsh.tabs.tooltip.mini_apps',
      url: `#/workspaces/${this.model.id}/mini_apps`,
      viewFun: tab => (new MiniAppsShowView({
        model: this.model,
        defaultApp: this.defaultApp,
        tabModel: tab,
      })),
      hidden: FEATURE.NEW_UX,
    };
  },

  containerisedAppsListTab() {
    return {
      icon: 'rocket',
      name: 'Containerised-apps',
      pinnedTab: true,
      icontext: 'rsh.tabs.tooltip.containerised_apps',
      url: `#/workspaces/${this.model.id}/apps`,
      no_sidebar: true,
      viewFun: tab => (new ContainerisedAppsShowView({
        model: this.model,
        defaultApp: this.defaultApp,
        tabModel: tab,
      })),
      hidden: FEATURE.NEW_UX,
    };
  },

  miniAppTab(url, args) {
    return {
      url,
      viewFun: function buildTab(tab) {
        return new MiniAppShowView({
          workspace: this.model,
          tab,
          selectedApp: args[2],
        });
      }.bind(this),
      fileType: 'mini_app',
      view_model: 0,
      label: t('mini_app.tab.title'),
      closeable: true,
      workspaceID: args[0],
      miniAppID: parseInt(args[2], 10),
      onUrlChange(view) {
        view.changeModel(args[2]);
      },
    };
  },

  appRunTab(url, args) {
    return {
      url,
      viewFun: function buildTab(tab) {
        return new ContainerisedAppView({
          workspace: this.model,
          selectedApp: args[2],
          tab,
        });
      }.bind(this),
      fileType: 'app',
      view_model: `containerised_app${args[2]}`,
      label: args[2],
      closeable: true,
      workspaceID: args[0],
      appID: parseInt(args[2], 10),
      no_sidebar: true,
    };
  },

  datasetsTab() {
    return {
      icon: 'table',
      name: 'Datasets',
      icontext: 'rsh.tabs.tooltip.datasets',
      pinnedTab: true,
      url: `#/workspaces/${this.model.id}/datasets`,
      viewFun: function buildTab(tab) {
        this.listenTo(this.model.datasets(), 'refresh', () => {
          tab.set('dirty', true);
        });
        return new DatasetsShow({ model: this.model });
      }.bind(this),
      hidden: FEATURE.NEW_UX,
    };
  },

  datasetTab(url, args) {
    const dataset = this.model.getDataset(args[2]);
    return {
      url,
      closeable: true,
      view_model: `dataset${dataset.id}`,
      viewFun: function buildTab(tab) {
        return new DatasetShowView({
          workspace: this.model,
          dataset,
          tab,
        });
      }.bind(this),
      fileType: 'dataset',
    };
  },

  datatableTab(url, args) {
    const config = instance;
    if (config.dataTableEditorEnabled()) {
      const tabConfiguration = datatableTabConfiguration(this.model, args[2], args[3]);
      if (tabConfiguration.itemModel && tabConfiguration.itemModel.get('entityType') === 'workfile') {
        tabConfiguration.itemModel.loaded = false;
      }
      return {
        url,
        onUrlChange(view) {
          view.showAnalyse(args[4] === 'analyse');
        },
        closeable: true,
        no_sidebar: true,
        view_model: tabConfiguration.viewID,
        viewFun: function buildTab(tab) {
          return new DataTableEditor({
            tab,
            workspace: this.model,
            itemModel: tabConfiguration.itemModel,
            resourceName: tabConfiguration.resourceName,
            type: args[2],
            analyse: args[4] === 'analyse',
            disableSandbox: true,
          });
        }.bind(this),
        fileType: 'dataset',
      };
    }
    return this.notFoundTab(url);
  },

  chorusViewTab(url, args) {
    const dataset = this.model.getDataset(args[2], 'ChorusView');
    return {
      url,
      closeable: true,
      view_model: `dataset${dataset.id}`,
      viewFun: function buildTab(tab) {
        return new ChorusViewShowView({
          workspace: this.model,
          dataset,
          tab,
        });
      }.bind(this),
      fileType: 'dataset',
    };
  },

  resultsTab(url, args) {
    const result = sqlResults.findWhere({ checkId: args[2] });
    return {
      url,
      closeable: true,
      viewFun: function buildTab(tab) {
        return new SqlResultView({
          workspace: this.model,
          model: result,
          tab,
        });
      }.bind(this),
      label: `Results (${sqlResults.indexOf(result) + 1})`,
      fileType: 'results',
    };
  },

  workfilesTab() {
    return {
      icon: 'file-text',
      name: 'Workfiles',
      icontext: 'rsh.tabs.tooltip.workfiles',
      type: 'workfiles',
      view_model: 'workfiles',
      pinnedTab: true,
      url: `#/workspaces/${this.model.id}/workfiles`,
      viewFun: function buildTab(tab) {
        this.listenTo(this.model.workfiles(), 'refresh', () => {
          tab.set('dirty', true);
        });

        const parts = tab.get('url').split('/');
        const folders = instance.get('topLevelFolders').split('|');
        let folder = folders[0];
        parts.forEach(function (item, index) {
          this[index] = decodeURIComponent(item);
        }, parts);
        if (parts.length >= 5 && parts[3] === 'folders') {
          folder = parts.slice(4, parts.length).join('/');
        } else if ($.inArray(parts[parts.length - 1], folders) >= 0) {
          folder = parts[parts.length - 1];
        }
        return new WorkfilesShow({
          model: this.model,
          folder: new WorkfileFolder({ folder, workspaceId: this.model.id }),
        });
      }.bind(this),
      hidden: FEATURE.NEW_UX,
    };
  },

  inboundAirlockTab(url, args) {
    const batchId = args.slice(2)[0];
    const folder = args.slice(3, args.length).join('/');
    const shorterFolder = folder.length > 16 ? `... ${folder.slice(folder.length - 13)}` : folder;
    return {
      url,
      no_sidebar: true,
      closeable: true,
      view_model: `upload${batchId}`,
      type: 'upload',
      label: t('inbound_airlock.upload_tab', { folder: shorterFolder }),
      title: t('inbound_airlock.upload_tab', { folder }),
      viewFun: function buildTab(tab) {
        return new InboundAirlockShowView({
          model: this.model, tab, folder, batchId,
        });
      }.bind(this),
    };
  },

  appTab(url) {
    return {
      url,
      no_sidebar: true,
      closeable: true,
      type: 'containerised_app_create',
      label: t('app.tab.create_new'),
      title: t('app.tab.create_new'),
      viewFun: function buildTab(tab) {
        return new ContainerisedAppCreateView({
          model: this.model,
          tab,
        });
      }.bind(this),
    };
  },

  uploadTokenTab() {
    return {
      url: `#/workspaces/${this.model.id}/upload_token`,
      no_sidebar: true,
      closeable: true,
      view_model: 'upload_token',
      type: 'upload_token',
      label: t('workfiles.header.menu.new.upload_api'),
      title: t('workfiles.header.menu.new.upload_api'),
      viewFun: function buildTab() {
        return new UploadTokenShowView({ model: this.model });
      }.bind(this),
    };
  },

  attachmentTab(url, args) {
    const attachment = new Activity({ id: args[2] });

    return {
      url,
      closeable: true,
      no_sidebar: true,
      type: 'attachment',
      view_model: `attachment${attachment.id}`,
      viewFun: tab => (new AttachmentView({ model: attachment, tab })),
    };
  },

  foldersTab(url, args) {
    const folder = new WorkfileFolder({
      folder: args.slice(2, args.length).join('/'),
      workspaceId: args[0],
    });

    return {
      type: 'workfiles',
      view_model: 'workfiles',
      url,
      onUrlChange(view) {
        view.setFolder(folder);
      },
    };
  },

  workfileTab(url, args) {
    const workfile = this.model.getWorkfile(args[2]);
    const version = args.length === 5 ? args[4] : null;

    return {
      url,
      closeable: true,
      type: 'workfile',
      file_type: 'workfile',
      view_model: `workfile${workfile.id}`,
      viewFun: function buildTab(tab) {
        return new WorkfileShowView({
          workspace: this.model,
          workfile,
          tab,
          versionId: version,
          action: args[3],
        });
      }.bind(this),
      onUrlChange(view) {
        view.setVersion(version);
      },
    };
  },

  rConsoleTab() {
    return this.rConsoleTabWithUrl(`#/workspaces/${this.model.id}/rtools`);
  },

  rConsoleTabWithUrl(url) {
    return {
      url,
      iconHTML: '<b>R</b>',
      name: 'RConsole',
      icontext: 'rsh.tabs.tooltip.rconsole',
      pinnedTab: true,
      closeable: true,
      view_model: 'r_console',
      viewFun: function buildTab(tab) {
        return new RTools({
          model: this.model,
          tab,
        });
      }.bind(this),
      onUrlChange(view) {
        view.performCommandWhenReady();
      },
    };
  },

  datasetUploadTab(url, args) {
    const workfile = this.model.getWorkfile(args[3]);
    return {
      url,
      closeable: true,
      view_model: `datasetupload${workfile.id}`,
      file_type: 'datasetupload',
      no_sidebar: true,
      viewFun: function buildTab(tab) {
        return new DatasetUploadView({
          workspace: this.model,
          workfile,
          tab,
        });
      }.bind(this),
    };
  },

  datasetMetadataTab(url, args) {
    const dataset = this.model.getDataset(args[2]);
    const columns = dataset.columns();
    return {
      url,
      closeable: true,
      view_model: `datasetmetadata${dataset.id}`,
      fileType: 'datasetmetadata',
      no_sidebar: true,
      viewFun: function buildTab(tab) {
        return new DatasetMetadataView({
          workspace: this.model,
          dataset,
          columns,
          tab,
        });
      }.bind(this),
    };
  },

  airlockNewTab() {
    return {
      iconHTML: t('airlock.tab_title'),
      name: t('airlock.tab_title'),
      icontext: 'rsh.tabs.tooltip.airlock',
      pinnedTab: true,
      url: `#/workspaces/${this.model.id}/airlock_new`,
      closeable: true,
      viewFun: function buildTab(tab) {
        return new AirlockNewView({ model: this.model, tab });
      }.bind(this),
    };
  },

  featuresTab() {
    return {
      iconHTML: t('features.tab_title'),
      name: t('features.tab_title'),
      pinnedTab: true,
      url: `#/workspaces/${this.model.id}/features`,
      closeable: true,
      no_sidebar: true,
      viewFun: function buildTab(tab) {
        return new FeaturesView();
      }.bind(this),
    };
  },

  notFoundTab(url) {
    return {
      url,
      closeable: true,
      viewFun(tab) {
        return new NotFound({
          tab,
        });
      },
    };
  },

  spacerTab() {
    return {
      spacer: true,
    };
  },
};

export default WorkspaceRouter;
