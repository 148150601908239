import _ from '../underscore';
import Model from './models';

export default Model.extend({
  constructorName: 'SchemaFunction',

  toText() {
    const functionArguments = this.getFunctionArguments();

    const schemaName = this.safePGName(this.get('schemaName'));
    const functionName = this.safePGName(this.get('name'));

    let result = `${schemaName}.${functionName}(`;
    result += functionArguments.join(', ');
    result = `${result})`;
    return result;
  },

  toHintText() {
    return `${this.get('returnType')} ${this.get('name')}${this.formattedArgumentList(true)}`;
  },

  getFunctionArguments() {
    const argNames = this.get('argNames') || [];
    return _.map(this.get('argTypes'), (argType, index) => {
      const argName = argNames[index] || `arg${index + 1}`;
      return `${argType} ${argName}`;
    });
  },

  formattedArgumentList(ensureParams) {
    const args = this.getFunctionArguments();
    if (ensureParams || args.length > 0) {
      return `(${args.join(', ')})`;
    }
    return '';
  },
});
