import { useState } from 'react';
import Backbone from 'backbone';
import ReactToBackboneWrapper from '../../ReactToBackboneWrapper';
import SqlResultView from '../../../../../application/views/workfiles/sql_results_view';
import { sqlResults } from '../../../../../application/collections/task_set';

const ResultsPanel = ({ tab, workspace }) => {
  const { component, config, id } = tab;

  const result = sqlResults.findWhere({ checkId: config.args[4] });

  const [view] = useState(() => new SqlResultView({
    workspace,
    model: result,
    tab: new Backbone.Model({ active: true }),
  }));

  return (
    <ReactToBackboneWrapper
      view={view}
      tabId={id}
      component={component}
    />
  );
};

export default ResultsPanel;
