import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import Tag from '../../models/tag';
import View from '../loading_view';
import TagItemManager from '../../utilities/tag_item_manager';

export default View.extend({
  templateName: 'tags_input',
  constructorName: 'TagsInputView',
  tags: [], // to make jshint happy

  setup() {
    this.tags = this.options.tags;
    this.taggable = this.options.taggable;

    this.listenTo(this.taggable, 'change:tags', this.render);
  },

  postRender() {
    this.$input = this.$('input');
    this.$input.hide();
    this.buildTextExt();
  },

  buildTextExt() {
    const tagsForTextext = this.tags.map(tag => ({ name: tag.name(), model: tag }));

    this.$input.textext({
      plugins: 'tags autocomplete ajax',
      tagsItems: tagsForTextext,
      itemManager: TagItemManager,
      keys: {
        9: 'tab',
        13: 'enter!',
        27: 'escape',
        37: 'left',
        38: 'up!',
        39: 'right',
        40: 'down!',
        46: 'delete',
        108: 'numpadEnter',
      },
      ajax: {
        url: 'api/workspaces/tags',
        dataType: 'json',
        existingTagCollection: this.tags,
      },
      autocomplete: {
        render(suggestion) {
          return suggestion.text;
        },
        dropdown: {
          maxHeight: '200px',
        },
      },
    });

    const textextInput = this.$input.textext()[0];
    this.textext = textextInput;

    $("<span class='app-toggleTagEditor rsh-text-tag'>" +
        "<span class='rsh-tag-btn'><span class='rsh-text-label'>" +
        "<i class='fa fa-plus'/><i class='app-btnToggleIndicator fa fa-caret-right'/>" +
        '</span></span></span>').insertBefore(this.$input);
  },

  events: {
    'setFormData input.tag_editor': 'updateTags',
    'isTagAllowed input.tag_editor': 'textExtValidate',
    'setInputData input.tag_editor': 'restoreInvalidTag',
    'focus input.tag_editor': 'resizeTextExt',
    'tagClick input.tag_editor': 'triggerTagClick',
    'click .app-toggleTagEditor': 'toggleInput',
  },

  toggleInput(e) {
    e && e.preventDefault();
    const $btnToggle = this.$('.app-btnToggleIndicator');
    if ($btnToggle.hasClass('fa-caret-right')) {
      this.$input.show().focus();
      $btnToggle.removeClass('fa-caret-right').addClass('fa-caret-down');
    } else if ($btnToggle.hasClass('fa-caret-down')) {
      this.$input.blur().hide();
      $btnToggle.removeClass('fa-caret-down').addClass('fa-caret-right');
    }
    chorus.page.trigger('resized');
  },

  triggerTagClick(e, tag, value) {
    this.trigger('tag:click', value.model);
  },

  resizeTextExt() {
    this.textext && this.textext.trigger('postInvalidate');
  },

  updateTags(e, tags) {
    if (tags.length > this.tags.length) {
      for (let i = this.tags.length; i < tags.length; ++i) {
        this.addTag(tags[i].model);
      }
    } else if (tags.length < this.tags.length) {
      this.removeMissingTag(tags);
    }
    this.focusInput();
  },

  addTag(newTag) {
    const duplicate = this.tags.find(tag => tag.matches(newTag.name()));
    if (duplicate) {
      this.tags.remove(duplicate, { silent: true });
    }

    this.tags.add(newTag);
    this.taggable.updateTags({ add: newTag });
    this.render();
  },

  removeMissingTag(tags) {
    const tagNames = _.pluck(tags, 'name');
    const missingTag = this.tags.find(tag => !_.contains(tagNames, tag.name()));
    this.tags.remove(missingTag);
    this.taggable.updateTags({ remove: missingTag });
  },

  textExtValidate(e, data) {
    const element = data;
    this.invalidTagName = '';
    element.tag.name = $.trim(element.tag.name);
    element.tag.model = new Tag({ name: element.tag.name });

    if (!this.validateTag(element.tag.model)) {
      element.result = false;

      if (this.keepInvalidTagName) {
        this.invalidTagName = element.tag.name;
        this.keepInvalidTagName = false;
      }
    }
  },

  validateTag(tag) {
    this.clearErrors();
    if (!tag.performValidation(tag.attributes)) {
      this.markInputAsInvalid(this.$input, tag.errors.name, false);
      this.keepInvalidTagName = true;
      return false;
    }
    return true;
  },

  restoreInvalidTag(e, tag) {
    this.$input.val(tag || this.invalidTagName);
    this.invalidTagName = '';
  },

  additionalContext() {
    return {
      tags: this.tags.models,
      hasTags: this.tags.length > 0,
    };
  },

  focusInput() {
    this.$input.focus();
  },
});
