import { Dropdown } from 'semantic-ui-react';
import { t } from 'i18n-js';
import { workspaceRToolsUrl, workspaceAppUrl, workspaceDesktopsUrl } from '../../constants/ui_urls';
import { useApps } from '../service';
import Can from '../../permissions';
import { appNames } from '../constants';

const ariaStatusLabel = (error, loading) => {
  if (error) return t('workspaces.launcher.not_loaded');
  if (loading) return t('workspaces.launcher.loading');
  return null;
};

const RConsoleLauncher = () => (
  <Dropdown.Item href={workspaceRToolsUrl()}>
    <i className="terminal-icon icon" data-testid="r_console_icon" />
    <span className="text">{(t('workspaces.launcher.new_r_console'))}</span>
  </Dropdown.Item>
);

const RStudioLauncher = ({ rStudioUrl, isLoading, isError }) => (
  <Dropdown.Item
    aria-label={ariaStatusLabel(isError, isLoading) || ''}
    title={ariaStatusLabel(isError, isLoading) || ''}
    className={`${isLoading || isError ? 'aridhia-disabled' : ''}`}
    href={rStudioUrl}
  >
    <i className="aridhia-r aridhia-icon circle icon" data-testid="r_studio_icon" />
    <span className="text">
      {(t('workspaces.launcher.open_r_studio'))}
    </span>
  </Dropdown.Item>
);

const JupyterNotebookLauncher = ({ jupyterNotebookUrl, isLoading, isError }) => (
  <Dropdown.Item
    aria-label={ariaStatusLabel(isError, isLoading) || ''}
    title={ariaStatusLabel(isError, isLoading) || ''}
    className={`${isLoading || isError ? 'aridhia-disabled' : ''}`}
    href={jupyterNotebookUrl}
  >
    <i className="aridhia-j aridhia-icon circle icon" data-testid="jupyter_icon" />
    <span className="text">
      {(t('workspaces.launcher.open_jupyter'))}
    </span>
  </Dropdown.Item>
);

const VMLauncher = () => (
  <Dropdown.Item
    text={(t('workspaces.launcher.virtual_machines'))}
    href={workspaceDesktopsUrl()}
    icon="desktop"
  />
);

// TODO: Add VM permission
const ToolsLauncherMenu = () => {
  const { apps, isError, isLoading } = useApps();

  const rStudio = apps?.find((app) => app.name === appNames.rStudio);
  const rStudioUrl = rStudio ? workspaceAppUrl(rStudio.id) : null;
  const jupyterNotebook = apps?.find((app) => app.name === appNames.jupyterNotebook);
  const jupyterNotebookUrl = jupyterNotebook ? workspaceAppUrl(jupyterNotebook.id) : null;

  return (
    <Dropdown
      aria-label={t('workspaces.launcher.tools')}
      icon={{ name: 'chart pie', title: t('workspaces.launcher.tools') }}
      text={t('workspaces.launcher.tools')}
      item
    >
      <Dropdown.Menu>
        <Can I="Read" a="ContainerisedApp">
          <RConsoleLauncher />
          {(isLoading || isError || rStudio) && (
            <RStudioLauncher
              rStudioUrl={rStudioUrl}
              isLoading={isLoading}
              isError={isError}
            />
          )}
          {(isLoading || isError || jupyterNotebook) && (
            <JupyterNotebookLauncher
              jupyterNotebookUrl={jupyterNotebookUrl}
              isError={isError}
              isLoading={isLoading}
            />
          )}
        </Can>
        <VMLauncher />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ToolsLauncherMenu;
