import ChartTask from './chart_task';

export default ChartTask.extend({
  chartType: 'timeseries',
  columnLabels: {
    time: 'dataset.visualization.timeseries.time',
    value: 'dataset.visualization.timeseries.value',
  },

  beforeSave() {
    this._super('beforeSave');
    this.set({
      'chart[xAxis]': this.get('xAxis'),
      'chart[yAxis]': this.get('yAxis'),
      'chart[aggregation]': this.get('aggregation'),
      'chart[timeInterval]': this.get('timeInterval'),
    });
  },
});

