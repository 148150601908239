import { useState, useEffect } from 'react';
import CopyWarningModal from './CopyWarningModal';
import { useNewAudit } from '../../audit/service';
import ConfirmWrapper from '../modals/ConfirmWrapper';
import { getCurrentTabComponent } from '../../layout/models/tab';

const copyWarningPanels = [
  'workfiles', // file
  'mini_app', // shiny app
  'datasets', // dataset preview
  'analyse', // analyse data
];

const createHandleCopy = (setCopyDetected, createAudit) => () => {
  const component = getCurrentTabComponent();

  if (copyWarningPanels.includes(component)) {
    createAudit({ resource: component });
    setCopyDetected(true);
  }
};

const createCopyListener = (handleCopy) => {
  // Needed for PDF viewer/ analyse data
  const iFrameListener = (e) => {
    const key = e.message ? 'message' : 'data';
    const data = e[key];
    if ((data === 'frameCopyEvent')) {
      handleCopy();
    }
  };

  window.addEventListener('message', iFrameListener, false);
  document.addEventListener('copy', handleCopy);
  document.addEventListener('cut', handleCopy);

  return () => {
    window.removeEventListener('message', iFrameListener, false);
    document.removeEventListener('copy', handleCopy);
    document.removeEventListener('cut', handleCopy);
  };
};

const useWarning = () => {
  const [warningIssued, setwarningIssued] = useState(sessionStorage.getItem('workspaceCopyWarningIssued'));

  const handleWarningIssued = () => {
    setwarningIssued(true);
    sessionStorage.setItem('workspaceCopyWarningIssued', true);
  };

  return [warningIssued, handleWarningIssued];
};

const CopyWarningMessage = () => {
  const [warningIssued, setWarningIssued] = useWarning();

  if (warningIssued) {
    return null;
  }

  return (
    <ConfirmWrapper handleCloseModal={setWarningIssued} closeOnDimmerClick={false}>
      <CopyWarningModal />
    </ConfirmWrapper>
  );
};

const CopyWarning = () => {
  const [copyDetected, setCopyDetected] = useState(false);
  const copyWarningEnabled = sessionStorage.getItem('copyWarningEnabled') === 'true';

  const createAudit = useNewAudit();

  useEffect(() => createCopyListener(createHandleCopy(setCopyDetected, createAudit)), [createAudit]);

  if (!copyWarningEnabled || !copyDetected) {
    return null;
  }

  return (
    <CopyWarningMessage />
  );
};

export default CopyWarning;
