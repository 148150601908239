import dynamicDataset from '../models/dynamic_dataset';
import LastFetchWins from './last_fetch_wins';
import { DataSourceCredentialsModel } from '../mixins/data_source_credentials';

export default LastFetchWins.include(DataSourceCredentialsModel).extend({
  constructorName: 'SchemaDatasetSet',
  urlTemplate: 'api/workspaces/schemas/{{schemaId}}/datasets',
  model(attrs, options) {
    return dynamicDataset(attrs, options);
  },

  urlParams() {
    if (this.attributes) {
      const paramsList = {};
      if (this.attributes.filter) {
        paramsList.filter = this.attributes.filter;
      }
      if (this.attributes.tablesOnly) {
        paramsList.tablesOnly = this.attributes.tablesOnly;
      }
      return paramsList;
    }
    return null;
  },

  search(term) {
    const self = this;
    self.attributes.filter = term;
    self.fetch({ silent: true, success() { self.trigger('searched'); } });
  },
});
