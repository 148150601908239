import chorus from '../../chorus';
import t from '../../intl';
import Dialog from '../../dialogs/dialogs';
import DialogFormHelpers from '../../mixins/dialog_form_helpers';

export default Dialog.include(DialogFormHelpers).extend({
  constructorName: 'RenameWorkfileDialog',
  templateName: 'rename_mini_app_dialog',

  title: t('mini_app.rename_dialog.title'),


  setup() {
    this.listenTo(this.model, 'saved', this.saved);
    this.listenTo(this.model, 'saveFailed', this.saveFailed);
    this.disableFormUnlessValid({
      formSelector: 'form',
      inputSelector: 'input',
    });
  },

  create() {
    const newName = this.$('input').val();
    this.oldName = this.model.name();
    this.model.set({ name: newName }, { silent: true });
    this.model.save();
    this.$('button.submit').startLoading('actions.renaming');
  },

  saved() {
    this.closeModal();
    chorus.PageEvents.trigger('change:miniAppName', this.model);
    this.model.getWorkfiles().forEach((workfile) => {
      chorus.PageEvents.trigger('change:fileName', workfile);
    });
  },

  saveFailed(e) {
    this.model.set({ name: this.oldName }, { silent: true });
    if (e.statusCode.toString() === '403') {
      this.displayForbiddenError();
      this.$('button.submit').hide();
    }
    this.$('button.submit').stopLoading();
  },

  displayForbiddenError() {
    this.resource.serverErrors = { fields: { base: { RENAME_FORBIDDEN: {} } } };
  },
});
