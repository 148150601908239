import React from 'react';
import { Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';

const FormValidFrom = ({ validFrom, handleValidFromChange }) => (
  <Form.Field inline>
    <label htmlFor="valid_from">{t('upload_token.upload_form.valid_from')}</label>
    <DatePicker
      id="valid_from"
      selected={Date.parse(validFrom)}
      dateFormat="yyyy-MM-dd"
      onChange={(date) => handleValidFromChange(date)}
      minDate={new Date()}
    />
  </Form.Field>
);

const mapModelsToProps = ({ uploadToken }) => ({
  validFrom: uploadToken.get('validFrom'),
  handleValidFromChange: (date) => {
    date.setHours(date.getHours() - (date.getTimezoneOffset() / 60));
    uploadToken.updateValidFrom(date.toISOString().split('T')[0]);
  },
});

const options = {
  debounce: true,
  events: {
    uploadToken: ['change:validFrom'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(FormValidFrom);
