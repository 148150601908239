import { useCallback } from 'react';
import { t } from 'i18n-js';
import {
  Segment, Header, Icon, Button,
} from 'semantic-ui-react';
import {
  ColumnsHeader,
  TableWrapper,
  EmptyMessage,
  ErrorComponent,
  Paging,
  Stats,
  TableHeader,
  FilterDropdown,
  Dropdown,
} from '../../query_table';
import RefreshButton from '../../components/buttons/RefreshButton';
import { useAuditContext } from '../Context';
import AuditTableRow from './AuditTableRow';

const NoAuditRows = () => (
  <Segment placeholder>
    <Header as="h4" icon>
      <Icon name="table" data-testid="audit-table-icon" />
      {t('audit.audit_panel.no_audit_data')}
    </Header>
  </Segment>
);

const TriggerClearFilters = () => {
  const {
    handleClearFilters,
    hasActiveFilters,
  } = useAuditContext();
  return <ClearFiltersButton handleClearFilters={handleClearFilters} hasActiveFilters={hasActiveFilters} />;
};

const ClearFiltersButton = ({ handleClearFilters, hasActiveFilters }) => (
  hasActiveFilters
  && (
    <Button size="small" title={t('audit.audit_panel.buttons.clear_filters')} onClick={handleClearFilters} data-testid="clear-all-filters-button">
      <Icon name="dont" data-testid="clear-all-filters-button-icon" />
      {t('audit.audit_panel.buttons.clear_filters')}
    </Button>
  )
);

const renderBodyRow = (row, index) => (
  <AuditTableRow row={row} key={`row-${index}`} index={index} />
);

const orderDirection = (orderByDirection) => (orderByDirection === 'asc' ? 'ascending' : 'descending');

const useFilterCallback = (
  name,
  handleFilterChange,
) => useCallback((value) => handleFilterChange({ column: name, value }), [name, handleFilterChange]);

const useColumns = (handleFilterChange, membersList, orderBy, orderByDirection) => (
  [
    {
      name: 'When',
      content: ['When', <FilterDropdown key="When" onFilter={useFilterCallback('When', handleFilterChange)} filter="When Filter" />],
      direction: orderBy === 'When' ? orderDirection(orderByDirection) : null,
      unsortable: false,
    },
    {
      name: 'Who',
      content: ['Who', <Dropdown key="Who" onFilter={useFilterCallback('Who', handleFilterChange)} options={membersList} filter="Who Filter" />],
    },
    {
      name: 'As',
      content: ['As', <FilterDropdown key="As" onFilter={useFilterCallback('As', handleFilterChange)} tabIndex={-1} filter="As Filter" />],
      direction: orderBy === 'As' ? orderDirection(orderByDirection) : null,
      unsortable: false,
    },
    {
      name: 'What',
      content: ['What', <FilterDropdown key="What" onFilter={useFilterCallback('What', handleFilterChange)} filter="What Filter" />],
      direction: orderBy === 'What' ? orderDirection(orderByDirection) : null,
      unsortable: false,
    },
    {
      name: 'Resource',
      content: ['Resource', <FilterDropdown key="Resource" onFilter={useFilterCallback('Resource', handleFilterChange)} filter="Resource Filter" />],
      direction: orderBy === 'Resource' ? orderDirection(orderByDirection) : null,
      unsortable: false,
    },
    {
      name: 'Detail',
      content: ['Detail', <FilterDropdown key="Detail" onFilter={useFilterCallback('Detail', handleFilterChange)} filter="Detail Filter" />],
      direction: orderBy === 'Detail' ? orderDirection(orderByDirection) : null,
      unsortable: false,
    },
  ]
);

const AuditTable = () => {
  const {
    auditRows,
    pagination,
    membersList,
    isFetching,
    handleRefreshButton,
    isError,
    setPage,
    page,
    handleFilterChange,
    resetCount,
    orderBy,
    orderByDirection,
    handleSort,
  } = useAuditContext();
  const columnsDef = useColumns(handleFilterChange, membersList, orderBy, orderByDirection);
  return (
    <TableWrapper
      tableData={auditRows}
      columns={columnsDef}
      tableHeader={(
        <TableHeader
          columns={columnsDef}
          errorComponent={<ErrorComponent isError={isError} />}
          refresh={<RefreshButton isFetching={isFetching} refetch={handleRefreshButton} />}
          filter={<TriggerClearFilters />}
        />
      )}
      columnsHeader={<ColumnsHeader columns={columnsDef} key={`filter-reset-count-${resetCount}`} onSortBy={handleSort} />}
      renderBodyRow={renderBodyRow}
      emptyWrapper={(
        <EmptyMessage
          isFetching={isFetching}
          tableData={auditRows}
          columns={columnsDef}
          emptyContent={<NoAuditRows />}
        />
      )}
      stats={<Stats shown={auditRows.length} total={pagination.records} />}
      pagination={<Paging total={pagination.records} onPageChange={setPage} activePage={page} />}
    />
  );
};

export default AuditTable;
