import { Icon, Header, Segment } from 'semantic-ui-react';
import { t } from 'i18n-js';

const NoDatabaseTableColumns = () => (
  <Segment placeholder>
    <Header as="h4" icon>
      <Icon name="columns" data-testid="database-table-icon" />
      {t('database_table.no_columns')}
    </Header>
  </Segment>
);

export default NoDatabaseTableColumns;
