import Schema from './schema';
import Model from './models';
import { PostgresIdentifier } from '../../utilities/validation_regexes';

export default Model.extend({
  constructorName: 'Sandbox',
  attrToLabel: {
    dataSourceName: 'data_sources.dialog.data_source_name',
    databaseName: 'data_sources.dialog.database_name',
    schemaName: 'data_sources.dialog.schema_name',
  },

  urlTemplate: 'api/workspaces/workspaces/{{workspaceId}}/sandbox',

  declareValidations(attrs) {
    const missingDb = !this.get('databaseId') && !attrs.databaseId;
    const missingSchema = !this.get('schemaId') && !attrs.schemaId;
    if (missingSchema || missingDb) {
      this.require('schemaName', attrs);
      this.requirePattern('schemaName', PostgresIdentifier(63), attrs);
    }
    if (missingDb) {
      this.require('databaseName', attrs);
      this.requirePattern('databaseName', PostgresIdentifier(63), attrs);
    }
  },

  schema() {
    this._schema = this._schema || new Schema(this.attributes);

    return this._schema;
  },

  canonicalName() {
    return this.schema().canonicalName();
  },
});
