import Backbone from 'backbone';

const UploadTokenList = Backbone.Model.extend({
  defaults: {
    page: 1,
    loaded: false,
  },
});

export default UploadTokenList;
