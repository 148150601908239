import _ from '../../underscore';
import $ from '../../jquery';
import View from '../loading_view';
import { FEATURE } from '../../../utilities/features';
import chorus from '../../chorus';

/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true }] */
const matchingId = model => item => item.get('uuid') === model.get('uuid');

const findUser = (id, users) => users.find(item => item.get('id') === id);
const findManager = (uuid, users) => users.find(item => item.get('uuid') === uuid && item.get('role') === 'Manager');

export default View.extend({
  templateName: 'shuttle_widget',

  events: {
    'click a.add': 'toggleAdd',
    'click a.remove': 'toggleAdd',
    'change select.role': 'changeRole',
    'click .goto_next': 'nextPage',
    'click .goto_prev': 'prevPage',
    'click .goto_first': 'firstPage',
    'click .goto_last': 'lastPage',
  },

  subviews: {
    '.roles_menu': 'roles_menu',
    '.shuttle_header': 'shuttle_header',
  },

  setup() {
    this.selectionSource = this.options.selectionSource;
    this.nonRemovableModels = this.options.nonRemovable;
    this.workspaceStatus = this.options.workspaceStatus;
    this.isManagerUser = _.any(findManager(chorus.session.user().get('uuid'), this.selectionSource));
  },

  collectionModelContext(model) {
    const member = this.selectionSource.find(matchingId(model));
    const isNonRemovalble = _.any(this.nonRemovableModels, matchingId(model));
    return {
      isInvited: member ? member.get('invited') : false,
      isAdded: member ? !member.get('removed') : false,
      displayName: model.displayName(),
      displayInitials: model.displayInitials(),
      displayAvatarBackgroundColor: model.displayAvatarBackgroundColor(),
      department: model.get('department'),
      imageUrl: model.fetchImageUrl({ size: 'icon' }),
      isNonRemovable: isNonRemovalble,
      nonRemovableText: this.options.nonRemovableText,
      pending: this.workspaceStatus !== 'active',
    };
  },

  selectionSourceContext(model) {
    const isNonRemovalble = _.any(this.nonRemovableModels, matchingId(model));
    const role = model.get('role');
    return _.extend({ model }, model.attributes, {
      isInvited: model.get('invited'),
      isNewInvite: model.get('new'),
      displayName: model.displayName(),
      displayInitials: model.displayInitials(),
      displayAvatarBackgroundColor: model.displayAvatarBackgroundColor(),
      imageUrl: model.fetchImageUrl({ size: 'icon' }),
      isNonRemovable: isNonRemovalble || (this.isManagerUser && role === 'Administrator' && !model.get('invited')),
      nonRemovableText: role,
      isManagerUser: this.isManagerUser,
      containerisedApps: FEATURE.CONTAINERISED_APPS,
      isAdmin: role === 'Administrator',
      isStandard: role === 'Standard',
      isObserver: role === 'Observer',
      isContributor: role === 'Contributor',
      isDeveloper: role === 'Developer',
      isManager: role === 'Manager',
      isAdded: !model.get('removed'),
      pending: this.workspaceStatus !== 'active',
    });
  },

  additionalContext() {
    const selectedModels = _.map(this.selectionSource.models, model => this.selectionSourceContext(model));
    return $.extend({ selectedModels }, this.updateSearchInfoVisibility());
  },

  updateSearchInfoVisibility() {
    return this.collection.attributes.namePattern ? { searchInfoVisible: false } : { searchInfoVisible: true };
  },

  postRender() {
    this.parentView.$('.selected_count').text(this.$('ul.selected li.added, ul.selected li.invited').length);
  },

  addUser(id) {
    let user = findUser(id, this.selectionSource);

    if (!user) {
      user = findUser(id, this.collection);

      this.selectionSource.add(user);
      user.set('invited', true);
      user.set('new', true);
      user.set('enabled', true);
      user.set('role', 'Standard');
    }
    user.set('removed', false);

    this.selectionSource.sort();
  },

  removeUser(id) {
    const user = findUser(id, this.selectionSource);
    user.set('removed', true);
  },

  toggleAdd(e) {
    e.preventDefault();

    const target = this.$(e.currentTarget);
    const id = target.closest('li').data('id');
    const isAdding = target.closest('ul').hasClass('available');

    if (isAdding) {
      this.addUser(id);
    } else {
      this.removeUser(id);
    }

    this.render();
  },

  changeRole(e) {
    const target = this.$(e.currentTarget);
    const id = target.closest('li').data('id');
    const user = findUser(id, this.selectionSource);
    const role = user.get('original_role') || user.get('role');

    user.set('role', target.val());
    user.set('original_role', role);
  },

  nextPage() {
    this.gotoPage(parseInt(this.collection.pagination.page, 10) + 1);
  },

  prevPage() {
    this.gotoPage(parseInt(this.collection.pagination.page, 10) - 1);
  },

  firstPage() {
    this.gotoPage(1);
  },

  lastPage() {
    this.gotoPage(parseInt(this.collection.pagination.total, 10));
  },

  gotoPage(page) {
    const options = { success: _.bind(this.render, this), data: {} };
    if (this.collection.attributes.namePattern) {
      options.data[this.collection.searchField] = `*${this.collection.attributes.namePattern}*`;
      this.collection.fetchPage(page, options);
    } else {
      this.collection.models = [];
      this.render();
    }
  },
});
