import React, { useState } from 'react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { Card } from 'semantic-ui-react';

import SingleCardFooter from './SingleCardFooter';
import SingleCardBody from './SingleCardBody';
import SingleCardHeader from './SingleCardHeader';
import './SingleContainerisedCard.less';

const SingleCardContainer = ({
  name,
  podStatus,
  startedBy,
  handleDelete,
  runningTag,
  isSystemApp,
  handleRun,
  displayPicture,
  additionalInfo,
  license,
}) => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <Card color="green" className="aridhia-containerised-app">
      <SingleCardHeader
        name={name}
        handleDelete={handleDelete}
        setShowInfo={setShowInfo}
        showInfo={showInfo}
        showMenu={!isSystemApp}
        handleRun={handleRun}
      />
      <Card.Content>
        <SingleCardBody
          podStatus={podStatus}
          isSystemApp={isSystemApp}
          runningTag={runningTag}
          startedBy={startedBy}
          showInfo={showInfo}
          handleRun={handleRun}
          displayPicture={displayPicture}
          additionalInfo={additionalInfo}
          license={license}
        />
      </Card.Content>
      <SingleCardFooter />
    </Card>
  );
};

const mapModelsToProps = ({ containerisedApp }) => ({
  name: containerisedApp.get('name'),
  podStatus: containerisedApp.get('podStatus'),
  runningTag: containerisedApp.get('runningTag'),
  startedBy: containerisedApp.get('startedBy'),
  developer: containerisedApp.get('developer'),
  displayPicture: containerisedApp.get('displayPicture'),
  isSystemApp: containerisedApp.isSystemApp(),
  additionalInfo: containerisedApp.get('additionalInfo'),
  license: containerisedApp.get('license'),
  handleRun: () => containerisedApp.navigateToAppRun(),
});

export default connectBackboneToReact(mapModelsToProps)(SingleCardContainer);
