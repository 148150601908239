import { useState } from 'react';
import { t } from 'i18n-js';
import {
  Modal, Form, Icon, Header,
} from 'semantic-ui-react';
import ModalActionButtons from '../../../components/buttons/ModalActionButtons';
import HeaderError from '../../../components/errors/HeaderError';
import FolderLocationDropdown from './FolderLocationDropdown';
import FormValidationError from '../../../components/errors/FormValidationError';
import {
  findErrors,
  hasError,
  invalidCharacters,
  invalidCharactersIn,
  invalidFileNames,
} from '../../../utilities/validation';
import { validationLengths, validationFields } from '../../../constants/validation';
import { useNewFile } from '../../panels/service';
import { workspaceFileListElementUrl } from '../../../constants/ui_urls';
import { useFilesContext } from '../../panels/files/Context';
import { workspaceId } from '../../../layout/helper';

const initialState = (folderPath) => ({
  name: '',
  folderLocation: folderPath || 'files',
});

const NewFolderModal = ({ setModalClose, setIsBusy }) => {
  const { baseFolderPath } = useFilesContext();

  const [folder, setFolder] = useState(initialState(baseFolderPath));
  const [validationErrors, setValidationErrors] = useState([]);

  const isValidEntry = !!folder.name && !!folder.folderLocation;

  const handleNameChange = (e, { value }) => {
    setFolder({ ...folder, name: value });
    const newErrors = validationErrors.filter((err) => err.field !== validationFields.NAME);
    setValidationErrors(newErrors);
  };

  const checkForErrors = (data) => {
    const errors = [];
    const trimmedName = data.name.trim();
    if (!trimmedName) {
      errors.push({
        field: 'name',
        message: (t('field_error.BLANK', { field: t('modals.new_folder.name') })),
      });
    }

    if (trimmedName?.length > validationLengths.NAME) {
      errors.push({
        field: 'name',
        message: (t('field_error.TOO_LONG', { field: t('modals.new_folder.name'), count: validationLengths.NAME })),
      });
    }

    if (trimmedName && invalidCharactersIn(trimmedName)) {
      errors.push({
        field: 'name',
        message: (
          t('field_error.INVALID_CHARACTERS', {
            field: t('modals.new_folder.name'),
            characters: invalidCharacters,
          })),
      });
    }

    if (trimmedName && invalidFileNames(trimmedName)) {
      errors.push({
        field: 'name',
        message: (t('field_error.INVALID_NAME')),
      });
    }
    return errors;
  };

  const {
    createNewFile,
    isLoading,
    isError,
    error,
    resetResponse,
  } = useNewFile();

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleSubmitting = () => {
    setIsBusy(true);
    const errors = checkForErrors(folder);
    setValidationErrors(errors);

    if (errors.length > 0) {
      setIsBusy(false);
      return;
    }

    const folderData = {
      workfile: {
        workspace: {
          id: workspaceId(),
        },
        file_name: folder.name.trim(),
        folder: folder.folderLocation,
        source: 'empty',
        entity_subtype: 'folder',
        content_type: 'folder',
      },
    };

    createNewFile(folderData, {
      onSuccess: ({ response }) => {
        setIsBusy(false);
        handleModalClose();
        const newFileUrl = workspaceFileListElementUrl(
          response.id,
          response.file_type,
          response.folder,
          response.file_name.trim(),
        );
        window.location.href = newFileUrl;
      },
      onError: () => {
        setIsBusy(false);
      },
    });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="folder" title="folder" />
        <Header.Content content={t('modals.new_folder.title')} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label htmlFor="folder">
              {t('modals.new_folder.name')}
              {hasError(validationErrors, validationFields.NAME) && (
                <FormValidationError errorMsg={(findErrors(validationErrors, validationFields.NAME)[0]).message} />
              )}
            </label>
            <Form.Input
              id="name"
              autoFocus
              placeholder={t('modals.new_folder.name_placeholder')}
              maxLength={validationLengths.NAME}
              value={folder.name}
              onChange={handleNameChange}
              error={hasError(validationErrors, validationFields.NAME)}
            />
          </Form.Field>
          <FolderLocationDropdown
            baseFolderPath={baseFolderPath}
            handleDropdownChange={(e, { value }) => setFolder({ ...folder, folderLocation: value })}
            value={folder.folderLocation}
          />
        </Form>
      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmitting}
        isValidEntry={isValidEntry}
        error={validationErrors.length > 0}
        submitText={t('actions.create_folder')}
      />
    </>
  );
};

export default NewFolderModal;
