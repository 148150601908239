import { Label, Menu, Tab } from 'semantic-ui-react';
import { t } from 'i18n-js';
import Apps from '../panels/apps/components/Apps';
import ShinyApps from '../panels/shiny_apps/components/ShinyApps';

const appsPane = {
  menuItem: (
    <Menu.Item key="apps" aria-label="test">
      {t('apps.apps_tab')}
      <Label>4</Label>
    </Menu.Item>
  ),
  render: () => <Apps />,
};

const shinyAppsPane = {
  menuItem: (
    <Menu.Item key="shinyApps">
      {t('apps.shiny_apps_tab')}
      <Label>15</Label>
    </Menu.Item>
  ),
  render: () => <ShinyApps />,
};

const AppsTabs = () => {
  const panes = [appsPane, shinyAppsPane];

  return <Tab panes={panes} menu={{ secondary: true, pointing: true, as: 'menu' }} />;
};

export default AppsTabs;
