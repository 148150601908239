import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import LinkMenu from '../core/link_menu_view';
import { canEdit, isInbox } from '../../utilities/file';
import View from '../loading_view';

export default View.extend({
  templateName: 'workfile_content_details',
  additionalClass: 'workfile_content_details action_bar_highlighted',
  bindCallbacks: $.noop,

  events: {
    'click button.save': 'replaceCurrentVersion',
    'click .workfile_layout ul li :checkbox': 'aceOptionChanged',
    'click .aceMenu': 'aceMenuOptionChanged',
    'change .aceOpt': 'aceOptionChanged',
    'change .workfile_layout ul li select': 'aceOptionChanged',
  },

  subviews: {
    '.app-btnSavefileMenu': 'fileMenu',
  },

  setup(options) {
    this.resource = this.model;
    this.allVersions = null;
    this.contentView = options.contentView;
    this.fileMenu = new LinkMenu({
      title: t('rsh.r.editor.save_as'),
      isSimple: true,
      options: this.fileMenuItems(),
      choose: _.bind(this.doMenuItem, this),
    });

    this.listenTo(this.model, 'change', this.updateFileMenuItems);
    this.listenTo(this.model, 'change:draftUpdatedAt', this.updateAutosaveText);
  },

  fileMenuItems() {
    return [
      { data: 'saveAsNewFile', text: t('workfile.content_details.save_new_file') },
    ];
  },

  updateFileMenuItems() {
    this.postRender();
  },

  doMenuItem(targetAction) {
    this[targetAction]();
  },

  saveAsNewFile() {
    this.contentView.createNewFile();
  },

  updateAutosaveText(args) {
    const text = args || 'workfile.content_details.auto_save';
    const time = this.formatTime(new Date());
    this.$('span.auto_save').removeClass('hidden');
    this.$('span.auto_save').text(t(text, { time }));
  },

  additionalContext() {
    const canUpdate = canEdit(this.model);
    return {
      isLatestVersion: this.model.isLatestVersion(),
      canUpdate,
      canSaveAs: canUpdate,
      isInbox: isInbox(this.model),
    };
  },

  postRender() {
    if (this.allVersions && this.model.get('versionInfo')) {
      if (!this.model.isLatestVersion()) {
        this.$('button.save').addClass('disabled');
      } else {
        this.$('button.save').removeClass('disabled');
      }
    }
  },

  // SAVE
  replaceCurrentVersion(e) {
    if (!$(e.target).hasClass('disabled')) {
      this.contentView.replaceCurrentVersion();
      this.updateAutosaveText('workfile.content_details.save');
      this.displaySpinner();
    }
  },

  getEditor() {
    const editor_div = $(this.$el).parent().find('.text_editor')[0];
    if (editor_div === undefined) {
      return undefined;
    }
    return ace.edit(editor_div);
  },

  // Layout
  aceMenuOptionChanged(e) {
    const option = e.target.dataset.editorOption;
    const editor = this.getEditor();
    const curVal = editor.getOption(option);
    if (option == 'theme') {
      const light = e.target.dataset.editorLight;
      const dark = e.target.dataset.editorDark;
      if (curVal == light) {
        editor.setOption(option, dark);
        $(e.target).children().addClass('fa-check');
      } else {
        editor.setOption(option, light);
        $(e.target).children().removeClass('fa-check');
      }
      return;
    }

    let value = !curVal;
    if (option == 'wrap') {
      // wrap can be set with a boolean, but returns the string 'off' or 'free'
      value = (curVal == 'off');
    }
    if (value) {
      $(e.target).children().addClass('fa-check');
    } else {
      $(e.target).children().removeClass('fa-check');
    }
    editor.setOption(option, value);
  },

  aceOptionChanged(e) {
    if (e.target.name == 'fontSize') {
      let value = $(e.target).val();
      if (value < 3) value = 3;
      if (value > 99) value = 99;
      $(e.target).val(value);
      const option = e.target.name;
      this.contentView.setEditorOption(option, `${value}px`);
    }
  },

  displaySpinner() {
    this.$('.app-savingSpinner').show().startLoading();
  },

  hideSpinner() {
    this.$('.app-savingSpinner').show().stopLoading();
  },

  formatTime(time) {
    let hours = time.getHours();
    let minutes = time.getMinutes();

    let suffix = 'AM';
    if (hours >= 12) {
      suffix = 'PM';
      hours -= 12;
    }
    if (hours === 0) {
      hours = 12;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    return `${hours}:${minutes} ${suffix}`;
  },
});
