import { t } from 'i18n-js';
import {
  Button, Form, Input,
} from 'semantic-ui-react';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import ImportCSVFileModal from './modals/ImportCSVFileModal';
import { useDatabaseTableCreationContext } from '../Context';
import { removeCSV } from '../actions';

const DatabaseTableCreationCSVSelect = ({ showEmptyWarning }) => {
  const { newDatabaseTableState, dispatch } = useDatabaseTableCreationContext();

  const handleRemoveFile = () => {
    dispatch(removeCSV);
  };

  const CreateFromCSVButton = (
    <Button
      icon="file"
      primary
      disabled={!!newDatabaseTableState.table.source_file}
      content={t('database_tables_new.new_table.from_csv.action')}
    />
  );

  const labelText = newDatabaseTableState.passed_csv_file
    ? t('database_tables_new.new_table.from_csv.label_passed_file')
    : t('database_tables_new.new_table.from_csv.label_select');

  return (
    <Form.Field
      className={showEmptyWarning ? 'warning' : ''}
    >
      <label htmlFor="select">
        {labelText}
        *
      </label>
      {!newDatabaseTableState.table.source_file && (
        <Input
          id="select"
          action
          placeholder={t('database_tables_new.new_table.from_csv.placeholder_select')}
          defaultValue={newDatabaseTableState.table.source_file}
        >
          <input readOnly />
          <ModalWrapper trigger={CreateFromCSVButton} size="small">
            <ImportCSVFileModal />
          </ModalWrapper>
        </Input>
      )}
      {!!newDatabaseTableState.table.source_file && (
        <Input
          id="select"
          action
          type="text"
          defaultValue={newDatabaseTableState.table.source_file}
          content={newDatabaseTableState.table.source_file}
        >
          <input readOnly />
          <Button
            icon="times"
            data-testid="remove-csv-icon"
            name="delete"
            onClick={handleRemoveFile}
            disabled={!newDatabaseTableState.table.source_file || !!newDatabaseTableState.passed_csv_file}
          />
        </Input>
      )}
    </Form.Field>
  );
};

export default DatabaseTableCreationCSVSelect;
