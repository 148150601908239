import React from 'react';
import { t } from 'i18n-js';

function ErrorMessage({ error }) {
  const { code, message } = error;
  let text;
  let extraInfo;

  switch (code) {
    case 'CSV_INVALID_CLOSING_QUOTE':
      text = 'dataset.import.error_invalid_closing_quote';
      break;
    case 'CSV_INCONSISTENT_RECORD_LENGTH':
      text = 'dataset.import.error_inconsistent_record_length';
      extraInfo = error.record;
      break;
    case 'CSV_RECORD_DONT_MATCH_COLUMNS_LENGTH':
      text = 'dataset.import.error_record_dont_match_columns';
      extraInfo = error.record;
      break;
    case 'CSV_INVALID_COLUMN_MAPPING':
      text = 'dataset.import.error_invalid_column_mapping';
      extraInfo = error.headers;
      break;
    case 'CSV_MAX_RECORD_SIZE':
      text = 'dataset.import.error_max_record_size';
      break;
    case 'CSV_NON_TRIMABLE_CHAR_AFTER_CLOSING_QUOTE':
      text = 'dataset.import.error_non_trimmable_char';
      break;
    case 'CSV_QUOTE_NOT_CLOSED':
      text = 'dataset.import.error_quote_not_closed';
      break;
    default:
      text = null;
  }

  return (
    <div className="alert">
      <p>{t('dataset.import.error_occurred')}</p>
      <p>{t('dataset.import.error_message', { message })}</p>
      {text && (<p>{ t(text, { extraInfo }) }</p>)}
    </div>
  );
}

export default ErrorMessage;
