import PageItemList from '../core/page_item_list_view';
import ItemWrapper from '../core/item_wrapper_view';
import workfile from './search_workfile_view';
import dataset from './search_dataset_view';
import workspace from './search_workspace_view';
import attachment from './search_attachment_view';

const SearchHandlers = {
  workfile,
  linked_tableau_workfile: workfile,
  dataset,
  chorusView: dataset,
  workspace,
  attachment,
};

const buildForType = (entityType, options) => new SearchHandlers[entityType](options);

export default PageItemList.extend({
  constructorName: 'WorkspaceSearchResultPageItemList',

  makeListItemView(model) {
    const itemView = buildForType(model.get('entityType'), {
      model,
      search: this.listItemOptions.search,
      workspaceIdForTagLink: this.listItemOptions.search.get('workspaceId'),
    });

    return new ItemWrapper({ itemView });
  },
});
