import _ from '../underscore';
import dynamicDataset from '../models/dynamic_dataset';
import BulkDestroyer from '../models/bulk_destroyer';
import BulkSaver from '../models/bulk_saver';
import LastFetchWins from './last_fetch_wins';

export default LastFetchWins.extend({

  constructorName: 'WorkspaceDatasetSet',
  model(attrs, options) {
    return dynamicDataset(attrs, options);
  },

  setup() {
    if (this.attributes.unsorted) {
      this.comparator = undefined;
    }
  },

  showUrlTemplate: 'workspaces/{{workspaceId}}/datasets',
  urlTemplate: 'api/workspaces/workspaces/{{workspaceId}}/datasets',

  save() {
    const ids = _.pluck(this.models, 'id');
    new BulkSaver({ collection: this }).save({ datasetIds: ids });
  },

  destroy() {
    const ids = _.pluck(this.models, 'id');
    new BulkDestroyer({ collection: this }).destroy({
      datasetIds: ids,
      includeDependencies: this.includeDependencies.toString(),
    });
  },

  urlParams() {
    const params = {
      namePattern: this.attributes.namePattern,
      databaseId: this.attributes.database && this.attributes.database.id,
      entitySubtype: this.attributes.type,
    };

    if (this.attributes.allImportDestinations) {
      params.allImportDestinations = true;
    }
    if (this.attributes.allImportSources) {
      params.allImportSources = true;
    }
    if (this.attributes.withMetadata) {
      params.withMetadata = true;
    }
    if (this.attributes.excludeDataset) {
      params.excludeDataset = this.attributes.excludeDataset;
    }
    return params;
  },

  comparator(dataset) {
    return dataset.get('objectName').replace('_', '').toLowerCase();
  },

  search(term) {
    const self = this;
    self.attributes.namePattern = term;
    self.fetch({ silent: true, success() { self.trigger('searched'); } });
  },

  hasFilter() {
    return !_.isEmpty(this.attributes.namePattern) || !_.isEmpty(this.attributes.type);
  },

  workspace() {
    return this._workspace;
  },

  setWorkspace(workspace) {
    this._workspace = workspace;
  },

  setIncludeDependencies(includeDependencies) {
    this.includeDependencies = includeDependencies;
  },
});
