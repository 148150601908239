import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import View from '../loading_view';

export const PopupMenu = () => {
  let currentMenu;

  const releaseClicks = function onRelease() {
    $(document).unbind('click.popup_menu');
  };

  const dismissPopups = function onDismiss(parentView) {
    releaseClicks();
    parentView.$('.menu').addClass('hidden');
  };

  const captureClicks = function onCapture(parentView) {
    $(document).bind('click.popup_menu', () => {
      dismissPopups(parentView);
    });
  };

  return {
    toggle(parentView, selector, e) {
      if (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
      }

      const previousMenu = currentMenu;
      currentMenu = parentView.$(selector);

      const isPoppedUp = !currentMenu.hasClass('hidden');
      dismissPopups(parentView);

      if (!isPoppedUp) {
        captureClicks(parentView);
      }

      if (previousMenu) {
        previousMenu.addClass('hidden');
      }

      currentMenu.toggleClass('hidden', isPoppedUp);
    },

    close(parentView) {
      dismissPopups(parentView);
    },
  };
};

export default View.extend({
  constructorName: 'LinkMenuView',
  templateName: 'link_menu',

  events: {
    'click a.popup': 'popupLinkClicked',
    'click li > a': 'resolve',
  },

  _configure(options) {
    if (options.isSimple) {
      this.tagName = 'ul';
    }
    if (options.choose) {
      this.choose = options.choose;
    }
    this._super('_configure', [options]);
  },

  context() {
    this.showTitle = this.options.title || true;

    if (!this.options.chosen) {
      this.options.chosen = this.options.options[0].data;
    }

    _.each(this.options.options, function displayChecked(option) {
      const opt = option;
      if (opt.data === this.options.chosen) {
        opt.isChecked = true;
        if (this.showTitle) {
          this.options.chosenText = opt.text;
        }
      } else {
        opt.isChecked = false;
      }
    }, this);

    return this.options;
  },

  popupLinkClicked(e) {
    PopupMenu().toggle(this, '.rsh-menu', e);
  },

  resolve(e) {
    e && e.preventDefault();
    const target = $(e.target);

    if (!target.hasClass('disabled')) {
      const ul = target.closest('ul');
      const li = target.closest('li');
      this.options.chosen = li.data('type');
      const eventName = ul.data('event');
      this.choose(this.options.chosen, eventName);
    }
  },

  choose(pickedChoiceData, eventName) {
    this.trigger('choice', eventName, pickedChoiceData);
    chorus.PageEvents.trigger(`choice:${eventName}`, pickedChoiceData, this);
    this.render();
  },

  disableItem(name) {
    this.$(`li[data-type='${name}'] a`).addClass('disabled');
  },

  enableItem(name) {
    this.$(`li[data-type='${name}'] a`).removeClass('disabled');
  },
});
