import { Segment, Header, Divider } from 'semantic-ui-react';
import HomeContent from './HomeContent';
import HomeDescription from './HomeDescription';
import HomeUserList from './HomeUserList';
import { PanelHeader, DefaultPanelHeaderIcon } from '../../layout';
import { useWorkspaceContext } from '../../adapters/Workspace';
import { calculateIsNarrow } from '../../helpers/panel_widths';
import './Home.less';

const Home = ({ panelWidth }) => {
  const { workspace } = useWorkspaceContext();
  const isNarrow = calculateIsNarrow(panelWidth);
  return (
    <Segment.Group className="aridhia-panel-wrapper" data-testid="home-panel">
      <PanelHeader
        headerIcon={<DefaultPanelHeaderIcon name="home" />}
        headerText={<Header as="h3" className="truncate" content={workspace.name} title={workspace.name} />}
      />
      <Segment as="article" className="aridhia-full-height-segment aridhia-columns">
        <HomeContent />
        <Segment as="aside" basic className="margin-none">
          <HomeUserList />
          <Divider />
          {!isNarrow && <HomeDescription />}
        </Segment>
      </Segment>
    </Segment.Group>
  );
};

export default Home;
