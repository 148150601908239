import chorus from '../../chorus';
import WorkfileContent from './workfile_content_view';

export default WorkfileContent.extend({
  templateName: 'image_workfile_content',

  setup(options) {
    this.scrollPanelDisable = false;
    this._super('setup', options);
  },

  postRender() {
    this.$('img').on('load', () => {
      chorus.page.trigger('resized');
    });
  },
});
