import $ from '../jquery';
import Model from './models';

export default Model.extend({
  constructorName: 'Filter',

  setColumn(column) {
    if (!this.get('column') || (this.get('column').cid !== column.cid)) {
      this.unset({ comparator: undefined, input: undefined }, { silent: true });
      this.set({ column });
    }
  },

  setComparator(comparator) {
    this.set({ comparator });
  },

  setInput(inputHash) {
    this.set({ input: inputHash });
  },

  getFilterMap: $.noop,
});
