import _ from '../underscore';
import Model from './models';

export default Model.extend({
  url(options) {
    return this.attributes.collection.url(options);
  },

  initialize() {
    this.bind('destroy', _.bind(function onDestroy() {
      this.trigger('destroy');
    }, this.attributes.collection));
  },

  // backbone will not perform a destroy for objects it considers 'new'
  // (objects without an 'id'), so we need to force it to believe
  // this BulkDestroyer is not 'new'
  isNew() {
    return false;
  },

  destroy(options) {
    // backbone will not take any additional data for a 'destroy' call unless
    // it is forced into the options.data property, so we'll send along
    // our options in the 'data' property
    return this._super('destroy', [{
      data: JSON.stringify(this.underscoreKeys(options)),
      contentType: 'application/json',
    }]);
  },

  handleRequestFailure(...args) {
    const { collection } = this.attributes;
    collection.handleRequestFailure(...args);
  },
});
