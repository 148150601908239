import { useState } from 'react';
import { t } from 'i18n-js';
import { Popup, Icon, Form } from 'semantic-ui-react';
import { useDatabaseTableCreationContext } from '../Context';
import MarkdownRender from '../../../../components/text_editors/MarkdownRender';
import { validationLengths } from '../../../../constants/validation';
import MarkdownEditor from '../../../../components/text_editors/MarkdownEditor';
import { isRichText } from '../../../../utilities/editor';
import { setColumnProperty } from '../actions';

const EditableDescriptionPopup = ({ column }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { dispatch } = useDatabaseTableCreationContext();

  const handleColumnDescriptionChange = ({ value, id }) => {
    dispatch(setColumnProperty(id, value, 'description'));
  };

  const toggleIsOpen = () => setIsOpen((state) => !state);
  const previewText = column?.description || t('database_tables_new.columns.column_description');
  const mountNode = document.querySelector('#popup-wrapper') || document.body;
  const markdownClass = column?.description ? '' : 'disabled';
  const disabled = column.primary_added ? 'disabled' : '';

  return (
    <Form.Field>
      <Popup
        mountNode={mountNode}
        className="aridhia-markdown-popup"
        content={
          (
            <MarkdownEditor
              value={column?.description}
              onChange={(value) => handleColumnDescriptionChange({ value, id: `description-${column.id}` })}
              textAreaAttributes={
                {
                  id: `description-${column.id}`,
                  placeholder: t('database_tables_new.columns.column_description'),
                  maxLength: validationLengths.TEXT,
                }
              }
            />
          )
        }
        wide
        position="bottom left"
        on="click"
        onOpen={toggleIsOpen}
        onClose={toggleIsOpen}
        open={isOpen}
        disabled={column.primary_added}
        trigger={(
          <Icon
            title={!isOpen ? 'Edit' : 'Close'}
            link
            name={!isOpen ? 'pencil' : 'close'}
            className={`float-right margin-small ${disabled}`}
          />
        )}
      />
      <div className={`six-line-truncate aridhia-markdown-popup-field ${disabled}`}>
        <MarkdownRender
          value={previewText}
          className={markdownClass}
          isRichText={isRichText(previewText)}
        />
      </div>
    </Form.Field>
  );
};

export default EditableDescriptionPopup;
