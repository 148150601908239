import chorus from '../../chorus';
import $ from '../../jquery';
import Dialog from '../../dialogs/dialogs';
import Routing from '../../mixins/routing';
import uploadFile from '../../utilities/upload_file';

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(decodeURIComponent(arr[1]).replace(/(\r\n|\n|\r)/g, ''));
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const file = new Blob([u8arr], { type: mime });
  file.name = filename;
  return file;
};

export default Dialog.include(Routing).extend({
  constructorName: 'RToolsPlotView',

  templateName: 'rtools/rtools_plot_view_dialog',
  title: 'Plot',

  // persistent: true,

  additionalClass: 'dialog_wide',

  events: {
    'click .app-btnSavePlot': 'plotSave',
    'click .app-btnClosePlot': 'plotClose',
  },

  setup() {

  },

  // This method display a requested plot in the plot viewer (page area)
  // We append the image to '.app-plotInput'
  plotView() {
    const plotDisplay = this.$('.app-plotOutput');
    const plotNameField = this.$('.app-savePlotName');
    // Bizarrely, when you save the plot as a workfile it magically becomes
    // a png :-/  For now we're just accepting this and renaming the file
    // accordingly.
    this.plotPNGName = this.options.plotName.replace('.svg', '.png');
    plotNameField.prop('placeholder', this.plotPNGName).val(this.plotPNGName);
    plotDisplay.append($('<img>').attr('src', this.options.plotURL));
  },

  plotSave(e) {
    e.preventDefault();
    let savePlotName = this.$('.app-savePlotName').val() || this.plotPNGName;
    const savePlotDescription = this.$('.app-savePlotDescription').val() || '';
    savePlotName = chorus.rsh.checkFileExtension(savePlotName, '.png');
    const blob = dataURLtoFile(this.options.plotURL, savePlotName);
    uploadFile(savePlotName, savePlotDescription, blob, this.options.workspaceId).then(() => this.plotClose());
  },

  // Called from button click
  plotClose() {
    this.closeModal();
  },

  postRender() {
    chorus.PageEvents.trigger('rsh:ui:rtools:plot:dialog:open');
    this.plotView();
  },
});
