import $ from '../../jquery';
import View from '../loading_view';

export default View.extend({
  templateName: 'tag_item',
  tagName: 'div',

  additionalContext() {
    return {
      name: this.model.get('name'),
      count: this.model.get('count'),
      checkable: false,
    };
  },

  postRender() {
    $(this.el).attr('data-Id', this.model.id);
    $(this.el).attr('data-type', this.model.entityType);
  },
});
