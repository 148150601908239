import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import PickItems from '../pick_items_dialog';
import WorkfileSet from '../../collections/workfile_set';
import WorkfileFolder from '../../models/workfile_folder';

export default PickItems.extend({
  constructorName: 'WorkfilesAttachDialog',
  templateName: 'workfiles_attach',

  title: t('workfiles.attach'),
  submitButtonTranslationKey: 'workfiles.button.attach_file',
  emptyListTranslationKey: 'workfiles.none',
  searchPlaceholderKey: 'workfiles.dialog.search',
  selectedEvent: 'files:selected',
  modelClass: 'Workfile',
  multiSelection: true,
  folder: '',

  events: {
    'click .workfile-folder': 'changeRootFolder',
    'click .workfile-breadcrumb-folder': 'changeFolderViaBreadcrumb',
  },

  makeModel(...args) {
    this._super('makeModel', args);
    this.options = _.extend({
      collection: new WorkfileSet([], {
        workspaceId: this.options.workspace.id,
        succinct: true,
      }),
    }, this.options);
  },

  setup() {
    this.workspace = this.options.workspace;

    if (this.options.title) {
      this.title = this.options.title;
    }
    if (this.options.submitButtonTranslationKey) {
      this.submitButtonTranslationKey = this.options.submitButtonTranslationKey;
    }
    if (this.options.multiSelection === false) {
      this.multiSelection = false;
    }

    const onTextChangeFunction = _.debounce(_.bind(function search(e) {
      this.paginationView.collection.attributes.namePattern = $(e.target).val();
      this.paginationView.fetchFirstPage();
    }, this), 300);

    this.options = _.extend({
      onTextChange: onTextChangeFunction,
    }, this.options);

    this._super('setup');

    if (this.options.folder) {
      this.setFolder(this.createFolder(this.options.folder));
    } else {
      this.setFolder(this.workspace.defaultWorkfilesFolder());
    }
  },

  postRender() {
    this._super('postRender');
    this.$('ul').find(`[data-folder='${this.folder.topLevelFolder()}']`).addClass('active');
  },

  collectionModelContext(model) {
    return {
      name: model.get('fileName'),
      imageUrl: model.iconUrl({ size: 'icon' }),
    };
  },

  additionalContext() {
    return _.extend(this._super('additionalContext'), {
      folders: this.folderOptions(),
    });
  },

  folderOptions() {
    return _.map(this.workspace.workfileFolders(), folder => ({ data: folder }));
  },

  changeRootFolder(e) {
    e.preventDefault();
    this.$el.find('li').removeClass('active');
    $(e.target).addClass('active');
    this.setFolder(this.createFolder($(e.target).data('folder')));
  },

  changeFolderViaBreadcrumb(e) {
    e.preventDefault();
    this.setFolder(this.createFolder($(e.target).data('folder')));
  },

  itemSelected(selectedItems) {
    let selectedItem = selectedItems;
    if (this.multiSelection) {
      selectedItem = selectedItems.slice(-1)[0];
    }
    if (selectedItem && selectedItem.isFolder()) {
      this.setFolder(this.createFolder(`${selectedItem.get('folder')}/${selectedItem.get('fileName')}`));
    } else {
      this.multiSelection ? this._super('itemSelected', selectedItems) : this._super('itemSelected', selectedItem);
    }
  },

  crumbs() {
    const crumbs = [];
    const parts = this.folder.name().split('/');

    _.each(parts, function addCrumb(data, idx) {
      crumbs.push({ label: data, data_folder: this.slice(0, idx + 1).join('/') });
    }, parts);

    crumbs.shift();
    return crumbs;
  },

  createFolder(folder) {
    return new WorkfileFolder({ folder, workspaceId: this.workspace.id });
  },

  setFolder(choice) {
    this.folder = choice;
    this.trigger('folder:changed', this.folder.name());
    this.paginationView.collection.attributes.folder = this.folder.name();
    this.paginationView.fetchFirstPage();
  },

  resetSelection() {
    this.renderSubviews();
    this._super('resetSelection');
  },
});
