import Backbone from '../vendor/backbone';
import Tab from '../models/layout/tab';

export default Backbone.Collection.extend({
  model: Tab,

  setActive(tab) {
    if (tab === null) {
      return;
    }
    const to = this.getOrAdd(tab);
    const from = this.getActive();
    if (from) {
      from.set('active', false);
    }
    if (to.get('url') !== tab.get('url')) {
      to.set('url', tab.get('url'));
      const view = to.get('view');
      if (view) {
        tab.get('onUrlChange')(view);
      }
    }
    to.set('active', true);
  },

  getActive() {
    return this.findWhere({ active: true });
  },

  getOrAdd(tab) {
    const url = tab.get('url');
    let to = this.find(m => m.get('url') === url ||
        (m.get('view_model') != null && m.get('view_model') === tab.get('view_model')));

    if (!to) {
      const options = {};
      if (tab.get('location')) {
        options.at = tab.get('location');
      }
      this.add(tab, options);
      to = tab;
    }
    return to;
  },
});
