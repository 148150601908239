import { Icon, Button } from 'semantic-ui-react';
import { t } from 'i18n-js';
import NewFolderModal from '../../../components/modals/NewFolderModal';
import { NewFileModal } from '../../../components/modals/NewFileModal';
import { useFilesContext } from '../Context';
import { uploadFile } from '../../../../constants/ui_urls';
import { isFileFolder } from '../../../helper';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import ButtonGroupWrapper from '../../../../components/buttons/ButtonGroupWrapper';
import Can from '../../../../permissions';
import { calculateIsNarrow } from '../../../../helpers/panel_widths';

const UploadFileButton = ({ destination, isNarrow }) => (
  <Button as="a" href={uploadFile(destination)} title={isNarrow ? t('file_list.actions.upload') : null}>
    <Icon name="upload" className={isNarrow ? 'margin-none' : null} />
    {!isNarrow && t('file_list.actions.upload')}
  </Button>
);

const FileHeaderButtons = () => {
  const { baseFolderPath, panelWidth } = useFilesContext();
  const isNarrow = calculateIsNarrow(panelWidth);

  const NewFileButton = (
    <Button title={isNarrow ? t('file_list.actions.new_file.default') : null}>
      <Icon name="file alternate" className={isNarrow ? 'margin-none' : null} />
      {!isNarrow && t('file_list.actions.new_file.default')}
    </Button>
  );

  const NewFolderButton = (
    <Button title={isNarrow ? t('file_list.actions.new_folder') : null}>
      <Icon name="folder" className={isNarrow ? 'margin-none' : null} />
      {!isNarrow && t('file_list.actions.new_folder')}
    </Button>
  );

  return (
    <ButtonGroupWrapper>
      <Can I="Write" this="WorkspaceStorage">
        <ModalWrapper trigger={NewFileButton}>
          <NewFileModal />
        </ModalWrapper>
        {isFileFolder(baseFolderPath) && (
          <ModalWrapper trigger={NewFolderButton}>
            <NewFolderModal />
          </ModalWrapper>
        )}
      </Can>
      <Can I="Upload to" this="WorkspaceStorage">
        <UploadFileButton
          destination={baseFolderPath}
          isNarrow={isNarrow}
        />
      </Can>
    </ButtonGroupWrapper>
  );
};

export default FileHeaderButtons;
