import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';

const MetadataTitleForm = ({ title, handleTitleChange, triggerErrorTitleChange }) => (
  <Form.Field inline>
    <label htmlFor="title">{t('dataset.meta.title')}</label>
    <Input
      id="title"
      defaultValue={title}
      onBlur={handleTitleChange}
      onChange={triggerErrorTitleChange}
      error={!title}
    />
  </Form.Field>
);

const mapModelsToProps = ({ datasetMeta }) => ({
  title: datasetMeta.get('title'),
  handleTitleChange: (_e) => {
    datasetMeta.updateTitle(_e.target.value);
  },
  triggerErrorTitleChange: (_e) => {
    if (_e.target.value.length <= 1) {
      datasetMeta.updateTitle(_e.target.value);
    }
  },
});

const options = {
  debounce: true,
  events: {
    datasetMeta: ['change:informationTitle'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(MetadataTitleForm);
