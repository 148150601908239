import chorus from '../chorus';
import _ from '../underscore';
import t, { I18n } from '../intl';
import Backbone from '../vendor/backbone';
import Bare from '../views/bare_view';

export default Bare.extend({

  bindCallbacks() {
    if (chorus.user) this.listenTo(chorus.user, 'change', this.render);
  },

  dependentResourceNotFound() {
    chorus.pageOptions = this.failurePageOptions();
    Backbone.history.loadUrl('/invalidRoute');
  },

  dependentResourceForbidden(model) {
    chorus.pageOptions = this.failurePageOptions();

    const error = model.serverErrors;
    if (error && error.type) {
      Backbone.history.loadUrl('/forbidden');
      return;
    }

    Backbone.history.loadUrl('/unauthorized');
  },

  unprocessableEntity(model) {
    chorus.pageOptions = this.failurePageOptions();

    const errors = model.serverErrors;
    if (errors) {
      const undefinedErrorTitle = 'unprocessable_entity.unidentified_error.title';
      if (errors.record) {
        const code = `record_error.${errors.record}`;
        const title = I18n.lookup(`${code}_title`);
        chorus.pageOptions = {
          title: title || t(undefinedErrorTitle),
          text: t(code, errors),
          message: errors.message,
        };
      } else {
        chorus.pageOptions = {
          title: t(undefinedErrorTitle),
          text: errors.message,
        };
      }
    }

    Backbone.history.loadUrl('/unprocessableEntity');
  },

  handleFetchErrorsFor(resource) {
    this.listenTo(resource, 'resourceNotFound', this.dependentResourceNotFound);
    this.listenTo(resource, 'resourceForbidden', _.bind(this.dependentResourceForbidden, this, resource));
    this.listenTo(resource, 'unprocessableEntity', _.bind(this.unprocessableEntity, this, resource));
  },

  failurePageOptions() {},
});
