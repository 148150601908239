import { useState } from 'react';
import { t } from 'i18n-js';
import {
  Modal, Form, Icon, Header,
} from 'semantic-ui-react';
import ModalActionButtons from '../../../../../components/buttons/ModalActionButtons';
import HeaderError from '../../../../../components/errors/HeaderError';
import FormValidationError from '../../../../../components/errors/FormValidationError';
import {
  findErrors, hasError, invalidCharacters, invalidCharactersIn, invalidFileNames,
} from '../../../../../utilities/validation';
import { validationLengths, validationFields } from '../../../../../constants/validation';
import { useRename } from '../../../service';
import { useFileContext } from '../../Context';
import toastMessage from '../../../../../utilities/toast';
import FileTypeDropdown from '../../../../components/FileTypeDropdown';
import { calculateFileNameExtension, calculateSupportedFileType } from '../../../../helper';
import { supportedFileTypes } from '../../../../constants';

const RenameModal = ({ setModalClose, setIsBusy }) => {
  const {
    folder, name, id, type,
  } = useFileContext();
  const calculatedFileType = calculateSupportedFileType(name, type);
  const [newFileName, setNewFileName] = useState(name);
  const [newFileType, setNewFileType] = useState(calculatedFileType);
  const [validationErrors, setValidationErrors] = useState([]);

  const handleNameChange = (e, { value }) => {
    setNewFileName(value);
    const newErrors = validationErrors.filter((err) => err.field !== validationFields.NAME);
    setValidationErrors(newErrors);
  };

  const createFilename = () => {
    const fileExtension = calculateFileNameExtension(newFileName);
    if (type === 'folder' || newFileType === supportedFileTypes.OTH || newFileType === fileExtension) {
      return newFileName.trim();
    }

    return `${newFileName}.${newFileType}`;
  };

  const isValidEntry = !!newFileName && (name !== createFilename());

  const checkForErrors = (fileName) => {
    const trimmedName = fileName.trim();
    const errors = [];
    if (trimmedName?.length > validationLengths.NAME) {
      errors.push({
        field: 'name',
        message: (t('field_error.TOO_LONG', { field: t('modals.rename_file.name'), count: validationLengths.NAME })),
      });
    }

    if (trimmedName && invalidCharactersIn(trimmedName)) {
      errors.push({
        field: 'name',
        message: (
          t('field_error.INVALID_CHARACTERS', {
            field: t('modals.rename_file.name'),
            characters: invalidCharacters,
          })),
      });
    }

    if (trimmedName && invalidFileNames(trimmedName)) {
      errors.push({
        field: 'name',
        message: (t('field_error.INVALID_NAME')),
      });
    }
    return errors;
  };

  const {
    renameFile,
    isLoading,
    isError,
    error,
    resetResponse,
  } = useRename(id);

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleSubmitting = () => {
    setIsBusy(true);
    const newName = createFilename();
    const errors = checkForErrors(newFileName);
    setValidationErrors(errors);

    if (errors.length > 0) {
      setIsBusy(false);
      return;
    }

    const fileData = {
      workfile: {
        id,
        file_name: newName.trim(),
        folder,
      },
    };

    renameFile(fileData, {
      onSuccess: () => {
        setIsBusy(false);
        handleModalClose();
        toastMessage(
          'success',
          t('file_list.actions.rename_success', { fileName: name, newFileName: newName }),
          'renameSuccess',
        );
      },
      onError: () => {
        setIsBusy(false);
      },
    });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="edit" title="rename" />
        <Header.Content content={
          type === 'folder' ? t('file_list.actions.rename_folder') : t('file_list.actions.rename_file')
        }
        />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label htmlFor="name">
              {t('modals.rename_file.name')}
              {hasError(validationErrors, validationFields.NAME) && (
                <FormValidationError errorMsg={(findErrors(validationErrors, validationFields.NAME)[0]).message} />
              )}
            </label>
            <Form.Input
              id="name"
              autoFocus
              placeholder={t('modals.rename_file.name_placeholder')}
              maxLength={validationLengths.NAME}
              value={newFileName}
              onChange={handleNameChange}
              error={hasError(validationErrors, validationFields.NAME)}
            />
            {type !== 'folder' && (
              <FileTypeDropdown
                handleDropdownChange={(e, { value }) => setNewFileType(value)}
                value={newFileType}
              />
            )}
          </Form.Field>
        </Form>
      </Modal.Content>
      <ModalActionButtons
        loading={isLoading}
        onClose={handleModalClose}
        onSubmit={handleSubmitting}
        isValidEntry={isValidEntry}
        error={validationErrors.length > 0}
        submitText={type === 'folder' ? t('file_list.actions.rename_folder') : t('file_list.actions.rename_file')}
      />
    </>
  );
};

export default RenameModal;
