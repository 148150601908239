import { Button, Icon } from 'semantic-ui-react';
import { t } from 'i18n-js';
import ButtonGroupWrapper from '../../../../components/buttons/ButtonGroupWrapper';
import Can from '../../../../permissions';
import { useDatabaseTablesContext } from '../Context';
import { calculateIsNarrow } from '../../../../helpers/panel_widths';
import { newDatabaseTablePanel } from '../../../../constants/ui_urls';

const DatabaseTablesHeaderButtons = () => {
  const { panelWidth } = useDatabaseTablesContext();
  const isNarrow = calculateIsNarrow(panelWidth);

  return (
    <ButtonGroupWrapper>
      <Can I="Write" this="WorkspaceDatabase">
        <Button
          title={isNarrow ? t('tables_list.actions.new_database_table') : null}
          href={newDatabaseTablePanel()}
        >
          <Icon name="table" data-testid="new-database-table-button-icon" className={isNarrow ? 'margin-none' : null} />
          {!isNarrow && t('tables_list.actions.new_database_table')}
        </Button>
      </Can>
    </ButtonGroupWrapper>
  );
};

export default DatabaseTablesHeaderButtons;
