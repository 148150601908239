import { t } from 'i18n-js';
import {
  Segment, Grid, Header,
} from 'semantic-ui-react';
import MarkdownRender from '../../../../components/text_editors/MarkdownRender';
import { isRichText } from '../../../../utilities/editor';
import { fileNameFromPath } from '../helper';

const DatabaseTableCreationSummarySegment = ({
  title,
  subtitle,
  description,
  url,
  sourceFile,
  sourceMetadataFile,
  tableSize,
  rowsLength,
  columnsLength,
}) => (
  <Segment attached="bottom">
    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column width={5}>
          <Header as="h6" content={t('database_tables_new.step5.title')} className="margin-none lighter" />
          <h3 className="margin-none">{title}</h3>
          <Header as="h6" content={t('database_tables_new.step5.name')} className="margin-none lighter" />
          <h4 className="margin-none">{subtitle}</h4>
        </Grid.Column>
        <Grid.Column width={10}>
          <Header as="h6" content={t('database_tables_new.step5.description')} className="margin-none lighter" />
          <MarkdownRender
            value={description}
            isRichText={isRichText(description)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column width={5}>
          <Header as="h6" content={t('database_tables_new.step5.source')} className="margin-none lighter" />
          <a href={url} target="_blank" rel="noreferrer">{url}</a>
        </Grid.Column>
        <Grid.Column width={5}>
          <Header as="h6" content={t('database_tables_new.step5.csv_file')} className="margin-none lighter" />
          <p>{fileNameFromPath(sourceFile) || 'none'}</p>
        </Grid.Column>
        <Grid.Column width={5}>
          <Header as="h6" content={t('database_tables_new.step5.tdf_file')} className="margin-none lighter" />
          <p>{fileNameFromPath(sourceMetadataFile) || 'none'}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column width={5}>
          <Header as="h6" content={t('database_tables_new.step5.size')} className="margin-none lighter" />
          <p>{tableSize}</p>
        </Grid.Column>
        <Grid.Column width={5}>
          <Header as="h6" content={t('database_tables_new.step5.rows')} className="margin-none lighter" />
          <p>{t('database_tables_new.step5.rows_content', { rowsLength })}</p>
        </Grid.Column>
        <Grid.Column width={5}>
          <Header as="h6" content={t('database_tables_new.step5.columns')} className="margin-none lighter" />
          <p>{columnsLength}</p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

export default DatabaseTableCreationSummarySegment;
