import { useMutation } from '@tanstack/react-query';
import { workspaceDeleteTable } from '../../../constants/api_urls';
import { deleter } from '../../../adapters/service';
import serverErrorMessage from '../../../utilities/server_errors';
import { invalidateTableLists } from '../../service';

const useMultipleDelete = (selectedTables) => {
  const successfulDelete = [];
  const failedToDelete = [];
  const errorMessages = [];
  const deleteTables = ({ withDependencies }) => Promise.allSettled(
    selectedTables.map((selectedTable) => deleter(workspaceDeleteTable(selectedTable.id, withDependencies))
      .then(() => successfulDelete.push({ ...selectedTable }))
      .catch((error) => {
        failedToDelete.push({ ...selectedTable });
        errorMessages.push(`${selectedTable.name} : ${error.message || serverErrorMessage(error)}`);
      })),
  );

  const {
    mutate,
    isError,
    isLoading,
    error,
    reset,
  } = useMutation(deleteTables, {
    onSuccess: invalidateTableLists,
  });

  return {
    deleteTables: mutate,
    isLoading,
    isError,
    error: error?.message || serverErrorMessage(error),
    resetResponse: reset,
    failedToDelete,
    successfulDelete,
    errorMessages,
  };
};

export default useMultipleDelete;
