/* eslint-disable object-curly-newline */
import { createContext } from 'react';
import { createContextualCan, useAbility } from '@casl/react';
import { t } from 'i18n-js';
import configurePermissions from './configure';
import { useWorkspaceAdmin } from '../adapters/Workspace';
import LoadingPage from '../pages/LoadingPage';
import ErrorPage, { UnuathorizedPage } from '../pages/ErrorPage';

const PermissionsContext = createContext();

export const PermissionsProvider = ({ permissions, children }) => (
  <PermissionsContext.Provider value={configurePermissions(permissions || [])}>
    {children}
  </PermissionsContext.Provider>
);

export const PermissionsWrapper = ({ uuid, children }) => {
  const { workspace, isLoading, isError, error } = useWorkspaceAdmin(uuid);

  if (!workspace && isLoading) {
    return LoadingPage();
  }

  if (!workspace && isError) {
    return <ErrorPage header={t('startup.error_load2')} error={error} />;
  }

  if (!workspace.permissions?.includes('Workspace.Interactive')) {
    return (<UnuathorizedPage />);
  }

  return PermissionsProvider({ permissions: workspace?.permissions, children });
};

// A permissions hook to check a permssions against context.
// Basically a way to wrap the Can library function and be able to pass in a UI readable operation
export const usePermissions = (action, instance) => {
  const ability = useAbility(PermissionsContext);
  return ability.can(action, instance);
};

// When checking multiple permissions it is easier to make direct use of the ability
export const usePermissionCheck = () => {
  const ability = useAbility(PermissionsContext);
  return ability.can.bind(ability);
};

/**
 * Higher-order function that wraps a component in a permission check - expects the component to be a function and
 * not something exotic
 * @param action
 * @param instance
 */
export const requirePermission = (action, instance) => (Component) => (props) => {
  if (!usePermissions(action, instance)) {
    return null;
  }
  return Component(props);
};

const Can = createContextualCan(PermissionsContext.Consumer);

export default Can;
