import _ from '../../../underscore';
import $ from '../../../jquery';
import Sidebar from '../../core/sidebar_view';
import Routing from '../../../mixins/routing';
import MiniAppRestartAlert from '../../../alerts/mini_app_restart_alert';
import t from '../../../intl';
import TabControl from '../../core/tab_control_view';
import MiniAppsSidebarInfo from '../apps/mini_apps_sidebar_info';
import MiniAppSidebarMiniapps from './mini_app_sidebar_miniapps';
import MiniAppsAndPods from '../../../collections/mini_apps_and_pods';
import chorus from '../../../chorus';

export default Sidebar.include(Routing).extend({

  templateName: 'mini_apps/app_sidebar',

  events: {
    'click .toggle-log': 'toggleLog',
    'click .save-log': 'saveLog',
    'click .restart': 'launchRestartMiniAppConfirmAlert',
    'click .refresh': 'refreshMiniApp',
  },

  subviews: {
    '.tab_control': 'tabs',
  },

  restarting: false,

  setup() {
    this.app = this.options.app;
    this.setupTabs();
    this.subscribePageEvent('mini_app:logs_available', this.enableLogsButton);
    this.subscribePageEvent('mini_app:saved_logs', this.enableLogsButton);
    this.subscribePageEvent('mini_app:ready', this.getExtendedModel);
    this.subscribePageEvent('mini_app:run_error', this.resetLogsButton);
    this.subscribePageEvent('mini_app:restart', this.restartMiniApp);
  },

  additionalContext() {
    const context = {};

    if (this.options.apps) {
      context.apps = this.options.apps.models.map(function extendContext(app) {
        return {
          isCurrent: app.id === this.options.model.id,
          app,
        };
      }, this);
      context.workspaceID = this.options.workspace.id;
    }

    context.currentAppName = this.options.model.getCurrentName();
    context.logsAvailable = this.options.app.logsAvailable;
    return context;
  },

  getExtendedModel() {
    const extendedApps = new MiniAppsAndPods([], {
      workspace: this.options.workspace,
    });

    extendedApps.fetchIfNotLoaded().then(() => {
      const extendedModel = _.find(extendedApps.models, m => m.id === this.options.model.id);
      if (extendedModel) {
        // this.model = extendedModel;
        if (extendedModel.attributes.running) this.updateTabs(this.app, extendedModel);
      }
    });
  },

  setupTabs() {
    this.setupAllTabs();
    this.registerSubView(this.tabs);
  },

  setupAllTabs() {
    this.tabs = new TabControl(['miniapps', 'information']);
    this.tabs.information = new MiniAppsSidebarInfo({ model: this.model, workspace: this.options.workspace });
    this.tabs.miniapps = new MiniAppSidebarMiniapps({
      model: this.model,
      workspace: this.options.workspace,
      apps: this.options.apps,
    });

  },

  updateTabs(app = this.app, model = this.model) {
    this.app = app;
    this.model = model;
    this.tabs.resetSelectedTab();
    this.setupTabs();
    this.render();
  },

  enableLogsButton() {
    this.$('.toggle-log').attr('disabled', false);
    this.$('.save-log').attr('disabled', false);
  },

  resetLogsButton(disable = false) {
    if (this.$('.toggle-log').text() === t('mini_app.sidebar.hide_log')) {
      this.toggleLog();
    }
    if (disable) {
      this.$('.toggle-log').attr('disabled', true);
      this.$('.save-log').attr('disabled', true);
    }
  },

  toggleLog() {
    this.$('.toggle-log').text((i, text) =>
      (text === t('mini_app.sidebar.view_log') ? t('mini_app.sidebar.hide_log') : t('mini_app.sidebar.view_log')));
    this.options.showLog();

    return false;
  },

  saveLog() {
    this.$('.save-log').attr('disabled', true);
    this.options.saveLog();
  },

  refreshMiniApp() {
    chorus.PageEvents.trigger('mini_app:run');
  },

  launchRestartMiniAppConfirmAlert(e) {
    e.preventDefault();
    const alert = new MiniAppRestartAlert();
    alert.launchModal();
  },

  restartMiniApp() {
    if (!this.restarting) {
      this.options.restartMiniApp();
    }
    this.restarting = true;
    chorus.PageEvents.trigger('mini_app:restarting');
    // eslint-disable-next-line no-return-assign
    setTimeout(() => this.restarting = false, 5000);

    this.resetLogsButton(true);
  },

  resetButton(e) {
    $(e.currentTarget).attr('disabled', false).toggleClass('loading');
  },

});
