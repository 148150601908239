import { useState } from 'react';
import Backbone from 'backbone';
import ContainerisedAppView from '../../../../../application/views/containerised_apps/containerised_app_view';
import ReactToBackboneWrapper from '../../ReactToBackboneWrapper';
import { renameTab } from '../../../tabMonitor';

const AppPanel = ({ tab, workspace }) => {
  const { component, id, config } = tab;
  const [view] = useState(
    () => new ContainerisedAppView({
      workspace,
      tab: new Backbone.Model({ active: true }),
      selectedApp: config.args[4],
    }),
  );

  const callback = () => renameTab(id, view.app.get('name'));

  return (
    <ReactToBackboneWrapper
      view={view}
      tabId={id}
      component={component}
      onceLoadedCallback={callback}
    />
  );
};

export default AppPanel;
