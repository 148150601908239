import React from 'react';
import { BackboneProvider } from 'connect-backbone-to-react';
import CopyWarningAlert from '../../../components/modals/CopyWarningAlert';
import CopyWarningModel from 'models/copy_warning';
import View from '../react_view';
import { instance } from '../../models/config';

export default View.extend({
  constructorName: 'copyWarningAlertView',

  setup(options) {
    this.workspace = options.model;
    this.tabs = options.tabs;
    this.copyWarningModel = new CopyWarningModel({
      workspaceID: this.workspace.id,
      tabs: this.tabs,
    });
  },

  renderOnce() {
    const warningEnabled = instance.copyWarningEnabled();

    const modelsMap = {
      copyWarning: this.copyWarningModel,
    };

    this.root.render(
      <BackboneProvider models={modelsMap}>
        <CopyWarningAlert />
      </BackboneProvider>,
    );

    window.addEventListener('message', (e) => {
      const key = e.message ? 'message' : 'data';
      const data = e[key];
      if ((data === 'frameCopyEvent')) {
        this.copyWarningModel.openCopyWarningAlert(warningEnabled);
      }
    }, false);
    document.addEventListener('copy', () => {
      this.copyWarningModel.openCopyWarningAlert(warningEnabled);
    });
    document.addEventListener('cut', () => {
      this.copyWarningModel.openCopyWarningAlert(warningEnabled);
    });
  },

});
