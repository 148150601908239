import { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Cookies from 'js-cookie';
import { t } from 'i18n-js';
import { FEATURE } from '../../utilities/features';

const PanelTracker = ({
  panelIsActive, workspaceName, panelName, children,
}) => {
  const { trackPageView } = useMatomo();

  const trackingEnabled = FEATURE.SITE_TRACKING_ENABLED && Cookies.get('workspacesTrackingConsent') === 'true';
  const panelHasChanged = trackingEnabled && panelIsActive && panelName && document.title !== `${t('application_title')} - ${panelName}`;

  useEffect(() => {
    if (panelHasChanged) {
      trackPageView({ documentTitle: `${workspaceName}-${panelName}` });
    }
  }, [panelHasChanged, panelName, trackPageView, workspaceName]);

  return children;
};

export default PanelTracker;
