import chorus from '../chorus';
import _ from '../underscore';
import $ from '../jquery';
import t from '../intl';
import Confirm from './confirm';
import Base from './base';
import { toAirlock } from '../presenters/activity_presenters';
import Routing from '../mixins/routing';

export default Confirm.include(Routing).extend({
  constructorName: 'RejectAirlockConfirmAlert',
  focusSelector: 'button.cancel',
  additionalClass: 'error',
  persistent: true, // here for documentation, doesn't actually do anything as we've overwritten bindCallbacks

  events: _.extend({}, Base.prototype.events, {
    'click button.submit': 'rejectAirlock',
  }),

  bindCallbacks() {
    this.model.bind('saved', this.airlockRejected, this);
    this.model.bind('saveFailed', this.render, this);
  },

  close() {
    this.model.unbind('saved', this.airlockRejected);
    this.model.unbind('saveFailed', this.render);
  },

  rejectAirlock(e) {
    e.preventDefault();
    this.model.save({}, { method: 'create', isReject: true });
    this.$('button.submit').startLoading('airlock.reject.alert.rejecting');
  },

  airlockRejected() {
    this.closeModal();
    chorus.toast(this.rejectMessage, $.noop);
    chorus.PageEvents.trigger('airlock:saved', this.model);
    if (this.redirectUrl) {
      this.navigate(this.redirectUrl);
    }
  },

  makeModel(...args) {
    this._super('makeModel', args);
    const { activity } = this.options;
    this.model = toAirlock(activity);
    this.text = t('airlock.reject.alert.text');
    this.title = t('airlock.reject.alert.title');
    this.ok = t('airlock.reject.alert.ok');
    this.rejectMessage = 'airlock.reject.alert.message';
  },
});
