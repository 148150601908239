import _ from '../underscore';
import Model from './models';
import { Time, Year, Month, Day } from 'utilities/validation_regexes';
import DbHelpers from '../mixins/db_helpers';

const isTrue = columnName => `${columnName} = TRUE`;

const isFalse = columnName => `${columnName} = FALSE`;

const isNull = columnName => `${columnName} IS NULL`;

const isNotNull = columnName => `${columnName} IS NOT NULL`;

// quote single
const qs = string => `'${DbHelpers.sqlEscapeString(string)}'`;

const makeGenerate = (comparator, options) => {
  const opts = options || {};
  _.defaults(opts, {
    inputPrefix: '',
    inputSuffix: '',
  });

  return function build(columnName, inputValue) {
    return inputValue ?
      `${columnName} ${comparator} ${qs(opts.inputPrefix + inputValue + opts.inputSuffix)}` :
      '';
  };
};

export const StringFilter = Model.extend({
  type: 'String',

  comparators: {
    equal: { usesInput: true, generate: makeGenerate('=') },
    not_equal: { usesInput: true, generate: makeGenerate('<>') },
    null: { usesInput: false, generate: isNull },
    not_null: { usesInput: false, generate: isNotNull },
    like: { usesInput: true, generate: makeGenerate('LIKE') },
    begin_with: { usesInput: true, generate: makeGenerate('LIKE', { inputSuffix: '%' }) },
    end_with: { usesInput: true, generate: makeGenerate('LIKE', { inputPrefix: '%' }) },
    alpha_after: { usesInput: true, generate: makeGenerate('>') },
    alpha_after_equal: { usesInput: true, generate: makeGenerate('>=') },
    alpha_before: { usesInput: true, generate: makeGenerate('<') },
    alpha_before_equal: { usesInput: true, generate: makeGenerate('<=') },
  },

  declareValidations() {
    return true;
  },
});

export const BooleanFilter = Model.extend({
  type: 'Boolean',

  comparators: {
    true: { usesInput: false, generate: isTrue },
    false: { usesInput: false, generate: isFalse },
    null: { usesInput: false, generate: isNull },
    not_null: { usesInput: false, generate: isNotNull },
  },

  declareValidations() {
    return true;
  },
});

export const NumericFilter = Model.extend({
  type: 'Numeric',

  comparators: {
    equal: { usesInput: true, generate: makeGenerate('=') },
    not_equal: { usesInput: true, generate: makeGenerate('<>') },
    null: { usesInput: false, generate: isNull },
    not_null: { usesInput: false, generate: isNotNull },
    greater: { usesInput: true, generate: makeGenerate('>') },
    greater_equal: { usesInput: true, generate: makeGenerate('>=') },
    less: { usesInput: true, generate: makeGenerate('<') },
    less_equal: { usesInput: true, generate: makeGenerate('<=') },
  },

  declareValidations(attrs) {
    this.requirePattern('value', /^-?[0-9]+[.,]?[0-9]*$/, attrs, 'dataset.filter.number_required', 'allowBlank');
  },
});

export const TimestampFilter = Model.extend({
  type: 'Timestamp',

  comparators: {
    equal: { usesInput: true, generate: makeGenerate('=') },
    not_equal: { usesInput: true, generate: makeGenerate('<>') },
    null: { usesInput: false, generate: isNull },
    not_null: { usesInput: false, generate: isNotNull },
    greater: { usesInput: true, generate: makeGenerate('>') },
    greater_equal: { usesInput: true, generate: makeGenerate('>=') },
    less: { usesInput: true, generate: makeGenerate('<') },
    less_equal: { usesInput: true, generate: makeGenerate('<=') },
  },

  declareValidations() {
    return true;
  },
});

export const TimeFilter = Model.extend({
  type: 'Time',

  comparators: {
    equal: { usesTimeInput: true, generate: makeGenerate('=') },
    before: { usesTimeInput: true, generate: makeGenerate('<') },
    after: { usesTimeInput: true, generate: makeGenerate('>') },
    null: { usesTimeInput: false, generate: isNull },
    not_null: { usesTimeInput: false, generate: isNotNull },
  },

  declareValidations(attrs) {
    this.requirePattern('value', Time(), attrs, 'dataset.filter.time_required', 'allowBlank');
  },
});

export const DateFilter = Model.extend({
  type: 'Date',

  comparators: {
    on: { usesDateInput: true, generate: makeGenerate('=') },
    before: { usesDateInput: true, generate: makeGenerate('<') },
    after: { usesDateInput: true, generate: makeGenerate('>') },
    null: { usesDateInput: false, generate: isNull },
    not_null: { usesDateInput: false, generate: isNotNull },
  },

  declareValidations(attrs) {
    if (_.isEmpty(attrs.year) && _.isEmpty(attrs.month) && _.isEmpty(attrs.day)) { return; }

    this.requirePattern('year', Year(), attrs, 'dataset.filter.year_required');
    this.requirePattern('month', Month(), attrs, 'dataset.filter.month_required');
    this.requirePattern('day', Day(), attrs, 'dataset.filter.day_required');
  },
});

export const OtherFilter = NumericFilter.extend({
  type: 'Other',

  declareValidations() {
  },
});

