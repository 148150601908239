import { statuses } from '../../../constants';
import {
  DesktopButton,
  StartButton,
  StartingButton,
  StopButton,
  StoppingButton,
  TerminalButton,
} from './VirtualMachinesActionButtons';
import { useRandomisedVirtualMachineUrl } from '../../../helper';
import { patchVM } from '../service';

/**
 *
 * @param name
 * @param status
 * @param isFull
 * @param terminalHref
 * @param desktopHref
 * @returns {JSX.Element}
 * @constructor
 */
const VirtualMachinesCardFooter = ({
  os,
  name,
  status,
  isFull,
  otherUsersAreConnected,
  terminalHref,
  desktopHref,
}) => {
  const performAction = (action) => () => patchVM(name, action);
  const showStartButton = status === statuses.STOPPED || status === statuses.PAUSED;
  const showStartingButton = status === statuses.STARTING;
  const showStopButton = status === statuses.RUNNING || status === statuses.PAUSED;
  const showStoppingButton = status === statuses.STOPPING;
  const showDesktopButton = status === statuses.RUNNING;
  const showTerminalButton = status === statuses.RUNNING && terminalHref;

  const [randomTerminalUrl, resetRandomTerminalUrl] = useRandomisedVirtualMachineUrl(name, os);

  return (
    <>
      {showStartingButton && <StartingButton />}
      {showStartButton && <StartButton onClick={performAction(statuses.RUNNING)} />}
      {
        showTerminalButton
        && <TerminalButton isFull={isFull} href={randomTerminalUrl} onClick={resetRandomTerminalUrl} />
      }
      {showDesktopButton && <DesktopButton isFull={isFull} href={desktopHref} />}
      {showStoppingButton && <StoppingButton />}
      {
        showStopButton
        && <StopButton disabled={otherUsersAreConnected} onClick={performAction(statuses.DEALLOCATED)} />
      }
    </>
  );
};

export default VirtualMachinesCardFooter;
