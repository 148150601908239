import {
  Label, Table, Checkbox, Icon,
} from 'semantic-ui-react';
import { t } from 'i18n-js';
import InboxRowDropdown from './InboxRowDropdown';
import { useInboxContext, useInboxFileContext } from '../Context';
import { workspaceFileListElementUrl } from '../../../../constants/ui_urls';

const FileNameCell = ({
  id, type, folder, displayName,
}) => (
  <Table.Cell className="aridhia-table-name-cell">
    <a href={workspaceFileListElementUrl(id, type, folder, displayName)}>
      <Icon name="file" data-testid="file-name-icon" />
      {displayName}
    </a>
  </Table.Cell>
);

const FolderNameCell = ({
  id, type, folder, name, displayName, setPage,
}) => (
  <Table.Cell className="aridhia-table-name-cell">
    <a href={workspaceFileListElementUrl(id, type, folder, name)} onClick={() => setPage(1)}>
      <Icon name="folder" data-testid="folder-name-icon" />
      {displayName}
    </a>
  </Table.Cell>

);

const FileSizeCell = ({ fileSizeText }) => (
  <Table.Cell>
    {fileSizeText && <Label size="small" content={fileSizeText} />}
  </Table.Cell>
);

const FileSourceCell = ({ fileSource }) => (
  <Table.Cell>
    {fileSource}
  </Table.Cell>
);

const InboxTableRow = () => {
  const { selectedFiles, handleSelectedFiles, setPage } = useInboxContext();
  const {
    id,
    displayName,
    name,
    size,
    folder,
    type,
    source,
  } = useInboxFileContext();

  return (
    <Table.Row aria-label={t('inbox.accessibility.inbox_table_row')}>
      <Table.Cell collapsing>
        <Checkbox
          value={id}
          onChange={handleSelectedFiles}
          checked={(selectedFiles?.findIndex((item) => item.id === id)) >= 0}
          name={name}
          filetype={type}
        />
      </Table.Cell>
      {type === 'folder' && (
        <FolderNameCell
          displayName={displayName}
          id={id}
          name={name}
          type={type}
          folder={folder}
          setPage={setPage}
        />
      )}
      {type !== 'folder' && (
        <FileNameCell
          displayName={displayName}
          id={id}
          type={type}
          folder={folder}
        />
      )}
      <FileSizeCell fileSizeText={size} />
      <FileSourceCell fileSource={source} />
      <Table.Cell collapsing textAlign="right">
        <InboxRowDropdown />
      </Table.Cell>
    </Table.Row>
  );
};

export default InboxTableRow;
