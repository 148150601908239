import React from 'react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { Form, Message } from 'semantic-ui-react';
import { t } from 'i18n-js';

import NoCompletionInput from '../Forms';

function RuntimeOptions({
  allowDownload,
  dbRequired,
  filesRequired,
  fileMountPath,
  readOnlyFiles,
  handleAllowDownloadChange,
  handleDbRequiredChange,
  handleFilesRequiredChange,
  handleVolumeChange,
  handleReadOnlyChange,
  developer,
}) {
  return (
    <>
      <Form.Field label={t('app.form.runtime_options')} disabled={!developer} />

      <Form.Checkbox
        checked={allowDownload}
        onChange={handleAllowDownloadChange}
        name="allow_download_selection"
        label={t('app.form.download.allow')}
        disabled={!developer}
      />

      <Message id="download-disclaimer-msg" warning visible={allowDownload}>
        {t('app.form.download.disclaimer')}
        &nbsp;
        <a href="https://knowledgebase.aridhia.io/policies/shared-responsibilities" target="_blank" rel="noopener noreferrer">
          {t('app.form.download.link_text')}
        </a>
        .
      </Message>

      <Form.Checkbox
        checked={dbRequired}
        onChange={handleDbRequiredChange}
        name="db_access_selection"
        label={t('app.form.db_access')}
        disabled={!developer}
      />

      <Form.Checkbox
        checked={filesRequired}
        onChange={handleFilesRequiredChange}
        name="files_access_selection"
        label={t('app.form.files_access.required')}
        disabled={!developer}
      />

      {filesRequired && (
        <NoCompletionInput
          required
          defaultValue={fileMountPath}
          onChange={handleVolumeChange}
          id="volume-mount"
          label={t('app.form.volume_mount')}
          disabled={!developer}
        />
      )}

      {filesRequired && (
        <Form.Checkbox
          checked={readOnlyFiles}
          onChange={handleReadOnlyChange}
          name="file-access-type"
          label={t('app.form.files_access.read_only')}
          disabled={!developer}
        />
      )}
    </>
  );
}

const mapModelsToProps = ({ containerisedApp }) => ({
  fileMountPath: containerisedApp.get('fileMountPath'),
  allowDownload: containerisedApp.get('allowDownload'),
  dbRequired: containerisedApp.get('dbAccess'),
  filesRequired: containerisedApp.get('filesRequired'),
  readOnlyFiles: containerisedApp.get('readOnlyFiles'),
  isEditing: containerisedApp.get('isEditing'),
  developer: containerisedApp.get('developer'),
  handleVolumeChange: (_, data) => {
    containerisedApp.updateFileMountPath(data.value);
  },
  handleAllowDownloadChange: () => {
    containerisedApp.toggleAllowDownload();
  },
  handleDbRequiredChange: () => {
    containerisedApp.toggleDbRequired();
  },
  handleFilesRequiredChange: () => {
    containerisedApp.toggleFilesRequired();
  },
  handleReadOnlyChange: () => {
    containerisedApp.toggleReadOnlyFiles();
  },
  handleSaveAndClose: () => {
    containerisedApp.closeTab();
  },
});

const options = {
  debounce: true,
  events: {
    containerisedApp: [
      'filesRequired',
      'fileMountPath',
      'allowDownload',
      'dbAccess',
      'readOnlyFiles',
      'isEditing',
      'developer',
    ].map((s) => `change:${s}`),
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(RuntimeOptions);
