import React from 'react';
import { Form, TextArea } from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';

const MetadataDescriptionForm = ({ description, handleDescriptionChange }) => (
  <Form.Field inline>
    <label htmlFor="description">{t('dataset.meta.description')}</label>
    <TextArea
      id="description"
      defaultValue={description}
      onBlur={handleDescriptionChange}
    />
  </Form.Field>
);

const mapModelsToProps = ({ datasetMeta }) => ({
  description: datasetMeta.get('description') ? datasetMeta.get('description') : '',
  handleDescriptionChange: (_e) => {
    datasetMeta.updateDescription(_e.target.value);
  },
});

const options = {
  debounce: true,
  events: {
    datasetMeta: ['change:informationDescription'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(MetadataDescriptionForm);
