import DatabaseTableCreationConfirmDescription from './DatabaseTableCreationConfirmDescription';
import DatabaseTableCreationConfirmColumns from './DatabaseTableCreationConfirmColumns';

const DatabaseTableCreationConfirm = () => (
  <>
    <DatabaseTableCreationConfirmDescription />
    <DatabaseTableCreationConfirmColumns />
  </>
);

export default DatabaseTableCreationConfirm;
