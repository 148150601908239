import { useState } from 'react';
import {
  statuses, statusesIndicatingChange, supportedVirtualMachineTypes, operationToStatusMapping,
} from './constants';
import { formatDayMonthYearDate } from '../helpers/dates';
import { virtualMachineUrl } from '../constants/ui_urls';

/**
 * Renames stopped -> paused and deallocated -> stopped
 * From the users point of view this makes it clearer that
 * a 'stopped' vm is still being paid for.
 *
 * @param status
 * @returns {*|string}
 */
export const transformStatus = (status) => {
  switch (status) {
    case (statuses.STOPPING):
      return statuses.PAUSING;
    case (statuses.STOPPED):
      return statuses.PAUSED;
    case (statuses.DEALLOCATING):
      return statuses.STOPPING;
    case (statuses.DEALLOCATED):
      return statuses.STOPPED;
    default:
      return status;
  }
};

export const statusColor = (status) => {
  switch (status) {
    case statuses.STARTING:
    case statuses.STOPPING:
    case statuses.PAUSING:
      return 'orange';
    case statuses.RUNNING:
      return 'green';
    case statuses.STOPPED:
    case statuses.PAUSED:
      return 'red';
    default: return 'grey';
  }
};

const uuidIs = (uuid) => (object) => object.uuid === uuid;
const isUserConnected = (connectedUsers, userUuid) => connectedUsers.some(uuidIs(userUuid));

const maxUsers = (os, tags) => (os === 'Linux' ? tags.desktops : 1);

const isComplete = ({ completed_at: completedAt }) => (completedAt !== null);
const operationTargetStatus = ({ operation_name: operationName }) => (operationToStatusMapping[operationName]);

/**
 * If there is an ongoing operation then the VM is either starting or deallocating
 * otherwise use the status.
 *
 * @param operation
 * @param status
 * @returns {*|string}
 */
const combineOperationAndStatus = (operation, status) => {
  if (!operation || isComplete(operation)) {
    return status;
  }
  return operationTargetStatus(operation);
};

/* We only want to display provisioned VMs */
const isProvisioned = (vm) => !(vm?.tags?.needsProvisioning);

/**
 * Creates a function that can be used to transform the data returned from the vm service
 * into the properties that the UI needs.
 *
 * @param userUuid
 * @returns {function(*): *}
 */
export const transformData = (userUuid) => (response) => ({
  vms: response.data.filter(isProvisioned).map(
    ({
      connected_users: connectedUsers, name, latest_operation: operation, power_state: powerState, os, tags,
    }) => {
      const numberOfConnectedUsers = connectedUsers.length;
      const otherUsersAreConnected = connectedUsers.some((u) => u.uuid !== userUuid);

      return {
        numberOfConnectedUsers,
        otherUsersAreConnected,
        isUserConnected: isUserConnected(connectedUsers, userUuid),
        name,
        displayName: tags?.displayName || name,
        os,
        createdAt: tags?.createdAt ? formatDayMonthYearDate(tags.createdAt) : null,
        status: transformStatus(combineOperationAndStatus(operation, powerState)),
        isFull: numberOfConnectedUsers >= maxUsers(os, tags),
        terminalHref: os === supportedVirtualMachineTypes.LINUX && virtualMachineUrl(name, os),
        desktopHref: virtualMachineUrl(name, os, true),
      };
    },
  ),
  refresh_pending: response.refresh_pending,
});

/**
 * Returns a variable tracking a virtual machine URL and a function to
 * reset its value. This is needed to produce new random termnial panel URLs
 * every time the user opens a new one
 *
 * @param name
 * @param os
 * @returns {[string, function]}
 */
export function useRandomisedVirtualMachineUrl(name, os) {
  const [randomUrl, setRandomUrl] = useState(virtualMachineUrl(name, os));
  const resetRandomUrl = () => {
    setRandomUrl(virtualMachineUrl(name, os));
  };
  return [randomUrl, resetRandomUrl];
}

const inChangingState = (vm) => (
  (vm.latest_operation && vm.latest_operation.completed_at == null)
  || statusesIndicatingChange.includes(vm.power_state)
);

/**
 * A function that can be passed into a react-query useQuery. The data to be a list of
 * vm objects, if any of the VMs are currently changing state or the server indicates
 * that the list if currently in the process of being refreshed then return 5 seconds,
 * otherwise we can stop refreshing.
 *
 * @param _ syntax changes in react-query 5 and it will no longer provide the selected data
 * @param query the query that this has been passed as an option to
 * @returns {number|false}
 */
export const refetchInterval = (_, query) => {
  const response = query.state.data;

  // first time this is called there is no data
  if (!response) {
    return false;
  }

  if (response.refresh_pending || response.data?.some(inChangingState)) {
    return 1000;
  }
  return false;
};
