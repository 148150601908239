import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import boxplot from './boxplot_chart_configuration_view';
import timeseries from './timeseries_chart_configuration_view';
import histogram from './histogram_chart_configuration_view';
import heatmap from './heatmap_chart_configuration_view';
import frequency from './frequency_chart_configuration_view';
import DatasetFilterWizard from './dataset_filter_wizard_view';
import ResultsConsole from '../workfiles/results_console_view';
import Error from '../../alerts/error_alert';
import { workspace } from '../../utilities/workspace_assoc';
import { schema, preview } from '../../utilities/schema_assoc';
import View from '../loading_view';

const ChartConfigurations = {
  boxplot,
  timeseries,
  histogram,
  heatmap,
  frequency,
};

const buildForType = (chartType, options) => new ChartConfigurations[chartType](options);

export default View.extend({
  templateName: 'dataset_content_details',
  constructorName: 'DatasetContentDetails',
  persistent: true,

  subviews: {
    '.data_preview': 'resultsConsole',
    '.filters': 'filterWizardView',
    '.chart_config': 'chartConfig',
  },

  events: {
    'click .preview': 'dataPreview',
    'click .create_chart .cancel': 'cancelVisualization',
    'click .create_chorus_view .cancel': 'cancelCreateChorusView',
    'click .edit_chorus_view .cancel': 'cancelEditChorusView',
    'click .edit_chorus_view .save': 'saveChorusView',
    'click .chart_icon': 'selectVisualization',
    'click .close_errors': 'closeErrorWithDetailsLink',
    'click .view_error_details': 'viewErrorDetails',
    'click a.select_all': 'triggerSelectAll',
    'click a.select_none': 'triggerSelectNone',
    'mouseenter .chart_icon': 'showTitle',
    'mouseleave .chart_icon': 'showSelectedTitle',
    'click button.visualize': 'startVisualizationWizard',
    'click button.derive': 'startCreateChorusViewWizard',
    'click button.edit': 'startEditChorusViewWizard',
  },

  setup() {
    this.subscribePageEvent('action:closePreview', this.closeDataPreview);
    this.dataset = this.options.dataset;
    this.resultsConsole = new ResultsConsole({
      titleKey: 'dataset.data_preview',
      enableResize: true,
      enableExpander: true,
      enableClose: true,
      showDownloadDialog: true,
      dataset: this.dataset,
    });
    this.filterWizardView = new DatasetFilterWizard({ columnSet: this.collection });

    this.statistics = this.dataset.statistics();
    this.statistics.fetchIfNotLoaded();

    if (this.options.inDeriveChorusView) {
      this.setupDeriveChorusView = true;
    };

    this.listenTo(this.statistics, 'loaded', this.render);
    this.listenTo(this.collection, 'add remove', this.updateColumnCount);
  },

  dataPreview(e) {
    e.preventDefault();

    this.$('.column_count').addClass('hidden');
    this.$('.edit_chorus_view_info').addClass('hidden');
    this.$('.data_preview').removeClass('hidden');
    this.resultsConsole.execute(preview(this.dataset));
  },

  closeDataPreview() {
    if (!this.inDeriveChorusView) {
      if (!this.options.inEditChorusView) {
        this.$('.column_count').removeClass('hidden');
      } else {
        this.$('.edit_chorus_view_info').removeClass('hidden');
      }
    }
    this.$('.data_preview').addClass('hidden');
  },

  postRender() {
    if (this.setupDeriveChorusView) {
      this.setupDeriveChorusView = false;
      _.delay(this.startCreateChorusViewWizard.bind(this), 500);
    }
  
    if (this.options.inEditChorusView) {
      this.showEditChorusViewWizard();
    }

    chorus.search({
      input: this.$('input.search'),
      list: this.options.$columnList,
    });

    if (this.collection.serverErrors && _.keys(this.collection.serverErrors).length) {
      this.showErrorWithDetailsLink(this.collection);
    }
    this.showErrors(this.dataset);
  },

  triggerSelectAll(e) {
    e && e.preventDefault();
    this.collection.trigger('column:select_all');
  },

  triggerSelectNone(e) {
    e && e.preventDefault();
    this.collection.trigger('column:select_none');
  },

  startVisualizationWizard() {
    this.resultsConsole.clickClose();
    this.$('.chart_icon:eq(0)').click();
    this.$('.column_count').addClass('hidden');
    this.$('.info_bar').removeClass('hidden');
    this.$('.definition').addClass('hidden');
    this.$('.create_chart').removeClass('hidden');
    this.$('.filters').removeClass('hidden');
    this.filterWizardView.options.showAliasedName = false;
    this.filterWizardView.resetFilters();
    this.dataset.trigger('start:visualization');
    // Hide content panel....

    this.$('.rsh-columnFilter').hide();
  },

  selectVisualization(e) {
    const type = $(e.target).data('chart_type');
    this.$('.create_chart .cancel').data('type', type);
    this.$('.chart_icon').removeClass('selected');
    $(e.target).addClass('selected');
    this.showTitle(e);
    this.showVisualizationConfig(type);
  },

  cancelVisualization(e) {
    e.preventDefault();
    this.$('.definition').removeClass('hidden');
    this.$('.create_chart').addClass('hidden');
    this.$('.filters').addClass('hidden');
    this.$('.column_count').removeClass('hidden');
    this.$('.info_bar').addClass('hidden');
    this.$('.chart_config').addClass('hidden');
    this.dataset.trigger('cancel:visualization');
    if (this.chartConfig) {
      this.chartConfig.teardown(true);
      delete this.chartConfig;
    }
    this.$('.rsh-columnFilter').show();
    chorus.page.trigger('resized');
  },

  startCreateChorusViewWizard() {
    this.trigger('transform:sidebar', 'chorus_view');
    this.$('.chorusview').addClass('selected');
    this.$('.definition').addClass('hidden');
    this.$('.create_chart').addClass('hidden');
    this.$('.create_chorus_view').removeClass('hidden');
    this.$('.chorus_view_info').removeClass('hidden');
    this.$('.column_count').addClass('hidden');
    this.$('.filters').removeClass('hidden');
    this.$('.rsh-derivedViewColumnFilter').removeClass('hidden');
    this.filterWizardView.options.showAliasedName = true;
    this.filterWizardView.resetFilters();
    this.inDeriveChorusView = true;
    this.$('.chorus_view_info input.search').trigger('textchange');
    this.$('.rsh-columnFilter').hide();
  },

  cancelCreateChorusView(e) {
    e.preventDefault();
    this.dataset.trigger('cancel:sidebar', 'chorus_view');
    this.$('.definition').removeClass('hidden');
    this.$('.create_chorus_view').addClass('hidden');
    this.$('.filters').addClass('hidden');
    this.$('.column_count').removeClass('hidden');
    this.$('.chorus_view_info').addClass('hidden');
    this.$('.rsh-derivedViewColumnFilter').addClass('hidden');

    this.$('.column_count input.search').trigger('textchange');
    this.subscribePageEvent('action:closePreview', this.closeDataPreview);
    this.inDeriveChorusView = false;
    this.$('.rsh-columnFilter').show();
  },

  startEditChorusViewWizard() {
    this.trigger('transform:sidebar', 'edit_chorus_view');
    this.showEditChorusViewWizard();
    this.trigger('dataset:edit');
  },

  showEditChorusViewWizard() {
    this.$('.edit_chorus_view').removeClass('hidden');
    this.$('.create_chorus_view').addClass('hidden');
    this.$('.create_chart').addClass('hidden');
    this.$('.definition').addClass('hidden');

    this.$('.edit_chorus_view_info').removeClass('hidden');
    this.$('.column_count').addClass('hidden');
  },

  cancelEditChorusView(e) {
    e.preventDefault();
    this.$('.edit_chorus_view').addClass('hidden');
    this.$('.edit_chorus_view_info').addClass('hidden');
    this.$('.column_count').removeClass('hidden');
    this.$('.definition').removeClass('hidden');
    this.dataset.set({ query: this.dataset.initialQuery });
    this.dataset.trigger('cancel:sidebar', 'chorus_view');
    this.dataset.trigger('dataset:cancelEdit');
  },

  saveChorusView() {
    this.dataset.trigger('dataset:saveEdit');
  },

  closeErrorWithDetailsLink(e) {
    e && e.preventDefault();
    this.$('.dataset_errors').addClass('hidden');
  },

  viewErrorDetails(e) {
    e.preventDefault();

    const alert = new Error({ model: this.errorSource });
    alert.launchModal();
    this.$('.errors').addClass('hidden');
  },

  showTitle(e) {
    this.$('.chart_type_title').addClass('hidden');
    this.$(`.chart_type_title.${$(e.target).data('chart_type')}`).removeClass('hidden');
  },

  showVisualizationConfig(chartType) {
    if (this.chartConfig) { this.chartConfig.teardown(true); }

    const options = { model: this.dataset, collection: this.collection, errorContainer: this };
    this.chartConfig = buildForType(chartType, options);
    this.chartConfig.filters = this.filterWizardView.collection;

    this.$('.chart_config').removeClass('hidden');
    this.renderSubview('chartConfig');
  },

  showSelectedTitle() {
    this.$('.chart_type_title').addClass('hidden');
    const type = this.$('.selected').data('chart_type');
    this.$(`.chart_type_title.${type}`).removeClass('hidden');
  },

  additionalContext() {
    const canUpdate = workspace(this.dataset) && workspace(this.dataset).canUpdate();
    return {
      definition: this.dataset.isChorusView() ? this.dataset.get('query') : this.statistics.get('definition'),
      showDerive: !this.dataset.isChorusView() && !this.options.isDataSourceBrowser && canUpdate,
      showPublish: false,
      showVisualize: schema(this.dataset),
      loaded: this.dataset.loaded && !this.dataset.isImporting(),
      hideCloseDerive: this.options.inDeriveChorusView,
    };
  },

  showErrorWithDetailsLink(taskOrColumnSet) {
    this.$('.dataset_errors').removeClass('hidden');
    this.errorSource = taskOrColumnSet;
  },

  updateColumnCount() {
    this.$('.count').text(t('dataset.column_count', { count: this.collection.length }));
  },
});
