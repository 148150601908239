import $ from '../../jquery';
import t from '../../intl';
import Sidebar from '../core/sidebar_view';

export default Sidebar.extend({
  templateName: 'airlock/airlock_new_sidebar',

  bindCallbacks: $.noop,

  setup() {
    this.listenTo(this.model, 'change:activeSection', this.render);
  },

  context() {
    const activeSection = this.model.get('sections')[this.model.get('activeSection')];
    return {
      section: activeSection.title,
      general_help: t('rsh.sidebar.help.airlock_new').replace(/\\n/g, '\n'),
      help: activeSection.help,
    };
  },
});
