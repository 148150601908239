import { t } from 'i18n-js';
import { Icon } from 'semantic-ui-react';
import { avatarBackgroundClassName, userInitials } from './avatarsUtils';
import './Avatars.less';

export const ErrorAvatar = ({ className = '' }) => (
  <Icon
    name="exclamation triangle"
    circular
    color="red"
    className={`aridhia-avatar ${className}`}
    title={t('activities.avatar.error')}
  />
);

export const SystemAvatar = ({ className = '' }) => (
  <Icon
    name="info"
    circular
    className={`aridhia-avatar ${className}`}
    title={t('activities.avatar.system')}
  />
);

export const UserAvatar = ({ userName, className = '', invited }) => {
  const backgroundClass = avatarBackgroundClassName(userName);
  const classNames = `aridhia-avatar aridhia-avatar-${backgroundClass} ${invited && 'aridhia-invited'} ${className}`;

  return (
    <div
      title={userName}
      className={classNames}
      data-testid="user-avatar"
    >
      {userInitials(userName)}
    </div>
  );
};
