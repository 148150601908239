/* eslint-disable object-curly-newline */
import { memo } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import '../components/toasts/Toasts.less';
import { t } from 'i18n-js';
import SiteTracking from '../components/sitetracking/SiteTracking';
import { queryClient } from '../adapters/service';
import LayoutPage from './LayoutPage';
import { PermissionsWrapper } from '../permissions';
import { WorkspaceProvider, useWorkspace } from '../adapters/Workspace';
import LoadingPage from './LoadingPage';
import ErrorPage from './ErrorPage';
import { OfficeDiscoveryProvider } from '../files/OfficeDiscoveryContext';

// TODO: Create test for this page
const WorkspaceWrapper = ({ children }) => {
  const { workspace, isLoading, isError, error } = useWorkspace();

  if (isLoading) {
    return LoadingPage();
  }

  if (!workspace && isError) {
    return ErrorPage({ header: t('startup.error_load1'), error });
  }

  const state = {
    workspaceUuid: workspace.uuid,
    workspace,
  };

  return (
    <PermissionsWrapper uuid={workspace.uuid}>
      <WorkspaceProvider workspace={state}>
        {children}
      </WorkspaceProvider>
    </PermissionsWrapper>
  );
};

const App = memo(() => (
  <QueryClientProvider client={queryClient}>
    <SiteTracking>
      {/* Add session expired */}
      <WorkspaceWrapper>
        <OfficeDiscoveryProvider>
          <LayoutPage />
        </OfficeDiscoveryProvider>
      </WorkspaceWrapper>
    </SiteTracking>
  </QueryClientProvider>
));

export default App;
