import Backbone from 'backbone';
import { BackboneProvider } from 'connect-backbone-to-react';
import { t } from 'i18n-js';
import $ from '../../jquery';
import App from '../../../components/containerised_apps/App';
import ContainerisedApp, { appRunStatus } from '../../../models/containerised_app';
import View from '../react_view';

export default View.include(Backbone.Events).extend({
  bindCallbacks: $.noop,
  className: 'app-containerisedApp semantic main_content',

  setup({
    workspace, tab, selectedApp,
  }) {
    this.workspace = workspace;
    this.tab = tab;
    this.app = new ContainerisedApp({
      uuid: selectedApp, workspace: this.workspace, workspaceID: this.workspace.get('id'),
    });
    this.beginAppTimer();
    this.listenTo(this.app, 'iframeReady', this.pollIframe);
  },

  renderOnce() {
    this.app.setRunState(appRunStatus.FETCHING, t('app.fetching'));

    const onAppFetchSuccess = async () => {
      this.tab.set('label', this.app.get('name'));
      await this.app.refreshCookie();
      this.app.start();
    };

    const onAppFetchError = (err) => {
      if (err.status === 401 || err.status === 403) {
        this.app.setRunState(appRunStatus.ERROR, t('app.auth_error'));
      } else {
        this.app.setRunState(appRunStatus.ERROR, t('app.initialize.error'));
      }
    };

    this.app.fetch().then(onAppFetchSuccess, onAppFetchError);

    const modelsMap = {
      app: this.app,
      workspace: this.workspace,
    };

    this.root.render(
      <BackboneProvider models={modelsMap}>
        <App />
      </BackboneProvider>,
    );
  },

  beginAppTimer() {
    // start afresh
    if (this.refreshAppCookieTimer) { this.tearDownTimer(); }

    const refreshAppCookie = () => this.app.refreshCookie();
    this.refreshAppCookieTimer = setInterval(refreshAppCookie, 59 * 60 * 1000); // 59 minutes
  },

  teardown(...args) {
    this.tearDownTimer();
    if (this.constantPolling) {
      clearInterval(this.constantPolling);
    }
    this._super('teardown', ...args);
  },

  tearDownTimer() {
    if (this.refreshAppCookieTimer) clearInterval(this.refreshAppCookieTimer);
  },
});
