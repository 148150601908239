import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import Handlebars from '../../vendor/handlebars';
import Collection from '../../collections/collections';
import View from '../loading_view';
import { FEATURE } from '../../../utilities/features';

export default View.extend({
  constructorName: 'MultiSelectionSidebarMenu',
  templateName: 'multiple_selection_sidebar_menu',

  events: {
    'click .deselect_all': 'deselectAll',
  },

  setup() {
    this.actions = this.options.actions || [];
    this.selectedModels = new Collection();
    this.events = _.extend({}, this.events, this.options.actionEvents);
    this.collections = this.options.collections;

    if (!this.collections) {
      this.collections = { collection: { data: this.options.collection, selectEvent: this.options.selectEvent } };
    }

    const self = this;
    $.each(this.collections, (key, value) => {
      self.listenTo(value.data, value.selectEvent, self.modelSelected);
    });

    this.subscribePageEvent('workfiles:deselectAll', this.deselectAll);
    this.subscribePageEvent('datasets:deselectAll', this.deselectAll);
  },

  render(...args) {
    this._super('render', args);
  },

  cleanSelectedModels() {
    this.selectedModels.each((model) => {
      if (typeof model !== 'undefined') {
        const selectedId = model.id;
        const inCollection = this.collection.get(selectedId);
        if (typeof inCollection === 'undefined') {
          this.selectedModels.remove(selectedId);
        }
      }
    });
  },

  modelSelected(selectedModels) {
    this.selectedModels = selectedModels;
    this.render();
  },

  showOrHideMultipleSelectionSection() {
    // Send a message to the rest of the app so that we can juggle the
    // sidebar layout in various views
    if (this.parentView) {
      if (this.selectedModels.length > 1) {
        this.$el.removeClass('hidden');
        this.parentView.$('#sidebar .sidebar-content').addClass('hidden').hide();
      } else {
        this.$el.addClass('hidden');
        this.parentView.$('#sidebar .sidebar-content').removeClass('hidden').show();
      }
    }
    
  },

  deselectAll(e) {
    if (e) e.preventDefault();
    $.each(this.collections, (key, value) => {
      value.data.trigger('selectNone');
    });
  },

  additionalContext() {
    return {
      selectedModels: this.selectedModels,
      actions: this.actions.map(action => Handlebars.compile(action)()),
    };
  },

  postRender() {
    this.cleanSelectedModels();
    this.showOrHideMultipleSelectionSection();
    this._super('postRender');
  },

  setActions(newActions) {
    this.actions = newActions;
  },

  setActionEvents(newActionEvents) {
    this.events = _.extend({}, this.events, newActionEvents);
  },
});
