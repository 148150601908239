import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';

const TextQualifierForm = ({ textQualifier, handleTextQualifierChange, triggerErrorTextQualifierChange }) => (
  <Form.Field inline className="top-padding">
    <label htmlFor="text_qualifier">{t('dataset.import.text_qualifier')}</label>
    <Input
      id="text_qualifier"
      defaultValue={textQualifier}
      onBlur={handleTextQualifierChange}
      onChange={triggerErrorTextQualifierChange}
      className="small-size"
    />
  </Form.Field>
);

const mapModelsToProps = ({ datasetUpload }) => ({
  textQualifier: datasetUpload.get('text_qualifier'),
  handleTextQualifierChange: (_e) => {
    datasetUpload.updateTextQualifier(_e.target.value);
  },
  triggerErrorTextQualifierChange: (_e) => {
    if (_e.target.value.length <= 1) {
      datasetUpload.updateTextQualifier(_e.target.value);
    }
  },
});

const options = {
  debounce: true,
  events: {
    datasetUpload: ['change:text_qualifier'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(TextQualifierForm);
