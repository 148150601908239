import _ from 'underscore';
import Model from '../models';

const WorkspaceOptions = Model.extend({
  urlTemplate: 'api/admin/options/workspace',
  constructorName: 'WorkspaceOptions',
});

export const CachedWorkspaceOptions = new WorkspaceOptions();

export const locationMap = _.once(async () => {
  await CachedWorkspaceOptions.fetchIfNotLoaded();
  const locations = new Map();
  CachedWorkspaceOptions.get('location').forEach((l) => { locations.set(l.value, l.description); });

  return locations;
});


export default WorkspaceOptions;
