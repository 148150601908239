import React from 'react';
import { createRoot } from 'react-dom/client';
import chorus from './chorus';
import _ from './underscore';
import $ from './jquery';
import Backbone from './vendor/backbone';
import WorkspaceTabs from './pages/workspace_tabs_page';
import WorkspaceEdit from './pages/workspace_edit_page';
import TagIndex from './pages/tag_index_page';
import TagShow from './pages/tag_show_page';
import App from '../new_ux/pages/App';
import WorkspaceSearchIndex from './pages/workspace_search_index_page';
import WorkspaceTagShow from './pages/workspace_tag_show_page';
import NotificationIndex from './pages/notification_index_page';
import Forbidden from './pages/forbidden_page';
import { FEATURE } from '../utilities/features';
import InvalidRoute from './pages/invalid_route_page';
import Unauthorized from './pages/unauthorized_page';
import UnprocessableEntity from './pages/unprocessable_entity_page';
import Events from './mixins/events';
import Routing from './mixins/routing';

const Pages = {
  WorkspaceTabs,
  WorkspaceEdit,
  TagIndex,
  TagShow,
  WorkspaceSearchIndex,
  WorkspaceTagShow,
  NotificationIndex,
  Forbidden,
  InvalidRoute,
  Unauthorized,
  UnprocessableEntity,
};

const buildForType = (className, options) => {
  const pageClass = Pages[className];

  function Page() {
    return pageClass.apply(this, options);
  }

  Page.prototype = pageClass.prototype;
  return new Page();
};

export default Backbone.Router.include(Events, Routing).extend({
  constructor: function chorus$Router(...args) {
    Backbone.Router.apply(this, args);
  },

  maps(){
    // routes are evaluated in LIFO format, so adding a match-all route first will act as a fallback properly
    // (as long as `maps` is evaluated in order)
    return [
    ['*path', 'InvalidRoute'],
    ['unauthorized', 'Unauthorized'],
    ['forbidden', 'Forbidden'],
    ['invalidRoute', 'InvalidRoute'],
    ['unprocessableEntity', 'UnprocessableEntity'],
    ['workspaces/:workspaceId', `${FEATURE.NEW_UX ? 'App' : 'WorkspaceTabs'}`],
    ['workspaces/:workspaceId/:objectType', `${FEATURE.NEW_UX ? 'App' : 'WorkspaceTabs'}`],
    ['workspaces/:workspaceId/:objectType/*path', `${FEATURE.NEW_UX ? 'App' : 'WorkspaceTabs'}`],
    ['workspaces/:workspaceId/search/:query', 'WorkspaceSearchIndex'],
    ['workspaces/:workspaceId/search/:scope/:entityType/:query', 'WorkspaceSearchIndex'],
    ['workspaces/:workspaceId/tags/:name', 'WorkspaceTagShow'],
    ['workspaces/:workspaceId/tags/:scope/:entityType/:name', 'WorkspaceTagShow'],
    ['workspaces/:uuid/edit', 'WorkspaceEdit'],
    ['notifications', 'NotificationIndex'],
    ['tags', 'TagIndex'],
    ['tags/:name', 'TagShow'],
    ['tags/:scope/:entityType/:name', 'TagShow']
    ];
  },

  currentPageClass: null,

  newUXAppLoaded: false,

  routeBasedOnMaps(self){
    _.each(this.maps(), (map) => {
      const pattern = map[0];
      const pageClassName = map[1];
      const callback = self.generateRouteCallback(pageClassName);
      self.route(pattern, pageClassName, callback);
    });
  },

  initialize(app) {
    const self = this;
    self.app = app;
    this.routeBasedOnMaps(self);
    self.route('logout', 'Logout', self.app.session.logout);
  },

  reload() {
    this.navigate(Backbone.history.fragment);
  },

  generateRouteCallback(className) {
    const self = this;

    return function routeTo(...args) {
      const pageArgs = _.toArray(args);
      const navFunction = function navigateToUrl() {
        if (self.app.modal) self.app.modal.closeModal();

        if (!FEATURE.NEW_UX &&
            className === self.currentPageClass &&
            self.app.page.updatePageArgs &&
            self.app.page.updatePageArgs(pageArgs)) {
          return;
        }

        let page;
        const container = document.getElementById('page');

        if (className === 'App') {
          if (!self.newUXAppLoaded) {
            page = buildForType('WorkspaceTabs', pageArgs);
            const root = createRoot(container);
            root.render(<App />);
            self.newUXAppLoaded = true;
          } else {
            // If the newUX is already loaded then no need to change the page
            page = self.app.page;
          }
        }
        else {
          chorus.PageEvents.off();
          self.trigger('leaving');
          page = buildForType(className, pageArgs);        
          $('#page').html(page.render().el).attr('data-page', className).addClass(page.pageClass);
          page.pageOptions = self.app.pageOptions;
        }
        delete self.app.pageOptions;
        self.app.page = page;
        self.app.updateCachebuster();
        self.rshCheckSockets();
        self.currentPageClass = className;
        self.app.scrollToTop();
      }; 
      if (!chorus.session.loggedIn() && className !== 'Unauthorized') {
        return;
      } else {
        navFunction();
      }
    };
  },

  rshCheckSockets() {
    // Check if there are any pending websockets (R sesssions) and close them on navigation
    if (typeof chorus.rsh.webSocket !== 'undefined') {
      chorus.rsh.webSocket.close();
    }
  },
});

