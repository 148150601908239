import { t } from 'i18n-js';
import { Table, Icon } from 'semantic-ui-react';
import { useDatabaseTableCreationContext } from '../Context';
import MarkdownRender from '../../../../components/text_editors/MarkdownRender';
import { isRichText } from '../../../../utilities/editor';

const DatabaseTableCreationConfirmColumns = () => {
  const { newDatabaseTableState } = useDatabaseTableCreationContext();

  return (
    <Table definition compact celled>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell collapsing>{t('database_tables_new.columns.column_name')}</Table.HeaderCell>
          {newDatabaseTableState.table.columns
            .filter((column) => !column.disabled)
            .map((column) => (
              <Table.HeaderCell key={`step4-column-name-${column.id}`}>
                {column.name}
              </Table.HeaderCell>
            ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell collapsing>{t('database_tables_new.columns.column_type')}</Table.Cell>
          {newDatabaseTableState.table.columns
            .filter((column) => !column.disabled)
            .map((column) => (
              <Table.Cell key={`step4-column-type-${column.id}`}>{column.type}</Table.Cell>
            ))}
        </Table.Row>
        <Table.Row>
          <Table.Cell collapsing>{t('database_tables_new.columns.column_description')}</Table.Cell>
          {newDatabaseTableState.table.columns
            .filter((column) => !column.disabled)
            .map((column) => (
              <Table.Cell key={`step4-column-description-${column.id}`} className="six-line-truncate">
                <MarkdownRender
                  value={column.description}
                  isRichText={isRichText(column.description)}
                />
              </Table.Cell>
            ))}
        </Table.Row>
        <Table.Row>
          <Table.Cell collapsing>{t('database_tables_new.columns.column_label')}</Table.Cell>
          {newDatabaseTableState.table.columns
            .filter((column) => !column.disabled)
            .map((column) => (
              <Table.Cell key={`step4-column-label-${column.id}`}>{column.label}</Table.Cell>
            ))}
        </Table.Row>
        <Table.Row>
          <Table.Cell collapsing>{t('database_tables_new.columns.column_primary_key')}</Table.Cell>
          {newDatabaseTableState.table.columns
            .filter((column) => !column.disabled)
            .map((column) => (
              <Table.Cell key={`step4-column-primary-key-${column.id}`}>
                {column.primary && (
                <Icon name="key" data-testid="column-primary-key" />
                )}
              </Table.Cell>
            ))}
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default DatabaseTableCreationConfirmColumns;
