import WorkfolderPickItems from '../workfiles/workfolder_pick_items';

export default WorkfolderPickItems.extend({
  setup() {
    this.workfile = this.options.workfile;
    this.workfileSet = this.options.workfileSet;
    this.excludeFileName = true;
    this._super('setup');
    this.totalFiles = 1;
    this.processedFiles = 0;
    this.failedFiles = 0;

    if (this.workfile) {
      this.listenTo(this.workfile, 'saved', this.saved);
      this.listenTo(this.workfile, 'saveFailed', this.saveFailed);
    }
    if (this.workfileSet) {
      this.totalFiles = this.workfileSet.models.length;
      this.listenTo(this.workfileSet, 'saved', this.workfileSetSaved);
      this.listenTo(this.workfileSet, 'saveFailed', this.workfileSetSaveFailed);
    }
  },

  submit() {
    const folder = this.folder.get('folder');
    if (this.workfileSet) {
      const workfiles = this.workfileSet.models;
      workfiles.forEach((workfile) => {
        workfile.set({
          folder,
          versionInfo: null,
          fileName: workfile.getFileName(),
        }, { silent: true });
        workfile.save({ source: 'empty' });
      }, this);
    } else {
      this.workfile.set({
        folder,
        versionInfo: null,
        fileName: this.workfile.getFileName(),
      }, { silent: true });
      this.workfile.save({ source: 'empty' });
    }
    this.$('button.submit').attr('disabled', 'disabled');
  },

  saved() {
    chorus.toast('workfiles.file.save_succeeded');
    chorus.PageEvents.trigger('workfiles:deselectAll');
    chorus.PageEvents.trigger(`workfile:deleted`, this.workfile);
    this.closeModal();
  },

  saveFailed() {
    if (this.workfile.isFolder()) {
      chorus.toast('workfiles.folder.save_failed', {
        toastOpts: { theme: 'bad_activity' },
      });
    } else {
      chorus.toast('workfiles.file.save_failed', {
        toastOpts: { theme: 'bad_activity' },
      });
    }
    this.closeModal();
  },

  workfileSetSaved() {
    this.processedFiles++;
    if (this.processedFiles === this.totalFiles) {
      this.workfileSetProcessed();
    }
  },

  workfileSetSaveFailed() {
    this.processedFiles++;
    this.failedFiles++;
    if (this.processedFiles === this.totalFiles) {
      this.workfileSetProcessed();
    }
  },

  workfileSetProcessed() {
    if (this.failedFiles) {
      chorus.toast('workfiles.file.save_failed', {
        toastOpts: { theme: 'bad_activity' },
      });
      this.closeModal();
    } else {
      chorus.toast('workfiles.file.save_succeeded');
      chorus.PageEvents.trigger('workfiles:deselectAll');
      setTimeout(() => {
        chorus.PageEvents.trigger(`workfile:deleted`, this.workfileSet);
        this.closeModal();
      }, 1000);
    }
  },
});
