import { Icon } from 'semantic-ui-react';
import { supportedTableTypes } from '../constants';

const TableIcon = ({
  tableType,
  size,
  className = '',
  color,
}) => (
  <Icon.Group className={className} data-testid={`${tableType}-icon-group`}>
    {tableType !== supportedTableTypes.VIEW && <Icon name="table" color={color} size={size} data-testid="table-icon" />}
    {tableType === supportedTableTypes.VIEW && <Icon name="database" color={color} size={size} data-testid="database-icon" />}
    {tableType !== supportedTableTypes.TABLE && <Icon corner="top right" name="eye" color={color} size={size} data-testid="view-icon" />}
  </Icon.Group>
);

export default TableIcon;
