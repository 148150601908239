import React from 'react';
import { Form, TextArea } from 'semantic-ui-react';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';

const FormReason = ({ reason, handleReasonChange, triggerErrorReasonChange }) => (
  <Form.Field>
    <label htmlFor="reason">{t('upload_token.upload_form.reason')}</label>
    <TextArea
      id="reason"
      defaultValue={reason}
      onBlur={handleReasonChange}
      onChange={triggerErrorReasonChange}
      className="aridhia-formreason-textarea"
      autoFocus={reason.length > 0}
    />
  </Form.Field>
);

const mapModelsToProps = ({ uploadToken }) => ({
  reason: uploadToken.get('reason'),
  handleReasonChange: (_e) => {
    uploadToken.updateReason(_e.target.value);
  },
  triggerErrorReasonChange: (_e) => {
    if (_e.target.value.length <= 1) {
      uploadToken.updateReason(_e.target.value);
    }
  },
});

const options = {
  debounce: true,
  events: {
    uploadToken: ['change:reason'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(FormReason);
