import React, { Suspense } from 'react';
import { Loader } from 'semantic-ui-react';
import LegacySummaryPanel from './panels/legacy/SummaryPanel';
import UploadPanel from './panels/legacy/UploadPanel';
import UploadTokenPanel from './panels/legacy/UploadTokenPanel';
import FilePanel from './panels/legacy/FilePanel';
import LegacyAppsPanel from './panels/legacy/AppsPanel';
import AppPanel from './panels/legacy/AppPanel';
import ShinyAppsPanel from './panels/legacy/ShinyAppsPanel';
import ShinyAppPanel from './panels/legacy/ShinyAppPanel';
import RConsolePanel from './panels/legacy/RConsolePanel';
import AirlockPanel from './panels/legacy/AirlockPanel';
import DatabaseTableMetadataPanel from './panels/legacy/DatabaseTableMetadataPanel';
import DatabaseTableEditorPanel from './panels/legacy/DatabaseTableEditorPanel';
import FeaturesPanel from './panels/legacy/FeaturesPanel';
import PanelTracker from '../../pages/PanelTracker';
import { FEATURE } from '../../../utilities/features';
import AppsPanel from './panels/new/AppsPanel';
import VirtualMachinePanel from './panels/new/VirtualMachinePanel';
import VirtualMachinesPanel from './panels/new/VirtualMachinesPanel';
import FilesPanel from './panels/new/FilesPanel';
import InboxPanel from './panels/new/InboxPanel';
import DatabaseTablesPanel from './panels/new/DatabaseTablesPanel';
import DatabaseTablePanel from './panels/new/DatabaseTablePanel';
import DatabaseTableCreationPanel from './panels/new/DatabaseTableCreationPanel';
import NotFoundPanel from './panels/new/NotFoundPanel';
import { useWorkspaceContext } from '../../adapters/Workspace';
import LegacyActivityPanel from './panels/legacy/ActivityPanel';
import ResultsPanel from './panels/legacy/ResultsPanel';
import BlobsPanel from './panels/new/BlobsPanel';
import SecretsPanel from './panels/new/SecretsPanel';
import legacyLoader from './legacyLoader';
import HomePanel from './panels/new/HomePanel';
import AuditPanel from './panels/new/AuditPanel';
import NewView from './panels/legacy/NewViewPanel';
import ActivityPanel from './panels/new/ActivityPanel';
import OfficePanel from './panels/new/OfficePanel';
import { turnOffFullscreen } from '../layoutState';

const wipPanels = {
  apps: AppsPanel,
  summary: ActivityPanel,
};

const legacyPanels = {
  // Legacy panels
  activity: LegacyActivityPanel,
  airlock_new: AirlockPanel,
  app: AppPanel,
  apps: LegacyAppsPanel,
  dataset_metadata: DatabaseTableMetadataPanel,
  datatable: DatabaseTableEditorPanel,
  analyse: DatabaseTableEditorPanel,
  desktops: VirtualMachinesPanel,
  features: FeaturesPanel,
  home: HomePanel,
  mini_app: ShinyAppPanel,
  mini_apps: ShinyAppsPanel,
  rtools: RConsolePanel,
  sqlresults: ResultsPanel,
  summary: LegacySummaryPanel,
  upload: UploadPanel,
  upload_token: UploadTokenPanel,
  workfiles: FilePanel,
  new_view: NewView,
};

const releasedPanels = {
  // Released panels
  not_found: NotFoundPanel,
  inbox: InboxPanel,
  blobs: BlobsPanel,
  files: FilesPanel,
  audit: AuditPanel,
  datasets: DatabaseTablesPanel,
  custom_views: DatabaseTablePanel,
  database_views: DatabaseTablePanel,
  database_table: DatabaseTablePanel,
  database_table_creation: DatabaseTableCreationPanel,
  office: OfficePanel,
  secrets: SecretsPanel,
  home: HomePanel,
  desktops: VirtualMachinesPanel,
  desktop: VirtualMachinePanel,
};

const toLegacyPanel = ([name, component]) => (
  [name, { component: legacyLoader(component), isLegacy: true }]
);

const toNewPanel = ([name, component]) => (
  [name, { component, isLegacy: false }]
);

const panels = {
  ...Object.fromEntries(Object.entries(legacyPanels).map(toLegacyPanel)),
  ...Object.fromEntries(Object.entries(releasedPanels).map(toNewPanel)),
  ...Object.fromEntries(Object.entries(FEATURE.WIP_PANELS ? wipPanels : {}).map(toNewPanel)),
};

const getPanelContent = (component) => (panels[component]);

const Panel = React.memo(({
  tab,
  tabIsActive,
}) => {
  const { id, component, name } = tab;
  const { workspace } = useWorkspaceContext();

  const { component: PanelContent, isLegacy } = getPanelContent(component);

  if (tabIsActive && isLegacy) {
    turnOffFullscreen();
  }

  return (
    <PanelTracker panelIsActive={tabIsActive} workspaceName={workspace.name} panelName={name}>
      <Suspense fallback={<Loader active />}>
        <PanelContent key={id} tab={tab} workspace={workspace} />
      </Suspense>
    </PanelTracker>
  );
});

export default Panel;
