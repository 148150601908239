import chorus from '../chorus';
import t from '../intl';
import ModelDelete from './model_delete_alert';
import { refreshVersionInfo, fileVersions } from '../utilities/file';
import Routing from '../mixins/routing';

export default ModelDelete.include(Routing).extend({
  constructorName: 'WorkfileVersionDelete',

  text: t('workfile_version.alert.delete.text'),
  ok: t('workfile_version.alert.delete.ok'),
  deleteMessage: 'workfile_version.alert.delete.success',

  setup(options) {
    this.redirectUrl = options.redirectUrl;
    this.workfile = options.workfile;
    this.title = t('workfile_version.alert.delete.title', { version: this.model.get('versionInfo').versionNum });
  },

  modelDeleted() {
    this.closeModal();
    chorus.toast(this.deleteMessage, this.deleteMessageParams());
    fileVersions(this.workfile).fetch();
    this.workfile.activities().fetch();
    let fragment = window.URI().fragment();
    fragment = fragment[0] === '/' ? `#${fragment}` : `#/${fragment}`;

    if (this.redirectUrl && fragment !== this.redirectUrl) {
      this.navigate(this.redirectUrl);
    } else if (this.redirectUrl) {
      refreshVersionInfo(this.workfile);
    }
  },
});
