import moment from 'moment';
import { t } from 'i18n-js';
import $ from '../../../jquery';
import MiniAppPagination from './mini_app_pagination';
import MiniAppRun from './mini_app_run';
import MiniAppSidebar from './mini_app_sidebar';
import MiniApp from '../../../models/mini_apps/app';
import Workfile from '../../../models/workfile';
import View from '../../loading_view';
import FetchErrors from '../../../mixins/fetch_errors';
import AppNotification from './mini_app_notification';
import MiniAppSet from '../../../collections/mini_app';
import chorus from '../../../chorus';

export default View.include(FetchErrors).extend({

  templateName: 'mini_apps/app',

  subviews: {
    '.app-sidebarBasic': 'sidebar',
    '.mini_app-pagination': 'pagination',
    '.mini_app-container': 'app',
    '.app_notifications': 'notification',
  },

  events: {
    'click .close_notification': 'hideNotification',
  },

  bindCallbacks: $.noop,
  logsFolder: '',

  setup(options) {
    this.tab = options.tab;
    this.apps = new MiniAppSet([], { workspaceID: options.workspace.attributes.id });
    this.apps.fetchIfNotLoaded();
    this.model = new MiniApp({ id: options.selectedApp, workspaceID: options.workspace.attributes.id });
    this.miniappWorkfiles = [];
    this.model.fetch().then(() => this.getLogsFolder());
    this.app = new MiniAppRun({ model: this.model, workspace: this.options.workspace });
    this.notification = null;

    this.sidebar = new MiniAppSidebar({
      model: this.model,
      workspace: options.workspace,
      apps: this.apps,
      logsAvailable: this.app.logsAvailable,
      app: this.app,
      showLog: () => { this.app.showLog(); },
      saveLog: () => { this.saveLog(); },
      restartMiniApp: () => {
        this.resetNotification();
        this.beginAppTimer();
        this.hideNotification();
        this.app.restartMiniApp();
      },
    });

    this.resetNotification();
    this.beginAppTimer();
    this.requiredResources.add(this.model);
    this.requiredResources.add(this.apps);
    this.pagination = new MiniAppPagination({ model: this.model, apps: this.apps });

    this.subscribePageEvent('mini_app:run', this.pageRunEvent);
    this.listenTo(this.tab, 'change:active', this.checkWorkfileChanges);
  },

  pageRunEvent(id = this.app.model.id) {
    this.resetNotification();
    if (parseInt(id, 10) === parseInt(this.options.selectedApp, 10)) {
      this.app.initializeApp();
    }
  },

  checkWorkfileChanges(active) {
    if (active) {
      this.detectWorkfileChanges();
    }
  },

  detectWorkfileChanges() {
    return this.model.fetch()
      .then(() => {
        const wsMiniAppModified = this.model.latestFileModificationTimestamp();
        const changed = (this.tabOpened !== null) && (moment(wsMiniAppModified)
          .isAfter(this.tabOpened));

        if (changed) {
          this.notification = new AppNotification({
            notificationType: 'warning',
            notificationMessage: this.app.getWarningMessage(),
          });
          this.showNotification();
        }
      });
  },


  beginAppTimer() {
    // start afresh
    if (this.refreshAppTimer) { this.tearDownTimer(); }

    const refreshFunc = () => {
      // for new session cookie
      this.model.fetch.bind(this.model);

      // check for workfile changes
      this.checkWorkfileChanges(this.tab.attributes.active);
    };

    this.refreshAppTimer = setInterval(refreshFunc, 59 * 60 * 1000);
  },

  teardown(...args) {
    this.tearDownTimer();
    this._super('teardown', ...args);
  },

  tearDownTimer() {
    if (this.refreshAppTimer) clearInterval(this.refreshAppTimer);
  },

  changeModel(id) {
    this.options.tab.set('miniAppID', id);
    this.options.selectedApp = id;
    this.model.set('id', id);
    this.model.fetch().then(() => this.getLogsFolder());
    this.app.teardown(true);
    this.app = new MiniAppRun({ model: this.model, workspace: this.options.workspace });

    this.hideNotification();
    this.registerSubView(this.app);
    this.renderSubviews();

    this.resetNotification();
    this.beginAppTimer();
    this.sidebar.updateTabs(this.app, this.model);
  },

  saveLog() {
    this.notification = new AppNotification({
      notificationType: 'warning',
      notificationMessage: t('mini_app.logs.saving.ok'),
    });
    this.showNotification();

    this.model.getLog().catch(() => {
      this.setWarning();
      this.showNotification();
    }).then((log) => {
      this.createNewLogsFile(log);
    });
  },

  createNewLogsFile(content) {
    const newName = `log_${this.model.attributes.name}_${moment().format('YYYYMMDDHHmmssSSS')}.txt`;

    const newLogsFile = new Workfile({
      workspace: { id: this.options.workspace.id },
      fileName: newName,
      fileType: 'text',
      folder: this.logsFolder,
      content,
    });

    newLogsFile.save({ source: 'empty' }).then(() => {
      this.notification = new AppNotification({
        notificationType: 'notification',
        notificationMessage: t('mini_app.logs.saved', { logsFileUrl: `${this.logsFolder}/${newName}` }),
      });
    }).catch(() => {
      this.setWarning();
    }).then(() => {
      this.showNotification();
      chorus.PageEvents.trigger('mini_app:saved_logs');
    });
  },

  showNotification() {
    this.renderSubview('notification');
    this.$('.app_notifications').removeClass('hidden');
  },

  setWarning() {
    this.notification = new AppNotification({
      notificationType: 'warning',
      notificationMessage: t('mini_app.logs.saving.error'),
    });
  },

  resetNotification() {
    this.notification = null;
    this.tabOpened = Date.now();
    this.hideNotification();
  },

  hideNotification() {
    const notificationToClose = this.$('.app_notification');
    notificationToClose.addClass('hidden');
    this.notification = null;
  },

  getLogsFolder() {
    const logsFolderArray = this.model.getWorkfiles()
      .filter(w => w.attributes.name === `files/${this.model.attributes.name}/logs`);

    if (logsFolderArray.length > 0) {
      this.logsFolder = logsFolderArray[0].attributes.name;
    } else {
      const newFolder = new Workfile({
        workspace: { id: this.options.workspace.id },
        fileName: 'logs',
        folder: `files/${this.model.attributes.name}`, // parent folder
      });

      newFolder.save({ source: 'empty', entity_subtype: 'folder', content_type: 'folder' })
        .then(() => {
          this.logsFolder = `${newFolder.attributes.folder}/${newFolder.attributes.fileName}`;
        });
    }
  },

});
