import SchemaFunction from '../models/schema_function';
import Collection from './collections';

export default Collection.extend({
  constructorName: 'SchemaFunctionSet',
  model: SchemaFunction,
  urlTemplate: 'api/workspaces/schemas/{{id}}/functions',

  comparator(schemaFunction) {
    return schemaFunction.get('name').toLowerCase();
  },
});
