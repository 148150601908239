import View from '../../loading_view';

export default View.extend({
  templateName: 'workspace/management/manage_sidebar',
  context() {
    return {
      pageTitle: this.options.pageTitle,
    };
  },
  serviceToggle() {
    this.render();
  },
});
