import Comment from '../models/comment';
import CommentSet from '../collections/comment_set';

/* eslint no-underscore-dangle: ["error", { "allow": ["_comments"] }] */

export const lastComment = (model) => {
  const commentsJson = model.get('recentComments');
  if (commentsJson && commentsJson.length > 0) {
    const comment = new Comment({
      body: commentsJson[0].body,
      author: commentsJson[0].author,
      commentCreatedStamp: commentsJson[0].timestamp,
    });

    comment.loaded = true;
    return comment;
  }
  return null;
};

export const comments = (activity) => {
  const associate = activity;
  associate._comments || (associate._comments = new CommentSet(associate.get('comments'), {
    entityType: associate.collection && associate.collection.attributes.entityType,
    entityId: associate.collection && associate.collection.attributes.entityId,
  }));
  return associate._comments;
};

