import BasePlugin from '@uppy/core/lib/BasePlugin.js'

const createUploader = (emitter, file, UploadClass, opts) => (resolve, reject) => {
  const events = [];

  const on = (event, fn) => {
    events.push([event, fn]);
    return emitter.on(event, fn);
  };

  const remove = () => {
    events.forEach(([event, fn]) => {
      emitter.off(event, fn);
    });
  };

  const uploader = new UploadClass({
    file,

    progress: (bytesUploaded) => {
      emitter.emit('upload-progress', file, { uploader: this, bytesUploaded, bytesTotal: file.size });
    },

    error: (err) => {
      emitter.emit('upload-error', file, err);
      remove();
      reject(err);
    },

    success: (location) => {
      emitter.emit('upload-success', file, {}, location);
      remove();
      resolve('Success');
    },

    ...opts
  });

  const cancel = () => { uploader.cancel(); remove(); };
  const { pauseResume } = uploader;
  const fileID = file.id;

  on('file-removed', (f) => { if (fileID === f.id) cancel(); });
  on('upload-pause', (targetFileID, isPaused) => { if (fileID === targetFileID) pauseResume(isPaused); });
  on('cancel-all', () => { cancel(); });
  on('pause-all', () => { pauseResume(true); });
  on('resume-all', () => { pauseResume(false); });

  if (!file.isPaused) {
    uploader.start();
    emitter.emit('upload-start', [file]);
  }
};

class ResumableUploader extends BasePlugin {
  constructor(uppy, { validateFile, uploadClass, ...opts }) {
    super(uppy, opts);
    this.validateFile = validateFile;
    this.uploadClass = uploadClass;
    this.type = 'uploader';
    this.id = `ResumableUploader${Math.random() * 10000}`;
    this.title = 'Resumable Uploader';
    this.upload = this.upload.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  uploadFile(id) {
    const file = this.uppy.getFile(id);
    this.validateFile(file);
    return new Promise(createUploader(this.uppy, file, this.uploadClass, this.opts));
  }

  upload(fileIDs) {
    return Promise.all(fileIDs.map(this.uploadFile));
  }

  install() {
    const { capabilities } = this.uppy.getState();
    this.uppy.setState({
      capabilities: {
        ...capabilities,
        resumableUploads: true,
      },
    });
    this.uppy.addUploader(this.upload);
  }

  uninstall() {
    this.uppy.setState({
      capabilities: Object.assign({}, this.uppy.getState().capabilities, {
        resumableUploads: false,
      }),
    });
    this.uppy.removeUploader(this.upload);
  }
}

export default ResumableUploader;
