import { idToken } from '../auth/oauth';

export default function secureImage(image, url) {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        image.attr('src', URL.createObjectURL(xhr.response));
      } else {
        // Display error image
        image.attr('src', 'images/rsh-loadfail.png');
      }
    }
  };
  xhr.open('GET', url, true);
  idToken().then((token) => {
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    xhr.send();
  });
}
