import t from '../intl';
import TagListSidebar from '../views/tags/tag_list_sidebar';
import MainContentList from '../views/core/main_content_list_view';
import BasePage from './base_page';
import TagSet from '../collections/tag_set';

export default BasePage.extend({
  crumbs: [
    { label: t('breadcrumbs.tags') },
  ],

  setup() {
    const tags = new TagSet();
    tags.fetch();

    this.mainContent = new MainContentList({
      modelClass: 'Tag',
      collection: tags,
    });

    this.sidebar = new TagListSidebar({ collection: tags });

    this.handleFetchErrorsFor(tags);
    this.listenTo(tags, 'loaded', this.render);
    this.listenTo(tags, 'remove', this.render);
  },
});
