import { t } from 'i18n-js';
import { Button, Icon } from 'semantic-ui-react';
import { toggleFullscreen } from '../layoutState';

const Fullscreen = () => (
  <Button
    title={t('layout.fullscreen.accessibility')}
    className="aridhia-fullscreen-button"
    icon
    compact
    onClick={toggleFullscreen}
  >
    <Icon data-testid="fullscreen-button-icon" name="expand" color="blue" />
  </Button>
);

export default Fullscreen;
