import { useState } from 'react';
import Backbone from 'backbone';
import InboundAirlockShowView from '../../../../../application/views/inbound_airlock/inbound_airlock_show_view';
import ReactToBackboneWrapper from '../../ReactToBackboneWrapper';
import { deleteTab } from '../../../tabMonitor';
import { usePermissions } from '../../../../permissions';
import NotAuthorised from '../../../../components/errors/NotAuthorised';

const UploadPanel = ({ tab, workspace }) => {
  const { component, id, config } = tab;
  const canUploadDirectly = usePermissions('Contribute', 'Workspace');
  const canUploadToInbox = usePermissions('Contribute.Inbox', 'Workspace');

  const backboneTab = new Backbone.Model({ active: true });
  backboneTab.remove = () => deleteTab(tab.id);

  const [view] = useState(
    () => new InboundAirlockShowView({
      model: workspace,
      tab: backboneTab,
      batchId: config.batchId,
      folder: canUploadDirectly ? config.folder : 'inbox',
      uploadToInboxOnly: !canUploadDirectly,
    }),
  );

  if (!(canUploadToInbox || canUploadDirectly)) {
    return (<NotAuthorised />);
  }

  return (
    <ReactToBackboneWrapper
      view={view}
      tabId={id}
      component={component}
    />
  );
};

export default UploadPanel;
