import Uppy from '@uppy/core';
import chorus from '../chorus';
import Azure from '../azure-uppy-plugin/uppy_azure_uploader_plugin';

export default function uploadFile(fileName, description, file, workspaceId) {
  const opts = {
    presignEndpoint: `/api/workspaces/workspaces/${workspaceId}/upload`,
    prefix: 'blobs',
  };
  const uppy = new Uppy({ debug: true });

  uppy.use(Azure, opts);
  uppy.addFile({
    name: fileName,
    type: file.type,
    data: file,
    source: 'Local',
    isRemote: false,
  });
  return uppy.upload().then((result) => {
    if (result.failed.length > 0) {
      chorus.toast('workfiles.upload.failed', { fileName });
    } else {
      chorus.toast('workfiles.uploaded', { fileName });
    }
    uppy.close();
  });
}
