import chorus from '../../chorus';
import $ from '../../jquery';
import User from '../user';
import { CreateUserPayload } from '../../utilities/management_payload';
import Routing from '../../mixins/routing';
import { idToken } from '../../auth/oauth';

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["attrs"] }] */
/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true }] */

export default User.include(Routing).extend({
  constructorName: 'ManagementUser',

  urlTemplate: 'api/admin/user/{{uuid}}',
  entityType: 'user',

  fetchWithImage() {
    this.fetch({
      success: function fetchChorusUser() {
        this.chorus_user = new User({ uuid: this.get('uuid') });
        this._chorusUser = this.chorus_user;
        this.chorus_user.fetch({
          success: function applyImage() {
            this.set('image', this.chorus_user.get('image'));
          }.bind(this),
        });
      }.bind(this),
    });
  },

  chorusUser() {
    if (!this._chorusUser) {
      this._chorusUser = new User({ uuid: this.get('uuid') });
    }
    return this._chorusUser;
  },

  saveChanges(attrs) {
    let navLocation = '/users';
    if (!chorus.session.user().isTenantAdmin()) {
      navLocation += `/${this.attributes.uuid}`;
    }

    if (Object.keys(attrs).length === 0 && attrs.constructor === Object) {
      this.navigate(navLocation);
      return;
    }

    if (!this.attributes.emailLocked && this.tenantAdminNotSelf()) {
      if (attrs.email && (attrs.email === attrs.emailConfirmation)) {
        attrs.new_email = attrs.email;
      }
    }
    delete attrs.emailConfirmation;
    delete attrs.email;

    if (attrs.firstName) {
      attrs.first_name = attrs.firstName;
      delete attrs.firstName;
    }

    if (attrs.lastName) {
      attrs.last_name = attrs.lastName;
      delete attrs.lastName;
    }

    this.patch(
      this.url(), attrs,
      () => {
        $(':focus').blur();
        this.navigate(navLocation);
      },
      (data) => {
        $(':focus').blur();
        chorus.toast('users.admin.edit.profile.restricted', { status: data.responseJSON.error });
      },
    );
  },

  performAction(action) {
    this.post(
      this.url(), JSON.stringify({ action }),
      () => {
        $(':focus').blur();
        chorus.toast('users.action.success', $.noop);
      },
      (data) => {
        $(':focus').blur();
        chorus.toast('users.action.error', { status: data.responseJSON.error });
      },
    );
  },

  declareValidations(newAttrs) {
    this.require('firstName', newAttrs);
    this.require('lastName', newAttrs);

    this.require('email', newAttrs);
    this.requireValidEmailAddress('email', newAttrs);

    if (typeof this.get('uuid') === 'undefined' || (!this.get('emailLocked') && this.tenantAdminNotSelf())) {
      this.require('emailConfirmation', newAttrs);
      this.requireValidEmailAddress('emailConfirmation', newAttrs);
      this.requireConfirmation('email', newAttrs);
    }
  },

  tenantAdminOrSelf() {
    return (chorus.session.user().isTenantAdmin() || this.isSessionUser());
  },

  tenantAdminNotSelf() {
    return (chorus.session.user().isTenantAdmin() && !this.isSessionUser());
  },

  provisioningError(error) {
    idToken().then(token => {
      $.ajax({
        url: `${this.url()}/status`,
        type: 'get',
        headers: { Authorization: `Bearer ${token}` },
      }).done(data => error(data));
    });
  },

  createUser(params, success, failure) {
    this.post(
      '/api/admin/user', JSON.stringify(CreateUserPayload(params)),
      () => {
        success();
      },
      (xhr) => {
        failure(xhr.responseJSON.error);
      },
    );
  },

  acceptInvite(workspace, success, failure) {
    const payload = { workspaces: {} };
    payload.workspaces[workspace] = 'accept';

    this.patch(this.url(), payload, success, failure);
  },

  declineInvite(workspace, success, failure) {
    const payload = { workspaces: {} };
    payload.workspaces[workspace] = 'reject';
    this.patch(this.url(), payload, success, failure);
  },

  attrToLabel: {
    email: 'users.email',
    emailConfirmation: 'users.confirm_email',
    firstName: 'users.first_name',
    lastName: 'users.last_name',
    telephone: 'users.telephone',
    title: 'users.title',
    department: 'users.department',
  },
});
