import { t } from 'i18n-js';
import { Button, Card, Icon } from 'semantic-ui-react';

const AppsCardFooter = () => (
  <Card.Content extra data-testid="apps-card-footer">
    <Button size="tiny" compact disabled>
      <Icon
        name="play"
        loading={false}
        color="green"
        data-testid="apps-start-button-icon"
      />
      {t('apps.apps_panel.action.start')}
    </Button>
  </Card.Content>
);

export default AppsCardFooter;
