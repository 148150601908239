import { useQuery } from '@tanstack/react-query';
import {
  workspaceTablesListUrl,
} from '../../../constants/api_urls';
import { getter } from '../../../adapters/service';
import { workspaceId } from '../../../layout/helper';

export const useTables = (page, namePattern, orderBy) => {
  const fetchTables = () => getter(workspaceTablesListUrl(page, namePattern, orderBy));
  const {
    data,
    isFetching,
    isError,
    refetch,
  } = useQuery(['tables', workspaceId(), page, namePattern, orderBy], fetchTables);

  return {
    tables: data ? data.response : [],
    pagination: data ? data.pagination : {},
    isFetching,
    isError,
    refetch,
  };
};

export default useTables;
