import t from '../intl';
import Dialog from './dialogs';

export default Dialog.extend({
  constructorName: 'publicInboxConfirm',
  templateName: 'static_content_view_dialog',
  events: {
    'click .app-btnClose': 'dialogClose',
  },

  dialogClose() {
    this.closeModal();
  },

  postRender() {
    let title = t('public_inbox_config.alert.title');
    let content = t('public_inbox_config.alert.text');
    this.$('.dialog_header h1').html(title);
    this.$('.app-staticContentBody').html(content);
  },

});