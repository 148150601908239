import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import View from '../loading_view';
import TagsContext from '../../mixins/tags_context';

export default View.include(TagsContext).extend({
  constructorName: 'UserItemView',
  templateName: 'user/user_item',
  tagName: 'div',

  additionalContext() {
    return _.extend({
      admin: this.model.isAdmin(),
      tenantAdmin: chorus.session.user().isTenantAdmin(),
      iconUrl: this.model.fetchImageUrl({ size: 'icon' }),
      name: this.model.displayName(),
      department: this.model.get('department'),
      title: this.model.get('title'),
      status: this.getRealStatus(this.model.get('status'), this.model.enabled()),
      enabled: this.model.enabled(),
      statusLabel: this.statusLabel(this.model.get('status'), this.model.enabled()),
      hideTags: true,
      checkable: false,
    });
  },

  getRealStatus(status, enabled) {
    let msg = 'admin.status.';
    switch (status) {
      case 'error':
      case 'pending':
        msg += status;
        break;
      case 'active':
        msg += (enabled ? 'active' : 'disabled');
        break;
      default: // this should never happen
        msg += 'pending';
    }
    return msg;
  },

  statusLabel(status, enabled) {
    if (status === 'error') {
      return 'important';
    } else if (status === 'pending') {
      return 'info';
    } else if (status === 'active') {
      return (enabled ? 'success' : 'default');
    }
    return 'default';
  },

  postRender() {
    $(this.el).attr('data-userId', this.model.get('uuid'));
    $(this.el).attr('data-Id', this.model.get('uuid'));
    $(this.el).attr('data-type', this.model.entityType);
  },
});
