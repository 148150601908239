import { useState } from 'react';
import Backbone from 'backbone';
import AirlockNewView from '../../../../../application/views/airlock/airlock_new_view';
import ReactToBackboneWrapper from '../../ReactToBackboneWrapper';
import Workfile from '../../../../../application/models/workfile';
import { deleteTab } from '../../../tabMonitor';

const AirlockPanel = ({ tab, workspace }) => {
  const { component, config, id } = tab;

  const backboneTab = new Backbone.Model({ active: true });
  backboneTab.remove = () => deleteTab(tab.id);

  const [view] = useState(() => new AirlockNewView({ model: workspace, tab: backboneTab }));

  const complete = view.wizardModel?.get('activeSection') === 2 && view.wizardModel?.get('request').loaded;

  if (config.args[5] && !complete) {
    if (config.args[4] === 'workfile') {
      const workfileIDs = config.args[5].split(',');
      workfileIDs.forEach((workfileID) => {
        const workfile = new Workfile({ id: workfileID, workspace });
        workfile.fetch().then(() => view.addRequestContent(workfile));
      });
    }
    if (config.args[4] === 'dataset') {
      const datasetIDs = config.args[5].split(',');
      datasetIDs.forEach((datasetID) => {
        const dataset = workspace.getDataset(datasetID);
        dataset.fetch().then(() => view.addRequestContent(dataset));
      });
    }
  }

  return (
    <ReactToBackboneWrapper
      view={view}
      tabId={id}
      component={component}
    />
  );
};

export default AirlockPanel;
