import { connectBackboneToReact } from 'connect-backbone-to-react';
import { Container } from 'semantic-ui-react';
import AppBody from './AppBody';
import AppHeader from './AppHeader';

import './ContainerisedApp.less';

const App = ({
  appName,
  appUrl,
  appMgmtUrl,
  allowDownload,
  appRunState,
  setAppRunState,
  setPodStatus,
  stopApp,
  workspaceToken,
}) => (
  <Container className="app semantic">
    <AppHeader appName={appName} stopApp={stopApp} />
    <AppBody
      appName={appName}
      appUrl={appUrl}
      appMgmtUrl={appMgmtUrl}
      allowDownload={allowDownload}
      appRunState={appRunState}
      setAppRunState={setAppRunState}
      setPodStatus={setPodStatus}
      workspaceToken={workspaceToken}
    />
  </Container>
);

const mapModelsToProps = ({ app, workspace }) => ({
  appName: app.get('name'),
  appUrl: app.get('appUrl'),
  appMgmtUrl: app.get('managementUrl'),
  allowDownload: app.get('allowDownload'),
  appRunState: app.get('runState'),
  setAppRunState: app.setRunState,
  setPodStatus: (value) => app.set('PodStatus', value),
  stopApp: () => app.stop(true),
  workspaceToken: workspace.token(),
});

const options = {
  debounce: true,
  events: {
    app: [
      'change',
    ],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(App);
