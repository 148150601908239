import chorus from '../chorus';
import t from '../intl';
import $ from '../jquery';
import Confirm from './confirm';

export default Confirm.extend({
  constructorName: 'MiniAppRestartAlert',
  focusSelector: 'button.cancel',
  additionalClass: 'error',
  persistent: true,

  text: t('mini_app.restart.alert.text'),
  title: t('mini_app.restart.alert.title'),
  ok: t('mini_app.restart.alert.ok'),
  requestedMessage: 'mini_app.restart.alert.message',

  confirmAlert(e) {
    e.preventDefault();
    this.closeModal();
    chorus.PageEvents.trigger('mini_app:restart');
  },

  postRender() {
    this._super('postRender');
  },
});
