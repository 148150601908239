import { useReducer } from 'react';
import { Segment } from 'semantic-ui-react';
import { DatabaseTableCreationProvider, DatabaseTableCreationQueryProvider } from '../Context';
import NotAuthorised from '../../../../components/errors/NotAuthorised';
import { usePermissions } from '../../../../permissions';
import { actions, instances } from '../../../../permissions/constants';
import { newDatabaseTable } from '../state';
import reducer from '../reducer';
import {
  tableNameFromFilePath,
  fileNameFromPath,
  pathFromFullName,
  matchingTDFFileFromPath,
} from '../helper';
import DatabaseTableCreationHeader from './DatabaseTableCreationHeader';
import DatabaseTableCreationSteps from './DatabaseTableCreationSteps';
import DatabaseTableCreationTable from './DatabaseTableCreationTable';
import DatabaseTableCreationMetadata from './DatabaseTableCreationMetadata';
import DatabaseTableCreationColumns from './DatabaseTableCreationColumns';
import DatabaseTableCreationConfirm from './DatabaseTableCreationConfirm';
import DatabaseTableCreationSummary from './DatabaseTableCreationSummary';
import DatabaseTableCreationFooter from './DatabaseTableCreationFooter';
import Loader from '../../../../components/Loader';
import { useFileContent, useMatchingTDFFile } from '../service';
import { setTableContentFromCSV, setMetadataContentFromLinkedTDF } from '../actions';

const DatabaseTableCreation = ({ fileId, panelWidth }) => {
  const [state, dispatch] = useReducer(reducer, newDatabaseTable(fileId));
  const providerValue = { newDatabaseTableState: state, dispatch, panelWidth };
  const autoLoadCSVFile = !!state.passed_csv_file && !state.table.source_file_id;
  const includeContent = 'preview';
  const includeMetadata = 'false';
  const tableName = state.table.data_table_name_from_file;
  const csvFolder = state.table.source_file ? pathFromFullName(state.table.source_file) : '';
  const matchingTDFFile = state.table.source_file ? matchingTDFFileFromPath(state.table.source_file) : '';

  const usePassedFileContentOptions = {
    refetchOnWindowFocus: false,
    enabled: autoLoadCSVFile,
    cacheTime: 2000,
    onSuccess: (data) => {
      const fullPath = `${data.response.folder}/${data.response.file_name}`;
      const passedFile = {
        fileId: data.response.id,
        tableName: tableNameFromFilePath(fullPath),
        tablePath: fullPath,
        fileName: fileNameFromPath(fullPath),
      };
      let newContent = '';
      if (data.response.version_info.content) {
        newContent = data.response.version_info.content.replace(/\r?\n$/, '');
      }
      dispatch(setTableContentFromCSV(passedFile, newContent));
    },
  };

  const { isFetching } = useFileContent(fileId, includeContent, includeMetadata, usePassedFileContentOptions);

  // a query is used to call the file endpoint for a matched file and return its content when a csv file is selected
  const useMatchingTDFFileOptions = {
    enabled: Boolean(tableName),
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data?.response?.version_info?.content?.DatasetDefinition) {
        dispatch(setMetadataContentFromLinkedTDF(data.response));
      }
    },
  };

  const { isFetchingTDF } = useMatchingTDFFile(
    csvFolder,
    1,
    matchingTDFFile,
    useMatchingTDFFileOptions,
  );

  const queryProviderValue = {
    isFetchingTDF,
  };

  if (!usePermissions(actions.WRITE, instances.DATABASE)) {
    return (<NotAuthorised />);
  }

  return (
    <DatabaseTableCreationProvider value={providerValue}>
      <DatabaseTableCreationQueryProvider value={queryProviderValue}>
        <Segment.Group className="aridhia-panel-wrapper" data-testid="database-table-creation-panel">
          <DatabaseTableCreationHeader />
          {isFetching && <Loader />}
          {!isFetching && (
            <>
              <DatabaseTableCreationSteps />
              <Segment as="article" placeholder attached="top" className="aridhia-panel-steps-content">
                {state.current_step === 1 && <DatabaseTableCreationTable />}
                {state.current_step === 2 && <DatabaseTableCreationMetadata />}
                {state.current_step === 3 && <DatabaseTableCreationColumns />}
                {state.current_step === 4 && <DatabaseTableCreationConfirm />}
                {state.current_step === 5 && <DatabaseTableCreationSummary />}
              </Segment>
              <DatabaseTableCreationFooter />
            </>
          )}
        </Segment.Group>
      </DatabaseTableCreationQueryProvider>
    </DatabaseTableCreationProvider>
  );
};

export default DatabaseTableCreation;
