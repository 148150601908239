import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import DatasetContentDetails from './dataset_content_details';
import MainContentList from '../core/main_content_list_view';
import CreateChorusViewSidebar from './dataset_create_chorus_view_sidebar';
import DatasetSidebar from './dataset_sidebar';
import LoadingSection from '../core/loading_section_view';
import DatasetShowContentHeader from './dataset_show_content_header_view';
import DatabaseColumnSet from '../../collections/database_column_set';
import View from '../loading_view';
import FetchErrors from '../../mixins/fetch_errors';
import { DataSourceCredentialsPage } from '../../mixins/data_source_credentials';

export default View.include(
  DataSourceCredentialsPage,
  FetchErrors,
).extend({
  helpId: 'dataset',
  subviews: {
    '.app-content': 'mainContent',
    '.app-sidebarBasic': 'sidebar',
    '.app-sidebarMultiple': 'secondarySidebar',
  },
  isDataSourceBrowser: true,
  additionalClass: 'dataset_show',
  sidebarOptions: {},
  contentDetailsOptions: {},

  failurePageOptions() {
    return {
      title: t('invalid_route.dataset.title'),
      text: t('invalid_route.dataset.content'),
    };
  },

  title() {
    return this.dataset.get('objectName');
  },

  setup(options) {
    this.model = options.dataset;
    this.dataset = options.dataset;
    this.tab = options.tab;
    this.className = 'app-datasetShow';
    this.templateName = 'tab_content';
    this.dataset.fetchIfNotLoaded();
    this.mainContent = new LoadingSection();
    this.handleFetchErrorsFor(this.model);
    this.sidebarOptions = {
      workspace: options.workspace,
      requiredResources: [options.workspace],
    };
    if (options.contentDetailsOptions) {
      this.contentDetailsOptions = options.contentDetailsOptions;
    }
    // this.handleFetchErrorsFor(this.Dataset);

    this.listenTo(this.dataset, 'loaded', this.datasetLoaded);
    this.listenTo(this.dataset, 'cancel:sidebar', this.hideSidebar);

    if (this.dataset.loaded) {
      this.datasetLoaded();
    }
  },

  datasetLoaded() {
    if (this.tab) {
      this.tab.set('label', this.dataset.get('objectName'));
    }

    this.columnSet = this.dataset.columns();
    this.fetchColumnSet();
  },

  setupMainContent() {
    this.customHeaderView = this.getHeaderView({
      model: this.dataset,
    });

    if (this.mainContent) {
      this.mainContent.teardown(true);
    }
    this.mainContent = new MainContentList({
      modelClass: 'DatasetColumn',
      useCustomList: true,
      collection: this.columnSet,
      persistent: true,
      contentHeader: this.customHeaderView,
      contentDetails: new DatasetContentDetails(_.extend(
        { dataset: this.dataset, collection: this.columnSet, isDataSourceBrowser: false },
        this.contentDetailsOptions,
      )),
    });
    this.setupSidebar();
  },

  fetchColumnSet() {
    if (!this.columnSet.loaded) {
      this.listenTo(this.columnSet, 'loaded', this.drawColumns);
      this.columnSet.fetchAll();
    } else {
      this.drawColumns();
    }
  },

  unprocessableEntity() {
    this.columnSet = this.dataset.columns();
    this.setupMainContent();
    this.render();
  },

  setupSidebar() {
    this.sidebar && this.sidebar.teardown();
    this.sidebar = new DatasetSidebar(this.sidebarOptions);
    this.sidebar.setDataset(this.dataset);
    this.sidebar.setColumnSet(this.columnSet);

    this.listenTo(this.mainContent.contentDetails, 'transform:sidebar', this.showSidebar);
  },

  updateContent() {
    if (this.dataset.isImporting()) {
      this.getProgress();
    }
    this.customHeaderView = this.getHeaderView({
      model: this.dataset,
    });
    this.render();
  },

  getProgress() {
    _.delay(_.bind(function checkProgress() {
      this.dataset.fetch({ silent: true, success: _.bind(this.updateContent, this) });
    }, this), 10000);
  },

  drawColumns() {
    const { serverErrors } = this.columnSet;
    this.columnSet = new DatabaseColumnSet(this.columnSet.models, { dataset: this.dataset });
    this.columnSet.serverErrors = serverErrors;
    this.columnSet.loaded = true;

    this.setupMainContent();
    this.mainContent.contentDetails.options.$columnList = $(this.mainContent.content.el);

    this.render();

    if (this.dataset.isImporting()) {
      this.getProgress();
    }
  },

  getHeaderView(options) {
    return new DatasetShowContentHeader(options);
  },

  constructSidebarForType(type, ...args) {
    if (type === 'chorus_view') {
      this.dataset.setDatasetNumber(1);
      this.sidebar.disabled = true;
      this.mainContent.content.selectMulti = true;
      this.mainContent.content.showDatasetName = true;
      this.mainContent.content.render();
      this.mainContent.content.selectNone();
      this.secondarySidebar = new CreateChorusViewSidebar({
        model: this.model,
        aggregateColumnSet: this.columnSet,
      });
    } else {
      this._super('constructSidebarForType', [type, ...args]);
    }
  },

  showSidebar(type) {
    this.$('.app-sidebarBasic').addClass('hidden');
    this.$('.app-sidebarMultiple').removeClass('hidden');

    if (this.secondarySidebar) {
      this.secondarySidebar.teardown();
      delete this.secondarySidebar;
    }

    this.mainContent.content.selectMulti = false;
    this.constructSidebarForType(type);

    if (this.secondarySidebar) {
      this.secondarySidebar.filters = this.mainContent.contentDetails.filterWizardView.collection;
      this.secondarySidebar.errorContainer = this.mainContent.contentDetails;
      this.renderSubview('secondarySidebar');
      this.trigger('resized');
    }
  },

  hideSidebar(type) {
    this.dataset.clearDatasetNumber();
    this.columnSet.reset(this.dataset.columns().models);
    this.mainContent.content.selectMulti = false;
    this.mainContent.content.showDatasetName = false;
    this.sidebar.disabled = false;
    if (this.secondarySidebar) {
      const parentEl = this.$('.app-sidebarMultiple').parent();
      this.secondarySidebar.teardown();
      delete this.secondarySidebar;
      parentEl.append('<section class="app-sidebarMultiple hidden"/>');
    }
    this.mainContent.content.render();
    this.$('.app-sidebarBasic').removeClass('hidden');
    this.$('.app-sidebarMultiple').addClass('hidden');
    this.removeOldSecondaryClasses(type);
    this.trigger('resized');
  },

  removeOldSecondaryClasses(type) {
    this.$('.app-sidebarMultiple').removeClass(`dataset_create_${type}_sidebar`);
  },
});
