import Office from '../../../../files/panels/office/components/Office';
import ResponsivePanel from '../../ResponsivePanel';

const OfficePanel = ({ tab }) => (
  <ResponsivePanel>
    {(panelWidth) => (
      <Office
        panelWidth={panelWidth}
        tabId={tab.id}
        fileId={tab.config.params.file_id}
      />
    )}
  </ResponsivePanel>
);

export default OfficePanel;
