import _ from '../underscore';
import t from '../intl';
import Error from './error_alert';
import Activity from '../models/activity';
import { renderErrors } from '../utilities/helpers';

export default Error.extend({
  constructorName: 'ImportFailed',

  title: t('import.failed.alert.title'),

  makeModel() {
    this.model = new Activity({ id: this.options.activityId });
  },

  setup() {
    this.requiredResources.add(this.model);
    this.model.fetch();
  },

  additionalContext() {
    return _.extend(this._super('additionalContext'), {
      body: this.getErrorMessage(),
    });
  },

  getErrorMessage() {
    let errorObjects = this.model.get('errorObjects');
    if (!errorObjects) {
      errorObjects = this.tryParsingMessage(this.model.get('errorMessage'));
    }

    if (errorObjects) {
      return renderErrors({ fields: errorObjects });
    }
    return this.model.get('errorMessage');
  },

  tryParsingMessage(message) {
    const regex = /^Validation failed: (.+) \[:(.+), \{\}\]$/;
    const match = regex.exec(message);
    if (!match) {
      return null;
    }

    const field = match[1];
    const reason = match[2];
    const hash = {};
    const reasonHash = {};
    reasonHash[reason.toUpperCase()] = {};
    hash[field.toLowerCase()] = reasonHash;
    return hash;
  },
});
