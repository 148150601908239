import { useMutation } from '@tanstack/react-query';
import { workspaceWorkfileUrl } from '../../constants/api_urls';
import {
  deleter,
  putter,
} from '../../adapters/service';
import { invalidateFileLists } from './service';
import serverErrorMessage from '../../utilities/server_errors';

export const useMultipleRename = (selectedFiles) => {
  const successfulRename = [];
  const failedToRename = [];
  const putFile = () => Promise.allSettled(
    selectedFiles.map((selectedFile) => putter(workspaceWorkfileUrl(selectedFile.id), selectedFile.body)
      .then(() => successfulRename.push({ ...selectedFile }))
      .catch(() => failedToRename.push({ ...selectedFile }))),
  );
  const {
    mutate,
    isError,
    isLoading,
    error,
    reset,
  } = useMutation((body) => putFile(body), {
    onSuccess: invalidateFileLists,
  });

  return {
    renameFiles: mutate,
    isLoading,
    isError,
    error: error?.message || serverErrorMessage(error),
    resetResponse: reset,
    failedToRename,
    successfulRename,
  };
};

export const useMultipleDelete = (selectedFiles) => {
  const successfulDelete = [];
  const failedToDelete = [];
  const deleteFiles = () => Promise.allSettled(
    selectedFiles.map((selectedFile) => deleter(workspaceWorkfileUrl(selectedFile.id))
      .then(() => successfulDelete.push({ ...selectedFile }))
      .catch(() => failedToDelete.push({ ...selectedFile }))),
  );

  const {
    mutate,
    isError,
    isLoading,
    error,
    reset,
  } = useMutation(deleteFiles, {
    onSuccess: invalidateFileLists,
  });

  return {
    deleteFiles: mutate,
    isLoading,
    isError,
    error: error?.message || serverErrorMessage(error),
    resetResponse: reset,
    failedToDelete,
    successfulDelete,
  };
};
