import { t } from 'i18n-js';
import {
  Modal, Icon, Header,
} from 'semantic-ui-react';
import { CloseButton } from '../buttons/ModalActionButtons';

const AboutModal = ({ setModalClose }) => {
  const version = '3.55.0-117182.2516700';
  const year = new Date().getFullYear();

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon aria-label="about" data-testid="about-modal-header-icon" name="info circle" />
        <Header.Content content={t('about.header')} />
      </Header>
      <Modal.Content>
        <p>
          {t('about.version', { version })}
        </p>
        <p>
          {t('about.copyright', { year })}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <CloseButton onClose={setModalClose} />
      </Modal.Actions>
    </>
  );
};

export default AboutModal;
