import React from 'react';
import { Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connectBackboneToReact } from 'connect-backbone-to-react';
import { t } from 'i18n-js';

const FormValidTo = ({
  validTo, validFrom, handleValidToChange, getMaxDate,
}) => (
  <Form.Field inline>
    <label htmlFor="valid_to">{t('upload_token.upload_form.valid_to')}</label>
    <DatePicker
      id="valid_to"
      selected={Date.parse(validTo)}
      dateFormat="yyyy-MM-dd"
      onChange={(date) => handleValidToChange(date)}
      minDate={new Date()}
      maxDate={getMaxDate(validFrom)}
    />
  </Form.Field>
);

const mapModelsToProps = ({ uploadToken }) => ({
  validTo: uploadToken.get('validTo'),
  validFrom: uploadToken.get('validFrom'),
  handleValidToChange: (date) => {
    date.setHours(date.getHours() - (date.getTimezoneOffset() / 60));
    uploadToken.updateValidTo(date.toISOString().split('T')[0]);
  },
  getMaxDate: (date) => {
    const currentDate = new Date(Date.parse(date));
    currentDate.setDate(currentDate.getDate() + 30);
    return currentDate;
  },
});

const options = {
  debounce: true,
  events: {
    uploadToken: ['change:validTo', 'change:validFrom'],
  },
};

export default connectBackboneToReact(mapModelsToProps, options)(FormValidTo);
