import _ from '../../underscore';
import $ from '../../jquery';
import Backbone from '../../vendor/backbone';
import Model from '../models';
import SQLResults from '../../mixins/sql_results';

export default Model.include(SQLResults).extend({
  constructorName: 'Task',

  urlTemplate(options) {
    if (options && options.method === 'delete') {
      return `${this.urlTemplateBase}/{{checkId}}`;
    }
    return this.urlTemplateBase;
  },

  showUrlTemplate() {
    return 'workspaces/{{workfile.attributes.workspace.id}}/sqlresults/{{checkId}}';
  },

  beforeSave() {
    delete this.loaded;
  },

  initialize() {
    this.set({ checkId: Math.floor((Math.random() * 1e8) + 1).toString() });
  },

  cancel() {
    if (this._cancelInProgress || this.loaded) return;
    this._cancelInProgress = true;
    Backbone.sync('delete', this, {
      success: _.bind(function onCancel() {
        this.trigger('canceled');
        delete this._cancelInProgress;
      }, this),
      data: this.underscoreKeys(this.destroyParams()),
      processData: true,
      contentType: 'application/json',
    });
  },

  destroyParams: $.noop,
});
