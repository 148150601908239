import UploadToken from 'models/upload_token';
import Collection from './collections';

export default Collection.extend({
  model: UploadToken,
  urlTemplate: 'api/workspaces/workspaces/{{workspaceID}}/upload_token/',

  urlParams(options) {
    const opts = options;
    if (this.attributes.viewArchived) {
      opts.view_archived = true;
    }
    delete opts.per_page;
    delete opts.page;
    return opts;
  }
});
