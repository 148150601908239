import Dataset from './dataset';
import Attachment from '../mixins/attachment';

export default Dataset.include(Attachment).extend({
  constructorName: 'WorkspaceDataset',

  urlTemplate(options, ...args) {
    const opts = options || {};
    if (opts.download) {
      return this._super('urlTemplate', [opts, ...args]);
    }

    return 'api/workspaces/workspaces/{{workspace.id}}/datasets/{{id}}';
  },

  showUrlTemplate() {
    return 'workspaces/{{workspace.id}}/datasets/{{id}}';
  },

  iconUrl(...args) {
    let result = this._super('iconUrl', args);
    if (!this.hasCredentials()) {
      result = result.replace('.png', '_locked.png');
    }
    return result;
  },

  query() {
    return this.get('query') || this.get('content');
  },

  isSandbox() {
    return this.get('entitySubtype') === 'SANDBOX_TABLE';
  },

  columns(options, ...args) {
    const result = this._super('columns', [options, ...args]);
    result.attributes.workspaceId = this.get('workspace').id;
    return result;
  },

  hasOwnPage() {
    return true;
  },

  setWorkspace(workspace) {
    this.set({ workspace: { id: workspace.id } });
  },

  setIncludeDependencies(includeDependencies) {
    this.set({ includeDependencies });
  },
});
