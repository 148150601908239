import t from '../intl';
import Confirm from './confirm';
import Draft from '../models/draft';
import { workspace } from '../utilities/workspace_assoc';

export default Confirm.extend({
  constructorName: 'WorkfileDraft',

  text: t('workfile.alert.text'),
  title: t('workfile.alert.title'),
  cancel: t('workfile.alert.open_draft'),
  ok: t('workfile.alert.latest_version'),
  additionalClass: 'info',

  setup(options) {
    this.continueFunction = options.continueWith;
  },

  cancelAlert() {
    const draft = new Draft({
      workspaceId: workspace(this.model).id,
      workfileId: this.model.get('id'),
    });
    this.listenTo(draft, 'change', function onDraftChange(data) {
      this.closeModal();
      this.model.isDraft = true;
      this.model.content(data.get('content'));
    });

    draft.fetch();

    if (this.continueFunction) {
      this.continueFunction();
    }
  },

  confirmAlert() {
    const draft = new Draft({
      workspaceId: workspace(this.model).id,
      workfileId: this.model.get('id'),
      id: 'Dummy',
    });

    this.listenTo(draft, 'change', () => {
      draft.destroy();
    });

    this.listenTo(draft, 'destroy', function onDraftDelete() {
      this.closeModal();
      this.model.set({ hasDraft: false });
    });

    draft.fetch();

    if (this.continueFunction) {
      this.continueFunction();
    }
  },
});

