import chorus from '../../chorus';
import _ from '../../underscore';
import $ from '../../jquery';
import t from '../../intl';
import ShuttleHeader from '../../views/core/shuttle_header_view';
import ShuttleWidget from '../../views/core/shuttle_widget_view';
import Dialog from '../../dialogs/dialogs';

/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true }] */

export default Dialog.extend({
  constructorName: 'WorkspaceEditMembers',
  templateName: 'workspace_edit_members',
  title: t('workspace.edit_members_dialog.title'),
  additionalClass: 'dialog_wide',
  persistent: true,

  events: {
    'click button.submit': 'updateMembers',
  },

  subviews: {
    '.shuttle': 'shuttle',
    '.shuttle_header': 'shuttle_header',
  },

  additionalContext() {
    this.status = this.managementWorkspace.get('status');

    return {
      canUpdate: this.status === 'active',
      statusPending: this.status === 'pending',
      statusError: this.status === 'error',

    };
  },

  setup() {
    this.model = this.options.model;
    this.managementWorkspace = this.options.managementWorkspace;
    this.collection = this.options.collection;
  },

  launchModal() {
    this._super('launchModal');
    this.managementWorkspace.fetch().then(() => this.renderShuttle());
  },

  renderShuttle() {
    const onTextChangeFunction = _.debounce(_.bind(function filterUsers(e) {
      this.collection.attributes.namePattern = $(e.target).val();
      this.shuttle.firstPage();
    }, this), 300);

    const currentMembers = this.managementWorkspace.users();

    this.shuttle = new ShuttleWidget({
      collection: this.collection,
      selectionSource: currentMembers,
      workspaceStatus: this.managementWorkspace.get('status'),
      nonRemovable: [this.managementWorkspace.owner(), chorus.session.user()],
      nonRemovableText: t('workspace.owner'),
    });

    this.shuttle_header = new ShuttleHeader({
      objectName: t('workspace.members'),
      onTextChange: onTextChangeFunction,
    });

    this.members = currentMembers.clone();
    this.render();
  },

  hasNewRole(member) {
    let originalRole = '';
    let newRole = 'Standard';

    if (member) {
      originalRole = member.get('original_role') || member.get('role');
      newRole = member.get('role');
    }

    return originalRole !== newRole;
  },

  modifiedMembers(models) {
    const self = this;
    const changedUsers = [];
    const owner = self.managementWorkspace.owner();

    _.each(models, (user) => {
      if (user.get('id') !== owner.get('id') &&
                self.hasNewRole(self.members.get(user.get('id')))) {
        changedUsers.push(user);
      }
    });

    return changedUsers;
  },

  removedUsers(models) {
    const self = this;
    const ids = _.pluck(models, 'id');
    const removedUsers = self.members.pluck('id').filter(x => ids.indexOf(x) < 0);

    return _.map(removedUsers, (removedUserId) => {
      let action = 'delete';
      const removedUser = self.members.get(removedUserId).clone();

      if (removedUser.get('invited')) {
        action = 'reject';
      }
      removedUser.set('action', action);

      return removedUser;
    });
  },

  updateMembers() {
    const self = this;
    self.$('button.submit').startLoading('actions.saving');
    const models = self.shuttle.selectionSource.filter(user => (!user.get('removed')));

    const changedUsers = this.modifiedMembers(models).concat(this.removedUsers(models));
    if (changedUsers.length > 0) {
      self.managementWorkspace.updateMembers(changedUsers, self.saved.bind(this), self.saveFailed.bind(this));
    } else {
      this.closeModal();
    }
  },

  saved() {
    this.closeModal();
    chorus.toast('shuttle.save.success');
    this.model.trigger('invalidated');
    this.model.fetch();
  },

  saveFailed() {
    this.closeModal();
    chorus.toast('shuttle.save.error', { toastOpts: { theme: 'bad_activity' } });
  },
});
