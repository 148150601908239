import { useState } from 'react';
import { t } from 'i18n-js';
import {
  Modal, Icon, Header,
} from 'semantic-ui-react';
import { CancelButton, NegativeButton } from '../../../../../components/buttons/ModalActionButtons';
import HeaderError from '../../../../../components/errors/HeaderError';
import { useDatabaseTablesContext } from '../../Context';
import useMultipleDelete from '../../service_batch';
import toastMessage from '../../../../../utilities/toast';
import { deleteTab } from '../../../../../layout/tabMonitor';
import { isDependencyError } from '../../../../helper';
import DeleteModalMessage from '../../../../../components/modals/DeleteModalMessage';

const MultipleDeleteModal = ({ setModalClose, setIsBusy }) => {
  const [serviceErrorMessages, setServiceErrorMessages] = useState('');
  const {
    selectedTables, setSelectedTables,
  } = useDatabaseTablesContext();
  const selectedTablesLength = selectedTables.length;
  const {
    deleteTables,
    isLoading,
    isError,
    error,
    resetResponse,
    failedToDelete,
    successfulDelete,
    errorMessages,
  } = useMultipleDelete(selectedTables);

  const handleModalClose = () => {
    setModalClose();
    resetResponse();
  };

  const handleDeleteTabs = (tableId) => {
    deleteTab(`datasets/${tableId}`);
    deleteTab(`datatable/dataset/${tableId}`);
    deleteTab(`datatable/dataset/${tableId}/analyse`);
    deleteTab(`dataset_metadata/${tableId}`);
  };

  const handleSubmitting = (withDependencies) => {
    setIsBusy(true);
    deleteTables({ withDependencies }, {
      onSuccess: () => {
        if (failedToDelete.length > 0) {
          setServiceErrorMessages(errorMessages.toString());
          const failedTables = failedToDelete.map((table) => table.name).join(', ');
          const message = failedToDelete.length === 1
            ? t('tables_list.actions.delete_selected_single_error', { tables: failedTables })
            : t('tables_list.actions.delete_selected_error', { count: failedToDelete.length, tables: failedTables });
          toastMessage(
            'error',
            message,
            'deleteError',
          );
        } else {
          const successTables = successfulDelete.map((table) => table.name).join(', ');
          const message = successfulDelete.length === 1
            ? t('tables_list.actions.delete_selected_single_success', { tables: successTables })
            : t('tables_list.actions.delete_selected_success', {
              count: successfulDelete.length, tables: successTables,
            });
          toastMessage(
            'success',
            message,
            'deleteSuccess',
          );
        }
        setSelectedTables(failedToDelete.length > 0 ? failedToDelete : []);
        successfulDelete.forEach((table) => {
          handleDeleteTabs(table.id);
        });
        setIsBusy(false);
        if (failedToDelete.length === 0) {
          handleModalClose();
        }
      },
      onError: () => {
        setIsBusy(false);
      },
    });
  };

  return (
    <>
      <Header as="header" className="aridhia-modal-header">
        <Icon name="trash alternate" title="delete" />
        <Header.Content content={t('tables_list.actions.delete')} />
        {isError && (
          <HeaderError
            error={error}
            onDismiss={() => resetResponse()}
          />
        )}
        {!!serviceErrorMessages && (
          <HeaderError
            error={serviceErrorMessages}
            onDismiss={() => resetResponse()}
          />
        )}
      </Header>
      <Modal.Content>
        <DeleteModalMessage title={t('tables_list.actions.delete_selected_sure', { selectedTablesLength })} />
      </Modal.Content>
      <Modal.Actions>
        <CancelButton loading={isLoading} onClose={handleModalClose} />
        {isDependencyError(serviceErrorMessages) && (
          <NegativeButton
            error={isError}
            isValidEntry
            loading={isLoading}
            onSubmit={() => handleSubmitting(true)}
            submitText={t('tables_list.actions.delete_with_dependants')}
          />
        )}
        <NegativeButton
          error={isError}
          isValidEntry
          loading={isLoading}
          onSubmit={() => handleSubmitting(false)}
          submitText={t('tables_list.actions.delete')}
        />
      </Modal.Actions>
    </>
  );
};

export default MultipleDeleteModal;
