import { t } from 'i18n-js';
import {
  Header, Form, Input, Segment,
} from 'semantic-ui-react';
import { useDatabaseTableCreationContext } from '../Context';
import FormValidationError from '../../../../components/errors/FormValidationError';
import { validationFields, validationLengths } from '../../../../constants/validation';
import { hasError, findErrors } from '../../../../utilities/validation';
import { setMetadataTitle, setMetadataDescription, setMetadataSource } from '../actions';
import MarkdownEditor from '../../../../components/text_editors/MarkdownEditor';

const DatabaseTableCreationMetadataFromScratch = () => {
  const { newDatabaseTableState, dispatch } = useDatabaseTableCreationContext();

  const handleTitleChange = (e, { value }) => {
    dispatch(setMetadataTitle(value));
  };

  const handleDescriptionChange = (value) => {
    dispatch(setMetadataDescription(value));
  };

  const handleSourceChange = (e, { value }) => {
    dispatch(setMetadataSource(value));
  };

  return (
    <>
      <Segment vertical basic>
        <Header
          as="h5"
          content={t('database_tables_new.metadata.from_scratch.header')}
          className="italic"
          textAlign="center"
          color="grey"
        />
      </Segment>
      <Segment vertical basic>
        <Form>
          <Form.Field>
            <label htmlFor="title">{t('database_tables_new.metadata.from_scratch.title')}</label>
            <Input
              id="title"
              placeholder={t('database_tables_new.metadata.from_scratch.title_placeholder')}
              maxLength={validationLengths.NAME}
              value={newDatabaseTableState.table.metadata.title}
              onChange={handleTitleChange}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="description">{t('database_tables_new.metadata.from_scratch.description')}</label>
            <MarkdownEditor
              value={newDatabaseTableState.table.metadata.description}
              onChange={handleDescriptionChange}
              textAreaAttributes={
                {
                  id: 'description',
                  placeholder: t('database_tables_new.metadata.from_scratch.description_placeholder'),
                  maxLength: validationLengths.TEXT,
                }
              }
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="url">
              {t('database_tables_new.metadata.from_scratch.url')}
              {hasError(newDatabaseTableState.validation_errors, validationFields.URL) && (
                <FormValidationError
                  errorMsg={(findErrors(newDatabaseTableState.validation_errors, validationFields.URL)[0]).message}
                />
              )}
            </label>
            <Input
              id="url"
              placeholder={t('database_tables_new.metadata.from_scratch.url_placeholder')}
              maxLength={validationLengths.URL}
              value={newDatabaseTableState.table.metadata.url}
              onChange={handleSourceChange}
              error={hasError(newDatabaseTableState.validation_errors, validationFields.URL)}
            />
          </Form.Field>
        </Form>
      </Segment>
    </>
  );
};

export default DatabaseTableCreationMetadataFromScratch;
