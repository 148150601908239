import chorus from '../../chorus';
import t from '../../intl';
import _ from '../../underscore';
import UserEnableAlert from '../../alerts/user_enable_alert';
import UserDisableAlert from '../../alerts/user_disable_alert';
import ResendMfaAlert from '../../alerts/resend_mfa_alert';
import ActivityList from '../activities/activity_list_view';
import TabControl from '../core/tab_control_view';
import Sidebar from '../core/sidebar_view';
import ChangePassword from '../../dialogs/users/change_password_dialog';
import User from '../../models/user';
import { instance } from '../../models/config';

export default Sidebar.extend({
  templateName: 'user/sidebar',
  entityType: 'user',

  subviews: {
    '.tab_control': 'tabs',
  },

  events: {
    'click .change_password': 'changepassword',
    'click .enable_user': 'launchEnableUserConfirmAlert',
    'click .disable_user': 'launchDisableUserConfirmAlert',
    'click .resend_mfa': 'launchResendMfaConfirmAlert',
  },

  setup() {
    this.config = instance;
    this.tabs = new TabControl(['activity']);
    if (this.model) this.setUser(this.model);
  },

  changepassword(e) {
    e.preventDefault();
    if (this.model) {
      const chorusModel = this.model.chorusUser();
      this.changepasswordDialog = new ChangePassword({ model: chorusModel });
      this.changepasswordDialog.launchModal();
    }
  },

  launchEnableUserConfirmAlert(e) {
    e.preventDefault();
    const alert = new UserEnableAlert({ model: this.model });
    alert.launchModal();
  },

  launchDisableUserConfirmAlert(e) {
    e.preventDefault();
    const alert = new UserDisableAlert({ model: this.model });
    alert.launchModal();
  },

  launchResendMfaConfirmAlert(e) {
    e.preventDefault();
    const alert = new ResendMfaAlert({ model: this.model });
    alert.launchModal();
  },

  additionalContext() {
    const ctx = {};
    if (this.model) {
      const isCurrentUser = this.model.isSessionUser();
      const currentUserCanEdit = this.model.currentUserCanEdit();
      const tenantAdmin = chorus.session.user().isTenantAdmin();
      const mfaEnabled = instance.get('phoneNumberRequired');
      let editUserText = 'actions.edit_profile_as_user';
      let userTitle = 'rsh.sidebar.title.user_list_as_user';
      let userText = 'rsh.sidebar.help.user_list_as_user_other';
      if (currentUserCanEdit) {
        userText = 'rsh.sidebar.help.user_list_as_user';
      } else if (tenantAdmin) {
        editUserText = 'actions.edit_profile';
        userTitle = 'rsh.sidebar.title.user_list';
        userText = 'rsh.sidebar.help.user_list';
      }

      _.extend(ctx, {
        displayName: this.model.displayName(),
        tenantAdmin,
        mfaEnabled,
        tenantAdminOrPermission: (currentUserCanEdit || tenantAdmin),
        listMode: this.options.listMode,
        changePasswordAvailable: currentUserCanEdit,
        isCurrentUser,
        enabled: this.model.enabled(),
        isInEditMode: this.options.editMode,
        editUserButtonText: t(editUserText),
        userSidebarTitle: t(userTitle),
        userSidebarText: t(userText),
        active: this.model.get('status') === 'active',
      });
    }

    return ctx;
  },

  setUser(user) {
    if (!user) return;
    this.setModel(user);
    if (!chorus.session.user().isTenantAdmin()) {
      const chorusUser = new User({ uuid: this.model.get('uuid') });
      chorusUser.fetch({
        success: function fetchActivities() {
          this.collection = chorusUser.activities();
          this.collection.fetch();
          this.listenTo(this.collection, 'changed', this.render);

          this.tabs.activity && this.tabs.activity.teardown();
          this.tabs.activity = new ActivityList({
            collection: this.collection,
            additionalClass: 'sidebar',
          });
          this.registerSubView(this.tabs.activity);
          this.render();
        }.bind(this),
      });
    }
    this.render();
  },
});
