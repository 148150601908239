import _ from '../../../underscore';
import View from '../../loading_view';

export default View.extend({
  templateName: 'mini_apps/app_sidebar_miniapps',
  entityType: 'MiniAppSidebarMiniapps',

  debouncedUpdateModel: null, // Will be populated in setup

  setup() {
    this.debouncedUpdateModel = _.debounce(this.updateModel.bind(this), 10);
    this.shouldUpdate = true;
  },

  additionalContext() {
    const context = {};
    if (this.options.apps) {
      context.apps = this.options.apps.models.map(function extendContext(app) {
        return {
          isCurrent: app.id === this.options.model.id,
          workspaceID: this.options.workspace.attributes.id,
          app,
        };
      }, this);
    }
    return context;
  },

  updateModel() {
    if (this.shouldUpdate) {
      this.model.fetch();
    }
  },

  modelDeleted(app) {
    if (!this.model || app.get('id') === this.model.get('id')) {
      this.shouldUpdate = false;
    }
  },
});
