import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import toastMessage from '../../utilities/toast';

const createEventListener = () => {
  const legacyErrorListener = (event) => {
    if (event.data.type === 'error') {
      toastMessage('error', event.data.message, 'toastFromLegacy');
    }
  };

  window.addEventListener('message', legacyErrorListener, false);
  return () => {
    window.removeEventListener('message', legacyErrorListener, false);
  };
};

const ToastWrapper = () => {
  useEffect(() => createEventListener(), []);

  return (
    <ToastContainer className="semantic" />
  );
};

export default ToastWrapper;
