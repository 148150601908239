import { useQuery, useMutation } from '@tanstack/react-query';
import {
  workspaceDeleteTable,
  workspaceSaveTableAsCSV,
  databaseTablePreview,
  newDBView,
} from '../constants/api_urls';
import { deleter, poster, queryClient } from '../adapters/service';
import serverErrorMessage from '../utilities/server_errors';
import { workspaceId } from '../layout/helper';
import { invalidateFileLists } from '../files/panels/service';

export const invalidateTableLists = () => {
  queryClient.invalidateQueries(['tables', workspaceId()]);
  queryClient.invalidateQueries(['attach', workspaceId()]);
};

export const useDelete = (tableId) => {
  const deleteTable = ({ withDependencies }) => deleter(workspaceDeleteTable(tableId, withDependencies));
  const {
    mutate,
    isError,
    isLoading,
    error,
    reset,
  } = useMutation((body) => deleteTable(body), {
    onSuccess: invalidateTableLists,
  });

  return {
    deleteTable: mutate,
    isLoading,
    isError,
    error: error?.message || serverErrorMessage(error),
    resetResponse: reset,
  };
};

export const useSaveAsCSV = () => {
  const postFile = (body) => poster(workspaceSaveTableAsCSV(), body);
  const {
    mutate,
    isError,
    isLoading,
    error,
    reset,
  } = useMutation((body) => postFile(body), {
    onSuccess: invalidateFileLists,
  });

  return {
    saveTableAsCSV: mutate,
    isLoading,
    isError,
    error: error?.message || serverErrorMessage(error),
    resetResponse: reset,
  };
};

export const useTablePreview = (tableId) => {
  const fetchTables = () => poster(databaseTablePreview(tableId));
  const {
    data,
    isLoading,
    isRefetching,
    refetch,
    isError,
    error,
  } = useQuery(['tablePreview', tableId], fetchTables);

  return {
    rowsData: data ? data.response.rows.slice(0, 50) : [],
    columnsData: data ? data.response.columns : [],
    isLoading,
    isRefetching,
    refetch,
    isError,
    error: error?.message || serverErrorMessage(error),
  };
};

export const useNewDBView = (tableId, options) => {
  const postDBView = (body) => poster(newDBView(tableId), body);
  const {
    mutate,
    isError,
    isLoading,
    error,
    reset,
  } = useMutation({ mutationFn: postDBView, ...options });

  return {
    createNewDBView: mutate,
    isLoading,
    isError,
    error: error?.message || serverErrorMessage(error),
    resetResponse: reset,
  };
};
