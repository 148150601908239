import t from '../intl';
import Confirm from './confirm';

export default Confirm.extend({
  constructorName: 'RemoveJoinConfirmAlert',

  title: t('dataset.join.remove.title'),
  ok: t('dataset.join.remove.ok'),

  setup() {
    this.text = t('dataset.join.remove.body', { tableName: this.options.dataset.get('objectName') });
  },

  confirmAlert() {
    this.options.chorusView.removeJoin(this.options.dataset);
    this.closeModal();
  },
});
