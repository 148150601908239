import { Progress } from 'semantic-ui-react';

const ProgressBar = ({ progress }) => (
  <Progress
    role="progressbar"
    value={progress}
    total="100"
    progress="percent"
    color="blue"
    active
    size="small"
    className="margin-bottom-none"
  />
);

export default ProgressBar;
