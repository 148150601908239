import { createContext, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { workspaceUrl, workspaceAdminUrl } from '../constants/api_urls';
import { getter } from './service';
import { setWorkspaceIdForTest } from '../layout/helper';

export const useWorkspace = () => {
  const options = {};
  // newish api (2022) some browsers may not have it but most should
  if (AbortSignal?.timeout) {
    options.signal = AbortSignal.timeout(10000);
  }
  // this should not be a lengthy query, if something goes wrong retry after 10 seconds
  const fetchWorkspace = () => getter(workspaceUrl(), options);
  const { data, ...props } = useQuery(['workspace'], fetchWorkspace);

  return {
    workspace: data?.response,
    ...props,
  };
};

export const useWorkspaceAdmin = (uuid) => {
  const fetchWorkspace = () => getter(workspaceAdminUrl(uuid));
  const { data, ...props } = useQuery(['workspace', uuid], fetchWorkspace);

  return {
    workspace: data,
    ...props,
  };
};

const WorkspaceContext = createContext();

export const useWorkspaceContext = () => useContext(WorkspaceContext);

export const WorkspaceProvider = ({ workspace, children }) => {
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    // There are a lot of tests that setup the workspaceId for the test by using workspaceprovider.
    // In production this value would be got from the url. In order to somewhat reduce an already
    // big change request this code is a hack to provide backwards compatibility for the tests.
    if (workspace.workspaceId) {
      setWorkspaceIdForTest(workspace.workspaceId);
    }
  }

  return (
    <WorkspaceContext.Provider value={workspace}>
      {children}
    </WorkspaceContext.Provider>
  );
};
