import { useCallback, useEffect } from 'react';
import { renameTab, setTabIcon } from '../../../../layout/tabMonitor';
import LoadingError from '../../../../components/errors/LoadingError';
import Loader from '../../../../components/Loader';
import OfficeView from './OfficeView';
import { useFile, useWopiAccessToken } from '../../service';
import { useOfficeDiscoveryContext } from '../../../OfficeDiscoveryContext';
import { constructOfficeDocumentUrl } from '../../../helper';

const Office = ({ tabId, fileId }) => {
  const {
    createToken,
    token,
    isIdle: isTokenIdle,
    isLoading: isTokenLoading,
    isError: isTokenError,
  } = useWopiAccessToken(fileId);
  useEffect(() => createToken(), [createToken]);

  const { isError: isFileError, isFetching: isFileFetching, file } = useFile(fileId);
  const { isError: isDiscoveryError, isFetching: isDiscoveryFetching, getAction } = useOfficeDiscoveryContext();

  const fileName = file?.file_name || '';
  const action = getAction(fileName);
  const documentUrl = action ? constructOfficeDocumentUrl(action.url, fileId) : null;
  const isLoading = isFileFetching || isDiscoveryFetching || isTokenLoading || isTokenIdle;
  const isError = isFileError || isDiscoveryError || isTokenError || !action;

  useEffect(
    () => { fileName && renameTab(tabId, fileName); },
    [tabId, fileName],
  );

  useEffect(
    () => { action?.favIconUrl && setTabIcon(tabId, action.favIconUrl); },
    [tabId, action?.favIconUrl],
  );

  const onFormLoaded = useCallback((formNode) => formNode?.submit(), []);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <LoadingError />;
  }

  return (
    <OfficeView
      fileId={fileId}
      fileName={fileName}
      favIconUrl={action.favIconUrl}
      documentUrl={documentUrl}
      token={token}
      onFormLoaded={onFormLoaded}
    />
  );
};

export default Office;
