import { t } from 'i18n-js';
import InboxTableRow from './InboxTableRow';
import inboxDisplayFileName from '../helper';
import NoInboxFilesMessage from './NoInboxFilesMessage';
import { InboxFileProvider, useInboxContext } from '../Context';
import { sizeAsString } from '../../../../utilities/helper';
import {
  ColumnsHeader,
  EmptyMessage,
  ErrorComponent,
  MultiSelectIcon,
  Paging,
  SelectAll,
  Stats,
  TableHeader,
  TableWrapper,
} from '../../../../query_table';
import RefreshButton from '../../../../components/buttons/RefreshButton';
import InboxMultiSelectActions from './InboxMultiSelectActions';

const state = (file) => ({
  id: file.id,
  displayName: inboxDisplayFileName(file.file_name),
  name: file.file_name,
  folder: file.folder,
  type: file.file_type,
  size: sizeAsString(file.content_length),
  source: file.metadata ? file.metadata.source : null,
});

const renderBodyRow = (file) => (
  <InboxFileProvider file={state(file)} key={file.id}>
    <InboxTableRow key={file.id} />
  </InboxFileProvider>
);

const InboxSelectAll = () => {
  const {
    files,
    isFetching,
    isError,
    selectedFiles,
    handleSelectedFiles,
  } = useInboxContext();

  return (
    <SelectAll
      tableData={files}
      selectedTableData={selectedFiles}
      handleSelectedTableData={handleSelectedFiles}
      isFetching={isFetching}
      isError={isError}
    />
  );
};

const columns = () => ([
  { name: 'multi_select', content: <InboxSelectAll />, collapsing: true },
  { name: 'file_name', content: t('file_list.table_header.file_name') },
  { name: 'size', content: t('file_list.table_header.size') },
  { name: 'uploaded_via', content: t('file_list.table_header.uploaded_via') },
  { name: 'actions', collapsing: true },
]);

const InboxTable = () => {
  const {
    files,
    selectedFiles,
    isFetching,
    handleRefreshButton,
    isError,
    pagination,
    setPage,
  } = useInboxContext();

  return (
    <TableWrapper
      tableData={files}
      columns={columns()}
      tableHeader={(
        <TableHeader
          columns={columns()}
          multiSelectIcon={<MultiSelectIcon selectedItems={selectedFiles} />}
          multiSelectActions={<InboxMultiSelectActions />}
          errorComponent={<ErrorComponent isError={isError} />}
          refresh={<RefreshButton isFetching={isFetching} refetch={handleRefreshButton} />}
        />
      )}
      columnsHeader={<ColumnsHeader columns={columns()} />}
      renderBodyRow={renderBodyRow}
      emptyWrapper={(
        <EmptyMessage
          isFetching={isFetching}
          tableData={files}
          columns={columns()}
          emptyContent={<NoInboxFilesMessage />}
        />
      )}
      stats={<Stats shown={files.length} total={pagination.records} />}
      pagination={<Paging total={pagination.records} onPageChange={setPage} activePage={pagination.page} />}
    />
  );
};

export default InboxTable;
